import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  img?: string;
}

export const Wrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 16px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 8px;
`;

export const ItemWrap = styled.button<IProps>`
  height: 28px;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.colors.white};
  margin-right: 6px;

  margin-bottom: 8px;
  border-radius: 14px;
  border: 1px solid ${(props) => props.theme.colors.neutral.n4};
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: none;
`;

export const Image = styled.span<IProps>`
  display: block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: url(${({ img }) => img}) no-repeat;
  background-size: cover;
  background-position: center;
`;

export const Name = styled.p`
  font-size: ${(props) => props.theme.typography.fontSize.f2};
  height: 13px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${(props) => props.theme.colors.black};
  margin-left: 5px;
  margin-right: 8px;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const MoreButton = styled.button<IProps>`
  height: 28px;
  border-radius: 14px;
  box-sizing: border-box;
  padding: 0 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.blue};
  background-color: ${(props) => props.theme.colors.blueLight};
  cursor: pointer;
  margin-bottom: 8px;
  outline: none;
  border: none;
`;

export const EmptyUserPhoto = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: ${(props) => props.theme.colors.blueLight};
  display: flex;
  justify-content: center;
  align-items: center;
`;
