import React from 'react';

import { TickIcon } from 'public/assets/icons';
import {
  GroupSettingRadioWrap,
  GroupSettingRadioBodyWrap,
  GroupSettingRadioHeader,
  GroupSettingRadioBodyText,
  RadioWrapper,
} from './CreateGroup.style';

interface IGroupSettingRadio {
  header: string;
  body: string;
  isSelected: boolean;
  onClick: () => void;
}

export const GroupSettingRadio = ({
  header,
  body,
  isSelected,
  onClick,
}: IGroupSettingRadio) => (
  <GroupSettingRadioWrap isSelected={isSelected} onClick={onClick}>
    <GroupSettingRadioBodyWrap>
      <GroupSettingRadioHeader>{header}</GroupSettingRadioHeader>
      <GroupSettingRadioBodyText>{body}</GroupSettingRadioBodyText>
    </GroupSettingRadioBodyWrap>

    <RadioWrapper isSelected={isSelected}>
      {isSelected && <TickIcon />}
    </RadioWrapper>
  </GroupSettingRadioWrap>
);
