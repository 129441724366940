import styled from 'styled-components';

import { style, deviceMaxWidth } from 'utils/constants/style';

interface IProps {
  width?: number;
  height?: number;
  mr?: number;
  ml?: number;
  img?: string;
  rounded?: boolean;
  backgroundColor?: string;
  right?: number;
  bottom?: number;
  pb?: number;
  alignSelf?: string;
  centralize?: boolean;
  cursor?: string;
}

export const CentralizeContentWrap = styled.div<IProps>`
  display: flex;
  align-items: center;
  cursor: ${({ cursor }) => cursor || 'auto'};
`;

export const UserProfilePhoto = styled.div<IProps>`
  min-width: ${({ width }) => (width ? `${width}px` : '36px')};
  width: ${({ width }) => (width ? `${width}px` : '36px')};
  height: ${({ height }) => (height ? `${height}px` : '36px')};
  border-radius: 50%;
  background: url(${({ img }) => img}) no-repeat;
  background-size: cover;
  background-position: center;
  margin-right: ${({ mr }) => (mr ? `${mr}px` : '0')};
  margin-left: ${({ ml }) => (ml ? `${ml}px` : '0')};
  position: relative;
`;

export const UsernameText = styled.p`
  font-size: 15px;
  line-height: 150%;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
`;

export const ThreeDotsWrap = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(90deg);
`;

export const InputFieldWrap = styled.div`
  width: 100%;
  max-width: 404px;
  min-height: 61px;
  display: flex;
  align-items: flex-end;
  padding: 0 16px;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  border-top: 1px solid ${style.mainGray.whisperColor};
  background-color: ${style.mainWhite.white};
  padding: 13px 16px;

  @media only screen and (${deviceMaxWidth.sm}) {
    max-width: 100%;
  }
`;

export const CameraIconWrap = styled.div`
  width: 19px;
  height: 16px;
  cursor: pointer;
  position: relative;
  bottom: 12px;
`;

export const ImageInput = styled.input`
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  opacity: 0;
  left: 0;
  cursor: pointer;
`;

export const IconWrap = styled.div<IProps>`
  width: ${({ width }) => (width ? `${width}px` : '28px')};
  height: ${({ height }) => (height ? `${height}px` : '28px')};
  border-radius: ${({ rounded }) => (rounded ? '50%' : '0')};
  background-color: ${({ backgroundColor }) =>
    backgroundColor || 'transparent'};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: ${({ bottom }) => (bottom ? `${bottom}px` : '0')};
  right: ${({ right }) => (right ? `${right}px` : '0')};
  cursor: pointer;

  :hover {
    ${({ backgroundColor }) =>
      backgroundColor &&
      `
      background-color: ${style.mainBlue.blue};
    `}
  }
`;

export const ChatMessagesWrap = styled.div<IProps>`
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  box-sizing: border-box;
  padding: 20px 16px 0 16px;
  overflow: auto;
  height: ${({ height }) => `${height}px`};

  @media only screen and (${deviceMaxWidth.sm}) {
    height: ${({ height }) => `${height + 56}px`};
  }

  ::-webkit-scrollbar-track {
    background: none;
  }
  // ::-webkit-scrollbar {
  //   width: 4px;
  //   height: 100px;
  //   background: none;
  // }
  // ::-webkit-scrollbar-thumb {
  //   background: ${style.mainGray.silver};
  //   border-radius: 10px;
  // }
`;

export const MessageWrap = styled.div<IProps>`
  width: 100%;
  align-self: ${({ alignSelf }) => alignSelf};
  text-align: ${({ centralize }) => (centralize ? 'center' : 'left')};
`;

export const ProfilePhotoIcon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${style.mainBlue.blue};
  position: absolute;
  right: -3px;
  bottom: -3px;
`;

export const PopOverWrap = styled.div<IProps>`
  position: absolute;
  top: 44px;
  right: 12px;
  z-index: 100;
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
`;

export const EmojiPickerWrap = styled.div`
  position: absolute;
  bottom: 42px;
  z-index: 9;
`;

export const PlaceholderWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;
`;

export const Text = styled.p`
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.02em;
  color: ${style.mainGray.silver};
`;
