import { HYDRATE } from 'next-redux-wrapper';

import { AsyncStatus } from 'utils/types';
import { actionTypes } from './actions';

const initialState = {
  token: null,
  email: null,
  terms: null,
  tutorialIsActive: null,
  uid: '',
  userNameOrEmail: '',
  password: '',
  error: null,
  code: null,
  _id: null,

  loginData: null,

  loginReqStat: AsyncStatus.NONE,
  loginWithFacebookReqStat: AsyncStatus.NONE,
  loginWithAppleReqStat: AsyncStatus.NONE,
  activateAccountReqStat: AsyncStatus.NONE,
  resendEmailReqStat: AsyncStatus.NONE,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE: {
      return { ...state, ...action.payload };
    }

    case actionTypes.LOGIN_ERROR:
      return {
        ...state,
        ...{ code: action.error },
        loginReqStat: AsyncStatus.ERROR,
      };
    case actionTypes.LOGIN_RESET_DATA:
      return {};

    case actionTypes.LOGIN_START:
      return {
        ...state,
        ...{
          userNameOrEmail: action.data.userNameOrEmail,
          password: action.data.password,
        },
        loginReqStat: AsyncStatus.LOADING,
        code: null,
      };

    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        ...{
          _id: action.data._id,
          code: action.data.code,
          error: action.data.message,
          token: action.data.token,
          uid: action.data.uid,
          tutorialIsActive: action.data.tutorialIsActive,
          terms: action.data.terms,
          email: action.data.email,
        },
        loginReqStat: AsyncStatus.SUCCESS,
      };

    // LOGIN WITH FACEBOOK
    case actionTypes.LOGIN_WITH_FACEBOOK_START:
      return { ...state, loginWithFacebookReqStat: AsyncStatus.LOADING };

    case actionTypes.LOGIN_WITH_FACEBOOK_SUCCESS:
      return {
        ...state,
        ...action.data,
        loginWithFacebookReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.LOGIN_WITH_FACEBOOK_ERROR:
      return { ...state, loginWithFacebookReqStat: AsyncStatus.ERROR };

    // LOGIN WITH APPLE
    case actionTypes.LOGIN_WITH_APPLE_START:
      return { ...state, loginWithAppleReqStat: AsyncStatus.LOADING };

    case actionTypes.LOGIN_WITH_APPLE_SUCCESS:
      return {
        ...state,
        ...action.data,
        loginWithAppleReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.LOGIN_WITH_APPLE_ERROR:
      return { ...state, loginWithAppleReqStat: AsyncStatus.ERROR };

    // SET LOGIN DATA
    case actionTypes.SET_LOGIN_DATA:
      return { ...state, loginData: action.data };

    // ACTIVATE ACCOUNT
    case actionTypes.ACTIVATE_ACCOUNT_START:
      return { ...state, activateAccountReqStat: AsyncStatus.LOADING };

    case actionTypes.ACTIVATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        ...action.data,
        activateAccountReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.ACTIVATE_ACCOUNT_ERROR:
      return {
        ...state,
        email: action?.data?.email || state?.email,
        activateAccountReqStat: AsyncStatus.ERROR,
      };

    // ACTIVATE ACCOUNT
    case actionTypes.RESEND_ACTIVATION_EMAIL_START:
      return { ...state, resendEmailReqStat: AsyncStatus.LOADING };

    case actionTypes.RESEND_ACTIVATION_EMAIL_SUCCESS:
      return {
        ...state,
        resendEmailReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.RESEND_ACTIVATION_EMAIL_ERROR:
      return {
        ...state,
        resendEmailReqStat: AsyncStatus.ERROR,
      };

    default:
      return state;
  }
}

export default reducer;
