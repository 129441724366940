import fetchData from 'utils/fetchData';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const followGroup = (groupId): Promise<any> => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._DB_HOST}api/v01/group/${groupId}/follow`, {
      method: 'PUT',
      headers: { ...headers, tsec: JSON.parse(token) },
    }).then((response) => response);
  } catch (error) {
    console.log(error);
  }
};

export const unfollowGroup = (groupId): Promise<any> => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._DB_HOST}api/v01/group/${groupId}/follow`, {
      method: 'DELETE',
      headers: { ...headers, tsec: JSON.parse(token) },
    }).then((response) => response);
  } catch (error) {
    console.log(error);
  }
};
