import React from 'react';
import { style } from '../../../utils/constants/style';

export const ShareIcon = ({
  colorStroke = '#1C1F29',
}: {
  colorStroke?: string | undefined;
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.3718 9.63124L14.414 2.46939C14.0681 2.15793 13.7147 2 13.3636 2C12.8763 2 12.3073 2.32863 12.3073 3.2545V7.67032C9.87851 7.81918 7.61868 8.74808 5.89185 10.3183C4.02692 12.0142 3 14.2623 3 16.6483C3 17.6761 3.19165 18.6855 3.56992 19.6484C3.65262 19.8591 3.87402 20 4.12228 20C4.3707 20 4.59195 19.8591 4.6748 19.6484C5.83813 16.6862 8.85983 14.6215 12.3074 14.3764V18.7454C12.3074 19.6712 12.8764 19.9999 13.3636 19.9999H13.3638C13.7147 19.9999 14.0681 19.8421 14.414 19.5307L22.3719 12.3686C22.7769 12.004 22.9998 11.518 22.9998 10.9999C22.9998 10.4819 22.7768 9.99571 22.3718 9.63124V9.63124Z"
      stroke={colorStroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
