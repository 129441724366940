/* eslint-disable no-unused-expressions */
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/common/Button';
import { useRouter } from 'next/router';
import {
  CardWrap,
  BackgroundImage,
  DescriptionText,
  AlertText,
} from './CampaignCard.style';

export const CampaignCard = ({
  id,
  coverPhoto,
  name,
  paginationRef,
  alertText,
  ctaText,
  itemsToShow,
}: any) => {
  const { t } = useTranslation();
  const router = useRouter();
  return (
    <CardWrap ref={paginationRef} style={{ marginBottom: '10%' }}>
      <BackgroundImage img={coverPhoto} role="img" aria-label="cover">
        <div style={{ paddingLeft: '5%', height: '100%' }}>
          <div
            style={{ height: itemsToShow && itemsToShow === 1 ? '36%' : '44%' }}
          >
            {alertText ? <AlertText>{alertText}</AlertText> : <></>}
          </div>

          <div
            style={{ height: itemsToShow && itemsToShow === 1 ? '19%' : '21%' }}
          >
            <DescriptionText>
              <b>{name}</b>
            </DescriptionText>
          </div>

          <div style={{ height: '37%' }}>
            <Button
              variant="default"
              size="sm"
              onClick={() => router.push(`c/${id}`)}
            >
              {ctaText && String(ctaText)?.trim()?.length
                ? String(ctaText)?.trim()
                : t('common:details')}
            </Button>
          </div>
        </div>
      </BackgroundImage>
    </CardWrap>
  );
};
