import fetchData from 'utils/fetchData';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const postVehicleShare = (vehicleId) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOST}api/v11/analytics/vehicles/${vehicleId}/shared`,
      {
        method: 'POST',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((resp) => (resp.status === 204 ? resp : resp.json()));
  } catch (error) {
    console.log(error);
  }
};

export const postPostShare = (postId) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOST}api/v11/analytics/posts/${postId}/shared`,
      {
        method: 'POST',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((resp) => (resp.status === 204 ? resp : resp.json()));
  } catch (error) {
    console.log(error);
  }
};

export const postPostViewed = (postId) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOST}api/v11/analytics/posts/${postId}`,
      {
        method: 'POST',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((resp) => (resp.status === 204 ? resp : resp.json()));
  } catch (error) {
    console.log(error);
  }
};

export const postEventShare = (eventId) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOST}api/v11/analytics/events/${eventId}/shared`,
      {
        method: 'POST',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((resp) => (resp.status === 204 ? resp : resp.json()));
  } catch (error) {
    console.log(error);
  }
};

export const postUserShare = (userId) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOST}api/v11/analytics/users/${userId}/shared`,
      {
        method: 'POST',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((resp) => (resp.status === 204 ? resp : resp.json()));
  } catch (error) {
    console.log(error);
  }
};
