import styled from 'styled-components';

export const BackButtonWrap = styled.div`
  cursor: pointer;
  width: 48px;
  height: 48px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
