/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import Input from 'components/common/Input';
import { composeValidators, required, requiredImage } from 'utils/validators';
import UploadImage from 'components/common/UploadImage';
import { ChevronDownIcon } from 'public/assets/icons';
import Make from './Make';
import Model from './Model';
import { IVehicleForm } from './IVehicleForm';

import {
  CollapsedBlock,
  CollapsedButton,
  FinalFormWrapper,
  FormWrapper,
  InputWrapper,
  SectionHeader,
  SectionWrapper,
  VehicleAdditionalInfo,
} from './VehicleForm.styles';
import VehicleType from './VehicleType';

const VehicleForm = (props: IVehicleForm) => {
  const [colapseAdditionalInfo, setColapseAdditionlInfo] =
    useState<boolean>(true);

  const { t } = useTranslation();
  const theme: any = useTheme();

  return (
    <FinalFormWrapper
      {...props}
      render={(formRenderProps: FormRenderProps) => {
        const { handleSubmit, values, form } = formRenderProps;
        const { inProgress, ready, onIconClick, variant = 'create' } = props;
        return (
          <FormWrapper
            onSubmit={handleSubmit}
            onIconClick={onIconClick}
            isHeaderBorder
            headerText={
              variant === 'create'
                ? t('addVehicle:addVehicleText')
                : t('addVehicle:editVehicleText')
            }
            submitText={
              variant === 'create' ? t('addVehicle:add') : t('addVehicle:save')
            }
          >
            <UploadImage
              name="photos"
              label="Add a photo"
              validate={composeValidators(
                requiredImage(t('error:thisFieldIsRequired'))
              )}
            />
            <SectionWrapper>
              <SectionHeader type="h3">
                {t('addVehicle:vehicleInformation')}
              </SectionHeader>
              <Input
                id="year"
                name="year"
                type="text"
                label={t('addVehicle:modelYear')}
                placeholder={t('addVehicle:modelYear')}
                validate={composeValidators(
                  required(t('error:thisFieldIsRequired'))
                )}
              />
            </SectionWrapper>
            <SectionWrapper>
              <Make />
              <Model id={values?.make} />
            </SectionWrapper>
            <VehicleAdditionalInfo
              onClick={() => setColapseAdditionlInfo(!colapseAdditionalInfo)}
            >
              <SectionHeader type="h3">
                {t('addVehicle:additionalInfo')}
              </SectionHeader>
              <CollapsedButton collapse={colapseAdditionalInfo}>
                <ChevronDownIcon
                  color={
                    colapseAdditionalInfo
                      ? theme.colors.white
                      : theme.colors.blue
                  }
                />
              </CollapsedButton>
            </VehicleAdditionalInfo>
            <CollapsedBlock collapse={colapseAdditionalInfo}>
              <InputWrapper>
                <Input
                  id="version"
                  name="version"
                  type="text"
                  label={t('addVehicle:vehicleTrim')}
                  placeholder={t('addVehicle:vehicleTrim')}
                  // validate={composeValidators(
                  //   required(t('error:thisFieldIsRequired'))
                  // )}
                />
              </InputWrapper>
              <InputWrapper>
                <Input
                  id="nickname"
                  name="nickname"
                  type="text"
                  label={t('addVehicle:nickname')}
                  placeholder={t('addVehicle:nickname')}
                />
              </InputWrapper>
              <InputWrapper>
                <Input
                  id="description"
                  name="description"
                  type="textarea"
                  label={t('addVehicle:description')}
                  placeholder={t('addVehicle:typeDescriptionHere')}
                  maxLength={500}
                />
              </InputWrapper>
              <VehicleType />
            </CollapsedBlock>
          </FormWrapper>
        );
      }}
    />
  );
};

export default VehicleForm;
