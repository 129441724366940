import React from 'react';

export const LocationIcon = ({ color = '#8D8D8D' }: { color?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 20.5C16.6667 16.2018 19 13.9503 19 10.0614C19 6.67018 16.8125 3 12 3C7.1875 3 5 6.67018 5 10.0614C5 13.9503 7.33333 16.2018 12 20.5Z"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="12"
      cy="9.70801"
      r="2.625"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
