import styled from 'styled-components';

import Text from 'components/common/Text';
import Heading from 'components/common/Typography/Heading';

interface IProps {
  pt?: number;
  pb?: number;
  mt?: number;
  mb?: number;
  fw?: number;
  fs?: number;
  mr?: number;
  isSelectedStep?: boolean;
  selected?: boolean;
  checked?: boolean;
}

export const PermissionContentWrapper = styled.div<IProps>`
  & > div {
    &:first-child,
    &:nth-child(2) {
      & > div {
        & > label {
          & > div {
            &:nth-child(2) {
              position: absolute;
              right: 20px;
              top: 20px;
              & > span {
                height: 20px;
                width: 20px;
                border: 2px solid ${(props) => props.theme.colors.blue};
                &::after {
                  left: 7px;
                  top: 3px;
                  width: 4px;
                  height: 10px;
                }
              }
            }
          }
        }
      }
    }
    &:last-child {
      & > div {
        & > label {
          & > div {
            ${(props) => {
              if (props.checked) {
                return `
                  margin-bottom: 12px;
                  &:nth-child(3) {
                    position: absolute;
                    right: 20px;
                    top: 10px;
                    & > span {
                      height: 20px;
                      width: 20px;
                      border: 2px solid ${props.theme.colors.blue};
                      &::after {
                        left: 7px;
                        top: 3px;
                        width: 4px;
                        height: 10px;
                      }
                    }
                  }
                `;
              }
              return `
                &:nth-child(2) {
                  position: absolute;
                  right: 20px;
                  top: 10px;
                  & > span {
                    height: 20px;
                    width: 20px;
                    border: 2px solid ${props.theme.colors.blue};
                    &::after {
                      left: 7px;
                      top: 3px;
                      width: 4px;
                      height: 10px;
                    }
                  }
                }
              `;
            }}
          }
        }
      }
    }
  }
`;

export const PermissionContentBlock = styled.div<IProps>`
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  border: 1px solid
    ${(props) =>
      !props.checked
        ? props.theme.colors.neutral.n4
        : props.theme.colors.blueHover};
  border-radius: 6px;
  margin-bottom: 16px;
`;

export const PermissionContentBlockLeft = styled.div`
  margin-right: 30px;
`;
export const PermissionContentBlockRight = styled.div`
  width: 100%;
  & > label {
    padding-left: 0;
    margin-bottom: 0;
  }
`;

export const BlockHeading = styled(Heading)<IProps>`
  font-size: ${({ fs, theme }) =>
    fs ? `${fs}px` : theme.typography.fontSize.f6};
  line-height: 150%;
  font-weight: ${({ fw }) => fw || '600'};
  letter-spacing: 0.02em;
  color: ${(props) => props.theme.colors.text};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
`;
export const BlockText = styled(Text)`
  font-size: ${(props) => props.theme.typography.fontSize.f2};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.colors.neutral.n9};
  letter-spacing: 0.02em;
`;

export const PermissionWrapper = styled.div`
  cursor: pointer;
`;
export const PermissionWrapperLeft = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const PermissionBlock = styled.div`
  display: flex;
  cursor: pointer;
`;
export const PermissionBlockText = styled(Text)`
  font-size: ${(props) => props.theme.typography.fontSize.f3};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.colors.neutral.n11};
`;
export const PermissionBlockLeft = styled.div``;
export const PermissionBlockRight = styled.div``;

export const SeparateSettingsHeader = styled(Heading)<IProps>`
  font-size: ${({ fs, theme }) =>
    fs ? `${fs}px` : theme.typography.fontSize.f6};
  line-height: 150%;
  font-weight: ${({ fw }) => fw || '600'};
  letter-spacing: 0.02em;
  color: ${(props) => props.theme.colors.text};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
`;
