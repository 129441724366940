import styled from 'styled-components';

interface IProps {
  bgColor?: string;
  color?: string;
  size?: string;
  type?: string;
  isSelected?: boolean;
  disabled?: boolean;
  isImage?: boolean;
}

export const PillsWrapper = styled.span<IProps>`
  padding: ${(props) =>
    (props.size === 'sm' && '7px 11px') ||
    (props.type === 'addTags' && '0 10px 0 0') ||
    '4px 10px'};
  border-radius: 30px;
  padding-left: ${(props) => props.isImage && '0'} !important;
  /* background: ${(props) => `${props.bgColor}`}; */
  ${(props) => {
    if (props.disabled) {
      return `
    background: ${props.theme.colors.neutral.n2} !important;
    `;
    }
    return `
  background: ${props.bgColor};
  `;
  }}
  font-family: ${(props) => props.theme.typography.fontFamily.primary};
  font-size: ${(props) => props.theme.typography.fontSize.f3};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) =>
    props.disabled
      ? `${props.theme.colors.neutral.n6} !important`
      : props.theme.colors.text};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  ${(props) => {
    if (props.type === 'selectTags' && props.isSelected) {
      return `
        display: inline-flex;
        align-items: center;
        color: ${props.theme.colors.white} !important;
        background: ${props.theme.colors.neutral.n11} !important;
        margin: 0 10px 8px 0;
        `;
    }
    if (props.type === 'selectTags') {
      return `
        margin: 0 10px 8px 0;
    `;
    }
    if (props.type === 'addTags') {
      return `
    display: flex;
    align-items: center;
    width: max-content;
    height: 28px;
    margin: 0 10px 8px 0;

    & > span {
      border-radius: 50%;

      &:last-child{
        display:flex;
      }
    }
`;
    }
  }}
`;

export const TextWrapper = styled.span<IProps>`
  margin: 0 6px;
  color: ${(props) => props.color};
`;

export const IconWrapper = styled.span<IProps>`
  cursor: pointer;
  display: ${(props) => props.type === 'selectTags' && 'flex'};
`;

export const EmptyUserPhoto = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: ${(props) => props.theme.colors.blueLight};
  display: flex;
  justify-content: center;
  align-items: center;
`;
