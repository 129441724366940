/* eslint-disable react/prop-types */
import React from 'react';
import { style } from '../../../utils/constants/style';

export const MyProfileIcon = ({
  colorStroke = '#1C1F29',
}: {
  colorStroke?: string | undefined;
}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0068 10.667C9.21571 10.667 8.44235 10.4324 7.78456 9.99287C7.12676 9.55334 6.61407 8.92863 6.31132 8.19772C6.00857 7.46682 5.92936 6.66255 6.0837 5.88663C6.23804 5.11071 6.619 4.39798 7.17841 3.83857C7.73782 3.27916 8.45055 2.89819 9.22647 2.74385C10.0024 2.58951 10.8067 2.66873 11.5376 2.97148C12.2685 3.27423 12.8932 3.78692 13.3327 4.44471C13.7722 5.10251 14.0068 5.87587 14.0068 6.66699C14.0068 7.72786 13.5854 8.74527 12.8353 9.49542C12.0851 10.2456 11.0677 10.667 10.0068 10.667ZM10.0068 4.00033C9.47942 4.00033 8.96385 4.15672 8.52532 4.44974C8.08678 4.74276 7.74499 5.15923 7.54316 5.6465C7.34132 6.13377 7.28852 6.66995 7.39141 7.18723C7.4943 7.70451 7.74828 8.17967 8.12122 8.55261C8.49416 8.92555 8.96931 9.17952 9.48659 9.28242C10.0039 9.38531 10.5401 9.3325 11.0273 9.13067C11.5146 8.92884 11.9311 8.58704 12.2241 8.14851C12.5171 7.70998 12.6735 7.19441 12.6735 6.66699C12.6735 5.95975 12.3925 5.28147 11.8925 4.78137C11.3924 4.28128 10.7141 4.00033 10.0068 4.00033Z"
      fill="#8D8D8D"
    />
    <path
      d="M10.0069 20.0003C8.52855 19.9994 7.06879 19.6706 5.73273 19.0377C4.39667 18.4048 3.21755 17.4836 2.28026 16.3403L1.93359 15.9137L2.28026 15.4937C3.21834 14.3519 4.3978 13.4322 5.73382 12.8007C7.06984 12.1693 8.5292 11.8418 10.0069 11.8418C11.4846 11.8418 12.944 12.1693 14.28 12.8007C15.616 13.4322 16.7955 14.3519 17.7336 15.4937L18.0803 15.9137L17.7336 16.3403C16.7963 17.4836 15.6172 18.4048 14.2811 19.0377C12.9451 19.6706 11.4853 19.9994 10.0069 20.0003ZM3.68026 15.9203C4.49108 16.7884 5.47168 17.4804 6.56117 17.9536C7.65066 18.4268 8.82579 18.6709 10.0136 18.6709C11.2014 18.6709 12.3765 18.4268 13.466 17.9536C14.5555 17.4804 15.5361 16.7884 16.3469 15.9203C15.5361 15.0523 14.5555 14.3603 13.466 13.8871C12.3765 13.4139 11.2014 13.1698 10.0136 13.1698C8.82579 13.1698 7.65066 13.4139 6.56117 13.8871C5.47168 14.3603 4.49108 15.0523 3.68026 15.9203Z"
      fill="#8D8D8D"
    />
    <path
      d="M10.0067 20C7.73517 20.0015 5.53083 19.2296 3.75647 17.8114C1.9821 16.3932 0.743444 14.4131 0.244365 12.1971C-0.254714 9.98113 0.0155245 7.66125 1.0106 5.6193C2.00568 3.57736 3.6663 1.93503 5.71914 0.96263C7.77199 -0.00976951 10.0947 -0.254295 12.3051 0.269299C14.5154 0.792892 16.4816 2.0534 17.8801 3.84337C19.2786 5.63333 20.026 7.84609 19.9993 10.1174C19.9726 12.3888 19.1735 14.5834 17.7333 16.34C16.796 17.4833 15.6169 18.4045 14.2809 19.0374C12.9448 19.6702 11.485 19.999 10.0067 20ZM10.0067 1.33334C8.29257 1.33334 6.61696 1.84163 5.19173 2.79394C3.76651 3.74624 2.65568 5.09979 1.99972 6.68341C1.34376 8.26704 1.17213 10.0096 1.50654 11.6908C1.84094 13.372 2.66636 14.9162 3.87842 16.1283C5.09047 17.3403 6.63472 18.1657 8.31589 18.5001C9.99706 18.8345 11.7396 18.6629 13.3233 18.007C14.9069 17.351 16.2604 16.2402 17.2127 14.8149C18.165 13.3897 18.6733 11.7141 18.6733 10C18.6733 7.70146 17.7602 5.49706 16.1349 3.87175C14.5096 2.24643 12.3052 1.33334 10.0067 1.33334Z"
      fill="#8D8D8D"
    />
  </svg>
);
