import { useState } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { InputDescriptionField } from 'components';
import Input from 'components/common/Input';
import {
  ArrowRight,
  MailIcon,
  PhoneIcon,
  WorldIcon,
} from 'public/assets/icons';
import CreateEventSwitcherSettings from 'containers/CreateEventSwitcherSettings';
import AttendanceManagementDrawer from 'containers/AttendanceManagementDrawer';
import { composeValidators, required } from 'utils/validators';
import { IStepTwo } from './ICreateEventPostForm';
import EventBrands from './EventBrands';
import MyCollaborators from './MyCollaborators';
import Routes from './Routes';

import {
  AttendanceBlock,
  AttendanceBlockLeft,
  AttendanceBlockRight,
  AttendanceBlockText,
  AttendanceWrapper,
  AttendanceWrapperLeft,
  InputsContainers,
  SectionWrapper,
  SelectorWrapper,
  SeparateSettingsHeader,
} from './CreateEventPostForm.styles';

const StepTwo = ({ form, initialValues }: IStepTwo) => {
  const { t } = useTranslation();

  const [isAttendanceDrawerOpen, SetIsAttendanceDrawerOpen] =
    useState<boolean>(false);
  const [attendanceManagement, setAttendanceManagement] = useState({
    title: null,
    text: null,
    attenderManagement: null,
  });

  return (
    <>
      <SectionWrapper pt={20} pb={17.5}>
        <InputDescriptionField
          placeholder={t('event:addEventDetails')}
          label={t('event:eventDetails')}
          name="eventDetails"
          // onChange={onChange}
          showCharactersNumber
          maxLength={3000}
          height={122}
          validate={composeValidators(required(t('error:thisFieldIsRequired')))}
        />

        <SelectorWrapper>
          <EventBrands />
          <MyCollaborators />
        </SelectorWrapper>

        <InputsContainers>
          <Input
            id="contactPhone"
            name="contactPhone"
            type="text"
            label={t('event:contactPhone')}
            prefix={<PhoneIcon />}
          />
          <Input
            id="contactEmail"
            name="contactEmail"
            type="text"
            label={t('event:contactEmail')}
            prefix={<MailIcon />}
          />
          <Input
            id="contactWebsite"
            name="contactWebsite"
            type="text"
            label={t('event:eventWebpageOrRouteLink')}
            prefix={<WorldIcon />}
          />
        </InputsContainers>
        <SelectorWrapper>
          <Routes />
        </SelectorWrapper>
      </SectionWrapper>
      <SectionWrapper>
        <Field name="eventSettings">
          {({ input }) => {
            return (
              <CreateEventSwitcherSettings
                eventSettings={input?.value}
                setEventSettings={input?.onChange}
                initialValues={initialValues}
                form = {form}
              />
            );
          }}
        </Field>
      </SectionWrapper>
      <SectionWrapper>
        <Field name="attenderManagement">
          {() => {
            if (attendanceManagement?.attenderManagement) {
              form.change(
                'attenderManagement',
                attendanceManagement?.attenderManagement
              );
            }
            return (
              <AttendanceWrapper
                onClick={() => SetIsAttendanceDrawerOpen(true)}
              >
                <AttendanceWrapperLeft>
                  <SeparateSettingsHeader type="h3" mb={8}>
                    {t('event:attendanceManagement:heading')}
                  </SeparateSettingsHeader>
                  <AttendanceBlockRight>
                    <ArrowRight />
                  </AttendanceBlockRight>
                </AttendanceWrapperLeft>
                {attendanceManagement?.text && (
                  <AttendanceBlock>
                    <AttendanceBlockLeft>
                      <SeparateSettingsHeader type="h4" fs={15} fw={500}>
                        {attendanceManagement?.title &&
                          attendanceManagement?.title}
                      </SeparateSettingsHeader>
                      <AttendanceBlockText>
                        {attendanceManagement?.text &&
                          attendanceManagement?.text}
                      </AttendanceBlockText>
                    </AttendanceBlockLeft>
                  </AttendanceBlock>
                )}
              </AttendanceWrapper>
            );
          }}
        </Field>
      </SectionWrapper>
      <AttendanceManagementDrawer
        open={isAttendanceDrawerOpen}
        setOpen={SetIsAttendanceDrawerOpen}
        setAttendanceManagement={setAttendanceManagement}
      />
    </>
  );
};

export default StepTwo;
