import React, { useState } from 'react';
import Image from 'next/image';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { style } from 'utils/constants/style';
import { DEFAULT_LINK_POST_PLACEHOLDER_IMAGE } from 'utils/constants';
import { INotificationItem } from './INotificationItem';
import {
  Wrapper,
  IconWrap,
  NotificationInfoWrap,
  DateText,
  RightIconWrap,
  ButtonsWrap,
  ButtonWrap,
  NotificationContainer,
} from './NotificationItem.style';
import { RectangleButton, EmptyUserProfilePhoto } from '../../index';

const NotificationItem = ({
  leftIcon,
  profilePhoto,
  text,
  headerText,
  date,
  rightPhoto,
  onClick,
  onLeftIconClick,
  onRightPhotoClick,
  paginationRef,
  withActions,
  isNotificationClickable,
}: INotificationItem) => {
  const [accepted, setAccepted] = useState(false);
  const [declined, setDeclined] = useState(false);

  const { t } = useTranslation();

  const handleAcceptRequest = () => {
    setAccepted(true);
    setDeclined(false);
  };
  const handleDeclineRequest = () => {
    setAccepted(false);
    setDeclined(true);
  };

  const renderLeftImage = () => {
    if (leftIcon) {
      return leftIcon;
    }

    return profilePhoto && profilePhoto.trim() ? (
      <Image
        loader={() => profilePhoto}
        width={24}
        height={24}
        src={profilePhoto}
        alt="profile"
        objectFit="cover"
        unoptimized
        priority
      />
    ) : (
      <EmptyUserProfilePhoto
        wrapSize={24}
        userIconSize={12}
        withBorder={false}
        cursor="pointer"
      />
    );
  };

  const getImgSrc = (img) =>
    img === DEFAULT_LINK_POST_PLACEHOLDER_IMAGE
      ? '/assets/img/tireTrace.png'
      : img;

  return (
    <Wrapper
      onClick={onClick}
      ref={paginationRef}
      isClickable={isNotificationClickable}
    >
      <NotificationContainer>
        <IconWrap
          onClick={(e) => {
            e.stopPropagation();
            onLeftIconClick();
          }}
        >
          {renderLeftImage()}
        </IconWrap>
        <NotificationInfoWrap isRightPhoto={Boolean(rightPhoto)}>
          {headerText}
          {text}
          <DateText>{format(new Date(date), 'PP')}</DateText>
          {withActions && (
            <ButtonsWrap>
              {!declined && (
                <ButtonWrap width={accepted ? 103 : 93}>
                  <RectangleButton
                    text={
                      accepted
                        ? t('notifications:acceptedUppercase')
                        : t('notifications:acceptUppercase')
                    }
                    height="32px"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAcceptRequest();
                    }}
                    backgroundColor={
                      accepted
                        ? style.mainBlue.aliceBlueColor
                        : style.mainBlue.blue
                    }
                    color={
                      accepted ? style.mainBlue.blue : style.mainWhite.white
                    }
                  />
                </ButtonWrap>
              )}
              {!accepted && (
                <ButtonWrap width={declined ? 103 : 93}>
                  <RectangleButton
                    text={
                      declined
                        ? t('notifications:declinedUppercase')
                        : t('notifications:declineUppercase')
                    }
                    height="32px"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeclineRequest();
                    }}
                    backgroundColor={
                      declined
                        ? style.mainGray.whisperColor
                        : style.mainGray.whisperColor
                    }
                    color={
                      declined
                        ? style.mainGray.zambeziColor
                        : style.mainBlack.black
                    }
                  />
                </ButtonWrap>
              )}
            </ButtonsWrap>
          )}
        </NotificationInfoWrap>
      </NotificationContainer>
      {rightPhoto && !withActions && (
        <RightIconWrap
          onClick={(e) => {
            e.stopPropagation();
            onRightPhotoClick();
          }}
        >
          <Image
            loader={() => getImgSrc(rightPhoto)}
            width={53}
            height={53}
            src={getImgSrc(rightPhoto)}
            alt="cover photo"
            objectFit="cover"
            unoptimized
            priority
          />
        </RightIconWrap>
      )}
    </Wrapper>
  );
};

export default NotificationItem;
