import Card from 'components/common/Card';

import styled from 'styled-components';

export const ProductMainCardSkeletonWrapper = styled(Card)`
  height: auto;
  width: 100%;
  max-width: 550px;
  margin-bottom: 16px;
  overflow: hidden;
  @media (max-width: 861px) {
    display: block;
    margin: 0 auto 16px;
  }
`;

export const ProductMainCardSkeletonContent = styled.div`
  padding: 16px 16px 0 16px;
`;

export const ProductMainCardSkeletonFooter = styled.div`
  padding: 16px;
  display: flex;
  align-items: center;
  & div {
    &:not(:last-child) {
      margin-right: 8px;
    }
    &:last-child {
      margin-left: auto;
    }
  }
`;
