import React from 'react';

export const CalendarChatIcon = ({ size = '16' }: { size?: string }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.49961 6.5V4.83359H3L3 8H13V4.83359H10.5004V6.5H9.50039V4.83359H6.49961V6.5H5.49961ZM9.50039 3.83359H6.49961V2.5H5.49961V3.83359H3C2.44772 3.83359 2 4.28131 2 4.8336V13.5003C2 14.0525 2.44772 14.5003 3 14.5003H13C13.5523 14.5003 14 14.0525 14 13.5003V4.83359C14 4.28131 13.5523 3.83359 13 3.83359H10.5004V2.5H9.50039V3.83359ZM3 9L3 13.5003L13 13.5003V9H3Z"
      fill="white"
    />
  </svg>
);
