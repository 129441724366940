import React from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { style } from 'utils/constants/style';
import { IEventCard } from './IEventCard';
import {
  CardWrap,
  BackgroundImage,
  UserDataWrap,
  EventNameText,
  EventDetailsWrap,
  Text,
  CircleWrap,
  Circle,
  EventInfoWrap,
} from './EventCard.style';

export const EventCard = ({
  id,
  coverPhoto,
  username,
  userProfilePhoto,
  eventName,
  date,
  onClick,
  distance,
  paginationRef,
  width,
  lastCardInLine,
}: IEventCard) => {
  const { t } = useTranslation();

  return (
    <CardWrap
      onClick={onClick}
      ref={paginationRef}
      width={width}
      lastCardInLine={lastCardInLine}
    >
      <BackgroundImage
        img={coverPhoto}
        role="img"
        aria-label="event card cover img"
      />
      <EventDetailsWrap>
        <EventNameText>{eventName}</EventNameText>
        {!!username?.length && (
          <UserDataWrap>
            <Text>{t('event:eventBy')}</Text>
            <Text isClickable color={style.mainBlack.black} ml={4}>
              {username}
            </Text>
          </UserDataWrap>
        )}

        <EventInfoWrap>
          <Text>{format(new Date(date), 'ccc dd LLL p')}</Text>
          {!!distance?.length && (
            <>
              <CircleWrap>
                <Circle />
              </CircleWrap>
              <Text color={style.mainBlue.blue}>{distance}</Text>
            </>
          )}
        </EventInfoWrap>
      </EventDetailsWrap>
    </CardWrap>
  );
};
