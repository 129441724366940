import React from 'react'

const ArrowDownBlack = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.99902 9L11.999 15L17.999 9" stroke="#1C1F29" strokeWidth="1.4" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default ArrowDownBlack