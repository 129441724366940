import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  mt?: number;
  mb?: number;
  mr?: number;
  ml?: number;
  pt?: number;
  pb?: number;
  fw?: number;
  isSelectedStep?: boolean;
  selected?: boolean;
}

export const Wrapper = styled.div`
  padding-top: 0;
`;

export const StepNumberWrap = styled.div`
  display: flex;
  justify-content: center;
`;

export const StepNumber = styled.div<IProps>`
  height: 4px;
  width: 24px;
  border-radius: 4px;
  margin-right: ${({ mr }) => (mr ? `${mr}px` : '0')};
  background-color: ${({ isSelectedStep }) =>
    isSelectedStep ? style.mainYellow.yellow : style.mainGray.whisperColor};
`;

export const SectionWrapper = styled.div<IProps>`
  padding-top: ${({ pt }) => (pt ? `${pt}px` : '20px')};
  padding-bottom: ${({ pb }) => (pb ? `${pb}px` : '16.5px')};
  border-bottom: 1px dashed ${style.mainGray.whisperColor};

  :last-of-type {
    border: none;
  }
`;

export const SeparateSettingsHeader = styled.h3<IProps>`
  font-size: 16px;
  line-height: 150%;
  font-weight: ${({ fw }) => fw || '600'};
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
`;

export const SwitcherSettingWrap = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
`;

export const Text = styled.p<IProps>`
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: ${style.mainGray.SuvaGrey};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
`;

export const SelectWrapper = styled.div`
  position: relative;
  height: 44px;
  width: 100%;
  max-width: 260px;
`;

export const TimeZoneText = styled.div`
  height: 44px;
  padding: 0 16px 0 38px;
  border-radius: 4px;
  background-color: ${style.mainGray.whiteSmokeColor};
  color: ${style.mainBlack.black};
  font-size: 15px;
  line-height: 44px;
  letter-spacing: 0.02em;
  border: none;
  outline: none;
  cursor: pointer;
  appearance: none;
  font-family: 'Poppins';
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
`;

export const Select = styled.select`
  height: 44px;
  width: 100%;
  max-width: 260px;
  padding: 0 16px 0 38px;
  border-radius: 4px;
  background-color: transparent;
  color: transparent;
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.02em;
  border: none;
  outline: none;
  cursor: pointer;
  appearance: none;
  font-family: 'Poppins';
`;

export const SelectIconWrap = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 10px;
  left: 8px;
`;

export const EventTypesWrap = styled.div`
  width: 100%;
  position: flex;
  flex-direction: column;
`;

export const EventTypesHeaderWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const EventTypesHeaderText = styled.p`
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.02em;
  font-weight: 500;
  color: ${style.mainBlack.black};
`;

export const EventTypesList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const EventTypeItemWrap = styled.div<IProps>`
  height: 50px;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: ${({ selected }) =>
    selected ? style.mainBlue.blue : 'transparent'};
  border: ${({ selected }) =>
    selected
      ? `1px solid ${style.mainBlue.blue}`
      : `1px solid ${style.mainGray.gray}`};
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 0 8px 8px 0;
`;

export const EventTypeText = styled.p<IProps>`
  font-size: 11px;
  line-height: 100%;
  letter-spacing: 0.02em;
  font-weight: 500;
  color: ${({ selected }) =>
    selected ? style.mainWhite.alabaster : style.mainGray.spunPearl};
  margin-top: 2px;
`;

export const ErrorMessage = styled.p<IProps>`
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${style.mainRed.cinnabarColor};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
`;

export const TagImageWrap = styled.div`
  height: 26px;
  width: 26px;
  border-radius: 50%;
  overflow: hidden;
`;

export const LocationText = styled.p`
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  margin: 14px 0 11px 8px;
`;
