import { useTranslation } from 'react-i18next';

import Button from 'components/common/Button';
import Image from 'components/common/Image';
import EmptyUserProfilePhoto from 'components/EmptyUserProfilePhoto/EmptyUserProfilePhoto';
import { IViewProfile } from './IViewProfile';

import {
  FullName,
  ProfileImage,
  UserName,
  ViewProfileContent,
  ViewProfileWrapper,
} from './ViewProfile.styles';

const ViewProfile = ({
  userImage,
  userName,
  fullName,
  onMyProfileClick,
}: IViewProfile) => {
  const { t } = useTranslation();

  return (
    <ViewProfileWrapper>
      <ProfileImage>
        {userImage ? (
          <Image
            src={userImage}
            alt="user image"
            width={90}
            height={90}
            unoptimized
            priority
          />
        ) : (
          <EmptyUserProfilePhoto
            wrapSize={88}
            userIconSize={48}
            cursor="pointer"
          />
        )}
      </ProfileImage>

      <ViewProfileContent>
        <UserName>{userName}</UserName>
        <FullName>{fullName}</FullName>
        <Button variant="secondary" onClick={onMyProfileClick}>
          {t('menu:viewProfileBtn')}
        </Button>
      </ViewProfileContent>
    </ViewProfileWrapper>
  );
};

export default ViewProfile;
