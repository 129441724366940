import styled from 'styled-components';

import { style } from 'utils/constants/style';

export const VehiclesItemWrap = styled.div`
  width: 100%;
  height: 80px;
  border-bottom: 1px solid ${style.mainGray.whiteSmokeColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ImageWrap = styled.div`
  border-radius: 50%;
  width: 48px;
  height: 48px;
  position: absolute;
  overflow: hidden;
  img {
    object-fit: cover;
  }
`;

export const EmptyVehicleImg = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #e8f3ff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const VehicleNameWrap = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  left: 60px;
  span {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0.02em;
    color: ${style.mainGray.SuvaGrey};
  }
`;

export const VehicleName = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: ${style.mainBlack.black};
`;

export const ButtonWrap = styled.div`
  width: 93px;
  position: relative;
  z-index: 100;
`;
