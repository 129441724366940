import styled from 'styled-components';

export const PublicHeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  height: 56px;
  padding: 10px 24px;
  background: ${(props) => props.theme.colors.white};
  box-sizing: border-box;

  @media (max-width: 480px) {
    display: none;
  }
`;
