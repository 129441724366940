/* eslint-disable no-shadow */
// import socketIO from 'socket.io-client';

import { io } from 'socket.io-client';

import { SOCKET_EVENTS } from './constants/socketEvents';

const Pages = {
  chatsList: 'chatList',
  chat: 'chat',
};

let socket;
let currentScreen = Pages.chatsList;
let chatId = '';
let userId = '';
let onNewMessage = (chatMessage: any) => {};

const getSocket = () => socket;

const setCurrentScreen = (screen) => {
  currentScreen = screen;
};

const setChatId = (id) => (chatId = id);

const setUserId = (id) => (userId = id);

const setOnNewMessage = (callback) => (onNewMessage = callback);

const create = (token) => {
  socket = io(`${process.env._CHAT_BASE_API_URL}/?tsec=${token}`);
};

const ackMessageFromList = (chatId) => {
  socket.emit(SOCKET_EVENTS.IS_NOTIFIED, { chatId }, (data) => {
    console.log('ackMessageFromList data', data);
  });
};

const ackMessage = (chatId) => {
  socket.emit(SOCKET_EVENTS.IS_READ, { chatId }, (data) => {
    console.log('ackMessage data', data);
  });
};

const joinRoom = ({ chatId }) => {
  socket.emit(SOCKET_EVENTS.JOIN_ROOM_EVENT, { chatId }, (data) => {
    console.log('-----');
    console.log('joined room:', { chatId });
    console.log('data: ', data);
  });
};

const isNotConnected = () => socket.disconnected;

const sendMessage = ({ chatId, message }, callback) => {
  socket.emit(SOCKET_EVENTS.SEND_MESSAGE_EVENT, { chatId, message }, (data) => {
    callback(data);
    console.log('hi');
  });
};

const deleteChat = ({ chatId }, callback) => {
  socket.emit(SOCKET_EVENTS.LEFT_ROOM_EVENT, { chatId }, (data) => {
    console.log('socket data', data, chatId);
    if (data.ok) {
      callback();
    }
  });
};

const deleteMessage = ({ chatId, messageId }, callback) => {
  socket.emit(SOCKET_EVENTS.UNSEND, { chatId, messageId }, (data) => {
    if (data.ok) {
      callback();
    }
  });
};

const callNewMessageObservers = (chatMessage) => {
  if (
    currentScreen === Pages.chatsList ||
    (currentScreen === Pages.chat &&
      chatId === chatMessage.to &&
      chatMessage.from !== userId)
  ) {
    onNewMessage(chatMessage);
  }
};

const sendAck = (args) => {
  if (args != null && args !== undefined) {
    const chatId = args.to;
    if (currentScreen === Pages.chatsList) {
      ackMessageFromList(chatId);
    } else {
      ackMessage(chatId);
    }
  }
};

const setNewMessageListener = () => {
  socket.on(SOCKET_EVENTS.NEW_MESSAGE_EVENT, (args) => {
    /**
     * args:
     * {
     *    _id: '',
     *    datetime: '',
     *    from: '',
     *    message: '',
     *    profilePhoto: '',
     *    to: '',
     *    username: '',
     *    media: ?
     * }
     */
    sendAck(args);

    console.log('args', args);

    const chatMessage = args; // same as chatMessage object of ChatScreen/handleOnSend function
    callNewMessageObservers(chatMessage);
  });
};

const connect = (callback) => {
  socket.on(SOCKET_EVENTS.CONNECT, () => {
    socket.off(SOCKET_EVENTS.NEW_MESSAGE_EVENT);

    // call callback function
    if (currentScreen === Pages.chatsList) {
      callback(false); // = getChats(false)
    } else {
      callback(chatId, false); // = getMessages(id, false)
    }

    // call setNewMessageListener
    setNewMessageListener();

    // call setMediaUploadedListener
  });

  socket.connect();
};

const disconnect = () => {
  socket.disconnect();
};

export {
  getSocket,
  create,
  connect,
  isNotConnected,
  sendMessage,
  setCurrentScreen,
  setChatId,
  setUserId,
  setOnNewMessage,
  deleteChat,
  deleteMessage,
  joinRoom,
  disconnect,
};
