export const actionTypes = {
  OPEN_SIDE_BAR: 'OPEN_SIDE_BAR',
  OPEN_SIDE_BAR_FAILED: 'OPEN_SIDE_BAR_FAILED',
  OPEN_SIDEBAR_EDIT_PROFILE: 'OPEN_SIDEBAR_EDIT_PROFILE',
  CLOSE_SIDEBAR_EDIT_PROFILE: 'CLOSE_SIDEBAR_EDIT_PROFILE',
  OPEN_SIDEBAR_CREATE_LINK_POST: 'OPEN_SIDEBAR_CREATE_LINK_POST',
  CLOSE_SIDEBAR_CREATE_LINK_POST: 'CLOSE_SIDEBAR_CREATE_LINK_POST',
  OPEN_SIDEBAR_CREATE_PHOTO_POST: 'OPEN_SIDEBAR_CREATE_PHOTO_POST',
  CLOSE_SIDEBAR_CREATE_PHOTO_POST: 'CLOSE_SIDEBAR_CREATE_PHOTO_POST',
  OPEN_SIDEBAR_ADD_VEHICLE: 'OPEN_SIDEBAR_ADD_VEHICLE',
  CLOSE_SIDEBAR_ADD_VEHICLE: 'CLOSE_SIDEBAR_ADD_VEHICLE',
  OPEN_SIDEBAR_ADD_VEHICLE_TO_GROUP: 'OPEN_SIDEBAR_ADD_VEHICLE_TO_GROUP',
  CLOSE_SIDEBAR_ADD_VEHICLE_TO_GROUP: 'CLOSE_SIDEBAR_ADD_VEHICLE_TO_GROUP',
  OPEN_SIDEBAR_CREATE_EVENT: 'OPEN_SIDEBAR_CREATE_EVENT',
  CLOSE_SIDEBAR_CREATE_EVENT: 'CLOSE_SIDEBAR_CREATE_EVENT',
  OPEN_SIDEBAR_TAG: 'OPEN_SIDEBAR_TAG',
  CLOSE_SIDEBAR_TAG: 'CLOSE_SIDEBAR_TAG',
  OPEN_SIDEBAR_TAG_WITH_ADDING: 'OPEN_SIDEBAR_TAG_WITH_ADDING',
  CLOSE_SIDEBAR_TAG_WITH_ADDING: 'CLOSE_SIDEBAR_TAG_WITH_ADDING',
  OPEN_SIDEBAR_COMMENTS: 'OPEN_SIDEBAR_COMMENTS',
  CLOSE_SIDEBAR_COMMENTS: 'CLOSE_SIDEBAR_COMMENTS',
  OPEN_SIDEBAR_NOTIFICATIONS: 'OPEN_SIDEBAR_NOTIFICATIONS',
  CLOSE_SIDEBAR_NOTIFICATIONS: 'CLOSE_SIDEBAR_NOTIFICATIONS',
  ADD_TAGS: 'ADD_TAGS',
  REMOVE_TAG: 'REMOVE_TAG',
  SCROLL_TO_TOP: 'SCROLL_TO_TOP',
  OPEN_SIDEBAR_PENDING_APPROVALS: 'OPEN_SIDEBAR_PENDING_APPROVALS',
  CLOSE_SIDEBAR_PENDING_APPROVALS: 'CLOSE_SIDEBAR_PENDING_APPROVALS',
  OPEN_SIDEBAR_ADD_USER_GROUP: 'OPEN_SIDEBAR_ADD_USER_GROUP',
  CLOSE_SIDEBAR_ADD_USER_GROUP: 'CLOSE_SIDEBAR_ADD_USER_GROUP',
  CLEAR_SIDEBAR_STORE: 'CLEAR_SIDEBAR_STORE',
  OPEN_SIDEBAR_ATTEND_EVENT: 'OPEN_SIDEBAR_ATTEND_EVENT',
  CLOSE_SIDEBAR_ATTEND_EVENT: 'CLOSE_SIDEBAR_ATTEND_EVENT',
  OPEN_SIDEBAR_INVITE_EVENT: 'OPEN_SIDEBAR_INVITE_EVENT',
  CLOSE_SIDEBAR_INVITE_EVENT: 'CLOSE_SIDEBAR_INVITE_EVENT',
  OPEN_SIDEBAR_MAP_FILTER: 'OPEN_SIDEBAR_MAP_FILTER',
  CLOSE_SIDEBAR_MAP_FILTER: 'CLOSE_SIDEBAR_MAP_FILTER',
  APLLY_FILTER_MAP: 'APLLY_FILTER_MAP',
  ATTEND_SUCCESS: 'ATTEND_SUCCESS',
  CANCEL_ATTEND_SUCCESS: 'CANCEL_ATTEND_SUCCESS',
  OPEN_SIDEBAR_CREATE_GROUP: 'OPEN_SIDEBAR_CREATE_GROUP',
  CLOSE_SIDEBAR_CREATE_GROUP: 'CLOSE_SIDEBAR_CREATE_GROUP',
  OPEN_SIDEBAR_CHOOSE_LOCATION: 'OPEN_SIDEBAR_CHOOSE_LOCATION',
  CLOSE_SIDEBAR_CHOOSE_LOCATION: 'CLOSE_SIDEBAR_CHOOSE_LOCATION',
  OPEN_SIDEBAR_SUGGESTIONS: 'OPEN_SIDEBAR_SUGGESTIONS',
  CLOSE_SIDEBAR_SUGGESTIONS: 'CLOSE_SIDEBAR_SUGGESTIONS',
  OPEN_SIDEBAR_LIKES: 'OPEN_SIDEBAR_LIKES',
  CLOSE_SIDEBAR_LIKES: 'CLOSE_SIDEBAR_LIKES',
  OPEN_SIDEBAR_INVOLVED_USERS: 'OPEN_SIDEBAR_INVOLVED_USERS',
  CLOSE_SIDEBAR_INVOLVED_USERS: 'CLOSE_SIDEBAR_INVOLVED_USERS',

  OPEN_SIDEBAR_CHATS_LIST: 'OPEN_SIDEBAR_CHATS_LIST',
  CLOSE_SIDEBAR_CHATS_LIST: 'CLOSE_SIDEBAR_CHATS_LIST',
  OPEN_SIDEBAR_CHAT_CONVERSATION: 'OPEN_SIDEBAR_CHAT_CONVERSATION',
  CLOSE_SIDEBAR_CHAT_CONVERSATION: 'CLOSE_SIDEBAR_CHAT_CONVERSATION',
  OPEN_SIDEBAR_NEW_CHAT: 'OPEN_SIDEBAR_NEW_CHAT',
  CLOSE_SIDEBAR_NEW_CHAT: 'CLOSE_SIDEBAR_NEW_CHAT',
  OPEN_SIDEBAR_NEW_MULTIPLE_CHAT: 'OPEN_SIDEBAR_NEW_MULTIPLE_CHAT',
  CLOSE_SIDEBAR_NEW_MULTIPLE_CHAT: 'CLOSE_SIDEBAR_NEW_MULTIPLE_CHAT',
};

export const openSideBar = (data) => ({
  type: actionTypes.OPEN_SIDE_BAR,
  data,
});

export function failureOpenSideBar(error) {
  return {
    type: actionTypes.OPEN_SIDE_BAR_FAILED,
    error,
  };
}

// EDIT PROFILE SIDEBAR ACTIONS
export function openSideBarEditProfile(data) {
  return { type: actionTypes.OPEN_SIDEBAR_EDIT_PROFILE, data };
}

export function closeSideBarEditProfile(data) {
  return { type: actionTypes.CLOSE_SIDEBAR_EDIT_PROFILE, data };
}

// CREATE LINK POST SIDEBAR ACTIONS
export function openSideBarCreateLinkPost({
  postForEditData,
  defaultTagId,
}: {
  postForEditData?: {};
  defaultTagId?: string;
}) {
  return {
    type: actionTypes.OPEN_SIDEBAR_CREATE_LINK_POST,
    data: { postForEditData, defaultTagId },
  };
}

export function closeSideBarCreateLinkPost() {
  return {
    type: actionTypes.CLOSE_SIDEBAR_CREATE_LINK_POST,
  };
}

// CREATE PHOTO POST SIDEBAR ACTIONS
export function openSideBarCreatePhotoPost({
  postForEditData,
  defaultTagId,
}: {
  postForEditData?: {};
  defaultTagId?: string;
}) {
  return {
    type: actionTypes.OPEN_SIDEBAR_CREATE_PHOTO_POST,
    data: { postForEditData, defaultTagId },
  };
}

export function closeSideBarCreatePhotoPost() {
  return {
    type: actionTypes.CLOSE_SIDEBAR_CREATE_PHOTO_POST,
  };
}

// ADD VEHICLE SIDEBAR ACTIONS
export function openSideBarAddVehicle(data) {
  return {
    type: actionTypes.OPEN_SIDEBAR_ADD_VEHICLE,
    data,
  };
}

export function closeSideBarAddVehicle() {
  return {
    type: actionTypes.CLOSE_SIDEBAR_ADD_VEHICLE,
  };
}

// ADD VEHICLE TO GROUP SIDEBAR ACTIONS
export function openSideBarAddVehicleToGroup(data) {
  return {
    type: actionTypes.OPEN_SIDEBAR_ADD_VEHICLE_TO_GROUP,
    data,
  };
}

export function closeSideBarAddVehicleToGroup() {
  return {
    type: actionTypes.CLOSE_SIDEBAR_ADD_VEHICLE_TO_GROUP,
  };
}

// CREATE EVENT SIDEBAR ACTIONS
export function openSidebarCreateEvent(data?: {}) {
  return {
    type: actionTypes.OPEN_SIDEBAR_CREATE_EVENT,
    data,
  };
}

export function closeSidebarCreateEvent() {
  return {
    type: actionTypes.CLOSE_SIDEBAR_CREATE_EVENT,
  };
}

// TAG SIDEBAR ACTIONS
export function openSidebarTag(data) {
  return {
    type: actionTypes.OPEN_SIDEBAR_TAG,
    data,
  };
}

export function closeSidebarTag(data) {
  return {
    type: actionTypes.CLOSE_SIDEBAR_TAG,
    data,
  };
}

// TAG SIDEBAR WITH ADDING ACTIONS
export function openSidebarTagWithAdding(data) {
  return {
    type: actionTypes.OPEN_SIDEBAR_TAG_WITH_ADDING,
    data,
  };
}
// OPEN CLOSE COMENTS SIDEBAR
export function openSidebarComments(data) {
  return {
    type: actionTypes.OPEN_SIDEBAR_COMMENTS,
    data,
  };
}

export function closeSidebarTagWithAdding(data) {
  return {
    type: actionTypes.CLOSE_SIDEBAR_TAG_WITH_ADDING,
    data,
  };
}

export function closeSidebarComments(data) {
  return {
    type: actionTypes.CLOSE_SIDEBAR_COMMENTS,
    data,
  };
}

// NOTIFICATIONS SIDEBAR ACTIONS
export function openSidebarNotifications() {
  return {
    type: actionTypes.OPEN_SIDEBAR_NOTIFICATIONS,
  };
}

export function closeSidebarNotifications() {
  return {
    type: actionTypes.CLOSE_SIDEBAR_NOTIFICATIONS,
  };
}

// ADD TAGS ACTIONS
export function addTags(data) {
  return {
    type: actionTypes.ADD_TAGS,
    data,
  };
}

export function removeTag(data) {
  return {
    type: actionTypes.REMOVE_TAG,
    data,
  };
}

// SCROLL TO TOP ACTIONS
export function scrollToTop() {
  return {
    type: actionTypes.SCROLL_TO_TOP,
  };
}

// PENDING APPROVALS ACTIONS
export function openSidebarPendingApprovals(data) {
  return {
    type: actionTypes.OPEN_SIDEBAR_PENDING_APPROVALS,
    data,
  };
}

export function closeSidebarPendingApprovals() {
  return {
    type: actionTypes.CLOSE_SIDEBAR_PENDING_APPROVALS,
  };
}

// OPEN SIDEBAR INVITE USER TO GROUP
export function openSidebarInviteUserToGroup(data) {
  return {
    type: actionTypes.OPEN_SIDEBAR_ADD_USER_GROUP,
    data,
  };
}

export function closeSidebarInviteUserToGroup(data) {
  return {
    type: actionTypes.CLOSE_SIDEBAR_ADD_USER_GROUP,
    data,
  };
}

// OPEN SIDEBAR INVITE USER TO GROUP
export function openSidebarAttendEvent(data) {
  return {
    type: actionTypes.OPEN_SIDEBAR_ATTEND_EVENT,
    data,
  };
}

export function closeSidebarAttendEvent() {
  return {
    type: actionTypes.CLOSE_SIDEBAR_ATTEND_EVENT,
  };
}
export function attendSuccess(data) {
  return {
    type: actionTypes.ATTEND_SUCCESS,
    data,
  };
}
export function cancelAttendSuccess(data) {
  return {
    type: actionTypes.CANCEL_ATTEND_SUCCESS,
    data,
  };
}

// OPEN SIDEBAR INVITE USER TO EVENT
export function openSidebarInviteEvent(data) {
  return {
    type: actionTypes.OPEN_SIDEBAR_INVITE_EVENT,
    data,
  };
}

export function closeSidebarInviteEvent() {
  return {
    type: actionTypes.CLOSE_SIDEBAR_INVITE_EVENT,
  };
}

// SIDEBAR CHATS LIST ACTIONS
export const openSidebarChatsList = () => ({
  type: actionTypes.OPEN_SIDEBAR_CHATS_LIST,
});

export const closeSidebarChatsList = () => ({
  type: actionTypes.CLOSE_SIDEBAR_CHATS_LIST,
});

// SIDEBAR CHAT CONVERSATION ACTIONS
export const openSidebarChatConversation = (data) => ({
  type: actionTypes.OPEN_SIDEBAR_CHAT_CONVERSATION,
  data,
});

export const closeSidebarChatConversation = () => ({
  type: actionTypes.CLOSE_SIDEBAR_CHAT_CONVERSATION,
});

// SIDEBAR NEW CHAT
export const openSidebarNewChat = () => ({
  type: actionTypes.OPEN_SIDEBAR_NEW_CHAT,
});

export const closeSidebarNewChat = () => ({
  type: actionTypes.CLOSE_SIDEBAR_NEW_CHAT,
});

// SIDEBAR NEW MULTIPLE CHAT
export const openSidebarNewMultipleChat = (data) => ({
  type: actionTypes.OPEN_SIDEBAR_NEW_MULTIPLE_CHAT,
  data,
});

export const closeSidebarNewMultipleChat = () => ({
  type: actionTypes.CLOSE_SIDEBAR_NEW_MULTIPLE_CHAT,
});

// CLEAR SIDEBAR STORE ACTIONS
export function clearSidebarStore() {
  return {
    type: actionTypes.CLEAR_SIDEBAR_STORE,
  };
}

// SIDEBAR CREATE GROUP
export const openSidebarCreateGroup = (data?: {}) => ({
  type: actionTypes.OPEN_SIDEBAR_CREATE_GROUP,
  data,
});

export const closeSidebarCreateGroup = () => ({
  type: actionTypes.CLOSE_SIDEBAR_CREATE_GROUP,
});

// SIDEBAR CHOOSE LOCATION
export const openSidebarChooseLocation = () => ({
  type: actionTypes.OPEN_SIDEBAR_CHOOSE_LOCATION,
});

export const closeSidebarChooseLocation = () => ({
  type: actionTypes.CLOSE_SIDEBAR_CHOOSE_LOCATION,
});

// SIDEBAR SUGGESTIONS
export const openSidebarSuggestions = () => ({
  type: actionTypes.OPEN_SIDEBAR_SUGGESTIONS,
});

export const closeSidebarSuggestions = () => ({
  type: actionTypes.CLOSE_SIDEBAR_SUGGESTIONS,
});

// SIDEBAR LIKES
export const openSidebarLikes = (data) => ({
  type: actionTypes.OPEN_SIDEBAR_LIKES,
  data,
});

export const closeSidebarLikes = () => ({
  type: actionTypes.CLOSE_SIDEBAR_LIKES,
});

// SIDEBAR INVOLVED USERS
export const openSidebarInvolvedUsers = (data) => ({
  type: actionTypes.OPEN_SIDEBAR_INVOLVED_USERS,
  data,
});

export const closeSidebarInvolvedUsers = () => ({
  type: actionTypes.CLOSE_SIDEBAR_INVOLVED_USERS,
});
