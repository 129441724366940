import React from 'react';

export const DonorIcon = ({ color = '#8D5CF5' }: { color?: string }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0007 3.74072C11.8798 2.02933 14.7838 2.08613 16.5941 3.92574C18.4037 5.76615 18.4661 8.69718 16.7829 10.609L9.99905 17.5L3.21678 10.609C1.53361 8.69718 1.59681 5.76128 3.40558 3.92574C5.21754 2.08857 8.11589 2.0269 10.0007 3.74072Z"
      fill={color}
    />
  </svg>
);
