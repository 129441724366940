// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

Sentry.init({
  dsn:
    process.env._SENTRY_DSN ||
    'https://49145bd11db84873badccc3beb953cfa@o489753.ingest.sentry.io/6116259',
  environment: process.env._ENV,
  tracesSampleRate: 1.0,
});
