import { HYDRATE } from 'next-redux-wrapper';
import { actionTypes } from './actions';

const initialState = {};

function reducer(state: {} = initialState, action) {
  switch (action.type) {
    case HYDRATE: {
      return { ...state, ...action.payload };
    }

    case actionTypes.FAILURE_LOAD_IDENTITY:
      return {
        ...state,
        ...{ error: action.error },
      };

    case actionTypes.LOAD_IDENTITY_SUCCESS:
      return {
        ...state,
        ...{ ...action.data.data },
      };

    default:
      return state;
  }
}

export default reducer;
