/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  MyProfileIcon,
  LogOutIcon,
  SettingsIcon,
  PhotoIcon,
  LinkIcon,
  MeetUpIcon,
  EventsIcon,
  GroupsIcon,
  GarageIcon,
  FavoriteIcon,
} from 'public/assets/icons';
import { routesPath } from 'utils/constants/routePath';
import {
  openSidebarCreateEvent,
  openSideBarCreateLinkPost,
  openSideBarCreatePhotoPost,
} from 'store/sideBarControler/actions';
import { PopoverWrapp, ListUserUserActions, ListItem } from './Popover.style';
import { IPopover } from './IPopover';

export interface IPopoverTemlpates {
  onClickLogOut: () => void;
  onSettingsClick: () => void;
  onMyProfileClick: () => void;
  onMyEventsClick: () => void;
  onMyGroupsClick: () => void;
  onMyGarageClick: () => void;
  onMyFavoriteClick: () => void;
  onClick?: () => void;
}

const PopoverTemlpatesUser = ({
  onClickLogOut,
  onSettingsClick,
  onMyProfileClick,
  onMyEventsClick,
  onMyGroupsClick,
  onMyGarageClick,
  onMyFavoriteClick,
  onClick,
}: IPopoverTemlpates) => {
  const { t } = useTranslation();

  return (
    <ListUserUserActions onClick={onClick}>
      <ListItem onClick={onMyProfileClick}>
        <span>
          <MyProfileIcon />
        </span>
        {t('menu:profileItem')}
      </ListItem>
      <ListItem onClick={onMyEventsClick}>
        <span>
          <EventsIcon />
        </span>
        {t('menu:eventsItem')}
      </ListItem>
      <ListItem onClick={onMyGroupsClick}>
        <span>
          <GroupsIcon />
        </span>
        {t('menu:groupsItem')}
      </ListItem>
      <ListItem onClick={onMyGarageClick}>
        <span>
          <GarageIcon />
        </span>
        {t('menu:garageItem')}
      </ListItem>
      <ListItem onClick={onMyFavoriteClick}>
        <span>
          <FavoriteIcon />
        </span>
        {t('menu:favoritesItem')}
      </ListItem>
      <ListItem onClick={onSettingsClick}>
        <span>
          <SettingsIcon />
        </span>
        {t('profile:settings')}
      </ListItem>
      <ListItem
        onClick={(e) => {
          e.stopPropagation();
          onClickLogOut();
        }}
      >
        <span>
          <LogOutIcon />
        </span>
        {t('settings:logOut')}
      </ListItem>
    </ListUserUserActions>
  );
};

export interface IPopoverTemlpatesCreateAction {
  onClickPhoto?: () => void;
  onClickLink?: () => void;
  onClickMeetUp?: () => void;
  onClick?: () => void;
}

const PopoverTemlpatesCreateAction = ({
  onClickPhoto,
  onClickLink,
  onClickMeetUp,
  onClick,
}: IPopoverTemlpatesCreateAction) => {
  const { t } = useTranslation();

  return (
    <ListUserUserActions onClick={onClick}>
      <li onClick={onClickPhoto} onKeyDown={onClickPhoto}>
        <span>
          <PhotoIcon />
        </span>
        {t('event:photoPost')}
      </li>
      <li onClick={onClickLink} onKeyDown={onClickLink}>
        <span>
          <LinkIcon />
        </span>
        {t('event:linkPost')}
      </li>
      <li onClick={onClickMeetUp} onKeyDown={onClickMeetUp}>
        <span>
          <MeetUpIcon />
        </span>
        {t('common:event')}
      </li>
    </ListUserUserActions>
  );
};

export const Popover = ({
  children,
  variant,
  width,
  onClick,
  onLogOutClick,
}: IPopover) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const onSettingsClick = () => {
    router.push(`${routesPath.settings}`);
  };

  const onMyProfileClick = () => {
    const userId = JSON.parse(localStorage.getItem('user_id'));
    if (router.query.id === userId) return;
    if (router.query.id !== userId) {
      router.push(`${routesPath.user_details}/${userId}`);
    }
    if (
      router.pathname !== routesPath.user_details &&
      router.query.id === userId
    ) {
      router.push(`${routesPath.user_details}/${userId}`);
    }
  };

  const onDispatchCreateLink = () => {
    dispatch(openSideBarCreateLinkPost({}));
  };
  const onDispatchCreatePhotoPost = () => {
    dispatch(openSideBarCreatePhotoPost({}));
  };
  const onDispatchCreateEvent = () => {
    dispatch(openSidebarCreateEvent());
  };

  return (
    <PopoverWrapp
      width={width}
      variant={variant}
      top={variant === 'UserMenu' ? 190 : 145}
    >
      {variant === 'createAction' && (
        <PopoverTemlpatesCreateAction
          onClickLink={() => onDispatchCreateLink()}
          onClickPhoto={onDispatchCreatePhotoPost}
          onClickMeetUp={onDispatchCreateEvent}
          onClick={onClick}
        />
      )}
      {variant === 'UserMenu' && (
        <PopoverTemlpatesUser
          onMyProfileClick={onMyProfileClick}
          onClickLogOut={onLogOutClick}
          onSettingsClick={onSettingsClick}
          onMyEventsClick={() => router.push(routesPath.my_events)}
          onMyGroupsClick={() => router.push(routesPath.my_groups)}
          onMyGarageClick={() => router.push(routesPath.my_garage)}
          onMyFavoriteClick={() => router.push(routesPath.my_favorites)}
          onClick={onClick}
        />
      )}
      {children}
    </PopoverWrapp>
  );
};
