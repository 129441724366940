import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';

import { ThemeContext } from 'theme';
import Link from 'components/common/Link';
import Button from 'components/common/Button';
import { routesPath } from 'utils/constants/routePath';
import { useWindowSize } from 'hooks';

import {
  AuthDescriptionText,
  AuthOptionWrapper,
  MobileLogoutHeaderWrapper,
  MobileLogoWrapper,
  OrText,
} from './MobileLogoutHeader.styles';

const MobileLogoutHeader = () => {
  const context: any = useContext(ThemeContext);
  const Logo = context.content.transparentLogo;
  const AnonymousLogo = context.content.logoWithText;
  const router = useRouter();

  const { t } = useTranslation();
  const getWindowSize = useWindowSize();
  const windowSize = getWindowSize.width > 575;

  return (
    <MobileLogoutHeaderWrapper windowSize={windowSize}>
      {windowSize ? (
        <>
          <MobileLogoWrapper>
            <Link path="/">
              <Logo />
            </Link>
          </MobileLogoWrapper>
          <AuthOptionWrapper>
            <Button
              variant="default"
              onClick={() => router.push(routesPath.authentication)}
            >
              {t('auth:loginText')}
            </Button>
            <OrText>{t('common:or')}</OrText>
            <Button
              variant="primary"
              onClick={() => router.push(routesPath.authentication)}
            >
              {t('auth:signUpText')}
            </Button>
          </AuthOptionWrapper>
          <AuthDescriptionText>
            {t('common:toHaveAccessToAllContentOfRoadStr')}
          </AuthDescriptionText>
        </>
      ) : (
        <>
          <Link path="/">
            <AnonymousLogo />
          </Link>
          <Button
            variant="primary"
            onClick={() => router.push(routesPath.authentication)}
          >
            {t('auth:loginText')}
          </Button>
        </>
      )}
    </MobileLogoutHeaderWrapper>
  );
};

export default MobileLogoutHeader;
