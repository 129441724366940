import React from 'react';

import { ICarCard } from './ICarCard';
import { CardWrap, CarName } from './CarCard.style';
import { BackgroundGradientWrap } from '../Cards.style';

export const CarCard = ({
  id,
  coverPhoto,
  name,
  onClick,
  isCoverPhotoExists,
  paginationRef,
  gradientHeight,
  cardHeight,
  borderRadius,
  isVehicleAttendingScreen = false,
}: ICarCard) => (
  <CardWrap
    img={coverPhoto}
    onClick={onClick}
    ref={paginationRef}
    cardHeight={cardHeight}
    borderRadius={borderRadius}
  >
    <span role="img" aria-label="car card" />

    <BackgroundGradientWrap
      isCoverPhotoExists={isCoverPhotoExists}
      height={gradientHeight || 60}
    >
      <CarName
        isCoverPhotoExists={isCoverPhotoExists}
        isVehicleAttendingScreen={isVehicleAttendingScreen}
      >
        {name}
      </CarName>
    </BackgroundGradientWrap>
  </CardWrap>
);
