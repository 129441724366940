import React from 'react'

const ArrowRight = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 18.5L15 12.5L9 6.5" stroke="#1188FF" strokeWidth="2.4" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )
}

export default ArrowRight