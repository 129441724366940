import React from 'react';

export const BeepIcon = ({ color = '#A9A9B5' }: { color?: string }) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9382 11.592C10.9382 11.8345 11.1351 12.0314 11.3776 12.0314C11.6201 12.0314 11.8171 11.8345 11.8171 11.592C11.8171 11.3495 11.6201 11.1525 11.3776 11.1525C11.1351 11.1525 10.9382 11.3495 10.9382 11.592Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.19727 12.0312C5.91218 12.0312 6.57159 11.6828 6.97316 11.1523H7.47041C7.59698 10.0862 8.15694 9.15398 8.98366 8.51562H6.97316C6.57159 7.98519 5.91218 7.63672 5.19727 7.63672C3.98579 7.63672 3 8.62251 3 9.83398C3 11.0455 3.98579 12.0312 5.19727 12.0312Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5607 14.668C17.8035 14.668 18.0001 14.4714 18.0001 14.2285V5.43945C18.0001 5.19661 17.8035 5 17.5607 5C17.3178 5 17.1212 5.19661 17.1212 5.43945V6.138L16.9982 6.25679C15.5392 7.7158 13.5763 8.51563 11.5197 8.51563C9.76187 8.51563 8.32049 9.88674 8.30286 11.5654C8.28753 13.2443 9.64834 14.668 11.379 14.668C12.7434 14.668 13.8736 13.8124 14.2706 12.6553C13.7169 12.4356 13.1368 12.2861 12.5392 12.1982C12.3282 12.62 11.8888 12.9102 11.379 12.9102C10.6495 12.9102 10.0607 12.3212 10.0607 11.5918C10.0607 10.8622 10.6495 10.2734 11.379 10.2734C12.0206 10.2734 12.548 10.7217 12.671 11.3281V11.3368C14.3512 11.5561 15.8047 12.2089 17.1036 13.5078L17.1212 13.5254V14.2285C17.1212 14.4714 17.3178 14.668 17.5607 14.668Z"
      fill={color}
    />
  </svg>
);
