import React, { useState, useEffect, useCallback, useRef } from 'react';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { useTranslation } from 'react-i18next';

import { style } from 'utils/constants/style';
import { IImageCropper } from './IImageCropper';
import { Wrapper, ButtonWrap } from './ImageCropper.style';
import { RectangleButton } from '..';

async function getCroppedImg(imageSrc, pixelCrop, isBlobImage, imgRef = null) {
  const image = imgRef.current;

  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const scaleX = imgRef.current.naturalWidth / imgRef.current.width;
  const scaleY = imgRef.current.naturalHeight / imgRef.current.height;
  const pixelRatio = window.devicePixelRatio;

  const croppedImageWidth = pixelCrop.width
    ? pixelCrop.width
    : imgRef.current.width;
  const cropperImageHeight = pixelCrop.height
    ? pixelCrop.height
    : imgRef.current.height;

  canvas.width = croppedImageWidth * pixelRatio * scaleX;
  canvas.height = cropperImageHeight * pixelRatio * scaleY;

  ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
  ctx.imageSmoothingQuality = 'high';

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    (pixelCrop.width && pixelCrop.x) * scaleX,
    (pixelCrop.height && pixelCrop.y) * scaleY,
    croppedImageWidth * scaleX,
    cropperImageHeight * scaleY,
    0,
    0,
    croppedImageWidth * scaleX,
    cropperImageHeight * scaleY
  );

  // As Base64 string
  if (isBlobImage) {
    // As a blob
    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        resolve(URL.createObjectURL(file));
      }, 'image/png');
    });
  }

  return canvas.toDataURL('image/jpeg');
}

const ImageCropper = ({
  image,
  aspect,
  isCircleShape,
  isCoverImage,
  onSubmitImage,
  isProfileImages,
  isBlobImage = false,
  isAspect = false,
}: IImageCropper) => {
  const [crop, setCrop] = useState<Crop>({
    ...(isAspect
      ? {
          unit: 'px',
          width: 270,
          height: 180,
          x: 10,
          y: 10,
        }
      : {
          unit: '%',
          width: 100,
          height: 100,
          x: 0,
          y: 0,
        }),
  });

  const imgRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (isCircleShape) {
      setCrop({ ...crop, aspect: 1, width: 210, height: 210 });
    }

    if (isCoverImage) {
      setCrop({ ...crop, aspect: 3 / 2, width: 270, height: 180 });
    }
  }, [isCircleShape, isCoverImage]);

  const onCropChange = (newCrop) => {
    setCrop(newCrop);
  };

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const showResults = async () => {
    try {
      const croppedImage: any = await getCroppedImg(
        image,
        crop,
        isBlobImage,
        imgRef
      );

      if (isProfileImages) {
        if (isCircleShape) {
          onSubmitImage(croppedImage, 'profile');
        } else {
          onSubmitImage(croppedImage, 'cover');
        }
      } else {
        onSubmitImage(croppedImage);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Wrapper>
      <ReactCrop
        src={image}
        crop={crop}
        onChange={onCropChange}
        circularCrop={isCircleShape}
        onImageLoaded={onLoad}
        minWidth={crop?.height / 2 < 100 ? 100 : crop?.height / 2}
        minHeight={crop?.width / 2 < 100 ? 100 : crop?.width / 2}
        maxWidth={crop?.height * 2}
        maxHeight={crop?.width * 2}
      />

      <ButtonWrap>
        <RectangleButton
          text={t('editUserProfile:save')}
          color={style.mainWhite.white}
          backgroundColor={style.mainYellow.yellow}
          onClick={showResults}
        />
      </ButtonWrap>
    </Wrapper>
  );
};

export default ImageCropper;
