import React from 'react';
import { style } from 'utils/constants/style';

export const CreateFirstPostIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 12C8 11.6844 8.25584 11.4285 8.57143 11.4285H15.4286C15.7442 11.4285 16 11.6844 16 12C16 12.3156 15.7442 12.5714 15.4286 12.5714H8.57143C8.25584 12.5714 8 12.3156 8 12Z"
      fill={style.mainBlue.blue}
    />
    <path
      d="M12.0001 16C11.6845 16 11.4287 15.7442 11.4287 15.4286L11.4287 8.57143C11.4287 8.25584 11.6845 8 12.0001 8C12.3157 8 12.5716 8.25584 12.5716 8.57143L12.5716 15.4286C12.5716 15.7442 12.3157 16 12.0001 16Z"
      fill={style.mainBlue.blue}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9997 21.3333C17.1543 21.3333 21.333 17.1547 21.333 12C21.333 6.84534 17.1543 2.66666 11.9997 2.66666C6.84502 2.66666 2.66634 6.84534 2.66634 12C2.66634 17.1547 6.84502 21.3333 11.9997 21.3333ZM11.9997 22.6667C17.8907 22.6667 22.6663 17.891 22.6663 12C22.6663 6.10896 17.8907 1.33333 11.9997 1.33333C6.10864 1.33333 1.33301 6.10896 1.33301 12C1.33301 17.891 6.10864 22.6667 11.9997 22.6667Z"
      fill={style.mainBlue.blue}
    />
  </svg>
);
