import NavTabs from 'components/common/NavTabs';
import { IInput } from './Types';

import { Wrapper, InputField } from './styles';

const Input = ({
  id,
  input,
  label,
  inputRef,
  meta,
  labelLeft,
  labelRight,
  reverse = false,
  ...restProps
}: IInput) => {
  const { type, ...inputWithoutValue } = input;

  // Use inputRef if it is passed as prop.
  const refMaybe = inputRef ? { ref: inputRef } : {};
  const inputProps = {
    id,
    type,
    value: input?.checked ? true : '',
    ...refMaybe,
    ...inputWithoutValue,
    ...restProps,
  };

  const leftNumber = reverse ? 1 : 2;
  const rightNumber = reverse ? 2 : 1;

  return (
    <Wrapper htmlFor={id}>
      <NavTabs
        tabsList={[
          { id: 1, name: labelLeft },
          { id: 2, name: labelRight },
        ]}
        defaultActive={input?.checked ? leftNumber : rightNumber}
      />
      <div>
        <InputField {...inputProps} getValidator={() => false} />
      </div>
    </Wrapper>
  );
};

export default Input;
