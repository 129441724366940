import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  width?: number;
  height?: number;
  backgroundColor?: string;
  color?: string;
  fontSize?: number;
  verticalGutter?: number;
}

export const Button = styled.div<IProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => `${props.width}px` || `${32}px`};
  height: ${(props) => `${props.width}px` || `${32}px`};
  background-color: ${style.mainBlue.blue};
  :hover {
    background-color: ${style.mainBlue.hoverBtnBlue};
  }
  border-radius: 50%;
  color: white;
  font-size: 22px;
  cursor: pointer;
  font-weight: 400;
`;
