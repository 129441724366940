import React from 'react';

export const EarthIcon = ({ color = '#ffffff' }: { color?: string }) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.66602 15C12.532 15 15.666 11.866 15.666 8C15.666 4.13401 12.532 1 8.66602 1C4.80002 1 1.66602 4.13401 1.66602 8C1.66602 11.866 4.80002 15 8.66602 15ZM6.71104 2.50524C6.44969 2.29123 6.63711 1.58746 6.63711 1.58746C6.63711 1.58746 5.28251 2.3408 4.66363 2.90516C3.94802 3.55774 2.82162 4.76717 2.88078 4.88555C2.94618 5.01642 3.30838 5.0305 3.71985 5.04648C4.05559 5.05953 4.42412 5.07385 4.69102 5.15393C5.16623 5.29651 4.98808 5.78762 4.8396 6.01535C4.58217 6.72825 4.07325 8.24318 4.09701 8.59963C4.12672 9.04519 4.89908 11.3621 5.28524 11.8077C5.67139 12.2533 6.65164 11.9859 6.71104 11.8077C6.72171 11.7757 6.71116 11.7265 6.69612 11.6563C6.64411 11.4136 6.53837 10.9202 7.06981 10.0213C7.31867 9.60047 7.71317 9.27923 8.12721 8.94207C8.60177 8.55563 9.10201 8.14828 9.43808 7.54604C9.91344 6.69419 10.057 5.65678 10.1365 5.08193L10.1365 5.08192L10.1365 5.08191L10.1365 5.08189C10.1467 5.00848 10.1558 4.94261 10.1644 4.88564C10.3164 3.88224 9.13519 2.83682 8.74904 2.77741C8.63612 2.76004 8.44604 2.75498 8.22449 2.74909C7.68839 2.73483 6.96804 2.71567 6.71104 2.50524ZM14.0353 8.19493V5.70805C14.0353 5.62114 14.0619 5.53669 14.1114 5.46526C14.3135 5.17382 14.7533 5.24431 14.8469 5.58639L14.9257 5.87562C15.0907 6.4825 15.2687 7.13691 15.4219 7.57095C15.7494 8.49885 14.6173 11.4981 14.3939 11.8081C14.1925 12.0878 13.5446 12.6206 12.8896 13.1118C12.4725 13.4206 11.8982 13.144 11.9402 12.6267C11.9901 12.0119 12.1205 11.3106 12.4394 10.912C13.0944 10.0933 14.0353 8.45693 14.0353 8.19493ZM7.59956 13.411C6.13544 14.0827 7.06247 14.5534 7.35613 14.6738C7.40924 14.6956 7.46397 14.7075 7.52111 14.7129C8.23995 14.7816 10.3333 14.8547 10.6329 14.3656C10.924 13.8907 9.30675 12.6277 7.59956 13.411ZM9.36473 9.50562C9.53864 9.4765 9.79153 9.55771 9.71989 10.3219C9.61321 11.4601 8.2145 11.8689 8.05273 11.571C7.89894 11.2877 8.75022 10.1699 9.10418 9.70511L9.11636 9.68911C9.18111 9.60407 9.25932 9.52328 9.36473 9.50562Z"
      fill={color}
    />
    <circle
      cx="8.66602"
      cy="8"
      r="6.58333"
      stroke={color}
      strokeWidth="0.833333"
    />
  </svg>
);
