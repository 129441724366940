/* eslint-disable prefer-destructuring */
import { useState, useRef } from 'react';
import Image from 'components/common/Image';
import moment from 'moment';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

import { EmptyGroupIcon } from 'public/assets/icons';
import { useOutsideClick } from 'utils/hooks';
import { routesPath } from 'utils/constants/routePath';
import { DEFAULT_LINK_POST_PLACEHOLDER_IMAGE } from 'utils/constants';
import { ICustomList } from './ICustomList';
import {
  CustomListWrapper,
  Wrapper,
  ListWrap,
  ItemDescriptions,
  ItemWrap,
  ItemContainer,
  PopOVerWrap,
  Title,
  EmptyUserPhoto,
  PopularPostsListWrapper,
  PopularPostsList,
  TitleWrap,
  PlaceHolderWrapper,
} from './CustomList.styled';
import { ConfiguringPopoverComponent } from '../Popover/ConfiguringPopoverComponent';
import { ListUserUserActions } from '../Popover/Popover.style';

export const CustomList = ({ list, title, icon, type }: ICustomList) => {
  const [popoverShow, setPopoverShow] = useState(false);

  const router = useRouter();
  const { t } = useTranslation();

  const refActionPopover = useRef(null);
  useOutsideClick(refActionPopover, () => {
    setPopoverShow(false);
  });

  const onItemClick = (item) => {
    if (type === 'my_groups') {
      router.push(`${routesPath.group_details}/${item._id}`);
    } else {
      router.push(`${routesPath.event_details}/${item._id}`);
    }
  };

  if (type === 'popular_posts') {
    const getRedirectUrl = (item) => {
      switch (item.type) {
        case 1:
        case 3:
          return `${routesPath.post_details}/${item._id}`;
        case 4:
          return `${routesPath.vehicle_details}/${item.originId}`;
        case 6:
        case 16:
          return `${routesPath.event_details}/${item.originId}`;
        case 7:
          return `${routesPath.group_details}/${item.originId}`;

        default:
          return '';
      }
    };

    const getImageSrc = (item) => {
      let src;

      switch (item.type) {
        case 1:
        case 4:
          src = item.media[0];
          break;

        case 3:
          src =
            item.linkImage === DEFAULT_LINK_POST_PLACEHOLDER_IMAGE
              ? ''
              : item.linkImage;
          break;

        case 6:
        case 16:
          src = item.event.coverPhoto;
          break;

        default:
          src = item.coverPhoto;
      }

      return src || '/assets/img/tireTrace.png';
    };

    return (
      <Wrapper>
        <div style={{ display: 'flex' }}>
          <div>{icon}</div>
          <Title>{title}</Title>
        </div>

        <PopularPostsListWrapper>
          {list?.length > 0 &&
            list.map((el, i) => (
              <div key={`custom-list${i}`}>
                <PopularPostsList
                  onClick={() => router.push(getRedirectUrl(el))}
                >
                  <Image
                    loader={() => getImageSrc(el)}
                    src={getImageSrc(el)}
                    alt="post"
                    width={130}
                    height={157}
                    objectFit="cover"
                    unoptimized
                    priority
                  />
                </PopularPostsList>
              </div>
            ))}
        </PopularPostsListWrapper>
      </Wrapper>
    );
  }

  return (
    <>
      {popoverShow && (
        <PopOVerWrap>
          <ConfiguringPopoverComponent>
            <ListUserUserActions>
              <li>{t('search:searchText')}</li>
              <li>{t('feed:create')}</li>
            </ListUserUserActions>
          </ConfiguringPopoverComponent>
        </PopOVerWrap>
      )}
      <Wrapper>
        <TitleWrap>
          <div>{icon}</div>
          <Title>{title}</Title>
        </TitleWrap>
        <CustomListWrapper>
          <PlaceHolderWrapper>
            {list?.length === 0 && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Image
                  width={72}
                  height={72}
                  alt="empty placeholder"
                  src={
                    type === 'my_groups'
                      ? '/assets/img/empty-groups.svg'
                      : '/assets/img/empty-events.svg'
                  }
                  unoptimized
                  priority
                />
                <div>
                  {type === 'my_groups'
                    ? t('myGroups:placeholder')
                    : t('myEvents:placeholder')}
                </div>
              </div>
            )}
          </PlaceHolderWrapper>

          <ListWrap>
            {list?.length > 0 &&
              list.map((el, i) => (
                <ItemContainer
                  key={`custom-list${i}`}
                  onClick={() => onItemClick(el)}
                >
                  <ItemWrap>
                    {el.coverPhoto && el.coverPhoto !== ' ' ? (
                      <span>
                        <Image
                          width={40}
                          height={40}
                          alt={type === 'my_groups' ? 'group' : 'event'}
                          src={el.coverPhoto}
                          unoptimized
                          priority
                        />
                      </span>
                    ) : (
                      <span>
                        <EmptyUserPhoto>
                          <EmptyGroupIcon />
                        </EmptyUserPhoto>
                      </span>
                    )}

                    <ItemDescriptions>
                      <div>{el.name}</div>
                      {type === 'my_groups' ? (
                        <span>{el.username}</span>
                      ) : (
                        <span>
                          {moment(el?.startDate).format('DD MMM hh:mm A')}
                        </span>
                      )}
                    </ItemDescriptions>
                  </ItemWrap>
                </ItemContainer>
              ))}
          </ListWrap>
        </CustomListWrapper>
      </Wrapper>
    </>
  );
};
