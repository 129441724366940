import styled from 'styled-components';

import { style, deviceMaxWidth } from 'utils/constants/style';

interface IProps {
  top?: number;
  right?: number;
  width?: number;
}

export const PopoverWrap = styled.div<IProps>`
  position: fixed;
  top: ${({ top }) => (top ? `${top}px` : '63px')};
  right: ${({ right }) => (right ? `${right}px` : '115px')};
  z-index: 110;
  width: ${({ width }) => (width ? `${width}px` : '165px')};
  height: 44px;

  @media only screen and (${deviceMaxWidth.sm}) {
    right: 45px;
    top: 10px;
  }
`;

export const PendingWrap = styled.div`
  height: 48px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: ${style.mainGray.whiteSmokeColor};
  box-sizing: border-box;
  padding: 0 16px;
  position: relative;
`;

export const ViewAll = styled.p`
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: ${style.mainBlue.blue};
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 17px;
`;

export const HeaderRightIconWrap = styled.div`
  width: 24px;
  height: 24px;
`;
