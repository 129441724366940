import { useCallback, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SelectVehicleDrawer from 'components/SelectVehicle';
import SidebarCheckItem from 'components/SidebarCheckItem';
import API_NAME from 'services/api';
import fetchApi from 'hooks/fetchApi';
import { ThemeContext } from 'theme';
import { WheelIcon } from 'public/assets/icons';
import AttendVehicleGarageCard from 'components/AttendVehicleGarageCard';
import { ISelectVehicle } from './ICreateEventPostForm';
import {
  NoResultText,
  NoResultWrap,
  VehiclesWrap,
} from './CreateEventPostForm.styles';

const SelectVehicle = ({ stepNumber }: ISelectVehicle) => {
  const context: any = useContext(ThemeContext);

  const { t } = useTranslation();

  const getVehicles = useSelector((store) => store.fetchData);

  const { action, loading } = fetchApi();

  const fetchData = useCallback(() => {
    action(API_NAME.GET_VEHICLES, {
      params: {
        id: context?.userId,
      },
    });
  }, []);

  useEffect(() => {
    if (stepNumber && !(getVehicles[API_NAME.GET_VEHICLES]?.data?.length > 0)) {
      fetchData();
    }
  }, [stepNumber]);

  return (
    <SelectVehicleDrawer
      name="vehicle"
      formRender={(value, onChange) => {
        const isSelectedValue = value?.shift();
        return (
          <>
            <SidebarCheckItem
              key={1}
              image=""
              fullName="Attend without a vehicle"
              userName=""
              isSelected={isSelectedValue?.id === 1}
              onSidebarCheckItemClick={() => onChange([{ id: 1 }], true)}
              type="group"
            />
            {getVehicles[API_NAME.GET_VEHICLES]?.data?.length > 0 ? (
              <>
                <VehiclesWrap>
                  {getVehicles[API_NAME.GET_VEHICLES]?.data?.map((v) => (
                    <AttendVehicleGarageCard
                      key={v?._id}
                      image={v?.photos[0]}
                      make={v?.make?.trim()}
                      model={v?.model}
                      checked={isSelectedValue?._id === v?._id}
                      onClick={() => onChange({ ...v, id: v?._id })}
                    />
                  ))}
                </VehiclesWrap>
              </>
            ) : (
              <NoResultWrap>
                <NoResultText>{t('chat:nothingFound')}</NoResultText>
                <WheelIcon />
              </NoResultWrap>
            )}
          </>
        );
      }}
    />
  );
};

export default SelectVehicle;
