import React from 'react';

export const GroupsIcon = ({ stroke = '#8D8D8D' }: { stroke?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0004 5.8C15.4246 5.8 18.2 8.57545 18.2 11.9996C18.2 15.4239 15.4244 18.2 12.0004 18.2C8.57616 18.2 5.8 15.4238 5.8 11.9996C5.8 8.57555 8.57605 5.8 12.0004 5.8Z"
      stroke={stroke}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 8H1L3.6048 13H5.62081"
      stroke={stroke}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17 8H23L20.3952 13H18.3792"
      stroke={stroke}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
