export const actionTypes = {
  LOGOUT_ERROR: 'LOGOUT_ERROR',
  LOGOUT_START: 'LOGOUT_START',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
};

export function logOutError(err) {
  return {
    type: actionTypes.LOGOUT_ERROR,
    err,
  };
}
export function logOutStart() {
  return {
    type: actionTypes.LOGOUT_START,
  };
}

export function logOutSuccess(data) {
  return {
    type: actionTypes.LOGOUT_SUCCESS,
    data,
  };
}
