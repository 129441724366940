/* eslint-disable no-unused-expressions */
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  postVehicleShare,
  postPostShare,
  postPostViewed,
  postEventShare,
  postUserShare,
} from 'services';
import {
  actionTypes,
  postVehicleShareSuccess,
  postVehicleShareError,
  postPostShareSuccess,
  postPostShareError,
  postPostViewedSuccess,
  postPostViewedError,
  postEventShareSuccess,
  postEventShareError,
  postUserShareSuccess,
  postUserShareError,
} from './actions';

function* workerPostVehicleShare(action) {
  try {
    const resp = yield call(postVehicleShare, action.data);

    console.log('analitic resp', resp);

    resp.status === 204 || resp.code === 200
      ? yield put(postVehicleShareSuccess())
      : yield put(postVehicleShareError('Error'));
  } catch (err) {
    yield put(postVehicleShareError(err));
  }
}

function* workerPostPostShare(action) {
  try {
    const resp = yield call(postPostShare, action.data);

    console.log('analitic resp', resp);

    resp.status === 204 || resp.code === 200
      ? yield put(postPostShareSuccess())
      : yield put(postPostShareError('Error'));
  } catch (err) {
    yield put(postPostShareError(err));
  }
}

function* workerPostPostViewed(action) {
  try {
    const resp = yield call(postPostViewed, action.data);

    resp.status === 204 || resp.code === 200
      ? yield put(postPostViewedSuccess(action.data))
      : yield put(postPostViewedError('Error'));
  } catch (err) {
    yield put(postPostViewedError(err));
  }
}

function* workerPostEventShare(action) {
  try {
    const resp = yield call(postEventShare, action.data);

    console.log('analitic resp', resp);

    resp.status === 204 || resp.code === 200
      ? yield put(postEventShareSuccess())
      : yield put(postEventShareError('Error'));
  } catch (err) {
    yield put(postEventShareError(err));
  }
}

function* workerPostUserShare(action) {
  try {
    const resp = yield call(postUserShare, action.data);

    console.log('analitic resp', resp);

    resp.status === 204 || resp.code === 200
      ? yield put(postUserShareSuccess())
      : yield put(postUserShareError('Error'));
  } catch (err) {
    yield put(postUserShareError(err));
  }
}

function* watchAnalytics() {
  yield takeEvery(actionTypes.POST_VEHICLE_SHARE_START, workerPostVehicleShare);
  yield takeEvery(actionTypes.POST_POST_SHARE_START, workerPostPostShare);
  yield takeEvery(actionTypes.POST_POST_VIEWED_START, workerPostPostViewed);
  yield takeEvery(actionTypes.POST_EVENT_SHARE_START, workerPostEventShare);
  yield takeEvery(actionTypes.POST_USER_SHARE_START, workerPostUserShare);
}

export default watchAnalytics;
