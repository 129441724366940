import React from 'react';

import { anonymousClickHandler } from 'utils/anonymousClickHandler';
import { IMessageText } from './IMessageText';
import { Text, LinkText } from './MessageText.style';

export const MessageText = ({
  text,
  onHashtagClick,
  onMentionClick,
  linkColor,
}: IMessageText) => {
  const hashtagMentionFormatter = (string) =>
    string
      .replace(/\n/g, ' ')
      .split(' ')
      .filter(Boolean)
      .map((v, i) => {
        if (v.includes('#')) {
          return (
            <Text
              onClick={() => anonymousClickHandler(() => onHashtagClick(v))}
              key={i + v}
              linkColor={linkColor}
            >
              {v}
            </Text>
          );
        }
        if (v.includes('@')) {
          return (
            <Text
              onClick={() => anonymousClickHandler(() => onMentionClick(v))}
              key={i + v}
              linkColor={linkColor}
            >
              {v}
            </Text>
          );
        }
        if (v.includes('https') || v.includes('www')) {
          return (
            <LinkText
              linkColor={linkColor}
              target="_blank"
              href={v}
              key={i + v}
            >
              {v}
            </LinkText>
          );
        }
        return `${v} `;
      });

  const formatedText = hashtagMentionFormatter(text);

  return formatedText;
};
