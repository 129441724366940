import { IValidationError } from './IValidationError';
import { ValidationErrorWrapper } from './ValidationError.styles';

const ValidationError = ({ touched, error }: IValidationError) => {
  return touched && error ? (
    <ValidationErrorWrapper className="validation-error">
      {error}
    </ValidationErrorWrapper>
  ) : null;
};

export default ValidationError;
