import styled from 'styled-components';

import Text from 'components/common/Text';
import Heading from 'components/common/Typography/Heading';

export const LikeItemWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;
`;

export const ProfileImageWrapper = styled.div`
  & > span {
    border-radius: 50%;
    margin-right: 10px;
  }
`;

export const LikeItemInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 0;
  margin-left: 10px;
`;

export const LikeItemHeaderText = styled(Heading)`
  font-size: ${(props) => props.theme.typography.fontSize.f3};
  line-height: 13px;
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  letter-spacing: 0.02em;
  color: ${(props) => props.theme.colors.black};
  padding-bottom: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const LikeItemText = styled(Text)`
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.colors.neutral.n8};
  padding-top: 2px;
`;
