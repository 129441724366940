import { ICol } from './ILayout';

import { ColWrapper } from './Layout.styles';

const Col = ({ children, span, className }: ICol) => {
  return (
    <ColWrapper span={span} className={className}>
      {children}
    </ColWrapper>
  );
};

export default Col;
