import { useContext } from 'react';

import Header from 'containers/Header';
import { Container } from 'containers/Layout';
import { ThemeContext } from 'theme';
import { IError } from './Types';
import { Wrapper } from './styles';

const Error = ({ children, staticData }: IError) => {
  const context: any = useContext(ThemeContext);
  const isAuthenticate = context?.isAuthenticate;

  const result = staticData?.result;
  const errorCode = staticData?.error?.status;
  const haveError =
    result === 'error' || errorCode === 500 || errorCode === 400;

  if (!haveError) {
    return children;
  }

  return (
    <>
      <Header />

      <Container
        isSidebar={isAuthenticate}
        meta={{
          title: 'Not available 404',
          ogUrl: ``,
          description: '',
          ogImage: '',
        }}
      >
        <h2>Oops, there is an error!</h2>
      </Container>
    </>
  );
};

export default Error;
