import React from 'react';

import { CircleWithEditIcon, PlusIcon } from 'public/assets/icons';
import { ISelector } from './ISelector';
import {
  Wrapper,
  IconWrapper,
  Title,
  TitleWithIconWrap,
} from './Selector.style';

const Selector = ({
  title,
  isSelectedItemsExist,
  leftIcon,
  margin,
  onClick,
}: ISelector) => (
  <Wrapper margin={margin}>
    {leftIcon ? (
      <TitleWithIconWrap>
        {leftIcon}
        <Title iconLeft>{title}</Title>
      </TitleWithIconWrap>
    ) : (
      <Title>{title}</Title>
    )}
    <IconWrapper onClick={onClick}>
      {isSelectedItemsExist ? <CircleWithEditIcon /> : <PlusIcon />}
    </IconWrapper>
  </Wrapper>
);

export default Selector;
