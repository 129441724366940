import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  margin?: string;
  iconLeft?: boolean;
  height?: number;
  isLabelExists?: boolean;
  crossIcon?: boolean;
  backgroundColor?: string;
  focus?: boolean;
  pl?: number;
  cursor?: string;
}

export const InputDescriptionWrapper = styled.div<IProps>`
  width: 100%;
`;

export const DescriptionWrapper = styled.div<IProps>`
  box-sizing: border-box;
  border: 1px solid #f9f9f9;
  cursor: text;
  padding: 16px;
  border-radius: 4px;
  margin: ${(props) => props.margin || '0'};
  min-height: 88px;
  background: #f9f9f9;
  height: ${({ height }) => (height ? `${height}px` : 'auto')};
  width: 100%;
  overflow-y: scroll;

  .public-DraftEditor-content {
    min-height: 88px;
  }
  .public-DraftEditorPlaceholder-root,
  .public-DraftStyleDefault-ltr {
    color: ${style.mainBlack.black};
    background-color: ${style.mainGray.whiteSmokeColor};
    line-height: 15px;
    font-size: 15px;
    font-family: 'Poppins';
  }
  &:focus,
  &:visited,
  &:active,
  &:hover {
    border: 1px solid #ff950a;
  }
`;

export const Label = styled.label`
  color: ${style.mainBlack.charcoalColor};
  font-size: 13px;
  line-height: 13px;
  margin-bottom: 4px;
`;
