export const actionTypes = {
  GET_SETTINGS_START: 'GET_SETTINGS_START',
  GET_SETTINGS_SUCCESS: 'GET_SETTINGS_SUCCESS',
  GET_SETTINGS_ERROR: 'GET_SETTINGS_ERROR',
  CHANGE_SETTINGS_START: 'CHANGE_SETTINGS_START',
  CHANGE_SETTINGS_SUCCESS: 'CHANGE_SETTINGS_SUCCESS',
  CHANGE_SETTINGS_ERROR: 'CHANGE_SETTINGS_ERROR',
  CHANGE_EMAIL_START: 'CHANGE_EMAIL_START',
  CHANGE_EMAIL_SUCCESS: 'CHANGE_EMAIL_SUCCESS',
  CHANGE_EMAIL_ERROR: 'CHANGE_EMAIL_ERROR',
  CHANGE_PASSWORD_START: 'CHANGE_PASSWORD_START',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_ERROR: 'CHANGE_PASSWORD_ERROR',
};

// GET SETTINGS ACTIONS
export const getSettingsStart = () => ({
  type: actionTypes.GET_SETTINGS_START,
});

export const getSettingsSuccess = (data) => ({
  type: actionTypes.GET_SETTINGS_SUCCESS,
  data,
});

export const getSettingsError = (error) => ({
  type: actionTypes.GET_SETTINGS_ERROR,
  error,
});

// CHANGE SETTINGS ACTIONS
export const changeSettingsStart = (data) => ({
  type: actionTypes.CHANGE_SETTINGS_START,
  data,
});

export const changeSettingsSuccess = (data) => ({
  type: actionTypes.CHANGE_SETTINGS_SUCCESS,
  data,
});

export const changeSettingsError = (error) => ({
  type: actionTypes.CHANGE_SETTINGS_ERROR,
  error,
});

// CHANGE EMAIL ACTIONS
export const changeEmailStart = (data) => ({
  type: actionTypes.CHANGE_EMAIL_START,
  data,
});

export const changeEmailSuccess = () => ({
  type: actionTypes.CHANGE_EMAIL_SUCCESS,
});

export const changeEmailError = (error) => ({
  type: actionTypes.CHANGE_EMAIL_ERROR,
  error,
});

// CHANGE PASSWORD ACTIONS
export const changePasswordStart = (data) => ({
  type: actionTypes.CHANGE_PASSWORD_START,
  data,
});

export const changePasswordSuccess = () => ({
  type: actionTypes.CHANGE_PASSWORD_SUCCESS,
});

export const changePasswordError = (error) => ({
  type: actionTypes.CHANGE_PASSWORD_ERROR,
  error,
});
