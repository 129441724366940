import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  pb?: number;
  pt?: number;
  backgroundImage?: string;
  mt?: number;
  mb?: number;
}

export const Wrapper = styled.div`
  padding-bottom: 20px;
`;

export const SectionWrapper = styled.div<IProps>`
  padding-top: ${({ pt }) =>
    pt || typeof pt === 'number' ? `${pt}px` : '20px'};
  padding-bottom: ${({ pb }) => (pb ? `${pb}px` : '16.5px')};
  border-bottom: 1px dashed ${style.mainGray.whisperColor};

  :last-of-type {
    border: none;
  }
`;

export const SectionHeader = styled.h3`
  font-size: 18px;
  line-height: 130%;
  font-weight: 600;
  color: ${style.mainBlack.black};
  margin-bottom: 13px;
`;

export const TagsWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TagItemWrap = styled.div`
  margin-bottom: 8px;

  :last-of-type {
    margin: 0;
  }
`;

export const QuickTagsWrap = styled.div`
  overflow: hidden;
  overflow-y: auto;
  max-height: 130px;

  ::-webkit-scrollbar-track {
    background: none;
  }
  ::-webkit-scrollbar {
    width: 4px;
    height: 100px;
    background: none;
  }
  ::-webkit-scrollbar-thumb {
    background: ${style.mainGray.silver};
    border-radius: 10px;
  }
`;

export const TagImageWrap = styled.div`
  height: 26px;
  width: 26px;
  border-radius: 50%;
  overflow: hidden;
`;

export const LinkInfoWrap = styled.div<IProps>`
  background-color: ${style.mainGray.whisperColor};
  background: ${({ backgroundImage }) =>
    backgroundImage && `url(${backgroundImage}) no-repeat center center`};
  background-size: cover;
  width: 100%;
  height: 240px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: -8px 0 16px 0;
`;

export const LinkInfoContentWrap = styled.div`
  width: 100%;
  height: 135px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 9.67%, #1c1f29 100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 0 50px 10px 10px;
`;

export const LinkInfoDescription = styled.p`
  font-size: 14px;
  line-height: 140%;
  font-weight: 600;
  color: ${style.mainWhite.white};
`;

export const LinkInfoSite = styled.p`
  font-size: 14px;
  line-height: 140%;
  text-transform: uppercase;
  color: ${style.mainYellow.yellow};
`;

export const LocationText = styled.p`
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  margin: 14px 0 11px 8px;
`;

export const SeparateSettingsHeader = styled.h3<IProps>`
  font-size: 16px;
  line-height: 150%;
  font-weight: 600;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
`;
