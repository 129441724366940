import React from 'react';
import { useTheme } from 'styled-components';

const ElipsisVertical = ({
  width = '24',
  height = '24',
}: {
  width?: string;
  height?: string;
}) => {
  const theme: any = useTheme();

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.167 16.167C13.2716 16.167 14.167 17.0624 14.167 18.167C14.167 19.2716 13.2716 20.167 12.167 20.167C11.0624 20.167 10.167 19.2716 10.167 18.167C10.167 17.0624 11.0624 16.167 12.167 16.167ZM12.167 10.167C13.2716 10.167 14.167 11.0624 14.167 12.167C14.167 13.2716 13.2716 14.167 12.167 14.167C11.0624 14.167 10.167 13.2716 10.167 12.167C10.167 11.0624 11.0624 10.167 12.167 10.167ZM14.167 5.83366C14.167 4.72909 13.2716 3.83366 12.167 3.83366C11.0624 3.83366 10.167 4.72909 10.167 5.83366C10.167 6.93823 11.0624 7.83366 12.167 7.83366C13.2716 7.83366 14.167 6.93823 14.167 5.83366Z"
        fill={theme.colors.neutral.n8}
      />
    </svg>
  );
};

export default ElipsisVertical;
