import styled from 'styled-components';

import Heading from 'components/common/Typography/Heading';

interface IProps {
  padding?: string;
  headerBorder?: boolean;
  rotate?: number;
  isChat?: boolean;
  open?: boolean;
}

export const DrawerOverlay = styled.div`
  position: fixed;
  inset: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
`;

export const DrawerWrapper = styled.div<IProps>`
  width: 100%;
  max-width: 404px;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => props.theme.colors.white};
  z-index: 1000;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-radius: 0;
  padding: 0;

  & > span {
    display: none;
  }
  & > div {
  }
  & > div {
  }
`;

export const DrawerHeader = styled.div<IProps>`
  max-width: 404px;
  width: 100%;
  height: 54px;
  padding: ${({ padding }) => padding || '11px 20px'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border-bottom: ${({ headerBorder, theme }) =>
    headerBorder ? `1px solid ${theme.colors.neutral.n5}` : 'none'};
  position: fixed;
  background-color: ${(props) => props.theme.colors.white};
  z-index: 110;
`;

export const HeaderTextWithIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderIconWrapper = styled.div<IProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  transform: ${({ rotate }) => (rotate ? `rotate(${rotate}deg)` : 'none')};
  & > button {
    color: ${(props) => props.theme.colors.orange};
    font-size: ${(props) => props.theme.typography.fontSize.f2};
    line-height: 12px;
    background: ${(props) => props.theme.colors.orangeLight} !important;
    border-radius: 30px;

    & > div {
      height: 12px;
    }

    &:hover {
      background: ${(props) => props.theme.colors.orangeLight} !important;
    }
    &:disabled {
      background: ${(props) => props.theme.colors.neutral.n2} !important;
      color: ${(props) => props.theme.colors.neutral.n6};
      cursor: not-allowed;
    }
  }
`;

export const HeaderText = styled(Heading)`
  font-size: 15px;
  line-height: 150%;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: ${(props) => props.theme.colors.black};
  margin-left: 6px;
`;

export const DrawerContent = styled.div`
  margin-top: 54px;
  padding: 20px;
  overflow-y: auto;

  &::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.colors.neutral.n0};
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.neutral.n6};
    border-radius: 10px;
  }
`;
