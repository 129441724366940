export const actionTypes = {
  RECOVER_ACCOUNT_START: 'RECOVER_ACCOUNT_START',
  RECOVER_ACCOUNT_SUCCESS: 'RECOVER_ACCOUNT_SUCCESS',
  RECOVER_ACCOUNT_ERROR: 'RECOVER_ACCOUNT_ERROR',
  RESET_PASSWORD_START: 'RESET_PASSWORD_START',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',
  SET_RESET_PASSWORD: 'SET_RESET_PASSWORD',
};

// RECOVER ACCOUNT ACTIONS
export const recoverAccountStart = (data) => ({
  type: actionTypes.RECOVER_ACCOUNT_START,
  data,
});

export const recoverAccountSuccess = (data) => ({
  type: actionTypes.RECOVER_ACCOUNT_SUCCESS,
  data,
});

export const recoverAccountError = (error) => ({
  type: actionTypes.RECOVER_ACCOUNT_ERROR,
  error,
});

// RESET PASSWORD ACTIONS
export const resetPasswordStart = (data) => ({
  type: actionTypes.RESET_PASSWORD_START,
  data,
});

export const resetPasswordSuccess = (data) => ({
  type: actionTypes.RESET_PASSWORD_SUCCESS,
  data,
});

export const resetPasswordError = (error) => ({
  type: actionTypes.RESET_PASSWORD_ERROR,
  error,
});

// SET RESET PASSWORD ACTIONS
export const setResetPassword = (data) => ({
  type: actionTypes.SET_RESET_PASSWORD,
  data,
});
