export default function imageResize(imgUrl, variantToUse = '_search') {
  if (
    imgUrl === undefined ||
    imgUrl === null ||
    imgUrl?.length < 0 ||
    imgUrl === '/' ||
    imgUrl === 'http://' ||
    imgUrl === 'https://' ||
    !imgUrl
  ) {
    return null;
  }
  const imageUrl = String(imgUrl);
  if (
    !imageUrl?.includes('cdn.roadstr.io') ||
    ['_detail', '_search', '_thumb']?.filter((el) => imageUrl?.includes(el))
      ?.length
  ) {
    return imageUrl;
  }
  const image = imageUrl?.split('.');
  if (image?.length > 1) {
    const preUrl = image.slice(0, image?.length - 1).join('.') + variantToUse;
    return [preUrl].concat(image[image?.length - 1]).join('.');
  }
  const relImage = [imageUrl].concat(variantToUse).join('');
  return relImage?.trim();
}
