import styled from 'styled-components';
import { Form as FinalForm } from 'react-final-form';

import Form from 'components/common/Form';

export const FinalFormWrapper = styled(FinalForm)``;

export const FormWrapper = styled(Form)`
  & > div {
    &:first-child {
      margin-bottom: 9px;
    }
  }
  & > button {
    margin-top: 20px;
    width: 100%;
  }
`;

export const ForgotPasswordWrapper = styled.div`
  margin: 11px 0 23px;
  text-align: right;
`;

export const ForgotPasswordText = styled.span`
  font-family: ${(props) => props.theme.typography.fontFamily.primary};
  font-size: ${(props) => props.theme.typography.fontSize.f3};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.colors.neutral.n7};
  letter-spacing: 0.02em;
  letter-spacing: 100%;
  cursor: pointer;
`;
