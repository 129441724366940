const ShareCircle = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_8077_107498)">
                <circle cx="20" cy="20" r="19" stroke="#1188FF" strokeWidth="2" />
                <path d="M30.3718 17.6312L22.414 10.4694C22.0681 10.1579 21.7147 10 21.3636 10C20.8763 10 20.3073 10.3286 20.3073 11.2545V15.6703C17.8785 15.8192 15.6187 16.7481 13.8918 18.3183C12.0269 20.0142 11 22.2623 11 24.6483C11 25.6761 11.1917 26.6855 11.5699 27.6484C11.6526 27.8591 11.874 28 12.1223 28C12.3707 28 12.5919 27.8591 12.6748 27.6484C13.8381 24.6862 16.8598 22.6215 20.3074 22.3764V26.7454C20.3074 27.6712 20.8764 27.9999 21.3636 27.9999C21.7146 27.9999 22.0681 27.8421 22.414 27.5307L30.3719 20.3686C30.7769 20.004 30.9998 19.518 30.9998 18.9999C30.9998 18.4819 30.7768 17.9957 30.3718 17.6312Z" fill="#1188FF" stroke="#1188FF" strokeWidth="1.6" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_8077_107498">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>

  )
}

export default ShareCircle