/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MapGL, { Marker } from 'react-map-gl';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';

import { ArrowBack, LocationArrowIcon } from 'public/assets/icons';
import {
  closeSidebarChooseLocation,
  addTags,
} from 'store/sideBarControler/actions';
import { getLocalStorage } from 'utils/hooks/useLocalStorage';
import { MapControls } from 'pages/map/components/mapControls/mapControls';
import { getPlaceName, searchPlace } from 'services';
import { LocationPlacesList } from 'components';
import { InputField } from '../../index';
import { SidebarWrapper } from '../index';
import { MapBoxContainer } from './ChooseLocation.style';

export const ChooseLocation = () => {
  const [locationInputValue, setLocationInputValue] = useState('');
  const [isOfferedPlacesModalOpen, setIsOfferedPlacesModalOpen] =
    useState(false);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const [submitLocationData, setSubmitLocationData] = useState(null);

  const userLocalData = JSON.parse(getLocalStorage('user_data'));
  const { location } = useSelector((state) => state.sideBarControler.tagsData);

  const { data } = userLocalData || { data: null };
  const { geoLocation } = data || { geoLocation: null };

  const { t } = useTranslation();

  const [viewport, setViewport] = useState({
    width: '100%',
    height: '100%',
    latitude: geoLocation?.coordinates[1],
    longitude: geoLocation?.coordinates[0],
    zoom: 6,
  });
  const [userCoordinates, setUserCoordinates] = useState([]);
  const [placesOffered, setPlacesOffered] = useState([]);

  const dispatch = useDispatch();
  const mapRef = useRef(null);
  const locationInputRef = useRef(null);

  useEffect(() => {
    if (location) {
      setTimeout(() => {
        setSubmitLocationData(location);
        setLocationInputValue(location.name || location.address);
        setViewport({
          ...viewport,
          latitude: Number(location.lat),
          longitude: Number(location.lng),
        });
      }, 5000);
    }
  }, [location]);

  useEffect(() => {
    if (isMapLoaded) {
      locationInputRef.current.focus();
    }
  }, [isMapLoaded]);

  const onPointClick = async (point) => {
    const { features } = await getPlaceName({ coordinates: point.lngLat });

    if (Array.isArray(features) && features?.length) {
      if (features[0].place_type[0]) {
        setLocationInputValue(features[0].place_name);
      } else {
        setLocationInputValue(
          `${point.lngLat[0].toFixed(6)},${point.lngLat[1].toFixed(6)}`
        );
      }
    }

    const [longitude, latitude] = point.lngLat;

    setSubmitLocationData({
      lat: latitude,
      lng: longitude,
      name: features[0]?.place_name,
      address: features[0]?.place_name,
    });
    setViewport({ ...viewport, latitude, longitude, zoom: 15 });
  };

  const onChangeUserLocation = ({ coords: { latitude, longitude } }) => {
    setUserCoordinates([longitude, latitude]);
  };

  const onChangePlace = (newText) => {
    setLocationInputValue(newText);

    const proximity = userCoordinates
      ? `${userCoordinates[0]},${userCoordinates[1]}`
      : '';

    const searchPlaceByText = async () => {
      const resp = await searchPlace({
        searchText: newText.trim(),
        proximity,
      });

      if (!resp) {
        return null;
      }

      const { features } = resp;
      if (features?.length) {
        setPlacesOffered(features);
        !isOfferedPlacesModalOpen && setIsOfferedPlacesModalOpen(true);
      } else {
        setPlacesOffered([]);
        setIsOfferedPlacesModalOpen(false);
      }
    };

    if (newText.length) {
      searchPlaceByText();
    } else {
      setIsOfferedPlacesModalOpen(false);
      setSubmitLocationData(null);
    }
  };

  const onPlaceOfferedClick = (place) => {
    setIsOfferedPlacesModalOpen(false);
    setLocationInputValue(place.place_name);

    const [longitude, latitude] = place.geometry.coordinates;

    setSubmitLocationData({
      lat: latitude,
      lng: longitude,
      name: place.place_name,
      address: place.place_name,
    });
    setViewport({ ...viewport, latitude, longitude, zoom: 13 });
  };

  const onSubmit = () => {
    dispatch(addTags({ type: 'location', tags: submitLocationData }));
    dispatch(closeSidebarChooseLocation());
  };

  const onMapLoad = () => {
    setIsMapLoaded(true);
  };

  return (
    <SidebarWrapper
      headerText={t('map:tagLocation')}
      headerIcon={<ArrowBack />}
      submitText={t('editUserProfile:save')}
      isSubmitAvailable={Boolean(submitLocationData)}
      onSubmit={onSubmit}
      onIconClick={() => dispatch(closeSidebarChooseLocation())}
      padding="20px"
    >
      <InputField
        value={locationInputValue}
        onChange={(e) => onChangePlace(e.target.value)}
        label={t('common:location')}
        crossIcon
        onCrossClick={() => {
          setLocationInputValue('');
          setSubmitLocationData(null);
          setPlacesOffered([]);
          setIsOfferedPlacesModalOpen(false);
        }}
        margin="0 0 20px 0"
        iconLeft={<LocationArrowIcon />}
        disabled={!isMapLoaded}
        ref={locationInputRef}
      />

      <MapBoxContainer>
        <MapGL
          ref={mapRef}
          {...viewport}
          mapStyle={process.env._MAPBOX_STYLE_URL}
          onViewportChange={(nextViewport) => setViewport(nextViewport)}
          mapboxApiAccessToken={process.env._MAPBOX_ACCESS_TOKEN}
          onClick={(evt) => onPointClick(evt)}
          onLoad={onMapLoad}
          attributionControl={false}
          interactiveLayerIds={[
            process.env._EVENT_PIN_LAYER_NAME,
            process.env._POI_PIN_LAYER_NAME,
            process.env._BUSINESS_MKPL_PIN_LAYER_NAME,
          ]}
        >
          <MapControls onGeolocate={onChangeUserLocation} />

          {submitLocationData && (
            <Marker
              key={submitLocationData.name}
              longitude={Number(submitLocationData.lng)}
              latitude={Number(submitLocationData.lat)}
              offsetLeft={-18}
              offsetTop={-43}
            >
              <Image
                src="/assets/img/pin.svg"
                alt="location_pin"
                width={36}
                height={43}
                unoptimized
                priority
              />
            </Marker>
          )}
        </MapGL>

        {isOfferedPlacesModalOpen && (
          <LocationPlacesList
            locationsList={placesOffered}
            onOutsideClick={() => setIsOfferedPlacesModalOpen(false)}
            onItemClick={(item) => onPlaceOfferedClick(item)}
          />
        )}
      </MapBoxContainer>
    </SidebarWrapper>
  );
};
