import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { anonymousClickHandler } from 'utils/anonymousClickHandler';
import { style } from 'utils/constants/style';
import { IPostText } from './IPostText';
import {
  TextContainer,
  Text,
  LinkText,
  SeeMoreWrap,
  SeeMoreText,
} from './PostText.style';

export const PostText = ({
  text,
  onHashtagClick,
  onMentionClick,
  enableSeeMore = false,
  charLimitForSeeMore = 100,
}: IPostText) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleTextExpansion = () => {
    setIsExpanded(!isExpanded);
  };
  const hashtagMentionFormatter = (string: string) => {
    return string
      .split(' ')
      .filter(Boolean)
      .map((v, i) => {
        if (v.includes('#')) {
          return (
            <Text
              isSimpleText={false}
              onClick={() => anonymousClickHandler(() => onHashtagClick(v))}
              key={i + v}
            >
              {v}
            </Text>
          );
        }
        if (v.includes('@')) {
          return (
            <Text
              onClick={() => anonymousClickHandler(() => onMentionClick(v))}
              key={i + v}
            >
              {v}
            </Text>
          );
        }
        if (v.includes('https') || v.includes('www')) {
          return (
            <LinkText href={v} key={i + v}>
              {v}
            </LinkText>
          );
        }
        return v;
      })
      .reduce((textArray, key) => {
        if (!textArray?.length) textArray[0] = '';
        if (typeof key === 'string')
          if (typeof textArray[textArray.length - 1] === 'object')
            textArray[textArray?.length] = ` ${key} `;
          else
            textArray[textArray?.length - 1] = `${
              textArray[textArray?.length - 1]
            } ${key} `;
        else textArray[textArray?.length] = key;

        return textArray;
      }, []);
  };
  const trimmedText = String(text).trim();
  const isTextLong = enableSeeMore && trimmedText.length > charLimitForSeeMore;
  // Function to truncate text at the nearest space after charLimitForSeeMore
  const truncateTextAtSpace = (text: string, limit: number) => {
    if (text.length <= limit) return text;
    const end = text.indexOf(' ', limit);
    return end === -1 ? text.slice(0, limit) : text.slice(0, end);
  };

  const textToUse =
    isExpanded || !isTextLong
      ? trimmedText
      : `${truncateTextAtSpace(
          String(trimmedText).trim(),
          charLimitForSeeMore
        )}...`;

  const formatedText = hashtagMentionFormatter(textToUse);

  return (
    <TextContainer>
      {formatedText}
      {isTextLong && (
        <SeeMoreWrap onClick={toggleTextExpansion}>
          <SeeMoreText>{isExpanded ? 'See Less' : 'See More'}</SeeMoreText>
        </SeeMoreWrap>
      )}
    </TextContainer>
  );
};
