const AppStore = () => {
  return (
    <svg
      width="132"
      height="44"
      viewBox="0 0 132 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7413_109894)">
        <path
          d="M121.488 -0.000101141H10.5176C10.1131 -0.000101141 9.71344 -0.000101141 9.31001 0.00209886C8.9723 0.00429886 8.63728 0.0106899 8.29632 0.0160689C7.55562 0.0247608 6.81665 0.0897427 6.08585 0.21045C5.35607 0.333785 4.64915 0.566306 3.98901 0.90015C3.32967 1.23682 2.72721 1.67428 2.2035 2.19668C1.67703 2.7176 1.23818 3.31972 0.903815 3.97987C0.568532 4.63872 0.336045 5.34473 0.214385 6.07353C0.0915684 6.8014 0.0254795 7.53767 0.0167118 8.27573C0.00647513 8.61299 0.0053941 8.95137 0 9.28867V34.7144C0.0053941 35.0559 0.00647513 35.3868 0.0167118 35.7284C0.0254822 36.4665 0.0915711 37.2027 0.214385 37.9305C0.335709 38.6598 0.56821 39.3662 0.903815 40.0253C1.23802 40.6833 1.67694 41.283 2.2035 41.801C2.72523 42.3257 3.32807 42.7635 3.98901 43.0976C4.64915 43.4323 5.35599 43.6662 6.08585 43.7915C6.81677 43.9112 7.55566 43.9762 8.29632 43.9859C8.63728 43.9934 8.9723 43.9977 9.31001 43.9977C9.71343 43.9999 10.1131 43.9999 10.5176 43.9999H121.488C121.885 43.9999 122.288 43.9999 122.684 43.9977C123.02 43.9977 123.365 43.9934 123.701 43.9859C124.44 43.9767 125.178 43.9117 125.907 43.7915C126.639 43.6653 127.349 43.4315 128.012 43.0976C128.672 42.7633 129.275 42.3255 129.796 41.801C130.321 41.2809 130.761 40.6817 131.099 40.0253C131.433 39.3657 131.663 38.6593 131.782 37.9305C131.905 37.2026 131.974 36.4665 131.987 35.7284C131.991 35.3868 131.991 35.0559 131.991 34.7144C132 34.3147 132 33.9173 132 33.5112V10.4896C132 10.0868 132 9.6872 131.991 9.28867C131.991 8.95137 131.991 8.61299 131.987 8.27568C131.974 7.53756 131.905 6.80146 131.782 6.07348C131.662 5.34511 131.432 4.63915 131.099 3.97983C130.419 2.65661 129.339 1.57953 128.012 0.900051C127.349 0.567023 126.639 0.334563 125.907 0.210351C125.178 0.0891119 124.44 0.0241074 123.701 0.0159149C123.365 0.0105469 123.02 0.00410086 122.684 0.00195586C122.288 -0.000244141 121.885 -0.000244141 121.488 -0.000244141V-0.000101141Z"
          fill="#A6A6A6"
        />
        <path
          d="M9.31527 43.0374C8.97919 43.0374 8.65122 43.0331 8.31776 43.0256C7.62699 43.0167 6.93783 42.9567 6.25594 42.8462C5.62011 42.737 5.00416 42.5339 4.42841 42.2435C3.85794 41.9556 3.33762 41.578 2.88739 41.1253C2.43065 40.6779 2.05039 40.159 1.76168 39.5891C1.46979 39.0156 1.26779 38.4008 1.1627 37.7662C1.04922 37.0843 0.987825 36.3948 0.97904 35.7037C0.972046 35.4717 0.962891 34.6993 0.962891 34.6993V9.28868C0.962891 9.28868 0.972642 8.52813 0.979095 8.30468C0.987507 7.61463 1.04855 6.92622 1.16169 6.24541C1.26697 5.60908 1.46913 4.99253 1.76117 4.4171C2.04882 3.84764 2.42697 3.32835 2.88096 2.87935C3.33444 2.42609 3.85642 2.04657 4.42787 1.75463C5.00229 1.46521 5.61708 1.26351 6.25162 1.1563C6.93576 1.04472 7.62731 0.984398 8.32048 0.975833L9.31581 0.962402H122.679L123.686 0.976372C124.373 0.984511 125.058 1.0443 125.736 1.15522C126.377 1.26377 126.998 1.46688 127.579 1.75786C128.724 2.34619 129.655 3.27698 130.243 4.41978C130.53 4.99124 130.729 5.60276 130.833 6.23359C130.948 6.91999 131.012 7.61381 131.025 8.30952C131.028 8.62104 131.028 8.95566 131.028 9.28868C131.037 9.70118 131.037 10.0938 131.037 10.4896V33.5112C131.037 33.9108 131.037 34.3008 131.028 34.6939C131.028 35.0516 131.028 35.3793 131.024 35.7166C131.011 36.3999 130.948 37.0813 130.835 37.7554C130.732 38.3946 130.532 39.0142 130.24 39.5924C129.949 40.1561 129.571 40.6705 129.119 41.1167C128.669 41.5719 128.148 41.9516 127.576 42.2414C126.996 42.534 126.376 42.7379 125.736 42.8462C125.054 42.9573 124.365 43.0173 123.674 43.0256C123.351 43.0331 123.012 43.0374 122.684 43.0374L121.488 43.0396L9.31527 43.0374Z"
          fill="black"
        />
        <path
          d="M27.3227 22.3306C27.3346 21.4124 27.5792 20.5122 28.0337 19.7137C28.4882 18.9151 29.1378 18.2442 29.9222 17.7633C29.4239 17.0536 28.7665 16.4696 28.0023 16.0576C27.238 15.6456 26.388 15.4169 25.5196 15.3898C23.6673 15.196 21.8715 16.4952 20.9276 16.4952C19.9654 16.4952 18.5121 15.4091 16.9471 15.4412C15.9348 15.4738 14.9483 15.7674 14.0836 16.2932C13.2189 16.8191 12.5056 17.5593 12.0131 18.4419C9.8797 22.1251 11.471 27.5383 13.5146 30.5155C14.537 31.9734 15.732 33.6019 17.2956 33.5442C18.8256 33.4809 19.3971 32.5712 21.244 32.5712C23.0737 32.5712 23.6099 33.5442 25.2052 33.5075C26.847 33.4809 27.8814 32.0431 28.868 30.5714C29.6026 29.5326 30.1679 28.3845 30.5429 27.1696C29.5891 26.7673 28.7751 26.0939 28.2024 25.2334C27.6298 24.3729 27.3239 23.3633 27.3227 22.3306Z"
          fill="white"
        />
        <path
          d="M24.3098 13.432C25.2049 12.3603 25.646 10.9829 25.5392 9.59229C24.1715 9.73553 22.9082 10.3873 22.0009 11.4179C21.5573 11.9213 21.2176 12.507 21.0011 13.1414C20.7846 13.7759 20.6957 14.4466 20.7393 15.1154C21.4234 15.1224 22.1001 14.9746 22.7186 14.683C23.337 14.3914 23.8811 13.9636 24.3098 13.432Z"
          fill="white"
        />
        <path
          d="M46.6614 29.8537H41.4401L40.1862 33.5458H37.9746L42.9202 19.886H45.2179L50.1635 33.5458H47.9142L46.6614 29.8537ZM41.9808 28.15H46.1196L44.0793 22.158H44.0222L41.9808 28.15Z"
          fill="white"
        />
        <path
          d="M60.845 28.5666C60.845 31.6614 59.1839 33.6498 56.6772 33.6498C56.0422 33.6829 55.4107 33.5371 54.855 33.2289C54.2992 32.9207 53.8418 32.4628 53.5349 31.9074H53.4875V36.8402H51.4375V23.5865H53.4218V25.243H53.4595C53.7805 24.6902 54.2457 24.2346 54.8056 23.9246C55.3656 23.6146 55.9994 23.4617 56.6395 23.4823C59.1742 23.4823 60.845 25.4804 60.845 28.5666ZM58.7379 28.5666C58.7379 26.5503 57.693 25.2247 56.0987 25.2247C54.5324 25.2247 53.4789 26.5782 53.4789 28.5666C53.4789 30.5732 54.5324 31.9171 56.0987 31.9171C57.693 31.9171 58.7379 30.6012 58.7379 28.5666Z"
          fill="white"
        />
        <path
          d="M71.8353 28.5666C71.8353 31.6614 70.1742 33.6498 67.6674 33.6498C67.0324 33.6829 66.4009 33.537 65.8452 33.2289C65.2895 32.9207 64.832 32.4628 64.5251 31.9074H64.4777V36.8402H62.4277V23.5865H64.412V25.2429H64.4497C64.7706 24.6902 65.2359 24.2346 65.7958 23.9246C66.3558 23.6146 66.9896 23.4617 67.6297 23.4823C70.1645 23.4823 71.8353 25.4803 71.8353 28.5666ZM69.7282 28.5666C69.7282 26.5503 68.6833 25.2247 67.089 25.2247C65.5227 25.2247 64.4691 26.5782 64.4691 28.5666C64.4691 30.5732 65.5227 31.9171 67.089 31.9171C68.6833 31.9171 69.7282 30.6012 69.7282 28.5666H69.7282Z"
          fill="white"
        />
        <path
          d="M79.1024 29.7398C79.2543 31.0944 80.5739 31.9838 82.3772 31.9838C84.1051 31.9838 85.3482 31.0944 85.3482 29.873C85.3482 28.8127 84.5984 28.1779 82.8232 27.7428L81.0479 27.3163C78.5325 26.7105 77.3648 25.5374 77.3648 23.6339C77.3648 21.2771 79.4245 19.6582 82.3492 19.6582C85.2437 19.6582 87.228 21.2771 87.2948 23.6339H85.2254C85.1015 22.2707 83.9715 21.4479 82.3201 21.4479C80.6687 21.4479 79.5386 22.2804 79.5386 23.4921C79.5386 24.4578 80.2604 25.0261 82.026 25.4611L83.5352 25.8306C86.3457 26.4934 87.5134 27.6192 87.5134 29.6173C87.5134 32.1728 85.472 33.7734 82.2253 33.7734C79.1875 33.7734 77.1364 32.2104 77.0039 29.7397L79.1024 29.7398Z"
          fill="white"
        />
        <path
          d="M91.9356 21.2297V23.5866H93.8348V25.2054H91.9356V30.6958C91.9356 31.5487 92.3159 31.9461 93.1507 31.9461C93.3762 31.9422 93.6013 31.9264 93.8251 31.8988V33.508C93.4497 33.578 93.0682 33.6096 92.6864 33.6026C90.6645 33.6026 89.8759 32.8452 89.8759 30.9138V25.2054H88.4238V23.5866H89.8759V21.2297H91.9356Z"
          fill="white"
        />
        <path
          d="M94.9355 28.5666C94.9355 25.4332 96.7862 23.4641 99.6721 23.4641C102.568 23.4641 104.41 25.4331 104.41 28.5666C104.41 31.7087 102.577 33.6692 99.6721 33.6692C96.7679 33.6692 94.9355 31.7087 94.9355 28.5666ZM102.321 28.5666C102.321 26.4171 101.333 25.1485 99.6721 25.1485C98.011 25.1485 97.0243 26.4268 97.0243 28.5666C97.0243 30.7247 98.011 31.9837 99.6721 31.9837C101.333 31.9837 102.321 30.7247 102.321 28.5666H102.321Z"
          fill="white"
        />
        <path
          d="M106.1 23.5867H108.055V25.2818H108.102C108.235 24.7523 108.546 24.2845 108.983 23.9568C109.42 23.6291 109.958 23.4616 110.504 23.4824C110.741 23.4816 110.976 23.5072 111.207 23.5587V25.4708C110.909 25.3799 110.597 25.3382 110.286 25.3473C109.988 25.3352 109.691 25.3876 109.415 25.5008C109.14 25.614 108.892 25.7853 108.689 26.0029C108.486 26.2206 108.333 26.4795 108.24 26.7619C108.147 27.0442 108.116 27.3433 108.15 27.6386V33.5457H106.1L106.1 23.5867Z"
          fill="white"
        />
        <path
          d="M120.657 30.6206C120.382 32.4285 118.616 33.6692 116.357 33.6692C113.452 33.6692 111.648 31.7281 111.648 28.6139C111.648 25.4901 113.461 23.4641 116.271 23.4641C119.034 23.4641 120.772 25.3569 120.772 28.3765V29.0769H113.718V29.2004C113.685 29.567 113.731 29.9362 113.853 30.2836C113.974 30.631 114.169 30.9487 114.423 31.2155C114.677 31.4823 114.985 31.6921 115.327 31.8311C115.668 31.97 116.036 32.0349 116.404 32.0213C116.889 32.0666 117.375 31.9547 117.791 31.7023C118.206 31.4499 118.529 31.0705 118.711 30.6205L120.657 30.6206ZM113.727 27.6482H118.72C118.739 27.3186 118.689 26.9888 118.573 26.6795C118.458 26.3702 118.279 26.088 118.049 25.8506C117.819 25.6133 117.543 25.4259 117.237 25.3003C116.931 25.1747 116.602 25.1135 116.271 25.1206C115.937 25.1186 115.606 25.1825 115.297 25.3087C114.988 25.435 114.707 25.6209 114.471 25.8559C114.235 26.0909 114.047 26.3703 113.92 26.6779C113.792 26.9855 113.727 27.3153 113.727 27.6482V27.6482Z"
          fill="white"
        />
        <path
          d="M41.7251 9.60364C42.1549 9.57288 42.5862 9.63763 42.9878 9.79321C43.3895 9.94878 43.7515 10.1913 44.0477 10.5033C44.344 10.8153 44.5672 11.189 44.7011 11.5974C44.835 12.0058 44.8764 12.4387 44.8222 12.865C44.8222 14.9618 43.6857 16.1672 41.7251 16.1672H39.3477V9.60364H41.7251ZM40.3699 15.2389H41.6109C41.918 15.2572 42.2254 15.207 42.5105 15.092C42.7957 14.9769 43.0516 14.7998 43.2595 14.5737C43.4675 14.3476 43.6222 14.0781 43.7125 13.7848C43.8028 13.4915 43.8264 13.1819 43.7815 12.8783C43.8231 12.576 43.7972 12.2683 43.7055 11.9771C43.6138 11.686 43.4587 11.4187 43.2512 11.1944C43.0438 10.9701 42.7891 10.7943 42.5055 10.6796C42.222 10.565 41.9165 10.5143 41.6109 10.5312H40.3699V15.2389Z"
          fill="white"
        />
        <path
          d="M45.9759 13.6882C45.9447 13.3627 45.982 13.0342 46.0856 12.724C46.1891 12.4137 46.3565 12.1284 46.577 11.8864C46.7976 11.6445 47.0664 11.4511 47.3663 11.3188C47.6662 11.1865 47.9905 11.1182 48.3184 11.1182C48.6463 11.1182 48.9706 11.1865 49.2705 11.3188C49.5703 11.4511 49.8392 11.6445 50.0597 11.8864C50.2803 12.1284 50.4477 12.4137 50.5512 12.724C50.6547 13.0342 50.692 13.3627 50.6608 13.6882C50.6926 14.014 50.6557 14.3429 50.5525 14.6537C50.4493 14.9645 50.282 15.2503 50.0614 15.4927C49.8407 15.7352 49.5717 15.9289 49.2715 16.0615C48.9714 16.1941 48.6467 16.2626 48.3184 16.2626C47.9901 16.2626 47.6654 16.1941 47.3652 16.0615C47.065 15.9289 46.796 15.7352 46.5754 15.4927C46.3548 15.2503 46.1875 14.9645 46.0842 14.6537C45.981 14.3429 45.9441 14.014 45.9759 13.6882ZM49.6525 13.6882C49.6525 12.6145 49.1688 11.9866 48.32 11.9866C47.4679 11.9866 46.9886 12.6145 46.9886 13.6882C46.9886 14.7705 47.4679 15.3935 48.32 15.3935C49.1689 15.3935 49.6525 14.7662 49.6525 13.6882H49.6525Z"
          fill="white"
        />
        <path
          d="M56.8908 16.1671H55.8739L54.8473 12.519H54.7698L53.7475 16.1671H52.7403L51.3711 11.2139H52.3654L53.2552 14.9935H53.3284L54.3497 11.2139H55.2901L56.3113 14.9935H56.3889L57.2743 11.2139H58.2546L56.8908 16.1671Z"
          fill="white"
        />
        <path
          d="M59.4063 11.214H60.3499V12.0009H60.4232C60.5474 11.7183 60.757 11.4814 61.0227 11.3232C61.2884 11.165 61.597 11.0934 61.9054 11.1184C62.1471 11.1003 62.3898 11.1366 62.6155 11.2247C62.8412 11.3128 63.0441 11.4504 63.2092 11.6274C63.3743 11.8043 63.4974 12.016 63.5693 12.2468C63.6413 12.4776 63.6602 12.7216 63.6247 12.9607V16.1672H62.6444V13.2062C62.6444 12.4102 62.2976 12.0143 61.5726 12.0143C61.4085 12.0067 61.2447 12.0345 61.0924 12.0959C60.9401 12.1574 60.8029 12.2509 60.6903 12.3701C60.5776 12.4893 60.4921 12.6314 60.4397 12.7867C60.3873 12.9419 60.3691 13.1066 60.3865 13.2695V16.1672H59.4062L59.4063 11.214Z"
          fill="white"
        />
        <path
          d="M65.1855 9.28027H66.1658V16.1671H65.1855V9.28027Z"
          fill="white"
        />
        <path
          d="M67.5286 13.6883C67.4974 13.3627 67.5348 13.0343 67.6383 12.724C67.7419 12.4137 67.9093 12.1284 68.1299 11.8864C68.3505 11.6445 68.6193 11.4511 68.9192 11.3188C69.2191 11.1865 69.5434 11.1182 69.8713 11.1182C70.1993 11.1182 70.5236 11.1865 70.8235 11.3188C71.1234 11.4511 71.3922 11.6445 71.6128 11.8864C71.8334 12.1284 72.0008 12.4137 72.1044 12.724C72.2079 13.0343 72.2453 13.3627 72.2141 13.6883C72.2458 14.0141 72.2089 14.343 72.1056 14.6538C72.0024 14.9646 71.835 15.2504 71.6144 15.4928C71.3938 15.7353 71.1247 15.929 70.8245 16.0616C70.5243 16.1942 70.1997 16.2627 69.8713 16.2627C69.543 16.2627 69.2183 16.1942 68.9182 16.0616C68.618 15.929 68.3489 15.7353 68.1283 15.4928C67.9077 15.2504 67.7403 14.9646 67.6371 14.6538C67.5338 14.343 67.4969 14.0141 67.5286 13.6883ZM71.2052 13.6883C71.2052 12.6146 70.7215 11.9867 69.8727 11.9867C69.0206 11.9867 68.5413 12.6146 68.5413 13.6883C68.5413 14.7706 69.0206 15.3936 69.8727 15.3936C70.7215 15.3936 71.2052 14.7663 71.2052 13.6883H71.2052Z"
          fill="white"
        />
        <path
          d="M73.2461 14.7662C73.2461 13.8746 73.9118 13.3606 75.0935 13.2875L76.439 13.2102V12.7827C76.439 12.2595 76.0921 11.9641 75.4221 11.9641C74.8749 11.9641 74.4957 12.1645 74.3869 12.5146H73.4378C73.538 11.6639 74.3406 11.1182 75.4674 11.1182C76.7126 11.1182 77.415 11.7364 77.415 12.7827V16.167H76.4713V15.4709H76.3938C76.2363 15.7206 76.0153 15.9241 75.7532 16.0608C75.491 16.1974 75.1973 16.2622 74.9018 16.2486C74.6933 16.2702 74.4825 16.248 74.2831 16.1835C74.0837 16.1189 73.9001 16.0134 73.7441 15.8737C73.5881 15.7341 73.4632 15.5633 73.3774 15.3726C73.2917 15.1818 73.2469 14.9753 73.2461 14.7662ZM76.439 14.343V13.9289L75.2261 14.0062C74.542 14.0518 74.2318 14.2839 74.2318 14.7205C74.2318 15.1663 74.6196 15.4258 75.1528 15.4258C75.3091 15.4415 75.4669 15.4258 75.6169 15.3795C75.767 15.3332 75.9061 15.2573 76.0261 15.1563C76.1461 15.0552 76.2445 14.9312 76.3154 14.7914C76.3864 14.6517 76.4284 14.4992 76.439 14.343Z"
          fill="white"
        />
        <path
          d="M78.7031 13.6883C78.7031 12.1232 79.51 11.1317 80.765 11.1317C81.0754 11.1174 81.3835 11.1916 81.6532 11.3455C81.9229 11.4994 82.1432 11.7266 82.2882 12.0007H82.3614V9.28027H83.3417V16.1671H82.4024V15.3845H82.3248C82.1686 15.6568 81.9407 15.8812 81.6657 16.0335C81.3908 16.1859 81.0793 16.2603 80.765 16.2487C79.5014 16.2488 78.7031 15.2572 78.7031 13.6883ZM79.7158 13.6883C79.7158 14.7389 80.2124 15.3711 81.0429 15.3711C81.8691 15.3711 82.3798 14.7298 82.3798 13.6926C82.3798 12.6603 81.8638 12.0099 81.0429 12.0099C80.2177 12.0099 79.7157 12.6463 79.7157 13.6883H79.7158Z"
          fill="white"
        />
        <path
          d="M87.3978 13.6882C87.3666 13.3627 87.4039 13.0342 87.5074 12.724C87.6109 12.4137 87.7783 12.1284 87.9989 11.8864C88.2194 11.6445 88.4883 11.4511 88.7882 11.3188C89.088 11.1865 89.4123 11.1182 89.7402 11.1182C90.0682 11.1182 90.3925 11.1865 90.6923 11.3188C90.9922 11.4511 91.261 11.6445 91.4816 11.8864C91.7021 12.1284 91.8695 12.4137 91.9731 12.724C92.0766 13.0342 92.1139 13.3627 92.0827 13.6882C92.1145 14.014 92.0776 14.3429 91.9744 14.6537C91.8711 14.9645 91.7038 15.2503 91.4832 15.4927C91.2626 15.7352 90.9936 15.9289 90.6934 16.0615C90.3932 16.1941 90.0685 16.2626 89.7402 16.2626C89.4119 16.2626 89.0873 16.1941 88.7871 16.0615C88.4869 15.9289 88.2179 15.7352 87.9973 15.4927C87.7767 15.2503 87.6094 14.9645 87.5061 14.6537C87.4029 14.3429 87.366 14.014 87.3978 13.6882ZM91.0744 13.6882C91.0744 12.6145 90.5907 11.9866 89.7419 11.9866C88.8898 11.9866 88.4104 12.6145 88.4104 13.6882C88.4104 14.7705 88.8898 15.3935 89.7419 15.3935C90.5907 15.3935 91.0744 14.7662 91.0744 13.6882Z"
          fill="white"
        />
        <path
          d="M93.3984 11.214H94.3421V12.0009H94.4154C94.5396 11.7183 94.7492 11.4814 95.0149 11.3232C95.2806 11.165 95.5892 11.0934 95.8976 11.1184C96.1393 11.1003 96.382 11.1366 96.6077 11.2247C96.8334 11.3128 97.0363 11.4504 97.2014 11.6274C97.3665 11.8043 97.4896 12.016 97.5615 12.2468C97.6334 12.4776 97.6524 12.7216 97.6169 12.9607V16.1672H96.6366V13.2062C96.6366 12.4102 96.2897 12.0143 95.5648 12.0143C95.4007 12.0067 95.2369 12.0345 95.0846 12.0959C94.9323 12.1574 94.7951 12.2509 94.6825 12.3701C94.5698 12.4893 94.4843 12.6314 94.4319 12.7867C94.3795 12.9419 94.3613 13.1066 94.3787 13.2695V16.1672H93.3984V11.214Z"
          fill="white"
        />
        <path
          d="M103.157 9.98071V11.2365H104.233V12.0599H103.157V14.6068C103.157 15.1257 103.371 15.3529 103.859 15.3529C103.984 15.3525 104.109 15.3449 104.233 15.3303V16.1446C104.057 16.176 103.878 16.1927 103.7 16.1945C102.609 16.1945 102.175 15.8121 102.175 14.8571V12.0598H101.387V11.2364H102.175V9.98071H103.157Z"
          fill="white"
        />
        <path
          d="M105.57 9.28027H106.542V12.0099H106.62C106.75 11.7246 106.965 11.4864 107.236 11.3276C107.507 11.1688 107.821 11.0972 108.134 11.1226C108.375 11.1095 108.615 11.1495 108.838 11.2398C109.061 11.33 109.262 11.4683 109.425 11.6446C109.588 11.821 109.711 12.0312 109.783 12.2601C109.856 12.4891 109.877 12.7312 109.845 12.9691V16.1671H108.863V13.2103C108.863 12.4191 108.494 12.0184 107.801 12.0184C107.633 12.0047 107.463 12.0278 107.305 12.0861C107.146 12.1445 107.002 12.2367 106.883 12.3563C106.764 12.4759 106.673 12.62 106.615 12.7786C106.558 12.9371 106.536 13.1063 106.551 13.2742V16.1671H105.57L105.57 9.28027Z"
          fill="white"
        />
        <path
          d="M115.56 14.8297C115.427 15.2824 115.138 15.6739 114.745 15.9359C114.351 16.1979 113.878 16.3135 113.407 16.2627C113.08 16.2713 112.755 16.2088 112.454 16.0795C112.154 15.9502 111.885 15.7573 111.667 15.514C111.448 15.2708 111.286 14.983 111.19 14.6708C111.095 14.3586 111.068 14.0294 111.113 13.706C111.069 13.3817 111.096 13.0517 111.192 12.7386C111.287 12.4255 111.449 12.1364 111.666 11.891C111.883 11.6457 112.151 11.4497 112.45 11.3163C112.75 11.183 113.075 11.1155 113.403 11.1182C114.785 11.1182 115.619 12.0598 115.619 13.6152V13.9563H112.112V14.0111C112.096 14.1929 112.119 14.3759 112.179 14.5483C112.239 14.7207 112.334 14.8788 112.458 15.0124C112.583 15.146 112.734 15.2522 112.902 15.3241C113.07 15.396 113.252 15.4322 113.434 15.4301C113.669 15.4582 113.906 15.4161 114.117 15.3092C114.327 15.2022 114.501 15.0353 114.616 14.8296L115.56 14.8297ZM112.112 13.2334H114.62C114.633 13.0671 114.61 12.9002 114.554 12.7432C114.498 12.5862 114.409 12.4427 114.294 12.3218C114.179 12.201 114.04 12.1055 113.885 12.0415C113.731 11.9775 113.565 11.9464 113.398 11.9502C113.228 11.9481 113.06 11.9799 112.903 12.0436C112.746 12.1073 112.603 12.2018 112.483 12.3214C112.363 12.4409 112.269 12.5832 112.205 12.7399C112.141 12.8965 112.109 13.0643 112.112 13.2334H112.112Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_7413_109894">
          <rect width="132" height="44" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AppStore;
