/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import UploadImage from 'components/common/UploadImage';
import { InputDescription, InputDescriptionField } from 'components';
import CreatePostAddTags from 'containers/CreatePostAddTags';
import SidebarSelectTags from 'containers/SidebarSelectTags';
import UploadMultipleImages from 'containers/UploadMultipleImages';
import CreatePostQuickTags from 'containers/CreatePostQuickTags';
import SchedulePost from 'containers/SchedulePost';
import ChooseLocationDrawer from 'containers/ChooseLocationDrawer';
import { closeSideBarCreatePhotoPost } from 'store/sideBarControler/actions';
import { composeValidators, required } from 'utils/validators';
import FieldLocation from 'components/common/FieldLocation';
import { ICreatePhotoPostForm } from './ICreatePhotoPostForm';

import {
  FinalFormWrapper,
  FormWrapper,
  SectionHeader,
  SectionWrapper,
} from './CreatePhotoPostForm.styles';
import Groups from './Groups';
import Users from './Users';
import Events from './Events';

const CreatePhotoPostForm = (props: ICreatePhotoPostForm) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [openTagDrawer, setOpenTagDrawer] = useState<boolean>(false);
  const [openChooseLocationDrawer, setOpenChooseLocationDrawer] =
    useState<boolean>(false);
  const [tagType, setTagType] = useState<string>('');
  const [images, setImages] = useState<Array<any>>([]);
  const [data, setData] = useState<any>({
    groups: null,
    users: null,
    events: null,
    location: null,
  });
  const [searchedItems, setSearchedItems] = useState<Array<any>>([]);
  const [quickTags, setQuickTags] = useState<Array<any>>([]);
  const [scheduledPostData, setScheduledPostData] = useState<{
    date: any;
    error: string;
  }>({
    date: new Date(),
    error: '',
  });

  return (
    <>
      <FinalFormWrapper
        {...props}
        render={(formRenderProps: FormRenderProps) => {
          const { handleSubmit, form, values, initialValues } = formRenderProps;

          const {
            inProgress,
            ready,
            interests,
            privateFeatures,
            onIconClick,
            variant,
          } = props;
          const submitAbility =
            values?.images?.length && values?.description?.trim();

          return (
            <FormWrapper
              onSubmit={handleSubmit}
              onIconClick={onIconClick}
              isHeaderBorder
              headerText={
                variant === 'create'
                  ? t('selectPhotos:createPhotoPost')
                  : t('selectPhotos:editPhotoPost')
              }
              submitText={t('editUserProfile:Save')}
              disabled={!submitAbility}
            >
              <UploadImage name="images" label="Add a photo" />
              <SectionWrapper>
                <SectionHeader type="h3">
                  {t('event:mainInformation')}
                </SectionHeader>
                <InputDescriptionField
                  placeholder={t('common:addADescription')}
                  label={t('common:addADescription')}
                  name="description"
                  showCharactersNumber
                  maxLength={500}
                  height={88}
                  validate={composeValidators(
                    required(t('error:thisFieldIsRequired'))
                  )}
                />
              </SectionWrapper>

              <SectionWrapper>
                <SectionHeader type="h3">{t('common:addTags')}</SectionHeader>
                <Groups />
                <Users />
                <Events />
                <FieldLocation
                  name="location"
                  label={t('addNewGroup:cityOrLocation')}
                  headerText={t('map:tagLocation')}
                  barIcon
                />
              </SectionWrapper>
              {/* <Field name="addTags">
                {({ input }) => {

                  // form.change('addTags', data);
                  return (
                    <>
                      <CreatePostAddTags
                        tagsData={input.value}
                        setOpen={setOpenTagDrawer}
                        setOpenChooseLocationDrawer={
                          setOpenChooseLocationDrawer
                        }
                        setData={input.onChange}
                        setTagType={setTagType}
                        {...input}
                      />
                      <SidebarSelectTags
                        isOpenSideBarTag={openTagDrawer}
                        onSubmit={(e) => {
                          input.onChange({ ...input.value, [tagType]: e });
                          // setData({ ...data, [input.value]: e });
                          setSearchedItems([]);
                        }}
                        setOpen={setOpenTagDrawer}
                        type={tagType}
                        setIsDrawerOpen={props.setIsDrawerOpen}
                        searchedItems={searchedItems}
                        setSearchedItems={setSearchedItems}
                      />
                    </>
                  );
                }}
              </Field> */}
              <Field name="quickTags">
                {({ input }) => {
                  return (
                    <CreatePostQuickTags
                      interests={interests}
                      quickTags={input.value}
                      setQuickTags={input.onChange}
                      {...input}
                    />
                  );
                }}
              </Field>
              {privateFeatures && (
                <Field name="schedulePost">
                  {({ input }) => {
                    form.change('schedulePost', scheduledPostData);
                    return (
                      <SchedulePost
                        scheduledPostData={scheduledPostData}
                        setScheduledPostData={setScheduledPostData}
                        {...input}
                      />
                    );
                  }}
                </Field>
              )}
            </FormWrapper>
          );
        }}
      />
      {/* <SidebarSelectTags
        isOpenSideBarTag={openTagDrawer}
        onSubmit={(e) => {
          setData({ ...data, [tagType]: e });
          setSearchedItems([]);
        }}
        setOpen={setOpenTagDrawer}
        type={tagType}
        setIsDrawerOpen={props.setIsDrawerOpen}
        searchedItems={searchedItems}
        setSearchedItems={setSearchedItems}
      /> */}
      {/* <ChooseLocationDrawer
        open={openChooseLocationDrawer}
        handleSubmit={(e) => setData({ ...data, location: e })}
        setOpenChooseLocationDrawer={setOpenChooseLocationDrawer}
      /> */}
    </>
  );
};

export default CreatePhotoPostForm;
