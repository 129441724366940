import React from 'react';

import { IErrorMessage } from './IErrorMessage';
import { Wrapper } from './ErrorMessage.style';

export const ErrorMessage = ({ text, mt, mb }: IErrorMessage) => (
  <Wrapper mb={mb} mt={mt}>
    {text}
  </Wrapper>
);
