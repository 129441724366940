export const actionTypes = {
  GET_USER_NOTIFICATIONS_START: 'GET_USER_NOTIFICATIONS_START',
  GET_USER_NOTIFICATIONS_SUCCESS: 'GET_USER_NOTIFICATIONS_SUCCESS',
  GET_USER_NOTIFICATIONS_ERROR: 'GET_USER_NOTIFICATIONS_ERROR',
  CLEAR_NOTIFICATIONS_STORE: 'CLEAR_NOTIFICATIONS_STORE',
  GET_USER_PENDING_NOTIFICATIONS_SUCCESS: 'GET_PENDING_NOTIFICATIONS_SUCCESS',
  GET_USER_PENDING_NOTIFICATIONS_ERROR: 'GET_PENDING_NOTIFICATIONS_SUCCESS',
  ACCEPT_PENDING_REQUEST_START: 'ACCEPT_PENDING_REQUEST_START',
  ACCEPT_PENDING_REQUEST_SUCCESS: 'ACCEPT_PENDING_REQUEST_SUCCESS',
  ACCEPT_PENDING_REQUEST_ERROR: 'ACCEPT_PENDING_REQUEST_ERROR',
  DECLINE_PENDING_REQUEST_START: 'DECLINE_PENDING_REQUEST_START',
  DECLINE_PENDING_REQUEST_SUCCESS: 'DECLINE_PENDING_REQUEST_SUCCESS',
  DECLINE_PENDING_REQUEST_ERROR: 'DECLINE_PENDING_REQUEST_ERROR',
};

// GET USER NOTIFICATIONS ACTIONS
export const getUserNotificationsStart = (data) => ({
  type: actionTypes.GET_USER_NOTIFICATIONS_START,
  data,
});

export const getUserNotificationsSuccess = (data) => ({
  type: actionTypes.GET_USER_NOTIFICATIONS_SUCCESS,
  data,
});

export const getUserNotificationsError = (error) => ({
  type: actionTypes.GET_USER_NOTIFICATIONS_ERROR,
  error,
});

// GET USER PENDING NOTIFICATIONS ACTIONS
export const getUserPendingNotificationsSuccess = (data) => ({
  type: actionTypes.GET_USER_PENDING_NOTIFICATIONS_SUCCESS,
  data,
});

export const getUserPendingNotificationsError = (error) => ({
  type: actionTypes.GET_USER_PENDING_NOTIFICATIONS_ERROR,
  error,
});

// ACCEPT PENDING REQUEST ACTIONS
export const acceptPendingRequestStart = (data) => ({
  type: actionTypes.ACCEPT_PENDING_REQUEST_START,
  data,
});

export const acceptPendingRequestSuccess = (data) => ({
  type: actionTypes.ACCEPT_PENDING_REQUEST_SUCCESS,
  data,
});

export const acceptPendingRequestError = (error) => ({
  type: actionTypes.ACCEPT_PENDING_REQUEST_ERROR,
  error,
});

// DECLINE PENDING REQUEST ACTIONS
export const declinePendingRequestStart = (data) => ({
  type: actionTypes.DECLINE_PENDING_REQUEST_START,
  data,
});

export const declinePendingRequestSuccess = (data) => ({
  type: actionTypes.DECLINE_PENDING_REQUEST_SUCCESS,
  data,
});

export const declinePendingRequestError = (error) => ({
  type: actionTypes.DECLINE_PENDING_REQUEST_ERROR,
  error,
});

// CLEAR NOTIFICATIONS STORE ACTIONS
export const clearNotificationsStore = () => ({
  type: actionTypes.CLEAR_NOTIFICATIONS_STORE,
});
