import { ICheckBox } from './ICheckBox';

import { CheckBoxWrapper, CheckBoxInput, CheckMark } from './CheckBox.styles';

const CheckBox = ({
  type = 'checkbox',
  name,
  htmlFor,
  inputId,
  disabled,
  checked,
  children,
}: ICheckBox) => {
  return (
    <CheckBoxWrapper htmlFor={htmlFor}>
      {children}
      <div>
        <CheckBoxInput
          id={type === 'radio' ? htmlFor : inputId}
          type={type}
          name={name}
          disabled={disabled}
          checked={checked}
          onChange={() => {
            // console.log('e')
          }}
        />
        <CheckMark type={type} disabled={disabled} />
      </div>
    </CheckBoxWrapper>
  );
};

export default CheckBox;
