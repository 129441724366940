import { ReactNode } from 'react';

import { ConfiguringPoverWrap } from './Popover.style';
import { Popover } from './Popover';

export const ConfiguringPopoverComponent = ({
  children,
}: {
  children?: ReactNode;
}) => (
  <ConfiguringPoverWrap>
    <div>
      <Popover variant="withoutArrow" width={80}>
        {children}
      </Popover>
    </div>
  </ConfiguringPoverWrap>
);
