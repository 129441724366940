import React from 'react';

export const NotificationsSettingsIcon = ({
  color = '#454545',
}: {
  color?: string;
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 17.1279H19V10.321C19 6.52828 15.866 3.45349 12 3.45349C8.134 3.45349 5 6.52828 5 10.321V17.1279ZM12 1.5C16.97 1.5 21 5.44898 21 10.321V19.0814H3V10.321C3 5.44898 7.03 1.5 12 1.5ZM9.5 20.0581H14.5C14.5 20.7058 14.2366 21.3269 13.7678 21.7848C13.2989 22.2427 12.663 22.5 12 22.5C11.337 22.5 10.7011 22.2427 10.2322 21.7848C9.76339 21.3269 9.5 20.7058 9.5 20.0581Z"
      fill={color}
    />
  </svg>
);
