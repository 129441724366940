/* eslint-disable no-unused-expressions */
import { call, put, takeEvery } from 'redux-saga/effects';

import { searchEventInvite } from 'services';
import {
  searchEventInviteSuccess,
  searchEventInviteError,
  actionTypes,
} from './actions';

function* workerSearchEventInvite(action) {
  try {
    const resp = yield call(searchEventInvite, action.data);

    const { internalError } = resp.data;
    if (
      (resp.status === 200 || typeof resp.total === 'number') &&
      !internalError
    ) {
      yield put(
        searchEventInviteSuccess({
          data: resp?.data?.users,
          params: {
            paramsForFilter: action.data.paramsForFilter,
            q: action.data.q,
          },
        })
      );
    } else {
      const resultError = internalError ? 'Empty response' : resp.data;

      yield put(searchEventInviteError(resultError));
    }
  } catch (err) {
    yield put(searchEventInviteError(err));
  }
}

function* watchSearch() {
  yield takeEvery(
    actionTypes.SEARCH_EVENT_INVITE_START,
    workerSearchEventInvite
  );
}

export default watchSearch;
