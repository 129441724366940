import { Field } from 'react-final-form';

import { IField } from './Types';
import InputField from './Input';

const Input = (props: IField) => {
  return <Field component={InputField} {...props} type="checkbox" />;
};

export default Input;
