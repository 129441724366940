import { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';

import SidebarMyEvents from 'containers/SidebarMyEvents';

import fetchApi from 'hooks/fetchApi';
import API_NAME from 'services/api';

import SidebarMyGroups from 'containers/SidebarMyGroups';
import SidebarPopularPosts from 'containers/SidebarPopularPosts';
import { useRouter } from 'next/router';
import { ISidebar } from './Types';

import { SidebarWrapper } from './styles';

const Sidebar = () => {
  const router = useRouter();
  const pathName = router?.pathname;
  const { action, loading } = fetchApi();

  const getData = useSelector((store) => store.fetchData);

  const renderDataCondition = pathName === '/g/[id]';

  const fetchData = useCallback(() => {
    if (!renderDataCondition) {
      action(API_NAME.SIDEBAR_GET_MY_EVENTS, {
        params: {
          offset: 0,
          limit: 20,
        },
      });
      action(API_NAME.SIDEBAR_GET_MY_GROUPS, {
        params: {
          offset: 0,
          limit: 20,
        },
      });
    }
    if (renderDataCondition) {
      action(API_NAME.SIDEBAR_GET_POPULAR_POSTS, {});
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <SidebarWrapper>
      {!renderDataCondition ? (
        <>
          <SidebarMyEvents
            loading={loading[API_NAME.SIDEBAR_GET_MY_EVENTS]}
            listItems={getData[API_NAME.SIDEBAR_GET_MY_EVENTS]?.data}
          />
          <SidebarMyGroups
            loading={loading[API_NAME.SIDEBAR_GET_MY_GROUPS]}
            listItems={getData[API_NAME.SIDEBAR_GET_MY_GROUPS]?.data}
          />
        </>
      ) : (
        <>
          {getData[API_NAME.SIDEBAR_GET_POPULAR_POSTS]?.data?.length > 0 && (
            <SidebarPopularPosts
              listItems={getData[API_NAME.SIDEBAR_GET_POPULAR_POSTS]?.data}
            />
          )}
        </>
      )}
    </SidebarWrapper>
  );
};

export default Sidebar;
