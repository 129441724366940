/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { appStateAction } from 'store/appState/actions';

const appState = () => {
  const dispatch = useDispatch();
  const actionState = useCallback((name, data) => {
    dispatch(
      appStateAction({
        key: name,
        data,
      })
    );
  }, []);

  return actionState;
};

export default appState;
