import React from 'react';

export const CircleWithArrowIcon = ({
  colorFill = 'none',
  colorStroke = '#1C1F29',
}: {
  colorFill?: string;
  colorStroke?: string;
}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="8" fill="#F7F7F7" />
    <path
      d="M5.5 6.75L8 9.25L10.5 6.75"
      stroke="#C2C2C2"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
