import React, { useCallback, useContext, useEffect } from 'react';
// import Image from 'next/image';
import { osName } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  AppStoreWhite,
  CrossIcon,
  GplayWhite,
  // Iphone,
} from 'public/assets/icons';
import Heading from 'components/common/Typography/Heading';
import { ThemeContext } from 'theme';
import { useRouter } from 'next/router';
import { useWindowSize } from 'hooks';
import { routesPath } from 'utils/constants/routePath';

import {
  Wrapper,
  LogoWrap,
  AlertBodyWrap,
  AppNameText,
  Text,
  OpenButton,
  CrossIconWrap,
  AppAlertLeft,
  DownloadContainer,
  DownloadButton,
  DownloadButtonWrap,
  AppAlertRight,
  Logo,
} from './AppAlert.style';
import { IAppAlert } from './IAppAlert';

export const AppAlert = ({ id, pathname, onCrossIconClick }: IAppAlert) => {
  const { isSidebarOpen } = useSelector((state) => state.sideBarControler);
  const getWindowSize = useWindowSize();
  const windowSize = getWindowSize.width > 767;

  const { t } = useTranslation();

  const context: any = useContext(ThemeContext);
  const { isAuthenticate } = context;
  const router = useRouter();
  const navigateToStore =
    !!(router?.query?.deep_link_value && router?.query?.deep_link_sub1) ||
    !!router?.query?.navigateToStore;

  const getPageName = useCallback(() => pathname.slice(1, 2), [pathname]);

  const appName = process.env._ENV === 'rockr' ? 'rockr' : 'roadstr';
  const onButtonClick = useCallback(() => {
    const queryString = navigateToStore ? `navigateToStore=true` : '';
    let appStoreRedirectTimeout = null;

    const redirectToAppStore = () => {
      console.log('REDIRECTING TO APPSTORE');
      // Cleanup before redirecting
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      clearTimeout(appStoreRedirectTimeout);

      // Redirect to the app store links
      if (osName === 'iOS') {
        console.log('OPENING STORE LINK');
        window.location.assign(
          'https://apps.apple.com/app/roadstr-car-social-network/id1382535778'
        );
      } else if (osName === 'Android') {
        window.location.assign(
          `https://play.google.com/store/apps/details?id=io.${appName}.app`
        );
      }
    };

    const handleVisibilityChange = () => {
      if (document.hidden) {
        console.log('DOCUMENT IS HIDDEN NOW', document.hidden);
        // The page is now hidden, likely because the app has opened.
        clearTimeout(appStoreRedirectTimeout);
      } else {
        console.log('DOCUMENT IS VISIBLE NOW');
        // The page is visible. If the redirect timeout hasn't fired yet, it may mean the deep link didn't work.
        // However, since this event can also fire when the tab regains focus, we don't automatically redirect here.
      }
    };

    // Add the visibility change event listener
    document.addEventListener('visibilitychange', handleVisibilityChange);
    console.log('ROUTER PATH IS', router?.asPath, osName);
    if (
      router?.asPath === '/events' ||
      router?.asPath === '/groups' ||
      router?.asPath === '/routes'
    ) {
      router.push(`${routesPath.map}`);
    }
    const currentUrl = window.location.href;
    let newUrl = `https://app.${appName}.io/${getPageName()}/${id}`;
    if (
      router?.asPath !== '/events' &&
      router?.asPath !== '/groups' &&
      router?.asPath !== '/routes'
    ) {
      if (osName === 'iOS') {
        console.log(
          'NAVIGATING IOS',
          `https://app.${appName}.io/${getPageName()}/${id}?${queryString}`
        );
        newUrl = `https://app.${appName}.io/${getPageName()}/${id}?${queryString}`;
      } else if (osName === 'Android') {
        newUrl = `http://app.${appName}.io/${getPageName()}/${id}?${queryString}`;
        setTimeout(() => {
          window.location.assign(
            `${appName}://app.${appName}.io/${getPageName()}/${id}`
          );
        }, 200);
      } else {
        newUrl = `https://app.${appName}.io/${getPageName()}/${id}`;
      }
      console.log('URLS ARE', currentUrl, newUrl);
      if (newUrl !== currentUrl) {
        window.location.assign(newUrl);
      }
    }

    // Schedule a redirect to the app store if the page remains visible
    appStoreRedirectTimeout = setTimeout(redirectToAppStore, 2000);
  }, [osName, navigateToStore]); // include any other dependencies for this useCallback hook

  useEffect(() => {
    console.log('NAVIGATE TO STORE', typeof window, navigateToStore);
    if (typeof window !== 'undefined' && navigateToStore) {
      onButtonClick();
    }
  }, [navigateToStore, onButtonClick]);

  return (
    <Wrapper
      isSidebarOpen={isSidebarOpen}
      isAuthenticate={isAuthenticate}
      windowSize={windowSize}
    >
      <LogoWrap>
        <Logo
          src={`/assets/img/logoRounded_${appName}.png`}
          width={48}
          height={48}
          alt="logo"
        />
      </LogoWrap>
      <DownloadContainer>
        <Text>{t('common:openApp')}</Text>
        <DownloadButtonWrap>
          <DownloadButton path={context.content.playStore}>
            <GplayWhite />
          </DownloadButton>
        </DownloadButtonWrap>
        <DownloadButtonWrap>
          <DownloadButton path={context.content.appStore}>
            <AppStoreWhite />
          </DownloadButton>
        </DownloadButtonWrap>
      </DownloadContainer>
      <AppAlertRight>
        <OpenButton onClick={onButtonClick}>{t('common:open')}</OpenButton>
      </AppAlertRight>

      {/* <AppAlertRight>
        <Iphone />
      </AppAlertRight> 
       <Heading type="h4">{t('common:openApp')}</Heading
      <AlertBodyWrap>
        <AppNameText>RoadStr</AppNameText>
        <Text>{t('common:downloadAndJoinCommunity')}</Text>
      </AlertBodyWrap>
      <OpenButton onClick={onButtonClick}>{t('common:open')}</OpenButton> */}

      <CrossIconWrap onClick={onCrossIconClick}>
        <CrossIcon color="white" />
      </CrossIconWrap>
    </Wrapper>
  );
};
