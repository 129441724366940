/* eslint-disable no-case-declarations */
import { actionTypes } from './actions';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.FETCH_API:
      const apiKey = action.data?.key;
      const response = action.data?.res;
      if (action?.data === undefined) {
        return {};
      }
      return {
        ...state,
        [apiKey]: response,
      };
    case actionTypes.CLEAR_APP_STATE:
      return {};
    default:
      return state;
  }
};

export default reducer;
