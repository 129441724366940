import styled from 'styled-components';

export const LinkContainer = styled.a`
  cursor: pointer;
  font-family: ${(props) => props.theme.typography.fontFamily.secondary};
  font-size: ${(props) => props.theme.typography.fontSize.f3};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.colors.blue};
  text-decoration: none;
`;
