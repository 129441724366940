import styled from 'styled-components';

import Heading from 'components/common/Typography/Heading';

interface IProps {
  pt?: number;
  pb?: number;
  mt?: number;
  mb?: number;
}

export const ScheduleTimeWrapper = styled.div``;

export const SeparateSettingsHeader = styled(Heading)<IProps>`
  font-size: ${(props) => props.theme.typography.fontSize.f6};
  line-height: 150%;
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  letter-spacing: 0.02em;
  color: ${(props) => props.theme.colors.black};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
`;
