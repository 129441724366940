import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  height?: number;
  width?: number;
  img?: string;
  borderWidth?: number;
  isCoverPhotoExists?: boolean;
  withoutBorder?: boolean;
}

export const BackgroundGradientWrap = styled.div<IProps>`
  width: 100%;
  height: ${({ height }) => (height ? `${height}px` : '80px')};
  display: flex;
  align-items: flex-end;
  background: ${({ isCoverPhotoExists }) =>
    isCoverPhotoExists
      ? 'linear-gradient(180deg, rgba(0, 0, 0, 0) 9.67%, #1c1f29 100%)'
      : 'transparent'};
`;

export const ProfileImage = styled.div<IProps>`
  height: ${({ height }) => (height ? `${height}px` : '17px')};
  width: ${({ width }) => (width ? `${width}px` : '17px')};
  min-width: ${({ width }) => (width ? `${width}px` : '17px')};
  border-radius: 50%;
  box-sizing: border-box;
  border: ${({ withoutBorder, borderWidth }) => {
    if (withoutBorder) {
      return 'none';
    }

    return `${borderWidth || 1.5}px solid ${style.mainWhite.white}`;
  }};
  overflow: hidden;
  background: url(${({ img }) => img || '/assets/img/emptyUserPhoto.png'})
    no-repeat;
  background-size: cover;
  background-position: center;
`;
