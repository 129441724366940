/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import blurImage from 'utils/blurImage';

import { IImage } from './IImage';

import { ImageWrapper } from './Image.styles';

const Image = (props: IImage) => {
  const { src, alt, layout, isLoader = false, ...rest } = props;

  if (isLoader) {
    return (
      <ImageWrapper
        src={src || '/assets/img/tireTrace.png'}
        alt={alt}
        layout={layout}
        placeholder="blur"
        loading="eager"
        blurDataURL={blurImage}
        {...rest}
        unoptimized
        loader={({ src, width, quality }) => src}
        priority
      />
    );
  }

  return (
    <ImageWrapper
      src={src || '/assets/img/tireTrace.png'}
      alt={alt}
      layout={layout}
      placeholder="blur"
      loading="eager"
      blurDataURL={blurImage}
      {...rest}
    />
  );
};

export default Image;
