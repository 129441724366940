import styled from 'styled-components';

import { style, deviceMaxWidth } from 'utils/constants/style';

interface IProps {
  backgroundColor?: string;
  height?: string;
  margin?: string;
  rounded?: string;
  width?: number;
  padding?: string;
  border?: string;
  fontSize?: number;
  mobileHeight?: number;
  cursor?: string;
}

export const Button = styled.button<IProps>`
  color: ${(props) => props.color};
  background-color: ${(props) => props.backgroundColor};
  border: ${({ border }) => border || 'none'};
  width: 100%;
  max-width: ${({ width }) => (width ? `${width}px` : '100%')};
  border-radius: ${(props) =>
    props.rounded === 'right' || props.rounded === 'left' ? '0px' : '4px'};

  border-top-left-radius: ${(props) => props.rounded === 'left' && '10px'};
  border-bottom-left-radius: ${(props) => props.rounded === 'left' && '10px'};
  border-top-right-radius: ${(props) => props.rounded === 'right' && '10px'};
  border-bottom-right-radius: ${(props) => props.rounded === 'right' && '10px'};

  box-sizing: border-box;
  height: ${(props) => props.height || '40px'};
  outline: none;
  cursor: ${({ cursor }) => cursor};
  font-family: ${style.fontFamily.poppins};
  font-size: 14px;
  margin: ${(props) => props.margin || '0'};
  letter-spacing: 0.02em;

  @media only screen and (${deviceMaxWidth.sm}) {
    height: ${({ mobileHeight }) =>
      mobileHeight ? `${mobileHeight}px` : '40px'};
  }
`;

export const TextWrapper = styled.div<IProps>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props) => props.height || '40px'};

  @media only screen and (${deviceMaxWidth.sm}) {
    height: ${({ mobileHeight }) =>
      mobileHeight ? `${mobileHeight}px` : '40px'};
  }
`;

export const ButtonText = styled.p<IProps>`
  margin: ${({ margin }) => margin || '0 0 0 17px'};
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '14px')};
`;
