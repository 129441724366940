import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  ml?: number;
}

export const CurrentLocationContainer = styled.div`
  display: flex;
  margin: 13px 0 16px 0;
  align-items: center;
  cursor: pointer;
`;

export const HeaderText = styled.h3<IProps>`
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0.02em;
  font-weight: 500;
  color: ${style.mainBlack.black};
  margin-left: ${({ ml }) => (ml ? `${ml}px` : '0')};
`;
