import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  mr?: number;
  cursor?: string;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RightSideWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const LeftSideWrap = styled(RightSideWrap)`
  width: 80%;
`;

export const IconWrap = styled.div<IProps>`
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin-right: ${({ mr }) => (mr ? `${mr}px` : '0')};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ cursor }) => cursor || 'auto'};
  position: relative;

  :hover {
    div {
      display: block;
    }
  }
`;

export const BurgerIconDescription = styled.div`
  display: none;
  position: absolute;
  bottom: -22px;
  right: -50px;
  width: 100px;
  font-size: 12px;
  line-height: 100%;
  color: ${style.mainWhite.white};
  padding: 6px 4px;
  background-color: rgba(28, 31, 41, 0.9);
  border-radius: 4px;
  text-align: center;
`;

export const Text = styled.p`
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.02em;
  font-weight: 500;
  padding: 4.5px 0;
  color: ${style.mainBlack.black};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const ClickableText = styled(Text)`
  color: ${style.mainBlue.blue};
  cursor: pointer;
  width: 100%;
`;
