import React from 'react';
import { style } from 'utils/constants/style';

export const CircleWithEditIcon = ({
  width = '24',
  height = '24',
  colorFill = style.mainBlue.blue,
  colorStroke = style.mainWhite.white,
  transparent = true,
}: {
  width?: string;
  height?: string;
  colorFill?: string;
  colorStroke?: string;
  transparent?: boolean;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    {transparent ? (
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM9.7438 16H8V14.2562C8 13.991 8.10536 13.7366 8.29289 13.5491L12.3159 9.52613L14.4737 11.684L15.1931 10.9647L13.0352 8.80679L13.395 8.44694C13.991 7.85102 14.9571 7.85102 15.5531 8.44694C16.149 9.04286 16.149 10.009 15.5531 10.605L10.4509 15.7071C10.2634 15.8946 10.009 16 9.7438 16Z"
        fill={colorFill}
      />
    ) : (
      <>
        <circle cx="12" cy="12" r="12" fill={colorFill} />
        <path
          d="M15.8935 9.92467L14.0753 8.10647C14.0071 8.0383 13.9147 8 13.8182 8C13.7218 8 13.6293 8.0383 13.5611 8.10647L8.10655 13.5611C8.03835 13.6292 8.00002 13.7217 8 13.8182V15.6364C8 15.7328 8.03831 15.8253 8.10651 15.8935C8.1747 15.9617 8.2672 16 8.36364 16H10.1818C10.2783 16 10.3708 15.9617 10.4389 15.8935L15.8935 10.4389C15.9617 10.3707 16 10.2782 16 10.1818C16 10.0853 15.9617 9.99286 15.8935 9.92467ZM10.0313 15.2727H8.72728V13.9687L12.7273 9.96867L14.0313 11.2727L10.0313 15.2727ZM14.5455 10.7585L13.2415 9.45448L13.8182 8.87775L15.1223 10.1818L14.5455 10.7585Z"
          fill={colorStroke}
        />
      </>
    )}
  </svg>
);
