import styled from 'styled-components';

import { style } from 'utils/constants/style';

export const HeaderText = styled.span`
  color: ${style.mainBlack.black};
  font-weight: 600;
  cursor: pointer;
  font-size: 14px;
  line-height: 100%;
  margin-bottom: 8px;
`;
