import Image from 'next/image';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ArrowBack, CheckMarkIcon } from 'public/assets/icons';
import { actionTypes, openSideBar } from 'store/sideBarControler/actions';
import {
  attendEventWithoutVehicleStart,
  attendEventWithVehicleStart,
} from 'store/posts/actions';
import { userGarageVehicles } from 'services/userGarage/userGarageVehicles';
import { getLocalStorage, useWindowSize } from 'utils/hooks';
import {
  CarImgWrap,
  CarsWrap,
  CheckIcon,
  GradientWrap,
} from './AttendEvent.style';
import { SidebarWrapper } from '..';

interface ICarsAttendIvent {
  attendId?: string;
}

const CarAttendEvent = ({ car, onClick, checked }: any) => {
  const { width } = useWindowSize();

  return (
    <CarImgWrap onClick={onClick} checked={checked}>
      <Image
        loader={() => car.photos[0]}
        src={
          car.photos[0]?.trim().length
            ? car.photos[0]
            : '/assets/img/tireTrace.png'
        }
        alt="author post img"
        width={width < 410 ? width - 40 : 172}
        height={width < 410 ? 150 : 120}
        objectFit="cover"
        unoptimized
        priority
      />
      <GradientWrap>
        <div>
          <span>{`${car.make}  ${car.model}`}</span>
        </div>
      </GradientWrap>
      <CheckIcon checked={checked}>{checked && <CheckMarkIcon />}</CheckIcon>
      <CheckMarkIcon />
    </CarImgWrap>
  );
};

const AttendEvent = ({ attendId }: ICarsAttendIvent) => {
  const [isCheckedCar, setIsCheckedCar] = useState(null);
  const [userGarage, setUserGarage] = useState(null);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const userId = JSON.parse(getLocalStorage('user_id'));

    userGarageVehicles(userId).then((res) => setUserGarage(res.data));
  }, []);

  const checkedCar = (id) => {
    setIsCheckedCar((prevState) => (prevState === id ? null : id));
  };

  const onAttendEvent = (vehicleId) => {
    if (vehicleId) {
      dispatch(attendEventWithVehicleStart({ eventId: attendId, vehicleId }));
    } else {
      dispatch(attendEventWithoutVehicleStart(attendId));
    }
  };

  return (
    <SidebarWrapper
      headerText={t('common:garage')}
      headerIcon={<ArrowBack />}
      onSubmit={() => onAttendEvent(isCheckedCar)}
      submitText={t('editUserProfile:save')}
      isSubmitAvailable
      onIconClick={() => {
        dispatch(openSideBar(actionTypes.CLOSE_SIDEBAR_ADD_USER_GROUP));
      }}
      contentPadding="20px"
    >
      <CarsWrap>
        {userGarage &&
          userGarage.map((el, i) => (
            <CarAttendEvent
              key={`CarAttendEvent-${i}`}
              car={el}
              onClick={() => checkedCar(el._id)}
              checked={el._id === isCheckedCar}
            />
          ))}
      </CarsWrap>
    </SidebarWrapper>
  );
};

export default AttendEvent;
