import React from 'react';
import { style } from 'utils/constants/style';

export const PopularPostsIcon = ({
  color = style.mainGray.spunPearl,
}: {
  color?: string;
}) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.14 12.0607C11.5894 12.0607 11.9538 11.6964 11.9538 11.2469C11.9538 10.7975 11.5894 10.4331 11.14 10.4331C10.6905 10.4331 10.3262 10.7975 10.3262 11.2469C10.3262 11.6964 10.6905 12.0607 11.14 12.0607Z"
      fill={style.mainBlue.blue}
    />
    <path
      d="M13.4183 12.0607C13.8677 12.0607 14.2321 11.6964 14.2321 11.2469C14.2321 10.7975 13.8677 10.4331 13.4183 10.4331C12.9688 10.4331 12.6045 10.7975 12.6045 11.2469C12.6045 11.6964 12.9688 12.0607 13.4183 12.0607Z"
      fill={style.mainBlue.blue}
    />
    <path
      d="M8.86068 12.0607C9.31013 12.0607 9.67448 11.6964 9.67448 11.2469C9.67448 10.7975 9.31013 10.4331 8.86068 10.4331C8.41123 10.4331 8.04688 10.7975 8.04688 11.2469C8.04688 11.6964 8.41123 12.0607 8.86068 12.0607Z"
      fill={style.mainBlue.blue}
    />
    <path
      d="M6.58236 12.0607C7.03181 12.0607 7.39616 11.6964 7.39616 11.2469C7.39616 10.7975 7.03181 10.4331 6.58236 10.4331C6.13291 10.4331 5.76855 10.7975 5.76855 11.2469C5.76855 11.6964 6.13291 12.0607 6.58236 12.0607Z"
      fill={style.mainBlue.blue}
    />
    <path
      d="M17.6826 3.3335H2.31803C1.95846 3.3335 1.66699 3.62497 1.66699 3.98454V16.0679C1.66699 16.4274 1.95846 16.7189 2.31803 16.7189H17.6826C18.0422 16.7189 18.3337 16.4274 18.3337 16.0679V3.98454C18.3337 3.62497 18.0422 3.3335 17.6826 3.3335ZM17.0316 15.4168H2.96908V4.63558H17.0316V15.4168Z"
      fill={style.mainBlue.blue}
    />
    <path
      d="M6.58268 8.6429H13.4186C13.7782 8.6429 14.0697 8.35143 14.0697 7.99186C14.0697 7.63229 13.7782 7.34082 13.4186 7.34082H6.58268C6.22311 7.34082 5.93164 7.63229 5.93164 7.99186C5.93164 8.35143 6.22311 8.6429 6.58268 8.6429Z"
      fill={style.mainBlue.blue}
    />
  </svg>
);
