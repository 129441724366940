import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  CircleWithArrowIcon,
  EventsIcon,
  FavoriteIcon,
  GarageIcon,
  GroupsIcon,
  LogOutIcon,
  SettingsIcon,
} from 'public/assets/icons';
import Dropdown from 'components/Dropdown';
import Image from 'components/common/Image';
import { EmptyUserProfilePhoto } from 'components';
import { useRouter } from 'next/router';
import { routesPath } from 'utils/constants/routePath';
import AppClubsList from 'containers/Club/AppClubsList';
import { useSelector } from 'react-redux';
import imageResize from 'utils/imageResize';
import { IProfileActionsButton } from './IHeader';
import { IconWrapper, ProfileButton, TextWrapper } from './Header.styles';

const ProfileActionsButton = ({
  userImage,
  userName,
  viewProfile,
  donationMetricBanner,
  setIsConfirmationModalOpen,
}: IProfileActionsButton) => {
  const router = useRouter();
  const { t } = useTranslation();
  const activeClub = useSelector((state) => state?.club?.activeClub);
  const activeClubId = activeClub?._id;
  const clubLogo = activeClub?.theme?.logoMedia?.url;

  const profileSections = [
    {
      items: [
        {
          id: 2,
          icon: <EventsIcon />,
          text: t('menu:eventsItem'),
          onClick: () => router.push(routesPath.my_events),
        },
        {
          id: 3,
          icon: <GroupsIcon />,
          text: t('menu:groupsItem'),
          onClick: () => router.push(routesPath.my_groups),
        },
        {
          id: 4,
          icon: <GarageIcon />,
          text: t('menu:garageItem'),
          onClick: () => router.push(routesPath.my_garage),
        },
        {
          id: 5,
          icon: <FavoriteIcon />,
          text: t('menu:favoritesItem'),
          onClick: () => router.push(routesPath.my_favorites),
        },
        {
          id: 6,
          icon: <SettingsIcon />,
          text: t('profile:settings'),
          onClick: () => router.push(`${routesPath.settings}`),
        },
        {
          id: 7,
          icon: <LogOutIcon />,
          text: t('settings:logOut'),
          onClick: () => setIsConfirmationModalOpen(true),
        },
      ],
    },
    {
      header: t('menu:switchClubs'),
      items: [
        {
          customContent: <AppClubsList />,
        },
      ],
    },
  ];

  return (
    <Dropdown
      sections={profileSections}
      variant="right"
      userImage={userImage}
      userName={userName}
      viewProfile={viewProfile}
      donationMetricBanner={donationMetricBanner}
    >
      <ProfileButton>
        {userImage ? (
          <Image
            src={activeClubId ? imageResize(clubLogo) : userImage}
            alt="user image"
            width={28}
            height={28}
            unoptimized
            priority
          />
        ) : (
          <EmptyUserProfilePhoto
            wrapSize={28}
            userIconSize={14}
            withBorder={false}
            cursor="pointer"
          />
        )}

        <TextWrapper>{userName}</TextWrapper>
        <IconWrapper>
          <CircleWithArrowIcon />
        </IconWrapper>
      </ProfileButton>
    </Dropdown>
  );
};

export default ProfileActionsButton;
