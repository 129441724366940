import { IForm } from './IForm';
import { FormWrapper } from './Form.styles';

const Form = ({ children, onSubmit, contentRef, ...restProps }: IForm) => {
  const formProps = {
    // These are mainly default values for the server
    // rendering. Otherwise the form would submit potentially
    // sensitive data with the default GET method until the client
    // side code is loaded.
    method: 'post',
    action: '/',

    // allow content ref function to be passed to the form
    ref: contentRef,

    ...restProps,
  };
  return (
    <FormWrapper onSubmit={onSubmit} {...formProps}>
      {children}
    </FormWrapper>
  );
};

export default Form;
