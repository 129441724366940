import { FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { composeValidators, required } from 'utils/validators';
import Input from 'components/common/Input';
import Button from 'components/common/Button';
import { ISignInForm } from './ISignInForm';

import {
  FinalFormWrapper,
  FormWrapper,
  ForgotPasswordWrapper,
  ForgotPasswordText,
} from './SignInForm.styles';

const SingInForm = (props: ISignInForm) => {
  const { t } = useTranslation();
  return (
    <FinalFormWrapper
      {...props}
      render={(formRenderProps: FormRenderProps) => {
        const { handleSubmit } = formRenderProps;
        const {
          inProgress,
          ready,
          onForgotPasswordClick,
          placeholderText,
          placeholderPassword,
        } = props;

        return (
          <FormWrapper onSubmit={handleSubmit}>
            <Input
              id="usernameoremail"
              name="usernameOrEmail"
              type="text"
              label={t('auth:emailOrUsernameText')}
              placeholder={placeholderText}
              validate={composeValidators(
                required(t('error:thisFieldIsRequired'))
              )}
            />
            <Input
              id="password"
              name="password"
              type="password"
              label={t('auth:passwordText')}
              placeholder={placeholderPassword}
              validate={composeValidators(
                required(t('error:thisFieldIsRequired'))
              )}
            />

            <ForgotPasswordWrapper>
              <ForgotPasswordText onClick={onForgotPasswordClick}>
                {t('auth:forgotPasswordText')}
              </ForgotPasswordText>
            </ForgotPasswordWrapper>

            <Button
              htmlType="submit"
              variant="primary"
              disabled={inProgress}
              loading={inProgress}
            >
              {t('auth:loginText')}
            </Button>
          </FormWrapper>
        );
      }}
    />
  );
};

export default SingInForm;
