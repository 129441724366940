import React from 'react';

export const PhotoIcon = () => (
  <svg
    width="22"
    height="19"
    viewBox="0 0 22 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="3"
      width="20"
      height="15"
      rx="2"
      stroke="#8D8D8D"
      strokeWidth="1.2"
    />
    <path
      d="M4 2C4 1.44772 4.44772 1 5 1H6C6.55228 1 7 1.44772 7 2V3H4V2Z"
      stroke="#8D8D8D"
      strokeWidth="1.2"
    />
    <circle cx="10" cy="11" r="4" stroke="#8D8D8D" strokeWidth="1.4" />
    <circle cx="10" cy="11" r="1" stroke="#8D8D8D" />
    <circle cx="17.5" cy="6.5" r="1.5" stroke="#8D8D8D" strokeWidth="1.2" />
  </svg>
);
