import React from 'react';

export const PhoneIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="7"
      y="5"
      width="10"
      height="16"
      rx="1"
      stroke="#8D8D8D"
      strokeWidth="1.4"
    />
    <rect x="10" y="5" width="4" height="2" fill="#8D8D8D" />
    <rect x="10" y="18" width="4" height="1" fill="#8D8D8D" />
  </svg>
);
