import styled from 'styled-components';

interface IProps {
  fontSize?: number;
  color?: string;
  backgroundColor?: string;
  borderRadius?: number;
}

export const Button = styled.button<IProps>`
  padding: 7px 11px;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '12px')};
  line-height: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '12px')};
  letter-spacing: 0.02em;
  border-radius: ${({ borderRadius }) =>
    borderRadius ? `${borderRadius}px` : '23px'};
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: none;
  outline: none;
  cursor: pointer;
  font-family: 'Poppins';
`;

export const LinkButton = styled.a<IProps>`
  display: inline-block;
  padding: 7px 11px;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '12px')};
  line-height: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '12px')};
  letter-spacing: 0.02em;
  border-radius: ${({ borderRadius }) =>
    borderRadius ? `${borderRadius}px` : '23px'};
  color: ${({ color }) => color};
  background-color: ${({ backgroundColor }) => backgroundColor};
  cursor: pointer;
  text-decoration: none;
`;
