/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { IText } from './IText';

import { TextContainer } from './Text.styles';

const Text = ({ children, onClick, ...rest }: IText) => {
  return (
    <TextContainer onClick={onClick} {...rest}>
      {children}
    </TextContainer>
  );
};

export default Text;
