/* eslint-disable react/prop-types */
import React from 'react';

export const SearchIcon = ({
  colorStroke = '#1C1F29',
}: {
  colorStroke?: string;
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.7123 16.7113L20.7452 20.7443"
      stroke={colorStroke}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="10.6626"
      cy="10.6626"
      r="7.66255"
      stroke={colorStroke}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
