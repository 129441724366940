import fetchData from 'utils/fetchData';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const createFormData = (photoUri, body, file = {}) => {
  const data: any = new FormData();

  // these values should be placed before 'file' key-value.
  Object.keys(body).forEach((key) => {
    data.append(key, body[key]);
  });

  data.append('file', file);

  return data;
};

export const getChats = () => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._CHAT_BASE_API_URL}/api/v11/chats`, {
      method: 'GET',
      headers: { ...headers, tsec: JSON.parse(token) },
    }).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const getChatsNext = (nextValue) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._CHAT_BASE_API_URL}/api/v11/next/${nextValue}`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const getMessages = ({ chatId }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._CHAT_BASE_API_URL}/api/v11/chat/${chatId}`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const getChatDetails = (chatId) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._CHAT_BASE_API_URL}/api/v11/chat/${chatId}/details`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const editMultipleChat = ({ chatId, title, size }) => {
  try {
    const token = localStorage.getItem('token');

    const body =
      size === 0
        ? { name: title }
        : {
            name: title,
            image: {
              'Content-Type': 'image/jpeg',
              size,
            },
          };

    return fetchData(
      `${process.env._CHAT_BASE_API_URL}/api/v11/chat/${chatId}/details`,
      {
        method: 'POST',
        headers: { ...headers, tsec: JSON.parse(token) },
        body: JSON.stringify(body),
      }
    ).then((response) =>
      response.status === 204 ? response : response.json()
    );
  } catch (error) {
    console.log(error);
  }
};

export const getChatMembers = (chatId) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._CHAT_BASE_API_URL}/api/v11/chat/${chatId}/members`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const getMessagesNext = (nextValue) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._CHAT_BASE_API_URL}/api/v11/next/${nextValue}`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const searchForUsers = (query) => {
  try {
    const token = localStorage.getItem('token');

    let searchUserApiEndpoint = `searchUser?prefix=${query}`;
    if (query === '') {
      searchUserApiEndpoint = 'searchUser';
    }

    return fetchData(
      `${process.env._CHAT_BASE_API_URL}/api/v11/${searchUserApiEndpoint}`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const searchForUsersNext = ({ nextValue, query }) => {
  try {
    const token = localStorage.getItem('token');

    let searchUserApiEndpoint = `${nextValue}?prefix=${query}`;
    if (query === '') {
      searchUserApiEndpoint = `${nextValue}`;
    }

    return fetchData(
      `${process.env._CHAT_BASE_API_URL}/api/v11/${searchUserApiEndpoint}`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const createSingleChat = (userId) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._CHAT_BASE_API_URL}/api/v11/chat/single/${userId}`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const createMultipleChat = ({ userIds, title, size }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._CHAT_BASE_API_URL}/api/v11/chat/multiple`,
      {
        method: 'POST',
        headers: { ...headers, tsec: JSON.parse(token) },
        body: JSON.stringify({
          name: title,
          users: userIds,
          image: { 'Content-Type': 'image/jpeg', size },
        }),
      }
    ).then((response) => (response.status === 204 ? null : response.json()));
  } catch (error) {
    console.log(error);
  }
};

export const changeChatSettings = ({ chatId, settings }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._CHAT_BASE_API_URL}/api/v11/chat/${chatId}`,
      {
        method: 'PUT',
        headers: { ...headers, tsec: JSON.parse(token) },
        body: JSON.stringify(settings),
      }
    ).then((response) => (response.status === 204 ? null : response.json()));
  } catch (error) {
    console.log(error);
  }
};

export const deleteChat = (chatId) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._CHAT_BASE_API_URL}/api/v11/chat/${chatId}`,
      {
        method: 'DELETE',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((resp) => resp.json());
  } catch (error) {
    console.log(error);
  }
};

export const uploadImageAws = ({ body, photoUri, file }) => {
  try {
    const token = localStorage.getItem('token');
    const formData = createFormData(photoUri, body, file);

    return fetchData(`${process.env._AWS_URL}`, {
      method: 'POST',
      headers: {
        tsec: JSON.parse(token),
      },
      body: formData,
    }).then((response) =>
      response.status === 204 ? { ok: true } : response.json()
    );
  } catch (error) {
    console.log(error);
  }
};

export const uploadChatMediaAws = ({ presignedUrl, body, photoUri, file }) => {
  try {
    const formData = createFormData(photoUri, body, file);

    return fetchData(`${presignedUrl}`, {
      method: 'POST',
      body: formData,
    }).then((response) =>
      response.status === 204 ? { ok: true } : response.json()
    );
  } catch (error) {
    console.log(error);
  }
};

export const getRoomInfo = (roomId) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._CHAT_BASE_API_URL}/api/v11/chat/room/${roomId}`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((resp) => resp.json());
  } catch (error) {
    console.log(error);
  }
};
