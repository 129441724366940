/* eslint-disable react/display-name */
import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  getLikesPostStart,
  getLikesVehicleStart,
  getLikesEventStart,
  clearLikesStore,
} from 'store/posts/actions';
import { LoadingComponent } from 'components';
import Drawer from 'components/Drawer';
import LikeItem from 'components/LikeItem';
import appState from 'hooks/appState';
import { APP_STATE } from 'types';
import { ILikesDrawer } from './ILIkesDrawer';

import { LikesListWrap } from './LIkesDrawer.styles';

const LikesDrawer = ({ postId, postType }: ILikesDrawer) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const appStateAction = appState();

  const { likesPost, likesVehicle, likesEvent } = useSelector(
    (state) => state.posts
  );

  const likes = likesPost || likesVehicle || likesEvent;
  useEffect(() => {
    switch (postType) {
      case 1:
      case 3:
        dispatch(getLikesPostStart(postId));
        break;
      case 4:
        dispatch(getLikesVehicleStart(postId));
        break;
      case 6:
      case 16:
        dispatch(getLikesEventStart(postId));
        break;
      default:
    }

    return () => {
      dispatch(clearLikesStore());
    };
  }, [postType, dispatch, postId]);

  const likesList = useCallback(
    () =>
      likes?.map((item) => (
        <LikeItem
          key={item._id}
          followingStatus={item?.followingStatus}
          isFollowing={item?.isFollowing}
          fullName={item?.fullName}
          isMe={item?.isMe}
          privacy={item?.privacy}
          profilePhoto={item?.profilePhoto?.trim()}
          userName={item?.username}
          id={item?._id}
          onItemClick={() =>
            appStateAction(APP_STATE.LIKES_DRAWER_ACTION, {
              isOpen: false,
            })
          }
        />
      )),
    [likes]
  );

  return (
    <Drawer
      open
      onIconClick={() =>
        appStateAction(APP_STATE.LIKES_DRAWER_ACTION, {
          isOpen: false,
        })
      }
      onDrawerClose={() =>
        appStateAction(APP_STATE.LIKES_DRAWER_ACTION, {
          isOpen: false,
        })
      }
      headerText={t('common:likesScreenHeader')}
    >
      {likes ? (
        <LikesListWrap>{likesList()}</LikesListWrap>
      ) : (
        <LoadingComponent fullWidth color="#C2C2C2" variant="spin" />
      )}
    </Drawer>
  );
};

export default React.memo(LikesDrawer);
