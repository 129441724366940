import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  selected?: boolean;
  withBorder?: boolean;
  inactiveFieldsBackgroundColor?: string;
}

export const ButtonsGroupWrap = styled.div`
  width: 100%;
  display: flex;
  max-width: 860px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
`;

export const Button = styled.div<IProps>`
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ selected, inactiveFieldsBackgroundColor }) =>
    selected
      ? style.mainBlack.charcoalColor
      : inactiveFieldsBackgroundColor || style.mainWhite.white};
  cursor: pointer;

  p {
    width: 100%;
    font-size: 13px;
    line-height: 100%;
    letter-spacing: 0.02em;
    color: ${({ selected }) =>
      selected ? style.mainWhite.white : style.mainBlack.black};
    border-right: ${({ withBorder }) =>
      withBorder ? `0.5px solid ${style.mainGray.silver}` : 'none'};
    text-align: center;
  }

  :last-of-type {
    p {
      border: none;
    }
  }
`;
