import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';

import Heading from 'components/common/Typography/Heading';
import Pills from 'components/common/Pills';
import { ICreatePostQuickTags } from './ICreatePostQuickTags';

import {
  CreatePostQuickTagsWrapper,
  QuickTagsWrap,
} from './CreatePostQuickTags.styles';

const CreatePostQuickTags = ({
  interests,
  quickTags,
  setQuickTags,
}: ICreatePostQuickTags) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme: any = useTheme();

  const quickTagsList = useMemo(() => {
    if (!interests) return [];

    return interests.map((interest) => {
      const newQuickTags =
        quickTags && quickTags?.filter((tag) => tag._id !== interest._id);

      const onQuickTagClick = () => {
        if (newQuickTags?.length === quickTags?.length) {
          setQuickTags([...quickTags, interest]);
        } else {
          setQuickTags(newQuickTags);
        }
      };

      return (
        <Pills
          key={interest._id}
          onSelectItemClick={onQuickTagClick}
          isSelected={
            quickTags && quickTags?.some((el) => el._id === interest._id)
          }
          bgColor={theme.colors.neutral.n0}
          type="selectTags"
        >
          {interest.name}
        </Pills>
      );
    });
  }, [interests, quickTags]);

  return (
    <CreatePostQuickTagsWrapper>
      <Heading type="h3">{t('common:quickTags')}</Heading>
      <QuickTagsWrap>{quickTagsList}</QuickTagsWrap>
    </CreatePostQuickTagsWrapper>
  );
};

export default CreatePostQuickTags;
