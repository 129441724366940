/* eslint-disable no-nested-ternary */
import React from 'react';

import { MESSAGE_TYPE } from 'utils/constants';
import { setSearchParams, handleRedirect } from 'store/search/actions';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import _ from 'lodash';

import { clearSidebarStore } from 'store/sideBarControler/actions';
import { getUserByName } from 'services';
import { routesPath } from 'utils/constants/routePath';
import { ILeftMessageItem } from './ILeftMessageItem';
import {
  MessageContainer,
  UserProfilePhoto,
  MessageWrap,
  MessageTextWrapper,
  MessageHeaderText,
  TimeText,
  UserProfilePhotoWrap,
} from './LeftMessageItem.style';
import { EmptyUserProfilePhoto, MessageText } from '../../index';

export const LeftMessageItem = ({
  messageType,
  profilePhoto,
  username,
  message,
  datetime,
  onMessageOwnerPress,
}: ILeftMessageItem) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const onHashtagClick = (item) => {
    const postText = _.trim(item).substring(1);
    dispatch(handleRedirect(false));
    dispatch(
      setSearchParams({
        category: 2,
        start: 0,
        limit: 20,
        q: postText,
        paramsForFilter: 0,
      })
    );

    router.push(routesPath.searchPath);
  };

  const onMentionClick = async (item) => {
    const mentionUsername = _.trim(item).substring(1);

    const userInfo = await getUserByName(mentionUsername);

    if (userInfo.status === 200) {
      if (userInfo?.data?.type === 'user')
        router.push(`${routesPath.user_details}/${userInfo.data._id}`);
      if (userInfo?.data?.type === 'group') {
        router.push(`${routesPath.group_details}/${userInfo.data._id}`);
      }
      if (userInfo?.data?.type === 'event') {
        router.push(`${routesPath.event_details}/${userInfo.data._id}`);
      }
    }
    dispatch(clearSidebarStore());
  };

  console.log('message', message);
  return (
    <>
      <MessageContainer>
        <UserProfilePhotoWrap>
          {messageType === MESSAGE_TYPE.MESSAGE_LEFT_SIMPLE_ITEM ||
          messageType === MESSAGE_TYPE.MESSAGE_LEFT_SIMPLE_NO_TITLE_ITEM ? (
            <UserProfilePhoto role="img" aria-label="user profile img" />
          ) : profilePhoto && profilePhoto.trim() ? (
            <UserProfilePhoto
              img={profilePhoto}
              onClick={onMessageOwnerPress}
              cursor="pointer"
              role="img"
              aria-label="user profile img"
            />
          ) : (
            <EmptyUserProfilePhoto
              wrapSize={32}
              userIconSize={16}
              onClick={onMessageOwnerPress}
              cursor="pointer"
              withBorder={false}
            />
          )}
        </UserProfilePhotoWrap>

        <MessageWrap
          isFullRounded={
            !(
              messageType !== MESSAGE_TYPE.MESSAGE_LEFT_SIMPLE_ITEM &&
              messageType !== MESSAGE_TYPE.MESSAGE_LEFT_SIMPLE_NO_TITLE_ITEM
            )
          }
        >
          {messageType !== MESSAGE_TYPE.MESSAGE_LEFT_SIMPLE_NO_TITLE_ITEM &&
            messageType !==
              MESSAGE_TYPE.MESSAGE_LEFT_COMPLETED_NO_TITLE_ITEM && (
              <MessageHeaderText mb={4} onClick={onMessageOwnerPress}>
                {username}
              </MessageHeaderText>
            )}
          <MessageTextWrapper>
            <MessageText
              text={message || ''}
              onHashtagClick={onHashtagClick}
              onMentionClick={onMentionClick}
            />
          </MessageTextWrapper>
        </MessageWrap>
      </MessageContainer>
      {messageType !== MESSAGE_TYPE.MESSAGE_LEFT_SIMPLE_ITEM &&
        messageType !== MESSAGE_TYPE.MESSAGE_LEFT_SIMPLE_NO_TITLE_ITEM && (
          <TimeText>{datetime}</TimeText>
        )}
    </>
  );
};
