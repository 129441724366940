/* eslint-disable no-unused-expressions */
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  getCarBrands,
  addCarBrand,
  getCarTypes,
  getCarModels,
  addCarModel,
  addCarType,
  addVehicleToMyGarage,
  editMyVehicle,
  getVehicleInfo,
  addCarBrands,
  addTutorialVehicle,
  reportVehicle,
} from 'services';
import {
  getCarBrandsSuccess,
  getCarBrandsError,
  addCarBrandSuccess,
  addCarBrandError,
  getCarTypesSuccess,
  getCarTypesError,
  getCarModelsSuccess,
  getCarModelsError,
  addCarTypeSuccess,
  addCarTypeError,
  addCarModelSuccess,
  addCarModelError,
  addVehicleToMyGarageSuccess,
  addVehicleToMyGarageError,
  editMyVehicleSuccess,
  editMyVehicleError,
  getVehicleInfoStart,
  getVehicleInfoSuccess,
  getVehicleInfoError,
  addCarBrandsSuccess,
  addCarBrandsError,
  addTutorialVehicleSuccess,
  addTutorialVehicleError,
  reportVehicleSuccess,
  reportVehicleError,
  actionTypes,
} from './actions';

function* workerGetCarBrands() {
  try {
    const resp = yield call(getCarBrands);

    resp.code === 200
      ? yield put(getCarBrandsSuccess(resp.data))
      : yield put(getCarBrandsError('Error'));
  } catch (err) {
    yield put(getCarBrandsError(err));
  }
}

function* workerAddCarBrands(action) {
  try {
    const resp = yield call(addCarBrands, action.data);

    console.log('add brands', resp);

    resp.status === 204
      ? yield put(addCarBrandsSuccess())
      : yield put(addCarBrandsError(resp.data.msg));
  } catch (err) {
    yield put(addCarBrandsError(err));
  }
}

function* workerAddBrand(action) {
  try {
    const resp = yield call(addCarBrand, action.data.brand);

    console.log('action', resp);

    resp.code === 200
      ? yield put(addCarBrandSuccess(resp.data))
      : yield put(addCarBrandError('Error'));
  } catch (err) {
    yield put(addCarBrandError(err));
  }
}

function* workerGetCarTypes() {
  try {
    const resp = yield call(getCarTypes);

    console.log('types', resp.data);

    resp.code === 200
      ? yield put(getCarTypesSuccess(resp.data))
      : yield put(getCarTypesError(resp.data));
  } catch (err) {
    yield put(getCarTypesError(err));
  }
}

function* workerAddCarType(action) {
  try {
    const resp = yield call(addCarType, action.data);

    console.log('add type', resp);

    resp.code === 200
      ? yield put(addCarTypeSuccess(resp.data))
      : yield put(addCarTypeError('Error'));
  } catch (err) {
    yield put(addCarTypeError(err));
  }
}

function* workerGetCarModels(action) {
  try {
    const resp = yield call(getCarModels, action.data);

    console.log('models', resp.data);

    resp.code === 200
      ? yield put(getCarModelsSuccess(resp.data))
      : yield put(getCarModelsError(resp.data));
  } catch (err) {
    yield put(getCarTypesError(err));
  }
}

function* workerAddCarModel(action) {
  try {
    const resp = yield call(addCarModel, action.data);

    console.log('add type', resp);

    resp.code === 200
      ? yield put(addCarModelSuccess(resp.data))
      : yield put(addCarModelError('Error'));
  } catch (err) {
    yield put(addCarModelError(err));
  }
}

function* workerAddVehicleToMyGarage(action) {
  try {
    const resp = yield call(addVehicleToMyGarage, action.data);

    !resp
      ? yield put(addVehicleToMyGarageSuccess())
      : yield put(addVehicleToMyGarageError(resp.data));
  } catch (err) {
    yield put(addVehicleToMyGarageError(err));
  }
}

function* workerEditMyVehicle(action) {
  try {
    const resp = yield call(editMyVehicle, action.data);

    if (!resp) {
      yield put(editMyVehicleSuccess());
      yield put(getVehicleInfoStart(action.data.vehicleId));
    } else {
      yield put(editMyVehicleError(resp.data));
    }
  } catch (err) {
    yield put(editMyVehicleError(err));
  }
}

function* workerGetVehicleInfo(action) {
  try {
    const resp = yield call(getVehicleInfo, action.data);

    console.log('vehicleInfo', resp.data);

    resp.code === 200
      ? yield put(getVehicleInfoSuccess(resp.data))
      : yield put(getVehicleInfoError(resp.data));
  } catch (err) {
    yield put(getVehicleInfoError(err));
  }
}

function* workerAddTutorialVehicle(action) {
  try {
    const resp = yield call(addTutorialVehicle, action.data);

    console.log('tutorial vehicle', resp.data);

    resp.status === 204 || resp.code === 200
      ? yield put(addTutorialVehicleSuccess())
      : yield put(addTutorialVehicleError(resp.data));
  } catch (err) {
    yield put(addTutorialVehicleError(err));
  }
}

function* workerReportVehicle(action) {
  try {
    const resp = yield call(reportVehicle, action.data);

    console.log('resp report vehicle', resp);

    resp.status === 204 || resp.code === 200
      ? yield put(reportVehicleSuccess())
      : yield put(reportVehicleError(resp.data));
  } catch (err) {
    yield put(reportVehicleError(err));
  }
}

function* watchVehicle() {
  yield takeEvery(actionTypes.GET_CAR_BRANDS_START, workerGetCarBrands);
  yield takeEvery(actionTypes.ADD_CAR_BRAND_START, workerAddBrand);
  yield takeEvery(actionTypes.GET_CAR_TYPES_START, workerGetCarTypes);
  yield takeEvery(actionTypes.GET_CAR_MODELS_START, workerGetCarModels);
  yield takeEvery(actionTypes.ADD_CAR_TYPE_START, workerAddCarType);
  yield takeEvery(actionTypes.ADD_CAR_MODEL_START, workerAddCarModel);
  yield takeEvery(
    actionTypes.ADD_VEHICLE_TO_MY_GARAGE_START,
    workerAddVehicleToMyGarage
  );
  yield takeEvery(actionTypes.EDIT_MY_VEHICLE_START, workerEditMyVehicle);
  yield takeEvery(actionTypes.GET_VEHICLE_INFO_START, workerGetVehicleInfo);
  yield takeEvery(actionTypes.ADD_CAR_BRANDS_START, workerAddCarBrands);
  yield takeEvery(
    actionTypes.ADD_TUTORIAL_VEHICLE_START,
    workerAddTutorialVehicle
  );
  yield takeEvery(actionTypes.REPORT_VEHICLE_START, workerReportVehicle);
}

export default watchVehicle;
