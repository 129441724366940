/* eslint-disable no-shadow */
import moment from 'moment';
import _ from 'lodash';

import i18n from 'utils/i18n';
import { MESSAGE_TYPE } from './constants';

// Functions for Chat messages
const isNextMessageDifferentSenderOrLast = (index, messages) => {
  if (index === 0) {
    return true;
  }
  if (
    _.isNil(messages[index - 1].messageType) &&
    messages[index - 1].messageType === MESSAGE_TYPE.DATE_ITEM
  ) {
    return true;
  }
  if (
    _.isNil(messages[index].messageType) &&
    messages[index].messageType === MESSAGE_TYPE.DATE_ITEM
  ) {
    return false;
  }
  return messages[index - 1].from !== messages[index].from;
};

const isPreviousMessageSameSender = (index, messages) => {
  if (index + 1 >= _.size(messages)) {
    return false;
  }
  if (
    _.isNil(messages[index + 1].messageType) &&
    messages[index + 1].messageType === MESSAGE_TYPE.DATE_ITEM
  ) {
    return false;
  }
  if (
    _.isNil(messages[index].messageType) &&
    messages[index].messageType === MESSAGE_TYPE.DATE_ITEM
  ) {
    return false;
  }
  return messages[index + 1].from === messages[index].from;
};

const getMessageType = (message, index, messages) => {
  if (!_.isNil(message.media)) {
    if (message.isMe) {
      // check more
      return MESSAGE_TYPE.MESSAGE_RIGHT_MEDIA_ITEM;
    }
    return MESSAGE_TYPE.MESSAGE_LEFT_MEDIA_ITEM;
  }

  if (message.isMe) {
    if (isNextMessageDifferentSenderOrLast(index, messages)) {
      if (isPreviousMessageSameSender(index, messages)) {
        return MESSAGE_TYPE.MESSAGE_RIGHT_COMPLETED_NO_TITLE_ITEM;
      }
      return MESSAGE_TYPE.MESSAGE_RIGHT_COMPLETED_ITEM;
    }
    if (isPreviousMessageSameSender(index, messages)) {
      return MESSAGE_TYPE.MESSAGE_RIGHT_SIMPLE_NO_TITLE_ITEM;
    }
    return MESSAGE_TYPE.MESSAGE_RIGHT_ITEM;
  }

  if (isNextMessageDifferentSenderOrLast(index, messages)) {
    if (isPreviousMessageSameSender(index, messages)) {
      return MESSAGE_TYPE.MESSAGE_LEFT_COMPLETED_NO_TITLE_ITEM;
    }
    return MESSAGE_TYPE.MESSAGE_LEFT_COMPLETED_ITEM;
  }
  if (isPreviousMessageSameSender(index, messages)) {
    return MESSAGE_TYPE.MESSAGE_LEFT_SIMPLE_NO_TITLE_ITEM;
  }
  return MESSAGE_TYPE.MESSAGE_LEFT_SIMPLE_ITEM;
};

export const getFormatedDate = (datetime) => {
  if (moment().isSame(datetime, 'day')) {
    return i18n.t('common:today');
  }
  // if () {
  //   return 'Yesterday'
  // }
  return moment(datetime).format('MMM DD');
};

export const insertDateMessages = (originalMessages = []) => {
  const convertedMessages = [];
  originalMessages.forEach((message, index, messages) => {
    if (!message || !message._id) {
      return;
    }

    if (index === 0) {
      convertedMessages.push(message);
    } else if (
      !moment(message?.datetime).isSame(messages[index - 1]?.datetime, 'day')
    ) {
      convertedMessages.push({
        date: getFormatedDate(messages[index - 1].datetime),
        messageType: MESSAGE_TYPE.DATE_ITEM,
      }); // check
      convertedMessages.push(message);
    } else {
      convertedMessages.push(message);
    }
  });

  if (_.size(originalMessages) > 0) {
    convertedMessages.push({
      date: getFormatedDate(_.last(originalMessages).datetime),
      messageType: MESSAGE_TYPE.DATE_ITEM,
    });
  }

  return convertedMessages;
};

export const convertMessages = (messages) => {
  const messagesWithDateMessages = insertDateMessages(messages);
  const convertedMessages = messagesWithDateMessages.map(
    (message, index, messages) => {
      if (
        !_.isNil(message.messageType) &&
        message.messageType === MESSAGE_TYPE.DATE_ITEM
      ) {
        return message;
      }
      const tempMessage = {
        ...message,
        messageType: getMessageType(message, index, messages),
      };
      return tempMessage;
    }
  );
  return convertedMessages;
};
