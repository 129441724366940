import styled from 'styled-components';

import { style, deviceMaxWidth } from 'utils/constants/style';
import { IPopover } from './IPopover';

export const PopoverWrapp = styled.div<IPopover>`
  width: ${(props) => `${props.width}px` || 'auto'};
  position: relative;
  top: ${({ top }) => (top ? `${top}px` : '150px')};
  z-index: 110;
  background-color: ${style.mainWhite.white};
  border-radius: 10px;
  :after {
    content: '';
    z-index: -1;
    position: absolute;
    top: -7px;
    left: ${(props) => (props.variant === 'UserMenu' ? '80%' : '10%')};
    transform: rotate(180deg);
    width: 0;
    height: 0;
    border-top: ${(props) =>
      props.variant === 'withoutArrow'
        ? 'solid 10px transparent'
        : `solid 10px ${style.mainWhite.white}`};
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
  }
`;

export const ListUserUserActions = styled.ul<IPopover>`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  width: 100%;
  padding-inline-start: 0px;
  border-radius: 10px;
  background-color: ${style.mainWhite.white};
  overflow: hidden;
  filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.08));

  span {
    position: relative;
    top: 5px;
    right: 10px;
  }
  li {
    height: ${({ height }) => (height ? `${height}px` : '44px')};
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: ${({ padding }) =>
      typeof padding === 'string' ? padding : '10px 26px 10px 26px'};
    padding-left: ${({ pl }) => (typeof pl === 'number' ? `${pl}px` : '26px')};
    cursor: pointer;
    color: ${style.mainBlack.black};
    font-size: 14px;
    :hover {
      background-color: ${style.mainGray.whisperColor};
    }
  }
`;

export const ListItem = styled.li`
  padding: 10px 26px 10px 26px;
  cursor: pointer;
  color: ${style.mainBlack.black};
  font-size: 14px;
  line-height: 21px;
  :hover {
    background-color: ${style.mainGray.whisperColor};
  }
`;

export const ConfiguringPoverWrap = styled.div`
  position: relative;
  bottom: 120px;
  left: 100px;
  @media only screen and (${deviceMaxWidth.sm}) {
    left: 20px;
  }
  div {
    width: 165px;
    position: absolute;
  }
`;
