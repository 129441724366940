/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  ArrowBack,
  SearchIcon,
  PlusWithoutCircleIcon,
  WheelIcon,
  CrossIcon,
} from 'public/assets/icons';
import {
  actionTypes,
  closeSidebarTagWithAdding,
  addTags,
  removeTag,
} from 'store/sideBarControler/actions';
import { style } from 'utils/constants/style';
import {
  getCarBrandsStart,
  addCarBrandStart,
  getCarModelsStart,
  addCarModelStart,
  getCarTypesStart,
  addCarTypeStart,
} from 'store/vehicle/actions';
import { getInterestsStart, addInterestStart } from 'store/user/actions';
import { SidebarWrapper } from '../index';
import { InputField, Pill } from '../../index';
import {
  InputWrapper,
  SearchHeader,
  Text,
  NoResultWrap,
  AddingWrap,
  AddingText,
  PlusIconWrap,
  ItemsWrap,
} from './Tag.style';
import { ITag } from './ITag';

const search = (data: any[], searchValue: string, type: string) => {
  const searchValueLowerCase = searchValue.toLocaleLowerCase();

  if (type === 'interests' || type === 'brands' || type === 'types') {
    return data.filter((elem) =>
      elem.name.toLowerCase().includes(searchValueLowerCase)
    );
  }

  if (type === 'models') {
    return data.filter((elem) =>
      elem.model.toLowerCase().includes(searchValueLowerCase)
    );
  }

  return [];
};

const TagWithAdding = ({ type, sidebarHeaderStartText }: ITag) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchedItems, setSearchedItems] = useState([]);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { carBrands, carTypes, carModels, addedDataByUser } = useSelector(
    (state) => state.vehicle
  );
  const { interests } = useSelector((state) => state.user);
  const { tagsData, maxSize, carBrandId } = useSelector(
    (state) => state.sideBarControler
  );

  const userData = {
    brands: carBrands,
    interests,
    types: carTypes,
    models: carModels,
  };

  useEffect(() => {
    if (type === 'brands' && !carBrands) {
      dispatch(getCarBrandsStart());
    } else if (type === 'interests') {
      dispatch(getInterestsStart());
    } else if (type === 'types' && !carTypes) {
      dispatch(getCarTypesStart());
    } else if (type === 'models') {
      dispatch(getCarModelsStart(carBrandId));
    }
  }, [dispatch, type]);

  useEffect(() => {
    const selectedTags = tagsData[type];
    if (selectedTags && selectedTags.length) {
      setSearchedItems(selectedTags);
    }
  }, [tagsData, type]);

  useEffect(() => {
    if (addedDataByUser[type]) {
      setSearchedItems([...searchedItems, addedDataByUser[type]]);
    }
  }, [addedDataByUser]);

  const onSelectClick = (item) => {
    const newSearchedItems = searchedItems.filter((el) => el._id !== item._id);

    if (newSearchedItems.length === searchedItems.length) {
      setSearchedItems([...searchedItems, item]);
    } else {
      setSearchedItems([...newSearchedItems]);
    }
  };

  const onAddTagClick = () => {
    if (type === 'interests') {
      dispatch(addInterestStart(searchValue));
    } else if (type === 'brands') {
      dispatch(addCarBrandStart({ brand: searchValue }));
    } else if (type === 'types') {
      dispatch(addCarTypeStart(searchValue));
    } else if (type === 'models') {
      dispatch(addCarModelStart({ model: searchValue, brandId: carBrandId }));
    }
  };

  const onSubmit = () => {
    if (type === 'brands' && tagsData.models && tagsData.models.length) {
      dispatch(removeTag({ id: tagsData.models[0]._id, type: 'models' }));
    }

    dispatch(addTags({ type, tags: searchedItems }));
  };

  const selectedItemsList = searchedItems.map((item) => {
    if (type === 'models') {
      return (
        <Pill
          key={item._id}
          text={item.model}
          onClick={() => onSelectClick(item)}
          fontSize={13}
          margin="0 10px 8px 0"
          iconRight={
            <CrossIcon width="12" height="12" color={style.mainWhite.white} />
          }
          backgroundColor={style.mainBlue.blue}
          color={style.mainWhite.white}
        />
      );
    }

    return (
      <Pill
        key={item._id}
        text={item.name || item.brand}
        onClick={() => onSelectClick(item)}
        fontSize={13}
        margin="0 10px 8px 0"
        iconRight={
          <CrossIcon width="12" height="12" color={style.mainWhite.white} />
        }
        backgroundColor={style.mainBlue.blue}
        color={style.mainWhite.white}
      />
    );
  });

  const searchedItemsList = search(userData[type] || [], searchValue, type).map(
    (item) => {
      const isItemSelected = searchedItems
        .map((el) => el._id)
        .includes(item._id);

      if (type === 'models') {
        return (
          <Pill
            key={item._id}
            text={item.model}
            onClick={() => {
              if (maxSize) {
                selectedItemsList.length < maxSize && onSelectClick(item);
              } else {
                onSelectClick(item);
              }
            }}
            fontSize={13}
            margin="0 10px 8px 0"
            backgroundColor={
              isItemSelected
                ? style.mainBlue.blue
                : style.mainGray.whiteSmokeColor
            }
            color={
              isItemSelected ? style.mainWhite.white : style.mainBlack.black
            }
          />
        );
      }

      return (
        <Pill
          key={item._id}
          text={item.name}
          onClick={() => {
            if (maxSize) {
              selectedItemsList.length < maxSize && onSelectClick(item);
            } else {
              onSelectClick(item);
            }
          }}
          fontSize={13}
          margin="0 10px 8px 0"
          backgroundColor={
            isItemSelected
              ? style.mainBlue.blue
              : style.mainGray.whiteSmokeColor
          }
          color={isItemSelected ? style.mainWhite.white : style.mainBlack.black}
        />
      );
    }
  );

  const renderAddingTags = () => {
    const AddingTags = (
      <AddingWrap>
        <Pill
          text={t('common:add')}
          iconLeft={
            <PlusIconWrap>
              <PlusWithoutCircleIcon />
            </PlusIconWrap>
          }
          backgroundColor={style.mainGray.whiteSmokeColor}
          color={style.mainBlue.blue}
          onClick={onAddTagClick}
        />
        <AddingText mr={8} ml={8}>{`"${searchValue}"`}</AddingText>
        <AddingText>
          {`${t('addVehicle:asNew')} `}
          {type.slice(0, type.length - 1)}
        </AddingText>
      </AddingWrap>
    );

    if (maxSize) {
      return searchValue && selectedItemsList.length < maxSize && AddingTags;
    }

    return searchValue && AddingTags;
  };

  return (
    <SidebarWrapper
      headerText={`${sidebarHeaderStartText || 'My'} ${type}`}
      headerIcon={<ArrowBack />}
      submitText={t('editUserProfile:save')}
      isSubmitAvailable
      onSubmit={onSubmit}
      onIconClick={() =>
        dispatch(
          closeSidebarTagWithAdding(actionTypes.CLOSE_SIDEBAR_TAG_WITH_ADDING)
        )
      }
      contentPadding="20px 0"
    >
      <InputWrapper>
        <InputField
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          iconLeft={<SearchIcon colorStroke={style.mainGray.silver} />}
        />
      </InputWrapper>

      {renderAddingTags()}

      {!!searchedItems.length && (
        <>
          <SearchHeader>{t('common:selected')}</SearchHeader>
          <ItemsWrap>{selectedItemsList}</ItemsWrap>
        </>
      )}

      <SearchHeader>{t('addVehicle:selectYours')}</SearchHeader>
      {!searchedItemsList.length ? (
        <NoResultWrap>
          <Text mb={42}>{t('chat:nothingFound')}</Text>
          <WheelIcon />
        </NoResultWrap>
      ) : (
        <ItemsWrap>{searchedItemsList}</ItemsWrap>
      )}
    </SidebarWrapper>
  );
};

export default TagWithAdding;
