import fetchData from 'utils/fetchData';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const likePost = (postID: string) => {
  const token = localStorage.getItem('token');

  return fetchData(`${process.env._DB_HOST}api/v01/media/${postID}/like`, {
    method: 'PUT',
    headers: {
      ...headers,
      tsec: JSON.parse(token),
    },
  })
    .then((response) => (response.status === 204 ? null : response.json()))
    .then((resp) => resp && resp.data);
};

export const likeEvent = (eventID: string) => {
  const token = localStorage.getItem('token');

  return fetchData(`${process.env._DB_HOST}api/v01/event/${eventID}/like`, {
    method: 'PUT',
    headers: {
      ...headers,
      tsec: JSON.parse(token),
    },
  })
    .then((response) => (response.status === 204 ? null : response.json()))
    .then((resp) => resp && resp.data);
};

export const unlikeEvent = (eventID: string) => {
  const token = localStorage.getItem('token');

  return fetchData(`${process.env._DB_HOST}api/v01/event/${eventID}/like`, {
    method: 'DELETE',
    headers: {
      ...headers,
      tsec: JSON.parse(token),
    },
  })
    .then((response) => (response.status === 204 ? null : response.json()))
    .then((resp) => resp && resp.data);
};

export const unlikePost = (postID: string) => {
  const token = localStorage.getItem('token');

  return fetchData(`${process.env._DB_HOST}api/v01/media/${postID}/like`, {
    method: 'DELETE',
    headers: {
      ...headers,
      tsec: JSON.parse(token),
    },
  })
    .then((response) => (response.status === 204 ? null : response.json()))
    .then((resp) => resp && resp.data);
};
