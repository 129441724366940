import fetchData from 'utils/fetchData';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const getCommentsPost = (postID: string) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._DB_HOST}api/v01/media/${postID}/comment`, {
      method: 'GET',
      headers: { ...headers, tsec: JSON.parse(token) },
    }).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const addCommentPost = (postID: string, text: string) => {
  try {
    const token = localStorage.getItem('token');
    const bodyContent = {
      text,
    };
    return fetchData(`${process.env._DB_HOST}api/v01/media/${postID}/comment`, {
      method: 'POST',
      headers: { ...headers, tsec: JSON.parse(token) },
      body: JSON.stringify(bodyContent),
    }).then((response) => (response.status === 204 ? null : response.json()));
  } catch (error) {
    console.log(error);
  }
};

export const getCommentsEvent = (eventId) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOST}api/v01/event/${eventId}/comment`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const addCommentEvent = ({ eventId, comment }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOST}api/v01/event/${eventId}/comment`,
      {
        method: 'POST',
        headers: { ...headers, tsec: JSON.parse(token) },
        body: JSON.stringify({ text: comment }),
      }
    ).then((response) => (response.status === 204 ? null : response.json()));
  } catch (error) {
    console.log(error);
  }
};

export const getCommentsVehicles = (vehicleId) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOST}api/v01/cars/detail/${vehicleId}/comment`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const addCommentVehicle = ({ vehicleId, comment }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOST}api/v01/cars/detail/${vehicleId}/comment`,
      {
        method: 'POST',
        headers: { ...headers, tsec: JSON.parse(token) },
        body: JSON.stringify({ text: comment }),
      }
    ).then((response) => (response.status === 204 ? null : response.json()));
  } catch (error) {
    console.log(error);
  }
};

export const deleteComment = ({ commentId }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._DB_HOSTv12}api/v12/comment/${commentId}`, {
      method: 'DELETE',
      headers: { ...headers, tsec: JSON.parse(token) },
    }).then((response) =>
      response.status === 204 ? response : response.json()
    );
  } catch (error) {
    console.log(error);
  }
};
