import Text from 'components/common/Text';
import Heading from 'components/common/Typography/Heading';
import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  mt?: number;
  pb?: number;
  pt?: number;
  mb?: number;
  mr?: number;
  img?: string;
  right?: number;
}

export const MapWrapper = styled.div`
  width: 100%;
  height: 200px;
`;

export const ContentContainer = styled.div<IProps>`
  width: 100%;
  border-bottom: 1px dashed ${style.mainGray.whisperColor};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
  padding-top: ${({ pt }) => (pt ? `${pt}px` : '0')};
  padding-bottom: ${({ pb }) => (pb ? `${pb}px` : '0')};

  :last-of-type {
    border-bottom: none;
  }
`;

export const ContainerHeader = styled.h2<IProps>`
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.02em;
  font-weight: 600;
  color: ${style.mainBlack.black};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
`;

export const RouteNameWrap = styled.div`
  width: 100%;
  margin-bottom: 23px;
`;

export const RouteNameLabel = styled.p`
  font-size: 13px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.charcoalColor};
  margin-bottom: 4px;
`;

export const RouteName = styled.p`
  width: 100%;
  height: 44px;
  border-radius: 4px;
  padding: 15px 12px 14px 12px;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
`;

export const RouteCreatorBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
`;
export const RouteCreatorDetails = styled.div`
  display: flex;
  align-items: center;
`;
export const RouteCreatorActions = styled.div`
  display: flex;
  & button {
    height: 40px;
    width: 40px !important;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;
export const CreatorImage = styled.div`
  display: flex;
  margin-right: 12px;
  & > span {
    display: flex;
    border-radius: 50%;
    & img {
      object-fit: cover;
    }
  }
`;

export const RouteDescriptionBlock = styled.div`
  padding-bottom: 13.5px;
`;
export const DescriptionTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 6px;

  & > button {
    height: 24px;
    width: 24px !important;
    display: block;
  }
`;

export const DescriptionText = styled(Text)`
  color: ${(props) => props.theme.colors.neutral.n9};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  font-size: ${(props) => props.theme.typography.fontSize.f2};
`;

export const RoutePrivacyBlock = styled.div`
  padding: 16.5px 0 18px;
  border-top: 1px dashed ${(props) => props.theme.colors.neutral.n5};
`;

export const RouteRateBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
  button {
    background: #1188ff;
  }
  button.btnEdit {
    background: transparent;
    color: #1188ff;
    font-weight: 500;
    padding: 0;
  }
`;

export const RateRoute = styled.div`
  text-align: center;
  margin-bottom: 18px;
  h4 {
    color: #454545;
    font-size: 18px;
    font-weight: 500;
  }
  .star-ratings {
    margin: 20px 0 30px 0;
  }
`;

export const RateButtonBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 10px;
  button {
    background: linear-gradient(180deg, #ffa532 0%, #ff8f00 100%);
    width: 49%;
    &:hover {
      background: #ffa532;
    }
  }
  button.btnCancel {
    background: transparent;
    border: 1px solid #ff950a;
    color: #ff950a;
  }
`;

export const RateBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  h6 {
    color: #a7a7a7;
    font-size: 12px;
    margin: 0 5px;
  }
  h5 {
    color: #747474;
    font-weight: 500;
    font-size: 14px;
  }
`;

export const PrivacyTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
`;

export const PrivacyTitleLabel = styled.span`
  line-height: 16px;
  margin-left: 4px;
  padding: 1px 4px;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.orange};
  font-size: ${(props) => props.theme.typography.fontSize.f2};
  font-weight: ${(props) => props.theme.typography.fontWeight.medium};
  border-radius: 10px;
  letter-spacing: 0.02em;
`;

export const PrivacyContent = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const PrivacyText = styled(Text)`
  color: ${(props) => props.theme.colors.neutral.n9};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  font-size: ${(props) => props.theme.typography.fontSize.f2};
`;

export const PrivacySwitch = styled.span`
  display: flex;
  padding-left: 17px;
`;

export const RouteShareBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16.5px;
  border-bottom: 1px dashed ${(props) => props.theme.colors.neutral.n5};

  & > button {
    height: 40px;
    width: 40px !important;
  }
`;

export const CreatorName = styled(Text)`
  font-size: ${(props) => props.theme.typography.fontSize.f3};
`;

export const ButtonsContainer = styled.div`
  display: inline-flex;
`;

export const ButtonWrap = styled.div<IProps>`
  margin-right: ${({ mr }) => (mr ? `${mr}px` : '0')};
`;

export const RouteCreatorContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
`;

export const CreatorAvatar = styled.div<IProps>`
  width: 48px;
  height: 48px;
  min-width: 48px;
  border-radius: 50%;
  margin-right: 12px;
  background: url(${({ img }) => img}) no-repeat;
  background-size: cover;
  background-position: center;
`;

export const UsernameFullNameWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Username = styled.p`
  font-size: 13px;
  line-height: 100%;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  margin-bottom: 8px;
`;

export const FullName = styled.p`
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${style.mainGray.SuvaGrey};
`;

export const ShareButtonWrap = styled.div<IProps>`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  bottom: 4px;
  right: ${({ right }) => (right ? `${right}px` : '0')};
  cursor: pointer;
`;

export const WayPointPinWrap = styled.div`
  width: 28.8px;
  height: 28.8px;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: ${style.mainWhite.white};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid ${style.mainBlue.blue};

  span {
    font-size: 19.2px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: ${style.mainBlue.blue};
  }
`;

export const ShareContainer = styled.div``;

export const ShareTitle = styled(Heading)``;

export const PopoverContentShareIcon = styled.div`
  & > div {
    top: 5px;
    right: 0;
  }
`;

export const PopoverContent = styled.div`
  & > div {
    top: -312px;
    right: -118px;
  }
`;

export const RouteShareAndEventButtonWrap = styled.div`
  display: flex;
  padding-top: 25.5px;
  border-top: 1px dashed ${(props) => props.theme.colors.neutral.n5};
  position: relative;

  & > span {
    margin-right: 8px;
    & > button {
      & > svg {
        margin-right: 4px;
        height: 16px;
        width: 16px;
        & > path {
          stroke: ${(props) => props.theme.colors.white};
        }
      }
    }
  }
  & > button {
    & > svg {
      margin-right: 4px;
    }
    &:not(:last-child) {
      & > svg {
        height: 16px;
        width: 16px;
        & > path {
          stroke: ${(props) => props.theme.colors.white};
        }
      }
    }
  }
`;
