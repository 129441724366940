import { useRouter } from 'next/router';

import Drawer from 'components/Drawer';
import VehicleForm from 'forms/VehicleForm';
import appState from 'hooks/appState';
import { APP_STATE } from 'types';
import { uploadToS3 } from 'utils/aws/upload-file';
import { routesPath } from 'utils/constants/routePath';

import fetchApi from 'hooks/fetchApi';
import API_NAME from 'services/api';

const getCarTypeName = (carTypes) => {
  if (!carTypes?.length) return undefined;

  const carTypesName = carTypes?.map((c) => c?.name);
  return carTypesName;
};

const VehicleDrawer = () => {
  const router = useRouter();

  const appStateAction = appState();
  const { action } = fetchApi();

  const onCreateVehicle = async (v) => {
    try {
      const resMedia = await uploadToS3(v?.photos);
      // If need to change any Post attribute then change in below object
      const dataBody = {
        model: v?.model,
        make: v?.make,
        year: v?.year?.trim(),
        cartype: getCarTypeName(v?.cartype),
        version: v?.version?.trim(),
        description: v?.description?.trim(),
        nickname: v?.nickname?.trim(),
        photos: [...resMedia],
      };

      const res = await action(API_NAME.VEHICLE_CREATE_POST, {
        body: dataBody,
      });
      appStateAction(APP_STATE.ADD_VEHICLE_FORM, {
        isOpen: false,
      });
      if (res?.data?._id) {
        router.push(`${routesPath.vehicle_details}/${res?.data?._id}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Drawer
      open
      onDrawerClose={() =>
        appStateAction(APP_STATE.ADD_VEHICLE_FORM, {
          isOpen: false,
        })
      }
      loading
      rootForm
    >
      <VehicleForm
        onSubmit={onCreateVehicle}
        onIconClick={() =>
          appStateAction(APP_STATE.ADD_VEHICLE_FORM, {
            isOpen: false,
          })
        }
      />
    </Drawer>
  );
};

export default VehicleDrawer;
