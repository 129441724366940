import React from 'react';

export const EventChatIcon = ({ size = '16' }: { size?: string }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00067 5C10.1847 5 11.955 6.77026 11.955 8.95432C11.955 11.1384 10.1847 12.9091 8.00067 12.9091C5.81661 12.9091 4.0459 11.1384 4.0459 8.95432C4.0459 6.77033 5.81655 5 8.00067 5Z"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.81818 6.40912H1L2.6576 9.59094H3.94051"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.1818 6.40912H15L13.3424 9.59094H12.0595"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
