import styled from 'styled-components';

import { deviceMaxWidth, style } from 'utils/constants/style';

export const CardWrapper = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  width: calc(50% - 16px);
  margin: 8px;
  border-radius: 4px;
  overflow: hidden;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 9.67%, #1c1f29 100%);

  & img {
    object-fit: cover;
  }
`;

export const GradientWrap = styled.div`
  height: 77px;
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  bottom: 7px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 14px;
  padding-bottom: 10px;
`;

export const VehicleInfoBlock = styled.p`
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${style.mainWhite.white};
`;
