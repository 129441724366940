import React from 'react';

export const MeetUpIcon = () => (
  <svg
    width="20"
    height="19"
    viewBox="0 0 20 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="2"
      width="18"
      height="16"
      rx="1"
      stroke="#8D8D8D"
      strokeWidth="1.4"
    />
    <rect x="4" width="2" height="2" fill="#8D8D8D" />
    <rect x="14" width="2" height="2" fill="#8D8D8D" />
    <path d="M1 7L19 7" stroke="#8D8D8D" strokeWidth="1.4" />
    <rect x="3" y="12" width="4" height="4" fill="#8D8D8D" />
  </svg>
);
