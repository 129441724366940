/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, batch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { SidebarWrapper, InputField, LocationPlacesList } from 'components';
import {
  closeSidebarChoseWayPoint,
  openSidebarPlanRoute,
  setSelectedWayPointId,
} from 'store/map/actions';
import { getPlaceName, searchPlace } from 'services';
import {
  ArrowBack,
  CurrentLocationIcon,
  LocationArrowIcon,
  LocationIcon,
} from 'public/assets/icons';
import { style } from 'utils/constants/style';
import {
  CurrentLocationContainer,
  HeaderText,
} from './SidebarChoseWayPoint.style';
import { ISidebarChoseWayPoint } from './ISidebarChoseWayPoint';
import { MapSidebarWrap } from '../index';

const SidebarChoseWayPoint = ({
  userCoordinates,
  viewport,
  setViewport,
  setWayPointCoordinates,
  waypointsNumber,
  temporaryWaypoint,
  setTemporaryWaypoint,
}: ISidebarChoseWayPoint) => {
  const [placesOffered, setPlacesOffered] = useState([]);
  const [isOfferedPlacesModalOpen, setIsOfferedPlacesModalOpen] =
    useState(false);
  const [isCurrentLocation, setIsCurrentLocation] = useState(false);
  const [wayPointAddress, setWayPointAddress] = useState(
    temporaryWaypoint?.waypointName || ''
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const inputRef = useRef(null);

  useEffect(() => {
    if (temporaryWaypoint) {
      setWayPointAddress(temporaryWaypoint.waypointName);
    }
  }, [temporaryWaypoint]);

  useEffect(() => {
    dispatch(setSelectedWayPointId(waypointsNumber));

    inputRef?.current?.focus();
  }, []);

  const removeWayPoint = () => {
    if (temporaryWaypoint) {
      setTemporaryWaypoint(null);
    }
  };

  const onChangePlace = (newText) => {
    setWayPointAddress(newText);

    const proximity = userCoordinates
      ? `${userCoordinates[0]},${userCoordinates[1]}`
      : '';

    const searchPlaceByText = async () => {
      const resp = await searchPlace({
        searchText: newText.trim(),
        proximity,
      });

      if (!resp) {
        return null;
      }

      const { features } = resp;
      if (features?.length) {
        setPlacesOffered(features);
        !isOfferedPlacesModalOpen && setIsOfferedPlacesModalOpen(true);
      } else {
        setPlacesOffered([]);
        setIsOfferedPlacesModalOpen(false);
      }
    };

    if (newText.length) {
      searchPlaceByText();
    } else {
      setIsOfferedPlacesModalOpen(false);
      removeWayPoint();
    }

    setIsCurrentLocation(false);
  };

  const onPlaceOfferedClick = (place) => {
    setIsOfferedPlacesModalOpen(false);
    setWayPointAddress(place.place_name);

    const [longitude, latitude] = place.geometry.coordinates;

    setTemporaryWaypoint({
      lat: latitude,
      lng: longitude,
      waypointName: place.place_name,
      placeId: place.id,
    });
    setViewport({ ...viewport, latitude, longitude, zoom: 13 });
  };

  const onCurrentLocationClick = async () => {
    const [longitude, latitude] = userCoordinates;

    const { features } = await getPlaceName({ coordinates: userCoordinates });

    let newAddress;

    if (Array.isArray(features) && features?.length) {
      if (features[0].place_type[0]) {
        newAddress = features[0].place_name;
      } else {
        newAddress = `${userCoordinates[0].toFixed(
          6
        )},${userCoordinates[1].toFixed(6)}`;
      }
    }

    setTemporaryWaypoint({
      lat: latitude,
      lng: longitude,
      waypointName: newAddress,
      placeId: features[0]?.id,
    });

    setWayPointAddress(newAddress);
    setViewport({ ...viewport, latitude, longitude, zoom: 16 });
    setIsCurrentLocation(true);
  };

  const onCloseSidebarClick = async () => {
    batch(() => {
      dispatch(closeSidebarChoseWayPoint());
      dispatch(openSidebarPlanRoute());
    });
    removeWayPoint();
  };

  const getInputLeftIcon = () => {
    if (isCurrentLocation) {
      return <CurrentLocationIcon color={style.mainGray.SuvaGrey} />;
    }

    return temporaryWaypoint ? <LocationIcon /> : <LocationArrowIcon />;
  };

  const onSubmit = () => {
    setWayPointCoordinates({ ...temporaryWaypoint });
    setTemporaryWaypoint(null);

    batch(() => {
      dispatch(closeSidebarChoseWayPoint());
      dispatch(openSidebarPlanRoute());
    });
  };

  return (
    <MapSidebarWrap>
      <SidebarWrapper
        headerText={t('map:chooseWayPoint')}
        headerIcon={<ArrowBack />}
        submitText={t('common:add')}
        isSubmitAvailable={Boolean(temporaryWaypoint)}
        onSubmit={onSubmit}
        onIconClick={onCloseSidebarClick}
        contentPadding="20px"
      >
        <InputField
          value={wayPointAddress}
          onChange={(e) => onChangePlace(e.target.value)}
          iconLeft={getInputLeftIcon()}
          placeholder={t('map:typeAPlace')}
          label={t('map:searchLocation')}
          crossIcon
          onCrossClick={() => {
            setWayPointAddress('');
            removeWayPoint();
            setPlacesOffered([]);
            setIsOfferedPlacesModalOpen(false);
            setIsCurrentLocation(false);
          }}
          ref={inputRef}
        />

        <CurrentLocationContainer onClick={onCurrentLocationClick}>
          <CurrentLocationIcon />

          <HeaderText ml={12}>{t('map:useCurrentLocation')}</HeaderText>
        </CurrentLocationContainer>

        {isOfferedPlacesModalOpen && (
          <LocationPlacesList
            locationsList={placesOffered}
            onOutsideClick={() => setIsOfferedPlacesModalOpen(false)}
            onItemClick={(item) => onPlaceOfferedClick(item)}
            top={86}
            left={20}
          />
        )}
      </SidebarWrapper>
    </MapSidebarWrap>
  );
};

export default SidebarChoseWayPoint;
