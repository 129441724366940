/* eslint-disable no-prototype-builtins */
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Image from 'components/common/Image';
import imageResize from 'utils/imageResize';
import {
  Player,
  ControlBar,
  BigPlayButton,
  PlayToggle,
  LoadingSpinner,
} from 'video-react';
import 'node_modules/video-react/dist/video-react.css';

import getMaxString from 'utils/getMaxString';
import { IImageSlider } from './IImageSlider';
import {
  ArrowNext,
  ArrowPrev,
  CarouselDots,
  CoverImg,
  ImageSliderWrapper,
  ImageWrap,
  WheelMarkIconWrap,
} from './ImageSlider.styles';

const getVideoWidthHeight = (daimention) => {
  const wid = daimention?.width;
  const hit = daimention?.height;
  let aspect = wid / 550;
  return {
    width: 550,
    height: hit / aspect,
  };
};

const ImageSlider = ({
  images,
  alt = 'photo post image',
  variantToUse = '_detail',
}: IImageSlider) => {
  const getPostImagesHeight = (width, height) => {
    const imageWidth = 550;
    const aspectRatio = width / height;
    const h = (imageWidth / width) * height;
    if (aspectRatio >= 3) {
      return imageWidth / 3;
    }
    if (aspectRatio <= 0.66666) {
      return imageWidth * 1.5;
    }
    return h;
  };

  return (
    <ImageSliderWrapper length={images?.length}>
      {images?.length === 0 ? (
        <ImageWrap
          height={
            images[0]?.dimensions
              ? getPostImagesHeight(
                  images[0]?.dimensions?.width,
                  images[0]?.dimensions?.height
                )
              : 399
          }
        >
          <Image
            src={
              imageResize(
                images[0]?.url ? images[0]?.url : images[0],
                variantToUse
              ) || '/assets/img/tireTrace.png'
            }
            unoptimized
            priority
            alt={getMaxString(alt, 8)}
            width={550}
            height={
              images[0]?.dimensions
                ? getPostImagesHeight(
                    images[0]?.dimensions?.width || 515,
                    images[0]?.dimensions?.height || 366
                  )
                : 366
            }
          />
        </ImageWrap>
      ) : images?.length > 0 ? (
        <Carousel
          dynamicHeight
          showThumbs={false}
          showStatus={false}
          preventMovementUntilSwipeScrollTolerance
          renderArrowPrev={(clickHandler, hasPrev) =>
            hasPrev && (
              <ArrowPrev
                onClick={(e) => {
                  e.stopPropagation();
                  clickHandler();
                }}
              >
                <>
                  <svg
                    width="7"
                    height="10"
                    viewBox="0 0 7 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.66602 9L5.66602 5L1.66602 0.999998"
                      stroke="white"
                      strokeWidth="1.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </>
              </ArrowPrev>
            )
          }
          renderArrowNext={(clickHandler, hasPrev) =>
            hasPrev && (
              <ArrowNext
                onClick={(e) => {
                  e.stopPropagation();
                  clickHandler();
                }}
              >
                <>
                  <svg
                    width="7"
                    height="10"
                    viewBox="0 0 7 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.66602 9L5.66602 5L1.66602 0.999998"
                      stroke="white"
                      strokeWidth="1.4"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </>
              </ArrowNext>
            )
          }
          renderIndicator={(clickHandler, isSelected, i) => (
            <>
              {images?.length > 1 && images[0]?.hasOwnProperty('dimensions') ? (
                <CarouselDots
                  key={`renderIndicator${i}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    clickHandler(e);
                  }}
                  isSelected={isSelected}
                />
              ) : null}
            </>
          )}
        >
          {images?.length > 0 &&
            images[0]?.hasOwnProperty('dimensions') &&
            images?.map((el, i) => {
              if (el?.dimensions) {
                return (
                  <ImageWrap
                    height={
                      getPostImagesHeight(
                        el?.dimensions?.width,
                        el?.dimensions?.height
                      ) || 366
                    }
                    key={`media-${i}`}
                  >
                    {!el?.videoUrl ? (
                      <Image
                        src={
                          imageResize(el?.url, variantToUse) ||
                          el?.url ||
                          '/assets/img/tireTrace.png'
                        }
                        unoptimized
                        priority
                        alt={getMaxString(alt, 8)}
                        width={550}
                        height={
                          getPostImagesHeight(
                            el?.dimensions?.width || 515,
                            el?.dimensions?.height || 366
                          ) || 366
                        }
                      />
                    ) : (
                      <Player
                        autoPlay={false}
                        controls={false}
                        width={550}
                        height={getVideoWidthHeight(el?.dimensions)?.height}
                        fluid={false}
                        poster={el?.url}
                        // src={el?.videoUrl}
                      >
                        <source src={el?.videoUrl} />
                        <LoadingSpinner />
                        <BigPlayButton position="center" />
                        <ControlBar autoHide disableDefaultControls>
                          <PlayToggle />
                        </ControlBar>
                      </Player>
                    )}
                  </ImageWrap>
                );
              }
              return (
                <WheelMarkIconWrap key={`media-${i}`}>
                  <Image
                    src="/assets/img/tireTrace.png"
                    alt={getMaxString(alt, 8)}
                    width={515}
                    height={366}
                    unoptimized
                    priority
                  />
                </WheelMarkIconWrap>
              );
            })}
        </Carousel>
      ) : (
        <CoverImg>
          <WheelMarkIconWrap>
            <Image
              src="/assets/img/tireTrace.png"
              alt={alt}
              width={515}
              height={366}
              unoptimized
              priority
            />
          </WheelMarkIconWrap>
        </CoverImg>
      )}
    </ImageSliderWrapper>
  );
};
export default ImageSlider;
