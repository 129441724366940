import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

import imageResize from 'utils/imageResize';
import { routesPath } from 'utils/constants/routePath';
import { EmptyGroupIcon } from 'public/assets/icons';
import { ICollapsibleTags } from './ICollapsibleTags';
import {
  Wrapper,
  ItemWrap,
  Image,
  Name,
  MoreButton,
  EmptyUserPhoto,
} from './CollapsibleTags.style';
import { EmptyUserProfilePhoto } from '..';

export const CollapsibleTags = ({
  groups = [],
  events = [],
  users = [],
}: ICollapsibleTags) => {
  const [normalizedData, setNormalizedData] = useState([]);
  const [collapsed, setCollapsed] = useState(true);

  const router = useRouter();
  const { t } = useTranslation();

  useEffect(() => {
    const tags = [];
    users.forEach((item) => {
      tags.push({ ...item, navTo: 1, role: 'user' });
    });
    groups.forEach((item) => {
      tags.push({
        ...item,
        username: item.username,
        iconType: 'defaultGroupAvatar',
        navTo: 2,
        role: 'group',
      });
    });
    events.forEach((item) => {
      tags.push({
        _id: item._id,
        profilePhoto: item.coverPhoto,
        username: item.name,
        navTo: 3,
        role: 'event',
      });
    });
    setNormalizedData(tags);
  }, [users, groups, events]);

  const onItemClick = (item) => {
    const { navTo } = item;

    if (navTo === 1) {
      router.push(`${routesPath.user_details}/${item._id}`);
    } else if (navTo === 2) {
      router.push(`${routesPath.group_details}/${item._id}`);
    } else {
      router.push(`${routesPath.event_details}/${item._id}`);
    }
  };

  const renderItem = (item, index) => (
    <ItemWrap onClick={() => onItemClick(item)} key={item.username + index}>
      {item?.profilePhoto && item?.profilePhoto?.trim() ? (
        <Image
          img={imageResize(item?.profilePhoto, '_thumb') || item?.profilePhoto}
          role="img"
          aria-label="profile image"
        />
      ) : item?.role === 'group' ? (
        <EmptyUserPhoto>
          <EmptyGroupIcon />
        </EmptyUserPhoto>
      ) : (
        <EmptyUserProfilePhoto
          wrapSize={28}
          userIconSize={14}
          withBorder={false}
        />
      )}
      <Name>{item.username}</Name>
    </ItemWrap>
  );

  return (
    normalizedData.length > 0 && (
      <Wrapper className="collapsible_tags">
        {normalizedData.length > 3 && collapsed
          ? normalizedData
              .slice(0, 3)
              .map((item, index) => renderItem(item, index))
          : normalizedData.map((item, index) => renderItem(item, index))}
        {normalizedData.length > 3 && (
          <MoreButton onClick={() => setCollapsed(!collapsed)}>
            {collapsed
              ? `+${normalizedData.length - 3} ${t('common:more')}`
              : t('common:hide')}
          </MoreButton>
        )}
      </Wrapper>
    )
  );
};
