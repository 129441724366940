import Skeleton from 'components/common/Skeleton';
import {
  PostSkeletonFooter,
  PostSkeletonHeader,
  PostSkeletonHeaderContent,
  PostSkeletonWrapper,
} from './PostSkeleton.styles';

const PostSkeleton = () => {
  return (
    <PostSkeletonWrapper>
      <PostSkeletonHeader>
        <Skeleton circle width="50px" borderRadius={50} bottom={0} />
        <PostSkeletonHeaderContent>
          <Skeleton height={14} width="60%" borderRadius={10} />
          <Skeleton height={8} width="30%" bottom={0} borderRadius={10} />
        </PostSkeletonHeaderContent>
      </PostSkeletonHeader>
      <Skeleton height={366} bottom={0} />
      <PostSkeletonFooter>
        <Skeleton height={12} width="8%" bottom={0} borderRadius={10} />
        <Skeleton height={12} width="8%" bottom={0} borderRadius={10} />
        <Skeleton height={12} width="8%" bottom={0} borderRadius={10} />
        <Skeleton height={12} width="8%" bottom={0} borderRadius={10} />
      </PostSkeletonFooter>
    </PostSkeletonWrapper>
  );
};

export default PostSkeleton;
