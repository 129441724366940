import styled from 'styled-components';

import { deviceMaxWidth, style } from 'utils/constants/style';

interface IProps {
  mb?: number;
  mt?: number;
}

export const ContentWrap = styled.div<IProps>`
  max-width: 860px;
  display: flex;
  padding: 0 10px;
  margin: 0 auto;
  justify-content: space-between;
  @media (max-width: 767px) {
    padding-top: 56px;
  }
`;

export const SideWrap = styled.div`
  width: 270px;
  max-height: 820px;

  @media only screen and (max-width: 860px) {
    display: none;
  }
`;

export const ContentContainerWrap = styled.div`
  width: 860px;
  margin: 0 auto;

  @media only screen and (max-width: 860px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  @media only screen and (${deviceMaxWidth.sm}) {
    /* width: 100vw; */
  }
`;

export const WrapDonationBanner = styled.div`
  margin-top: 6px;
`;
export const WrapMyEvents = styled.div<IProps>`
  max-width: 270px;
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
`;

export const MyEventsWrap = styled.div`
  overflow: hidden;
  max-height: 200px;
  border-bottom: 1px solid ${style.mainGray.whisperColor};
`;

export const MyPopularPostsWrap = styled.div`
  overflow: hidden;
  height: 371px;
  border-bottom: 1px solid ${style.mainGray.whisperColor};
`;

export const StickyBoxWrap = styled.div`
  @media only screen and (max-width: 860px) {
    display: none;
  }
`;
