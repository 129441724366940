/* eslint-disable react/prop-types */
import React from 'react';
import { style } from '../../../utils/constants/style';

export const CalendarIcon = ({
  colorStroke = '#1C1F29',
  width = 16,
  height = 16,
}: {
  colorStroke?: string | undefined;
  width?: number;
  height?: number;
}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.7 5.5V3.76667H2.1V7.2H13.9V3.76667H11.3V5.5H9.7V3.76667H6.3V5.5H4.7ZM9.7 2.16667H6.3V0.5L4.7 0.5V2.16667H1.5C0.947715 2.16667 0.5 2.61438 0.5 3.16667V14.5C0.5 15.0523 0.947715 15.5 1.5 15.5H14.5C15.0523 15.5 15.5 15.0523 15.5 14.5V3.16667C15.5 2.61438 15.0523 2.16667 14.5 2.16667H11.3V0.5L9.7 0.5V2.16667ZM2.1 8.8V13.9H13.9V8.8H2.1Z"
      fill={style.mainBlue.blue}
    />
  </svg>
);
