import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  linkColor?: string;
}

export const Text = styled.span<IProps>`
  display: inline-block;
  color: ${({ linkColor }) => linkColor || style.mainBlue.blue};
  cursor: pointer;
  margin-right: 5.5px;
  font-size: inherit;
  font-weight: 500;
`;

export const LinkText = styled.a<IProps>`
  display: inline-block;
  font-size: inherit;
  line-height: 150%;
  letter-spacing: 0.02em;
  font-weight: 500;
  color: ${({ linkColor }) => linkColor || style.mainBlue.blue};
  cursor: pointer;
  text-decoration: none;
  margin-right: 4px;
`;
