import { Field } from 'react-final-form';

import { IField } from './IPrivacyDrawer';
import InputField from './Input';

const PrivacyDrawerField = (props: IField) => {
  return <Field component={InputField} {...props} />;
};

export default PrivacyDrawerField;
