import { fork } from 'redux-saga/effects';
import watchLogin from './auth/Login/saga';
import watchLogOut from './auth/LogOut/saga';
import watchSignUp from './auth/Registration/saga';
import watchResetPassword from './auth/ResetPassword/saga';
import watchSettings from './settings/saga';
import watchLoadIdentity from './identity/saga';
import watchSideBar from './sideBarControler/saga';
import watchUser from './user/saga';
import watchPosts from './posts/saga';
import watchVehicle from './vehicle/saga';
import watchSearch from './search/saga';
import watchNotifications from './notifications/saga';
import watchChat from './chat/saga';
import watchAnalytics from './analytics/saga';
import watchMap from './map/saga';
import watchClub from './club/saga';

export default function* rootSaga() {
  yield fork(watchLogin);
  yield fork(watchLogOut);
  yield fork(watchSignUp);
  yield fork(watchResetPassword);
  yield fork(watchLoadIdentity);
  yield fork(watchSettings);
  yield fork(watchSideBar);
  yield fork(watchUser);
  yield fork(watchPosts);
  yield fork(watchVehicle);
  yield fork(watchSearch);
  yield fork(watchNotifications);
  yield fork(watchChat);
  yield fork(watchAnalytics);
  yield fork(watchMap);
  yield fork(watchClub);
}
