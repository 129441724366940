import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  isSelected?: boolean;
  iconRight?: boolean;
  iconLeft?: boolean;
  margin?: string;
  fontSize?: number;
  backgroundColor?: string;
  color?: string;
}

export const Wrapper = styled.div<IProps>`
  height: 30px;
  padding: ${({ iconLeft }) => (iconLeft ? '0 11px 0 2px' : '0 11px')};
  background-color: ${(props) => {
    if (props.backgroundColor) {
      return props.backgroundColor;
    }

    return props.isSelected
      ? style.mainBlack.charcoalColor
      : style.mainGray.whiteSmokeColor;
  }};

  margin: ${(props) => props.margin || '0'};
  cursor: ${({ iconRight }) => (iconRight ? 'auto' : 'pointer')};
  border-radius: 30px;
  display: inline-flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 4px;
`;

export const Text = styled.span<IProps>`
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '14px')};
  line-height: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '14px')};
  letter-spacing: 0.02em;
  color: ${(props) => {
    if (props.color) {
      return props.color;
    }

    return props.isSelected
      ? style.mainWhite.white
      : style.mainGray.zambeziColor;
  }};

  margin-left: ${(props) => {
    if (props.isSelected) {
      return '4px';
    }
    if (props.iconLeft) {
      return '6px';
    }

    return '0';
  }};
`;
