import { useTranslation } from 'react-i18next';

import Fetch from 'components/Fetch';
import Heading from 'components/common/Typography/Heading';
import { CalendarIcon } from 'public/assets/icons';
import SidebarListItem from 'components/SidebarListItem';
import Image from 'components/common/Image';
import SidebarListItemSkeleton from 'components/SidebarListItemSkeleton';
import { ISidebarMyEvents } from './ISidebarMyEvents';

import {
  PlaceHolderWrapper,
  SidebarMyEventsList,
  SidebarMyEventsWrapper,
  TitleIcon,
  TitleWrapper,
} from './SidebarMyEvents.styles';

const SidebarMyEvents = ({
  listItems = [],
  loading = true,
}: ISidebarMyEvents) => {
  const { t } = useTranslation();

  return (
    <SidebarMyEventsWrapper>
      <TitleWrapper>
        <TitleIcon>
          <CalendarIcon />
        </TitleIcon>
        <Heading type="h6">{t('myEvents:headerTitle')}</Heading>
      </TitleWrapper>
      <SidebarMyEventsList dataLength={listItems?.length}>
        <Fetch
          loading={loading}
          skeleton={
            <>
              <SidebarListItemSkeleton />
              <SidebarListItemSkeleton />
              <SidebarListItemSkeleton />
            </>
          }
          dataLength={listItems?.length}
          empty={
            <PlaceHolderWrapper>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Image
                  alt="empty placeholder"
                  src="/assets/img/empty-events.svg"
                  width={72}
                  height={72}
                  unoptimized
                  priority
                />
                <div>{t('myEvents:placeholder')}</div>
              </div>
            </PlaceHolderWrapper>
          }
        >
          <>
            {listItems?.map((item) => (
              <SidebarListItem
                key={item?._id}
                id={item?._id}
                imageSrc={item?.coverPhoto}
                name={item?.name}
                startDate={item?.startDate}
              />
            ))}
          </>
        </Fetch>
      </SidebarMyEventsList>
    </SidebarMyEventsWrapper>
  );
};

export default SidebarMyEvents;
