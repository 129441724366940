import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { useField } from 'react-final-form';

import { composeValidators, required } from 'utils/validators';
import FieldElect from 'components/common/FieldElect';
import fetchApi from 'hooks/fetchApi';
import API_NAME from 'services/api';
import { SearchIcon, WheelIcon } from 'public/assets/icons';
import { IModel } from './IVehicleForm';

import {
  FormRenderPills,
  ItemForSelectText,
  ItemForSelectValue,
  ItemForSelectWrapper,
  RenderPills,
  SelectedPills,
  SelectedItemWrapper,
  SelectedItemText,
  SelectedValues,
  NoResultWrap,
  NoResultText,
  FormSearchWrapper,
  FormSearch,
} from './VehicleForm.styles';

const search = (data: any[], searchValue: string) => {
  const searchValueLowerCase = searchValue?.toLocaleLowerCase();

  return data?.filter((elem) =>
    elem?.model?.toLowerCase()?.includes(searchValueLowerCase)
  );
};

const Model = ({ id }: IModel) => {
  const { t } = useTranslation();
  const theme: any = useTheme();

  const searchValue = useField('search').input.value;

  const getCarModels = useSelector((store) => store.fetchData);

  const { action, loading } = fetchApi();

  useEffect(() => {
    if (id) {
      action(API_NAME.GET_CAR_MODELS, {
        params: {
          id,
        },
      });
    }
  }, [id]);

  return (
    <FieldElect
      name="model"
      label={t('addVehicle:model')}
      headerText="My models"
      multipleSelect={false}
      isDisabled={!id}
      render={(b, onRemove) =>
        b?.length > 0 && (
          <RenderPills
            key={b}
            onCrossClick={() => onRemove(b)}
            bgColor={theme.colors.neutral.n0}
            type="addTags"
            isCross
          >
            {b}
          </RenderPills>
        )
      }
      formRender={(value, onChange) => {
        return (
          <>
            <FormSearchWrapper>
              <FormSearch
                name="search"
                prefix={<SearchIcon colorStroke={theme.colors.neutral.n6} />}
              />
            </FormSearchWrapper>
            {value?.length > 0 && (
              <SelectedItemWrapper>
                <SelectedItemText>{t('common:selected')}</SelectedItemText>
                <SelectedValues>
                  {value && (
                    <SelectedPills
                      key={value}
                      onCrossClick={() => onChange()}
                      bgColor={theme.colors.blue}
                      color={theme.colors.white}
                      type="addTags"
                      isCross
                    >
                      {value}
                    </SelectedPills>
                  )}
                </SelectedValues>
              </SelectedItemWrapper>
            )}

            <ItemForSelectWrapper>
              <ItemForSelectText>
                {t('addVehicle:selectYours')}
              </ItemForSelectText>
              <ItemForSelectValue>
                {getCarModels[API_NAME.GET_CAR_MODELS]?.data?.length > 0 ? (
                  search(
                    getCarModels[API_NAME.GET_CAR_MODELS]?.data,
                    searchValue
                  )?.map((s, i) => {
                    return (
                      <FormRenderPills
                        key={s?._id || i}
                        onSelectItemClick={() => onChange(s?.model)}
                        bgColor={
                          s?.model === value
                            ? theme.colors.blue
                            : theme.colors.neutral.n0
                        }
                        color={
                          s?.model === value
                            ? theme.colors.white
                            : theme.colors.neutral.text
                        }
                        type="addTags"
                      >
                        {s?.model}
                      </FormRenderPills>
                    );
                  })
                ) : (
                  <NoResultWrap>
                    <NoResultText>{t('chat:nothingFound')}</NoResultText>
                    <WheelIcon />
                  </NoResultWrap>
                )}
              </ItemForSelectValue>
            </ItemForSelectWrapper>
          </>
        );
      }}
      validate={composeValidators(required(t('error:thisFieldIsRequired')))}
    />
  );
};

export default Model;
