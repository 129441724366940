import { Field } from 'react-final-form';

import { IField } from './ISelectVehicle';
import InputField from './Input';

const SelectVehicleField = (props: IField) => {
  return <Field component={InputField} {...props} />;
};

export default SelectVehicleField;
