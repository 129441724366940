import styled from 'styled-components';

import Dropdown from 'components/Dropdown';

interface IProps {
  isSidebarOpen?: boolean;
  isConformationModalOpen?: boolean;
}

export const MobileBottomNavWrapper = styled.div<IProps>`
  position: fixed;
  z-index: ${(props) =>
    (props.isSidebarOpen && '0') ||
    (props.isConformationModalOpen && '100') ||
    '200'};
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-height: 50px;
  background: ${(props) => props.theme.colors.white};
`;

export const MobileBottomNavContainer = styled.ul`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.5);
`;

export const HeaderMobileNavItem = styled.li`
  cursor: pointer;
  list-style-type: none;

  &:nth-child(3) {
    position: relative;
    bottom: 10px;
  }
`;

export const DropdownWrapper = styled(Dropdown)`
  & > div {
    & > ul {
      position: fixed;
      top: 50px;
      left: 0;
      right: 0;
      bottom: 50px;
      width: 100%;
      height: calc(100vh - 100px);
      overflow-y: auto;
      z-index: -1;
      border-radius: 0;

      & > div {
        &:first-child {
          order: 1;
          padding-top: 40px;
          & > div {
            flex-basis: auto;
          }
        }
        &:last-child {
          order: 2;
          padding-bottom: 28px;
        }
      }

      & li {
        order: 3;
        padding: 23px 24px 23px 16px;

        & > span {
          &:last-child {
            font-size: ${(props) => props.theme.typography.fontSize.f7};
            font-weight: ${(props) =>
              props.theme.typography.fontWeight.regular};
          }
        }
      }
    }
  }

  & img {
    border-radius: 50%;
  }
`;
