import React from 'react';
import { style } from 'utils/constants/style';

export const FindYourFriendsIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.2097 16.5834H18.416C18.3113 16.5405 18.2292 16.2873 18.1356 15.9985C18.0047 15.5948 17.8513 15.1216 17.5819 15.0556C17.3161 14.9905 17.0756 14.9359 16.8583 14.8866C16.0956 14.7136 15.6189 14.6054 15.3337 14.3326C15.0763 14.0863 15.2222 13.9365 15.495 13.6565C15.9186 13.2216 16.6483 12.4726 16.6483 10.5603C16.6483 8.46461 15.7719 7.41675 14.0192 7.41675C13.6018 7.41675 13.234 7.47619 12.916 7.59507C13.9333 7.97533 14.4419 8.96374 14.4419 10.5603C14.4419 12.4726 13.7123 13.2216 13.2886 13.6565C13.0158 13.9365 12.8699 14.0863 13.1274 14.3326C13.4125 14.6054 13.8892 14.7136 14.6519 14.8866C14.8692 14.9359 15.1097 14.9905 15.3755 15.0556C15.6449 15.1216 15.7983 15.5948 15.9292 15.9985C16.0228 16.2873 16.1049 16.5405 16.2097 16.5834Z"
      fill={style.mainBlue.blue}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.58301 16.5834C5.81579 16.4914 6.18711 15.0556 6.5223 15.0166C7.83765 14.8635 8.43237 14.6904 8.82073 14.3326C9.50303 13.7039 7.45612 13.7039 7.45612 10.5603C7.45612 8.46456 8.36586 7.4167 10.1853 7.4167C12.0048 7.4167 12.9146 8.46456 12.9146 10.5603C12.9146 13.7039 10.8676 13.7039 11.5499 14.3326C11.9303 14.6831 12.6388 14.7618 13.8838 15.0556C14.3635 15.1688 14.4889 16.4804 14.7497 16.5834"
      fill={style.mainBlue.blue}
    />
    <path
      d="M18.4556 18.4556L21.7889 21.7889"
      stroke={style.mainBlue.blue}
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="11.6667"
      cy="11.6667"
      r="9.66667"
      stroke={style.mainBlue.blue}
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
