import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  img?: string;
}

export const LikesListWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const LikeItemWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;
`;

export const LikeItemImage = styled.div<IProps>`
  width: 48px;
  height: 48px;
  min-width: 48px;
  border-radius: 50%;
  margin-right: 10px;
  background: url(${({ img }) => img}) no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
`;

export const LikeItemInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 0;
  margin-right: 10px;
`;

export const LikeItemHeaderText = styled.p`
  font-size: 13px;
  line-height: 13px;
  font-weight: 600;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  padding-bottom: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const LikeItemText = styled(LikeItemHeaderText)`
  font-weight: 400;
  color: ${style.mainGray.SuvaGrey};
  padding-top: 2px;
`;

export const ButtonWrap = styled.div`
  width: 100px;
  min-width: 100px;
`;
