import { IFetch } from './IFetch';

const FetchComponent = ({
  loading,
  skeleton,
  children,
  dataLength,
  empty,
}: IFetch) => {
  return loading || dataLength === undefined
    ? skeleton
    : dataLength > 0
    ? children
    : !loading && dataLength === 0 && empty;
};

export default FetchComponent;
