import React from 'react';

export const AppleIcon = () => (
  <svg
    width="20"
    height="24"
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.2808 18.424C18.933 19.2275 18.5213 19.9672 18.0442 20.6472C17.394 21.5743 16.8616 22.216 16.4513 22.5724C15.8153 23.1573 15.1338 23.4568 14.4041 23.4739C13.8802 23.4739 13.2485 23.3248 12.513 23.0224C11.7752 22.7214 11.0972 22.5724 10.4772 22.5724C9.82695 22.5724 9.12959 22.7214 8.38368 23.0224C7.63662 23.3248 7.03481 23.4824 6.57468 23.498C5.87491 23.5278 5.1774 23.2197 4.48118 22.5724C4.03681 22.1848 3.48099 21.5204 2.81515 20.5791C2.10075 19.5739 1.51342 18.4084 1.05329 17.0795C0.560506 15.6442 0.313477 14.2543 0.313477 12.9087C0.313477 11.3673 0.646541 10.0379 1.31366 8.92385C1.83796 8.029 2.53546 7.32312 3.40844 6.80493C4.28142 6.28674 5.22468 6.02267 6.24048 6.00578C6.7963 6.00578 7.52518 6.1777 8.43095 6.51559C9.33417 6.85462 9.91412 7.02655 10.1684 7.02655C10.3585 7.02655 11.0028 6.82552 12.0949 6.42473C13.1278 6.05305 13.9995 5.89916 14.7136 5.95978C16.6487 6.11595 18.1024 6.87876 19.0693 8.25303C17.3386 9.30163 16.4826 10.7703 16.4996 12.6544C16.5152 14.122 17.0476 15.3432 18.0939 16.3129C18.5681 16.7629 19.0977 17.1107 19.6868 17.3578C19.5591 17.7283 19.4242 18.0832 19.2808 18.424ZM14.8428 0.960131C14.8428 2.11039 14.4226 3.18439 13.5849 4.17847C12.5741 5.36023 11.3514 6.04311 10.0256 5.93536C10.0087 5.79736 9.99888 5.65213 9.99888 5.49951C9.99888 4.39526 10.4796 3.21349 11.3333 2.24724C11.7595 1.75801 12.3015 1.35122 12.9588 1.02671C13.6147 0.707053 14.2352 0.530273 14.8187 0.5C14.8357 0.653772 14.8428 0.807554 14.8428 0.960116V0.960131Z"
      fill="white"
    />
  </svg>
);
