import React, { useEffect } from 'react';

import { style } from 'utils/constants/style';
import { ISidebarHeader } from './ISidebarWrapper';
import {
  Header,
  HeaderTextWithIcon,
  HeaderIconWrapper,
  HeaderText,
  ContentWrapper,
} from './SidebarWrapper.style';
import { PillButton } from '../../index';

const SidebarWrapper = ({
  headerText,
  headerTextElement,
  headerIcon,
  submitText,
  isSubmitAvailable,
  contentPadding,
  headerBorder = true,
  onSubmit,
  onIconClick,
  children,
  headerRightIcon,
  padding,
  isChat,
  onSidebarClick,
  headerRightElement,
  loading = false,
  isTop = true,
}: ISidebarHeader) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
      document.body.style.overflowX = 'hidden';
    };
  }, []);
  const renderRightIcon = () => {
    if (onSubmit) {
      return headerRightIcon ? (
        <HeaderIconWrapper onClick={onSubmit}>
          {headerRightIcon}
        </HeaderIconWrapper>
      ) : (
        <PillButton
          text={submitText}
          disabled={!isSubmitAvailable || loading}
          backgroundColor={
            isSubmitAvailable
              ? style.mainYellow.oldLaceColor
              : style.mainGray.snowColor
          }
          color={
            isSubmitAvailable ? style.mainYellow.yellow : style.mainGray.silver
          }
          loading={loading}
          onClick={onSubmit}
        />
      );
    }

    if (headerRightElement) {
      return headerRightElement;
    }
  };

  return (
    <>
      <Header headerBorder={headerBorder} padding={padding} isTop={isTop}>
        <HeaderTextWithIcon>
          <HeaderIconWrapper onClick={onIconClick}>
            {headerIcon}
          </HeaderIconWrapper>
          {headerTextElement || <HeaderText>{headerText}</HeaderText>}
        </HeaderTextWithIcon>
        {renderRightIcon()}
      </Header>
      <ContentWrapper
        padding={contentPadding}
        isChat={isChat}
        onClick={onSidebarClick}
        isTop={isTop}
      >
        {children}
      </ContentWrapper>
    </>
  );
};

export default SidebarWrapper;
