import { useState } from 'react';
import { useTheme } from 'styled-components';

import Button from 'components/common/Button';
import { ArrowBack, CrossIcon } from 'public/assets/icons';
import { IDrawerForm } from './IDrawerForm';

import {
  DrawerContent,
  DrawerFormWrapper,
  DrawerHeader,
  HeaderIconWrapper,
  HeaderText,
  HeaderTextWithIcon,
} from './DrawerForm.styles';

const DrawerForm = ({
  headerText,
  isHeaderBorder = true,
  disabled = false,
  isCross = false,
  onSubmit,
  onIconClick,
  submitText,
  children,
  className,
}: IDrawerForm) => {
  const [isLoading, setIsLoading] = useState(false);

  const theme: any = useTheme();

  return (
    <DrawerFormWrapper
      onSubmit={async (v) => {
        try {
          setIsLoading(true);
          await onSubmit(v);

          setIsLoading(false);
        } catch (error) {
          setIsLoading(false);

          console.log(error);
        }
      }}
      className={className}
    >
      <DrawerHeader headerBorder={isHeaderBorder}>
        <HeaderTextWithIcon>
          <HeaderIconWrapper onClick={onIconClick}>
            {isCross ? <CrossIcon /> : <ArrowBack />}
          </HeaderIconWrapper>
          <HeaderText type="h3">{headerText}</HeaderText>
        </HeaderTextWithIcon>
        {submitText && (
          <HeaderIconWrapper>
            <Button
              htmlType="submit"
              size="sm"
              disabled={disabled}
              loading={isLoading}
              dotColor={theme?.colors.neutral.n6}
            >
              {submitText}
            </Button>
          </HeaderIconWrapper>
        )}
      </DrawerHeader>
      <DrawerContent>{children}</DrawerContent>
    </DrawerFormWrapper>
  );
};

export default DrawerForm;
