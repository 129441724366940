import React from 'react';

export const CrossIcon = ({
  width = '24',
  height = '24',
  color = '#C2C2C2',
}: {
  width?: string;
  height?: string;
  color?: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.0498 6.9502L16.9493 16.8497"
      stroke={color}
      strokeWidth={width !== '24' ? '2.5' : '1.6'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.9502 6.9502L7.0507 16.8497"
      stroke={color}
      strokeWidth={width !== '24' ? '2.5' : '1.6'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
