import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ArrowBack, CrossIcon, ChevronDownIcon } from 'public/assets/icons';
import {
  closeSideBarAddVehicle,
  actionTypes,
  openSidebarTagWithAdding,
  removeTag,
  addTags,
} from 'store/sideBarControler/actions';
import {
  addVehicleToMyGarageStart,
  clearVehicleStore,
  getVehicleInfoStart,
  getCarBrandsStart,
  getCarModelsStart,
  getCarTypesStart,
  editMyVehicleStart,
} from 'store/vehicle/actions';
import { AsyncStatus } from 'utils/types';
import { getCorrectImageBase64Format } from 'utils/getCorrectImageBase64Format';
import { style } from 'utils/constants/style';
import { SidebarWrapper } from '../index';
import { ImagesUploader, InputField, Pill, Selector } from '../../index';
import {
  Wrapper,
  SectionWrapper,
  SectionHeader,
  CollapsedBlock,
  HeadingAdditionalInfo,
  CollapsedButton,
  InputWrapper,
} from './AddVehicle.style';
import { IAddVehicle } from './IAddVehicle';

const initialState = {
  photos: [],
  year: '',
  version: '',
  nickname: '',
  description: '',
};

const AddVehicle = ({ dummy }: IAddVehicle) => {
  const [colapseAdditionalInfo, setColapseAdditionlInfo] = useState(false);
  const [isTagAdded, setIsTagsAdded] = useState(false);
  const [vehicleData, setVehicleData] = useState(initialState);
  const [isCustomBrand, setIsCustomBrand] = useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { tagsData, vehicleId } = useSelector(
    (state) => state.sideBarControler
  );
  const {
    addVehicleToMyGarageReqStat,
    editMyVehicleReqStat,
    vehicleInfo,
    carBrands,
    carTypes,
    carModels,
  } = useSelector((state) => state.vehicle);

  useEffect(() => {
    if (vehicleId) {
      dispatch(getVehicleInfoStart(vehicleId));
      dispatch(getCarBrandsStart());
      dispatch(getCarTypesStart());
    }

    return () => {
      dispatch(clearVehicleStore());
    };
  }, [vehicleId]);

  useEffect(() => {
    if (vehicleInfo && carBrands) {
      const {
        description = '',
        photos,
        year = '',
        nickname = '',
        version = '',
        make = '',
      } = vehicleInfo;

      const carBrand = carBrands.filter((brand) => brand.name === make);

      if (carBrand[0] && carBrand[0]._id) {
        dispatch(getCarModelsStart(carBrand[0]._id));
      } else {
        setIsCustomBrand(true);
      }
      setVehicleData({ photos, year, version, nickname, description });
    }
  }, [vehicleInfo, carBrands]);

  useEffect(() => {
    if (
      carBrands &&
      carTypes &&
      (carModels || isCustomBrand) &&
      vehicleInfo &&
      !isTagAdded
    ) {
      const { make = '', model = '', cartype = [] } = vehicleInfo;

      let brand = carBrands.filter((el) => el.name === make);
      let vehicleModel = carModels?.filter((el) => el.model === model);
      let types = carTypes.filter((el) => cartype.includes(el.name));

      brand = brand.length ? brand : [{ _id: 'custom', name: make }];
      vehicleModel = vehicleModel?.length
        ? vehicleModel
        : [{ _id: 'custom', model }];
      types = types.length
        ? types
        : cartype.map((el, index) => ({ _id: `custom${index}`, name: el }));

      setIsTagsAdded(true);
      dispatch(addTags({ type: 'brands', tags: brand }));
      dispatch(addTags({ type: 'models', tags: vehicleModel }));
      dispatch(addTags({ type: 'types', tags: types }));
    }
  }, [carBrands, carTypes, carModels, isCustomBrand]);

  useEffect(() => {
    if (
      addVehicleToMyGarageReqStat === AsyncStatus.SUCCESS ||
      editMyVehicleReqStat === AsyncStatus.SUCCESS
    ) {
      dispatch(closeSideBarAddVehicle());
      dispatch(clearVehicleStore());
    }
  }, [addVehicleToMyGarageReqStat, editMyVehicleReqStat, dispatch]);

  const onFileInputChange = (image: string) => {
    setVehicleData({
      ...vehicleData,
      photos: [...vehicleData.photos, image],
    });
  };

  const onEditImageIconClick = (image: string, index: number) => {
    const vehiclePhotosCopy = [...vehicleData.photos];
    vehiclePhotosCopy[index] = image;
    setVehicleData({ ...vehicleData, photos: vehiclePhotosCopy });
  };

  const onCloseIconClick = (index: number) => {
    setVehicleData({
      ...vehicleData,
      photos: vehicleData.photos.filter(
        (image, imageIndex) => imageIndex !== index
      ),
    });
  };

  const onRemoveImageClick = (index) => {
    setVehicleData({
      ...vehicleData,
      photos: vehicleData.photos.filter(
        (vehicle, vehicleIndex) => vehicleIndex !== index
      ),
    });
  };

  const onSubmit = () => {
    const vehicle = {
      photos: vehicleData.photos.map((photo) =>
        getCorrectImageBase64Format(photo)
      ),
      year: vehicleData.year,
      make: tagsData.brands[0].name,
      model: tagsData.models ? tagsData.models[0].model || '' : '',
      version: vehicleData.version,
      nickname: vehicleData.nickname,
      description: vehicleData.description,
      cartype: tagsData.types ? tagsData.types.map((el) => el.name) : [],
    };

    if (vehicleId) {
      dispatch(editMyVehicleStart({ vehicleData: vehicle, vehicleId }));
    } else {
      dispatch(addVehicleToMyGarageStart(vehicle));
    }
  };

  const tagsList = (selectedTags, type) =>
    selectedTags.map((tag) => {
      if (type === 'models') {
        return (
          <Pill
            text={tag ? tag.model : '2'}
            onClick={() =>
              dispatch(removeTag({ id: tag ? tag._id : '0', type }))
            }
            iconRight={<CrossIcon width="10" height="10" />}
            fontSize={12}
            margin="0 10px 8px 0"
            key={tag ? tag._id : '0'}
          />
        );
      }

      return (
        <Pill
          text={tag ? tag.name : '2'}
          onClick={() => dispatch(removeTag({ id: tag ? tag._id : '0', type }))}
          iconRight={<CrossIcon width="10" height="10" />}
          fontSize={12}
          margin="0 10px 8px 0"
          key={tag ? tag._id : '0'}
        />
      );
    });

  const renderTagsList = (type: string) => {
    if (tagsData[type] && tagsData[type].length) {
      return tagsList(tagsData[type], type);
    }

    return null;
  };

  const isSubmitAvailable = () => {
    let submit = true;

    if (vehicleId && vehicleInfo) {
      const vehicle = {
        photos: vehicleData.photos,
        year: vehicleData.year,
        make: tagsData.brands ? tagsData.brands[0]?.name : '',
        model: tagsData.models ? tagsData.models[0]?.model || '' : '',
        version: vehicleData.version,
        nickname: vehicleData.nickname,
        description: vehicleData.description,
        cartype: tagsData.types ? tagsData.types.map((el) => el.name) : [],
      };

      const initialVehicleData = {
        photos: vehicleInfo.photos || [],
        year: vehicleInfo.year || '',
        make: vehicleInfo.make || '',
        model: vehicleInfo.model || '',
        version: vehicleInfo.version || '',
        nickname: vehicleInfo.nickname || '',
        description: vehicleInfo.description || '',
        cartype: vehicleInfo.cartype || [],
      };

      if (JSON.stringify(vehicle) === JSON.stringify(initialVehicleData)) {
        submit = false;
      }
    }

    if (
      !vehicleData.photos.length ||
      !vehicleData.year.length ||
      !tagsData.brands ||
      !tagsData.brands.length
    ) {
      submit = false;
    }

    return submit;
  };

  return (
    <SidebarWrapper
      headerText={t('addVehicle:addVehicleText')}
      headerIcon={<ArrowBack />}
      submitText={`${
        vehicleId ? t('editUserProfile:save') : t('addVehicle:add')
      }`}
      isSubmitAvailable={isSubmitAvailable()}
      onSubmit={onSubmit}
      onIconClick={() => dispatch(closeSideBarAddVehicle())}
    >
      <Wrapper>
        <ImagesUploader
          images={vehicleData.photos}
          onChange={onFileInputChange}
          isAddVehicleImage
          onRemoveMultiplePhotoClick={onRemoveImageClick}
          onCloseClick={onCloseIconClick}
          onEditMultipleImages={onEditImageIconClick}
          isPhotoPost
        />

        <SectionWrapper>
          <SectionHeader>{t('addVehicle:vehicleInformation')}</SectionHeader>
          <InputField
            type="text"
            value={vehicleData.year}
            onChange={(e) =>
              (e.target.value === '' || +e.target.value) &&
              setVehicleData({
                ...vehicleData,
                year: String(+e.target.value),
              })
            }
            placeholder={t('addVehicle:modelYear')}
            label={t('addVehicle:modelYear')}
            crossIcon
            onCrossClick={() => setVehicleData({ ...vehicleData, year: '' })}
          />
        </SectionWrapper>

        <Selector
          title={t('addVehicle:make')}
          isSelectedItemsExist={tagsData.brands && !!tagsData.brands.length}
          onClick={() =>
            dispatch(
              openSidebarTagWithAdding({
                actionType: actionTypes.OPEN_SIDEBAR_TAG_WITH_ADDING,
                tagType: 'brands',
                maxSize: 1,
              })
            )
          }
          margin={`21px 0 ${
            tagsData.brands && !!tagsData.brands.length ? '0' : '20px'
          } 0`}
        />
        {renderTagsList('brands')}

        <Selector
          title={t('addVehicle:model')}
          isSelectedItemsExist={tagsData.models && !!tagsData.models.length}
          onClick={() =>
            tagsData.brands &&
            !!tagsData.brands.length &&
            dispatch(
              openSidebarTagWithAdding({
                actionType: actionTypes.OPEN_SIDEBAR_TAG_WITH_ADDING,
                tagType: 'models',
                maxSize: 1,
                brandId: tagsData.brands[0]._id,
              })
            )
          }
          margin={`21px 0 ${
            tagsData.models && !!tagsData.models.length ? '0' : '20px'
          } 0`}
        />
        {renderTagsList('models')}

        <SectionWrapper />
        <HeadingAdditionalInfo
          onClick={() => setColapseAdditionlInfo(!colapseAdditionalInfo)}
        >
          <div>{t('addVehicle:additionalInfo')}</div>
          <CollapsedButton colapse={colapseAdditionalInfo}>
            <ChevronDownIcon
              color={
                colapseAdditionalInfo
                  ? style.mainWhite.white
                  : style.mainBlue.blue
              }
            />
          </CollapsedButton>
        </HeadingAdditionalInfo>
        <CollapsedBlock colapse={colapseAdditionalInfo}>
          <InputWrapper>
            <InputField
              type="text"
              value={vehicleData.version}
              onChange={(e) =>
                setVehicleData({ ...vehicleData, version: e.target.value })
              }
              placeholder={t('addVehicle:vehicleTrim')}
              label={t('addVehicle:vehicleTrim')}
              crossIcon
              onCrossClick={() =>
                setVehicleData({ ...vehicleData, version: '' })
              }
            />
          </InputWrapper>
          <InputWrapper>
            <InputField
              type="text"
              value={vehicleData.nickname}
              onChange={(e) =>
                setVehicleData({ ...vehicleData, nickname: e.target.value })
              }
              placeholder={t('addVehicle:nickname')}
              label={t('addVehicle:nickname')}
              crossIcon
              onCrossClick={() =>
                setVehicleData({ ...vehicleData, nickname: '' })
              }
            />
          </InputWrapper>
          <InputWrapper>
            <InputField
              type="textarea"
              value={vehicleData.description}
              onChange={(e) =>
                setVehicleData({ ...vehicleData, description: e.target.value })
              }
              placeholder={t('addVehicle:typeDescriptionHere')}
              label={t('addVehicle:description')}
              crossIcon
              onCrossClick={() =>
                setVehicleData({ ...vehicleData, description: '' })
              }
              showCharactersNumber
              maxLength={500}
            />
          </InputWrapper>

          <Selector
            title={t('addVehicle:vehicleType')}
            isSelectedItemsExist={tagsData.types && !!tagsData.types.length}
            onClick={() =>
              dispatch(
                openSidebarTagWithAdding({
                  actionType: actionTypes.OPEN_SIDEBAR_TAG_WITH_ADDING,
                  tagType: 'types',
                })
              )
            }
            margin={`21px 0 ${
              tagsData.types && !!tagsData.types.length ? '0' : '20px'
            } 0`}
          />
          {renderTagsList('types')}
        </CollapsedBlock>
      </Wrapper>
    </SidebarWrapper>
  );
};

export default AddVehicle;
