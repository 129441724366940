import { useEffect, useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';

import { closeSideBarAddVehicleToGroup } from 'store/sideBarControler/actions';
import { getLocalStorage } from 'utils/hooks';
import { addVehiclesToGroup } from 'services/posts';
import { userGaragePageVehicles } from 'services/userGarage/userGarageVehicles';
import { ArrowBack, EmptyGroupIcon } from 'public/assets/icons';
import { style } from 'utils/constants/style';
import { SidebarWrapper } from '../index';
import { RectangleButton } from '../..';
import { IAddVehicleToGroup } from './IAddVehicleToGroup';
import {
  VehiclesItemWrap,
  ImageWrap,
  EmptyVehicleImg,
  VehicleNameWrap,
  VehicleName,
  ButtonWrap,
} from './AddVehicleToGroup.style';

export const VehiclesItem = ({
  onAddVehicle,
  added,
  image,
  make,
  model,
  year,
  id,
}: any) => {
  const { t } = useTranslation();

  return (
    <VehiclesItemWrap>
      {image ? (
        <ImageWrap>
          <Image
            loader={() => image}
            src={image}
            alt="Picture of vehicle"
            width={48}
            height={48}
            unoptimized
            priority
          />
        </ImageWrap>
      ) : (
        <ImageWrap>
          <EmptyVehicleImg>
            <EmptyGroupIcon />
          </EmptyVehicleImg>
        </ImageWrap>
      )}
      <VehicleNameWrap>
        <VehicleName>{`${make} ${model}`}</VehicleName>
        <span>{year}</span>
      </VehicleNameWrap>

      <ButtonWrap>
        <RectangleButton
          color={added ? style.mainBlue.blue : style.mainWhite.white}
          text={added ? t('common:added') : t('common:add')}
          backgroundColor={
            added ? style.mainBlue.aliceBlueColor : style.mainBlue.blue
          }
          onClick={() => onAddVehicle((prev) => [...prev, id])}
          disabled={added}
        />
      </ButtonWrap>
    </VehiclesItemWrap>
  );
};

const AddVehicleToGroup = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [vehicles, setVehicles] = useState([]);
  const [selected, setSelected] = useState([]);
  const [isAddLoading, setIsAddLoading] = useState(false);

  const { groupVehicles } = useSelector((state) => state.posts);
  const { vehicleId } = useSelector((state) => state.sideBarControler);

  const getMyVehicles = useCallback(async () => {
    try {
      const userId = getLocalStorage('user_id');
      const res = await userGaragePageVehicles(JSON.parse(userId), 0, 20);
      setVehicles([...res?.data]);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const getIdOfAddedVehicles = useMemo(() => {
    return groupVehicles?.map((v) => v?._id);
  }, [groupVehicles]);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onSubmit = async () => {
    try {
      setIsAddLoading(true);
      const res = await addVehiclesToGroup(vehicleId, selected);
      setIsAddLoading(false);
    } catch (error) {
      console.log(error);
      setIsAddLoading(false);
    }
  };

  useEffect(() => {
    getMyVehicles();
  }, [getMyVehicles]);

  return (
    <SidebarWrapper
      headerText={t('addVehicle:addVehicleText')}
      headerIcon={<ArrowBack />}
      onIconClick={() => dispatch(closeSideBarAddVehicleToGroup())}
      onSubmit={onSubmit}
      submitText={t('addVehicle:add')}
      loading={isAddLoading}
      isSubmitAvailable={selected?.length > 0}
    >
      {vehicles?.length > 0 &&
        vehicles.map((v) => (
          <VehiclesItem
            key={v?._id}
            id={v?._id}
            onAddVehicle={setSelected}
            added={
              getIdOfAddedVehicles?.includes(v?._id) ||
              selected?.includes(v?._id)
            }
            image={v?.photos[0]}
            make={v?.make}
            model={v?.model}
            year={v?.year}
          />
        ))}
    </SidebarWrapper>
  );
};

export default AddVehicleToGroup;
