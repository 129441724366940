import Text from 'components/common/Text';
import Heading from 'components/common/Typography/Heading';
import styled from 'styled-components';

interface IProps {
  mt?: number;
  mb?: number;
  mr?: number;
  ml?: number;
  pt?: number;
  pb?: number;
  fw?: number;
  isSelectedStep?: boolean;
  selected?: boolean;
}

export const CreateEventSwitcherSettingsWrapper = styled.div<IProps>`
  /* padding-top: ${({ pt }) => (pt ? `${pt}px` : '20px')};
  padding-bottom: ${({ pb }) => (pb ? `${pb}px` : '16.5px')}; */
  border-bottom: 1px dashed ${(props) => props.theme.colors.neutral.n4};

  :last-of-type {
    border: none;
  }
`;

export const SeparateSettingsHeader = styled(Heading)<IProps>`
  font-size: 16px;
  line-height: 150%;
  font-weight: ${({ fw }) => fw || '600'};
  letter-spacing: 0.02em;
  color: ${(props) => props.theme.colors.black};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
`;

export const SwitcherSettingWrap = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
`;

export const TextWrap = styled(Text)<IProps>`
  font-size: ${(props) => props.theme.typography.fontSize.f4};
  line-height: 150%;
  letter-spacing: 0.02em;
  color: ${(props) => props.theme.colors.neutral.n8};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
`;
