import React from 'react';
import { style } from 'utils/constants/style';

export const InviteYourFriendsIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12"
      cy="12"
      r="11.3333"
      stroke={style.mainBlue.blue}
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.33301 17.9999C5.46644 17.9472 5.63463 17.5572 5.8184 17.1312C6.05434 16.5841 6.31596 15.9776 6.56262 15.9488C8.28454 15.7485 9.06309 15.5219 9.57148 15.0534C9.92135 14.731 9.72304 14.5349 9.35232 14.1683C8.77661 13.599 7.78509 12.6184 7.78509 10.1151C7.78509 7.37165 8.97602 5.99991 11.3579 5.99991C13.7397 5.99991 14.9307 7.37165 14.9307 10.1151C14.9307 10.6461 14.8861 11.1086 14.8103 11.5132C13.1821 12.0197 12 13.5385 12 15.3333C12 16.3577 12.3851 17.2922 13.0185 17.9999H5.33301ZM16.0003 12.6665C16.3685 12.6665 16.667 12.965 16.667 13.3332V14.6665H18.0003C18.3685 14.6665 18.667 14.965 18.667 15.3332C18.667 15.7014 18.3685 15.9999 18.0003 15.9999H16.667V17.3332C16.667 17.7014 16.3685 17.9999 16.0003 17.9999C15.6321 17.9999 15.3337 17.7014 15.3337 17.3332V15.9999H14.0003C13.6321 15.9999 13.3337 15.7014 13.3337 15.3332C13.3337 14.965 13.6321 14.6665 14.0003 14.6665H15.3337V13.3332C15.3337 12.965 15.6321 12.6665 16.0003 12.6665Z"
      fill={style.mainBlue.blue}
    />
  </svg>
);
