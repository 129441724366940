/* eslint-disable no-unused-expressions */
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/common/Button';
import Image from 'components/common/Image';
import EmptyUserProfilePhoto from 'components/EmptyUserProfilePhoto/EmptyUserProfilePhoto';
import { routesPath } from 'utils/constants/routePath';
import imageResize from 'utils/imageResize';
import { followUser, unfollowUser } from 'services/followUser';

import { LockIcon } from 'public/assets/icons';
import { ConfirmationModal } from 'components/Modals';
import { ILikeItem } from './ILikeItem';

import {
  LikeItemHeaderText,
  LikeItemInfoWrap,
  LikeItemText,
  LikeItemWrapper,
  ProfileImageWrapper,
} from './LikeItem.styles';

const LikeItem = ({
  followingStatus,
  isFollowing,
  fullName,
  isMe,
  privacy,
  profilePhoto,
  userName,
  id,
  onItemClick,
}: ILikeItem) => {
  const [status, setStatus] = useState(followingStatus);
  const [loading, setLoading] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const router = useRouter();
  const { t } = useTranslation();

  const getButtonText = () => {
    switch (status) {
      case 0:
        return t('group:followUppercase');
      case 1:
        return t('group:requestedUppercase');
      case 2:
        return t('group:followingUppercase');
      default:
        return '';
    }
  };

  const onButtonClick = (id) => {
    if (status !== 1) {
      setLoading(true);
    }

    if (status === 0) {
      followUser(id).then((resp) => {
        if (resp.ok) {
          setStatus(privacy ? 1 : 2);
          isConfirmationModalOpen && setIsConfirmationModalOpen(false);
        }

        setLoading(false);
      });
    }

    if (status === 2) {
      unfollowUser(id).then((resp) => {
        if (resp.ok) {
          setStatus(0);
        }

        setIsConfirmationModalOpen(false);
        setLoading(false);
      });
    }
  };

  const onLikeItemClick = () => {
    router.push(`${routesPath.user_details}/${id}`);

    onItemClick();
  };

  return (
    <>
      <LikeItemWrapper onClick={onLikeItemClick}>
        <ProfileImageWrapper>
          {profilePhoto ? (
            <Image
              src={imageResize(profilePhoto, '_thumb') || profilePhoto}
              height={48}
              width={48}
              alt="profile photo"
              unoptimized
              priority
            />
          ) : (
            <EmptyUserProfilePhoto
              wrapSize={48}
              userIconSize={24}
              withBorder={false}
              margin="0 10px 0 0"
              cursor="pointer"
            />
          )}
        </ProfileImageWrapper>
        <LikeItemInfoWrap>
          {userName && (
            <LikeItemHeaderText type="h6">{userName}</LikeItemHeaderText>
          )}
          {fullName && <LikeItemText>{fullName}</LikeItemText>}
        </LikeItemInfoWrap>
        {!isMe && (
          <Button
            variant="primary"
            onClick={() => {
              if (status === 2 || !!privacy) {
                setIsConfirmationModalOpen(true);
              } else {
                onButtonClick(id);
              }
            }}
            loading={loading}
          >
            <>
              {!!privacy && status !== 1 ? <LockIcon /> : null}
              {getButtonText()}
            </>
          </Button>
        )}
      </LikeItemWrapper>
      {isConfirmationModalOpen && (
        <ConfirmationModal
          headerText={`${
            status === 2
              ? t('group:unfollowCapitalize')
              : t('search:followBtnTitle')
          } ${userName}`}
          bodyText={
            status === 2
              ? t('confirmation:unfollowUserConfirmation')
              : t('confirmation:followUserConfirmation')
          }
          submitText={`${
            status === 2
              ? t('group:unfollowUppercase')
              : t('group:followUppercase')
          }`}
          onSubmit={() => onButtonClick(id)}
          loading={loading}
          onCancelClick={() => setIsConfirmationModalOpen(false)}
        />
      )}
    </>
  );
};

export default LikeItem;
