export const getSessionStorage = (name: string) => {
  if (typeof window !== 'undefined') {
    return sessionStorage.getItem(name);
  }
};

export const setSessionStorage = (name: string, value) => {
  if (typeof window !== 'undefined') {
    sessionStorage.setItem(name, value);
  }
};
