import ReactDom from 'react-dom';
import { useTheme } from 'styled-components';
import { useEffect } from 'react';

import { CrossIcon } from 'public/assets/icons';
import { IModal } from './IModal';

import {
  ModalContainer,
  ModalOverlay,
  CrossIconWrapper,
  ModalWrapper,
} from './Modal.styles';

const Modal = ({
  open,
  onClose,
  children,
  isCloseable = true,
  className,
  isDrawerOpen = false,
  expanded = false,
}: IModal) => {
  const theme: any = useTheme();

  const handleClose = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (onClose) {
      onClose();
    }
  };

  useEffect(() => {
    if (open || isDrawerOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'unset';
      document.body.style.overflowX = 'hidden';
    };
  }, [open, isDrawerOpen]);

  const modalContent = open ? (
    <ModalOverlay expanded={expanded} onClick={handleClose}>
      <ModalWrapper
        expanded={expanded}
        className={className}
        onClick={(e) => e.stopPropagation()}
      >
        <ModalContainer expanded={expanded}>
          {isCloseable && (
            <CrossIconWrapper onClick={handleClose}>
              <CrossIcon color={theme.colors.neutral.n8} />
            </CrossIconWrapper>
          )}

          {children}
        </ModalContainer>
      </ModalWrapper>
    </ModalOverlay>
  ) : null;

  if (typeof window !== 'undefined') {
    return ReactDom.createPortal(
      modalContent,
      document.getElementById('modal-root')
    );
  }
  return null;
};

export default Modal;
