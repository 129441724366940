import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
`;

export const Loader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .spinner {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const ListContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-inline-start: 0px;
  border-radius: 10px;
  overflow: hidden;
  filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.08));
  max-height: 400px;
  overflow-y: auto;
`;
