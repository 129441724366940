import {
  AnonymousLogoIcon,
  AnonymousRockrLogoIcon,
  Logo,
  LogoWithoutBG,
  RockrLogoIcon,
} from 'public/assets/icons';
import { Icontents } from './Icontents';

const roadstrContent: Icontents = {
  authBgImage: 'https://cdn.roadstr.io/splash/roadstr-background-auth.jpg',
  donateBanerImage: 'https://cdn.roadstr.io/splash/driveandhelp-web.jpg',
  donateModalImage: 'https://cdn.roadstr.io/splash/roadstr-download-splash.jpg',
  appStore:
    'https://itunes.apple.com/us/app/roadstr-for-car-enthusiasts/id1382535778?ls=1&mt=8',
  playStore: 'https://play.google.com/store/apps/details?id=io.roadstr.app',
  appBannerImage: 'https://cdn.roadstr.io/web/ChallengerMiata-1024x577_3.jpeg',
  smallLogo: Logo,
  logoWithText: AnonymousLogoIcon,
  transparentLogo: LogoWithoutBG,
  landingEventBanner:
    'https://cdn.roadstr.io/web/landings/roadstr_events_cover.jpg',
  landingGroupBanner:
    'https://cdn.roadstr.io/web/landings/roadstr_groups_cover.jpg',
  landingRoutBanner: '',
};
const rockrContent: Icontents = {
  authBgImage: 'https://cdn.rockr.io/splash/rockr-background-auth.jpg',
  donateBanerImage: 'https://cdn.rockr.io/splash/rideandhelp-web.jpg',
  donateModalImage: 'https://cdn.rockr.io/splash/rockr-download-splash.jpg',
  appStore: 'https://apps.apple.com/es/app/rockr-ride-together/id1514759685',
  playStore: 'https://play.google.com/store/apps/details?id=io.rockr.app',
  appBannerImage: 'https://cdn.rockr.io/web/rockr-footer-join.jpeg',
  smallLogo: RockrLogoIcon,
  logoWithText: AnonymousRockrLogoIcon,
  transparentLogo: RockrLogoIcon,
  landingEventBanner:
    'https://cdn.rockr.io/web/landings/rockr_events_cover.jpg',
  landingGroupBanner:
    'https://cdn.rockr.io/web/landings/rockr_groups_cover.jpg',
  landingRoutBanner: '',
};

export { roadstrContent, rockrContent };
