/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-undef */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/dist/client/router';
import { useTranslation } from 'react-i18next';

import {
  ArrowBack,
  WelcomeToRoadSTRIcon,
  UpcomingEventIcon,
  InviteYourFriendsIcon,
  CustomNotificationIcon,
} from 'public/assets/icons';
import {
  closeSidebarPendingApprovals,
  closeSidebarNotifications,
} from 'store/sideBarControler/actions';
import {
  getRightSideImageBasedOnType,
  getLeftSideIconBasedOnType,
  getLeftSideImageBasedOnType,
  getRightSideImagePressDestinationBasedOnType,
  getLeftSideImagePressDestinationBasedOnType,
  getNotificationPressDestinationBasedOnType,
} from 'utils/notificationsHelpers';
import { SidebarWrapper, NotificationItem } from '../index';
import { NotificationText } from '../Notifications/NotificationText/NotificationText';
import { NotificationHeader } from '../Notifications/NotificationHeaderText/NotificationHeaderText';

const PendingApprovals = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { t } = useTranslation();

  const { pendingNotifications } = useSelector(
    (state) => state.sideBarControler
  );

  const getLeftSideIcon = (type) => {
    const iconText = getLeftSideIconBasedOnType(type);

    if (!iconText) {
      return false;
    }

    switch (iconText) {
      case 'welcomeToRoadSTR':
        return <WelcomeToRoadSTRIcon />;

      case 'upcomingEvent':
        return <UpcomingEventIcon />;

      case 'inviteYourFriends':
        return <InviteYourFriendsIcon />;

      case 'customNotification':
        return <CustomNotificationIcon />;

      default:
        return false;
    }
  };

  const pendingNotificationsList = pendingNotifications.map((notification) => {
    const { data } = notification;
    const { type } = notification.data;

    const leftIcon = getLeftSideIcon(type);
    const leftImage =
      getLeftSideImageBasedOnType(type, data) &&
      getLeftSideImageBasedOnType(type, data).trim();
    const rightPhoto = getRightSideImageBasedOnType(type, data) || '';

    const onLeftImageClick = getLeftSideImagePressDestinationBasedOnType(
      type,
      data
    );
    const onNotificationPress = getNotificationPressDestinationBasedOnType(
      type,
      data
    );
    const rightImageRedirectPath = getRightSideImagePressDestinationBasedOnType(
      type,
      data
    );

    return (
      <NotificationItem
        key={notification._id}
        rightPhoto={
          rightPhoto.startsWith('https') || rightPhoto.startsWith('http')
            ? rightPhoto
            : ''
        }
        leftIcon={leftIcon}
        profilePhoto={leftIcon ? '' : leftImage}
        text={<NotificationText type={type} data={data} />}
        headerText={<NotificationHeader type={type} />}
        date={notification.datetime}
        onRightPhotoClick={() => {
          if (rightImageRedirectPath) {
            router.push(rightImageRedirectPath);
            dispatch(closeSidebarNotifications());
          }
        }}
        onLeftIconClick={() => {
          if (onLeftImageClick) {
            dispatch(closeSidebarNotifications());
            router.push(onLeftImageClick);
          }
        }}
        onClick={() => {
          if (onNotificationPress) {
            dispatch(closeSidebarNotifications());
            router.push(onNotificationPress);
          }
        }}
        isNotificationClickable={Boolean(onNotificationPress)}
        withActions
      />
    );
  });

  return (
    <SidebarWrapper
      headerText={t('notifications:pendingApprovals')}
      headerIcon={<ArrowBack />}
      onIconClick={() => dispatch(closeSidebarPendingApprovals())}
      contentPadding="0 0 20px 0"
    >
      {pendingNotificationsList}
    </SidebarWrapper>
  );
};

export default PendingApprovals;
