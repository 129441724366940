import React from 'react';

export const Lock150Icon = () => (
  <svg
    width="151"
    height="151"
    viewBox="0 0 151 151"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="39.5"
      y="67.8823"
      width="72"
      height="50"
      rx="5"
      stroke="#C2C2C2"
      strokeWidth="6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M70.5 89.4998C70.5 87.0145 72.5147 84.9998 75 84.9998C77.4853 84.9998 79.5 87.0145 79.5 89.4998C79.5 91.985 77.4853 93.9998 75 93.9998C72.5147 93.9998 70.5 91.985 70.5 89.4998ZM75 79.9998C69.7533 79.9998 65.5 84.2531 65.5 89.4998C65.5 93.3175 67.752 96.6092 71 98.1191V102.5C71 104.709 72.7909 106.5 75 106.5C77.2091 106.5 79 104.709 79 102.5V98.1191C82.248 96.6092 84.5 93.3175 84.5 89.4998C84.5 84.2531 80.2467 79.9998 75 79.9998Z"
      fill="#C2C2C2"
    />
    <path
      d="M53.5 57.5C53.5 45.3497 63.3497 35.5 75.5 35.5C87.6503 35.5 97.5 45.3497 97.5 57.5V67.5H53.5V57.5Z"
      stroke="#C2C2C2"
      strokeWidth="6"
    />
  </svg>
);
