import styled from 'styled-components';

interface IProps {
  variant?: string;
  outline?: Boolean;
  buttonType?: string;
  active?: boolean;
  loading?: boolean;
  width?: string;
  size?: string;
  bgColor?: string;
}

export const SuffixWrapper = styled.span<IProps>`
  margin-right: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
`;

export const ButtonWrapper = styled.button<IProps>`
  padding: ${(props) =>
    ((props.buttonType === 'icon' || props.variant === 'link') && '0') ||
    (props.size === 'sm' && '7px 11px') ||
    '13px 20px'};
  border-radius: ${(props) => (props.buttonType === 'icon' && '8px') || '4px'};
  display: ${(props) => (props.variant === 'link' ? 'inline-block' : 'flex')};
  justify-content: center;
  align-items: center;
  height: ${(props) => props.buttonType === 'icon' && '48px'};
  width: ${(props) =>
    (props.buttonType === 'icon' && '48px') ||
    (props.width === 'full' && '100%')} !important;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: ${(props) =>
    props.variant === 'link' ? 'capitalize' : 'uppercase'};
  border: 1px solid
    ${(props) =>
      (props.variant === 'primary' &&
        props.outline &&
        props.theme.colors.orange) ||
      (props.variant === 'default' &&
        props.outline &&
        props.theme.colors.blue) ||
      (props.variant === 'secondary' &&
        props.outline &&
        props.theme.colors.neutral.n0) ||
      'transparent'};
  cursor: pointer;
  color: ${(props) =>
    (props.variant === 'primary' &&
      props.outline &&
      props.theme.colors.orange) ||
    (props.variant === 'default' && props.outline && props.theme.colors.blue) ||
    (props.variant === 'secondary' &&
      props.outline &&
      props.theme.colors.neutral.n0) ||
    (props.variant === 'secondary' && props.theme.colors.text) ||
    (props.variant === 'link' && !props.active && props.theme.colors.blue) ||
    (props.variant === 'link' &&
      props.active &&
      props.theme.colors.orangeHover) ||
    (props.variant === 'link' && 'initial') ||
    (props.buttonType === 'icon' && 'initial') ||
    props.theme.colors.white};
  background: ${(props) =>
    (props.variant === 'primary' && props.outline && 'transparent') ||
    (props.variant === 'primary' && props.theme.colors.gradients.orange) ||
    (props.variant === 'secondary' && props.outline && 'transparent') ||
    (props.variant === 'secondary' && props.theme.colors.neutral.n0) ||
    (props.variant === 'default' && props.outline && 'transparent') ||
    (props.variant === 'default' && props.theme.colors.gradients.blue) ||
    (props.variant === 'link' && 'none') ||
    (props.buttonType === 'icon' && props.bgColor
      ? props.bgColor
      : props.theme.colors.white)};
  box-shadow: ${(props) =>
    props.buttonType === 'icon' && props.theme.colors.shadows.xs};
  &:hover {
    transition: ${(props) =>
      (props.variant === 'default' ||
        props.variant === 'primary' ||
        props.variant === 'secondary') &&
      props.outline &&
      '0.3s'};

    color: ${(props) =>
      ((props.variant === 'default' || props.variant === 'primary') &&
        props.outline &&
        props.theme.colors.white) ||
      (props.variant === 'secondary' &&
        props.outline &&
        props.theme.colors.text)};
    background: ${(props) =>
      (props.variant === 'primary' &&
        props.outline &&
        props.theme.colors.orangeHover) ||
      (props.variant === 'default' &&
        props.outline &&
        props.theme.colors.blue) ||
      (props.variant === 'secondary' &&
        props.outline &&
        props.theme.colors.neutral.n0) ||
      (props.variant === 'primary' && props.theme.colors.orangeHover) ||
      (props.variant === 'secondary' && props.theme.colors.neutral.n4) ||
      (props.variant === 'link' && 'none') ||
      (props.buttonType === 'icon' && props.theme.colors.white) ||
      props.theme.colors.blue};
    ${SuffixWrapper} {
      color: ${(props) =>
        props.variant === 'link' &&
        !props.disabled &&
        !props.active &&
        props.theme.colors.orangeHover};
    }
  }

  &:disabled {
    color: ${(props) =>
      (props.variant === 'secondary' && props.theme.colors.neutral.n8) ||
      (props.buttonType === 'icon' && 'initial') ||
      props.theme.colors.neutral.n5};
    background: ${(props) =>
      (props.variant === 'secondary' &&
        !props.loading &&
        props.theme.colors.neutral.n0) ||
      (props.buttonType === 'icon' &&
        !props.loading &&
        props.theme.colors.neutral.n6) ||
      (props.variant === 'link' && !props.loading && 'none') ||
      (!props.loading && props.theme.colors.neutral.n6)};
    cursor: not-allowed;
    ${SuffixWrapper} {
      background: ${(props) => props.theme.colors.neutral.n6};
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

export const PrefixWrapper = styled.span`
  margin-left: 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
`;
