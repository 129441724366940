import React from 'react';

export const EmptyMyGroupsIcon = () => (
  <svg
    width="104"
    height="105"
    viewBox="0 0 104 105"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.0017 20.9998C69.1227 20.9998 83 34.8771 83 51.9981C83 69.1193 69.1224 82.9998 52.0017 82.9998C34.8807 82.9998 21 69.1191 21 51.9981C21 34.8774 34.8804 20.9998 52.0017 20.9998ZM81.8953 38.0052C76.6304 26.776 65.2248 18.9998 52.0017 18.9998C38.7785 18.9998 27.3712 26.776 22.1053 38.0052C22.0707 38.0016 22.0356 37.9998 22 37.9998H3C2.6795 37.9998 2.37843 38.1534 2.19033 38.4129C2.00224 38.6724 1.94992 39.0063 2.04963 39.3109L10.8888 66.3109C11.0234 66.7218 11.4068 66.9998 11.8392 66.9998H22C22.1921 66.9998 22.3716 66.9456 22.524 66.8517C27.9595 77.6169 39.1188 84.9998 52.0017 84.9998C64.8272 84.9998 75.9428 77.6825 81.4038 66.9952C81.4355 66.9982 81.4676 66.9998 81.5 66.9998H91.9398C92.3695 66.9998 92.7512 66.7253 92.8879 66.3179L101.948 39.3179C102.05 39.0128 102 38.6771 101.812 38.4159C101.624 38.1546 101.322 37.9998 101 37.9998H82C81.9646 37.9998 81.9297 38.0016 81.8953 38.0052ZM82.7512 39.9998C84.2031 43.7184 85 47.7651 85 51.9981C85 56.6147 84.0522 61.0099 82.3404 64.9998H91.2206L99.6096 39.9998H82.7512ZM4.3796 39.9998H21.2493C19.7971 43.7184 19 47.7651 19 51.9981C19 56.6147 19.9481 61.0099 21.6601 64.9998H12.564L4.3796 39.9998ZM52.0014 25.9998C66.3609 25.9998 78 37.6388 78 51.9983C78 66.3581 66.3607 77.9998 52.0014 77.9998C37.6419 77.9998 26 66.3579 26 51.9983C26 37.6391 37.6416 25.9998 52.0014 25.9998ZM80 51.9983C80 36.5342 67.4655 23.9998 52.0014 23.9998C36.5373 23.9998 24 36.5342 24 51.9983C24 67.4624 36.5373 79.9998 52.0014 79.9998C67.4655 79.9998 80 67.4624 80 51.9983Z"
      fill="#747474"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.556 48.3333L61.7782 42.2222L54.4449 55.6667L42.2227 61.7778L49.556 48.3333ZM52.0004 54.4445C53.3505 54.4445 54.4449 53.35 54.4449 52C54.4449 50.65 53.3505 49.5556 52.0004 49.5556C50.6504 49.5556 49.556 50.65 49.556 52C49.556 53.35 50.6504 54.4445 52.0004 54.4445Z"
      fill="#747474"
    />
    <path
      d="M61.7782 42.2222L62.2172 42.4617C62.3218 42.2698 62.2897 42.0323 62.138 41.875C61.9863 41.7178 61.75 41.6773 61.5546 41.775L61.7782 42.2222ZM49.556 48.3333L49.3324 47.8861C49.2411 47.9318 49.1659 48.0043 49.117 48.0939L49.556 48.3333ZM54.4449 55.6667L54.6685 56.1139C54.7598 56.0682 54.8349 55.9957 54.8838 55.9061L54.4449 55.6667ZM42.2227 61.7778L41.7837 61.5384C41.6791 61.7302 41.7111 61.9677 41.8628 62.125C42.0146 62.2822 42.2508 62.3227 42.4463 62.225L42.2227 61.7778ZM61.5546 41.775L49.3324 47.8861L49.7796 48.7806L62.0018 42.6694L61.5546 41.775ZM54.8838 55.9061L62.2172 42.4617L61.3393 41.9828L54.0059 55.4272L54.8838 55.9061ZM42.4463 62.225L54.6685 56.1139L54.2213 55.2195L41.999 61.3306L42.4463 62.225ZM49.117 48.0939L41.7837 61.5384L42.6616 62.0172L49.9949 48.5728L49.117 48.0939ZM53.9449 52C53.9449 53.0739 53.0743 53.9445 52.0004 53.9445V54.9445C53.6266 54.9445 54.9449 53.6262 54.9449 52H53.9449ZM52.0004 50.0556C53.0743 50.0556 53.9449 50.9261 53.9449 52H54.9449C54.9449 50.3738 53.6266 49.0556 52.0004 49.0556V50.0556ZM50.056 52C50.056 50.9261 50.9265 50.0556 52.0004 50.0556V49.0556C50.3743 49.0556 49.056 50.3738 49.056 52H50.056ZM52.0004 53.9445C50.9265 53.9445 50.056 53.0739 50.056 52H49.056C49.056 53.6262 50.3743 54.9445 52.0004 54.9445V53.9445Z"
      fill="#747474"
    />
    <rect
      x="50.7773"
      y="32.4445"
      width="2.44445"
      height="4.88889"
      rx="1"
      fill="#747474"
    />
    <rect
      x="50.7773"
      y="66.6667"
      width="2.44445"
      height="4.88889"
      rx="1"
      fill="#747474"
    />
    <rect
      x="71.5557"
      y="50.7778"
      width="2.44445"
      height="4.88889"
      rx="1"
      transform="rotate(90 71.5557 50.7778)"
      fill="#747474"
    />
    <rect
      x="37.333"
      y="50.7778"
      width="2.44445"
      height="4.88889"
      rx="1"
      transform="rotate(90 37.333 50.7778)"
      fill="#747474"
    />
  </svg>
);
