import fetchData from 'utils/fetchData';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

interface IGetUserGroups {
  offset: number;
  limit: number;
  search: string;
}

export const getUserGroups = ({ offset, limit, search }: IGetUserGroups) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOSTv12}api/v12/profile/groups?offset=${offset}&limit=${limit}&search=${search}`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};
