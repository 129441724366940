export const routesPath = {
  feedPath: '/feed',
  searchPath: '/search',
  notificationsPath: '/notifications',
  mapPath: '/map',
  authentication: '/authentication',
  tutorial: '/tutorial',
  settings: '/settings',
  user_details: '/u',
  group_details: '/g',
  event_details: '/e',
  post_details: '/p',
  vehicle_details: '/v',
  routeDetails: '/r',
  business: '/b',
  shop: '/s',
  components: '/components',
  resetPassword: '/magic_link/reset',
  activateAccount: '/magic_link/activate',
  mailLogin: '/magic_link/login',
  vehicles_attending: '/vehicles_attending',
  group_garage: '/group_garage',
  my_events: '/my_events',
  my_groups: '/my_groups',
  my_garage: '/my_garage',
  my_favorites: '/my_favorites',
  map: '/map',
  custom: '/custom',
  donate: '/donate',
  termsOfServices: 'terms_of_services',
  privacyPolicy: '/privacy_policy',
  landing_events: '/events',
  landing_groups: '/groups',
  landing_routs: '/routes',
  es_landing_events: '/es/events',
  es_landing_groups: '/es/groups',
  es_landing_routs: '/es/routes',
};
