import React from 'react';
import { style } from 'utils/constants/style';

export const PlusIcon = ({
  color = style.mainBlue.blue,
}: {
  color?: string;
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM11.1667 7.83333C11.1667 7.3731 11.5398 7 12 7C12.4602 7 12.8333 7.3731 12.8333 7.83333V11.1667H16.1667C16.6269 11.1667 17 11.5398 17 12C17 12.4602 16.6269 12.8333 16.1667 12.8333H12.8333V16.1667C12.8333 16.6269 12.4602 17 12 17C11.5398 17 11.1667 16.6269 11.1667 16.1667V12.8333H7.83333C7.3731 12.8333 7 12.4602 7 12C7 11.5398 7.3731 11.1667 7.83333 11.1667H11.1667V7.83333Z"
      fill={color}
    />
  </svg>
);
