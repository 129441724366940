import styled from 'styled-components';

import Text from 'components/common/Text';

interface IProps {
  windowSize?: boolean;
}

export const MobileLogoutHeaderWrapper = styled.div<IProps>`
  display: flex;
  flex-direction: ${(props) => props.windowSize && 'column'};
  align-items: ${(props) => props.windowSize && 'center'};
  justify-content: ${(props) => !props.windowSize && 'space-between'};
  min-height: 56px;
  padding: 10px 24px;
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.text};
  box-shadow: ${(props) => props.theme.colors.shadows.xs};
  box-sizing: border-box;
  width: 100%;
`;
export const MobileLogoWrapper = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
`;
export const AuthOptionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  & button {
    width: 100%;
    height: 32px;
  }
`;
export const OrText = styled.span`
  padding: 0 25px;
  font-size: ${(props) => props.theme.typography.fontSize.f4};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.colors.neutral.n8};
  letter-spacing: 1px;
`;
export const AuthDescriptionText = styled(Text)`
  margin: 12px 0 0;
  font-size: ${(props) => props.theme.typography.fontSize.f3};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.colors.neutral.n8};
  letter-spacing: 1px;
`;
