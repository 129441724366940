import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { getLocalStorage } from 'utils/hooks';

// Languages
import en from 'locales/en.json';
import es from 'locales/es.json';

import enRockr from 'locales/rockr/en.json';
import esRockr from 'locales/rockr/es.json';
import { useRouter } from 'next/router';

const checkAuthenticate = () => {
  if (typeof window !== 'undefined') {
    const token = localStorage.getItem('token');
    const isHaveToken = token && (token !== undefined || token !== 'undefined');
    let userId = localStorage.getItem('user_id');
    userId = userId === 'undefined' ? '' : JSON.parse(userId);

    if (isHaveToken) {
      if (userId === process.env._ANONYMOUS_ID) return false;
      return true;
    }
    return false;
  }
  return false;
};

const languageDetector: any = {
  init: Function.prototype,
  type: 'languageDetector',
  async: true, // flags below detection to be async
  detect: (callback) => {
    let selectLanguage = 'en';
    const isAuthenticate = checkAuthenticate();

    if (typeof window !== 'undefined') {
      const localLocale =
        window.location.pathname.split('/')[1] === 'es'
          ? 'es'
          : getLocalStorage('RoadStr-appLanguage') || 'en';
      const userLocale =
        window.location.pathname.split('/')[1] === 'es'
          ? 'es'
          : navigator.language.split('-')[0];
      if (isAuthenticate)
        if (userLocale === 'es' || userLocale === 'ca') selectLanguage = 'es';
        else selectLanguage = localLocale;
      else selectLanguage = userLocale;
    }
    callback(selectLanguage);
  },
  cacheUserLanguage: () => {},
};

const resources = {
  en: process.env._ENV === 'rockr' ? enRockr : en,
  es: process.env._ENV === 'rockr' ? esRockr : es,
};

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
