import {
  ReactElement,
  useMemo,
  createContext,
  useCallback,
  useState,
  useEffect,
} from 'react';
import { ThemeProvider } from 'styled-components';

import { rockrContent, roadstrContent } from './contents';
import {
  roadstrDarkStyle,
  roadstrLightStyle,
  rockrDarkStyle,
  rockrLightStyle,
} from './styles';

export const ThemeContext = createContext({});

interface ITheme {
  children: ReactElement;
}

const Theme = ({ children }: ITheme) => {
  const [theme, setTheme] = useState(null);

  const isRockr = process.env._ENV === 'rockr';

  const themeProvider = useMemo(() => {
    const roadstr = theme === 'dark' ? roadstrDarkStyle : roadstrLightStyle;
    const rockr = theme === 'dark' ? rockrDarkStyle : rockrLightStyle;
    if (isRockr) {
      return rockr;
    }
    return roadstr;
  }, [theme]);

  const content = useMemo(() => {
    if (isRockr) {
      return rockrContent;
    }
    return roadstrContent;
  }, []);

  const userData = () => {
    if (typeof window !== 'undefined') {
      const userDataLocal = localStorage.getItem('user_data');
      if (userDataLocal !== 'undefined') {
        const localUser = JSON.parse(userDataLocal);
        if (localUser?.data?._id !== process.env._ANONYMOUS_ID) {
          return localUser?.data;
        }
        return undefined;
      }
      return undefined;
    }
    return undefined;
  };

  const userIdLocal = () => {
    if (typeof window !== 'undefined') {
      const userIdLocal = localStorage.getItem('user_id');
      if (userIdLocal !== 'undefined') {
        const localUserId = JSON.parse(userIdLocal);
        if (localUserId !== process.env._ANONYMOUS_ID) {
          return localUserId;
        }
        return undefined;
      }
      return undefined;
    }
    return undefined;
  };

  const checkAuthenticate = () => {
    if (typeof window !== 'undefined') {
      const token = localStorage.getItem('token');
      const isHaveToken =
        token && (token !== undefined || token !== 'undefined');
      let userId = localStorage.getItem('user_id');
      userId = userId === 'undefined' ? '' : JSON.parse(userId);

      if (isHaveToken) {
        if (userId === process.env._ANONYMOUS_ID) {
          return false;
        }
        return true;
      }
      return false;
    }
    return false;
  };

  const toggleTheme = useCallback(() => {
    if (typeof window !== 'undefined') {
      const themeColor = localStorage.getItem('theme');

      if (themeColor === 'light') {
        setTheme('dark');
        localStorage.setItem('theme', 'dark');
      } else {
        setTheme('light');
        localStorage.setItem('theme', 'light');
      }
    }
  }, []);

  const isAuthenticate = checkAuthenticate();
  const userId = userIdLocal();
  const user = userData();

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const contextValue = { toggleTheme, content, user, isAuthenticate, userId };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const themeColor = localStorage.getItem('theme');
      if (themeColor) {
        setTheme(themeColor);
      }
    }
  }, []);

  return (
    <ThemeContext.Provider value={contextValue}>
      <ThemeProvider theme={themeProvider}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default Theme;
