import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  pt?: number;
  pb?: number;
  colapse?: boolean;
}

export const Wrapper = styled.div`
  padding-top: 20px;
`;

export const SectionWrapper = styled.div<IProps>`
  padding-top: ${({ pt }) => (pt ? `${pt}px` : '20px')};
  padding-bottom: ${({ pb }) => (pb ? `${pb}px` : '16.5px')};
  border-bottom: 1px dashed ${style.mainGray.whisperColor};

  :last-of-type {
    border: none;
  }
`;

export const SectionHeader = styled.h3`
  font-size: 18px;
  line-height: 130%;
  font-weight: 600;
  color: ${style.mainBlack.black};
  margin-bottom: 13px;
`;

export const CollapsedBlock = styled.div<IProps>`
  display: ${({ colapse }) => (colapse ? `block` : 'none')};
  height: auto;
  width: auto;
`;

export const HeadingAdditionalInfo = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  padding-top: 16px;
  cursor: pointer;
`;

export const CollapsedButton = styled.div<IProps>`
  width: 16px;
  height: 16px;
  background: ${({ colapse }) =>
    colapse ? style.mainBlue.blue : style.mainGray.whiteSmokeColor};
  border-radius: 50%;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  transform: ${({ colapse }) => (colapse ? `rotate(180deg)` : 'none')}; ;
`;

export const InputWrapper = styled.div`
  padding-top: 20px;
`;
