import React from 'react';

export const CalendarWithStarIcon = ({
  color = '#A9A9B5',
}: {
  color?: string;
}) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4867 4.12H14.8916V3.23522C14.8916 2.82915 14.5625 2.5 14.1563 2.5H14.0397C13.6336 2.5 13.3046 2.82915 13.3046 3.23522V4.12H7.67663V3.23522C7.67663 2.82915 7.34747 2.5 6.94153 2.5H6.82481C6.41874 2.5 6.08971 2.82915 6.08971 3.23522V4.12H4.51317C3.67894 4.12 3 4.7987 3 5.63317V16.2253C3 17.0593 3.67894 17.7384 4.51317 17.7384H16.4868C17.3209 17.7384 18 17.0594 18 16.2253V5.63317C17.9999 4.7987 17.3208 4.12 16.4867 4.12ZM16.2893 16.0281H4.71061V7.7828H16.2893V16.0281ZM8.51711 13.6992L8.75091 12.3105L7.74514 11.3244C7.60742 11.19 7.55859 10.9888 7.61792 10.8059C7.67726 10.6231 7.835 10.4893 8.02558 10.4612L9.41862 10.2548L10.0454 8.99374C10.1313 8.82159 10.3074 8.71256 10.4993 8.71256C10.6916 8.71256 10.8679 8.82159 10.953 8.99374L11.5804 10.2548L12.9735 10.4614C13.1637 10.4895 13.3222 10.6233 13.3814 10.806C13.4404 10.9889 13.3914 11.1899 13.2539 11.3245L12.2486 12.3107L12.4822 13.6993C12.5143 13.8891 12.4362 14.0805 12.2806 14.1938C12.1924 14.2576 12.0877 14.2905 11.9829 14.2905C11.9028 14.2905 11.8218 14.2712 11.748 14.2328L10.4995 13.581L9.25099 14.2327C9.08092 14.3218 8.87422 14.3067 8.71856 14.1936C8.56314 14.0803 8.48524 13.889 8.51711 13.6992Z"
      fill={color}
    />
  </svg>
);
