import fetchData from 'utils/fetchData';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const GetSettings = (userId: string) => {
  const token = localStorage.getItem('token');

  return fetchData(`${process.env._DB_HOST}api/v01/user/${userId}/settings`, {
    method: 'get',
    headers: {
      ...headers,
      tsec: JSON.parse(token),
    },
  })
    .then((response) => response.json())
    .then((res) => res.data);
};

export const PatchSettings = (userId, settings) => {
  const token = localStorage.getItem('token');

  return fetchData(`${process.env._DB_HOST}api/v01/user/${userId}/settings`, {
    method: 'PATCH',
    headers: {
      ...headers,
      tsec: JSON.parse(token),
    },
    body: JSON.stringify(settings),
  })
    .then((response) => (response.status === 204 ? null : response.json()))
    .then((resp) => resp && resp.data);
};

export const PostEmail = (userId, newEmail) => {
  const token = localStorage.getItem('token');

  return fetchData(`${process.env._DB_HOST}api/v01/user/${userId}/email`, {
    method: 'post',
    headers: {
      ...headers,
      tsec: JSON.parse(token),
    },
    body: JSON.stringify({ newEmail }),
  }).then((response) => (response.status === 204 ? null : response.json()));
};

export const postPassword = (
  newPassword: string,
  newPasswordConfirmation: string
) => {
  const token = localStorage.getItem('token');

  return fetchData(`${process.env._DB_HOST}api/v04/user/changePassword`, {
    method: 'post',
    headers: {
      ...headers,
      tsec: JSON.parse(token),
    },
    body: JSON.stringify({ newPassword, newPasswordConfirmation }),
  }).then((response) => (response.status === 204 ? null : response.json()));
};
