export const style =
  process.env._ENV === 'rockr'
    ? {
        mainWhite: {
          white: '#ffffff',
          alabaster: '#fbfbfb',
        },
        mainBlack: {
          black: '#1C1F29',
          charcoalColor: '#454545',
          appleBtnBlack: '#000000',
        },
        mainGray: {
          gray: '#A7A7A7',
          silver: '#C2C2C2',
          whiteSmokeColor: '#F7F7F7',
          snowColor: '#F9F9F9',
          dimGrayColor: '#747474',
          whisperColor: '#ECECEC',
          echoBlue: '#BBBCBF',
          zambeziColor: '#5C5C5C',
          SuvaGrey: '#8D8D8D',
          gainsboroColor: '#E3E3E3',
          midGray: '#5b5d67',
          ironsideGray: '#65676b',
          spunPearl: '#a9a9b5',
        },
        mainRed: {
          red: 'red',
          cinnabarColor: '#EA3C24',
          mistyRose: '#FEE7E3',
        },
        mainYellow: {
          yellow: '#ED0547',
          oldLaceColor: '#FFF5E8',
          sunshade: '#ED0547',
          orangeGradientTop: '#FF1F5E',
        },
        mainBlue: {
          blue: '#0884CC',
          dodgerBlueColor: '#1877F2',
          hoverBtnBlue: '#2691fc',
          aliceBlueColor: '#E8F3FF',
          CornflowerBlue: '#46A3FF',
          dodgerBlue: '#0575B6',
        },
        mainOrange: {
          darkOrange: '#D4043F',
        },
        fontFamily: {
          poppins: 'Poppins',
        },
      }
    : {
        mainWhite: {
          white: '#ffffff',
          alabaster: '#fbfbfb',
        },
        mainBlack: {
          black: '#1C1F29',
          charcoalColor: '#454545',
          appleBtnBlack: '#000000',
        },
        mainGray: {
          gray: '#A7A7A7',
          silver: '#C2C2C2',
          whiteSmokeColor: '#F7F7F7',
          snowColor: '#F9F9F9',
          dimGrayColor: '#747474',
          whisperColor: '#ECECEC',
          echoBlue: '#BBBCBF',
          zambeziColor: '#5C5C5C',
          SuvaGrey: '#8D8D8D',
          gainsboroColor: '#E3E3E3',
          midGray: '#5b5d67',
          ironsideGray: '#65676b',
          spunPearl: '#a9a9b5',
        },
        mainRed: {
          red: 'red',
          cinnabarColor: '#EA3C24',
          mistyRose: '#FEE7E3',
        },
        mainYellow: {
          yellow: '#FF950A',
          oldLaceColor: '#FFF5E8',
          sunshade: '#FBA11F',
          orangeGradientTop: '#FFAF47',
        },
        mainBlue: {
          blue: '#1188FF',
          dodgerBlueColor: '#1877F2',
          hoverBtnBlue: '#2691fc',
          aliceBlueColor: '#E8F3FF',
          CornflowerBlue: '#46A3FF',
          dodgerBlue: '#1F8FFF',
        },
        mainOrange: {
          darkOrange: '#FF8F00',
        },
        fontFamily: {
          poppins: 'Poppins',
        },
      };

const size: {
  xs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
} = {
  xs: '320px',
  sm: '800px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
};

export const deviceMaxWidth = {
  xs: `max-width: ${size.xs}`,
  sm: `max-width: ${size.sm}`,
  md: `max-width: ${size.md}`,
  lg: `max-width: ${size.lg}`,
  xl: `max-width: ${size.xl}  `,
};

export const deviceMinWidth = {
  xs: `min-width: ${size.xs}`,
  sm: `min-width: ${size.sm}`,
  md: `min-width: ${size.md}`,
  lg: `min-width: ${size.lg}`,
  xl: `min-width: ${size.xl}`,
};
