const MailOutline = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3281 13.875H52.6719C55.4681 13.875 57.75 16.1581 57.75 18.9681V46.0319C57.75 48.8419 55.4681 51.125 52.6719 51.125H11.3281C8.53194 51.125 6.25 48.8419 6.25 46.0319V18.9681C6.25 16.1581 8.53194 13.875 11.3281 13.875Z"
        stroke="#C2C2C2"
        strokeWidth="2.5"
      />
      <path
        d="M7.75 18.4102L32.375 31.5897L57 18.4102"
        stroke="#C2C2C2"
        strokeWidth="2.31221"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default MailOutline;
