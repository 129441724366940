import React, { useEffect } from 'react';

import LoadingComponent from './LoadingComponent';
import {
  RedirectLoadingWrap,
  RedirectLoadingContainer,
  ScreenWrap,
} from './LoadingComponent.style';

export const RedirectLoading = () => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
      document.body.style.overflowX = 'hidden';
    };
  }, []);

  return (
    <ScreenWrap>
      <RedirectLoadingContainer>
        <RedirectLoadingWrap>
          <LoadingComponent fullWidth color="#C2C2C2" variant="spin" />
        </RedirectLoadingWrap>
      </RedirectLoadingContainer>
    </ScreenWrap>
  );
};
