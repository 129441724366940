import styled from 'styled-components';

export const SidebarMyGroupsWrapper = styled.div`
  margin-top: 30px;
`;
export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  & h6 {
    font-size: ${(props) => props.theme.typography.fontSize.f5};
  }
`;
export const TitleIcon = styled.span`
  display: flex;
  margin-right: 10px;
`;
export const SidebarMyGroupsList = styled.div`
  height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  border-bottom: 1px solid ${(props) => props.theme.colors.neutral.n4};
  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 100px;
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.neutral.n6};
    border-radius: 10px;
  }
`;
export const PlaceHolderWrapper = styled.div`
  width: 140px;
  margin: 0 auto;
  padding: 20px 0;

  div {
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.02em;
    color: ${(props) => props.theme.colors.neutral.n6};
  }

  img {
    background: none;
  }
`;
