import React from 'react';

export const WorldIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12"
      cy="12"
      r="9"
      stroke="#A7A7A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0004 21C14.4004 18.8182 15.6004 15.8182 15.6004 12C15.6004 8.18182 14.4004 5.18182 12.0004 3C9.60039 5.18182 8.40039 8.18182 8.40039 12C8.40039 15.8182 9.60039 18.8182 12.0004 21Z"
      stroke="#A7A7A7"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.4502 8.5498C3.03598 8.5498 2.7002 8.88559 2.7002 9.2998C2.7002 9.71402 3.03598 10.0498 3.4502 10.0498V8.5498ZM20.5502 10.0498C20.9644 10.0498 21.3002 9.71402 21.3002 9.2998C21.3002 8.88559 20.9644 8.5498 20.5502 8.5498V10.0498ZM3.4502 13.9498C3.03598 13.9498 2.7002 14.2856 2.7002 14.6998C2.7002 15.114 3.03598 15.4498 3.4502 15.4498V13.9498ZM20.5502 15.4498C20.9644 15.4498 21.3002 15.114 21.3002 14.6998C21.3002 14.2856 20.9644 13.9498 20.5502 13.9498V15.4498ZM3.4502 10.0498H20.5502V8.5498H3.4502V10.0498ZM3.4502 15.4498H20.5502V13.9498H3.4502V15.4498Z"
      fill="#A7A7A7"
    />
  </svg>
);
