import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  img?: string;
  mt?: number;
  mb?: number;
  fontSize?: number;
  color?: string;
  fontWeight?: number;
  isCoverPhotoExists?: boolean;
  width?: number;
  lastCardInLine?: number;
  isMyGroupsScreen?: boolean;
}

export const CardWrap = styled.div`
  width: 100%;
  background-color: ${style.mainWhite.white};
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const BackgroundImage = styled.span<IProps>`
  display: block;
  width: 100%;
  height: 184px;
  background: url(${({ img }) => img}) no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
`;

export const DescriptionText = styled.div`
  color: #ffffff;
  flex-wrap: wrap;
  font-family: Poppins-Medium;
  font-size: 16px;
  margin-top: 8%;
`;

export const AlertText = styled.div`
  padding: 5px;
  border-radius: 5px;
  color: #ffffff;
  flex-wrap: 'wrap';
  // font-family: Poppins-SemiBold;
  font-size: 12px;
  text-transform: 'uppercase';
  line-height: 16px;
  background-color: rgba(254, 142, 1, 0.9);
  display: inline-block;
  margin-top: 4%;
`;
