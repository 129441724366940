import _ from 'lodash';
import fetchData from 'utils/fetchData';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const getFeedFollowingPosts = (filterIds: string): Promise<any> => {
  const token = localStorage.getItem('token');
  const userId = JSON.parse(localStorage.getItem('user_id'));

  return fetchData(
    `${process.env._DB_HOST}api/v10/user/${userId}/feed?filterIds=${filterIds}`,
    {
      method: 'GET',
      headers: { ...headers, tsec: JSON.parse(token) },
    }
  ).then((response) => response.json());
};

export const getFeedDiscoverPosts = (filterIds: string): Promise<any> => {
  const token = localStorage.getItem('token');
  const userId = JSON.parse(localStorage.getItem('user_id'));
  return fetchData(
    `${process.env._DB_HOST}api/v10/user/${userId}/discover?filterIds=${filterIds}`,
    {
      method: 'get',
      headers: { ...headers, tsec: JSON.parse(token) },
    }
  ).then((response) => response.json());
};

export const getEventsPosts = (start: number) => {
  const token = localStorage.getItem('token');
  const userData = JSON.parse(localStorage.getItem('user_data'));
  const {
    data: { location },
  } = userData;
  const { lat, lng } = location;

  if (_.isNil(lat) || _.isNil(lng)) {
    return fetchData(
      `${process.env._DB_HOST}api/v02/route/events/panel?start=${start}&limit=20`,
      {
        method: 'get',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  }

  return fetchData(
    `${process.env._DB_HOST}api/v02/route/events/panel?lat=${lat}&lng=${lng}&start=${start}&limit=20`,
    {
      method: 'get',
      headers: { ...headers, tsec: JSON.parse(token) },
    }
  ).then((response) => response.json());
};
