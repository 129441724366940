import { useEffect } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Image from 'next/image';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';

import { openSideBarAddVehicle } from 'store/sideBarControler/actions';
import { routesPath } from 'utils/constants/routePath';
import {
  ArrowNext,
  ArrowPrev,
  CollaboratorImg,
  CollaboratorsSliderComponentWrap,
  GradientBlock,
  SlideComponent,
  UserInfoBlock,
  CollaboratorNameText,
  CollaboratorWrap,
  CoverImgWrap,
  EmptyCollaboratorImgWrap,
} from './ColaboratorsSlider.style';
import { EmptyUserProfilePhoto } from '../index';

// eslint-disable-next-line react/prop-types
const CustomRightArrow = ({ isMe, onClick, isGeneralInfo, ...rest }: any) => (
  <ArrowNext
    onClick={() => onClick()}
    isMe={isMe}
    isGeneralInfo={isGeneralInfo}
  >
    <>
      <svg
        width="7"
        height="10"
        viewBox="0 0 7 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.66602 9L5.66602 5L1.66602 0.999998"
          stroke="white"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  </ArrowNext>
);
// eslint-disable-next-line react/prop-types
const CustomLeftArrow = ({ onClick, isGeneralInfo, ...rest }: any) => (
  <ArrowPrev onClick={() => onClick()} isGeneralInfo={isGeneralInfo}>
    <>
      <svg
        width="7"
        height="10"
        viewBox="0 0 7 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.66602 9L5.66602 5L1.66602 0.999998"
          stroke="white"
          strokeWidth="1.4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  </ArrowPrev>
);

export interface IColaboratorsSlider {
  isMe?: boolean;
  vehicle: {
    coverPhoto: string;
    fullName: string;
    profilePhoto: string;
    username: string;
    _id: string;
  }[];
  itemHeight?: number;
  itemWidth?: number;
  itemMarginRight?: number;
  itemGradientHeight?: number;
  isGeneralInfo?: boolean;
}

export const ColaboratorsSlider = ({
  vehicle,
  isMe,
  itemHeight,
  itemWidth,
  itemMarginRight,
  isGeneralInfo,
  itemGradientHeight,
}: IColaboratorsSlider) => {
  useEffect(() => {}, [isMe, vehicle]);
  const dispatch = useDispatch();
  const router = useRouter();

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 550 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 550, min: 464 },
      items: 3.7,
    },
    mobile2: {
      breakpoint: { max: 464, min: 0 },
      items: 2.5,
    },
  };

  const nextArrow = () => {
    console.log('works');
  };
  const prevArrow = () => {
    console.log('works');
  };
  const onAddVehicle = () => {
    dispatch(openSideBarAddVehicle(''));
  };

  return (
    <CollaboratorsSliderComponentWrap>
      <Carousel
        responsive={responsive}
        customRightArrow={
          <CustomRightArrow onClick={nextArrow} isGeneralInfo={isGeneralInfo} />
        }
        customLeftArrow={
          <CustomLeftArrow onClick={prevArrow} isGeneralInfo={isGeneralInfo} />
        }
      >
        {vehicle?.length > 0 &&
          vehicle.map((el, i) => (
            <CollaboratorWrap
              key={`MyGarageComponent-${i}`}
              onClick={() =>
                router.push(`${routesPath.user_details}/${el._id}`)
              }
            >
              <CoverImgWrap
                img={
                  el?.coverPhoto.trim().length
                    ? el?.coverPhoto
                    : '/assets/img/empty_garage_slide.png'
                }
              />

              {el?.profilePhoto.trim() ? (
                <CollaboratorImg img={el.profilePhoto} />
              ) : (
                <EmptyCollaboratorImgWrap>
                  <EmptyUserProfilePhoto
                    wrapSize={36}
                    userIconSize={18}
                    withBorder
                  />
                </EmptyCollaboratorImgWrap>
              )}

              <CollaboratorNameText>{el.username}</CollaboratorNameText>
            </CollaboratorWrap>
          ))}
      </Carousel>
    </CollaboratorsSliderComponentWrap>
  );
};
