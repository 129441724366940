/* eslint-disable no-unused-expressions */
/* eslint-disable react/display-name */
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import Image from 'next/image';
import { minimalTimezoneSet } from 'node_modules/compact-timezone-list';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import moment from 'moment-timezone';

import i18n from 'utils/i18n';
import {
  CrossIcon,
  ArrowBack,
  PhoneIcon,
  MailIcon,
  WorldIcon,
  EventsIcon,
  LocationIcon,
  RoadIcon,
  CupIcon,
  FlagIcon,
  BeepIcon,
  CalendarWithStarIcon,
  StarIcon,
} from 'public/assets/icons';
import { style } from 'utils/constants/style';
import {
  closeSidebarCreateEvent,
  actionTypes,
  openSidebarTag,
  removeTag,
  scrollToTop,
  addTags,
  openSidebarChooseLocation,
} from 'store/sideBarControler/actions';
import {
  createEventStart,
  clearCreateEventReqStat,
  editEventStart,
  clearEditEventReqStat,
} from 'store/posts/actions';
import { AsyncStatus } from 'utils/types';
import { regexMap } from 'utils/constants/regex';
import { getCorrectImageBase64Format } from 'utils/getCorrectImageBase64Format';
import { validateEmail, validateUrl } from 'utils/validators';
import { routesPath } from 'utils/constants/routePath';
import {
  InputField,
  Selector,
  ButtonsGroup,
  ImagesUploader,
  CustomDatePicker,
  Pill,
  InputDescription,
} from '../../index';
import { SidebarWrapper } from '../index';
import {
  Wrapper,
  StepNumberWrap,
  StepNumber,
  SectionWrapper,
  SeparateSettingsHeader,
  SwitcherSettingWrap,
  Text,
  Select,
  SelectWrapper,
  SelectIconWrap,
  EventTypesWrap,
  EventTypesHeaderWrap,
  EventTypesHeaderText,
  EventTypesList,
  EventTypeItemWrap,
  EventTypeText,
  ErrorMessage,
  TagImageWrap,
  LocationText,
  TimeZoneText,
} from './CreateEvent.style';

const EventTypes = () => [
  {
    id: 0,
    name: i18n.t('buttonGroupLabels:meetup'),
    value: process.env._EVENT_MEETUPS,
    pinType: 'meetups',
    icon: (color) => <StarIcon color={color} />,
  },
  {
    id: 1,
    name: i18n.t('buttonGroupLabels:drive'),
    value: process.env._EVENT_DRIVES,
    pinType: 'drives',
    icon: (color) => <RoadIcon color={color} />,
  },
  {
    id: 2,
    name: i18n.t('buttonGroupLabels:race'),
    value: process.env._EVENT_RACES,
    pinType: 'races',
    icon: (color) => <CupIcon color={color} />,
  },
  {
    id: 3,
    name: i18n.t('buttonGroupLabels:trackDay'),
    value: process.env._EVENT_TRACK_DAYS,
    pinType: 'trackdays',
    icon: (color) => <FlagIcon color={color} />,
  },
  {
    id: 4,
    name: i18n.t('buttonGroupLabels:carShow'),
    value: process.env._EVENT_CARS_SHOWS,
    pinType: 'carshows',
    icon: (color) => <BeepIcon color={color} />,
  },
  {
    id: 5,
    name: i18n.t('settings:other'),
    value: process.env._EVENT_OTHERS,
    pinType: 'otros',
    icon: (color) => <CalendarWithStarIcon color={color} />,
  },
];

const CreateEvent = ({ eventForEditData }: { eventForEditData: any }) => {
  const [stepNumber, setStepNumber] = useState<number>(1);
  const [eventData, setEventData] = useState({
    coverPhoto: '',
    name: '',
    startDate: new Date(),
    endDate: new Date(),
    interests: [],
    description: '',
    phone: '',
    email: '',
    website: '',
    chat: true,
    broadcast: false,
    privacy: false,
    postPermission: false,
  });
  const [eventDefaultData, setEventDefaultData] = useState<any>({});
  const [timeZone, setTimeZone] = useState(format(new Date(), 'xxx'));
  const [showDateInfo, setShowDateInfo] = useState(false);

  const [phoneError, setPhoneError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [linkError, setLinkError] = useState('');

  const wrapperRef = useRef(null);

  const router = useRouter();

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const tagsData = useSelector((state) => state.sideBarControler.tagsData);
  const { createEventReqStat, editEventReqStat, createEventData } = useSelector(
    (state) => state.posts
  );

  const getTimezone = useMemo(() => {
    const minimalTimeOne = minimalTimezoneSet.filter(
      (d) => d?.offset === timeZone
    );
    const { offset, tzCode, label } = minimalTimeOne[0];
    const tzAbbrMoment = moment.tz(tzCode).format('z');
    const isAbbrValid = !tzAbbrMoment.match(/[+-]/);
    if (isAbbrValid) {
      return `(GMT${offset}) ${tzAbbrMoment}`;
    }
    return label;
  }, [timeZone]);

  useEffect(() => {
    if (createEventReqStat === AsyncStatus.SUCCESS) {
      dispatch(closeSidebarCreateEvent());
      dispatch(clearCreateEventReqStat());
      createEventData?._id &&
        router.push(`${routesPath.event_details}/${createEventData._id}`);
    }

    if (editEventReqStat === AsyncStatus.SUCCESS) {
      dispatch(closeSidebarCreateEvent());
      dispatch(clearEditEventReqStat());
    }

    !showDateInfo && setShowDateInfo(true);
  }, [createEventReqStat, editEventReqStat, createEventData]);

  useEffect(() => {
    if (eventForEditData) {
      const {
        coverPhoto,
        name,
        startDate,
        endDate,
        pinType,
        description,
        brands,
        collaborators,
        phone,
        email,
        website,
        isChat: chat,
        privacy,
        postPermission,
        address,
        geoLocation: { coordinates },
        broadcast,
      } = eventForEditData;
      const interests = [
        EventTypes().find((el) => el.pinType === pinType).value,
      ];

      setEventData({
        coverPhoto,
        name,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        interests,
        description,
        phone,
        email,
        website,
        chat,
        privacy: Boolean(privacy),
        postPermission: Boolean(postPermission),
        broadcast,
      });
      setEventDefaultData({
        coverPhoto,
        name,
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        interests,
        description,
        phone,
        email,
        website,
        chat,
        privacy: Boolean(privacy),
        postPermission: Boolean(postPermission),
      });

      const location = {
        lat: coordinates[1],
        lng: coordinates[0],
        name: address,
        address,
      };

      dispatch(addTags({ type: 'brands', tags: brands }));
      dispatch(addTags({ type: 'collaborators', tags: collaborators }));
      dispatch(addTags({ type: 'location', tags: location }));
    }
  }, eventForEditData);

  const onImageAdd = (img: string) => {
    setEventData({ ...eventData, coverPhoto: img });
  };

  const onSubmit = () => {
    if (stepNumber === 1) {
      dispatch(scrollToTop());
      setStepNumber(stepNumber + 1);
    } else {
      const getIdsArray = (array) => array.map((elem) => elem._id);
      let isErrorsOccurred = false;

      if (eventData.email.trim().length) {
        const validationResult = validateEmail(eventData.email || null);
        const error = validationResult;

        if (error) {
          setEmailError(validationResult.email[0]);
          isErrorsOccurred = true;
        }
      }

      if (eventData.website.trim().length) {
        const validationResult = validateUrl(eventData.website || null);
        const error = validationResult;

        if (error) {
          setLinkError(error.url[0]);
          isErrorsOccurred = true;
        }
      }

      if (
        eventData.phone.trim().length &&
        !regexMap.phone.test(eventData.phone)
      ) {
        setPhoneError(t('auth:phoneIncorrect'));

        isErrorsOccurred = true;
      }

      if (isErrorsOccurred) {
        return;
      }

      const defaultLocation = {
        lat: undefined,
        lng: undefined,
        name: '',
      };

      const data = {
        ...eventData,
        startDate: eventData.startDate.getTime(),
        endDate: eventData.endDate.getTime(),
        privacy: eventData.privacy ? 1 : 0,
        postPermission: eventData.postPermission ? 1 : 0,
        collaborators:
          (tagsData.collaborators && getIdsArray(tagsData.collaborators)) || [],
        brands: (tagsData.brands && getIdsArray(tagsData.brands)) || [],
        location: tagsData.location || defaultLocation,
        coverPhoto: getCorrectImageBase64Format(eventData.coverPhoto),
      };

      if (eventForEditData) {
        dispatch(
          editEventStart({ eventId: eventForEditData._id, eventData: data })
        );
      } else {
        dispatch(createEventStart({ eventData: data }));
      }
    }
  };

  const onBlur = (type: string, value: string) => {
    if (type === 'phone') {
      if (eventData.phone.length && !regexMap.phone.test(value)) {
        setPhoneError(t('auth:phoneIncorrect'));
      }
    }

    if (type === 'email') {
      const validationResult = validateEmail(value || null);
      const error = validationResult;

      error && setEmailError(validationResult.email[0]);
    }

    if (type === 'website') {
      const validationResult = validateUrl(value || null);
      const error = validationResult;

      error && setLinkError(error.url[0]);
    }
  };

  const eventsTypesList = EventTypes().map((event) => (
    <EventTypeItemWrap
      selected={event.value === eventData.interests[0]}
      key={event.id}
      onClick={() => setEventData({ ...eventData, interests: [event.value] })}
    >
      {event.icon(
        event.value === eventData.interests[0] ? '#FBFBFB' : '#A9A9B5'
      )}
      <EventTypeText selected={event.value === eventData.interests[0]}>
        {event.name}
      </EventTypeText>
    </EventTypeItemWrap>
  ));

  const tagElem = (id: string, title: string, type: string) => (
    <Pill
      text={title}
      onClick={() => dispatch(removeTag({ id, type }))}
      iconRight={<CrossIcon width="10" height="10" />}
      fontSize={12}
      margin="0 10px 8px 0"
      key={id}
    />
  );

  const tagsList = (selectedTags, type) =>
    selectedTags.map((tag) => {
      if (type === 'brands') {
        return tagElem(tag._id, tag.brand, type);
      }

      if (type === 'collaborators') {
        return (
          <Pill
            text={tag.username}
            onClick={() => dispatch(removeTag({ id: tag._id, type }))}
            iconRight={<CrossIcon width="10" height="10" />}
            fontSize={12}
            margin="0 10px 8px 0"
            key={tag._id}
            iconLeft={
              <TagImageWrap>
                <Image
                  width={26}
                  height={26}
                  src={
                    tag.profilePhoto.trim().length
                      ? tag.profilePhoto
                      : '/assets/img/emptyUserPhoto.png'
                  }
                  alt="tag"
                  unoptimized
                  priority
                />
              </TagImageWrap>
            }
          />
        );
      }

      return null;
    });

  const firstStep = (
    <>
      <SectionWrapper pt={20} pb={17.5}>
        <ImagesUploader
          coverPhoto={eventData.coverPhoto}
          onProfileImagesChange={onImageAdd}
          onRemovePhotoClick={() =>
            setEventData({ ...eventData, coverPhoto: '' })
          }
          label={t('event:addACoverPhotoForYourEvent')}
          height={240}
          cropAspect={3 / 2}
          isProfileImages
          isOnePhoto
          autoRatio
          isAspect
        />

        <SeparateSettingsHeader mt={20} mb={20}>
          {t('event:mainInformation')}
        </SeparateSettingsHeader>

        <InputField
          value={eventData.name}
          onChange={(e) => setEventData({ ...eventData, name: e.target.value })}
          label={t('event:eventName')}
          crossIcon
          onCrossClick={() => setEventData({ ...eventData, name: '' })}
          maxLength={50}
        />
      </SectionWrapper>

      {showDateInfo && (
        <SectionWrapper pt={16.5} pb={16.5}>
          <SeparateSettingsHeader mb={4}>
            {t('event:eventStart')}
          </SeparateSettingsHeader>
          <CustomDatePicker
            selectedDate={eventData.startDate}
            onChange={(date) => setEventData({ ...eventData, startDate: date })}
            minDate={new Date()}
          />

          <SeparateSettingsHeader mb={4} mt={16}>
            {t('event:eventEnd')}
          </SeparateSettingsHeader>
          <CustomDatePicker
            selectedDate={
              eventData.endDate < eventData.startDate
                ? eventData.startDate
                : eventData.endDate
            }
            onChange={(date) => setEventData({ ...eventData, endDate: date })}
            minDate={eventData.startDate}
          />

          <SeparateSettingsHeader mb={4} mt={16}>
            {t('timePicker:timeZone')}
          </SeparateSettingsHeader>
          <SelectWrapper>
            <SelectIconWrap>
              <WorldIcon />
            </SelectIconWrap>
            <TimeZoneText>{getTimezone}</TimeZoneText>
            <Select
              onChange={(e: any) => setTimeZone(e.target.value)}
              value={timeZone}
            >
              {minimalTimezoneSet.map((el, index) => (
                <option value={el.offset} key={index}>
                  {el.label}
                </option>
              ))}
            </Select>
          </SelectWrapper>
        </SectionWrapper>
      )}

      <SectionWrapper pt={22.5}>
        <EventTypesWrap>
          <EventTypesHeaderWrap>
            <EventsIcon />
            <EventTypesHeaderText>{t('event:eventType')}</EventTypesHeaderText>
          </EventTypesHeaderWrap>
          <EventTypesList>{eventsTypesList}</EventTypesList>
        </EventTypesWrap>

        <Selector
          title={t('event:location')}
          isSelectedItemsExist={tagsData.location}
          onClick={() => dispatch(openSidebarChooseLocation())}
          leftIcon={<LocationIcon />}
        />
        {tagsData.location && (
          <LocationText>{tagsData.location.address}</LocationText>
        )}
      </SectionWrapper>
    </>
  );

  const secondStep = (
    <>
      <SectionWrapper pt={20} pb={17.5}>
        {/* <InputField
          type="textarea"
          value={eventData.description}
          onChange={(e) =>
            setEventData({ ...eventData, description: e.target.value })
          }
          label={t('event:eventDetails')}
          placeholder={t('event:addEventDetails')}
          crossIcon
          onCrossClick={() => setEventData({ ...eventData, description: '' })}
          showCharactersNumber
          maxLength={3000}
          height={88}
        /> */}
        <InputDescription
          defaultValue={eventData.description}
          onChange={(e) => setEventData({ ...eventData, description: e })}
          label={t('event:eventDetails')}
          placeholder={t('event:addEventDetails')}
          // crossIcon
          // onCrossClick={() => setEventData({ ...eventData, description: '' })}
          // showCharactersNumber
          maxLength={3000}
          height={122}
        />

        <Selector
          title={t('event:eventBrands')}
          isSelectedItemsExist={tagsData.brands && !!tagsData.brands.length}
          onClick={() =>
            dispatch(
              openSidebarTag({
                actionType: actionTypes.OPEN_SIDEBAR_TAG,
                tagType: 'brands',
              })
            )
          }
          margin={`24px 0 ${
            tagsData.brands && !!tagsData.brands.length ? '0' : '28px'
          } 0`}
        />
        {tagsData.brands &&
          !!tagsData.brands.length &&
          tagsList(tagsData.brands, 'brands')}
        <Selector
          title={t('editUserProfile:myCollaborators')}
          isSelectedItemsExist={
            tagsData.collaborators && !!tagsData.collaborators.length
          }
          onClick={() =>
            dispatch(
              openSidebarTag({
                actionType: actionTypes.OPEN_SIDEBAR_TAG,
                tagType: 'collaborators',
              })
            )
          }
        />
        {tagsData.collaborators &&
          !!tagsData.collaborators.length &&
          tagsList(tagsData.collaborators, 'collaborators')}

        <InputField
          value={eventData.phone}
          onChange={(e) => {
            setEventData({ ...eventData, phone: e.target.value });
            phoneError && setPhoneError('');
          }}
          label={t('event:contactPhone')}
          crossIcon
          onCrossClick={() => {
            setEventData({ ...eventData, phone: '' });
            phoneError && setPhoneError('');
          }}
          iconLeft={<PhoneIcon />}
          margin="22px 0 17px 0"
          onBlur={(e) => onBlur('phone', e.target.value)}
        />
        {phoneError && (
          <ErrorMessage mt={-11} mb={17}>
            {phoneError}
          </ErrorMessage>
        )}

        <InputField
          value={eventData.email}
          onChange={(e) => {
            setEventData({ ...eventData, email: e.target.value });
            emailError && setEmailError('');
          }}
          label={t('event:contactEmail')}
          crossIcon
          onCrossClick={() => {
            setEventData({ ...eventData, email: '' });
            emailError && setEmailError('');
          }}
          iconLeft={<MailIcon />}
          margin="0 0 17px 0"
          onBlur={(e) => onBlur('email', e.target.value)}
        />
        {emailError && (
          <ErrorMessage mt={-11} mb={17}>
            {emailError}
          </ErrorMessage>
        )}

        <InputField
          value={eventData.website}
          onChange={(e) => {
            setEventData({ ...eventData, website: e.target.value });
            linkError && setLinkError('');
          }}
          label={t('event:eventWebpageOrRouteLink')}
          crossIcon
          onCrossClick={() => {
            setEventData({ ...eventData, website: '' });
            linkError && setLinkError('');
          }}
          iconLeft={<WorldIcon />}
          onBlur={(e) => onBlur('website', e.target.value)}
        />
        {linkError && <ErrorMessage mt={6}>{linkError}</ErrorMessage>}
      </SectionWrapper>
      <SectionWrapper pt={16.5}>
        <SwitcherSettingWrap>
          <SeparateSettingsHeader fw={500} mb={6}>
            {t('event:eventChat')}
          </SeparateSettingsHeader>
          <ButtonsGroup
            itemList={[
              { id: 0, name: 'On' },
              { id: 1, name: 'Off' },
            ]}
            isSelectedId={eventData.chat ? 0 : 1}
            onClick={(e) => setEventData({ ...eventData, chat: !e })}
            inactiveFieldsBackgroundColor={style.mainGray.whiteSmokeColor}
          />
          <Text mt={6}>
            {eventData.chat ? t('event:eventChatOn') : t('event:eventChatOff')}
          </Text>
        </SwitcherSettingWrap>

        <SwitcherSettingWrap>
          <SeparateSettingsHeader fw={500} mb={6}>
            {t('event:broadCastMode')}
          </SeparateSettingsHeader>
          <ButtonsGroup
            itemList={[
              { id: 0, name: 'On' },
              { id: 1, name: 'Off' },
            ]}
            isSelectedId={eventData.broadcast ? 0 : 1}
            onClick={(e) => setEventData({ ...eventData, chat: !e })}
            inactiveFieldsBackgroundColor={style.mainGray.whiteSmokeColor}
          />
          <Text mt={6}>
            {eventData.chat
              ? t('event:eventChatBroadcastOn')
              : t('event:broadCastModeOff')}
          </Text>
        </SwitcherSettingWrap>

        <SwitcherSettingWrap mt={16} mb={16}>
          <SeparateSettingsHeader fw={500} mb={6}>
            {t('event:eventPrivacy')}
          </SeparateSettingsHeader>
          <ButtonsGroup
            itemList={[
              { id: 0, name: t('map:public') },
              { id: 1, name: t('map:private') },
            ]}
            isSelectedId={eventData.privacy ? 1 : 0}
            onClick={(e) => setEventData({ ...eventData, privacy: Boolean(e) })}
            inactiveFieldsBackgroundColor={style.mainGray.whiteSmokeColor}
          />
          <Text mt={6}>
            {eventData.privacy
              ? t('event:eventChatOn')
              : t('event:privateEventOff')}
          </Text>
        </SwitcherSettingWrap>

        <SwitcherSettingWrap>
          <SeparateSettingsHeader fw={500} mb={6}>
            {t('addNewGroup:postPermissions')}
          </SeparateSettingsHeader>
          <ButtonsGroup
            itemList={[
              { id: 0, name: t('event:postPermissionOpen') },
              { id: 1, name: t('event:postPermissionOpenApproval') },
            ]}
            isSelectedId={eventData.postPermission ? 1 : 0}
            onClick={(e) =>
              setEventData({
                ...eventData,
                postPermission: Boolean(e),
              })
            }
            inactiveFieldsBackgroundColor={style.mainGray.whiteSmokeColor}
          />
          <Text mt={6}>
            {eventData.postPermission
              ? t('event:postPermissionOpenApprovalDescription')
              : t('event:postPermissionOpenDescription')}
          </Text>
        </SwitcherSettingWrap>
      </SectionWrapper>
    </>
  );

  const getSubmitAvailability = () => {
    let submit = true;

    if (eventForEditData) {
      const { brands, collaborators, address } = eventForEditData;
      const isTagsDataChanged =
        JSON.stringify([brands, collaborators, address]) !==
        JSON.stringify([
          tagsData.brands,
          tagsData.collaborators,
          tagsData.location?.address,
        ]);

      if (
        JSON.stringify(eventData) === JSON.stringify(eventDefaultData) &&
        !isTagsDataChanged &&
        stepNumber !== 1
      ) {
        submit = false;
      }
    }

    if (
      !eventData.coverPhoto.length ||
      !eventData.name.length ||
      !eventData.interests.length ||
      !tagsData.location ||
      phoneError ||
      emailError ||
      linkError
    ) {
      submit = false;
    }

    return submit;
  };

  const headerTitle =
    eventForEditData === null ? t('event:createEvent') : t('event:editEvent');

  return (
    <SidebarWrapper
      headerText={stepNumber === 1 ? headerTitle : t('event:optionalInfo')}
      headerIcon={
        stepNumber === 1 ? (
          <CrossIcon color={style.mainBlack.black} />
        ) : (
          <ArrowBack />
        )
      }
      submitText={
        stepNumber === 1 ? t('common:nextText') : t('editUserProfile:save')
      }
      isSubmitAvailable={getSubmitAvailability()}
      onSubmit={onSubmit}
      onIconClick={() => {
        if (stepNumber === 1) {
          dispatch(closeSidebarCreateEvent());
        } else {
          dispatch(scrollToTop());
          setStepNumber(stepNumber - 1);
        }
      }}
      headerBorder={false}
      contentPadding="0 20px 20px 20px"
    >
      <Wrapper ref={wrapperRef}>
        <StepNumberWrap>
          <StepNumber mr={8} isSelectedStep={stepNumber === 1} />
          <StepNumber isSelectedStep={stepNumber !== 1} />
        </StepNumberWrap>
        {stepNumber === 1 ? firstStep : secondStep}
      </Wrapper>
    </SidebarWrapper>
  );
};

export default CreateEvent;
