import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
  lh?: number;
  pb?: number;
  pt?: number;
  isNewMessage?: boolean;
  width?: number;
  height?: number;
  backgroundColor?: string;
  img?: string;
  isCutting?: boolean;
}
export const InputWrapper = styled.div`
  padding-top: 20px;
  margin: 0 auto;
  width: 86%;
`;

export const EventUserItemWrap = styled.div`
  width: 100%;
  height: 80px;
  border-bottom: 1px solid ${style.mainGray.whiteSmokeColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const UserNameEvent = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: ${style.mainBlack.black};
`;

export const ImageWrap = styled.div`
  border-radius: 50%;
  width: 48px;
  height: 48px;
  position: absolute;
  overflow: hidden;
  img {
    object-fit: cover;
  }
`;
export const PlaceholderWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;
`;
export const Text = styled.p<IProps>`
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.02em;
  color: ${style.mainGray.silver};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
`;

export const ButtonWrap = styled.div`
  width: 93px;
  position: relative;
`;

export const EmptyEventImg = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: #e8f3ff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EventNameWrap = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  left: 60px;
  span {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0.02em;
    color: ${style.mainGray.SuvaGrey};
  }
`;
