import { INavigationButton } from './INavigationButton';

import {
  NavigationButtonWrapper,
  IconWrap,
  TextWrap,
} from './NavigationButton.styles';

const NavigationButton = ({
  icon,
  text,
  active,
  onClick,
}: INavigationButton) => {
  return (
    <NavigationButtonWrapper onClick={onClick} active={active}>
      <IconWrap active={active}>{icon}</IconWrap>
      <TextWrap>{text}</TextWrap>
    </NavigationButtonWrapper>
  );
};

export default NavigationButton;
