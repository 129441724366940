import styled from 'styled-components';

import Text from 'components/common/Text';

interface IProps {
  height?: number | string;
  backgroundColor?: string;
  wrapperHeight?: string;
  mb?: number;
  mt?: number;
  fullWidth?: boolean;
  objectFit?: string;
  rounded?: boolean;
  isPhotoPost?: boolean;
  isFilter?: boolean;
  isSmallImages?: boolean;
  hasError?: boolean;
}

export const ImageUploaderWrapper = styled.div<IProps>`
  position: relative;
  width: 100%;
  height: ${({ wrapperHeight }) => wrapperHeight || '160px'};
  max-height: ${({ height }) => (height ? `${height}px` : '360px')};
  border-radius: 6px;
  display: flex;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.colors.neutral.n0};
  justify-content: center;
  border: ${({ hasError, theme, backgroundColor }) =>
    hasError
      ? `1px solid ${theme.colors.red}`
      : `1px solid ${backgroundColor || theme.colors.neutral.n0}`};
`;

export const PickerIcon = styled.div<IProps>`
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '6px')};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & > svg > path {
    fill: ${({ hasError, theme }) =>
      hasError ? theme.colors.red : theme.colors.blue};
  }
  @media only screen and (max-width: 800px) {
    ${({ isSmallImages }) => {
      if (isSmallImages) {
        return `
          position: absolute;
          top: 50%;
          margin-top: -12px;
        `;
      }

      return `
        position: absolute;
        top: 0
      `;
    }}
  }
`;

export const PickerLabel = styled(Text)<IProps>`
  width: 100%;
  max-width: 138px;
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  font-size: ${(props) => props.theme.typography.fontSize.f4};
  line-height: 21px;
  text-align: center;
  color: ${({ hasError, theme }) =>
    hasError ? theme.colors.red : theme.colors.neutral.n7};
`;

export const FileInput = styled.input`
  width: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  z-index: 1;
`;

export const UploadedImage = styled.img<IProps>`
  width: auto;
  object-fit: ${({ objectFit }) => objectFit || 'cover'};
  border-radius: ${({ rounded }) => (rounded ? '50%' : '6px')};
  max-width: 100%;
`;

export const SmallImageContainerWrap = styled.div<IProps>`
  display: block;
  position: relative;
  width: 64px;
  height: 64px;
  border-radius: 8px;
  display: flex;
  background-color: ${(props) => props.theme.colors.neutral.n0};
  justify-content: center;
  text-align: center;
  margin-top: 8px;
  margin-right: 10px;
  filter: ${({ isFilter }) =>
    isFilter ? 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.06))' : 'none'};

  :last-of-type {
    margin-right: 0;
  }

  @media only screen and (max-width: 800px) {
    width: 19%;
    height: 0;
    margin-right: 1.25%;
    padding-top: 19%;
  }
`;
