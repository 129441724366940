/* eslint-disable react/prop-types */
import React from 'react';
import { style } from '../../../utils/constants/style';

export const MessageIcon = ({
  colorStroke = '#1C1F29',
}: {
  colorStroke?: string | undefined;
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 3C6.48602 3 2.00003 6.86348 2.00003 11.6124C2.00003 13.2727 2.54903 14.8807 3.58936 16.2708C3.39236 18.356 2.86436 19.904 2.09769 20.6373C1.99636 20.7343 1.97103 20.8826 2.03503 21.0051C2.09203 21.1145 2.20836 21.1818 2.33336 21.1818C2.34869 21.1818 2.36436 21.1809 2.38003 21.1786C2.51503 21.1604 5.65102 20.7282 7.91902 19.4759C9.20735 19.9732 10.5793 20.2249 12 20.2249C17.514 20.2249 22 16.3614 22 11.6124C22 6.86348 17.514 3 12 3Z"
      stroke={colorStroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
