import { Container, Row } from 'components/common/Layout';
import { useState, useCallback, useEffect } from 'react';

import { IScrollToLoad } from './IScrollToLoad';
import { InfiniteScrollWrapper } from './ScrollToLoad.styles';

const ScrollToLoad = ({
  render,
  totalData,
  data = [],
  loader,
  fetchMoreData,
  pageSize,
  style,
  scrollableTarget,
  isResetOffset,
  isMore = true,
  needContainer = false,
  fr,
}: IScrollToLoad) => {
  const [offset, setOffset] = useState(0);
  const loadMoreData = () => {
    fetchMoreData(offset + pageSize);
    setOffset(offset + pageSize);
  };

  const resetOffset = useCallback(() => {
    if (isResetOffset) {
      setOffset(0);
    }
  }, [isResetOffset]);

  useEffect(() => {
    resetOffset();
  }, [resetOffset]);

  return (
    <InfiniteScrollWrapper
      dataLength={data?.length}
      next={loadMoreData}
      hasMore={totalData > data?.length}
      loader={isMore ? loader : null}
      style={style}
      scrollableTarget={scrollableTarget}
    >
      {needContainer ? (
        <Container>
          <Row fr={fr}>
            {data?.length > 0 && data.map((item, i) => render(item, i))}
          </Row>
        </Container>
      ) : (
        data?.length > 0 && data.map((item, i) => render(item, i))
      )}
    </InfiniteScrollWrapper>
  );
};

export default ScrollToLoad;
