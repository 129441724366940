import React from 'react';

export const TickIcon = ({ color = '#ffffff' }: { color?: string }) => (
  <svg
    width="10"
    height="8"
    viewBox="0 0 7 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.98018 3.06143L5.7383 0.303319C6.02684 0.0147791 6.49465 0.0147791 6.78319 0.303319C7.04954 0.569664 7.07003 0.988764 6.84466 1.27861L6.78319 1.34821L3.50263 4.62878C3.23629 4.89512 2.81719 4.91561 2.52734 4.69024L2.45774 4.62878L0.842382 3.01342C0.553842 2.72488 0.553842 2.25707 0.842382 1.96853C1.10873 1.70218 1.52783 1.68169 1.81767 1.90706L1.88728 1.96853L2.98018 3.06143L5.7383 0.303319L2.98018 3.06143Z"
      fill={color}
    />
  </svg>
);
