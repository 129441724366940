import Skeleton from 'components/common/Skeleton';
import {
  ProfileCardSkeletonContent,
  ProfileCardSkeletonWrapper,
  ProfileCardSkeletonFooter,
  ProfileCardSkeletonContentLeft,
  ProfileCardSkeletonContentRight,
} from './ProfileCardSkeleton.styles';

const ProfileCardSkeleton = () => {
  return (
    <ProfileCardSkeletonWrapper>
      <Skeleton height={366} bottom={0} />
      <ProfileCardSkeletonContent>
        <ProfileCardSkeletonContentLeft>
          <Skeleton circle width="84px" borderRadius={100} bottom={0} />
        </ProfileCardSkeletonContentLeft>
        <ProfileCardSkeletonContentRight>
          <Skeleton height={10} width="30%" bottom={9} borderRadius={2} />
          <Skeleton height={12} width="50%" bottom={9} borderRadius={2} />
          <Skeleton height={14} width="60%" bottom={0} borderRadius={2} />
        </ProfileCardSkeletonContentRight>
      </ProfileCardSkeletonContent>

      <ProfileCardSkeletonFooter>
        <Skeleton height={40} width="24%" bottom={0} borderRadius={4} />
        <Skeleton height={40} width="10%" bottom={0} borderRadius={4} />
      </ProfileCardSkeletonFooter>
    </ProfileCardSkeletonWrapper>
  );
};

export default ProfileCardSkeleton;
