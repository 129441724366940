/* eslint-disable no-use-before-define */
import { io } from 'socket.io-client';

import { SOCKET_EVENTS } from './constants/socketEvents';

const Pages = {
  chatsList: 'chatList',
  chat: 'chat',
};

let socket;
let onNewMessage = (callback) => {};
let currentScreen = Pages.chatsList;

const setOnNewMessage = (callback) => (onNewMessage = callback);
const setCurrentChatScreen = (screen) => {
  currentScreen = screen;
};

const connect = (token) => {
  socket = io(`${process.env._CHAT_BASE_API_URL}/?tsec=${token}`);
  socket.connect();
};

const setNewMessageListener = () => {
  socket.on(SOCKET_EVENTS.NEW_MESSAGE_EVENT, (args) => {
    // Display new message at the user screen
    showNewChatMessage(args);
    // Call isRead or isNotified based on the screen user is currently at
    callbackNewChatMessage(args);
  });
};

const showNewChatMessage = (chatMessage) => {
  onNewMessage(chatMessage);
};

const callbackNewChatMessage = (args) => {
  if (args != null && args !== undefined) {
    const chatId = args.to;
    if (currentScreen === Pages.chatsList) {
      isNotified(chatId);
    } else {
      isRead(chatId);
    }
  }
};

const isNotified = (chatId) => {
  socket.emit(SOCKET_EVENTS.IS_NOTIFIED, { chatId }, (data) => {
    console.log('isNotified data', data);
  });
};

const isRead = (chatId) => {
  socket.emit(SOCKET_EVENTS.IS_READ, { chatId }, (data) => {
    console.log('isRead data', data);
  });
};

const joinRoom = ({ chatId }) => {
  socket.emit(SOCKET_EVENTS.JOIN_ROOM_EVENT, { chatId }, () => {});
};

const isNotConnected = () => socket.disconnected;

const sendMessage = ({ chatId, message }, callback) => {
  socket.emit(SOCKET_EVENTS.SEND_MESSAGE_EVENT, { chatId, message }, (data) => {
    callback(data);
  });
};

const uploadMedia = ({ chatId, mediaForUpload }, callback) => {
  socket.emit(
    SOCKET_EVENTS.UPLOAD_MEDIA,
    { chatId, media: mediaForUpload },
    (data) => {
      callback(data);
    }
  );
};

const sendMedia = ({ chatId, messageId, imageUrl, mediaForSend }) => {
  const mm = mediaForSend;
  mm.url = imageUrl;
  socket.emit(
    SOCKET_EVENTS.SEND_MEDIA,
    { chatId, messageId, media: mm },
    () => {}
  );
};

const unsendMessage = ({ chatId, messageId }, callback) => {
  socket.emit(SOCKET_EVENTS.UNSEND, { chatId, messageId }, (data) => {
    callback(data);
  });
};

const leaveRoom = ({ chatId }) => {
  socket.emit(SOCKET_EVENTS.LEFT_ROOM_EVENT, { chatId }, () => {});
};

const deleteChat = ({ chatId }, callback) => {
  socket.emit(SOCKET_EVENTS.LEFT_ROOM_EVENT, { chatId }, (data) => {
    if (data.ok) {
      callback();
    }
  });
};

const deleteMessage = ({ chatId, messageId }, callback) => {
  socket.emit(SOCKET_EVENTS.UNSEND, { chatId, messageId }, (data) => {
    if (data.ok) {
      callback();
    }
  });
};

const disconnect = () => {
  socket.disconnect();
};

export {
  connect,
  isNotConnected,
  joinRoom,
  setNewMessageListener,
  leaveRoom,
  sendMessage,
  uploadMedia,
  sendMedia,
  unsendMessage,
  setOnNewMessage,
  disconnect,
  deleteChat,
  deleteMessage,
  setCurrentChatScreen,
};
