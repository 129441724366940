import React from 'react';

export const PlayButton = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.6" cx="12" cy="12" r="12" fill="#5C5C5C" />
    <path
      d="M17.4002 10.9606C18.2002 11.4225 18.2002 12.5772 17.4002 13.039L10.2002 17.196C9.4002 17.6578 8.4002 17.0805 8.4002 16.1567L8.4002 7.84288C8.4002 6.91912 9.4002 6.34177 10.2002 6.80365L17.4002 10.9606Z"
      fill="url(#paint0_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="19.2002"
        y1="11.9998"
        x2="4.8002"
        y2="11.9998"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#F3F7F8" />
      </linearGradient>
    </defs>
  </svg>
);
