import fetchData from 'utils/fetchData';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const getUserNotifications = ({ limit, start, pending }) => {
  try {
    const token = localStorage.getItem('token');
    const userId = JSON.parse(localStorage.getItem('user_id'));

    return fetchData(
      `${process.env._DB_HOST}api/v01/user/${userId}/notifications?limit=${limit}&start=${start}&pending=${pending}`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const acceptPendingRequest = ({
  notificationId,
  notificationType,
  body,
}) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOST}api/v01/user/notification/${notificationId}/${notificationType}`,
      {
        method: 'PUT',
        headers: { ...headers, tsec: JSON.parse(token) },
        body: JSON.stringify(body),
      }
    ).then((response) =>
      response.status === 204 ? response : response.json()
    );
  } catch (error) {
    console.log(error);
  }
};

export const declinePendingRequest = ({
  notificationId,
  notificationType,
  body,
}) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOST}api/v01/user/notification/${notificationId}/${notificationType}`,
      {
        method: 'DELETE',
        headers: { ...headers, tsec: JSON.parse(token) },
        body: JSON.stringify({ data: body }),
      }
    ).then((response) =>
      response.status === 204 ? response : response.json()
    );
  } catch (error) {
    console.log(error);
  }
};
