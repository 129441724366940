import React from 'react';

export const LinkIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.05723 9.25627C5.93763 9.90267 5.37783 10.2259 5.37783 10.2259C3.05907 11.5646 2.2646 14.5296 3.60334 16.8484C4.94208 19.1671 7.90708 19.9616 10.2258 18.6229C10.2258 18.6229 10.7856 18.2997 11.9052 17.6533"
      stroke="#8D8D8D"
      strokeWidth="1.6"
      strokeLinecap="round"
    />
    <path
      d="M8.64279 13.9394L15.3604 10.061"
      stroke="#8D8D8D"
      strokeWidth="1.6"
      strokeLinecap="round"
    />
    <path
      d="M16.9437 14.7447C18.0633 14.0983 18.6231 13.7751 18.6231 13.7751C20.9419 12.4364 21.7364 9.47137 20.3976 7.1526C19.0589 4.83383 16.0939 4.03937 13.7751 5.37811C13.7751 5.37811 13.2153 5.70131 12.0957 6.34771"
      stroke="#8D8D8D"
      strokeWidth="1.6"
      strokeLinecap="round"
    />
  </svg>
);
