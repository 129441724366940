import React, { ReactNode } from 'react';

import { Wrap } from './SidebarWrap.style';

const SidebarWrap = ({
  children,
  isFullMobileHeight,
  isTop = true,
}: {
  children: ReactNode;
  isFullMobileHeight?: boolean;
  isTop?: boolean;
}) => (
  <Wrap isTop={isTop} isFullMobileHeight={isFullMobileHeight}>
    {children}
  </Wrap>
);

export default SidebarWrap;
