import { useSelector } from 'react-redux';

import RequestAction from 'components/RequestAction';
import Drawer from 'components/Drawer';
import { APP_STATE } from 'types';
import appState from 'hooks/appState';
import API_NAME from 'services/api';
import fetchApi from 'hooks/fetchApi';
import SelectedVehicleForm from 'forms/SelectedVehicleForm';
import { IAddVehicleGroupDrawer } from './IAddVehicleGroupDrawer';
import SelectVehicle from './SelectVehicle';

const AddVehicleGroupDrawer = ({ open, groupId }: IAddVehicleGroupDrawer) => {
  const getVehicles = useSelector((store) => store.fetchData);

  const appStateAction = appState();
  const { action, loading } = fetchApi();

  const onAddVehicle = async (e) => {
    try {
      await action(
        API_NAME.GROUP_ATTEND_VEHICLE,
        {
          params: {
            groupId,
          },
          body: { vehicles: e?.vehicle?.map((v) => v?._id) },
        },
        true,
        false,
        [],
        false
      );
      action(API_NAME.GET_GROUP_VEHICLES, {
        params: {
          id: groupId,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Drawer
      open={open}
      onDrawerClose={() =>
        appStateAction(APP_STATE.ADD_VEHICLE_DRAWER, {
          isOpen: false,
        })
      }
      rootForm
    >
      <RequestAction
        apiName={API_NAME.GROUP_ATTEND_VEHICLE}
        onComplete={() => {
          appStateAction(APP_STATE.ADD_VEHICLE_DRAWER, {
            isOpen: false,
          });
        }}
      >
        <SelectedVehicleForm
          onSubmit={onAddVehicle}
          onIconClick={() =>
            appStateAction(APP_STATE.ADD_VEHICLE_DRAWER, {
              isOpen: false,
            })
          }
        >
          <SelectVehicle />
        </SelectedVehicleForm>
      </RequestAction>
    </Drawer>
  );
};

export default AddVehicleGroupDrawer;
