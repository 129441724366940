import styled from 'styled-components';

import { style } from 'utils/constants/style';

export const PointNumberWrap = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${style.mainBlue.blue};
`;

export const PointNumber = styled.p`
  font-size: 12px;
  line-height: 100%;
  font-weight: 700;
  color: ${style.mainWhite.white};
`;
