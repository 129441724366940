import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  img?: string;
  isCoverPhotoExists?: boolean;
  lastCardInLine?: number;
  mr?: number;
  mobilePercentageWidth?: number;
}

export const CardWrap = styled.div<IProps>`
  width: 100%;
  border-radius: 8px;
  background-color: ${style.mainWhite.white};
  overflow: hidden;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
`;

export const BackgroundImage = styled.div<IProps>`
  width: 100%;
  height: 106px;
  background: url(${({ img }) => img}), url(/assets/img/tireTrace.png);
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  cursor: pointer;
`;

export const ProfilePhotoWrap = styled.div`
  position: relative;
  top: 6px;
`;

export const UserInfoWrap = styled.div`
  background-color: ${style.mainWhite.white};
  width: 100%;
  box-sizing: border-box;
  padding: 12px 16px 8px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

export const UserNickname = styled.p`
  font-size: 14px;
  width: 100%;
  text-align: center;
  line-height: 120%;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const UserFullName = styled(UserNickname)`
  margin-top: 4px;
  font-size: 12px;
  color: ${style.mainGray.SuvaGrey};
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding-bottom: 8px;
`;

export const FollowersNumberText = styled(UserNickname)`
  margin-top: 8px;
  font-size: 13px;
  color: ${style.mainGray.SuvaGrey};
  margin-bottom: 0;
  text-transform: lowercase;
`;

export const UserNameWrap = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
