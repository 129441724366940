import React from 'react';
import NextLink from 'next/link';

import { ILink } from './ILink';

import { LinkContainer } from './Link.styles';

const Link = ({ path, target, children, className }: ILink) => {
  return (
    <NextLink href={path}>
      <LinkContainer href={path} target={target} className={className}>
        {children}
      </LinkContainer>
    </NextLink>
  );
};

export default Link;
