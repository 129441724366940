import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  checked: boolean;
}

export const GroupCardWrap = styled.div`
  width: 100%;
  height: 80px;
  border-bottom: 1px solid ${style.mainGray.whiteSmokeColor};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const UserNameGroup = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: ${style.mainBlack.black};
`;

export const CarsWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  @media only screen and (max-width: 800px) {
    justify-content: center;
  }
`;

export const CarImgWrap = styled.div<IProps>`
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 12px;
  width: 172px;
  height: 120px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  border: ${({ checked }) => (checked ? '3px solid #f18c09' : 'none')};

  :nth-of-type(odd) {
    margin-right: 16px;
  }

  @media only screen and (max-width: 800px) {
    margin-right: 16px;
  }

  @media only screen and (max-width: 590px) {
    margin-right: 0;

    :nth-of-type(odd) {
      margin-right: 16px;
    }
  }

  @media only screen and (max-width: 410px) {
    margin-right: 0;
    width: 100%;
    height: 150px;

    :nth-of-type(odd) {
      margin-right: 0;
    }
  }
`;

export const GradientWrap = styled.div`
  position: relative;

  div {
    width: 100%;
    height: 45px;
    top: -52px;
    position: absolute;
    z-index: 1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 9.67%, #1c1f29 100%);
    border-radius: 6px;

    span {
      position: relative;
      top: 15px;
      left: 20px;
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 100%;
      letter-spacing: 0.02em;
      color: ${style.mainWhite.white};
    }
  }
`;

export const CheckIcon = styled.div<IProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  top: 6px;
  right: 6px;
  background: ${({ checked }) => (checked ? style.mainBlue.blue : 'none')};
  border: ${({ checked }) =>
    checked
      ? `2px solid ${style.mainBlue.blue}`
      : `2px solid ${style.mainGray.whisperColor}`};
`;
