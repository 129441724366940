import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ArrowBack } from 'public/assets/icons';
import { closeSidebarSuggestions } from 'store/sideBarControler/actions';
import { getSuggestionStart, clearSuggestions } from 'store/user/actions';
import { LoadingComponent } from 'components';
import { AsyncStatus } from 'utils/types';
import { SidebarWrapper } from '../index';
import { SuggestionsListWrap } from './Suggestions.style';
import { SuggestionItem } from './SuggestionItem';

const SUGGESTIONS_LIMIT = 10;

export const Suggestions = () => {
  const [suggestionsStart, setSuggestionsStart] = useState(0);
  const [suggestions, setSuggestions] = useState([]);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { suggestion, getSuggestionReqStat } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    dispatch(
      getSuggestionStart({ start: suggestionsStart, limit: SUGGESTIONS_LIMIT })
    );

    return () => {
      dispatch(clearSuggestions());
    };
  }, [suggestionsStart]);

  useEffect(() => {
    if (suggestion?.length) {
      setSuggestions((prevState) => [...prevState, ...suggestion]);
    }
  }, [suggestion]);

  const observer = useRef(null);

  const lastSearchElement = useCallback((node) => {
    if (observer.current) observer?.current?.disconnect();
    observer.current = new IntersectionObserver((entires) => {
      if (entires[0].isIntersecting) {
        setSuggestionsStart((prevState) => prevState + 10);
      }
    });
    if (node) observer.current.observe(node);
  }, []);

  const suggestionsList = suggestions.map((item) => (
    <SuggestionItem
      key={item._id}
      data={item}
      paginationRef={lastSearchElement}
      onItemClick={() => dispatch(closeSidebarSuggestions())}
    />
  ));

  return (
    <SidebarWrapper
      headerText={t('common:suggestedHeader')}
      headerIcon={<ArrowBack />}
      contentPadding="20px"
      onIconClick={() => dispatch(closeSidebarSuggestions())}
    >
      {suggestions.length === 0 &&
      getSuggestionReqStat !== AsyncStatus.SUCCESS ? (
        <LoadingComponent fullWidth color="#C2C2C2" variant="spin" />
      ) : (
        <SuggestionsListWrap>{suggestionsList}</SuggestionsListWrap>
      )}
    </SidebarWrapper>
  );
};
