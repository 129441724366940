import styled from 'styled-components';

interface IProps {
  top?: number;
  right?: number;
}

export const WrapPopover = styled.div<IProps>`
  width: auto;
  position: absolute;
  z-index: 90;
  top: ${({ top }) => `${top}px`};
  right: ${({ right }) => `${right}px`};
  li button {
    white-space: nowrap;
  }
`;
