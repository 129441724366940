import styled from 'styled-components';

export const UploadMultipleImagesWrapper = styled.div``;

export const MultipleImagesWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const OnePhotoPreview = styled.div`
  position: relative;
`;
export const DropdownWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;

  & > div {
    & > button {
      position: relative;
      z-index: 1;
    }
    & > div {
      top: 0;
      right: 0;
      & > ul {
        top: 32px;
        right: 2px;
      }
    }
  }
`;

export const EditButton = styled.span``;
export const CustomListContent = styled.div`
  display: flex;
  align-items: center;
`;
export const IconWrapper = styled.span`
  display: flex;
  margin-right: 10px;
`;

export const TextWrapper = styled.span`
  font-family: ${(props) => props.theme.typography.fontFamily.primary};
  font-weight: ${(props) => props.theme.typography.fontWeight.medium};
  font-size: ${(props) => props.theme.typography.fontSize.f3};
  color: ${(props) => props.theme.colors.text};
  line-height: 100%;
  letter-spacing: 0.02em;
`;

export const CustomListItem = styled.li`
  position: relative;
  font-family: ${(props) => props.theme.typography.fontFamily.primary};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  font-size: ${(props) => props.theme.typography.fontSize.f5};
  color: ${(props) => props.theme.colors.text};
  line-height: 100%;
  letter-spacing: 0.02em;
  display: flex;
  align-items: center;
  padding: 14px 20px;
  transition: all 0.3s;
  cursor: pointer;
  border-radius: 10px;
  /* overflow: hidden; */
  border-bottom: 1px solid ${(props) => props.theme.colors.neutral.n3};
  border-top: 1px solid ${(props) => props.theme.colors.neutral.n3};
  margin-bottom: -1px;

  &:hover {
    background-color: ${(props) => props.theme.colors.neutral.n3};
    border-radius: 10px !important;
    transition: all 0.3s;
    &:first-child,
    &:last-child {
      border-radius: 8px;
    }
  }

  & > div {
    &:nth-child(2) {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      opacity: 0;
    }
  }
`;
