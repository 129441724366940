import styled from 'styled-components';

export const SidbarListItemSkeleton = styled.div`
  display: -webkit-box;
  margin-bottom: 6px;
`;
export const SidbarListItemSkeletonRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin-left: 9px;
`;
