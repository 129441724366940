import React from 'react';
import { useTheme } from 'styled-components';

const CircleWithPlus = ({
  disabled,
  width = '32',
  height = '32',
  cx = '16',
  cy = '16',
  r = '16',
}: {
  disabled?: boolean;
  width?: string;
  height?: string;
  cx?: string;
  cy?: string;
  r?: string;
}) => {
  const theme: any = useTheme();

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx={cx}
        cy={cy}
        r={r}
        fill={disabled ? theme.colors.neutral.n6 : theme.colors.blue}
      />
      <path
        d="M11.333 16H20.6663"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 11.333V20.6663"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CircleWithPlus;
