import styled from 'styled-components';

import Heading from 'components/common/Typography/Heading';

export const SchedulePostWrapper = styled.div`
  padding: 20px 0 16.5px;
  border-bottom: 1px dashed ${(props) => props.theme.colors.neutral.n4};

  :last-of-type {
    border: none;
  }
`;

export const SchedulePostHeader = styled(Heading)`
  font-size: ${(props) => props.theme.typography.fontSize.f6};
  line-height: 150%;
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  letter-spacing: 0.02em;
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 6px;
`;
