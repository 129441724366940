import { useRouter } from 'next/router';

import { ArrowBack } from 'public/assets/icons';
import { BackButtonWrap } from './BackButton.style';

interface IBackButton {
  onClick?: () => void;
}

export const BackButton = ({ onClick }: IBackButton) => {
  const router = useRouter();

  const onButtonClick = () => {
    if (onClick) {
      onClick();
    } else {
      router.back();
    }
  };

  return (
    <BackButtonWrap onClick={onButtonClick}>
      <ArrowBack />
    </BackButtonWrap>
  );
};
