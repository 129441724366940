import Image from 'next/image';
import { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Picker } from 'emoji-mart';
import TextareaAutosize from 'react-textarea-autosize';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { ArrowBack, PaperPlaneIcon, SmileyIcon } from 'public/assets/icons';
import { LoadingComponent, CommentItem } from 'components';
import {
  actionTypes,
  closeSidebarComments,
} from 'store/sideBarControler/actions';
import { style } from 'utils/constants/style';
import { setSelectedChatId } from 'store/chat/actions';
import SidebarWrapper from '../SidebarWrapper/SidebarWrapper';

import { ConfirmationModal } from '../../index';
import {
  CommentWrap,
  ContentComments,
  UserName,
  DateComment,
  ContainerComment,
  ImageWrap,
  InputFieldWrap,
  CameraIconWrap,
  IconWrap,
  EmojiPickerWrap,
  CommentWrapper,
  DeleteCommentWrapper,
  DeleteComment,
  WrapPopover,
  ListUserUserActions,
} from './Comments.style';

const Comments = ({ comments, type, onAddComment, onDeleteComment }: any) => {
  const dispatch = useDispatch();
  const [commentIdSelect, setCommentIdSelect] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [sentIconColor, setSentIconColor] = useState(style.mainBlue.blue);
  const [showEmodjiPopup, setShowEmodjiPopup] = useState(false);

  const messageInputRef = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    messageInputRef.current?.focus();
  }, []);

  const onEmojiClick = (emoji) => {
    setInputValue(`${inputValue}${emoji.native} `);
  };

  const isEmptyStr = (str) => {
    if (str.trim() === '') return true;

    return false;
  };

  const sendComment = (e) => {
    if (!isEmptyStr(e)) {
      onAddComment(e);
      setInputValue('');
    }
  };

  const removeComment = async () => {
    try {
      setDeleteLoading(true);
      await onDeleteComment(commentIdSelect);
      setDeleteLoading(false);
      setCommentIdSelect(null);
    } catch (error) {
      setDeleteLoading(false);
      setCommentIdSelect(null);
      console.log(error);
    }
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendComment(inputValue);
    }
  };

  const commentHeader = () => {
    if (type) {
      if (type === 'profile') {
        return t('event:postComments');
      }
      if (type === 'car') {
        return t('event:vehicleComments');
      }
      if (type === 'event') {
        return t('event:eventComments');
      }
    }
    return t('event:postComments');
  };

  return (
    <SidebarWrapper
      headerText={commentHeader()}
      headerIcon={<ArrowBack />}
      isSubmitAvailable={false}
      onIconClick={() =>
        dispatch(closeSidebarComments(actionTypes.CLOSE_SIDEBAR_COMMENTS))
      }
      onSidebarClick={() => showEmodjiPopup && setShowEmodjiPopup(false)}
      contentPadding="0"
      isChat
    >
      <ContentComments>
        {_.isArray(comments) ? (
          comments.map((el, index) => {
            return (
              <CommentItem
                key={`${el._id}_${index}`}
                photo={el?.creator.profilePhoto}
                username={el?.creator?.username}
                text={el?.text}
                datetime={el.datetime}
                type={type}
                id={el._id}
                onDeleteComment={(v) => setCommentIdSelect(v)}
                creatorId={el?.creator._id}
              />
            );
          })
        ) : (
          <LoadingComponent fullWidth color="#C2C2C2" variant="spin" />
        )}
      </ContentComments>

      <InputFieldWrap>
        <TextareaAutosize
          value={inputValue}
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          placeholder={t('chat:writeAMessage')}
          style={{
            flexGrow: 1,
            padding: '8px 60px 8px 17px',
            marginLeft: 8,
            borderRadius: 18,
            backgroundColor: style.mainGray.snowColor,
            fontSize: 14,
            lineHeight: '21px',
            letterSpacing: '0.01em',
            color: style.mainBlack.black,
            position: 'relative',
            border: 'none',
            outline: 'none',
            fontFamily: 'Poppins',
            resize: 'none',
          }}
          onKeyDown={onKeyDown}
          ref={messageInputRef}
        />

        <IconWrap
          width={16}
          height={16}
          bottom={23}
          right={58}
          onClick={() => setShowEmodjiPopup(!showEmodjiPopup)}
        >
          <SmileyIcon />
        </IconWrap>
        <IconWrap
          width={28}
          height={28}
          rounded
          backgroundColor={style.mainBlue.aliceBlueColor}
          bottom={17}
          right={20}
          onClick={() => sendComment(inputValue)}
          onMouseEnter={() => setSentIconColor(style.mainWhite.white)}
          onMouseLeave={() => setSentIconColor(style.mainBlue.blue)}
        >
          <div
            style={{
              position: 'absolute',
              bottom: 6,
              left: 6,
              height: 14,
              width: 14,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <PaperPlaneIcon colorStroke={sentIconColor} size="14" />
          </div>
        </IconWrap>

        {showEmodjiPopup && (
          <EmojiPickerWrap onClick={(e) => e.stopPropagation()}>
            <Picker
              onSelect={onEmojiClick}
              showPreview={false}
              showSkinTones={false}
            />
          </EmojiPickerWrap>
        )}
      </InputFieldWrap>
      {commentIdSelect !== null && (
        <ConfirmationModal
          headerText={t('common:delete')}
          bodyText="Are you sure you want to delete the comment"
          submitText="Yes"
          onSubmit={removeComment}
          loading={deleteLoading}
          onCancelClick={() => setCommentIdSelect(null)}
        />
      )}
    </SidebarWrapper>
  );
};

export default Comments;
