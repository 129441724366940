import { useContext } from 'react';
import { useTheme } from 'styled-components';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';

import Link from 'components/common/Link';
import { routesPath } from 'utils/constants/routePath';
import { ChatIcon, SearchIcon } from 'public/assets/icons';
import { ThemeContext } from 'theme';

import {
  clearSidebarStore,
  openSidebarChatsList,
  openSidebarNotifications,
} from 'store/sideBarControler/actions';

import {
  HeaderMobileNavItem,
  MobileLoginHeaderWrapper,
  NavItem,
} from './MobileLoginHeader.styles';
import { IMobileLoginHeader } from './IMobileLoginHeader';

const MobileLoginHeader = ({
  isSidebarOpen,
  isOpenSidebarSavedRoutes,
  isOpenSidebarWhereTo,
  isOpenSidebarPlanRoute,
  isOpenSidebarChoseStartPoint,
  isOpenSidebarChoseWayPoint,
  isOpenSidebarRouteDetail,
  isOpenSidebarChoseEndPoint,
  isConformationModalOpen,
}: IMobileLoginHeader) => {
  const dispatch = useDispatch();
  const { searchPath } = routesPath;
  const router = useRouter();
  const { route } = router;
  const theme: any = useTheme();

  const context: any = useContext(ThemeContext);
  const Logo = context.content.transparentLogo;

  return (
    <MobileLoginHeaderWrapper
      isSidebarOpen={
        isSidebarOpen ||
        isOpenSidebarSavedRoutes ||
        isOpenSidebarWhereTo ||
        isOpenSidebarPlanRoute ||
        isOpenSidebarChoseStartPoint ||
        isOpenSidebarChoseWayPoint ||
        isOpenSidebarRouteDetail ||
        isOpenSidebarChoseEndPoint
      }
      isConformationModalOpen={isConformationModalOpen}
    >
      <HeaderMobileNavItem>
        <Link path={searchPath}>
          <NavItem>
            <SearchIcon
              colorStroke={
                route === searchPath ? theme.colors.orange : theme.colors.black
              }
            />
          </NavItem>
        </Link>
      </HeaderMobileNavItem>
      <HeaderMobileNavItem>
        <Link path="/">
          <NavItem>
            <Logo />
          </NavItem>
        </Link>
      </HeaderMobileNavItem>
      <HeaderMobileNavItem onClick={() => dispatch(openSidebarChatsList())}>
        <NavItem>
          <ChatIcon colorStroke={theme.colors.black} />
        </NavItem>
      </HeaderMobileNavItem>
    </MobileLoginHeaderWrapper>
  );
};

export default MobileLoginHeader;
