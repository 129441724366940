import React from 'react';

export const ArrowBack = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3657 7.36569C11.6781 7.05327 11.6781 6.54673 11.3657 6.23431C11.0533 5.9219 10.5467 5.9219 10.2343 6.23431L5.23431 11.2343C4.9219 11.5467 4.9219 12.0533 5.23431 12.3657L10.2343 17.3657C10.5467 17.6781 11.0533 17.6781 11.3657 17.3657C11.6781 17.0533 11.6781 16.5467 11.3657 16.2343L7.77975 12.6484H19.1964C19.6383 12.6484 19.9964 12.2902 19.9964 11.8484C19.9964 11.4065 19.6383 11.0484 19.1964 11.0484H7.68299L11.3657 7.36569Z"
      fill="black"
    />
  </svg>
);
