import Link from 'components/common/Link';
import styled from 'styled-components';
import Image from 'next/image';
import { style } from 'utils/constants/style';

interface IProps {
  isSidebarOpen?: boolean;
  isAuthenticate?: boolean;
  windowSize?: boolean;
}

export const Wrapper = styled.div<IProps>`
  width: 100%;
  /* height: 72px; */
  box-sizing: border-box;
  border-top: 1px solid ${(props) => props.theme.colors.neutral.n5};
  box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
  /* border-radius: 12px 12px 0px 0px; */
  /*background-color: ${(props) => props.theme.colors.neutral.n0};*/
  background: ${() =>
    process.env._ENV === 'rockr'
      ? 'linear-gradient(180deg, #ED0547 0%, #DB0643 100%)'
      : 'linear-gradient(180deg, #FFAF47 0%, #FF950A 100%)'};
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: fixed;
  bottom: ${({ isSidebarOpen, isAuthenticate, windowSize }) =>
    isSidebarOpen || !isAuthenticate || windowSize ? `0` : '49px'};
  z-index: 100;
  transition-duration: 2s;
`;

export const AppAlertLeft = styled.div`
  padding: 14px;

  & h2 {
    margin-bottom: 10px;
  }
`;
export const AppAlertRight = styled.div`
  padding: 10px;
  // position: absolute;
  // right: 20px;
  // top: -105px;
  // bottom: 0;

  // @media (max-width: 370px) {
  //   & > svg {
  //     width: 110px;
  //     @media (max-width: 340px) {
  //       width: 100px;
  //     }
  //   }
  // }
`;

export const DownloadContainer = styled.div``;
export const DownloadButton = styled(Link)``;
export const DownloadButtonWrap = styled.div`
  display: inline-block;
  &:not(:last-child) {
    margin-right: 10px;
  }
`;

export const LogoWrap = styled.div`
  height: 48px;
  width: 48px;
  margin-right: 8px;
  padding: 10px;
`;

export const AlertBodyWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AppNameText = styled.p`
  font-size: 14px;
  line-height: 100%;
  font-weight: 600;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.charcoalColor};
  margin-top: 1px;
  margin-bottom: 4px;
`;

export const Text = styled.p`
  width: 100%;
  max-width: 177px;
  font-size: 16px;
  line-height: 16px;
  padding-bottom: 5px;
  color: ${style.mainWhite.white};
`;

export const OpenButton = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-top: 13px;
  width: 64px;
  height: 32px;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${style.mainBlue.blue};
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: ${style.mainWhite.white};
`;

export const CrossIconWrap = styled.div`
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
`;

export const Logo = styled(Image)`
  background-size: contain;
`;
