/* eslint-disable react/prop-types */
import React from 'react';

export const ChatIcon = ({
  colorStroke = '#1C1F29',
}: {
  colorStroke?: string | undefined;
}) => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.7 10.3004C6.7 10.0004 6.7 9.70039 6.8 9.40039C4.3 10.2004 2.5 12.1004 2.5 14.3004C2.5 15.7004 3.2 16.9004 4.2 17.9004L3.8 20.9004L7 19.3004C7.7 19.5004 8.4 19.6004 9.1 19.6004C11.7 19.6004 13.9 18.4004 15 16.6004C14.9 16.6004 14.8 16.6004 14.6 16.6004C10.2 16.6004 6.7 13.8004 6.7 10.3004Z"
      stroke={colorStroke}
      strokeWidth="1.4747"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.0002 16.6C15.8002 16.6 16.5002 16.5 17.2002 16.3L21.0002 18.2L20.5002 14.6C21.8002 13.5 22.6002 12 22.6002 10.3C22.6002 6.8 19.0002 4 14.6002 4C10.6002 4 7.3002 6.3 6.7002 9.4"
      stroke={colorStroke}
      strokeWidth="1.4747"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
