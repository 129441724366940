import styled from 'styled-components';

interface IProps {
  span?: number;
  fr?: number;
}

export const ContainerWrapper = styled.div`
  max-width: 860px;
  margin: 0 auto;
`;

export const RowWrapper = styled.div<IProps>`
  display: grid;
  grid-template-columns: ${({ fr }) => `repeat(${fr || 12}, 1fr)`};
  grid-gap: 14px;
  width: 100%;

  @media (max-width: 575px) {
    grid-gap: 10px;
    @media (max-width: 400px) {
      grid-gap: 8px;
    }
  }
`;
export const ColWrapper = styled.div<IProps>`
  ${(props) => {
    if (props.span) {
      return `
    grid-column: span
    ${
      (props.span === 12 && '12') ||
      (props.span === 11 && '11') ||
      (props.span === 10 && '10') ||
      (props.span === 9 && '9') ||
      (props.span === 8 && '8') ||
      (props.span === 7 && '7') ||
      (props.span === 6 && '6') ||
      (props.span === 5 && '5') ||
      (props.span === 4 && '4') ||
      (props.span === 3 && '3') ||
      (props.span === 2 && '2') ||
      (props.span === 1 && '1')
    };
    `;
    }
    return `width: 100%`;
  }}
`;
