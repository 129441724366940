/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ScrollToLoad from 'components/ScrollToLoad';
import { ArrowBack, SearchIcon, WheelIcon } from 'public/assets/icons';
import { closeSidebarInvolvedUsers } from 'store/sideBarControler/actions';
import { getInvolvedUsers, clearInvolvedUsersStore } from 'store/user/actions';
import { clearInvolvedUsersPostStore } from 'store/posts/actions';
import { InputField, LoadingComponent } from 'components';
import {
  removeUserFromMyFollowers,
  deleteGroupFollower,
  deleteGroupMember,
} from 'services';
import { InvolvedUserTypes } from 'utils/constants';
import { routesPath } from 'utils/constants/routePath';
import { IInvolvedUsers } from './IInvolvedUsers';
import { SidebarWrapper } from '../index';
import {
  InvolvedUsersListWrap,
  SearchWrap,
  PlaceholderWrap,
  Text,
} from './InvolvedUsers.style';
import {
  getHeaderTitle,
  getInvolvedUsersVariable,
  isSearchVisible,
  isPaginationUsed,
} from './helpers';
import { InvolvedUserItem } from './InvolveduserItem';

const DEFAULT_PAGE_SIZE = 20;

// eslint-disable-next-line react/display-name
export const InvolvedUsers = React.memo(
  ({ myId, involvedUserType, id, isAdmin }: IInvolvedUsers) => {
    const [involvedUsers, setInvolvedUsers] = useState([]);
    const [query, setQuery] = useState('');
    const [isResetOffset, setIsResetOffset] = useState(false);
    const [loading, setLoading] = useState(true);

    const router = useRouter();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const posts = useSelector((state) => state.posts);
    const user = useSelector((state) => state.user);
    const { isOpenSidebarInvolvedUsers } = useSelector(
      (state) => state.sideBarControler
    );

    const observer = useRef(null);

    const lastSearchElement = useCallback((node) => {
      if (observer.current) observer?.current?.disconnect();
      observer.current = new IntersectionObserver((entires) => {
        if (entires[0].isIntersecting) {
          // setIsResetOffset((prevState) => prevState + 20);
        }
      });
      if (node) observer.current.observe(node);
    }, []);

    const getUsers = useCallback(
      (offset = 0) => {
        dispatch(
          getInvolvedUsers({
            id,
            involvedUserType,
            offset,
            limit: DEFAULT_PAGE_SIZE,
            query,
          })
        );

        return () => {
          const isUserReducer =
            involvedUserType === InvolvedUserTypes.USER_FOLLOWING ||
            involvedUserType === InvolvedUserTypes.MY_FOLLOWING ||
            involvedUserType === InvolvedUserTypes.USER_FOLLOWERS ||
            involvedUserType === InvolvedUserTypes.MY_FOLLOWERS;
          const involvedUsersVariable =
            getInvolvedUsersVariable(involvedUserType);

          if (isUserReducer) {
            dispatch(clearInvolvedUsersStore(involvedUsersVariable));
          } else {
            dispatch(clearInvolvedUsersPostStore(involvedUsersVariable));
          }
        };
      },
      [query]
    );

    useEffect(() => {
      getUsers();
      setInvolvedUsers([]);
    }, [getUsers]);

    const involvedUsersVariable = getInvolvedUsersVariable(involvedUserType);

    const involvedUsersDataFromStore =
      posts[involvedUsersVariable] || user[involvedUsersVariable];
    const involvedUsersTotalData =
      posts[`${involvedUsersVariable}Total`] ||
      user[`${involvedUsersVariable}Total`];
    useEffect(() => {
      if (involvedUsersDataFromStore) {
        setInvolvedUsers((prevState) => [
          ...prevState,
          ...involvedUsersDataFromStore,
        ]);
        loading && setLoading(false);
      }
    }, [involvedUsersDataFromStore]);

    const onItemClick = useCallback(
      (userId) => {
        router.push(`${routesPath.user_details}/${userId}`);

        dispatch(closeSidebarInvolvedUsers());
      },
      [dispatch]
    );

    const onRemoveUserClick = (userId = '') => {
      const updateInvolvedUsersState = () => {
        setInvolvedUsers((prevState) =>
          prevState.filter((el) => el._id !== userId)
        );
      };

      switch (involvedUserType) {
        case InvolvedUserTypes.MY_FOLLOWERS:
          removeUserFromMyFollowers({ userId }).then((resp) => {
            if (!resp.error) {
              updateInvolvedUsersState();
            }
          });
          break;

        case InvolvedUserTypes.GROUP_FOLLOWERS:
          deleteGroupFollower({ groupId: id, followerId: userId }).then(
            (resp) => {
              if (!resp.error) {
                updateInvolvedUsersState();
              }
            }
          );
          break;

        case InvolvedUserTypes.GROUP_MEMBERS:
          deleteGroupMember({ groupId: id, memberId: userId }).then((resp) => {
            if (resp.ok) {
              updateInvolvedUsersState();
            }
          });
          break;

        default:
      }
    };

    const onQueryValueChange = (value) => {
      setIsResetOffset(true);
      setLoading(true);
      setInvolvedUsers([]);
      setQuery(value);
    };

    const involvedUsersList = () =>
      involvedUsers.map((item) => (
        <InvolvedUserItem
          data={item}
          onItemClick={() => onItemClick(item._id)}
          isAdmin={isAdmin}
          myId={myId}
          involvedUserType={involvedUserType}
          // paginationRef={
          //   isPaginationUsed(involvedUserType) ? lastSearchElement : null
          // }
          onRemoveUserClick={() => onRemoveUserClick(item._id)}
          key={item._id}
        />
      ));
    const renderContent = useCallback(() => {
      if (loading) {
        return <LoadingComponent fullWidth color="#C2C2C2" variant="spin" />;
      }

      return !involvedUsers.length && query.length ? (
        <PlaceholderWrap>
          <Text>{t('chat:nothingFound')}</Text>
          <WheelIcon />
        </PlaceholderWrap>
      ) : (
        <InvolvedUsersListWrap>{involvedUsersList()}</InvolvedUsersListWrap>
      );
    }, [loading, involvedUsers]);

    return (
      <SidebarWrapper
        headerText={getHeaderTitle(involvedUserType)}
        headerIcon={<ArrowBack />}
        contentPadding="20px"
        onIconClick={() => dispatch(closeSidebarInvolvedUsers())}
      >
        {isSearchVisible(involvedUserType) && (
          <SearchWrap>
            <InputField
              value={query}
              onChange={(e) => {
                onQueryValueChange(e.target.value);
              }}
              crossIcon
              onCrossClick={() => onQueryValueChange('')}
              iconLeft={<SearchIcon />}
              placeholder={t('search:searchTextWithDots')}
            />
          </SearchWrap>
        )}
        <ScrollToLoad
          pageSize={DEFAULT_PAGE_SIZE}
          loader={<LoadingComponent fullWidth color="#C2C2C2" variant="spin" />}
          totalData={involvedUsersTotalData}
          isResetOffset={isResetOffset}
          data={involvedUsers}
          fetchMoreData={getUsers}
          scrollableTarget="scrollTarget"
          render={(item, i) => {
            return (
              <InvolvedUserItem
                data={item}
                onItemClick={() => onItemClick(item._id)}
                isAdmin={isAdmin}
                myId={myId}
                involvedUserType={involvedUserType}
                onRemoveUserClick={() => onRemoveUserClick(item._id)}
                key={item._id}
              />
            );
          }}
        />
      </SidebarWrapper>
    );
  }
);
