/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/dist/client/router';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import {
  ArrowBack,
  SettingsIcon,
  WelcomeToRoadSTRIcon,
  UpcomingEventIcon,
  InviteYourFriendsIcon,
  CustomNotificationIcon,
  NotificationsWithDotIcon,
  FindYourFriendsIcon,
  CreateFirstPostIcon,
  CreateFirstEventIcon,
  CreateFirstVehicleIcon,
  CreateFirstPOIIcon,
  TurnOnRoadSharingIcon,
} from 'public/assets/icons';
import {
  closeSidebarNotifications,
  openSidebarPendingApprovals,
} from 'store/sideBarControler/actions';
import {
  getUserNotificationsStart,
  clearNotificationsStore,
} from 'store/notifications/actions';
import {
  getRightSideImageBasedOnType,
  getLeftSideIconBasedOnType,
  getLeftSideImageBasedOnType,
  getRightSideImagePressDestinationBasedOnType,
  getLeftSideImagePressDestinationBasedOnType,
  getNotificationPressDestinationBasedOnType,
} from 'utils/notificationsHelpers';
import { routesPath } from 'utils/constants/routePath';
import { useOutsideClick } from 'utils/hooks';
import { style } from 'utils/constants/style';
import {
  PopoverWrap,
  PendingWrap,
  ViewAll,
  HeaderRightIconWrap,
} from './ Notifications.style';
import { ListUserUserActions } from '../../Popover/Popover.style';
import { ConfiguringPopoverComponent } from '../../Popover/ConfiguringPopoverComponent';
import { HeaderText } from '../NotificationItem/NotificationItem.style';
import { NotificationText } from './NotificationText/NotificationText';
import { NotificationHeader } from './NotificationHeaderText/NotificationHeaderText';
import { SidebarWrapper, NotificationItem, PendingApprovals } from '../index';

const NOTIFICATIONS_LIMIT = 10;

const Notifications = () => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [notificationsStart, setNotificationsStart] = useState(0);
  const [notificationsData, setNotificationsData] = useState([]);

  const dispatch = useDispatch();
  const router = useRouter();
  const refActionPopover = useRef(null);
  useOutsideClick(refActionPopover, () => {
    setIsPopoverOpen(false);
  });

  const { t } = useTranslation();

  const { notifications, pendingNotifications } = useSelector(
    (state) => state.notifications
  );

  const observer = useRef(null);

  const lastSearchElement = useCallback((node) => {
    if (observer.current) observer?.current?.disconnect();
    observer.current = new IntersectionObserver((entires) => {
      if (entires[0].isIntersecting) {
        setNotificationsStart((prevState) => prevState + 10);
      }
    });
    if (node) observer.current.observe(node);
  }, []);

  useEffect(() => {
    dispatch(
      getUserNotificationsStart({
        limit: NOTIFICATIONS_LIMIT,
        start: notificationsStart,
        pending: true,
      })
    );

    return () => {
      dispatch(clearNotificationsStore());
    };
  }, []);

  useEffect(() => {
    dispatch(
      getUserNotificationsStart({
        limit: NOTIFICATIONS_LIMIT,
        start: notificationsStart,
        pending: false,
      })
    );
  }, [notificationsStart]);

  useEffect(() => {
    if (notifications && _.isArray(notifications)) {
      setNotificationsData((prev) => [...prev, ...notifications]);
    }
  }, [notifications]);

  const getLeftSideIcon = (type) => {
    const iconText = getLeftSideIconBasedOnType(type);

    if (!iconText) {
      return false;
    }

    switch (iconText) {
      case 'welcomeToRoadSTR':
        return <WelcomeToRoadSTRIcon />;

      case 'upcomingEvent':
        return <UpcomingEventIcon />;

      case 'inviteYourFriends':
        return <InviteYourFriendsIcon />;

      case 'customNotification':
        return <CustomNotificationIcon />;

      case 'findYourFriends':
        return <FindYourFriendsIcon />;

      case 'createFirstPost':
        return <CreateFirstPostIcon />;

      case 'createFirstEvent':
        return <CreateFirstEventIcon />;

      case 'createFirstVehicle':
        return <CreateFirstVehicleIcon />;

      case 'createFirstPOI':
        return <CreateFirstPOIIcon />;

      case 'turnOnRoadSharing':
        return <TurnOnRoadSharingIcon />;

      default:
        return false;
    }
  };

  const notificationsList = notificationsData.map((notification) => {
    const { data } = notification;
    const { type } = notification.data;

    const leftIcon = getLeftSideIcon(type);
    const leftImage = getLeftSideImageBasedOnType(type, data)?.trim();
    const rightPhoto = getRightSideImageBasedOnType(type, data) || '';

    const onLeftImageClick = getLeftSideImagePressDestinationBasedOnType(
      type,
      data
    );
    const onNotificationPress = getNotificationPressDestinationBasedOnType(
      type,
      data
    );
    const rightImageRedirectPath = getRightSideImagePressDestinationBasedOnType(
      type,
      data
    );

    return (
      <NotificationItem
        key={notification._id}
        paginationRef={lastSearchElement}
        rightPhoto={
          rightPhoto.startsWith('https') || rightPhoto.startsWith('http')
            ? rightPhoto
            : ''
        }
        leftIcon={leftIcon}
        profilePhoto={leftImage}
        text={<NotificationText type={type} data={data} />}
        headerText={<NotificationHeader type={type} />}
        date={notification.datetime}
        onRightPhotoClick={() => {
          if (rightImageRedirectPath) {
            router.push(rightImageRedirectPath);
            dispatch(closeSidebarNotifications());
          }
        }}
        onLeftIconClick={() => {
          if (onLeftImageClick) {
            dispatch(closeSidebarNotifications());
            router.push(onLeftImageClick);
          }
        }}
        onClick={() => {
          if (onNotificationPress) {
            dispatch(closeSidebarNotifications());
            router.push(onNotificationPress);
          }
        }}
        isNotificationClickable={Boolean(onNotificationPress)}
      />
    );
  });

  const onSettingsClick = () => {
    setIsPopoverOpen(false);
    dispatch(closeSidebarNotifications());

    router.push(routesPath.settings);
  };

  return (
    <SidebarWrapper
      headerText={t('notifications:notifications')}
      headerIcon={<ArrowBack />}
      onSubmit={() => setIsPopoverOpen(!isPopoverOpen)}
      onIconClick={() => {
        dispatch(closeSidebarNotifications());
      }}
      headerRightIcon={
        <HeaderRightIconWrap ref={refActionPopover}>
          <SettingsIcon color={style.mainBlack.black} />
        </HeaderRightIconWrap>
      }
      contentPadding="0 0 20px 0"
    >
      {isPopoverOpen && (
        <PopoverWrap>
          <ConfiguringPopoverComponent>
            <ListUserUserActions>
              <li onClick={onSettingsClick}>{t('profile:settings')}</li>
            </ListUserUserActions>
          </ConfiguringPopoverComponent>
        </PopoverWrap>
      )}
      {PendingApprovals && !!PendingApprovals.length && (
        <PendingWrap>
          <NotificationsWithDotIcon />
          <HeaderText ml={10}>{`${PendingApprovals.length} ${t(
            'notifications:pendingApprovals'
          )}`}</HeaderText>
          <ViewAll
            onClick={() =>
              dispatch(openSidebarPendingApprovals(PendingApprovals))
            }
          >
            {t('common:viewAll')}
          </ViewAll>
        </PendingWrap>
      )}
      {notificationsList}
    </SidebarWrapper>
  );
};

export default Notifications;
