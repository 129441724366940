import styled from 'styled-components';

interface IProps {
  type?: string;
  valid?: boolean;
}

export const InputField = styled.input<any>`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

export const Wrapper = styled.label<IProps>`
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 4px;
  cursor: pointer;
  font-size: ${(props) => props.theme.typography.fontSize.f5};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.colors.text};
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
