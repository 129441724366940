/* eslint-disable react/prop-types */
import React from 'react';
import { style } from '../../../utils/constants/style';

export const LogOutIcon = ({
  colorStroke = '#1C1F29',
}: {
  colorStroke?: string | undefined;
}) => (
  <svg
    width="24"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.6 11.2V12.8C1.6 14.568 3.0328 16 4.8 16H12.8C14.568 16 16 14.568 16 12.8C16 10.1368 16 5.864 16 3.2C16 1.4328 14.568 0 12.8 0C10.5128 0 7.088 0 4.8 0C3.0328 0 1.6 1.4328 1.6 3.2V4.8C1.6 5.2416 1.9584 5.6 2.4 5.6C2.8416 5.6 3.2 5.2416 3.2 4.8C3.2 4.8 3.2 4.0504 3.2 3.2C3.2 2.3168 3.9168 1.6 4.8 1.6H12.8C13.684 1.6 14.4 2.3168 14.4 3.2V12.8C14.4 13.684 13.684 14.4 12.8 14.4C10.5128 14.4 7.088 14.4 4.8 14.4C3.9168 14.4 3.2 13.684 3.2 12.8C3.2 11.9504 3.2 11.2 3.2 11.2C3.2 10.7592 2.8416 10.4 2.4 10.4C1.9584 10.4 1.6 10.7592 1.6 11.2ZM7.6688 7.2L6.6344 6.16639C6.3224 5.85359 6.3224 5.3472 6.6344 5.0344C6.9472 4.7224 7.4536 4.7224 7.7656 5.0344L10.1656 7.4344C10.4784 7.7472 10.4784 8.25359 10.1656 8.56639L7.7656 10.9664C7.4536 11.2784 6.9472 11.2784 6.6344 10.9664C6.3224 10.6536 6.3224 10.1472 6.6344 9.8344L7.6688 8.8H0.8C0.3584 8.8 0 8.4416 0 8C0 7.5592 0.3584 7.2 0.8 7.2H7.6688Z"
      fill="#8D8D8D"
    />
  </svg>
);
