import React from 'react';
import Carousel from 'react-multi-carousel';
import { useRouter } from 'next/router';
import { CampaignCard } from 'components';
import { ChevronRightIcon } from 'public/assets/icons';
import { routesPath } from 'utils/constants/routePath';
import {
  Wrapper,
  HeaderWrap,
  SuggestionsListWrap,
  ArrowWrap,
  GroupCardWrap,
} from './CampaignSlider.style';

// eslint-disable-next-line react/prop-types
const CustomRightArrow = ({ onClick }) => (
  <ArrowWrap right={16} onClick={() => onClick()}>
    <ChevronRightIcon />
  </ArrowWrap>
);

// eslint-disable-next-line react/prop-types
const CustomLeftArrow = ({ onClick }) => (
  <ArrowWrap left={16} transform={180} onClick={() => onClick()}>
    <ChevronRightIcon />
  </ArrowWrap>
);

export const CampaignSlider = ({ data }: any) => {
  const router = useRouter();
  let itemsToShow;
  if (data.length < 2) itemsToShow = 1;

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: itemsToShow || 1.95,
    },
    desktop: {
      breakpoint: { max: 3000, min: 860 },
      items: itemsToShow || 1.95,
    },
    tablet: {
      breakpoint: { max: 860, min: 550 },
      items: itemsToShow || 1.95,
    },
    mobile: {
      breakpoint: { max: 550, min: 490 },
      items: itemsToShow || 1.7,
    },
    mobile2: {
      breakpoint: { max: 490, min: 450 },
      items: itemsToShow || 1.6,
    },
    mobile3: {
      breakpoint: { max: 450, min: 360 },
      items: itemsToShow || 1.3,
    },
  };

  const renderSuggestionsList = () => {
    return data?.map((item) => {
      const isCoverPhotoExists = Boolean(
        item?.coverPhoto?.url && item?.coverPhoto?.url.trim()
      );

      return (
        <GroupCardWrap
          key={item._id}
          width={itemsToShow && itemsToShow === 1 ? '100%' : ''}
        >
          <CampaignCard
            id={item._id}
            alertText={item.alertText}
            coverPhoto={
              isCoverPhotoExists
                ? item?.coverPhoto?.url
                : '/assets/img/tireTrace.png'
            }
            username={item.username}
            profilePhoto={item.profilePhoto?.url}
            name={item.name}
            followers={item.followers}
            onClick={() =>
              router.push(`${routesPath.group_details}/${item._id}`)
            }
            onJoinClick={() => alert('join')}
            isCoverPhotoExists={isCoverPhotoExists}
            privacy={item.privacy}
            isFollowing={item.isFollowing}
            followingStatus={item.followingStatus}
            withConfirmationModal={false}
            ctaText={item?.ctaText}
            itemsToShow={itemsToShow}
          />
        </GroupCardWrap>
      );
    });
  };

  return (
    <Wrapper>
      <SuggestionsListWrap>
        <Carousel
          responsive={responsive}
          customRightArrow={<CustomRightArrow onClick={() => null} />}
          customLeftArrow={<CustomLeftArrow onClick={() => null} />}
        >
          {renderSuggestionsList()}
        </Carousel>
      </SuggestionsListWrap>
    </Wrapper>
  );
};
