import styled from 'styled-components';

export const ModalOverlay = styled.div`
  position: fixed;
  inset: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: none;
`;

export const ModalContainer = styled.div`
  position: fixed;
  left: 0;
  top: 56px;
  bottom: 0;
  right: 0;
  background-color: ${(props) => props.theme.colors.neutral.n3};
  padding: 30px;
  box-shadow: ${(props) => props.theme.colors.shadows.sm};
  border-radius: '16px';

  @media (max-width: 575px) {
    width: auto !important;
  }
`;

export const ContentWrapper = styled.div`
  width: 860px;
  margin: 0 auto;

  @media only screen and (max-width: 860px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
`;

export const ModalWrapper = styled.div`
  height: calc(100vh - 58px);
  padding: '100px 0';
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;
