import styled from 'styled-components';

export const ItemWrap = styled.div`
  display: flex;
  cursor: pointer;
  margin-bottom: 5px;
  text-overflow: ellipsis;
`;

export const ImageWrapper = styled.span`
  height: 40px;
  width: 40px;
  overflow: hidden;
  border-radius: 50%;
`;
export const ItemDescriptions = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 9px;
  white-space: nowrap;
`;

export const ItemName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 100%;
  font-size: ${(props) => props.theme.typography.fontSize.f3};
  font-weight: ${(props) => props.theme.typography.fontWeight.medium};
  padding-bottom: 4px;
`;

export const ItemDescription = styled.span`
  font-family: ${(props) => props.theme.typography.fontFamily.primary};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  font-size: ${(props) => props.theme.typography.fontSize.f2};
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${(props) => props.theme.colors.neutral.n8};
`;

export const EmptyUserPhoto = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #e8f3ff;
  display: flex;
  justify-content: center;
  align-items: center;
`;
