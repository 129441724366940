import styled from 'styled-components';

export const ValidationErrorWrapper = styled.div<any>`
  margin: 6px 0 0;
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  color: ${(props) => props.theme.colors.red};
  letter-spacing: 0.02em;
`;
