/* eslint-disable react/style-prop-object */
/* eslint-disable react/prop-types */
import React from 'react';
import { style } from 'utils/constants/style';

export const PaperPlaneIcon = ({
  colorStroke = style.mainBlue.blue,
  size = '16',
}: {
  colorStroke?: string;
  size?: string;
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.9973 1.26423C14.9952 1.2481 14.9928 1.23257 14.9879 1.21675C14.9827 1.19939 14.9757 1.18356 14.9678 1.16743C14.9635 1.1586 14.962 1.14917 14.9568 1.14064C14.9535 1.13516 14.948 1.13151 14.9441 1.12633C14.934 1.11233 14.9221 1.10015 14.9097 1.08767C14.8972 1.07519 14.8847 1.06362 14.8704 1.05358C14.8652 1.04993 14.8619 1.04475 14.8567 1.04171C14.8482 1.03653 14.8387 1.03531 14.8299 1.03105C14.8132 1.02283 14.7967 1.01583 14.7791 1.01066C14.7641 1.0067 14.7495 1.00457 14.7343 1.00274C14.7164 1.00061 14.699 0.999394 14.681 1.00031C14.6643 1.00122 14.6482 1.00457 14.6317 1.00853C14.6214 1.01096 14.6107 1.01005 14.6004 1.0137L1.20614 5.57991C1.08499 5.6207 1.00249 5.73364 1.00006 5.8618C0.99762 5.98996 1.07555 6.10594 1.19549 6.1519L7.06077 8.40563C7.31183 8.5021 7.51345 8.69563 7.6201 8.94254L10.1574 14.8164C10.2058 14.9282 10.316 15 10.4369 15C10.4421 15 10.4469 15 10.4521 14.9997C10.5791 14.9933 10.6883 14.909 10.727 14.7881L14.9888 1.39391C14.9918 1.38448 14.9909 1.37474 14.9928 1.36499C14.9964 1.34795 14.9992 1.33151 14.9998 1.31416C15.0004 1.29711 14.9995 1.28098 14.9973 1.26423Z"
      stroke={colorStroke}
    />
    <path
      d="M14.6339 1.38965L7.62225 8.77854"
      stroke={colorStroke}
      strokeLinecap="round"
    />
  </svg>
);
