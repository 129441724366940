import { HYDRATE } from 'next-redux-wrapper';
import { AsyncStatus } from 'utils/types';

import { actionTypes } from './actions';

const initialState = {
  recoverError: null,
  resetError: null,

  isResetPasswordActive: null,

  recoverAccountReqStat: AsyncStatus.NONE,
  resetPasswordReqStat: AsyncStatus.NONE,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE: {
      return { ...state, ...action.payload };
    }

    // RECOVER ACCOUNT
    case actionTypes.RECOVER_ACCOUNT_START:
      return { ...state, recoverAccountReqStat: AsyncStatus.LOADING };

    case actionTypes.RECOVER_ACCOUNT_SUCCESS:
      return {
        ...state,
        recoverError: null,
        recoverAccountReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.RECOVER_ACCOUNT_ERROR:
      return {
        ...state,
        recoverError: action.error,
        recoverAccountReqStat: AsyncStatus.ERROR,
      };

    // RESET PASSWORD
    case actionTypes.RESET_PASSWORD_START:
      return { ...state, resetPasswordReqStat: AsyncStatus.LOADING };

    case actionTypes.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        resetError: null,
        resetPasswordReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.RESET_PASSWORD_ERROR:
      return {
        ...state,
        resetError: action.error,
        resetPasswordReqStat: AsyncStatus.ERROR,
      };

    // SET RESET postPassword
    case actionTypes.SET_RESET_PASSWORD:
      return { ...state, isResetPasswordActive: action.data };

    default:
      return state;
  }
};

export default reducer;
