import fetchData from 'utils/fetchData';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const getIdentity = (id) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._DB_HOST}api/v10/user/${id}`, {
      method: 'GET',
      headers: { ...headers, tsec: JSON.parse(token) },
    }).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};
