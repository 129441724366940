import styled from 'styled-components';

import { style } from 'utils/constants/style';

export const MenuWrap = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
  z-index: 200;
`;

export const MenuItem = styled.div`
  width: 100%;
  height: 44px;
  box-sizing: border-box;
  background-color: ${style.mainWhite.white};
  border-bottom: 1px solid ${style.mainGray.whiteSmokeColor};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  cursor: pointer;

  :hover {
    background-color: ${style.mainGray.snowColor};
  }
`;

export const MenuItemText = styled.p`
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  margin-left: 8px;
`;
