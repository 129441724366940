import React from 'react';

export const TrashIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.8 3.6C9.48174 3.6 9.17652 3.72643 8.95147 3.95147C8.72643 4.17652 8.6 4.48174 8.6 4.8V6H15V4.8C15 4.48174 14.8736 4.17652 14.6485 3.95147C14.4235 3.72643 14.1183 3.6 13.8 3.6H9.8ZM16.6 6V4.8C16.6 4.05739 16.305 3.3452 15.7799 2.8201C15.2548 2.295 14.5426 2 13.8 2H9.8C9.05739 2 8.3452 2.295 7.8201 2.8201C7.295 3.3452 7 4.05739 7 4.8V6H4.8C4.35817 6 4 6.35817 4 6.8V19.8C4 20.5426 4.295 21.2548 4.8201 21.7799C5.3452 22.305 6.05739 22.6 6.8 22.6H16.8C17.5426 22.6 18.2548 22.305 18.7799 21.7799C19.305 21.2548 19.6 20.5426 19.6 19.8V6.8C19.6 6.35817 19.2418 6 18.8 6H16.6ZM15.8 7.6H7.8H5.6V19.8C5.6 20.1183 5.72643 20.4235 5.95147 20.6485C6.17652 20.8736 6.48174 21 6.8 21H16.8C17.1183 21 17.4235 20.8736 17.6485 20.6485C17.8736 20.4235 18 20.1183 18 19.8V7.6H15.8ZM9.7998 10C10.2416 10 10.5998 10.3582 10.5998 10.8V16.8C10.5998 17.2419 10.2416 17.6 9.7998 17.6C9.35798 17.6 8.9998 17.2419 8.9998 16.8V10.8C8.9998 10.3582 9.35798 10 9.7998 10ZM14.5998 10.8C14.5998 10.3582 14.2416 10 13.7998 10C13.358 10 12.9998 10.3582 12.9998 10.8V16.8C12.9998 17.2419 13.358 17.6 13.7998 17.6C14.2416 17.6 14.5998 17.2419 14.5998 16.8V10.8Z"
      fill="#8D8D8D"
    />
  </svg>
);
