import { ILoadingDots } from './ILoadingDots';

import { DotWrapper, Dot } from './LoadingDots.styles';

const LoadingDots = ({ wrapperHeight, dotWidth, dotColor }: ILoadingDots) => (
  <DotWrapper height={wrapperHeight}>
    <Dot animationDelay="0s" dotWidth={dotWidth} dotColor={dotColor} />
    <Dot animationDelay="0.2s" dotWidth={dotWidth} dotColor={dotColor} />
    <Dot animationDelay="0.4s" dotWidth={dotWidth} dotColor={dotColor} />
  </DotWrapper>
);

export default LoadingDots;
