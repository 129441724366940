import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  mr?: number;
  padding?: string;
}

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const ItemWrap = styled.div<IProps>`
  height: 44px;
  padding: ${({ padding }) => padding || '0 28px 0 8px'};
  border-radius: 4px;
  background-color: ${style.mainGray.whiteSmokeColor};
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: ${({ mr }) => (mr ? `${mr}px` : '0')};
`;

export const DateText = styled.div`
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  margin-left: 6px;
`;
