import fetchData from 'utils/fetchData';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const searchEventInvite = ({
  prefix,
  from,
  size,
  q,
  paramsForFilter,
  eventId,
}) => {
  try {
    /* Old request takes &nearby and &brands value
    and the new one takes &filterNearby and &filterBrands
    this could be simplified in case of we choose only one scheme */

    const token = localStorage.getItem('token');

    const paramsForV12Filter = paramsForFilter.replace(
      'nearby',
      'filterNearby'
    );
    const qForV12 = q.replace('&q', '&search');

    return fetchData(
      `${
        process.env._DB_HOST
      }api/v11/event/${eventId}/invite?prefix=${paramsForV12Filter}&from=${
        from || 0
      }&size=${size || 0}`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const searchUserAutocomplete = ({ query }) => {
  try {
    /* Old request takes &nearby and &brands value
    and the new one takes &filterNearby and &filterBrands
    this could be simplified in case of we choose only one scheme */

    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOST}api/v11/autocomplete?prefix=${query}`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
        // body: { prefix: query } as any,
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};
