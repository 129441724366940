import styled from 'styled-components';

import { style, deviceMaxWidth } from 'utils/constants/style';

interface IProps {
  mt?: number;
}

export const SavedRoutesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16px 0;
`;

export const RouteWrap = styled.div`
  width: 100%;
  padding: 10.5px 0 16px 0;
  box-sizing: border-box;
  margin-bottom: 8px;
  border-bottom: 1px solid ${style.mainGray.gainsboroColor};
  cursor: pointer;
`;

export const RouteName = styled.h3`
  font-size: 15px;
  line-height: 100%;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  margin-bottom: 10.5px;
`;

export const WaypointWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const WaypointName = styled.p`
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  margin-left: 6px;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const EmptyScreenWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 200px;
`;

export const EmptyScreenText = styled.p<IProps>`
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
  font-size: 16px;
  line-height: 150%;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: ${style.mainGray.midGray};
`;
