import styled from 'styled-components';

interface IProps {
  isOn?: boolean;
}

export const SwitchCheckbox = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
`;
export const SwitchLabel = styled.label<IProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 50px;
  height: 30px;
  background: ${(props) =>
    !props.isOn ? props.theme.colors.blue : props.theme.colors.neutral.n5};
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;

  &:active {
    width: 50px;
  }
`;
export const SwitchButton = styled.span<IProps>`
  content: '';
  position: absolute;
  /* left: calc(50% - 24px / 2 + 10px); */
  top: calc(50% - 24px / 2);
  width: 24px;
  height: 24px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06);

  ${(props) => {
    if (props.isOn) {
      return `
      left: calc(50% - 24px / 2 - 10px);
    `;
    }
    return `
  left: calc(50% - 24px / 2 + 10px);
  `;
  }}
`;
