import styled from 'styled-components';
import Image from 'next/image';

interface IProps {
  height?: number;
  checked?: boolean;
}
export const DropdownListItem = styled.li`
  font-family: ${(props) => props.theme.typography.fontFamily.primary};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  font-size: ${(props) => props.theme.typography.fontSize.f5};
  color: ${(props) => props.theme.colors.text};
  line-height: 100%;
  letter-spacing: 0.02em;
  border-bottom: 1px solid ${(props) => props.theme.colors.neutral.n3};
  border-top: 1px solid ${(props) => props.theme.colors.neutral.n3};
  margin-bottom: -1px;
`;

export const Pressable = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 10px;
`;

export const Container = styled.div<IProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-around;
`;

export const ImageWrapper = styled.div`
  flex: 0 0 50px;
  margin-left: 10px;
`;

export const imageStyle = {
  width: '50px',
  height: '50px',
  borderRadius: '25px',
};

export const TextContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TextWrapper = styled.span`
  font-family: ${(props) => props.theme.typography.fontFamily.primary};
  font-weight: ${(props) => props.theme.typography.fontWeight.medium};
  font-size: ${(props) => props.theme.typography.fontSize.f3};
  color: ${(props) => props.theme.colors.text};
  line-height: 100%;
  letter-spacing: 0.02em;
`;

export const LogoWrap = styled.div`
  flex: 0 0 50px;
  border-radius: 20px;
`;
export const Logo = styled(Image)`
  background-size: contain;
  border-radius: 20px;
`;

export const CheckIcon = styled.div<IProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background-color: ${({ checked }) => (checked ? '#1a85ff' : 'transparent')};
  border-radius: 50%;
  margin-right: 10px;
`;
