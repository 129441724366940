import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  pt?: number;
  pb?: number;
  mb?: number;
  mt?: number;
}

export const Wrapper = styled.div`
  width: 100%;
`;

export const SectionWrapper = styled.div<IProps>`
  padding-top: ${({ pt }) => (pt ? `${pt}px` : '20px')};
  padding-bottom: ${({ pb }) => (pb ? `${pb}px` : '16.5px')};
  border-bottom: 1px dashed ${style.mainGray.whisperColor};

  :last-of-type {
    border: none;
  }
`;

export const SectionHeader = styled.h3<IProps>`
  font-size: 18px;
  line-height: 130%;
  font-weight: 600;
  color: ${style.mainBlack.black};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '13px')};
`;

export const ErrorMessage = styled.p<IProps>`
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${style.mainRed.cinnabarColor};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
`;

export const TagImageWrap = styled.div`
  height: 26px;
  width: 26px;
  border-radius: 50%;
  overflow: hidden;
`;

export const LocationText = styled.p`
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  margin: -8px 0 31px 8px;
`;
