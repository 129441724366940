import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { useTranslation } from 'react-i18next';

import { LoadingComponent } from 'components';
import {
  ArrowBack,
  SearchIcon,
  CircleWithCheckMark,
  CrossIcon,
  WheelIcon,
} from 'public/assets/icons';
import {
  closeSidebarNewChat,
  openSidebarNewMultipleChat,
  openSidebarChatConversation,
} from 'store/sideBarControler/actions';
import { getFollowersStart, clearFollowersData } from 'store/user/actions';
import {
  createSingleChatStart,
  setSelectedChatId,
  clearSingleChatReqStat,
} from 'store/chat/actions';
import { AsyncStatus } from 'utils/types';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';

import { style } from 'utils/constants/style';
import {
  Wrapper,
  SearchFieldWrap,
  SelectedUsersWrap,
  UserPhoto,
  SelectedUserWrap,
  RemoveSelectedUserIconWrap,
  UsernameText,
  UserWrap,
  UserInfoWrap,
  FullNameText,
  CheckboxWrap,
  EmptyCheckbox,
  NoResultWrap,
  Text,
} from './NewChat.style';
import { InputField, EmptyUserProfilePhoto } from '../../../index';
import { SidebarWrapper } from '../../index';

export const NewChat = () => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(true);

  const { users } = useSelector((state) => state.user);
  const { createdChatInfo, createSingleChatReqStat } = useSelector(
    (state) => state.chat
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const observer = useRef(null);

  const lastSearchElement = useCallback((node) => {
    if (observer.current) observer?.current?.disconnect();
    observer.current = new IntersectionObserver((entires) => {
      if (entires[0].isIntersecting) {
        setOffset((prevState) => prevState + 20);
        console.log('hello');
      }
    });
    if (node) observer.current.observe(node);
  }, []);

  useEffect(() => {
    dispatch(
      getFollowersStart({
        offset: 0,
        search: searchValue,
        limit: 100,
      })
    );
  }, [searchValue, offset]);

  useEffect(() => {
    if (users) {
      setSearchedUsers(users);
      setLoading(false);
    }
  }, [users]);

  useEffect(() => {
    if (createSingleChatReqStat === AsyncStatus.SUCCESS) {
      dispatch(setSelectedChatId(createdChatInfo.chat._id));
      dispatch(clearSingleChatReqStat());
      dispatch(openSidebarChatConversation(createdChatInfo.chat));
      dispatch(closeSidebarNewChat());
    }
  }, [createSingleChatReqStat]);

  useEffect(
    () => () => {
      dispatch(clearFollowersData());
    },
    []
  );

  const onSelectClick = (item) => {
    const newSelectedUsers = selectedUsers.filter((el) => el._id !== item._id);

    if (newSelectedUsers.length === selectedUsers.length) {
      setSelectedUsers([...selectedUsers, item]);
    } else {
      setSelectedUsers([...newSelectedUsers]);
    }
  };

  const onRemoveSelectedUserClick = (id) => {
    setSelectedUsers(selectedUsers.filter((item) => item._id !== id));
  };

  const selectedUsersList = selectedUsers.map((user, index) => {
    const isProfilePhotoExists = user.profilePhoto && user.profilePhoto.trim();

    const isUserPhotoExists = selectedUsers.some(
      (el) => el.profilePhoto && el.profilePhoto.trim()
    );

    return (
      <SelectedUserWrap
        key={`${user._id}_${index}`}
        ml={index === 0 && 33}
        top={isUserPhotoExists && !isProfilePhotoExists ? -13 : 0}
      >
        {isProfilePhotoExists ? (
          <UserPhoto withBorder img={user.profilePhoto} mb={4}>
            <span role="img" aria-label="user profile img" />

            <RemoveSelectedUserIconWrap
              className="selected-item-remove-icon"
              onClick={() => onRemoveSelectedUserClick(user._id)}
            >
              <CrossIcon
                color={style.mainGray.SuvaGrey}
                width="16"
                height="16"
              />
            </RemoveSelectedUserIconWrap>
          </UserPhoto>
        ) : (
          <EmptyUserProfilePhoto
            wrapSize={52}
            userIconSize={26}
            margin="0 0 4px 0"
            icon={
              <RemoveSelectedUserIconWrap
                className="selected-item-remove-icon"
                onClick={() => onRemoveSelectedUserClick(user._id)}
              >
                <CrossIcon
                  color={style.mainGray.SuvaGrey}
                  width="16"
                  height="16"
                />
              </RemoveSelectedUserIconWrap>
            }
          />
        )}

        <UsernameText lh={120} className="selected-item-username">
          {user.username}
        </UsernameText>
      </SelectedUserWrap>
    );
  });

  const renderUsersList = () => {
    if (loading) {
      return <LoadingComponent fullWidth color="#C2C2C2" variant="spin" />;
    }

    const searchedUsersList = searchedUsers.map((user, index) => (
      <UserWrap
        key={`${user._id}_${index}`}
        onClick={() => onSelectClick(user)}
        ref={lastSearchElement}
      >
        <CheckboxWrap>
          {selectedUsers.map((el) => el._id).includes(user._id) ? (
            <CircleWithCheckMark />
          ) : (
            <EmptyCheckbox />
          )}
        </CheckboxWrap>
        {user.profilePhoto && user.profilePhoto.trim() ? (
          <UserPhoto
            img={user.profilePhoto}
            width={48}
            height={48}
            ml={5}
            mr={10}
          />
        ) : (
          <EmptyUserProfilePhoto
            wrapSize={48}
            userIconSize={24}
            margin="0 10px 0 5px"
            withBorder={false}
          />
        )}
        <UserInfoWrap>
          <UsernameText mb={4}>{user.username}</UsernameText>
          <FullNameText>{user.fullName}</FullNameText>
        </UserInfoWrap>
      </UserWrap>
    ));

    return searchedUsersList?.length ? (
      searchedUsersList
    ) : (
      <NoResultWrap>
        <Text mb={42}>{t('chat:nothingFound')}</Text>
        <WheelIcon />
      </NoResultWrap>
    );
  };

  const renderSelectedUsers = () => (
    <SelectedUsersWrap>
      <ScrollMenu
        data={selectedUsersList}
        wrapperStyle={{
          width: '100%',
          textAlign: 'left',
          display: 'flex',
          alignItems: 'center',
        }}
        alignCenter={false}
        itemStyle={{ border: 'none', outline: 'none' }}
      />
    </SelectedUsersWrap>
  );

  const onCreateClick = () => {
    if (selectedUsers.length === 1) {
      const userId = selectedUsers[0]._id;

      dispatch(createSingleChatStart(userId));
    } else {
      dispatch(openSidebarNewMultipleChat({ selectedUsers }));
    }
  };

  const onChangeSearchInput = (e) => {
    setSearchValue(e.target.value);
    setSearchedUsers([]);
    setOffset(0);
    setLoading(true);
  };

  return (
    <SidebarWrapper
      headerText={t('chat:newChatBtnTitle')}
      headerIcon={<ArrowBack />}
      submitText={t('chat:createChat')}
      isSubmitAvailable={!!selectedUsers.length}
      onSubmit={onCreateClick}
      onIconClick={() => dispatch(closeSidebarNewChat())}
      contentPadding="0 0 20px 0"
    >
      <Wrapper>
        <SearchFieldWrap>
          <InputField
            value={searchValue}
            onChange={onChangeSearchInput}
            iconLeft={<SearchIcon colorStroke={style.mainGray.silver} />}
          />
        </SearchFieldWrap>
        {!!selectedUsers.length && renderSelectedUsers()}
        {renderUsersList()}
      </Wrapper>
    </SidebarWrapper>
  );
};
