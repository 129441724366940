/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { ICreateEventPostForm } from './ICreateEventPostForm';
import StepOne from './StepOne';
import StepTwo from './StepTwo';

import {
  FinalFormWrapper,
  FormWrapper,
  SelectVehicleWrapper,
  StepNumber,
  StepNumberWrap,
} from './CreateEventPostForm.styles';
import SelectVehicle from './SelectVehicle';

const CreateEventPostForm = (props: ICreateEventPostForm) => {
  const [stepNumber, setStepNumber] = useState<number>(1);

  const { t } = useTranslation();

  const [data, setData] = useState<any>({
    brands: null,
    collaborators: null,
    location: null,
  });

  return (
    <>
      <FinalFormWrapper
        {...props}
        render={(formRenderProps: FormRenderProps) => {
          const { handleSubmit, form, values, initialValues } = formRenderProps;
          const { inProgress, ready, onIconClick, variant = 'create' } = props;
          const isReadyStepOne =
            values?.eventName &&
            values?.interests?.length &&
            values?.location &&
            values?.coverPhoto;

          const isReadyStepTwo = values?.eventDetails?.length > 0;

          const formTitle =
            variant === 'create' ? 'event:createEvent' : 'event:EditEvent';

          return (
            <>
              <FormWrapper
                onSubmit={async (e) => {
                  if (variant === 'create') {
                    e.preventDefault();
                    if (stepNumber === 1) {
                      setStepNumber((prev) => prev + 1);
                    } else if (stepNumber === 2) {
                      setStepNumber((prev) => prev + 1);
                    } else if (values?.vehicle) {
                      await handleSubmit();
                    }
                  } else {
                    e.preventDefault();
                    if (stepNumber === 2) {
                      e.preventDefault();
                      await handleSubmit();
                    } else {
                      e.preventDefault();
                      setStepNumber((prev) => prev + 1);
                    }
                  }
                }}
                onIconClick={() => {
                  if (stepNumber === 1) {
                    onIconClick();
                  } else {
                    setStepNumber((prev) => prev - 1);
                  }
                }}
                isHeaderBorder
                isCross={stepNumber === 1}
                headerText={
                  stepNumber === 1 ? t(formTitle) : t('event:optionalInfo')
                }
                submitText={
                  stepNumber === 1
                    ? t('common:nextText')
                    : t('editUserProfile:save')
                }
                disabled={
                  !!(
                    (stepNumber === 1 && !isReadyStepOne) ||
                    (stepNumber === 2 && !isReadyStepTwo)
                  )
                }
              >
                <StepNumberWrap>
                  <StepNumber mr={8} isSelectedStep={stepNumber === 1} />
                  <StepNumber mr={8} isSelectedStep={stepNumber === 2} />
                  {variant === 'create' && (
                    <StepNumber isSelectedStep={stepNumber === 3} />
                  )}
                </StepNumberWrap>
                {(stepNumber === 1 && (
                  <StepOne
                    form={form}
                    data={data}
                    initialValues={initialValues}
                  />
                )) ||
                  (stepNumber === 2 && <StepTwo form={form} initialValues={initialValues} />) ||
                  (stepNumber === 3 && (
                    <SelectVehicleWrapper>
                      <SelectVehicle stepNumber={3} />
                    </SelectVehicleWrapper>
                  ))}
              </FormWrapper>
            </>
          );
        }}
      />
    </>
  );
};

export default CreateEventPostForm;
