import styled from 'styled-components';

interface IProps {
  active?: boolean;
}

export const NavigationButtonWrapper = styled.button<IProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.active ? 'rgba(17, 136, 255, 0.05)' : 'transparent'};
  border-color: transparent;
  cursor: pointer;
  padding: 4px 16px;
  margin-right: 16px;
  border-radius: 8px;
  transition: all 0.3s;

  &:hover {
    background: ${(props) => props.theme.colors.neutral.n3};
    transition: all 0.3s;

    & > span {
      &:nth-child(2) {
        display: block;
      }
    }
  }
`;
export const IconWrap = styled.span<IProps>`
  & > svg {
    & > path {
      stroke: ${(props) => props.active && props.theme.colors.blue};
    }
    & > circle {
      stroke: ${(props) => props.active && props.theme.colors.blue};
    }
  }
`;
export const TextWrap = styled.span`
  display: none;
  position: absolute;
  bottom: -30px;
  font-family: ${(props) => props.theme.typography.fontFamily.primary};
  font-size: ${(props) => props.theme.typography.fontSize.f2};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.colors.white};
  letter-spacing: 0.02em;
  line-height: 100%;
  padding: 6px 12px;
  background-color: rgba(28, 31, 41, 0.9);
  border-radius: 4px;
`;
