import React from 'react';
import { style } from 'utils/constants/style';

export const EmptyGroupIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.15 11.028C20.6846 9.183 18.4217 8 15.8823 8C13.3429 8 11.0798 9.183 9.61414 11.028H1.70085C1.48815 11.028 1.28822 11.1295 1.16268 11.3012C1.03715 11.4729 1.00108 11.6942 1.06559 11.8969L4.01312 21.1568C4.10116 21.4334 4.35809 21.6213 4.64838 21.6213H10.1901C11.6406 23.0899 13.6552 24 15.8823 24C18.1094 24 20.1238 23.0899 21.5741 21.6213H27.2772C27.5675 21.6213 27.8244 21.4334 27.9125 21.1568L30.86 11.8969C30.9245 11.6942 30.8884 11.4729 30.7629 11.3012C30.6373 11.1295 30.4374 11.028 30.2247 11.028H22.15ZM23.0087 12.3613C23.567 13.4528 23.8819 14.6894 23.8819 15.9996C23.8819 17.5774 23.4252 19.0485 22.6368 20.288H26.7898L29.3129 12.3613H23.0087ZM2.61268 12.3613H8.7553C8.19691 13.4528 7.88191 14.6894 7.88191 15.9996C7.88191 17.5774 8.33869 19.0485 9.12722 20.288H5.1358L2.61268 12.3613Z"
      fill={style.mainBlue.blue}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.8853 16.1839H16.227C16.6035 16.1839 16.8943 16.0927 17.1 15.9111C17.3047 15.7294 17.4076 15.467 17.4076 15.1229C17.4076 14.7916 17.3047 14.537 17.1 14.3583C16.8943 14.1801 16.6035 14.0913 16.227 14.0913H14.8853V16.1839ZM17.2856 19.493L16.2563 17.6049H16.2159H14.8843V19.493H13.0381V12.6699H16.2159C17.1507 12.6699 17.8756 12.8792 18.3902 13.2976C18.9049 13.7161 19.1622 14.3051 19.1622 15.0643C19.1622 15.5769 19.0545 16.0199 18.8393 16.393C18.6238 16.7657 18.3111 17.0566 17.901 17.2642L19.3738 19.493H17.2856Z"
      fill={style.mainWhite.white}
    />
  </svg>
);
