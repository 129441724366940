/* eslint-disable react/destructuring-assignment */
import { FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import {
  composeValidators,
  minLength,
  required,
  userNameValid,
  validateGroupUsername,
} from 'utils/validators';
import FieldLocation from 'components/common/FieldLocation';
import Input from 'components/common/Input';
import { InputDescriptionField } from 'components';
import GroupLocalityTypesField from 'components/GroupLocalityTypes';
import PrivacyDrawerField from 'containers/PrivacyDrawer';
import PermissionDrawerField from 'containers/PermissionDrawer';
import { IGroupForm } from './IGroupForm';
import Interests from './interests';
import Brands from './Brands';
import Collaborators from './Collaborators';
import Admins from './Admins';
import GroupPrivate from './GroupPrivate';
import GroupBroadcast from './GroupBroadcast';
import {
  CoverImageUploader,
  FinalFormWrapper,
  FormWrapper,
  ImageUploaderWrapper,
  ProfileImageUploader,
  SectionHeader,
  SectionWrapper,
} from './GroupForm.styles';
import GroupChat from './GroupChat';

const GroupForm = (props: IGroupForm) => {
  const { t } = useTranslation();

  return (
    <FinalFormWrapper
      {...props}
      render={(formRenderProps: FormRenderProps) => {
        const { handleSubmit, values, form, initialValues } = formRenderProps;

        const { inProgress, ready, onIconClick, variant = 'create' } = props;
        return (
          <FormWrapper
            onSubmit={handleSubmit}
            onIconClick={onIconClick}
            isHeaderBorder
            headerText={
              variant === 'create'
                ? t('myGroups:createGroup')
                : t('addNewGroup:editGroup')
            }
            submitText={t('editUserProfile:save')}
            isCross
          >
            <ImageUploaderWrapper>
              <CoverImageUploader
                name="coverPhoto"
                isOnePhoto
                label="Add a photo"
              />
              <ProfileImageUploader
                name="profilePhoto"
                isProfileImages
                isOnePhoto
              />
            </ImageUploaderWrapper>

            <SectionWrapper pt={40}>
              <SectionHeader type="h3">{t('event:requiredInfo')}</SectionHeader>
              <Input
                id="groupUsername"
                name="groupUsername"
                type="text"
                label={t('addNewGroup:groupUsername')}
                placeholder={t('addNewGroup:groupUsername')}
                validate={composeValidators(
                  required(t('error:thisFieldIsRequired')),
                  userNameValid(t('error:vaildUsername')),
                  minLength(t('error:atLeastThree'), 3),
                  validateGroupUsername(initialValues?.groupUsername)
                )}
              />
              <Input
                id="groupFullname"
                name="groupFullname"
                type="text"
                label={t('addNewGroup:groupFullName')}
                placeholder={t('addNewGroup:groupFullName')}
                validate={composeValidators(
                  required(t('error:thisFieldIsRequired')),
                  minLength(t('error:atLeastThree'), 3)
                )}
              />
              <Interests />
              <SectionHeader fs={16} fw={500}>
                {t('addNewGroup:groupLocality')}
              </SectionHeader>
              <GroupLocalityTypesField name="locality" />
            </SectionWrapper>

            <SectionWrapper>
              <SectionHeader type="h3">
                {t('addNewGroup:optionalInfo')}
              </SectionHeader>
              <Brands />

              <InputDescriptionField
                placeholder={t('common:addADescription')}
                label={t('addNewGroup:addShortBioOrDescription')}
                name="description"
                showCharactersNumber
                maxLength={500}
                height={88}
                validate={composeValidators(
                  required(t('error:thisFieldIsRequired'))
                )}
              />
              <FieldLocation
                name="location"
                label={t('addNewGroup:cityOrLocation')}
                headerText={t('map:tagLocation')}
                validate={composeValidators(
                  required(t('error:thisFieldIsRequired'))
                )}
              />
              <Input
                id="groupWebsite"
                name="groupWebsite"
                type="text"
                label={t('addNewGroup:groupWebsite')}
                placeholder={t('addNewGroup:groupWebsite')}
              />
              <Admins />
              <Collaborators />
            </SectionWrapper>
            <SectionWrapper>
              <PrivacyDrawerField
                name="privacy"
                privacy={initialValues?.privacy}
              />
              <PermissionDrawerField name="permission" />
              <GroupChat />
              <GroupPrivate privateKey={initialValues?.private}/>
              <GroupBroadcast broadcast={initialValues?.broadcast}/>
            </SectionWrapper>
          </FormWrapper>
        );
      }}
    />
  );
};

export default GroupForm;
