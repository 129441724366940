import { useTranslation } from 'react-i18next';

import Heading from 'components/common/Typography/Heading';
import { CalendarIcon } from 'public/assets/icons';
import SidebarListItem from 'components/SidebarListItem';
import SidebarListItemSkeleton from 'components/SidebarListItemSkeleton';
import Image from 'components/common/Image';
import Fetch from 'components/Fetch';
import { ISidebarMyGroups } from './ISidebarMyGroups';

import {
  PlaceHolderWrapper,
  SidebarMyGroupsList,
  SidebarMyGroupsWrapper,
  TitleIcon,
  TitleWrapper,
} from './SidebarMyGroups.styles';

const SidebarMyGroups = ({
  loading = true,
  listItems = [],
}: ISidebarMyGroups) => {
  const { t } = useTranslation();

  return (
    <SidebarMyGroupsWrapper>
      <TitleWrapper>
        <TitleIcon>
          <CalendarIcon />
        </TitleIcon>
        <Heading type="h6">{t('myGroups:headerTitle')}</Heading>
      </TitleWrapper>
      <SidebarMyGroupsList>
        <Fetch
          loading={loading}
          skeleton={
            <>
              <SidebarListItemSkeleton />
              <SidebarListItemSkeleton />
              <SidebarListItemSkeleton />
            </>
          }
          dataLength={listItems?.length}
          empty={
            <PlaceHolderWrapper>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Image
                  alt="empty placeholder"
                  src="/assets/img/empty-events.svg"
                  width={72}
                  height={72}
                  unoptimized
                  priority
                />
                <div>{t('myEvents:placeholder')}</div>
              </div>
            </PlaceHolderWrapper>
          }
        >
          <>
            {listItems?.map((item) => (
              <SidebarListItem
                key={item?._id}
                id={item?._id}
                imageSrc={item?.coverPhoto}
                name={item?.name}
                userName={item?.username}
                variant="my_groups"
              />
            ))}
          </>
        </Fetch>
      </SidebarMyGroupsList>
    </SidebarMyGroupsWrapper>
  );
};

export default SidebarMyGroups;
