import React from 'react';

import { IAutoCloseModal } from './IAutoCloseModal';
import { Wrapper, ModalText } from './AutoCloseModal.style';

export const AutoCloseModal = ({ text, delay = 3 }: IAutoCloseModal) => (
  <Wrapper delay={delay}>
    <ModalText>{text}</ModalText>
  </Wrapper>
);
