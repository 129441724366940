import styled from 'styled-components';

/* eslint-disable no-nested-ternary */
import { style, deviceMaxWidth } from 'utils/constants/style';

interface IProps {
  isSelected?: boolean;
  origin?: 'event' | 'create_event' | 'group' | 'profile' | 'car' | 'link';
  newCarPost?: boolean;
  isDark?: boolean;
  height?: number;
  colapsed?: boolean;
  following?: boolean;
  eventPost?: boolean;
  event?: boolean;
  type?: number;
  variant?: string;
  isSliderUsed?: boolean;
  fs?: number;
  width?: number;
  mr?: number;
  mb?: number;
  ml?: number;
  pr?: number;
  pl?: number;
  fw?: number;
  img?: string;
  padding?: string;
  isClickable?: boolean;
  color?: string;
  isRounded?: boolean;
  isCollaboratorsExists?: boolean;
  withBorder?: boolean;
  isEventHomeTab?: boolean;
}

export const PostComponentWrapper = styled.div<IProps>`
  height: auto;
  max-width: 550px;
  width: 100%;
  margin: 0 auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  /* overflow: hidden; */
  background: white;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin-bottom: 16px;

  @media only screen and (${deviceMaxWidth.sm}) {
    margin: 0 auto;
    width: 100%;
    max-width: 550px;
    margin-bottom: 16px;
  }
`;

export const HederPost = styled.div`
  max-width: 80%;

  @media only screen and (${deviceMaxWidth.sm}) {
    flex: 1;
    box-sizing: border-box;
    padding-right: 40px;
  }
`;

export const UserIconWrap = styled.div<IProps>`
  width: 16px;
  height: 16px;
  margin-left: 8px;
  margin-right: ${({ mr }) => (mr ? `${mr}px` : '0')};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UserPic = styled.div<IProps>`
  min-width: 48px;
  width: 48px;
  height: 48px;
  margin-right: 16px;
  overflow: hidden;
  border-radius: 50%;
  cursor: pointer;
  box-sizing: border-box;

  ${({ type }) =>
    type !== 1 &&
    type !== 3 &&
    `border: 1.5px solid ${style.mainWhite.white}; filter: drop-shadow(0.75px 0.75px 3.75px rgba(0, 0, 0, 0.15));`}
`;

export const UserNameBlock = styled.div<IProps>`
  font-family: Poppins;
  font-style: normal;
  font-weight: ${({ fw }) => fw || 600};
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  margin-right: ${({ mr }) => (mr ? `${mr}px` : 0)};
  margin-left: ${({ ml }) => (ml ? `${ml}px` : 0)};
`;

export const Dot = styled.div<IProps>`
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: ${({ color }) => color || style.mainGray.silver};
  margin-left: ${({ ml }) => (ml ? `${ml}px` : '0')};
  margin-right: ${({ mr }) => (mr ? `${mr}px` : '0')};
`;
export const FollowingIndicator = styled.div<IProps>`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: ${(props) => (props.following ? '#5C5C5C' : style.mainYellow.yellow)};
`;

export const BlockUserInfo = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  cursor: pointer;
  min-height: 24px;
  margin-bottom: 4px;
`;

export const BlockPostTime = styled.div<IProps>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: ${({ type }) => (type === 4 ? '4px' : '1.5px')};

  span {
    display: block;

    ${({ type }) => {
      if (type === 4) {
        return `font-size: 14px; line-height: 150%; font-weight: 600; color: ${style.mainWhite.white}; padding: 0 12px; border-radius: 40px; background-color: ${style.mainBlue.blue}; display: flex; align-items: center; height: 24px;`;
      }

      return `font-size: 13px; line-height: 100%; color: ${style.mainBlack.black};`;
    }}
  }

  p {
    font-size: 13px;
    line-height: 100%;
    color: ${style.mainGray.SuvaGrey};
  }
`;

export const BlockGeolocation = styled.div<IProps>`
  display: flex;
  align-items: center;
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
  padding-right: ${({ pr }) => (pr ? `${pr}px` : '0')};
  padding-left: ${({ pl }) => (pl ? `${pl}px` : '0')};

  p {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: ${({ fs }) => (fs ? `${fs}px` : '13px')};
    line-height: 123%;
    color: ${({ color }) => color || style.mainBlue.CornflowerBlue};
    letter-spacing: 0.02em;
    margin-left: ${({ ml }) => (ml ? `${ml}px` : '0')};
  }
`;

export const EventCreatorWrap = styled.div<IProps>`
  display: flex;
  align-items: center;
  margin-bottom: ${({ isEventHomeTab }) => (isEventHomeTab ? '0' : '16px')};
  cursor: pointer;
  align-self: flex-start;
`;

export const EventCreatorImageWrap = styled.span<IProps>`
  display: block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  overflow: hidden;
  background: url(${({ img }) => img}) no-repeat;
  background-size: cover;
  background-position: center;
  margin-right: 8px;
`;

export const EventCreatorName = styled.p<IProps>`
  font-size: 13px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${({ color }) => color || style.mainGray.SuvaGrey};
  margin-left: ${({ ml }) => (ml ? `${ml}px` : '0')};
`;

export const EventAttendingWrap = styled(EventCreatorWrap)`
  cursor: auto;
`;

export const EventAttendingItem = styled.div<IProps>`
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};
  margin-right: ${({ mr }) => (mr ? `${mr}px` : '0')};
  margin-left: ${({ ml }) => (ml ? `${ml}px` : '0')};
  line-height: 100%;
`;

export const EventAttendingText = styled(EventCreatorName)`
  display: inline-block;
`;

export const ActionsPostBlock = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  top: 50%;
  margin-top: -12px;
  right: 16px;

  div {
    cursor: pointer;
    width: 24px;
    height: 24px;
    transform: rotate(-90deg);
  }
`;

export const CoverImg = styled.div`
  width: 100%;
  height: auto;
`;
export const CarouselDots = styled.li<IProps>`
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.isSelected ? style.mainYellow.yellow : style.mainGray.gainsboroColor};
  margin-right: 4px;
  box-sizing: content-box;

  :last-of-type {
    margin-right: 0;
  }
`;

export const ArrowPrev = styled.div`
  position: absolute;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  /* padding-right: 3px; */
  top: 50%;
  left: 24px;
  z-index: 50;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: rgba(0, 0, 0, 0.5);
  margin-top: -16px;

  @media only screen and (${deviceMaxWidth.sm}) {
    left: 10px;
  }

  svg {
    transform: rotate(180deg);
  }
`;
export const ArrowNext = styled.div`
  position: absolute;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  /* margin-left: 10px; */
  /* padding-left: 1px; */
  top: 50%;
  right: 24px;
  z-index: 50;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: rgba(0, 0, 0, 0.5);
  margin-top: -16px;

  @media only screen and (${deviceMaxWidth.sm}) {
    right: 10px;
  }
`;

export const SocialActivityBlock = styled.div<IProps>`
  width: 550px;

  @media only screen and (${deviceMaxWidth.sm}) {
    width: 100%;
  }
`;

export const SocialActivityAction = styled.div<IProps>`
  width: 100%;
  box-sizing: border-box;
  padding: ${({ padding }) => padding || '16px'};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: ${({ withBorder }) =>
    withBorder ? `1px solid ${style.mainGray.snowColor}` : 'none'};
  border-top: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SocialButtonsContainer = styled.div`
  height: 24px;
`;

export const SocialButtonContainer = styled.div<IProps>`
  display: inline-flex;
  align-items: center;
  position: relative;
  margin-left: ${({ ml }) => (ml ? `${ml}px` : '0')};
  cursor: pointer;
`;

export const SocialButtonsText = styled.p<IProps>`
  font-size: 13px;
  line-height: 130%;
  color: ${style.mainBlack.black};
  margin-left: ${({ ml }) => (ml ? `${ml}px` : '0')};
`;

export const OutlinedLikeIconWrap = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${style.mainYellow.sunshade};
`;

export const WheelMarkIconWrap = styled.div`
  width: 100%;
  max-width: 550px;

  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 55%,
    rgba(28, 31, 41, 0.2) 100%
  );
  background-color: ${style.mainGray.whisperColor};
`;

export const WrapPopover = styled.div`
  position: absolute;
  z-index: 100;
  top: 45px;
  right: 12px;
`;

export const MediaWrap = styled.div<IProps>`
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};

  .carousel-root {
    position: relative;
    width: 100%;

    .carousel-slider {
      position: static;
      display: flex;
      justify-content: center;
    }

    .control-dots {
      position: absolute;
      width: auto;
      box-sizing: border-box;
      padding: 0 16px;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 8px;
      height: 24px;
      display: ${({ isSliderUsed }) => (isSliderUsed ? 'flex' : 'none')};
      align-items: center;
    }
    .slider-wrapper {
      padding: 0;
      overflow: hidden;
      background: none;

      ::-webkit-scrollbar {
        width: 0;
        background: transparent;
      }

      ::-webkit-scrollbar-thumb {
        width: 0;
        background: transparent;
      }
    }

    /* @media only screen and (${deviceMaxWidth.sm}) {
      max-height: 550px;
    } */
  }

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
`;

export const LinkTitle = styled.div<IProps>`
  width: 100%;
  font-size: 18px;
  line-height: 130%;
  font-weight: 600;
  color: ${style.mainBlack.black};
`;

export const LinkText = styled.p`
  width: 100%;
  font-size: 15px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: ${style.mainGray.SuvaGrey};
  text-transform: uppercase;
  margin-top: 4px;
`;

export const LinkImageBackground = styled.div`
  background: url(/assets/img/tireTrace.png) no-repeat;
  background-size: cover;
  background-position: center;
  max-height: 366px;
`;

export const LinkWrap = styled.a`
  display: block;
  max-width: 550px;
  cursor: pointer;
  text-decoration: none;
`;

export const EventBlock = styled.div<IProps>`
  position: relative;
  overflow: hidden;
  max-height: 366px;

  ${({ isRounded }) =>
    isRounded && `border-top-right-radius: 8px; border-top-left-radius: 8px;`}
`;

export const EventDetailsBlock = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  border-right: 1px solid ${style.mainGray.snowColor};
  border-left: 1px solid ${style.mainGray.snowColor};

  ${({ isCollaboratorsExists }) => {
    if (isCollaboratorsExists) {
      return `border-bottom: 1px solid ${style.mainGray.whisperColor}`;
    }

    return `border-bottom: 1px solid ${style.mainGray.snowColor}; border-bottom-right-radius: 8px; border-bottom-left-radius: 8px;`;
  }};
`;

export const EventButtons = styled.div<IProps>`
  display: flex;
  width: 100%;

  button {
    margin-right: 16px;

    :last-of-type {
      margin-right: 0;
    }
  }
`;

export const CarInfoBlock = styled.div<IProps>`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};
  padding: 16px;
  border-right: 1px solid ${style.mainGray.snowColor};
  border-left: 1px solid ${style.mainGray.snowColor};
  border-bottom: 1px solid ${style.mainGray.whisperColor};

  div {
    font-size: 18px;
    line-height: 100%;
    font-weight: 600;
    color: ${style.mainBlack.black};
  }

  span {
    margin-top: 8px;
    font-size: 15px;
    line-height: 150%;
    color: ${style.mainBlack.black};
  }
`;

export const DescriptionWrap = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  border-right: 1px solid ${style.mainGray.snowColor};
  border-left: 1px solid ${style.mainGray.snowColor};
  border-top: 1px solid ${style.mainGray.whisperColor};
  padding: 16px 0;

  div {
    :nth-of-type(n) {
      margin-bottom: 16px;
    }

    :last-of-type {
      margin-bottom: 0;
    }
  }

  .collapsible_tags {
    margin-bottom: 8px !important;
  }
`;

export const LinkBlockWrap = styled.div`
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  border-right: 1px solid ${style.mainGray.snowColor};
  border-left: 1px solid ${style.mainGray.snowColor};
  border-bottom: 1px solid ${style.mainGray.whisperColor};
`;

export const LoadingDotsWrap = styled.div`
  width: 50px;
  position: relative;
  bottom: 4px;
`;

export const EventPeriod = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-left: 8px;
    font-size: 13px;
    line-height: 100%;
    color: ${style.mainGray.SuvaGrey};
  }
`;

export const EventNameText = styled.p`
  font-size: 18px;
  line-height: 100%;
  font-weight: 600;
  color: ${style.mainBlack.black};
  margin: 16px 0;
`;

export const GroupCoverPhoto = styled.div<IProps>`
  cursor: pointer;
  width: 48px;
  min-width: 48px;
  height: 48px;
  border: 1.5px solid ${style.mainWhite.white};
  border-radius: 50%;
  box-sizing: border-box;
  margin-right: 16px;
  background: url(${({ img }) => img}) no-repeat;
  background-size: cover;
  background-position: center;
`;

export const GroupDescription = styled.div`
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
  line-height: 100%;
  color: ${style.mainBlack.black};
  display: flex;
  align-items: center;
  margin-bottom: 6px;

  svg {
    padding-left: 8px;
  }
`;

export const GroupDescriptionName = styled.div`
  font-size: 15px;
  line-height: 150%;
  color: ${style.mainBlack.black};
`;

export const CollaboratorsWrap = styled.div<IProps>`
  width: 100%;
  box-sizing: border-box;
  padding: 16px 6px 6px 6px;
  border: 1px solid ${style.mainGray.snowColor};
  border-top: none;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
`;

export const CollaboratorsHeading = styled.div`
  width: 100%;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 130%;
  color: ${style.mainBlack.black};
  font-weight: 500;
  padding-left: 10px;
`;

export const ThreeDotsWrap = styled.div`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 12px;
  right: 16px;
  cursor: pointer;
  z-index: 2;
  transform: rotate(90deg);
  background: rgba(24, 27, 37, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CanceledEventText = styled.p`
  width: 100%;
  height: 40px;
  margin-right: 15px;
  background-color: ${style.mainGray.whiteSmokeColor};
  font-size: 20px;
  line-height: 100%;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  font-weight: 500;
  color: ${style.mainBlack.black};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MessageButtonWrap = styled.div`
  width: 40px;
  padding: 0 !important;
  margin-left: 16px;

  div {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding: 0;
    width: 40px;
  }
`;

// POST HEADER
export const PostHeaderWrap = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border: 1px solid ${style.mainGray.snowColor};
  border-bottom: none;
`;

export const PostHeaderInfoWrap = styled.div`
  display: flex;
  align-items: center;
`;

// GROUp HEADER
export const GroupHeaderWrap = styled.div<IProps>`
  width: 100%;
  display: flex;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};
  border-right: 1px solid ${style.mainGray.snowColor};
  border-left: 1px solid ${style.mainGray.snowColor};

  ${({ isCollaboratorsExists }) => {
    if (isCollaboratorsExists) {
      return `border-bottom: 1px solid ${style.mainGray.whisperColor}`;
    }

    return `border-bottom: 1px solid ${style.mainGray.snowColor}; border-bottom-right-radius: 8px; border-bottom-left-radius: 8px;`;
  }}
`;

export const GroupNamesWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CarouselWrap = styled.div<IProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #000000;
`;

export const ClickableArea = styled.div<IProps>`
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};
`;
