import styled from 'styled-components';

interface IProps {
  height?: number | string;
  backgroundColor?: string;
  wrapperHeight?: string;
  mb?: number;
  fullWidth?: boolean;
  objectFit?: string;
  rounded?: boolean;
  isPhotoPost?: boolean;
}

export const ImagePreviewWrapper = styled.div<IProps>`
  position: relative;
  width: 100%;
  height: ${({ wrapperHeight }) => wrapperHeight || '160px'};
  max-height: ${({ height }) => (height ? `${height}px` : '360px')};
  border-radius: 6px;
  display: flex;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.colors.neutral.n0};
  justify-content: center;
`;

export const UploadedImage = styled.img<IProps>`
  width: auto;
  object-fit: ${({ objectFit }) => objectFit || 'cover'};
  border-radius: ${({ rounded }) => (rounded ? '50%' : '6px')};
  max-width: 100%;
`;
