import React from 'react';

import { LineWrap, Line } from './VerticalDashedLine.style';

const VerticalDashedLine = ({
  margin = '4px 0 4px 11px',
}: {
  margin?: string;
}) => (
  <LineWrap margin={margin}>
    <Line />
    <Line />
  </LineWrap>
);

export default VerticalDashedLine;
