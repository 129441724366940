import ReactDom from 'react-dom';
import { useEffect } from 'react';

import { IPagesSkeletonModal } from './IPagesSkeletonModal';

import {
  ContentWrapper,
  ModalContainer,
  ModalOverlay,
  ModalWrapper,
} from './PagesSkeletonModal.styles';

const PagesSkeletonModal = ({
  open,
  children,
  className,
}: IPagesSkeletonModal) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'unset';
      document.body.style.overflowX = 'hidden';
    };
  }, [open]);

  const modalContent = open ? (
    <ModalOverlay>
      <ModalWrapper className={className} onClick={(e) => e.stopPropagation()}>
        <ModalContainer>
          <ContentWrapper>{children}</ContentWrapper>
        </ModalContainer>
      </ModalWrapper>
    </ModalOverlay>
  ) : null;

  if (typeof window !== 'undefined') {
    return ReactDom.createPortal(
      modalContent,
      document.getElementById('modal-root')
    );
  }
  return null;
};

export default PagesSkeletonModal;
