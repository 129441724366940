import styled from 'styled-components';

interface IProps {
  isSidebarOpen?: boolean;
  isConformationModalOpen?: boolean;
}

export const MobileLoginHeaderWrapper = styled.ul<IProps>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${(props) =>
    (props.isSidebarOpen && '0') ||
    (props.isConformationModalOpen && '100') ||
    '200'};
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  background: ${(props) => props.theme.colors.white};
`;

export const HeaderMobileNavItem = styled.li`
  cursor: pointer;
  list-style-type: none;
`;

export const NavItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
