import React from 'react';

export const StarIcon = ({ color = '#A9A9B5' }: { color?: string }) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.3947 16.7968L10.5 15.4117L6.60502 16.7968C6.3638 16.8826 6.09558 16.8438 5.88834 16.6933C5.68111 16.5425 5.56126 16.2995 5.56823 16.0434L5.68202 11.9111L3.16122 8.63482C3.00496 8.43214 2.95884 8.16482 3.03803 7.92118C3.11722 7.67753 3.31141 7.48881 3.55718 7.41629L7.52225 6.24752L9.85918 2.8374C10.0042 2.62622 10.2439 2.5 10.5 2.5C10.7561 2.5 10.9958 2.62622 11.1408 2.8374L13.4777 6.24752L17.4428 7.41629C17.6886 7.48881 17.8828 7.67753 17.962 7.92118C18.0412 8.16482 17.995 8.43183 17.8388 8.63482L15.318 11.9114L15.4318 16.0434C15.4387 16.2995 15.3189 16.5428 15.1117 16.6933C14.9772 16.791 14.8167 16.8417 14.655 16.8417C14.5676 16.8417 14.4796 16.8268 14.3947 16.7968Z"
      fill={color}
    />
  </svg>
);
