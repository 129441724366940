import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  top?: number;
  left?: number;
}

export const ListWrap = styled.div<IProps>`
  width: 320px;
  max-height: 245px;
  box-sizing: border-box;
  overflow: auto;
  border-radius: 8px;
  background-color: ${style.mainWhite.white};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  position: absolute;
  top: ${({ top }) => (typeof top === 'number' ? `${top}px` : '-13px')};
  left: ${({ left }) => (typeof left === 'number' ? `${left}px` : '-1px')};
  padding: 8px;

  ::-webkit-scrollbar-track {
    background: none;
  }
  ::-webkit-scrollbar {
    width: 4px;
    height: 100px;
    background: none;
  }
  ::-webkit-scrollbar-thumb {
    background: ${style.mainGray.silver};
    border-radius: 10px;
  }
`;

export const LocationItemWrap = styled.div`
  width: 100%;
  height: 46px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  background-color: ${style.mainWhite.white};
  cursor: pointer;
  padding: 0 10px;

  :hover {
    background-color: ${style.mainGray.whiteSmokeColor};
  }
`;

export const HeaderText = styled.p<IProps>`
  font-size: 13px;
  line-height: 100%;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  margin-bottom: 4px;
`;

export const Text = styled.p<IProps>`
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${style.mainGray.silver};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 13px;
`;
