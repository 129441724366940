export function getReferrerQueryData(query: any) {
  const isValidString = (value) =>
    typeof value === 'string' && value.trim() !== '';

  // ----- APPSFLYER_DIRECT_PARAMS -----
  const {
    deep_link_sub1: deepLinkSub1,
    deep_link_sub2: deepLinkSub2,
    campaign,
    deep_link_sub3: deepLinkSub3,
  } = query || {};

  // ----- OUR PARAMS -----
  let { contentType, contentId, referrer } = query || {};

  // ----- CONVERTING APPSFLYER TO OUR PARAMS VARIABLES  ----

  if (!contentType && (campaign?.length || deepLinkSub3)) {
    contentType = campaign?.length ? campaign : deepLinkSub3;
  }

  if (!contentId && deepLinkSub2) {
    contentId = deepLinkSub2;
  }

  if (!referrer && deepLinkSub1) {
    referrer = deepLinkSub1;
  }
  console.log('DATA', [contentType, contentId, referrer]);
  // Check if all parameters are valid strings
  if ([contentType, contentId, referrer].every(isValidString)) {
    // Store in Local Storage
    const referrerData = { contentType, contentId, referrer };
    return referrerData;
  }
  return null;
}
export function processReferrerQueryData(query: any) {
  const referrerData = getReferrerQueryData(query);
  if (referrerData) {
    storeReferrerData(referrerData);
  }
}

export function getReferrerDataQueryParams(query: any) {
  const referrerData = getReferrerQueryData(query);
  let str = '';
  if (referrerData) {
    Object.keys(referrerData).forEach((_key, index) => {
      str += `${index > 0 ? '&' : ''}${_key}=${referrerData[_key]}`;
    });
  }
  return str;
}

export function storeReferrerData(referrerData: any) {
  try {
    if (typeof window === 'undefined') {
      return;
    }
    localStorage.setItem('referrerData', JSON.stringify(referrerData));
  } catch (error) {
    console.log('ERROR IN SAVING REFERRER DATA', error);
  }
}

export function getReferrerData() {
  try {
    if (typeof window === 'undefined') {
      return null;
    }
    const referrerDataStr = localStorage.getItem('referrerData');
    if (referrerDataStr) {
      return JSON.parse(referrerDataStr);
    }
    return null;
  } catch (error) {
    return null;
  }
}
