import React from 'react';
import { style } from 'utils/constants/style';

export const SendIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill={style.mainYellow.yellow}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9977 0.226486C11.9959 0.212657 11.9938 0.19935 11.9896 0.185782C11.9852 0.170909 11.9792 0.157341 11.9724 0.143511C11.9687 0.135945 11.9674 0.127856 11.963 0.12055C11.9601 0.115853 11.9554 0.112722 11.952 0.108286C11.9434 0.0962838 11.9333 0.0858468 11.9226 0.0751488C11.9119 0.0644508 11.9012 0.0545356 11.8889 0.045925C11.8845 0.0427939 11.8816 0.0383582 11.8772 0.0357489C11.8699 0.0313132 11.8618 0.0302695 11.8542 0.0266165C11.8398 0.0195715 11.8258 0.0135702 11.8106 0.00913445C11.7978 0.00574241 11.7853 0.00391592 11.7723 0.00235037C11.7569 0.000523882 11.742 -0.000519823 11.7266 0.000262955C11.7123 0.00104573 11.6984 0.00391592 11.6843 0.00730796C11.6755 0.00939537 11.6663 0.0086126 11.6575 0.0117437L0.176695 3.92564C0.072846 3.9606 0.00213497 4.0574 4.75562e-05 4.16725C-0.00203986 4.2771 0.0647573 4.37652 0.167562 4.41592L5.53143 6.47697L7.84924 11.8427C7.89073 11.9384 7.98518 12 8.08877 12C8.0932 12 8.09738 12 8.10181 11.9997C8.21062 11.9943 8.30429 11.922 8.33743 11.8184L11.9904 0.337641C11.993 0.329552 11.9922 0.321202 11.9938 0.312853C11.9969 0.298241 11.9993 0.284151 11.9998 0.269278C12.0003 0.254666 11.9995 0.240837 11.9977 0.226486Z"
      fill={style.mainYellow.yellow}
    />
  </svg>
);
