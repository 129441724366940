const CopyCircle = () => {
  return (
      <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_7641_111312)">
              <circle cx="20" cy="20" r="19" stroke="#FF950A" stroke-width="2" />
              <path d="M22.0002 29.9992H12.0002C11.4643 30.0178 10.9447 29.8131 10.5655 29.4339C10.1863 29.0547 9.98157 28.5351 10.0002 27.9992V17.9992C9.98157 17.4633 10.1863 16.9437 10.5655 16.5645C10.9447 16.1853 11.4643 15.9806 12.0002 15.9992H16.0002V11.9992C15.9816 11.4633 16.1863 10.9437 16.5655 10.5645C16.9447 10.1853 17.4643 9.98059 18.0002 9.99922H28.0002C28.5361 9.98059 29.0557 10.1853 29.4349 10.5645C29.8141 10.9437 30.0188 11.4633 30.0002 11.9992V21.9992C30.0185 22.5351 29.8137 23.0545 29.4346 23.4336C29.0555 23.8127 28.536 24.0175 28.0002 23.9992H24.0002V27.9992C24.0185 28.5351 23.8137 29.0545 23.4346 29.4336C23.0555 29.8127 22.536 30.0175 22.0002 29.9992ZM12.0002 17.9992V27.9992H22.0002V23.9992H18.0002C17.4644 24.0175 16.9449 23.8127 16.5658 23.4336C16.1867 23.0545 15.9819 22.5351 16.0002 21.9992V17.9992H12.0002ZM18.0002 11.9992V21.9992H28.0002V11.9992H18.0002Z" fill="#FF950A" />
          </g>
          <defs>
              <clipPath id="clip0_7641_111312">
                  <rect width="40" height="40" fill="white" />
              </clipPath>
          </defs>
      </svg>

  )
}

export default CopyCircle