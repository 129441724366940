import { useTranslation } from 'react-i18next';
import ReactLoading from 'react-loading';
import { NoResultsWrap } from './NoResultsComponent.style';

interface INoResultsComponent {
  width?: number;
  fullWidth?: boolean;
  loaderSize?: number;
  wrapHeight?: number;
  mobileWrapHeight?: number;
}

const NoResultsComponent = ({
  width,
  fullWidth,
  wrapHeight = 400,
  mobileWrapHeight = 400,
}: INoResultsComponent) => {
  const { t } = useTranslation();
  return (
    <NoResultsWrap
      width={860}
      fullWidth={fullWidth}
      height={wrapHeight}
      mobileWrapHeight={mobileWrapHeight}
    >
      {t('common:noresultsavailable')}
    </NoResultsWrap>
  );
};

export default NoResultsComponent;
