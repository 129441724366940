/* eslint-disable no-prototype-builtins */
import { routesPath } from './constants/routePath';
import { DEFAULT_LINK_POST_PLACEHOLDER_IMAGE } from './constants';

export const getRightSideImageBasedOnType = (type, data) => {
  switch (type) {
    case 9:
    case 10:
      if (data?.postPhoto === DEFAULT_LINK_POST_PLACEHOLDER_IMAGE) {
        return '';
      }
      return data.postPhoto || data.eventCoverPhoto || data.vehiclePhoto;

    case 11:
    case 26:
    case 31:
    case 34:
    case 36:
    case 37:
    case 44:
    case 51:
    case 70:
      if (data.postPhoto === DEFAULT_LINK_POST_PLACEHOLDER_IMAGE) {
        return '';
      }
      return data.postPhoto;

    case 12:
    case 13:
    case 14:
    case 15:
    case 43:
    case 56:
      return data.coverPhoto;
    case 17:
    case 20:
    case 45:
    case 48:
    case 76:
    case 24:
      return data.groupProfilePhoto;
    case 54:
      return data.lastVehiclePhoto;
    case 22:
    case 33:
    case 39:
    case 40:
      return data.eventCoverPhoto;
    case 222:
      return data.params.image;
    default:
      return false;
  }
};

export const getLeftSideIconBasedOnType = (type) => {
  switch (type) {
    case 28:
      return 'welcomeToRoadSTR';
    case 38:
      return 'upcomingEvent';
    case 41:
      return 'inviteYourFriends';
    case 59:
      return 'findYourFriends';
    case 60:
      return 'createFirstPost';
    case 61:
      return 'createFirstEvent';
    case 62:
      return 'createFirstVehicle';
    case 63:
      return 'createFirstPOI';
    case 42:
      return 'turnOnRoadSharing';
    case 222:
      return 'customNotification';
    default:
      return false;
  }
};

export const getLeftSideImageBasedOnType = (type, data) => {
  switch (type) {
    case 20:
      if (data?.membership) {
        return data.groupProfilePhoto;
      }
      return data.profilePhoto;

    case 31:
    case 32:
    case 33:
    case 34:
      return data.mentionerProfilePhoto;
    case 47:
    case 54:
      return data.groupProfilePhoto;
    case 8:
    case 9:
    case 10:
    case 11:
    case 12:
    case 13:
    case 14:
    case 15:
    case 16:
    case 17:
    case 18:
    case 19:
    case 22:
    case 23:
    case 24:
    case 25:
    case 26:
    case 27:
    case 29:
    case 35:
    case 36:
    case 37:
    case 39:
    case 40:
    case 43:
    case 44:
    case 45:
    case 48:
    case 76:
    case 51:
    case 56:
      if (data.profilePhoto === '') {
        return ' ';
      }
      return data.profilePhoto;

    case 70:
      if (data?.eventCoverPhoto) {
        return data.eventCoverPhoto;
      }
      return data.groupProfilePhoto;

    default:
      return ' ';
  }
};

export const getRightSideImagePressDestinationBasedOnType = (type, data) => {
  switch (type) {
    case 9:
    case 10:
      if (data.hasOwnProperty('eventId')) {
        return `${routesPath.event_details}/${data.eventId}`;
      }

      if (data.hasOwnProperty('postId')) {
        return `${routesPath.post_details}/${data.postId}`;
      }

      if (data.hasOwnProperty('vehicleId')) {
        return `${routesPath.vehicle_details}/${data.vehicleId}`;
      }
      break;

    case 32:
      return `${routesPath.vehicle_details}/${data.vehicleId}`;
    case 11:
    case 22:
    case 24:
    case 26:
    case 31:
    case 34:
    case 36:
    case 37:
    case 44:
    case 48:
    case 51:
    case 70:
      return `${routesPath.post_details}/${data.postId}`;
    case 12:
    case 13:
    case 14:
    case 15:
    case 33:
    case 39:
    case 40:
    case 43:
    case 56:
      return `${routesPath.event_details}/${data.eventId}`;
    case 17:
    case 20:
    case 45:
    case 47:
    case 76:
    case 54:
      return `${routesPath.group_details}/${data.groupId}`;
    case 27:
    case 42:
      return `${routesPath.mapPath}`;
    case 35:
      // WONDER: Seems like it doesn't have any image so handler is unneeded
      // return { route: 'suggested' };
      return '';
    case 38:
      // return { route: 'upcomingEvents' };
      return '';
    case 41:
      // WONDER: Seems like it doesn't have any image so handler is unneeded
      // return { route: 'inviteScreen' };
      return '';
    default:
      return '';
  }
};

export const getLeftSideImagePressDestinationBasedOnType = (type, data) => {
  switch (type) {
    case 20:
      if (data?.membership) {
        return `${routesPath.group_details}/${data.groupId}`;
      }
      return `${routesPath.user_details}/${data.userId}`;

    case 47:
    case 54:
      return `${routesPath.group_details}/${data.groupId}`;
    case 1:
    case 2:
    case 3:
    case 4:
    case 8:
    case 9:
    case 10:
    case 11:
    case 12:
    case 13:
    case 14:
    case 15:
    case 16:
    case 17:
    case 18:
    case 19:
    case 21:
    case 22:
    case 23:
    case 24:
    case 25:
    case 26:
    case 27:
    case 28:
    case 29:
    case 36:
    case 37:
    case 39:
    case 40:
    case 43:
    case 44:
    case 45:
    case 48:
    case 49:
    case 51:
    case 52:
    case 53:
    case 56:
      return `${routesPath.user_details}/${data.userId}`;
    case 42:
      return `${routesPath.mapPath}`;
    case 31:
    case 32:
    case 34:
    case 33:
      return `${routesPath.user_details}/${data.mentionerId}`;
    case 41:
    case 76:
    case 59:
      // return { route: 'inviteScreen' };
      return false;
    case 35:
      // return { route: 'suggestedScreen' };
      return false;
    case 38:
      // return { route: 'upcomingEvents' };
      return false;
    case 61:
      return `${routesPath.feedPath}`;
    case 70:
      if (data?.eventCoverPhoto) {
        return `${routesPath.event_details}/${data.eventId}`;
      }
      return `${routesPath.group_details}/${data.groupId}`;

    case 222:
      // return { route: `${data.source}${data.params}` };
      return false;
    default:
      return false;
  }
};

export const getNotificationPressDestinationBasedOnType = (type, data) => {
  switch (type) {
    case 9:
    case 10:
      if (data.hasOwnProperty('eventId')) {
        return `${routesPath.event_details}/${data.eventId}`;
      }

      if (data.hasOwnProperty('postId')) {
        return `${routesPath.post_details}/${data.postId}`;
      }

      if (data.hasOwnProperty('vehicleId')) {
        return `${routesPath.vehicle_details}/${data.vehicleId}`;
      }
      break;

    case 32:
      return `${routesPath.vehicle_details}/${data.vehicleId}`;
    case 12:
    case 13:
    case 14:
    case 15:
    case 33:
    case 39:
    case 40:
    case 43:
    case 56:
      return `${routesPath.event_details}/${data.eventId}`;
    case 17:
    case 18:
    case 20:
    case 45:
    case 47:
    case 54:
      return `${routesPath.group_details}/${data.groupId}`;
    case 41:
    case 59:
      // return { route: 'findFriends' };
      return false;
    case 27:
    case 42:
      return `${routesPath.mapPath}`;
    case 25:
    case 11:
    case 22:
    case 23:
    case 24:
    case 26:
    case 31:
    case 34:
    case 36:
    case 37:
    case 44:
    case 48:
    case 76:
    case 51:
    case 70:
      return `${routesPath.post_details}/${data.postId}`;
    case 8:
    case 16:
    case 19:
    case 28:
    case 29:
      return `${routesPath.user_details}/${data.userId}`;
    case 38:
      // return { route: 'upcomingEvents' };
      return false;
    case 61:
      return `${routesPath.feedPath}`;
    case 222:
      // return { route: 'some custom route here' };
      return false;
    default:
      return false;
  }
};
