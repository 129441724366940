import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchApiReq } from 'store/fetchApi/actions';

import Error from './Error';
import Success from './Success';
import Loader from './Loader';
import { IRequestAction } from './Types';

import { Wrapper } from './styles';

const RequestAction = ({ children, apiName, onComplete }: IRequestAction) => {
  const { success, loading, error } =
    useSelector((store) => store.fetchData[apiName]) || {};
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading) {
      if (success) {
        setTimeout(() => {
          onComplete();
          dispatch(
            fetchApiReq({
              key: apiName,
              res: {
                error: false,
                success: false,
                loading: false,
              },
            })
          );
        }, 1000);
      }
      if (error) {
        setTimeout(() => {
          dispatch(
            fetchApiReq({
              key: apiName,
              res: {
                error: false,
                success: false,
                loading: false,
              },
            })
          );
        }, 2500);
      }
    }
  }, [success, error, loading]);

  return (
    <>
      {(loading || error || success) && (
        <Wrapper>
          {success && <Success />}
          {error && <Error />}
          {loading && <Loader />}
        </Wrapper>
      )}

      {children}
    </>
  );
};

export default RequestAction;
