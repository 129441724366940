import React from 'react';
import { useTranslation } from 'react-i18next';

import { HeaderText } from './NotificationHeaderText.style';
import { INotificationHeaderText } from './INotificationHeaderText';

export const NotificationHeader = ({ type }: INotificationHeaderText) => {
  const { t } = useTranslation();

  switch (type) {
    case 28:
      return (
        <HeaderText>{t('notifications:welcomeToRoadStrHeadline')}</HeaderText>
      );

    case 41:
      return <HeaderText>{t('notifications:enjoyingRoadStr')}</HeaderText>;

    case 59:
      return <HeaderText>{t('notifications:getConnected')}</HeaderText>;

    case 60:
      return <HeaderText>{t('notifications:sayHi')}</HeaderText>;

    case 61:
      return <HeaderText>{t('notifications:meetOthers')}</HeaderText>;

    case 62:
      return <HeaderText>{t('notifications:shareYourRide')}</HeaderText>;

    case 63:
      return <HeaderText>{t('notifications:shareYourKnowledge')}</HeaderText>;

    default:
      return null;
  }
};
