import styled from 'styled-components';

import Text from 'components/common/Text';

export const VehiclesWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  @media only screen and (max-width: 800px) {
    justify-content: center;
  }
`;

export const NoResultText = styled(Text)`
  font-size: ${(props) => props.theme.typography.fontSize.f3};
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${(props) => props.theme.colors.neutral.n7};
  text-align: center;
  margin-top: 0;
  margin-bottom: 42px;
`;

export const NoResultWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
`;
