import styled from 'styled-components';

import { deviceMaxWidth, style } from 'utils/constants/style';

export const CommentWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

export const DateComment = styled.div`
  margin-top: 6px;
  margin-left: 40px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 300;
  color: ${style.mainGray.SuvaGrey};
  margin-bottom: 16px;
`;

export const DeleteCommentWrapper = styled.div`
  position: relative;
  height: 34px;
`;

export const DeleteComment = styled.div`
  margin: 10px 0;
  cursor: pointer;
  width: 24px;
  height: 24px;
  transform: rotate(-90deg);
`;

export const WrapPopover = styled.div`
  position: absolute;
  z-index: 100;
  top: 100%;
  right: 6px;
  width: 170px;
`;

export const ImageWrap = styled.div`
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
  overflow: hidden;
  align-self: flex-end;
`;

export const CommentWrap = styled.div`
  max-width: 90%;
  height: auto;
  padding-left: 17px;
  padding-right: 17px;
  padding-bottom: 8px;
  border-radius: 16px 16px 16px 0;
  background-color: ${style.mainGray.whiteSmokeColor};
  padding: 6px 10px;

  span {
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    color: ${style.mainBlack.black};
    letter-spacing: 0.01em;
  }
`;

export const UserName = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: ${style.mainBlack.black};
  margin-bottom: 4px;
`;

export const ContentComments = styled.div`
  height: auto;
  padding: 20px 20px 40px 20px;
  width: 100%;
  box-sizing: border-box;
`;

export const ContainerComment = styled.div`
  display: flex;
`;

export const ListUserUserActions = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  width: 100%;
  padding-inline-start: 0px;
  border-radius: 10px;
  background-color: ${style.mainWhite.white};
  overflow: hidden;
  filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.08));

  span {
    position: relative;
    top: 5px;
    right: 10px;
  }
  li {
    height: 44px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 10px 26px 10px 26px;
    padding-left: 26px;
    cursor: pointer;
    color: ${style.mainBlack.black};
    font-size: 14px;
    :hover {
      background-color: ${style.mainGray.whisperColor};
    }
  }
`;
