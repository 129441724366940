/* eslint-disable no-unused-expressions */
/* eslint-disable import/no-cycle */
import { all, call, put, takeLatest } from 'redux-saga/effects';
import Router from 'next/router';
import cookieCutter from 'cookie-cutter';

import { routesPath } from 'utils/constants/routePath';
import {
  postLogin,
  loginWithFacebook,
  loginWithApple,
  activateAccount,
  resendActivationEmail,
} from 'services';
import { loadIdentity } from 'store/identity/actions';
import {
  actionTypes,
  loginError,
  LogInSuccess,
  loginWithAppleSuccess,
  loginWithAppleError,
  loginWithFacebookSuccess,
  loginWithFacebookError,
  activateAccountSuccess,
  activateAccountError,
  resendActivationEmailSuccess,
  resendActivationEmailError,
} from './actions';

function* workerLogin(action) {
  try {
    const { userNameOrEmail, password, goToFeed = true } = action.data;

    const resp = yield call(postLogin, userNameOrEmail, password);

    if (resp.data.ok && resp.data.token) {
      yield cookieCutter.set('tsec', resp.data.token);
      yield localStorage.setItem('token', JSON.stringify(resp.data.token));
      yield localStorage.setItem('user_id', JSON.stringify(resp.data._id));
      yield localStorage.setItem(
        'tutorialIsActive',
        JSON.stringify(resp.data.tutorialIsActive)
      );
      yield put(loadIdentity(resp.data._id));

      yield put(LogInSuccess(resp.data));

      if (resp.data.tutorialIsActive) {
        yield Router.push(routesPath.tutorial);
      } else if (
        userNameOrEmail !== process.env._ANONYMOUS_USERNAME &&
        goToFeed
      ) {
        yield Router.push(`${routesPath.feedPath}`);
      }
    } else {
      yield put(loginError(resp.data.code));
    }
  } catch (err) {
    yield put(loginError(err));
  }
}

function* workerActivateAccount(action) {
  try {
    const resp = yield call(activateAccount, action.data);

    console.log('activate resp', resp);

    const data = resp.data?.info || resp.data;

    if (data.token && data.ok) {
      yield cookieCutter.set('tsec', data.token);
      yield localStorage.setItem('token', JSON.stringify(data.token));
      yield localStorage.setItem('user_id', JSON.stringify(data._id));

      yield put(activateAccountSuccess(data));

      yield localStorage.setItem(
        'tutorialIsActive',
        JSON.stringify(resp.data.tutorialIsActive)
      );
      if (data.tutorialIsActive) {
        yield Router.push(routesPath.tutorial);
      } else {
        yield Router.push(`${routesPath.feedPath}`);
      }
    } else {
      yield put(activateAccountError(data));
    }
  } catch (err) {
    yield put(activateAccountError(err));
  }
}

function* workerLoginWithFacebook(action) {
  try {
    const resp = yield call(loginWithFacebook, action.data);

    console.log('facebook', resp);

    if (resp.data.token && resp.data.ok) {
      yield cookieCutter.set('tsec', resp.data.token);
      yield localStorage.setItem('token', JSON.stringify(resp.data.token));
      yield localStorage.setItem('user_id', JSON.stringify(resp.data._id));
      yield localStorage.setItem(
        'tutorialIsActive',
        JSON.stringify(resp.data.tutorialIsActive)
      );

      yield put(loginWithFacebookSuccess(resp.data));

      if (resp.data.tutorialIsActive) {
        yield Router.push(routesPath.tutorial);
      } else {
        yield Router.push(`${routesPath.feedPath}`);
      }
    } else {
      yield put(loginWithFacebookError(resp.data));
    }
  } catch (err) {
    yield put(loginWithFacebookError(err));
  }
}

function* workerLoginWithApple(action) {
  try {
    const resp = yield call(loginWithApple, action.data);

    if (resp.data.token && resp.data.ok) {
      yield cookieCutter.set('tsec', resp.data.token);
      yield localStorage.setItem('token', JSON.stringify(resp.data.token));
      yield localStorage.setItem('user_id', JSON.stringify(resp.data._id));
      yield localStorage.setItem(
        'tutorialIsActive',
        JSON.stringify(resp.data.tutorialIsActive)
      );

      yield put(loginWithAppleSuccess(resp.data));

      if (resp.data.tutorialIsActive) {
        yield Router.push(routesPath.tutorial);
      } else {
        yield Router.push(`${routesPath.feedPath}`);
      }
    } else {
      yield put(loginWithAppleError(resp.data));
    }
  } catch (err) {
    yield put(loginWithAppleError(err));
  }
}

function* workerResendActivationEmail(action) {
  try {
    const { email, onSuccess } = action;
    const resp = yield call(resendActivationEmail, email);

    if (resp.data && resp.data.ok) {
      onSuccess && onSuccess();
      yield put(resendActivationEmailSuccess());
      // You can do more things here upon success
    } else {
      yield put(resendActivationEmailError(resp.data));
    }
  } catch (err) {
    yield put(resendActivationEmailError(err));
  }
}

function* watchLogin() {
  yield all([
    takeLatest(actionTypes.LOGIN_START, workerLogin),
    takeLatest(actionTypes.LOGIN_WITH_FACEBOOK_START, workerLoginWithFacebook),
    takeLatest(actionTypes.LOGIN_WITH_APPLE_START, workerLoginWithApple),
    takeLatest(actionTypes.ACTIVATE_ACCOUNT_START, workerActivateAccount),
    takeLatest(
      actionTypes.RESEND_ACTIVATION_EMAIL_START,
      workerResendActivationEmail
    ),
  ]);
}

export default watchLogin;
