import { useRouter } from 'next/router';
import moment from 'moment';

import Image from 'components/common/Image';
import { EmptyGroupIcon } from 'public/assets/icons';
import { routesPath } from 'utils/constants/routePath';
import imageResize from 'utils/imageResize';

import EmptyUserProfilePhoto from 'components/EmptyUserProfilePhoto/EmptyUserProfilePhoto';
import { ISidebarListItem } from './ISidebarListItem';

import {
  EmptyUserPhoto,
  ImageWrapper,
  ItemDescription,
  ItemDescriptions,
  ItemName,
  ItemWrap,
} from './SidebarListItem.styles';

const SidebarListItem = ({
  imageSrc,
  name,
  userName,
  startDate,
  variant = 'my_events',
  id,
}: ISidebarListItem) => {
  const router = useRouter();

  const onItemClick = (itemId) => {
    if (variant === 'my_groups') {
      router.push(`${routesPath.group_details}/${itemId}`);
    } else {
      router.push(`${routesPath.event_details}/${itemId}`);
    }
  };

  return (
    <ItemWrap onClick={() => onItemClick(id)}>
      <ImageWrapper>
        {imageSrc ? (
          <Image
            src={imageResize(imageSrc, '_thumb') || imageSrc?.trim()}
            alt="list item image"
            height={40}
            width={40}
            unoptimized
            priority
          />
        ) : variant === 'my_events' ? (
          <EmptyUserProfilePhoto
            wrapSize={40}
            userIconSize={20}
            withBorder
            cursor="pointer"
          />
        ) : (
          <EmptyUserPhoto>
            <EmptyGroupIcon />
          </EmptyUserPhoto>
        )}
      </ImageWrapper>
      <ItemDescriptions>
        <ItemName>{name}</ItemName>
        <ItemDescription>
          {(variant === 'my_events' &&
            moment(startDate).format('DD MMM hh:mm A')) ||
            (variant === 'my_groups' && userName)}
        </ItemDescription>
      </ItemDescriptions>
    </ItemWrap>
  );
};

export default SidebarListItem;
