import styled from 'styled-components';

import { style, deviceMaxWidth } from 'utils/constants/style';

interface IProps {
  padding?: string;
}

export const Wrapper = styled.div`
  width: 100%;
  max-width: 404px;
  position: fixed;
  top: 56px;
  right: 0;
  bottom: 0;
  background-color: ${style.mainWhite.white};
  overflow-y: auto;
  z-index: 53;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar-track {
    background-color: ${style.mainGray.whiteSmokeColor};
  }

  &::-webkit-scrollbar {
    width: 4px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${style.mainGray.silver};
    border-radius: 10px;
  }

  @media only screen and (${deviceMaxWidth.sm}) {
    top: 0;
    max-width: 100%;
  }
`;

export const MainPageContentWrap = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${style.mainBlack.black};
  opacity: 0.3;
  z-index: 52;
`;
