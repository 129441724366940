import React from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { FinishLocationIcon, MoveIcon, CloseIcon } from 'pages/map/icons';
import { openSidebarWhereTo, closeSidebarPlanRoute } from 'store/map/actions';
import { IEndPoint } from './IEndPoint';
import {
  Container,
  LeftSideWrap,
  RightSideWrap,
  IconWrap,
  Text,
  ClickableText,
  BurgerIconDescription,
} from '../StartPoint/StartPoint.style';

const EndPoint = ({
  address,
  isEditable = true,
  onCrossClick = null,
  onDrag = null,
  onDrop = null,
  waypointIndex = 0,
}: IEndPoint) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { isOpenSidebarWhereTo } = useSelector(
    (state) => state.map.sidebarsInfo
  );

  const onClick = () => {
    if (isOpenSidebarWhereTo) {
      batch(() => {
        dispatch(closeSidebarPlanRoute());
        dispatch(openSidebarWhereTo());
      });
    } else {
      dispatch(openSidebarWhereTo());
    }
  };

  return (
    <Container
      draggable
      onDragOver={(ev) => ev.preventDefault()}
      onDragStart={onDrag}
      onDrop={onDrop}
      id={String(waypointIndex)}
    >
      <LeftSideWrap>
        <IconWrap mr={8}>
          <FinishLocationIcon />
        </IconWrap>

        {address ? (
          <Text>{address}</Text>
        ) : (
          <ClickableText onClick={onClick}>
            {t('map:chooseTheEndPoint')}
          </ClickableText>
        )}
      </LeftSideWrap>

      {address && isEditable && (
        <RightSideWrap>
          <IconWrap mr={8} cursor="pointer">
            <MoveIcon />

            <BurgerIconDescription>
              {t('map:dragToReorder')}
            </BurgerIconDescription>
          </IconWrap>

          <IconWrap cursor="pointer" onClick={onCrossClick}>
            <CloseIcon />
          </IconWrap>
        </RightSideWrap>
      )}
    </Container>
  );
};

export default EndPoint;
