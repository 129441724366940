import React, {
  useEffect,
  useCallback,
  useState,
  useRef,
  useContext,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import MapGL, { Marker, Layer, Source, FullscreenControl } from 'react-map-gl';
import { Popover, ArrowContainer } from 'react-tiny-popover';
import StarRatings from 'react-star-ratings';

import {
  SidebarWrapper,
  PillButton,
  EmptyUserProfilePhoto,
  LoadingComponent,
  ConfirmationModal,
  ShareOptions,
} from 'components';
import {
  closeSidebarRouteDetail,
  getRouteDetailsByIdStart,
  openSidebarPlanRoute,
  deleteRouteStart,
  createRouteRatingStart,
  updateRouteRatingStart,
  deleteRouteRatingStart,
  deleteRouteFromFavoritesStart,
  addRouteToFavoritesStart,
  setRouteForEditData,
} from 'store/map/actions';
import {
  CalendarAdd,
  CalendarCircle,
  CircleWithEditIcon,
  CopyCircle,
  CrossIcon,
  ShareCircle,
  ShareIcon,
} from 'public/assets/icons';
import appState from 'hooks/appState';
import { APP_STATE } from 'types';
import { style } from 'utils/constants/style';
import { routesPath } from 'utils/constants/routePath';
import { START_WAYPOINT_TYPE } from 'utils/constants';
import { convertUnitsOfMeasurement } from 'utils/convertUnitsOfMeasurement';
import { getMinutesAndHoursFromSeconds } from 'utils/transforms';
import {
  ShareButton,
  BookmarkIcon,
  RouteStartPin,
  RouteEndPin,
} from 'pages/map/icons';
import { AsyncStatus } from 'utils/types';
import { useOutsideClick, getLocalStorage } from 'utils/hooks';
import Image from 'components/common/Image';
import Button from 'components/common/Button';
import Heading from 'components/common/Typography/Heading';
import SwitchComponent from 'components/common/Switch';
import { ThemeContext } from 'theme';
import fetchApi from 'hooks/fetchApi';
import API_NAME from 'services/api';
import {
  ContentContainer,
  ContainerHeader,
  RouteNameWrap,
  RouteNameLabel,
  RouteName,
  ButtonsContainer,
  ButtonWrap,
  RouteCreatorContainer,
  CreatorAvatar,
  UsernameFullNameWrap,
  Username,
  FullName,
  ShareButtonWrap,
  MapWrapper,
  WayPointPinWrap,
  RouteCreatorBlock,
  RouteCreatorDetails,
  RouteCreatorActions,
  CreatorName,
  CreatorImage,
  RouteDescriptionBlock,
  DescriptionTitle,
  DescriptionText,
  RoutePrivacyBlock,
  RouteRateBlock,
  RateBlock,
  RateRoute,
  RateButtonBlock,
  PrivacyTitle,
  PrivacyTitleLabel,
  PrivacyContent,
  PrivacyText,
  PrivacySwitch,
  RouteShareBlock,
  ShareTitle,
  PopoverContent,
  RouteShareAndEventButtonWrap,
  PopoverContentShareIcon,
} from './SidebarRouteDetails.style';
import {
  MapSidebarWrap,
  WayPoint,
  EndPoint,
  StartPoint,
  VerticalDashedLine,
  RouteSummary,
} from '../index';
import { ISidebarRouteDetails } from './ISidebarRouteDetails';

const SidebarRouteDetails = ({
  waypoints,
  setWaypoints,
  isRouteDetails,
  isTop = true,
  viewport,
}: ISidebarRouteDetails) => {
  // const [viewport, setViewport] = useState({
  //   width: '100%',
  //   height: '100%',
  //   zoom: 6,
  // });
  const [mapSize, setMapSize] = useState<any>(null);
  const [routeTime, setRouteTime] = useState({
    minutes: '',
    hours: '',
  });
  const [routeDistance, setRouteDistance] = useState('');
  const [isConformationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isShareMenuOpen, setIsShareMenuOpen] = useState(false);
  const [isRouteUrlCopied, setIsRouteUrlCopied] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const clickMeButtonRef = useRef<HTMLButtonElement | undefined>();
  const [checked, setChecked] = React.useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isOpenRouteRating, setIsOpenRouteRating] = useState(false);
  const [rating, setRating] = useState(0);
  const context: any = useContext(ThemeContext);
  const dispatch = useDispatch();
  const router = useRouter();
  const { action } = fetchApi();
  const { t } = useTranslation();

  const appStateAction = appState();

  const {
    routeDetailsId,
    routeDetails,
    directionsForDriving,
    updateRouteReqStat,
  } = useSelector((state) => state.map);
  const settings = useSelector((state) => state.settings);

  const userId = context?.userId;

  const refShareModal = useRef(null);

  useOutsideClick(refShareModal, () => {
    setIsShareMenuOpen(false);
  });

  useEffect(() => {
    if (directionsForDriving) {
      const { distance, duration } = directionsForDriving;
      let distanceResult;

      if (settings?.units) {
        distanceResult = Math.round(distance / 1000);
      } else {
        distanceResult = convertUnitsOfMeasurement(distance / 1000, false);
      }

      const { hours, minutes } = getMinutesAndHoursFromSeconds(duration);

      setRouteTime({ hours, minutes });
      setRouteDistance(distanceResult);
    }
  }, [directionsForDriving, settings]);

  useEffect(() => {
    const condition =
      routeDetailsId &&
      (updateRouteReqStat === AsyncStatus.NONE ||
        updateRouteReqStat === AsyncStatus.SUCCESS);

    if (condition) {
      dispatch(getRouteDetailsByIdStart({ routeId: routeDetailsId }));
    }
  }, [routeDetailsId, dispatch, updateRouteReqStat]);

  useEffect(() => {
    setChecked(routeDetails?.isPublic || false);
    setIsFavorite(routeDetails?.isFavorite || false);
    let newWaypoints;

    if (routeDetails) {
      newWaypoints = routeDetails.waypoints.map((waypoint, index) => ({
        lat: waypoint.location.coordinates[1],
        lng: waypoint.location.coordinates[0],
        waypointName: waypoint.waypointName,
        placeId: waypoint.placeId,
        type: index === 0 ? START_WAYPOINT_TYPE : null,
      }));

      setWaypoints(newWaypoints);
    }

    if (isRouteDetails) {
      return () => {
        dispatch(
          setRouteForEditData({
            routeId: routeDetailsId,
            routeName: routeDetails?.name,
            waypoints: newWaypoints,
          })
        );
      };
    }
    return () => {
      setWaypoints([]);
      dispatch(setRouteForEditData(null));
    };
  }, [routeDetails]);

  const isMe = routeDetails?.creator._id === userId;

  const renderHeaderRight = () => {
    // if (!isMe && isFavorite) {
    //   return (
    //     <PillButton
    //       text={t('common:remove')}
    //       backgroundColor={style.mainYellow.oldLaceColor}
    //       color={style.mainYellow.yellow}
    //       onClick={() => setIsConfirmationModalOpen(true)}
    //     />
    //   );
    // }

    if (!isMe) {
      return;
    }

    return (
      <ButtonsContainer>
        <ButtonWrap mr={12}>
          <PillButton
            text={t('event:delete')}
            backgroundColor={style.mainRed.mistyRose}
            color={style.mainRed.cinnabarColor}
            onClick={() => setIsConfirmationModalOpen(true)}
          />
        </ButtonWrap>

        <ButtonWrap>
          <PillButton
            text={t('common:edit')}
            backgroundColor={style.mainYellow.oldLaceColor}
            color={style.mainYellow.yellow}
            onClick={() => {
              dispatch(
                openSidebarPlanRoute({
                  routeId: routeDetailsId,
                  routeName: routeDetails?.name,
                  waypoints,
                })
              );

              if (isRouteDetails) {
                router.push(routesPath.map);
              }
            }}
          />
        </ButtonWrap>
      </ButtonsContainer>
    );
  };

  const renderWaypoints = useCallback(
    () =>
      waypoints.map((waypoint, index) => {
        let waypointForRender;

        if (index === 0) {
          waypointForRender = (
            <>
              <StartPoint address={waypoint.waypointName} isEditable={false} />
              <VerticalDashedLine />
            </>
          );
        } else if (index === waypoints.length - 1) {
          waypointForRender = (
            <EndPoint address={waypoint.waypointName} isEditable={false} />
          );
        } else {
          waypointForRender = (
            <>
              <WayPoint
                address={waypoint.waypointName}
                pointNumber={index}
                isEditable={false}
              />
              <VerticalDashedLine />
            </>
          );
        }

        return (
          <div key={`${waypoint.placeId}_${index}`}>{waypointForRender}</div>
        );
      }),
    [waypoints]
  );

  const renderConfirmationModal = () => {
    const isMyRoute = routeDetails?.creator._id === userId;

    const bodyText = isMyRoute
      ? t('routeDetails:deleteRouteConfirmation')
      : t('routeDetails:removeFromFavoritesConfirmation');

    const submitCallback = () => {
      if (isMyRoute) {
        dispatch(
          deleteRouteStart({
            routeId: routeDetailsId,
            isRouteDetailsScreen: isRouteDetails,
          })
        );
      } else {
        dispatch(deleteRouteFromFavoritesStart({ routeId: routeDetailsId }));
        // MODIFY LOGIC FOR CLOSING POPUP
        setTimeout(() => setIsConfirmationModalOpen(false), 1000);
      }
    };

    return (
      <ConfirmationModal
        headerText={isMyRoute ? t('common:delete') : t('common:remove')}
        bodyText={bodyText}
        submitText={
          isMyRoute ? t('common:deleteUppercase') : t('common:removeUppercase')
        }
        onSubmit={submitCallback}
        onCancelClick={() => setIsConfirmationModalOpen(false)}
      />
    );
  };

  const getRouteUrl = (routeId) => {
    const { origin: URLOrigin } =
      typeof window !== 'undefined' && window.location;
    const url = URLOrigin;

    return `${url}/r/${routeId}`;
  };

  const onCopyToClipboardClick = (routeUrl) => {
    if (!isRouteUrlCopied) {
      navigator.clipboard.writeText(routeUrl);
      setIsRouteUrlCopied(true);
    }
  };

  const getRouteGeojson = (): any => ({
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {},
        geometry: directionsForDriving.geometry,
      },
    ],
  });

  const renderAllWaypointsWithoutStart = () => {
    const waypointsWithoutStart = waypoints.slice(1);

    return waypointsWithoutStart.map((item, index) => {
      const isEndPoint = index === waypointsWithoutStart.length - 1;

      return (
        <Marker
          key={`${item.name}_${index}`}
          longitude={Number(item.lng)}
          latitude={Number(item.lat)}
          offsetLeft={isEndPoint ? -23 : -18}
          offsetTop={-23}
        >
          {isEndPoint ? (
            <RouteEndPin />
          ) : (
            <WayPointPinWrap>
              <span>{index + 1}</span>
            </WayPointPinWrap>
          )}
        </Marker>
      );
    });
  };
  const onChangePrivecy = () => {
    action(API_NAME.ROUTES_PATCH_PRIVACY, {
      params: { routesId: routeDetailsId },
      body: {
        isPublic: !checked,
      },
    });
    setChecked((prevState) => !prevState);
  };

  const onHandleFevarite = () => {
    if (isFavorite) {
      action(API_NAME.ROUTES_DELETE_FAVORITES, {
        params: { routesId: routeDetailsId },
      });
      setIsFavorite(false);
      return;
    }
    action(API_NAME.ROUTES_ADD_FAVORITES, {
      params: { routesId: routeDetailsId },
    });
    setIsFavorite(true);
  };

  const routeBack = () => {
    router.push(`${routesPath.map}`);
    appStateAction(APP_STATE.MAP_GET_BACK_FROM_ROUTE, {
      isOpen: true,
      data: viewport,
    });
  };

  const createRouteRating = async () => {
    if (!routeDetails?.myRating)
      await dispatch(createRouteRatingStart({ rating, routeDetailsId }));
    else await dispatch(updateRouteRatingStart({ rating, routeDetailsId }));
    await dispatch(getRouteDetailsByIdStart({ routeId: routeDetailsId }));
    setIsOpenRouteRating(!isOpenRouteRating);
  };

  const deleteRouteRating = async () => {
    await dispatch(deleteRouteRatingStart({ routeDetailsId }));
    await dispatch(getRouteDetailsByIdStart({ routeId: routeDetailsId }));
    setRating(0);
    setIsOpenRouteRating(!isOpenRouteRating);
  };

  return (
    <MapSidebarWrap isTop={isTop}>
      <SidebarWrapper
        headerText={routeDetails?.name || t('routeDetails:routeDetails')}
        headerIcon={<CrossIcon color={style.mainBlack.black} />}
        headerRightElement={renderHeaderRight()}
        isSubmitAvailable
        onIconClick={
          isRouteDetails ? routeBack : () => dispatch(closeSidebarRouteDetail())
        }
        contentPadding="20px"
        isTop={isTop}
      >
        {routeDetails ? (
          <>
            {(isMe || isFavorite) && (
              <MapWrapper>
                <MapGL
                  {...viewport}
                  height="200px"
                  width="100%"
                  zoom={12}
                  mapStyle={process.env._MAPBOX_STYLE_URL}
                  mapboxApiAccessToken={process.env._MAPBOX_ACCESS_TOKEN}
                  // onViewportChange={(nextViewport) => {
                  //   if (mapSize === null) {
                  //     setMapSize({
                  //       width: nextViewport?.width,
                  //       height: nextViewport?.height,
                  //     });
                  //   }
                  //   setViewport(nextViewport);
                  // }}
                  // onClick={() => {
                  //   if (isController) {
                  //     if (onFullScreen) {
                  //       setViewport({ ...viewport, ...mapSize });
                  //     }
                  //     setOnFullScreen((prevState) => !prevState);
                  //   }
                  // }}
                  // attributionControl={false}
                >
                  {/* {isController && (
                  <FullscreenControl
                    style={{ bottom: '10px', right: '15px' }}
                  />
                )} */}
                  {!!waypoints.length && directionsForDriving && (
                    <>
                      <Marker
                        key={waypoints[0].name}
                        longitude={Number(waypoints[0].lng)}
                        latitude={Number(waypoints[0].lat)}
                        offsetLeft={-18}
                        offsetTop={-23}
                      >
                        <RouteStartPin />
                      </Marker>

                      {renderAllWaypointsWithoutStart()}

                      <Source
                        id="routeSource"
                        type="geojson"
                        data={getRouteGeojson()}
                      >
                        <Layer
                          id="route"
                          type="line"
                          source="route"
                          layout={{
                            'line-join': 'round',
                            'line-cap': 'round',
                          }}
                          paint={{
                            'line-color': style.mainBlue.blue,
                            'line-width': 8,
                          }}
                        />
                      </Source>
                    </>
                  )}
                </MapGL>
              </MapWrapper>
            )}
            <RouteNameWrap>
              <RouteName>{routeDetails?.name}</RouteName>
            </RouteNameWrap>
            <RouteCreatorBlock>
              <RouteCreatorDetails>
                <CreatorImage>
                  <Image
                    src={routeDetails?.creator?.profilePhoto.trim()}
                    alt="creator image"
                    height={48}
                    width={48}
                    unoptimized
                    priority
                  />
                </CreatorImage>
                <CreatorName>{routeDetails?.creator?.username}</CreatorName>
              </RouteCreatorDetails>
              <RouteCreatorActions>
                <Button
                  htmlType="button"
                  buttonType="icon"
                  onClick={onHandleFevarite}
                >
                  <BookmarkIcon
                    colorFill={isFavorite && style.mainYellow.yellow}
                  />
                </Button>
                <Button
                  htmlType="button"
                  buttonType="icon"
                  onClick={() =>
                    onCopyToClipboardClick(getRouteUrl(routeDetailsId))
                  }
                >
                  <CopyCircle />
                </Button>
              </RouteCreatorActions>
            </RouteCreatorBlock>
            {!isOpenRouteRating && (
              <RouteRateBlock>
                <RateBlock>
                  <StarRatings
                    rating={routeDetails?.averageRating}
                    starRatedColor="#FF950A"
                    starHoverColor="#FF950A"
                    name="rating"
                    starDimension="18px"
                    starSpacing="1px"
                  />
                  <ShareTitle type="h6">
                    {routeDetails?.averageRating}
                  </ShareTitle>
                  <Heading type="h5">{`(${routeDetails?.ratings?.length})`}</Heading>
                </RateBlock>
                {routeDetails?.myRating ? (
                  <Button
                    htmlType="button"
                    buttonType="default"
                    className="btnEdit"
                    onClick={() => setIsOpenRouteRating(!isOpenRouteRating)}
                  >
                    Edit
                  </Button>
                ) : (
                  <Button
                    htmlType="button"
                    buttonType="default"
                    onClick={() => setIsOpenRouteRating(!isOpenRouteRating)}
                  >
                    Rate it
                  </Button>
                )}
              </RouteRateBlock>
            )}
            {isOpenRouteRating && (
              <RateRoute>
                <Heading type="h4">Rate this route</Heading>
                <StarRatings
                  rating={rating}
                  starRatedColor="#FF950A"
                  starHoverColor="#FF950A"
                  name="rating"
                  starDimension="30px"
                  changeRating={(e) => setRating(e)}
                  starSpacing="4px"
                />
                <RateButtonBlock>
                  {routeDetails?.myRating ? (
                    <Button
                      htmlType="button"
                      buttonType="default"
                      className="btnCancel"
                      onClick={deleteRouteRating}
                    >
                      Delete
                    </Button>
                  ) : (
                    <Button
                      htmlType="button"
                      buttonType="default"
                      className="btnCancel"
                      onClick={() => setIsOpenRouteRating(!isOpenRouteRating)}
                    >
                      Cancel
                    </Button>
                  )}
                  <Button
                    htmlType="button"
                    buttonType="default"
                    onClick={createRouteRating}
                  >
                    save
                  </Button>
                </RateButtonBlock>
              </RateRoute>
            )}
            {routeDetails?.description && (
              <RouteDescriptionBlock>
                <DescriptionTitle>
                  <Heading type="h6">Description</Heading>
                  {isMe && (
                    <Button
                      htmlType="button"
                      buttonType="icon"
                      onClick={() => console.log('circle')}
                    >
                      <CircleWithEditIcon />
                    </Button>
                  )}
                </DescriptionTitle>
                <DescriptionText>{routeDetails?.description}</DescriptionText>
              </RouteDescriptionBlock>
            )}
            {isMe && (
              <RoutePrivacyBlock>
                <PrivacyTitle>
                  <Heading type="h6">Make route public</Heading>
                  <PrivacyTitleLabel>new</PrivacyTitleLabel>
                </PrivacyTitle>
                <PrivacyContent>
                  <PrivacyText>
                    Share your route with the community by making it publicly
                    visible on the map.
                  </PrivacyText>
                  <PrivacySwitch>
                    <SwitchComponent
                      isOn={!checked}
                      handleToggle={onChangePrivecy}
                    />
                  </PrivacySwitch>
                </PrivacyContent>
              </RoutePrivacyBlock>
            )}
            <RouteShareBlock>
              <ShareTitle type="h6">Share route</ShareTitle>
              <Popover
                isOpen={isOpen}
                positions={['bottom', 'left']} // preferred positions by priority
                ref={clickMeButtonRef}
                onClickOutside={() => setIsOpen(!isOpen)}
                reposition={false}
                content={({ position, childRect, popoverRect }) => (
                  <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    arrowColor="#F6F6F6"
                    arrowSize={10}
                    className="popover-arrow-container"
                    arrowStyle={{ opacity: 0.7 }}
                    arrowClassName="popover-arrow"
                  >
                    <PopoverContentShareIcon onClick={() => setIsOpen(!isOpen)}>
                      <ShareOptions
                        onCancelClick={() => setIsOpen(!isOpen)}
                        url={getRouteUrl(routeDetailsId)}
                        isUrlCopied={isRouteUrlCopied}
                        setIsUrlCopied={setIsRouteUrlCopied}
                      />
                    </PopoverContentShareIcon>
                  </ArrowContainer>
                )}
              >
                <span onClick={() => setIsOpen(!isOpen)}>
                  <Button
                    htmlType="button"
                    buttonType="icon"
                    onClick={() => console.log('share')}
                  >
                    <ShareCircle />
                  </Button>
                </span>
              </Popover>
            </RouteShareBlock>

            <ContentContainer pt={20.5} pb={20.5}>
              <ContainerHeader mb={18}>{t('map:waypoints')}</ContainerHeader>
              {renderWaypoints()}
            </ContentContainer>

            <ContentContainer pb={16} mt={16}>
              <ContainerHeader mb={16}>{t('map:routeSummary')}</ContainerHeader>
              <RouteSummary
                distance={routeDistance}
                hours={routeTime.hours}
                minutes={routeTime.minutes}
                units={settings?.units ? t('common:km') : t('common:mi')}
              />
            </ContentContainer>

            {/* <ContentContainer mt={24}>
              <RouteCreatorContainer>
                {routeDetails.creator.profilePhoto.trim() ? (
                  <CreatorAvatar img={routeDetails.creator.profilePhoto} />
                ) : (
                  <EmptyUserProfilePhoto
                    userIconSize={24}
                    wrapSize={48}
                    withBorder={false}
                    margin="0 12px 0 0"
                  />
                )}

                <UsernameFullNameWrap>
                  <Username>{routeDetails.creator.username}</Username>
                  <FullName>{routeDetails.creator.fullName}</FullName>
                </UsernameFullNameWrap>

                {userId !== process.env._ANONYMOUS_ID &&
                  routeDetails.creator._id !== userId && (
                    <ShareButtonWrap
                      right={56}
                      onClick={() => {
                        if (routeDetails.isFavorite) {
                          setIsConfirmationModalOpen(true);
                        } else {
                          dispatch(
                            addRouteToFavoritesStart({
                              routeId: routeDetailsId,
                            })
                          );
                        }
                      }}
                    >
                      <BookmarkIcon
                        colorFill={
                          routeDetails?.isFavorite
                            ? style.mainYellow.yellow
                            : style.mainWhite.white
                        }
                      />
                    </ShareButtonWrap>
                  )}

                <ShareButtonWrap
                  onClick={() => setIsShareMenuOpen((prev) => !prev)}
                  ref={refShareModal}
                >
                  <ShareButton />

                  {isShareMenuOpen && (
                    <ShareOptions
                      url={getRouteUrl(routeDetailsId)}
                      right={0}
                      top={40}
                      isCopyToClipboard
                      onCopyToClipboardClick={onCopyToClipboardClick}
                      isUrlCopied={isRouteUrlCopied}
                      isProfileUrl
                    />
                  )}
                </ShareButtonWrap>
              </RouteCreatorContainer>
              <RouteShareAndEventButtonWrap>
                <Popover
                  isOpen={isOpen}
                  positions={['bottom', 'left']} // preferred positions by priority
                  ref={clickMeButtonRef}
                  onClickOutside={() => setIsOpen(!isOpen)}
                  reposition={false}
                  content={({ position, childRect, popoverRect }) => (
                    <ArrowContainer // if you'd like an arrow, you can import the ArrowContainer!
                      position={position}
                      childRect={childRect}
                      popoverRect={popoverRect}
                      arrowColor="#F6F6F6"
                      arrowSize={10}
                      arrowStyle={{ opacity: 0.7 }}
                      className="popover-arrow-container"
                      arrowClassName="popover-arrow"
                    >
                      <PopoverContent onClick={() => setIsOpen(!isOpen)}>
                        <ShareOptions
                          onCancelClick={() => setIsOpen(!isOpen)}
                          url={getRouteUrl(routeDetailsId)}
                          isUrlCopied={isRouteUrlCopied}
                          setIsUrlCopied={setIsRouteUrlCopied}
                        />
                      </PopoverContent>
                    </ArrowContainer>
                  )}
                >
                  <span onClick={() => setIsOpen(!isOpen)}>
                    <Button
                      htmlType="button"
                      variant="default"
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      <ShareIcon />
                      <>Share</>
                    </Button>
                  </span>
                </Popover>
                <Button variant="default">
                  <CalendarAdd />
                  <>event</>
                </Button>
              </RouteShareAndEventButtonWrap>
            </ContentContainer> */}
          </>
        ) : (
          <LoadingComponent
            fullWidth
            color="#C2C2C2"
            variant="spin"
            mobileWrapHeight={200}
          />
        )}

        {isConformationModalOpen && renderConfirmationModal()}
      </SidebarWrapper>
    </MapSidebarWrap>
  );
};

export default SidebarRouteDetails;
