import styled from 'styled-components';

import { deviceMaxWidth, style } from 'utils/constants/style';

interface IProps {
  img?: string;
  isVehiclesExist?: boolean;
  margin?: string;
}
export const AttendingVehiclesWrap = styled.div<IProps>`
  width: 100%;
  padding-bottom: 10px;
  background: transparent;
  border-radius: 4px;
  color: ${style.mainBlack.black};
  display: flex;
  margin: ${({ margin }) => margin || '0'};
`;

export const SliderBlock = styled.div<IProps>`
  width: 100%;
  @media only screen and (${deviceMaxWidth.sm}) {
    width: 100%;
  }
  height: 130px;
  color: ${style.mainWhite.white};
  margin-left: 0;

  li {
    width: 229px !important;

    & > div {
      & > span {
        height: 130px !important;
        width: 214px !important;
      }
    }
  }

  img {
    border-radius: 4px;
    object-fit: cover;

    @media only screen and (${deviceMaxWidth.sm}) {
      width: 195px;
    }
  }

  li {
    @media only screen and (${deviceMaxWidth.sm}) {
      width: 210px !important;
    }
  }

  position: relative;
  top: 16px;
`;

export const ArrowNext = styled.div<IProps>`
  position: absolute;
  left: 488px;
  top: 50px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-left: 10px;

  z-index: 50;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: ${style.mainBlue.blue};
  svg {
    position: relative;
    left: 1px;
  }
`;

export const ArrowPrev = styled.div`
  position: absolute;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
  top: 50px;
  left: -10px;
  z-index: 50;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: ${style.mainBlue.blue};
  transform: rotate(180deg);
  svg {
    position: relative;
    left: 1px;
  }
`;

export const SlideComponent = styled.div<IProps>`
  cursor: pointer;
  position: relative;
`;

export const GradientWrap = styled.div`
  height: 77px;
  width: 214px;
  box-sizing: border-box;
  position: absolute;
  bottom: 7px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-left: 14px;
  padding-bottom: 10px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 9.67%, #1c1f29 100%);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  @media only screen and (${deviceMaxWidth.sm}) {
    width: 195px;
  }
`;

export const VehicleInfoBlock = styled.p`
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${style.mainWhite.white};
`;
