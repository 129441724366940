import React from 'react';
import { style } from 'utils/constants/style';

export const CreateFirstPOIIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6074_93702)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8207 20.4274C11.8207 20.4274 18.7951 11.4929 18.7951 7.64106C18.7951 3.78922 15.6725 0.666697 11.8207 0.666697C7.96888 0.666697 4.84635 3.78922 4.84635 7.64106C4.84635 11.4929 11.8207 20.4274 11.8207 20.4274ZM11.8201 12.2906C14.5484 12.2906 16.7602 10.0788 16.7602 7.3504C16.7602 4.62202 14.5484 2.41023 11.8201 2.41023C9.09167 2.41023 6.87988 4.62202 6.87988 7.3504C6.87988 10.0788 9.09167 12.2906 11.8201 12.2906ZM11.1527 10.0001C11.1527 10.3683 11.4511 10.6667 11.8193 10.6667C12.1875 10.6667 12.486 10.3683 12.486 10.0001V8.00007H14.486C14.8542 8.00007 15.1527 7.70159 15.1527 7.33341C15.1527 6.96522 14.8542 6.66674 14.486 6.66674H12.486V4.66674C12.486 4.29855 12.1875 4.00007 11.8193 4.00007C11.4511 4.00007 11.1527 4.29855 11.1527 4.66674V6.66674H9.15267C8.78448 6.66674 8.486 6.96522 8.486 7.33341C8.486 7.70159 8.78448 8.00007 9.15267 8.00007H11.1527V10.0001ZM4 20.3334C4 19.0383 5.46489 17.9001 7.67664 17.2477C7.94901 17.6478 8.21886 18.0365 8.48212 18.4098C7.88764 18.5593 7.35472 18.7437 6.89869 18.9528C5.54543 19.573 5.33333 20.1594 5.33333 20.3334C5.33333 20.5074 5.54543 21.0938 6.89869 21.714C8.0678 22.2499 9.74223 22.624 11.6639 22.6633L11.6667 22.6667L11.6693 22.6634C11.7788 22.6656 11.889 22.6667 12 22.6667C14.0622 22.6667 15.8645 22.2809 17.1013 21.714C18.4546 21.0938 18.6667 20.5074 18.6667 20.3334C18.6667 20.1594 18.4546 19.573 17.1013 18.9528C16.5027 18.6784 15.7715 18.4464 14.9425 18.2801C15.2101 17.8992 15.4839 17.5032 15.7597 17.0961C18.2828 17.7129 20 18.9312 20 20.3334C20 22.3584 16.4183 24.0001 12 24.0001C7.58172 24.0001 4 22.3584 4 20.3334Z"
        fill={style.mainBlue.blue}
      />
    </g>
    <defs>
      <clipPath id="clip0_6074_93702">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
