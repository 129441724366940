import fetchData from 'utils/fetchData';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const getAllClubs = () => {
  const apiToken = localStorage.getItem('token');
  return fetchData(`${process.env._CLUB_BASE_API_URL}api/v01/user/club/`, {
    method: 'GET',
    headers: {
      ...headers,
      tsec: JSON.parse(apiToken),
    },
  }).then((response) => response.json());
};

export const getSingleClub = ({ clubId }) => {
  const apiToken = localStorage.getItem('token');
  return fetchData(
    `${process.env._CLUB_BASE_API_URL}api/v01/user/club/${clubId}`,
    {
      method: 'GET',
      headers: {
        ...headers,
        tsec: JSON.parse(apiToken),
      },
    }
  ).then((response) => response.json());
};

export const getMemberList = ({ clubId }) => {
  const apiToken = localStorage.getItem('token');
  return fetchData(
    `${process.env._CLUB_BASE_API_URL}api/v01/user/club/${clubId}/members`,
    {
      method: 'GET',
      headers: {
        ...headers,
        tsec: JSON.parse(apiToken),
      },
    }
  ).then((response) => response.json());
};

export const postActiveClubInfo = ({ clubId }) => {
  const apiToken = localStorage.getItem('token');
  return fetchData(
    `${process.env._CLUB_BASE_API_URL}api/v01/user/club/activate`,
    {
      method: 'POST',
      headers: {
        ...headers,
        tsec: JSON.parse(apiToken),
      },
      body: JSON.stringify({ clubId }),
    }
  ).then((response) => response.json());
};

export const postJoinClub = ({ clubId, inviteToken }) => {
  const apiToken = localStorage.getItem('token');
  return fetchData(
    `${process.env._CLUB_BASE_API_URL}api/v01/user/club/join/${clubId}`,
    {
      method: 'POST',
      headers: {
        ...headers,
        tsec: JSON.parse(apiToken),
      },
      body: JSON.stringify({ inviteToken }),
    }
  ).then((response) => response.json());
};

export const deleteMember = ({ clubId, memberId }) => {
  const apiToken = localStorage.getItem('token');
  return fetchData(
    `${process.env._CLUB_BASE_API_URL}api/v01/user/club/${clubId}/members/${memberId}`,
    {
      method: 'DELETE',
      headers: {
        ...headers,
        tsec: JSON.parse(apiToken),
      },
    }
  ).then((response) => response.json());
};
