import styled from 'styled-components';
import { style } from 'utils/constants/style';

interface IProps {
  isPopularPostsScrollable?: boolean;
}

export const Wrapper = styled.div`
  position: relative;
`;

export const TitleWrap = styled.div`
  position: absolute;
  top: 10px;
  display: flex;
`;

export const PopOVerWrap = styled.div`
  position: relative;
  left: -20px;
  z-index: 100;
`;
export const CustomListWrapper = styled.div`
  width: 270px;
  height: 200px;
  overflow: hidden;
  margin: 10px 0;

  span {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 100%;
    letter-spacing: 0.02em;
  }
`;

export const Title = styled.div`
  position: relative;
  left: 10px;
  padding-top: 3px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.02em;
`;

export const ListWrap = styled.div`
  position: absolute;
  padding-top: 10px;
  top: 30px;
  height: 260px;
  width: 270px;
  overflow: scroll;
  ::-webkit-scrollbar-track {
    background: none;
  }
  ::-webkit-scrollbar {
    width: 4px;
    height: 100px;
    background: none;
  }
  ::-webkit-scrollbar-thumb {
    background: ${style.mainGray.silver};
    border-radius: 10px;
  }

  img {
    border-radius: 50%;
  }
  div {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 100%;
    letter-spacing: 0.02em;
  }
`;

export const ItemContainer = styled.div`
  :last-of-type {
    margin-bottom: 20px;
  }
`;

export const ItemWrap = styled.div`
  display: flex;
  cursor: pointer;
  margin-bottom: 5px;
  text-overflow: ellipsis;

  img {
    object-fit: cover;
  }
`;

export const PlaceHolderWrapper = styled.div`
  width: 140px;
  position: relative;
  margin: 0 auto;
  top: 50px;

  div {
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.02em;
    color: ${style.mainGray.silver};
  }

  img {
    background: none;
  }
`;

export const ItemDescriptions = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 170px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 9px;
  white-space: nowrap;
  div {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 170px;
    padding-bottom: 4px;
  }
  span {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 100%;
    letter-spacing: 0.02em;
    color: ${style.mainGray.SuvaGrey};
  }
`;

export const EmptyUserPhoto = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #e8f3ff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PopularPostsListWrapper = styled.div<IProps>`
  width: 270px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const PopularPostsList = styled.div`
  width: 130px;
  height: 157px;
  cursor: pointer;

  img {
    border-radius: 8px;
    overflow: hidden;
  }

  margin: 5px 0;
`;
