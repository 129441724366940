/* eslint-disable no-unused-expressions */
import { call, put, takeEvery } from 'redux-saga/effects';

import { recoverAccount, resetPassword } from 'services';
import {
  actionTypes,
  recoverAccountSuccess,
  recoverAccountError,
  resetPasswordSuccess,
  resetPasswordError,
} from './actions';

function* workerRecoverAccount(action) {
  try {
    const resp = yield call(recoverAccount, action.data);

    console.log('Recover', resp);

    if (resp.status === 200) {
      resp.data.ok
        ? yield put(recoverAccountSuccess(resp.data))
        : yield put(recoverAccountError(resp.data.message));
    } else {
      resp.status === 500
        ? yield put(recoverAccountError('Server error'))
        : yield put(recoverAccountError('Email format error'));
    }
  } catch (err) {
    yield put(recoverAccountError(err));
  }
}

function* workerResetPassword(action) {
  try {
    const resp = yield call(resetPassword, action.data);

    console.log('Reset', resp);

    if (resp.status === 200) {
      resp.data.ok
        ? yield put(resetPasswordSuccess(resp.data))
        : yield put(resetPasswordError(resp.data.message));
    } else {
      resp.status === 500
        ? yield put(resetPasswordError('Server error'))
        : yield put(resetPasswordError('Bad params'));
    }
  } catch (err) {
    yield put(resetPasswordError(err));
  }
}

function* watchResetPassword() {
  yield takeEvery(actionTypes.RECOVER_ACCOUNT_START, workerRecoverAccount);
  yield takeEvery(actionTypes.RESET_PASSWORD_START, workerResetPassword);
}

export default watchResetPassword;
