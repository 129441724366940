import React from 'react';

// export const MailIcon = ({ size = '24' }: { size?: string }) => (
//   <svg
//     width={size}
//     height={size}
//     viewBox="0 0 24 24"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <path
//       d="M19.6562 5H4.34375C3.05141 5 2 6.05141 2 7.34375V17.3438C2 18.6361 3.05141 19.6875 4.34375 19.6875H19.6562C20.9486 19.6875 22 18.6361 22 17.3438V7.34375C22 6.05141 20.9486 5 19.6562 5ZM20.4375 17.3438C20.4375 17.7745 20.087 18.125 19.6562 18.125H4.34375C3.91297 18.125 3.5625 17.7745 3.5625 17.3438V7.34375C3.5625 6.91297 3.91297 6.5625 4.34375 6.5625H19.6562C20.087 6.5625 20.4375 6.91297 20.4375 7.34375V17.3438Z"
//       fill="#8D8D8D"
//     />
//     <path
//       d="M20.305 5.97412L12.0001 12.1513L3.69527 5.97412L2.7627 7.22783L12.0001 14.0987L21.2375 7.22783L20.305 5.97412Z"
//       fill="#8D8D8D"
//     />
//   </svg>
// );

export const MailIcon = ({ size = '24' }: { size?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.6562 5H4.34375C3.05141 5 2 6.05141 2 7.34375V17.3438C2 18.6361 3.05141 19.6875 4.34375 19.6875H19.6562C20.9486 19.6875 22 18.6361 22 17.3438V7.34375C22 6.05141 20.9486 5 19.6562 5ZM20.4375 17.3438C20.4375 17.7745 20.087 18.125 19.6562 18.125H4.34375C3.91297 18.125 3.5625 17.7745 3.5625 17.3438V7.34375C3.5625 6.91297 3.91297 6.5625 4.34375 6.5625H19.6562C20.087 6.5625 20.4375 6.91297 20.4375 7.34375V17.3438Z"
      fill="#8D8D8D"
    />
    <path
      d="M20.305 5.97412L12.0001 12.1513L3.69527 5.97412L2.7627 7.22783L12.0001 14.0987L21.2375 7.22783L20.305 5.97412Z"
      fill="#8D8D8D"
    />
  </svg>
);
