import styled from 'styled-components';

export const MapBoxContainer = styled.div`
  width: 100%;
  height: 320px;
  position: relative;

  img {
    background: none;
  }

  .mapbox-logo {
    display: none;
  }
  .mapboxgl-ctrl-logo {
    display: none !important;
  }
  .mapbox-improve-map {
    display: none;
  }
  .mapboxgl-ctrl-compass {
    display: none;
  }
  .mapboxgl-ctrl-logo {
    display: none !important;
  }
  .mapboxgl-popup-content {
    padding: 0px;
    border-radius: 5px;
    overflow: hidden;
  }
`;
