import styled from 'styled-components';

import { style, deviceMaxWidth } from 'utils/constants/style';

interface IProps {
  mt?: number;
  mb?: number;
  isFilter?: boolean;
  height?: number | string;
  isCover?: boolean;
  top?: number;
  right?: number;
  fullWidth?: boolean;
  backgroundColor?: string;
  width?: number;
  rounded?: boolean;
  isPhotoPost?: boolean;
  wrapperHeight?: string;
  isSmallImages?: boolean;
  autoRatio?: boolean;
  objectFit?: string;
}

export const Wrapper = styled.div<IProps>`
  position: relative;
  width: 100%;
  height: ${({ wrapperHeight }) => wrapperHeight || '160px'};
  max-height: ${({ height }) => (height ? `${height}px` : '360px')};
  border-radius: 6px;
  display: flex;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || style.mainGray.whiteSmokeColor};
  justify-content: center;
`;

export const PickerIcon = styled.div<IProps>`
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '6px')};
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (${deviceMaxWidth.sm}) {
    ${({ isSmallImages }) => {
      if (isSmallImages) {
        return `
          position: absolute;
          top: 50%;
          margin-top: -12px;
        `;
      }

      return `
        position: absolute;
        top: 0
      `;
    }}
  }
`;

export const PickerLabel = styled.p`
  width: 100%;
  max-width: 138px;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: ${style.mainGray.gray};
`;

export const FileInput = styled.input`
  width: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  cursor: pointer;
  z-index: 1;
`;

export const UploadedImage = styled.img<IProps>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  /* ${({ width }) => width && `width: ${width}px`}; */
  /* ${({ width }) => width > 360 && `height: ${width}`}; */
  object-fit: ${({ objectFit }) => objectFit || 'cover'};
  border-radius: ${({ rounded }) => (rounded ? '50%' : '6px')};
  max-width: 100%;
`;

export const MultipleImagesWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const SmallImageContainerWrap = styled.div<IProps>`
  display: block;
  position: relative;
  width: 64px;
  height: 64px;
  border-radius: 8px;
  display: flex;
  background-color: ${style.mainGray.whiteSmokeColor};
  justify-content: center;
  text-align: center;
  margin-top: 8px;
  margin-right: 10px;
  filter: ${({ isFilter }) =>
    isFilter ? 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.06))' : 'none'};

  :last-of-type {
    margin-right: 0;
  }

  @media only screen and (${deviceMaxWidth.sm}) {
    width: 19%;
    height: 0;
    margin-right: 1.25%;
    padding-top: 19%;
  }
`;

export const SmallImageWrap = styled.div`
  width: 64px;
  height: 64px;
  overflow: hidden;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  cursor: pointer;

  @media only screen and (${deviceMaxWidth.sm}) {
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

export const CloseIconWrap = styled.div`
  height: 24px;
  width: 24px;
  background-color: ${style.mainWhite.white};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
  position: absolute;
  top: -4px;
  right: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const UserImagePicker = styled.div`
  position: absolute;
  width: 77px;
  height: 77px;
  border-radius: 50%;
  border: 3px solid ${style.mainWhite.white};
  background-color: ${style.mainGray.whiteSmokeColor};
  bottom: -18px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ProfilePhotoWrap = styled.div`
  overflow: hidden;
  width: 77px;
  height: 77px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CoverPhotoWrap = styled.div<IProps>`
  overflow: hidden;
  width: 100%;
  height: ${({ height, autoRatio }) =>
    // eslint-disable-next-line no-nested-ternary
    height ? (autoRatio ? 'auto' : `${height}px`) : '240px'};
  display: flex;
  justify-content: center;
  border-radius: 6px;
  position: relative;
`;

export const EditIconWrapper = styled.div<IProps>`
  cursor: pointer;
  position: absolute;
  top: ${({ isCover }) => (isCover ? '10px' : '0')};
  right: ${({ isCover }) => (isCover ? '10px' : '0')};
`;

export const PopOverWrap = styled.div<IProps>`
  position: absolute;
  top: ${({ top }) => (top ? `${top}px` : '45px')};
  right: ${({ right }) => (right ? `${right}px` : '12px')};
  z-index: 100;
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
`;

export const ChangeImgButton = styled.div`
  width: 32px;
  height: 32px;
  background: ${style.mainBlue.blue};
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RoundedImageWrap = styled.div<IProps>`
  width: ${({ width }) => (width ? `${width}px` : '150px')};
  height: ${({ height }) => (height ? `${height}px` : '150px')};
  border-radius: 50%;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || style.mainGray.whiteSmokeColor};
  position: relative;
  display: flex;
  justify-content: center;
`;

export const GroupChatPhotoWrap = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
`;
