import styled from 'styled-components';

interface IProps {
  expanded?: boolean;
}

export const ModalOverlay = styled.div<IProps>`
  position: fixed;
  inset: 0;
  z-index: ${(props) => (props?.expanded ? 999 : 1000)};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.expanded ? 'transparent' : 'rgba(0, 0, 0, 0.2)'};
`;

export const ModalContainer = styled.div<IProps>`
  position: relative;
  width: 500px;
  background-color: white;
  padding: 30px;
  border-top: ${(props) => (props?.expanded ? '1px solid #ececec' : '16px')};
  box-shadow: ${(props) =>
    props?.expanded ? 'none' : props.theme.colors.shadows.sm};
  border-radius: ${(props) => (props?.expanded ? '0' : '16px')};

  @media (max-width: 575px) {
    width: auto !important;
  }
`;

export const CrossIconWrapper = styled.span`
  position: absolute;
  width: 24px;
  height: 24px;
  right: 15px;
  top: 16px;
  z-index: 100;
  cursor: pointer;
`;

export const ModalWrapper = styled.div<IProps>`
  height: ${(props) =>
    props?.expanded ? 'calc(100vh - 60px)' : 'calc(100vh - 100px)'};
  padding: ${(props) => (props?.expanded ? '0' : '100px 0')};
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;
