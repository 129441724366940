import React from 'react';

export const ShieldCheckIcon = () => (
  <svg
    width="132"
    height="132"
    viewBox="0 0 132 132"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M66 121C66 121 110 99 110 66V27.5L66 11L22 27.5V66C22 99 66 121 66 121Z"
      stroke="#C2C2C2"
      strokeWidth="8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M44 63.25L60.5 79.75L93.5 46.75"
      stroke="#C2C2C2"
      strokeWidth="8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
