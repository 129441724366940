import React from 'react';

export const SmileyIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0ZM8 1.06667C4.17083 1.06667 1.06667 4.17083 1.06667 8C1.06667 11.8292 4.17083 14.9333 8 14.9333C11.8292 14.9333 14.9333 11.8292 14.9333 8C14.9333 4.17083 11.8292 1.06667 8 1.06667ZM12.0999 8.62692C12.3575 8.76968 12.4507 9.09428 12.3079 9.35192C11.5036 10.8034 9.84143 11.7333 8 11.7333C6.16333 11.7333 4.50477 10.8082 3.69803 9.36259C3.5545 9.10538 3.64665 8.78051 3.90386 8.63697C4.16107 8.49343 4.48594 8.58558 4.62948 8.84279C5.24324 9.94261 6.54132 10.6667 8 10.6667C9.46238 10.6667 10.7631 9.93892 11.3749 8.83491C11.5177 8.57727 11.8423 8.48415 12.0999 8.62692ZM4.8 5.86667C5.09455 5.86667 5.33333 6.10545 5.33333 6.4C5.33333 6.69455 5.09455 6.93333 4.8 6.93333C4.50545 6.93333 4.26667 6.69455 4.26667 6.4C4.26667 6.10545 4.50545 5.86667 4.8 5.86667ZM11.2 5.86667C11.4946 5.86667 11.7333 6.10545 11.7333 6.4C11.7333 6.69455 11.4946 6.93333 11.2 6.93333C10.9054 6.93333 10.6667 6.69455 10.6667 6.4C10.6667 6.10545 10.9054 5.86667 11.2 5.86667Z"
      fill="#C2C2C2"
    />
  </svg>
);
