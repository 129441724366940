/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
import axios from 'axios';
import fetchData from 'utils/fetchData';
// import { API_BASE_URL } from 'src/constants';

export const createFormData = (photoUri, body) => {
  const data = new FormData();

  // these values should be placed before 'file' key-value.
  Object.keys(body).forEach((key) => {
    data.append(key, body[key]);
  });

  // data.append('region', 'us-west-2');
  data.append('Content-Type', 'image/jpeg');
  data.append('acl', 'public-read');

  data.append('file', photoUri);
  // data.append('file', photoUri);
  // data.append('uri', photoUri);

  return data;
};
export async function uploadToS3(fileContents) {
  let output = [];
  if (fileContents?.length > 0) {
    for (let i = 0; fileContents?.length > i; i++) {
      const presignedPostUrl = await getPresignedPostUrl();

      const resp = await fetch(fileContents[i]);
      const imageBody = await resp.blob();

      const formData = createFormData(imageBody, presignedPostUrl.fields);
      await axios.post(presignedPostUrl.url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      output.push(`${presignedPostUrl?.url}/${presignedPostUrl?.fields?.Key}`);
    }
  }

  return output;
}

async function getPresignedPostUrl() {
  try {
    const token =
      typeof window !== 'undefined' ? localStorage.getItem('token') : '';
    const res = await fetchData(
      `${process.env._DB_HOSTv12}api/v12/media/presigned`,
      {
        method: 'GET',
        headers: {
          tsec: JSON.parse(token),
        },
      }
    );
    const data = await res.json();
    return data?.data;
  } catch (error) {
    console.log(error);
  }
}
