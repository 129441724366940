/* eslint-disable react/display-name */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';

import i18n from 'utils/i18n';
import {
  LinkIcon,
  GroupsIcon,
  UsersIcon,
  EventsIcon,
  LocationIcon,
  CrossIcon,
  ArrowBack,
} from 'public/assets/icons';
import {
  openSidebarTag,
  actionTypes,
  closeSideBarCreateLinkPost,
  removeTag,
  addTags,
  openSidebarChooseLocation,
} from 'store/sideBarControler/actions';
import { getInterestsStart } from 'store/user/actions';
import {
  createPostStart,
  clearCreatePostReqStat,
  parseLinkStart,
  editPostStart,
  clearEditPostStatus,
} from 'store/posts/actions';
import { AsyncStatus } from 'utils/types';
import { regexMap } from 'utils/constants/regex';
import { style } from 'utils/constants/style';
import { ERROR_MESSAGES } from 'utils/constants';
import { SidebarWrapper } from '../index';
import {
  InputField,
  Selector,
  Pill,
  ButtonsGroup,
  CustomDatePicker,
  ErrorMessage,
  InputDescription,
} from '../../index';
import {
  Wrapper,
  SectionWrapper,
  SectionHeader,
  TagsWrap,
  TagItemWrap,
  QuickTagsWrap,
  TagImageWrap,
  LinkInfoWrap,
  LinkInfoContentWrap,
  LinkInfoDescription,
  LinkInfoSite,
  LocationText,
  SeparateSettingsHeader,
} from './CreateLinkPost.style';
import { ICreateLinkPost } from './ICreateLinkPost';

const TagsMock = () => [
  {
    id: 0,
    title: i18n.t('search:groupsTab'),
    value: 'groups',
    icon: <GroupsIcon />,
  },
  { id: 1, title: i18n.t('common:users'), value: 'users', icon: <UsersIcon /> },
  {
    id: 2,
    title: i18n.t('search:eventsTab'),
    value: 'events',
    icon: <EventsIcon />,
  },
  {
    id: 3,
    title: i18n.t('addNewGroup:cityOrLocation'),
    value: 'location',
    icon: <LocationIcon />,
  },
];

const CreateLinkPost = React.memo(
  ({ postForEditData, defaultTagId, privateFeatures }: ICreateLinkPost) => {
    const [link, setLink] = useState<string>('');
    const [description, setDescription] = useState<string>(
      postForEditData ? postForEditData?.text : ''
    );
    const [quickTags, setQuickTags] = useState([]);
    const [isLinkFormatError, setIsLinkFormatError] = useState(false);
    const [isSubmitAvailable, setIsSubmitAvailable] = useState(false);
    const [isScheduledPost, setIsScheduledPost] = useState(false);
    const [scheduledPostData, setScheduledPostData] = useState({
      date: new Date(),
      error: '',
    });

    const dispatch = useDispatch();
    const { t } = useTranslation();

    const tagsData = useSelector((state) => state.sideBarControler.tagsData);
    const { interests } = useSelector((state) => state.user);
    const { createPostReqStat, editPostReqStat, linkInfo, parseLinkError } =
      useSelector((state) => state.posts);

    useEffect(() => {
      if (!interests) {
        dispatch(getInterestsStart());
      }

      if (parseLinkError) {
        setIsLinkFormatError(true);
      }
    }, [dispatch, interests, parseLinkError]);

    useEffect(() => {
      if (createPostReqStat === AsyncStatus.SUCCESS) {
        dispatch(closeSideBarCreateLinkPost());
        dispatch(clearCreatePostReqStat());
      }

      if (editPostReqStat === AsyncStatus.SUCCESS) {
        dispatch(closeSideBarCreateLinkPost());
        // dispatch(clearEditPostStatus());
      }
    }, [createPostReqStat, dispatch, editPostReqStat]);

    useEffect(() => {
      if (postForEditData) {
        const {
          link: linkForEdit,
          text,
          groups,
          users,
          events,
          location,
          interests: interestsForEdit,
        } = postForEditData;

        const postText = text
          .split(' ')
          .filter((el) => !el.includes('#'))
          .join(' ');

        setLink(linkForEdit);
        setDescription(postText);
        setQuickTags(
          interests?.filter((el) => interestsForEdit?.includes(el._id)) || []
        );

        dispatch(addTags({ type: 'groups', tags: groups }));
        dispatch(addTags({ type: 'users', tags: users }));
        dispatch(addTags({ type: 'events', tags: events }));
        dispatch(addTags({ type: 'location', tags: location }));
        dispatch(parseLinkStart({ link: linkForEdit }));
        setIsSubmitAvailable(true);
      }
    }, [postForEditData]);

    const onLinkInputBlur = (e) => {
      if (regexMap.url.test(link)) {
        dispatch(parseLinkStart({ link }));
        setIsSubmitAvailable(true);
      } else {
        setIsLinkFormatError(true);
      }
    };

    const onPostTimeChange = (newDate) => {
      if (newDate <= new Date()) {
        setScheduledPostData({
          date: newDate,
          error: ERROR_MESSAGES().scheduledPostError,
        });
      } else {
        setScheduledPostData({ date: newDate, error: '' });
      }
    };

    const onSubmit = () => {
      const getIdsArray = (array) => array.map((elem) => elem._id);

      const hashtags = quickTags.map((el) => `#${el.name} `);
      const text = `${description} ${hashtags.join('')}`;
      const defaultLocation = {
        lat: undefined,
        lng: undefined,
        name: '',
      };

      const tagsFullInfo = {
        events: tagsData.events || [],
        users: tagsData.users || [],
        groups: tagsData.groups || [],
      };

      const postData = {
        type: 3,
        link,
        text,
        location: tagsData.location || defaultLocation,
        events: (tagsData.events && getIdsArray(tagsData.events)) || [],
        users: (tagsData.users && getIdsArray(tagsData.users)) || [],
        groups: (tagsData.groups && getIdsArray(tagsData.groups)) || [],
        interests: getIdsArray(quickTags) || [],
        publishAt: isScheduledPost ? scheduledPostData.date.getTime() : '',
      };

      if (postForEditData) {
        dispatch(
          editPostStart({ postId: postForEditData._id, postData, tagsFullInfo })
        );
      } else {
        dispatch(createPostStart({ postData, defaultTagId }));
      }
    };

    const tagElem = (
      id: string,
      image: string,
      title: string,
      type: string
    ) => (
      <Pill
        text={title}
        onClick={() => dispatch(removeTag({ id, type }))}
        iconRight={<CrossIcon width="10" height="10" />}
        fontSize={12}
        margin="0 10px 8px 0"
        key={id}
        iconLeft={
          <TagImageWrap>
            <Image
              width={26}
              height={26}
              src={
                image.trim().length ? image : '/assets/img/emptyUserPhoto.png'
              }
              alt="tag"
              unoptimized
              priority
            />
          </TagImageWrap>
        }
      />
    );

    const tagsList = (selectedTags, type) =>
      selectedTags.map((tag) => {
        if (type === 'events') {
          return tagElem(tag._id, tag.coverPhoto, tag.name, type);
        }

        if (type === 'users' || type === 'groups') {
          return tagElem(tag._id, tag.profilePhoto, tag.username, type);
        }

        return null;
      });

    const tags = TagsMock().map((tag) => {
      const selectedTags = tagsData[tag.value];

      const renderSelectedTags = () => {
        if (
          tag.value === 'location'
            ? !selectedTags
            : !selectedTags || !selectedTags.length
        ) {
          return;
        }

        return tag.value === 'location' ? (
          <LocationText>{selectedTags.name}</LocationText>
        ) : (
          tagsList(selectedTags, tag.value)
        );
      };

      return (
        <TagItemWrap key={tag.id}>
          <Selector
            title={tag.title}
            isSelectedItemsExist={
              tag.value === 'location'
                ? selectedTags
                : selectedTags && !!selectedTags.length
            }
            leftIcon={tag.icon}
            onClick={() => {
              if (tag.value === 'location') {
                dispatch(openSidebarChooseLocation());
              } else {
                dispatch(
                  openSidebarTag({
                    actionType: actionTypes.OPEN_SIDEBAR_TAG,
                    tagType: tag.value,
                  })
                );
              }
            }}
          />
          {renderSelectedTags()}
        </TagItemWrap>
      );
    });

    const quickTagsList = useMemo(
      () =>
        interests &&
        interests.map((interest) => {
          const newQuickTags = quickTags.filter(
            (tag) => tag._id !== interest._id
          );

          const onQuickTagClick = () => {
            if (newQuickTags.length === quickTags.length) {
              setQuickTags([...quickTags, interest]);
            } else {
              setQuickTags(newQuickTags);
            }
          };

          return (
            <Pill
              text={interest.name}
              onClick={onQuickTagClick}
              isSelected={quickTags.some((el) => el._id === interest._id)}
              fontSize={12}
              margin="0 10px 8px 0"
              key={interest._id}
            />
          );
        }),
      [interests, quickTags]
    );

    const getSubmitAvailability = () => {
      let submit = true;

      if (postForEditData) {
        const {
          link: linkForEdit,
          text,
          groups,
          users,
          events,
          interests: interestsForEdit,
          location,
        } = postForEditData;

        const defaultPostData = {
          link: linkForEdit,
          description: text,
          quickTags: interestsForEdit || [],
          groups,
          users,
          events,
          location,
        };
        const postData = {
          link,
          description,
          quickTags,
          groups: tagsData.groups,
          users: tagsData.users,
          events: tagsData.events,
          location: tagsData.location,
        };

        if (JSON.stringify(defaultPostData) === JSON.stringify(postData)) {
          submit = false;
        }
      }

      if ((isLinkFormatError && !isSubmitAvailable) || !link.length) {
        submit = false;
      }

      if (isScheduledPost) {
        const { date, error } = scheduledPostData;

        if (error || date < new Date()) {
          submit = false;
        }
      }
      if (isLinkFormatError) {
        submit = false;
      }

      return submit;
    };

    return (
      <SidebarWrapper
        headerText={postForEditData ? t('link:editLink') : t('link:createLink')}
        headerIcon={<ArrowBack />}
        submitText={
          postForEditData ? t('editUserProfile:save') : t('feed:create')
        }
        isSubmitAvailable={getSubmitAvailability()}
        onSubmit={onSubmit}
        onIconClick={() => dispatch(closeSideBarCreateLinkPost())}
      >
        <Wrapper>
          <SectionWrapper pt={0}>
            <SectionHeader>{t('event:mainInformation')}</SectionHeader>
            <InputField
              value={link}
              onChange={(e) => {
                setLink(e.target.value);
                isLinkFormatError && setIsLinkFormatError(false);
                isSubmitAvailable && setIsSubmitAvailable(false);
              }}
              label={t('common:addADescription')}
              placeholder={t('link:typeOrPasteLink')}
              crossIcon
              onCrossClick={() => {
                setLink('');
                setIsSubmitAvailable(false);
              }}
              iconLeft={<LinkIcon />}
              margin="0 0 17px 0"
              onBlur={onLinkInputBlur}
              disabled={Boolean(postForEditData)}
            />
            {isLinkFormatError && !!link.length && (
              <ErrorMessage
                text={t('link:incorrectLinkFormat')}
                mt={-11}
                mb={17}
              />
            )}
            {linkInfo && !isLinkFormatError && isSubmitAvailable && (
              <LinkInfoWrap backgroundImage={linkInfo.ogImage.url}>
                <LinkInfoContentWrap>
                  <LinkInfoDescription>{linkInfo.ogTitle}</LinkInfoDescription>
                  <LinkInfoSite>
                    {`${linkInfo.host.domain}.${linkInfo.host.tld}`}
                  </LinkInfoSite>
                </LinkInfoContentWrap>
              </LinkInfoWrap>
            )}

            {/* <InputField
              type="textarea"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder={t('common:addADescription')}
              label={t('common:addADescription')}
              crossIcon
              onCrossClick={() => setDescription('')}
              showCharactersNumber
              maxLength={500}
              height={88}
            /> */}
            <InputDescription
              defaultValue={description}
              onChange={(e) => setDescription(e)}
              placeholder={t('common:addADescription')}
              label={t('common:addADescription')}
              crossIcon
              onCrossClick={() => setDescription('')}
              showCharactersNumber
              maxLength={500}
              height={122}
            />
          </SectionWrapper>

          <SectionWrapper>
            <SectionHeader>{t('common:addTags')}</SectionHeader>
            <TagsWrap>{tags}</TagsWrap>
          </SectionWrapper>

          <SectionWrapper>
            <SectionHeader>{t('common:quickTags')}</SectionHeader>
            <QuickTagsWrap>{quickTagsList}</QuickTagsWrap>
          </SectionWrapper>

          {privateFeatures && (
            <SectionWrapper>
              <SeparateSettingsHeader mb={6}>
                {t('common:schedulePost')}
              </SeparateSettingsHeader>
              <ButtonsGroup
                itemList={[
                  { id: 0, name: t('common:no') },
                  { id: 1, name: t('common:yes') },
                ]}
                isSelectedId={isScheduledPost ? 1 : 0}
                onClick={(newValue) => setIsScheduledPost(Boolean(newValue))}
                inactiveFieldsBackgroundColor={style.mainGray.whiteSmokeColor}
              />

              {isScheduledPost && (
                <>
                  <SeparateSettingsHeader mb={6} mt={16}>
                    {t('common:date')}
                  </SeparateSettingsHeader>
                  <CustomDatePicker
                    selectedDate={scheduledPostData.date}
                    onChange={onPostTimeChange}
                    minDate={new Date()}
                  />
                  {scheduledPostData.error && (
                    <ErrorMessage text={scheduledPostData.error} mt={6} />
                  )}
                </>
              )}
            </SectionWrapper>
          )}
        </Wrapper>
      </SidebarWrapper>
    );
  }
);

export default CreateLinkPost;
