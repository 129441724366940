import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  img?: string;
  isFullRounded?: boolean;
  mb?: number;
  cursor?: string;
}

export const MessageContainer = styled.div<IProps>`
  display: flex;
  width: 100%;
  margin-bottom: ${({ isFullRounded }) => (isFullRounded ? '10px' : '6px')};
`;

export const UserProfilePhotoWrap = styled.div`
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  align-self: flex-end;
  margin-right: 8px;
`;

export const UserProfilePhoto = styled.span<IProps>`
  display: block;
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  ${({ img }) =>
    img &&
    `background: url(${img}) no-repeat;
     background-size: cover; background-position: center;`};
  cursor: ${({ cursor }) => cursor || 'auto'};
`;

export const MessageWrap = styled.div<IProps>`
  padding: 10px;
  color: ${style.mainGray.whiteSmokeColor};
  border-radius: ${({ isFullRounded }) =>
    isFullRounded ? '16px' : '16px 16px 16px 0'};
  background-color: ${style.mainGray.whiteSmokeColor};
  max-width: 84%;
`;

export const MessageTextWrapper = styled.p<IProps>`
  font-size: 13px;
  line-height: 130%;
  color: ${style.mainBlack.black};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
`;

export const MessageHeaderText = styled(MessageTextWrapper)`
  line-height: 100%;
  font-weight: 700;
  font-size: 14px;
  line-height: 130%;
  cursor: pointer;
`;

export const TimeText = styled.p`
  font-size: 12px;
  line-height: 100%;
  font-weight: 300;
  color: ${style.mainGray.SuvaGrey};
  margin-left: 50px;
  margin-bottom: 12px;
`;
