/* eslint-disable react/prop-types */
import React from 'react';
import { style } from 'utils/constants/style';

export const VerifiedIcon = ({
  color = style.mainYellow.yellow,
}: {
  color?: string;
}) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_6172_40778"
      maskUnits="userSpaceOnUse"
      x="1"
      y="1"
      width="14"
      height="15"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 1.5H15V15.5H1V1.5Z"
        fill={style.mainWhite.white}
      />
    </mask>
    <g mask="url(#mask0_6172_40778)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 8.82812C15 9.31755 14.8824 9.77148 14.6473 10.1871C14.4121 10.6027 14.0977 10.9281 13.7012 11.1551C13.7121 11.2289 13.7176 11.3438 13.7176 11.4996C13.7176 12.2406 13.4688 12.8695 12.9766 13.3891C12.4816 13.9113 11.8855 14.1711 11.1883 14.1711C10.8766 14.1711 10.5785 14.1137 10.2969 13.9988C10.0781 14.4473 9.76367 14.8082 9.35078 15.0844C8.94063 15.3633 8.48945 15.5 8 15.5C7.49961 15.5 7.0457 15.366 6.64102 15.0926C6.23359 14.8219 5.92188 14.4582 5.70312 13.9988C5.42148 14.1137 5.12617 14.1711 4.81172 14.1711C4.11445 14.1711 3.51562 13.9113 3.01523 13.3891C2.51484 12.8695 2.26602 12.2379 2.26602 11.4996C2.26602 11.4176 2.27695 11.3027 2.29609 11.1551C1.89961 10.9254 1.58516 10.6027 1.35 10.1871C1.11758 9.77148 1 9.31755 1 8.82812C1 8.30859 1.13125 7.83008 1.39102 7.39805C1.65078 6.96602 2.00078 6.64609 2.43828 6.43828C2.32344 6.12656 2.26602 5.81211 2.26602 5.50039C2.26602 4.76211 2.51484 4.13047 3.01523 3.61094C3.51562 3.09141 4.11445 2.82891 4.81172 2.82891C5.12344 2.82891 5.42148 2.88633 5.70312 3.00117C5.92188 2.55273 6.23633 2.1918 6.64922 1.91563C7.05937 1.63945 7.51055 1.5 8 1.5C8.48945 1.5 8.94063 1.63945 9.35078 1.91289C9.76094 2.18906 10.0781 2.55 10.2969 2.99844C10.5785 2.88359 10.8738 2.82617 11.1883 2.82617C11.8855 2.82617 12.4816 3.08594 12.9766 3.6082C13.4715 4.13047 13.7176 4.75938 13.7176 5.49766C13.7176 5.84219 13.6656 6.15391 13.5617 6.43555C13.9992 6.64336 14.3492 6.96328 14.609 7.39531C14.8687 7.83008 15 8.30859 15 8.82812Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.70234 10.9363L10.5926 6.60779C10.6664 6.49294 10.6883 6.36716 10.6637 6.23318C10.6363 6.09919 10.568 5.99255 10.4531 5.92146C10.3383 5.84763 10.2125 5.82302 10.0785 5.84216C9.9418 5.86404 9.83242 5.92966 9.75039 6.04451L7.20469 9.87266L6.03164 8.70232C5.92773 8.59841 5.80742 8.54919 5.67344 8.55466C5.53672 8.56016 5.41914 8.60935 5.31523 8.70232C5.22227 8.79529 5.17578 8.91286 5.17578 9.05505C5.17578 9.19451 5.22227 9.31211 5.31523 9.40781L6.92578 11.0183L7.00508 11.0812C7.09805 11.1441 7.19375 11.1742 7.28672 11.1742C7.46992 11.1715 7.60938 11.0949 7.70234 10.9363Z"
        fill={style.mainWhite.white}
      />
    </g>
  </svg>
);
