import React from 'react';
import { style } from 'utils/constants/style';

export const GeoLocationIcon = ({
  size = '16',
  color = style.mainBlue.blue,
}: {
  size?: string;
  color?: string;
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 12.5288C10.9167 9.53989 12.375 7.97424 12.375 5.26994C12.375 2.91172 10.4162 1 8 1C5.58375 1 3.625 2.91172 3.625 5.26994C3.625 7.97424 5.08333 9.53989 8 12.5288ZM8 4.9707C7.86193 4.9707 7.75 5.08263 7.75 5.2207C7.75 5.35877 7.86193 5.4707 8 5.4707C8.13807 5.4707 8.25 5.35877 8.25 5.2207C8.25 5.08263 8.13807 4.9707 8 4.9707ZM8 6.9707C8.9665 6.9707 9.75 6.1872 9.75 5.2207C9.75 4.2542 8.9665 3.4707 8 3.4707C7.0335 3.4707 6.25 4.2542 6.25 5.2207C6.25 6.1872 7.0335 6.9707 8 6.9707ZM12.6169 11.7255C12.0878 11.5387 11.4503 11.3811 10.7327 11.2662C11.0275 10.9699 11.3031 10.6827 11.5594 10.4017C13.6189 10.8317 15 11.6236 15 12.5294C15 13.8938 11.866 14.9998 8 14.9998C4.13401 14.9998 1 13.8938 1 12.5294C1 11.6236 2.38108 10.8317 4.44056 10.4017C4.69691 10.6827 4.97248 10.9699 5.26725 11.2662C4.54973 11.3811 3.91219 11.5387 3.38306 11.7255C2.81145 11.9272 2.41998 12.1443 2.19077 12.3356C2.07759 12.43 2.02919 12.4952 2.00942 12.5294C2.02919 12.5635 2.07759 12.6287 2.19077 12.7232C2.41998 12.9144 2.81145 13.1315 3.38306 13.3332C4.51725 13.7335 6.14952 13.9998 8 13.9998C9.85048 13.9998 11.4827 13.7335 12.6169 13.3332C13.1885 13.1315 13.58 12.9144 13.8092 12.7232C13.9224 12.6287 13.9708 12.5635 13.9906 12.5294C13.9708 12.4952 13.9224 12.43 13.8092 12.3356C13.58 12.1443 13.1885 11.9272 12.6169 11.7255ZM14.0033 12.5015C14.0034 12.5015 14.0031 12.5032 14.0019 12.5064C14.0025 12.5031 14.0031 12.5015 14.0033 12.5015ZM1.99675 12.5015C1.99688 12.5015 1.99747 12.5031 1.99811 12.5063C1.99693 12.5032 1.99661 12.5015 1.99675 12.5015ZM1.99675 12.5572C1.99661 12.5572 1.99693 12.5556 1.99811 12.5524C1.99747 12.5556 1.99688 12.5572 1.99675 12.5572ZM14.0019 12.5524C14.0031 12.5556 14.0034 12.5572 14.0033 12.5572C14.0031 12.5572 14.0025 12.5556 14.0019 12.5524Z"
      fill={color}
    />
  </svg>
);
