/* eslint-disable react/display-name */
import React from 'react';
import StickyBox from 'react-sticky-box';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  CalendarIcon,
  PopularPostsIcon,
  GroupsIcon,
} from 'public/assets/icons';
import { routesPath } from 'utils/constants/routePath';
import { style } from 'utils/constants/style';
import { CustomList, LoadingComponent } from 'components';
import { AsyncStatus } from 'utils/types';
import DonationMetricBanner from 'components/DonationMetricBanner';
import { IContentContainer } from './IContentContainer';
import {
  ContentContainerWrap,
  ContentWrap,
  SideWrap,
  WrapMyEvents,
  MyEventsWrap,
  MyPopularPostsWrap,
  StickyBoxWrap,
  WrapDonationBanner,
} from './ContentContainer.style';

const ContentContainer: React.FC<IContentContainer> = React.memo(
  ({ children, isHomeWithNearby }: IContentContainer) => {
    const router = useRouter();
    const { t } = useTranslation();

    const {
      sideGroups: listGroups,
      sideMyEvents: listEvents,
      getSideGroupsReqStat,
      getSideMyEventsReqStat,
    } = useSelector((state) => state.user);
    const { popularPosts } = useSelector((state) => state.posts);

    const renderMyEvents = () => {
      if (getSideMyEventsReqStat === AsyncStatus.LOADING) {
        return (
          <LoadingComponent
            fullWidth
            color="#C2C2C2"
            variant="spin"
            wrapHeight={200}
            loaderSize={75}
          />
        );
      }
      return (
        getSideMyEventsReqStat === AsyncStatus.SUCCESS && (
          <CustomList
            list={listEvents}
            title={t('myEvents:headerTitle')}
            icon={<CalendarIcon />}
          />
        )
      );
    };

    const renderMyGroups = () => {
      if (getSideGroupsReqStat === AsyncStatus.LOADING) {
        return (
          <LoadingComponent
            fullWidth
            color="#C2C2C2"
            variant="spin"
            wrapHeight={200}
            loaderSize={75}
          />
        );
      }

      return (
        getSideGroupsReqStat === AsyncStatus.SUCCESS && (
          <CustomList
            type="my_groups"
            list={listGroups}
            title={t('myGroups:headerTitle')}
            icon={<GroupsIcon stroke={style.mainBlue.blue} />}
          />
        )
      );
    };

    const getOffsetTop = () =>
      router.pathname === routesPath.feedPath ||
      router.pathname === routesPath.my_events ||
      router.pathname === routesPath.my_groups
        ? 64
        : 56;

    return (
      <ContentWrap className="row">
        <ContentContainerWrap>{children}</ContentContainerWrap>

        <StickyBoxWrap>
          <StickyBox
            offsetTop={getOffsetTop()}
            offsetBottom={20}
            className="sticky_side_content"
          >
            <SideWrap>
              {(router.pathname === routesPath.feedPath ||
                router.pathname === routesPath.my_events ||
                router.pathname === routesPath.my_groups ||
                router.pathname === routesPath.my_garage ||
                router.pathname === '/vehicles_attending/[id]' ||
                router.pathname === '/group_garage/[id]') && (
                <>
                  {/* <WrapDonationBanner>
                    <DonationMetricBanner variant="sidebar" />
                  </WrapDonationBanner> */}
                  {!!listEvents?.length && (
                    <WrapMyEvents>
                      <MyEventsWrap>{renderMyEvents()}</MyEventsWrap>
                    </WrapMyEvents>
                  )}
                  {!!listGroups?.length && (
                    <WrapMyEvents mb={17}>
                      <MyEventsWrap>{renderMyGroups()}</MyEventsWrap>
                    </WrapMyEvents>
                  )}
                </>
              )}

              {!!popularPosts?.length &&
                (router.pathname === routesPath.feedPath ||
                  router.pathname === '/g/[id]' ||
                  router.pathname === '/e/[id]' ||
                  router.pathname === '/p/[id]' ||
                  router.pathname === '/v/[id]' ||
                  router.pathname === routesPath.my_events ||
                  router.pathname === routesPath.my_groups) && (
                  <WrapMyEvents
                    mt={
                      router.pathname !== routesPath.my_events &&
                      router.pathname !== routesPath.my_groups &&
                      router.pathname !== routesPath.feedPath
                        ? 20
                        : 0
                    }
                  >
                    <MyPopularPostsWrap>
                      <CustomList
                        type="popular_posts"
                        list={popularPosts?.slice(0, 4)}
                        title={t('common:popularPosts')}
                        icon={<PopularPostsIcon />}
                      />
                    </MyPopularPostsWrap>
                  </WrapMyEvents>
                )}
            </SideWrap>
          </StickyBox>
        </StickyBoxWrap>
      </ContentWrap>
    );
  }
);

export default ContentContainer;
