/* eslint-disable react/display-name */
import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ArrowBack } from 'public/assets/icons';
import { closeSidebarLikes } from 'store/sideBarControler/actions';
import {
  getLikesPostStart,
  getLikesVehicleStart,
  getLikesEventStart,
  clearLikesStore,
} from 'store/posts/actions';
import { LoadingComponent } from 'components';
import { SidebarWrapper } from '../index';
import { ILikes } from './ILikes';
import { LikesListWrap } from './Likes.style';
import { LikeItem } from './LikeItem';

export const Likes = React.memo(({ postId, postType }: ILikes) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { likesPost, likesVehicle, likesEvent } = useSelector(
    (state) => state.posts
  );

  const likes = likesPost || likesVehicle || likesEvent;

  useEffect(() => {
    switch (postType) {
      case 1:
      case 3:
        dispatch(getLikesPostStart(postId));
        break;
      case 4:
        dispatch(getLikesVehicleStart(postId));
        break;
      case 6:
      case 16:
        dispatch(getLikesEventStart(postId));
        break;
      default:
    }

    return () => {
      dispatch(clearLikesStore());
    };
  }, [postType, dispatch, postId]);

  const userId = JSON.parse(localStorage.getItem('user_id'));

  const likesList = useCallback(
    () =>
      likes?.map((item) => (
        <LikeItem
          key={item._id}
          data={{
            ...item,
            isMe: item._id === userId,
          }}
          onItemClick={() => dispatch(closeSidebarLikes())}
        />
      )),
    [likes]
  );

  return (
    <SidebarWrapper
      headerText={t('common:likesScreenHeader')}
      headerIcon={<ArrowBack />}
      contentPadding="20px"
      onIconClick={() => dispatch(closeSidebarLikes())}
    >
      {likes ? (
        <LikesListWrap>{likesList()}</LikesListWrap>
      ) : (
        <LoadingComponent fullWidth color="#C2C2C2" variant="spin" />
      )}
    </SidebarWrapper>
  );
});
