/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { IHeading } from './ITypography';

import { H1, H2, H3, H4, H5, H6, HeadingContainer } from './Typography.styles';

const Heading = ({ type = 'h3', children, ...rest }: IHeading) => {
  const renderHeading: any = (headingType) => {
    switch (headingType) {
      case 'h1':
        return <H1 {...rest}>{children}</H1>;
      case 'h2':
        return <H2 {...rest}>{children}</H2>;
      case 'h3':
        return <H3 {...rest}>{children}</H3>;
      case 'h4':
        return <H4 {...rest}>{children}</H4>;
      case 'h5':
        return <H5 {...rest}>{children}</H5>;
      case 'h6':
        return <H6 {...rest}>{children}</H6>;
      default:
        break;
    }
  };
  return <HeadingContainer>{renderHeading(type)}</HeadingContainer>;
};

export default Heading;
