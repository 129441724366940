import styled from 'styled-components';

import { style, deviceMaxWidth } from 'utils/constants/style';

export const NothingHappeningWrap = styled.div`
  width: 100%;
  max-width: 550px;
  height: 550px;
  margin-top: 16px;
  background: #fff;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  align-items: center;

  @media only screen and (${deviceMaxWidth.sm}) {
    height: 350px;
  }
`;

export const CentralBlock = styled.div`
  width: 239px;
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  span {
    color: ${style.mainGray.gray};
    span {
      color: ${style.mainBlue.blue};
      cursor: pointer;
    }
  }
`;
