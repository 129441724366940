/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-expressions */
import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { followUser, unfollowUser } from 'services/followUser';
import { ThreeDotsIcon, LockIcon } from 'public/assets/icons';
import {
  EmptyUserProfilePhoto,
  RectangleButton,
  ConfirmationModal,
} from 'components';
import { style } from 'utils/constants/style';
import { useOutsideClick } from 'utils/hooks';
import {
  getButtonText,
  isVerticalDotsVisible,
  getPopoverOption,
  getRemoveModalBodyText,
} from './helpers';
import {
  InvolvedUserItemWrap,
  InvolvedUserItemImage,
  InvolvedUserItemInfoWrap,
  InvolvedUserItemHeaderText,
  InvolvedUserItemText,
  ButtonWrap,
  ThreeDotsWrap,
  PopoverWrap,
} from './InvolvedUsers.style';
import { ListUserUserActions } from '../../Popover/Popover.style';

interface IInvolvedUserItem {
  data: {
    followingStatus: number;
    isFollowing: boolean;
    fullName: string;
    isMe: boolean;
    privacy: number;
    profilePhoto: string;
    username: string;
    _id: string;
  };
  onItemClick: () => void;
  isAdmin?: boolean;
  myId?: string;
  involvedUserType: string;
  paginationRef?: (node: any) => void | null;
  onRemoveUserClick: () => void;
}

// eslint-disable-next-line react/display-name
export const InvolvedUserItem = React.memo(
  ({
    data,
    onItemClick,
    isAdmin,
    myId,
    involvedUserType,
    paginationRef = null,
    onRemoveUserClick,
  }: IInvolvedUserItem) => {
    const [status, setStatus] = useState(data.followingStatus);
    const [loading, setLoading] = useState(false);
    const [removeLoading, setRemoveLoading] = useState(false);
    const [
      isConfirmationUnfollowModalOpen,
      setIsConfirmationUnfollowModalOpen,
    ] = useState(false);
    const [
      isConfirmationRemoveUserModalOpen,
      setIsConfirmationRemoveUserModalOpen,
    ] = useState(false);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const refActionPopover = useRef(null);
    const { t } = useTranslation();

    useOutsideClick(refActionPopover, () => {
      setIsPopoverOpen(false);
    });

    const onButtonClick = (id) => {
      if (status !== 1) {
        setLoading(true);
      }

      if (status === 0) {
        followUser(id).then((resp) => {
          if (resp.ok) {
            setStatus(data.privacy ? 1 : 2);
            isConfirmationUnfollowModalOpen &&
              setIsConfirmationUnfollowModalOpen(false);
          }

          setLoading(false);
        });
      }

      if (status === 2) {
        unfollowUser(id).then((resp) => {
          if (resp.ok) {
            setStatus(0);
          }

          setIsConfirmationUnfollowModalOpen(false);
          setLoading(false);
        });
      }
    };

    const onThreeDotsClick = (e) => {
      e.stopPropagation();
      setIsPopoverOpen((prevState) => !prevState);
    };

    const onRemove = () => {
      setRemoveLoading(true);
      onRemoveUserClick();
    };

    return (
      <>
        <InvolvedUserItemWrap onClick={onItemClick} ref={paginationRef}>
          {data.profilePhoto?.trim() ? (
            <InvolvedUserItemImage img={data.profilePhoto} />
          ) : (
            <EmptyUserProfilePhoto
              wrapSize={48}
              userIconSize={24}
              withBorder={false}
              margin="0 10px 0 0"
              cursor="pointer"
            />
          )}

          <InvolvedUserItemInfoWrap>
            <InvolvedUserItemHeaderText>
              {data.username}
            </InvolvedUserItemHeaderText>
            <InvolvedUserItemText>{data.fullName}</InvolvedUserItemText>
          </InvolvedUserItemInfoWrap>

          {data._id !== myId && (
            <ButtonWrap onClick={(e) => e.stopPropagation()}>
              <RectangleButton
                text={getButtonText(status)}
                color={status ? style.mainBlue.blue : style.mainWhite.white}
                backgroundColor={
                  status ? style.mainWhite.white : style.mainBlue.blue
                }
                onClick={() => {
                  if (status === 2 || !!data.privacy) {
                    setIsConfirmationUnfollowModalOpen(true);
                  } else {
                    onButtonClick(data._id);
                  }
                }}
                height="32px"
                icon={!!data.privacy && status === 0 ? <LockIcon /> : null}
                buttonTextMargin={
                  !!data.privacy && status === 0 ? '0 0 0 4px' : ''
                }
                border={status ? `1px solid ${style.mainBlue.blue}` : ''}
                loading={loading}
                mobileHeight={32}
              />
            </ButtonWrap>
          )}

          {isVerticalDotsVisible({ involvedUserType, isAdmin }) &&
            data._id !== myId && (
              <ThreeDotsWrap onClick={onThreeDotsClick} ref={refActionPopover}>
                <ThreeDotsIcon />
              </ThreeDotsWrap>
            )}

          {isPopoverOpen && (
            <PopoverWrap onClick={(e) => e.stopPropagation()}>
              <ListUserUserActions height={60}>
                <li onClick={() => setIsConfirmationRemoveUserModalOpen(true)}>
                  {getPopoverOption(involvedUserType)}
                </li>
              </ListUserUserActions>
            </PopoverWrap>
          )}
        </InvolvedUserItemWrap>

        {isConfirmationRemoveUserModalOpen && (
          <ConfirmationModal
            headerText={t('common:remove')}
            bodyText={getRemoveModalBodyText(involvedUserType)}
            submitText={t('common:removeUppercase')}
            onSubmit={onRemove}
            loading={removeLoading}
            onCancelClick={() => setIsConfirmationRemoveUserModalOpen(false)}
          />
        )}

        {isConfirmationUnfollowModalOpen && (
          <ConfirmationModal
            headerText={`${
              status === 2
                ? t('group:unfollowCapitalize')
                : t('search:followBtnTitle')
            } ${data.username}`}
            bodyText={
              status === 2
                ? t('confirmation:unfollowUserConfirmation')
                : t('confirmation:followUserConfirmation')
            }
            submitText={`${
              status === 2
                ? t('group:unfollowUppercase')
                : t('group:followUppercase')
            }`}
            onSubmit={() => onButtonClick(data._id)}
            loading={loading}
            onCancelClick={() => setIsConfirmationUnfollowModalOpen(false)}
          />
        )}
      </>
    );
  }
);
