import moment from 'moment';
import 'moment/min/locales';

import { getLocalStorage } from 'utils/hooks';

const getCurrentLanguage = async () => {
  let selectLanguage = 'en';
  const savedDataJSON = await getLocalStorage('RoadStr-appLanguage');
  const lng = savedDataJSON || null;
  if (typeof window !== 'undefined') {
    const language = navigator.language.split('-')[0];
    if (!lng && (language === 'es' || language === 'ca')) {
      selectLanguage = 'es';
    } else {
      selectLanguage = lng || 'en';
    }
  }
  return selectLanguage;
};

export default async () => {
  const deviceLocale = await getCurrentLanguage();

  moment.locale(deviceLocale);
};
