import { ISwitch } from './Types';

import { SwitchButton, SwitchCheckbox, SwitchLabel } from './styles';

const SwitchComponent = ({ isOn, handleToggle, ...restProps }: ISwitch) => {
  return (
    <>
      <SwitchCheckbox
        checked={isOn}
        onChange={handleToggle}
        id="switch"
        type="checkbox"
      />
      <SwitchLabel isOn={isOn} htmlFor="switch">
        <SwitchButton isOn={isOn} />
      </SwitchLabel>
    </>
  );
};

export default SwitchComponent;
