/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { SidebarWrapper, InputField, LocationPlacesList } from 'components';
import {
  closeSidebarWhereTo,
  openSidebarPlanRoute,
  setSelectedWayPointId,
} from 'store/map/actions';
import { getPlaceName, searchPlace } from 'services';
import {
  ArrowBack,
  LocationArrowIcon,
  CurrentLocationIcon,
  LocationIcon,
} from 'public/assets/icons';
import { style } from 'utils/constants/style';
import { CurrentLocationContainer, HeaderText } from './SidebarWhereTo.style';
import { ISidebarWhereTo } from './ISidebarWhereTo';
import { MapSidebarWrap } from '../index';

const SidebarWhereTo = ({
  userCoordinates,
  viewport,
  setViewport,
  setEndPointCoordinates,
  endPoint,
  waypointsNumber,
  temporaryWaypoint,
  setTemporaryWaypoint,
}: ISidebarWhereTo) => {
  const [placesOffered, setPlacesOffered] = useState([]);
  const [isOfferedPlacesModalOpen, setIsOfferedPlacesModalOpen] =
    useState(false);
  const [isCurrentLocation, setIsCurrentLocation] = useState(false);
  const [endPointAddress, setEndPointAddress] = useState(
    (endPoint || temporaryWaypoint)?.waypointName || ''
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const inputRef = useRef(null);

  useEffect(() => {
    if (endPoint || temporaryWaypoint) {
      setEndPointAddress((temporaryWaypoint || endPoint).waypointName);
    }
  }, [endPoint, temporaryWaypoint]);

  useEffect(() => {
    dispatch(setSelectedWayPointId(waypointsNumber));

    inputRef?.current?.focus();
  }, []);

  const onChangePlace = (newText) => {
    setEndPointAddress(newText);

    const proximity = userCoordinates
      ? `${userCoordinates[0]},${userCoordinates[1]}`
      : '';

    const searchPlaceByText = async () => {
      const resp = await searchPlace({
        searchText: newText.trim(),
        proximity,
      });

      if (!resp) {
        return null;
      }

      const { features } = resp;
      if (features?.length) {
        setPlacesOffered(features);
        !isOfferedPlacesModalOpen && setIsOfferedPlacesModalOpen(true);
      } else {
        setPlacesOffered([]);
        setIsOfferedPlacesModalOpen(false);
      }
    };

    if (newText.length) {
      searchPlaceByText();
    } else {
      setIsOfferedPlacesModalOpen(false);
      setTemporaryWaypoint(null);
    }

    setIsCurrentLocation(false);
  };

  const onPlaceOfferedClick = (place) => {
    setIsOfferedPlacesModalOpen(false);
    setEndPointAddress(place.place_name);

    const [longitude, latitude] = place.geometry.coordinates;

    setTemporaryWaypoint({
      lat: latitude,
      lng: longitude,
      waypointName: place.place_name,
      placeId: place.id,
    });
    setViewport({ ...viewport, latitude, longitude, zoom: 13 });
  };

  const onCurrentLocationClick = async () => {
    const [longitude, latitude] = userCoordinates;

    const { features } = await getPlaceName({ coordinates: userCoordinates });

    let newAddress;

    if (Array.isArray(features) && features?.length) {
      if (features[0].place_type[0]) {
        newAddress = features[0].place_name;
      } else {
        newAddress = `${userCoordinates[0].toFixed(
          6
        )},${userCoordinates[1].toFixed(6)}`;
      }
    }

    setTemporaryWaypoint({
      lat: latitude,
      lng: longitude,
      waypointName: newAddress,
      placeId: features?.[0]?.id,
    });

    setEndPointAddress(newAddress);
    setViewport({ ...viewport, latitude, longitude, zoom: 16 });
    setIsCurrentLocation(true);
  };

  const getInputLeftIcon = () => {
    if (isCurrentLocation) {
      return <CurrentLocationIcon color={style.mainGray.SuvaGrey} />;
    }

    return temporaryWaypoint || endPoint ? (
      <LocationIcon />
    ) : (
      <LocationArrowIcon />
    );
  };

  const onCloseSidebarClick = async () => {
    setTemporaryWaypoint(null);
    dispatch(closeSidebarWhereTo());
  };

  const onSubmit = () => {
    if (!endPoint) {
      setEndPointCoordinates({ ...temporaryWaypoint });
      setTemporaryWaypoint(null);
    }
    dispatch(openSidebarPlanRoute());
  };

  return (
    <MapSidebarWrap>
      <SidebarWrapper
        headerText={t('map:whereTo')}
        headerIcon={<ArrowBack />}
        submitText={t('common:add')}
        isSubmitAvailable={Boolean(temporaryWaypoint || endPoint)}
        onSubmit={onSubmit}
        onIconClick={onCloseSidebarClick}
        contentPadding="20px"
      >
        <InputField
          value={endPointAddress}
          onChange={(e) => onChangePlace(e.target.value)}
          iconLeft={getInputLeftIcon()}
          placeholder={t('map:typeAPlace')}
          label={t('map:searchLocation')}
          crossIcon
          onCrossClick={() => {
            setEndPointAddress('');
            setTemporaryWaypoint(null);
            setPlacesOffered([]);
            setIsOfferedPlacesModalOpen(false);
            setIsCurrentLocation(false);
          }}
          ref={inputRef}
        />

        <CurrentLocationContainer onClick={onCurrentLocationClick}>
          <CurrentLocationIcon />

          <HeaderText ml={12}>{t('map:useCurrentLocation')}</HeaderText>
        </CurrentLocationContainer>

        {isOfferedPlacesModalOpen && (
          <LocationPlacesList
            locationsList={placesOffered}
            onOutsideClick={() => setIsOfferedPlacesModalOpen(false)}
            onItemClick={(item) => onPlaceOfferedClick(item)}
            top={86}
            left={20}
          />
        )}
      </SidebarWrapper>
    </MapSidebarWrap>
  );
};

export default SidebarWhereTo;
