/* eslint-disable no-nested-ternary */
import React from 'react';

import { PlayButton } from 'public/assets/icons';
import { DEFAULT_LINK_POST_PLACEHOLDER_IMAGE } from 'utils/constants';
import { IPostCard } from './IPostCard';
import {
  CardWrap,
  LinkTitle,
  LinkWrap,
  LinkHost,
  PlayButtonWrap,
} from './PostCard.style';
import { BackgroundGradientWrap } from '../Cards.style';

export const PostCard = ({
  id,
  onClick,
  coverPhoto,
  isCoverPhotoExists,
  linkHost,
  linkTitle,
  linkImage,
  paginationRef,
  linkVideo,
}: IPostCard) => (
  <CardWrap
    img={
      linkTitle
        ? linkImage.trim().length === 0 ||
          linkImage === DEFAULT_LINK_POST_PLACEHOLDER_IMAGE
          ? '/assets/img/tireTrace.png'
          : linkImage
        : coverPhoto
    }
    onClick={onClick}
    ref={paginationRef}
  >
    <span role="img" aria-label="profile card" />

    <BackgroundGradientWrap
      isCoverPhotoExists={
        linkTitle &&
        Boolean(linkImage && linkImage.trim()) &&
        linkImage !== DEFAULT_LINK_POST_PLACEHOLDER_IMAGE
      }
      height={69}
    >
      {linkTitle && (
        <LinkWrap>
          <LinkTitle
            height={38}
            isCoverPhotoExists={
              linkTitle &&
              Boolean(linkImage && linkImage.trim()) &&
              linkImage !== DEFAULT_LINK_POST_PLACEHOLDER_IMAGE
            }
          >
            {linkTitle}
          </LinkTitle>
          <LinkHost>{linkHost}</LinkHost>
        </LinkWrap>
      )}
    </BackgroundGradientWrap>

    {linkVideo && (
      <PlayButtonWrap>
        <PlayButton />
      </PlayButtonWrap>
    )}
  </CardWrap>
);
