import styled from 'styled-components';

import { style } from 'utils/constants/style';

export const AddPostWrap = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
`;

export const AddPostText = styled.p`
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${style.mainBlue.blue};
  margin-right: 4px;
  text-transform: lowercase;
`;

export const PostTypePopoverWrap = styled.div`
  width: 130px;
  position: absolute;
  top: 25px;
  right: -10px;
  z-index: 100;
`;
