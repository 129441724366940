import { useState, useRef, useMemo } from 'react';
import Image from 'next/image';
import { format } from 'date-fns';

import { ThreeDotsIcon } from 'public/assets/icons';
import { useOutsideClick } from 'utils/hooks';
import { ICommentItem } from './ICommentItem';

import {
  CommentWrapper,
  ImageWrap,
  ContainerComment,
  CommentWrap,
  UserName,
  DateComment,
  DeleteCommentWrapper,
  DeleteComment,
  WrapPopover,
  ListUserUserActions,
} from './CommentItem.style';

const CommentItem = ({
  id,
  username,
  photo,
  text,
  onDeleteComment,
  datetime,
  type,
  creatorId,
}: ICommentItem) => {
  const [isOpen, setIsOpen] = useState(false);

  const refActionPopover = useRef(null);

  useOutsideClick(refActionPopover, () => {
    setIsOpen(false);
  });

  const removeComment = () => {
    onDeleteComment(id);
  };

  const userId = useMemo(() => JSON.parse(localStorage.getItem('user_id')), []);

  return (
    <CommentWrapper>
      <div>
        <ContainerComment>
          <ImageWrap>
            <Image
              loader={() => photo}
              src={photo}
              alt="Picture author"
              width={32}
              height={32}
              unoptimized
              priority
            />
          </ImageWrap>
          <CommentWrap>
            <UserName>{username}</UserName>
            <span>{text}</span>
          </CommentWrap>
        </ContainerComment>
        <DateComment>{format(new Date(datetime), 'PPp')}</DateComment>
      </div>
      {(type === 'event' || type === 'car' || type === 'profile') &&
        userId === creatorId && (
          <DeleteCommentWrapper>
            <DeleteComment
              ref={refActionPopover}
              role="button"
              onClick={() => setIsOpen((prevState) => !prevState)}
            >
              <ThreeDotsIcon colorStroke="#C4C4C4" />
            </DeleteComment>
            {isOpen && (
              <WrapPopover>
                <ListUserUserActions>
                  <li onClick={removeComment} onKeyDown={removeComment}>
                    Delete comment
                  </li>
                </ListUserUserActions>
              </WrapPopover>
            )}
          </DeleteCommentWrapper>
        )}
    </CommentWrapper>
  );
};

export default CommentItem;
