/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';

import i18n from 'utils/i18n';
import {
  WorldIcon,
  PhoneIcon,
  MailIcon,
  ArrowBack,
  CrossIcon,
} from 'public/assets/icons';
import { style } from 'utils/constants/style';
import {
  closeSideBarEditProfile,
  actionTypes,
  openSidebarTag,
  removeTag,
  openSidebarTagWithAdding,
  addTags,
  openSidebarChooseLocation,
} from 'store/sideBarControler/actions';
import { loadIdentity } from 'store/identity/actions';
import {
  editProfileDataStart,
  clearRequestsAsyncStatus,
} from 'store/user/actions';
import { regexMap } from 'utils/constants/regex';
import { AsyncStatus } from 'utils/types';
import { getCorrectImageBase64Format } from 'utils/getCorrectImageBase64Format';
import { validateUrl, validateEmail } from 'utils/validators';
import { SidebarWrapper } from '../index';
import {
  ImagesUploader,
  InputField,
  Selector,
  ButtonsGroup,
  Pill,
} from '../../index';
import {
  Wrapper,
  SectionWrapper,
  SectionHeader,
  ErrorMessage,
  TagImageWrap,
  LocationText,
} from './EditProfile.style';
import { IEditProfile } from './IEditProfile';

const DummyDataButtonSwitch = () => [
  { id: 0, name: i18n.t('buttonGroupLabels:individual') },
  { id: 1, name: i18n.t('buttonGroupLabels:business') },
];

const EditProfile = ({ dummy }: IEditProfile) => {
  const [userProfileData, setUserProfileData] = useState({
    isBusiness: false,
    coverPhoto: '',
    profilePhoto: '',
    username: '',
    fullName: '',
    description: '',
    website: '',
    business: {
      phone: '',
      email: '',
      interests: null,
      isActive: false,
    },
    location: {
      lat: '',
      lnt: '',
      address: '',
    },
    interests: null,
    brands: null,
    collaborators: null,
  });
  const [userProfileDefaultData, setUserProfileDefaultData] =
    useState(userProfileData);

  const [phoneError, setPhoneError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [linkError, setLinkError] = useState('');

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const userData = useSelector((state) => state.identity);
  const tagsData = useSelector((state) => state.sideBarControler.tagsData);
  const { editProfileDataReqStat } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(loadIdentity());
  }, []);

  useEffect(() => {
    if (editProfileDataReqStat === AsyncStatus.SUCCESS) {
      dispatch(closeSideBarEditProfile(actionTypes.CLOSE_SIDEBAR_CREATE_EVENT));
      dispatch(clearRequestsAsyncStatus());
    }
  }, [editProfileDataReqStat]);

  useEffect(() => {
    if (userData._id) {
      const {
        isBusiness,
        coverPhoto,
        profilePhoto,
        username,
        fullName,
        description,
        website,
        interests,
        brands,
        collaborators,
        business,
        location,
      } = userData;

      setUserProfileData({
        ...userProfileData,
        isBusiness,
        coverPhoto,
        profilePhoto,
        username,
        fullName,
        description,
        website,
        interests,
        brands,
        collaborators,
        business,
        location,
      });

      setUserProfileDefaultData({
        ...userProfileData,
        isBusiness,
        coverPhoto,
        profilePhoto,
        username,
        fullName,
        description,
        website,
        interests,
        brands,
        collaborators,
        business,
        location,
      });

      dispatch(addTags({ type: 'interests', tags: interests }));
      dispatch(addTags({ type: 'brands', tags: brands }));
      dispatch(addTags({ type: 'collaborators', tags: collaborators }));
      dispatch(addTags({ type: 'location', tags: location }));
    }
  }, [userData]);

  const onFileInputChange = (image: string, type) => {
    if (type === 'cover') {
      setUserProfileData({ ...userProfileData, coverPhoto: image });
    } else {
      setUserProfileData({ ...userProfileData, profilePhoto: image });
    }
  };

  const onRemovePhotoClick = (type) => {
    if (type === 'cover') {
      setUserProfileData({ ...userProfileData, coverPhoto: '' });
    } else {
      setUserProfileData({ ...userProfileData, profilePhoto: '' });
    }
  };

  const onSubmit = () => {
    let isErrorsOccurred = false;

    if (userProfileData.business?.email?.trim().length) {
      const validationResult = validateEmail(
        userProfileData.business?.email || null
      );
      const error = validationResult;

      if (error) {
        setEmailError(validationResult.email[0]);
        isErrorsOccurred = true;
      }
    }

    if (userProfileData.website?.trim().length) {
      const validationResult = validateUrl(userProfileData.website || null);
      const error = validationResult;

      if (error) {
        setLinkError(error.url[0]);
        isErrorsOccurred = true;
      }
    }

    if (
      userProfileData.business?.phone?.trim().length &&
      !regexMap.phone.test(userProfileData.business?.phone)
    ) {
      setPhoneError(t('auth:phoneIncorrect'));

      isErrorsOccurred = true;
    }

    if (isErrorsOccurred) {
      return;
    }

    const defaultLocation = {
      lat: undefined,
      lng: undefined,
      address: '',
    };

    const data = {
      ...userProfileData,
      interests: tagsData.interests,
      brands: tagsData.brands,
      collaborators: tagsData.collaborators,
      profilePhoto: userProfileData.profilePhoto?.startsWith('data')
        ? getCorrectImageBase64Format(userProfileData.profilePhoto)
        : userProfileData.profilePhoto,
      coverPhoto: userProfileData.coverPhoto?.startsWith('data')
        ? getCorrectImageBase64Format(userProfileData.coverPhoto)
        : userProfileData.coverPhoto,
      location: tagsData.location || defaultLocation,
    };

    dispatch(editProfileDataStart(data));
  };

  const onBlur = (type: string, value: string) => {
    if (type === 'phone') {
      if (
        userProfileData.business?.phone?.trim().length &&
        !regexMap.phone.test(value)
      ) {
        setPhoneError(t('auth:phoneIncorrect'));
      }
    }

    if (type === 'email') {
      const validationResult = validateEmail(value || null);
      const error = validationResult;

      error && setEmailError(validationResult.email[0]);
    }

    if (type === 'website') {
      const validationResult = validateUrl(value || null);
      const error = validationResult;

      error && setLinkError(error.url[0]);
    }
  };

  const tagsList = (selectedTags, type) =>
    selectedTags.map((tag) => {
      if (type === 'collaborators') {
        return (
          <Pill
            text={tag.username}
            onClick={() => dispatch(removeTag({ id: tag._id, type }))}
            iconRight={<CrossIcon width="10" height="10" />}
            fontSize={12}
            margin="0 10px 8px 0"
            key={tag._id}
            iconLeft={
              <TagImageWrap>
                <Image
                  width={26}
                  height={26}
                  src={
                    tag.profilePhoto.trim().length
                      ? tag.profilePhoto
                      : '/assets/img/emptyUserPhoto.png'
                  }
                  alt="tag"
                  unoptimized
                  priority
                />
              </TagImageWrap>
            }
          />
        );
      }

      return (
        <Pill
          text={tag ? tag.name : '2'}
          onClick={() => dispatch(removeTag({ id: tag ? tag._id : '0', type }))}
          iconRight={<CrossIcon width="10" height="10" />}
          fontSize={12}
          margin="0 10px 8px 0"
          key={tag ? tag._id : '0'}
        />
      );
    });

  const renderTagsList = (type: string) => {
    if (tagsData[type] && tagsData[type].length) {
      return tagsList(tagsData[type], type);
    }

    return null;
  };

  const isSubmitAvailable = () => {
    let submit = false;

    const tags = [
      tagsData.interests,
      tagsData.brands,
      tagsData.collaborators,
      tagsData.location,
    ];
    const defaultTags = [
      userProfileData.interests,
      userProfileData.brands,
      userProfileData.collaborators,
      userProfileData.location,
    ];
    const { username, fullName } = userProfileData;

    if (
      JSON.stringify(userProfileDefaultData) !== JSON.stringify(userProfileData)
    ) {
      submit = true;
    }

    if (JSON.stringify(tags) !== JSON.stringify(defaultTags)) {
      submit = true;
    }

    if (
      !username.length ||
      !fullName.length ||
      emailError ||
      phoneError ||
      linkError
    ) {
      submit = false;
    }

    return submit;
  };

  return (
    <SidebarWrapper
      headerText={t('editUserProfile:editProfile')}
      headerIcon={<ArrowBack />}
      submitText={t('editUserProfile:save')}
      isSubmitAvailable={isSubmitAvailable()}
      onSubmit={onSubmit}
      loading={editProfileDataReqStat}
      onIconClick={() =>
        dispatch(
          closeSideBarEditProfile(actionTypes.CLOSE_SIDEBAR_EDIT_PROFILE)
        )
      }
    >
      {userData._id && (
        <Wrapper>
          <ImagesUploader
            coverPhoto={userProfileData.coverPhoto}
            profilePhoto={userProfileData.profilePhoto}
            isProfileImages
            height={userProfileData.coverPhoto ? 240 : 180}
            onProfileImagesChange={onFileInputChange}
            onRemovePhotoClick={onRemovePhotoClick}
            cropAspect={3 / 2}
            autoRatio
            isAspect
          />

          <SectionWrapper pt={40}>
            <SectionHeader mb={6}>
              {t('editUserProfile:profileType')}
            </SectionHeader>
            <ButtonsGroup
              itemList={DummyDataButtonSwitch()}
              onClick={(e) =>
                setUserProfileData({
                  ...userProfileData,
                  isBusiness: Boolean(e),
                  business: {
                    ...userProfileData.business,
                    isActive: Boolean(e),
                  },
                })
              }
              isSelectedId={userProfileData.isBusiness ? 1 : 0}
              inactiveFieldsBackgroundColor={style.mainGray.whiteSmokeColor}
            />
          </SectionWrapper>

          <SectionWrapper pt={16.5}>
            <InputField
              value={userProfileData.username}
              onChange={(e) =>
                setUserProfileData({
                  ...userProfileData,
                  username: e.target.value,
                })
              }
              label={t('editUserProfile:myUsername')}
              crossIcon
              onCrossClick={() =>
                setUserProfileData({ ...userProfileData, username: '' })
              }
            />

            <Selector
              title={t('editUserProfile:myInterests')}
              isSelectedItemsExist={
                tagsData.interests && !!tagsData.interests.length
              }
              onClick={() =>
                dispatch(
                  openSidebarTagWithAdding({
                    actionType: actionTypes.OPEN_SIDEBAR_TAG_WITH_ADDING,
                    tagType: 'interests',
                  })
                )
              }
              margin={`21px 0 ${
                tagsData.interests && !!tagsData.interests.length
                  ? '14px'
                  : '20px'
              } 0`}
            />
            {renderTagsList('interests') && (
              <div style={{ marginBottom: 22 }}>
                {renderTagsList('interests')}
              </div>
            )}

            <Selector
              title={t('editUserProfile:myBrands')}
              isSelectedItemsExist={tagsData.brands && !!tagsData.brands.length}
              onClick={() =>
                dispatch(
                  openSidebarTagWithAdding({
                    actionType: actionTypes.OPEN_SIDEBAR_TAG_WITH_ADDING,
                    tagType: 'brands',
                  })
                )
              }
              margin={`0 0 ${
                tagsData.brands && !!tagsData.brands.length ? '14px' : '20px'
              } 0`}
            />
            {renderTagsList('brands')}
          </SectionWrapper>

          <SectionWrapper>
            <SectionHeader mb={16}>
              {t('editUserProfile:myInformation')}
            </SectionHeader>

            <InputField
              value={userProfileData.fullName}
              onChange={(e) =>
                setUserProfileData({
                  ...userProfileData,
                  fullName: e.target.value,
                })
              }
              label={t('editUserProfile:completeName')}
              crossIcon
              onCrossClick={() =>
                setUserProfileData({ ...userProfileData, fullName: '' })
              }
              margin="0 0 17px 0"
            />
            <InputField
              type="textarea"
              value={userProfileData.description}
              onChange={(e) =>
                setUserProfileData({
                  ...userProfileData,
                  description: e.target.value,
                })
              }
              label={t('editUserProfile:bio')}
              placeholder={t('editUserProfile:addBio')}
              crossIcon
              onCrossClick={() =>
                setUserProfileData({ ...userProfileData, description: '' })
              }
              maxLength={500}
              showCharactersNumber
              height={88}
            />

            <Selector
              title={t('addNewGroup:cityOrLocation')}
              isSelectedItemsExist={tagsData.location}
              onClick={() => dispatch(openSidebarChooseLocation())}
              margin="22px 0 20px 0"
            />
            {tagsData.location && (
              <LocationText>{tagsData.location.address}</LocationText>
            )}

            {userProfileData.isBusiness && (
              <>
                <InputField
                  value={userProfileData.business.phone}
                  onChange={(e) => {
                    setUserProfileData({
                      ...userProfileData,
                      business: {
                        ...userProfileData.business,
                        phone: e.target.value,
                      },
                    });
                    phoneError && setPhoneError('');
                  }}
                  onBlur={(e) => onBlur('phone', e.target.value)}
                  label={t('editUserProfile:businessPhone')}
                  placeholder={t('editUserProfile:phone')}
                  crossIcon
                  onCrossClick={() => {
                    setUserProfileData({
                      ...userProfileData,
                      business: {
                        ...userProfileData.business,
                        phone: '',
                      },
                    });
                    phoneError && setPhoneError('');
                  }}
                  iconLeft={<PhoneIcon />}
                />
                {phoneError && (
                  <ErrorMessage mt={6} mb={17}>
                    {phoneError}
                  </ErrorMessage>
                )}

                <InputField
                  value={userProfileData.business.email}
                  onChange={(e) => {
                    setUserProfileData({
                      ...userProfileData,
                      business: {
                        ...userProfileData.business,
                        email: e.target.value,
                      },
                    });
                    emailError && setEmailError('');
                  }}
                  onBlur={(e) => onBlur('email', e.target.value)}
                  label={t('editUserProfile:businessEmail')}
                  placeholder={t('editUserProfile:email')}
                  crossIcon
                  onCrossClick={() => {
                    setUserProfileData({
                      ...userProfileData,
                      business: {
                        ...userProfileData.business,
                        email: '',
                      },
                    });
                    emailError && setEmailError('');
                  }}
                  iconLeft={<MailIcon />}
                  margin="17px 0"
                />
                {emailError && (
                  <ErrorMessage mt={-11} mb={17}>
                    {emailError}
                  </ErrorMessage>
                )}
              </>
            )}

            <InputField
              value={userProfileData.website}
              onChange={(e) => {
                setUserProfileData({
                  ...userProfileData,
                  website: e.target.value,
                });
                linkError && setLinkError('');
              }}
              onBlur={(e) => onBlur('website', e.target.value)}
              label={t('editUserProfile:myWebsite')}
              placeholder={t('editUserProfile:website')}
              crossIcon
              onCrossClick={() => {
                setUserProfileData({ ...userProfileData, website: '' });
                linkError && setLinkError('');
              }}
              iconLeft={<WorldIcon />}
            />
            {linkError && <ErrorMessage mt={6}>{linkError}</ErrorMessage>}

            <Selector
              title={t('editUserProfile:myCollaborators')}
              isSelectedItemsExist={
                tagsData.collaborators && tagsData.collaborators.length
              }
              onClick={() =>
                dispatch(
                  openSidebarTag({
                    actionType: actionTypes.OPEN_SIDEBAR_TAG,
                    tagType: 'collaborators',
                  })
                )
              }
              margin="20px 0 0 0"
            />
            {renderTagsList('collaborators')}
          </SectionWrapper>
        </Wrapper>
      )}
    </SidebarWrapper>
  );
};

export default EditProfile;
