import fetchData from 'utils/fetchData';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const inviteUserToGroups = (groupId, userId) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOST}api/v01/group/${groupId}/invite/${userId}`,
      {
        method: 'PUT',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};
