import { ICircleButton } from './ICircleButton';
import { Button } from './CircleButton.style';

export const CircleButton = ({ variant, onClick, size }: ICircleButton) => (
  <Button width={size} onClick={onClick}>
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.41602 13H20.5827"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 5.4165V20.5832"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </Button>
);
