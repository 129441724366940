import styled from 'styled-components';

import { deviceMaxWidth, style } from 'utils/constants/style';

interface IProps {
  width?: number;
  height?: number;
  rounded?: boolean;
  backgroundColor?: string;
  bottom?: number;
  right?: number;
}

export const CommentsWrapp = styled.div``;

export const ContainerComment = styled.div`
  display: flex;
`;

export const ImageWrap = styled.div`
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
  overflow: hidden;
  align-self: flex-end;
`;

export const CommentWrap = styled.div`
  max-width: 90%;
  height: auto;
  padding-left: 17px;
  padding-right: 17px;
  padding-bottom: 8px;
  border-radius: 16px 16px 16px 0;
  background-color: ${style.mainGray.whiteSmokeColor};
  padding: 6px 10px;

  span {
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    color: ${style.mainBlack.black};
    letter-spacing: 0.01em;
  }
`;

export const UserName = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: ${style.mainBlack.black};
  margin-bottom: 4px;
`;

export const ContentComments = styled.div`
  height: auto;
  padding: 20px 20px 40px 20px;
  width: 100%;
  box-sizing: border-box;
`;

export const CommentWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

export const DateComment = styled.div`
  margin-top: 6px;
  margin-left: 40px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 300;
  color: ${style.mainGray.SuvaGrey};
  margin-bottom: 16px;
`;

export const DeleteCommentWrapper = styled.div`
  position: relative;
  height: 34px;
`;

export const DeleteComment = styled.div`
  margin: 10px 0;
  cursor: pointer;
  width: 24px;
  height: 24px;
  transform: rotate(-90deg);
`;
export const WrapPopover = styled.div`
  position: absolute;
  z-index: 100;
  top: 100%;
  right: 6px;
  width: 170px;
`;
export const ListUserUserActions = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  width: 100%;
  padding-inline-start: 0px;
  border-radius: 10px;
  background-color: ${style.mainWhite.white};
  overflow: hidden;
  filter: drop-shadow(0px 8px 20px rgba(0, 0, 0, 0.08));

  span {
    position: relative;
    top: 5px;
    right: 10px;
  }
  li {
    height: 44px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 10px 26px 10px 26px;
    padding-left: 26px;
    cursor: pointer;
    color: ${style.mainBlack.black};
    font-size: 14px;
    :hover {
      background-color: ${style.mainGray.whisperColor};
    }
  }
`;

export const InputFieldWrap = styled.div`
  width: 100%;
  max-width: 404px;
  min-height: 61px;
  display: flex;
  align-items: flex-end;
  padding: 0 16px;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  border-top: 1px solid ${style.mainGray.whisperColor};
  background-color: ${style.mainWhite.white};
  padding: 13px 16px;

  @media only screen and (${deviceMaxWidth.sm}) {
    max-width: 100%;
  }
`;

export const CameraIconWrap = styled.div`
  width: 19px;
  height: 16px;
  cursor: pointer;
  position: relative;
  bottom: 12px;
`;

export const IconWrap = styled.div<IProps>`
  width: ${({ width }) => (width ? `${width}px` : '28px')};
  height: ${({ height }) => (height ? `${height}px` : '28px')};
  border-radius: ${({ rounded }) => (rounded ? '50%' : '0')};
  background-color: ${({ backgroundColor }) =>
    backgroundColor || 'transparent'};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: ${({ bottom }) => (bottom ? `${bottom}px` : '0')};
  right: ${({ right }) => (right ? `${right}px` : '0')};
  cursor: pointer;

  :hover {
    ${({ backgroundColor }) =>
      backgroundColor &&
      `
      background-color: ${style.mainBlue.blue};
    `}
  }
`;

export const EmojiPickerWrap = styled.div`
  position: absolute;
  bottom: 42px;
`;
