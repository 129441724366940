import { HYDRATE } from 'next-redux-wrapper';

import { AsyncStatus } from 'utils/types';
import { actionTypes } from './actions';

const initialState = {
  signUpReqStat: AsyncStatus.NONE,

  isSignUp: null,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE: {
      return { ...state, ...action.payload };
    }

    // SIGN UP
    case actionTypes.SIGN_UP_ERROR:
      return {
        ...state,
        ...{ code: action.error },
        signUpReqStat: AsyncStatus.ERROR,
      };

    case actionTypes.SIGN_UP_START:
      return {
        ...state,
        ...{
          data: action.data,
        },
        code: null,
        signUpReqStat: AsyncStatus.LOADING,
      };

    case actionTypes.SIGN_UP_SUCCESS:
      return {
        ...state,
        ...{
          data: action.data,
        },
        signUpReqStat: AsyncStatus.SUCCESS,
      };

    // SET SIGN UP STATUS
    case actionTypes.SET_SIGN_UP_STATUS:
      return { ...state, isSignUp: action.data };

    default:
      return state;
  }
}

export default reducer;
