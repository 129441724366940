import styled, { keyframes } from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  animationDelay?: string;
  height?: number;
  dotWidth?: number;
  dotColor?: string;
}

const OpacityAnimation = keyframes`
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
`;

export const DotWrapper = styled.div<IProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ height }) => (height ? `${height}px` : '21px')};
`;

export const Dot = styled.div<IProps>`
  background-color: ${(props) =>
    props.dotColor ? props.dotColor : style.mainWhite.white};
  border-radius: 50%;
  width: ${({ dotWidth }) => (dotWidth ? `${dotWidth}px` : '10px')};
  height: ${({ dotWidth }) => (dotWidth ? `${dotWidth}px` : '10px')};
  margin-right: ${({ dotWidth }) => (dotWidth ? `${dotWidth / 2}px` : '5x')};
  margin-left: ${({ dotWidth }) => (dotWidth ? `${dotWidth / 2}px` : '5px')};
  animation: ${OpacityAnimation} 1.4s infinite;
  animation-delay: ${({ animationDelay }) => animationDelay};
`;
