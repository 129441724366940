import React from 'react';

export const LockIcon = ({ color = 'white' }: any) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.65006 0.875C4.46515 0.875 2.68754 2.37491 2.68754 4.2186V6.12822C2.68754 6.14992 2.6558 6.18649 2.63087 6.19348C2.41659 6.25378 2.24256 6.30603 2.08326 6.35785C1.89329 6.41965 1.75 6.59729 1.75 6.77109V11.9755C1.75 12.1481 1.89236 12.3259 2.08113 12.3891C3.54169 12.8774 5.0789 13.125 6.65003 13.125C8.22117 13.125 9.75838 12.8774 11.219 12.389C11.4077 12.3259 11.55 12.1481 11.55 11.9755V6.77109C11.55 6.59729 11.4067 6.41965 11.2167 6.35779C11.0573 6.30597 10.8833 6.25372 10.6692 6.19342C10.6443 6.18643 10.6126 6.14985 10.6126 6.12815V4.21855C10.6125 2.37491 8.83496 0.875 6.65006 0.875ZM5.57077 8.10745C5.57077 7.60413 6.05398 7.19682 6.64999 7.19682C7.24595 7.19682 7.72917 7.60409 7.72917 8.10745C7.72917 8.44097 7.50627 8.7202 7.18957 8.87849V10.3841C7.18957 10.6358 6.94823 10.8394 6.64996 10.8394C6.35168 10.8394 6.11037 10.6358 6.11037 10.3841V8.87849C5.79368 8.7202 5.57077 8.44097 5.57077 8.10745ZM9.25005 4.21855V5.8662C8.39742 5.71138 7.52269 5.63298 6.6504 5.63292C5.77852 5.63292 4.90397 5.71134 4.05002 5.86602V4.21855C4.05002 3.00881 5.21635 2.02467 6.64999 2.02467C8.08361 2.02465 9.25005 3.00881 9.25005 4.21855Z"
      fill={color}
    />
  </svg>
);
