import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  margin?: string;
}

export const LineWrap = styled.div<IProps>`
  width: 2px;
  height: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: ${({ margin }) => margin};
`;

export const Line = styled.div`
  height: 4px;
  width: 2px;
  background-color: ${style.mainBlue.blue};
  border-radius: 1px;
`;
