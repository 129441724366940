/* eslint-disable no-unused-expressions */
import { call, put, takeEvery, select } from 'redux-saga/effects';

import {
  getChats,
  getChatsNext,
  getMessages,
  getChatDetails,
  getChatMembers,
  getMessagesNext,
  searchForUsers,
  searchForUsersNext,
  createSingleChat,
  createMultipleChat,
  changeChatSettings,
  deleteChat,
  uploadImageAws,
  uploadChatMediaAws,
  getRoomInfo,
  editMultipleChat,
} from 'services';
import { sendMedia } from 'utils/chatSocketManager2';
import {
  actionTypes,
  getChatsSuccess,
  getChatsError,
  getChatsStart,
  getChatsNextSuccess,
  getChatsNextError,
  getMessagesSuccess,
  getMessagesError,
  getChatDetailsStart,
  getChatDetailsSuccess,
  getChatDetailsError,
  getChatMembersSuccess,
  getChatMembersError,
  getMessagesNextSuccess,
  getMessagesNextError,
  searchForUsersSuccess,
  searchForUsersError,
  searchForUsersNextSuccess,
  searchForUsersNextError,
  createSingleChatSuccess,
  createSingleChatError,
  createMultipleChatSuccess,
  createMultipleChatError,
  changeChatSettingsSuccess,
  changeChatSettingsError,
  deleteChatSuccess,
  deleteChatError,
  getRoomInfoSuccess,
  getRoomInfoError,
  setSelectedChatId,
  editMultipleChatSuccess,
  editMultipleChatError,
  getRoomInfoStart,
} from './actions';
import { ChatSelectors } from './reducer';
import { closeSidebarNewMultipleChat } from '../sideBarControler/actions';

function* workerGetChats(action) {
  try {
    const forNext = action.data;
    let nextValue = null;
    let response;

    if (forNext) {
      nextValue = yield select(ChatSelectors.getChatsNextValue);
    }

    if (forNext) {
      response = yield call(getChatsNext, nextValue);
    } else {
      response = yield call(getChats);
    }

    const { data: responseData } = response;

    if (responseData.ok) {
      const result = responseData;
      yield put(getChatsSuccess(result));
    } else {
      const result = responseData;
      yield put(getChatsError(result));
    }
  } catch (err) {
    yield put(getChatsError(err));
  }
}

function* workerGetMessages(action) {
  try {
    const { chatId, forNextPageMessages } = action.data;
    let response;

    if (forNextPageMessages) {
      response = yield call(getMessagesNext, forNextPageMessages);

      console.log('next', response);
    } else {
      response = yield call(getMessages, { chatId });
    }

    const { data: responseData } = response;

    if (responseData.ok) {
      const result = responseData;
      yield put(getMessagesSuccess(result));
    } else {
      const result = responseData;
      yield put(getMessagesError(result));
    }
  } catch (err) {
    yield put(getMessagesError(err));
  }
}

function* workerGetChatDetails(action) {
  try {
    const resp = yield call(getChatDetails, action.data);

    resp.status === 200
      ? yield put(getChatDetailsSuccess(resp.data))
      : yield put(getChatDetailsError(resp.data));
  } catch (err) {
    yield put(getChatDetailsError(err));
  }
}

function* workerGetChatMembers(action) {
  try {
    const resp = yield call(getChatMembers, action.data);

    resp.status === 200 && resp.data.ok
      ? yield put(getChatMembersSuccess(resp.data))
      : yield put(getChatMembersError(resp.data));
  } catch (err) {
    yield put(getChatMembersError(err));
  }
}

function* workerGetMessagesNext(action) {
  try {
    const resp = yield call(getMessagesNext, action.data);

    console.log('messages next', resp);

    resp.code === 200
      ? yield put(getMessagesNextSuccess(resp.data))
      : yield put(getMessagesNextError(resp.data));
  } catch (err) {
    yield put(getMessagesNextError(err));
  }
}

function* workerSearchForUsers(action) {
  try {
    const resp = yield call(searchForUsers, action.data);

    console.log('search users', resp);

    resp.code === 200
      ? yield put(searchForUsersSuccess(resp.data))
      : yield put(searchForUsersError(resp.data));
  } catch (err) {
    yield put(searchForUsersError(err));
  }
}

function* workerSearchForUsersNext(action) {
  try {
    const resp = yield call(searchForUsersNext, action.data);

    console.log('search users next', resp);

    resp.code === 200
      ? yield put(searchForUsersNextSuccess(resp.data))
      : yield put(searchForUsersNextError(resp.data));
  } catch (err) {
    yield put(searchForUsersNextError(err));
  }
}

function* workerCreateSingleChat(action) {
  try {
    const resp = yield call(createSingleChat, action.data);
    const { status, data } = resp;

    console.log('single chat', resp);

    if (status === 200 && data.ok) {
      yield put(setSelectedChatId(data.chat._id));
      yield put(createSingleChatSuccess(data));
    } else {
      yield put(createSingleChatError('Error'));
    }
  } catch (err) {
    yield put(createSingleChatError(err));
  }
}

function* workerCreateMultipleChat(action) {
  try {
    const { userIds, title, size, photoUri, file } = action.data;

    let params: any = {
      userIds,
      title,
      size,
    };

    const resp = yield call(createMultipleChat, params);

    if (resp.data.ok) {
      const { data: result } = resp;

      if (size === 0) {
        yield put(createMultipleChatSuccess(resp.data));
      } else {
        const { fields } = result.upload;

        params = {
          body: fields,
          photoUri,
          file,
        };
        const awsResponse = yield call(uploadImageAws, params);

        if (awsResponse.ok) {
          yield put(createMultipleChatSuccess(resp.data));
          yield put(getChatsStart(false));
        } else {
          yield put(
            createMultipleChatError('There was issue uploading image to aws.')
          );
        }
      }
    }
  } catch (err) {
    yield put(createMultipleChatError(err));
  }
}

function* workerEditMultipleChat(action) {
  try {
    const { chatId, title, size, photoUri, file } = action.data;

    let params: any = {
      chatId,
      title,
      size: size || 0,
    };

    const resp = yield call(editMultipleChat, params);

    if (resp.data.ok) {
      yield put(getChatsStart(false));

      const { data: result } = resp;

      if (size === 0) {
        yield put(editMultipleChatSuccess(resp.data));
      } else {
        const { fields } = result.upload;

        params = {
          body: fields,
          photoUri,
          file,
        };

        const awsResponse = yield call(uploadImageAws, params);

        if (awsResponse.ok) {
          yield put(editMultipleChatSuccess(resp.data));
        } else {
          yield put(
            editMultipleChatError('There was issue uploading image to aws.')
          );
        }
      }

      yield put(closeSidebarNewMultipleChat());
      yield put(getChatDetailsStart(chatId));
    }
  } catch (err) {
    yield put(editMultipleChatError(err));
  }
}

function* workerChangeChatSettings(action) {
  try {
    const resp = yield call(changeChatSettings, action.data);

    console.log('change chat settings', resp);

    resp.status === 200 && resp.data.ok
      ? yield put(changeChatSettingsSuccess())
      : yield put(changeChatSettingsError('Error'));
  } catch (err) {
    yield put(changeChatSettingsError(err));
  }
}

function* workerDeleteChat(action) {
  try {
    const resp = yield call(deleteChat, action.data);

    resp.status === 200 && resp.data.ok
      ? yield put(deleteChatSuccess(action.data))
      : yield put(deleteChatError(resp.data));
  } catch (err) {
    yield put(deleteChatError(err));
  }
}

function* workerUploadChatMediaAws(action) {
  try {
    const { chatId, data, mediaForSend, file } = action.data;
    const photoUri = mediaForSend.url;
    const presignedUrl = data.upload.url;
    const body = data.upload.fields;

    const params = {
      chatId,
      presignedUrl,
      body,
      photoUri,
      file,
    };

    const response = yield call(uploadChatMediaAws, params);

    console.log('chat image resp', response);

    const { ok } = response;
    const { messageId } = data;
    const imageUrl = data.url;

    const mediaParams = {
      chatId,
      messageId,
      imageUrl,
      mediaForSend,
    };

    if (ok) {
      console.log('all is ok');
      yield call(sendMedia, mediaParams);
    } else {
      console.log('else uploadChatMediaAws err');
    }
  } catch (error) {
    console.log('error', error);
  }
}

function* workerGetRoomInfo(action) {
  try {
    const resp = yield call(getRoomInfo, action.data);

    if (resp.status === 200 && resp.data.ok) {
      yield put(setSelectedChatId(resp.data.chat._id));
      yield put(getRoomInfoSuccess(resp.data));
    } else {
      yield put(getRoomInfoError(resp.data));
    }
  } catch (err) {
    yield put(getRoomInfoError(err));
  }
}

function* watchChat() {
  yield takeEvery(actionTypes.GET_CHATS_START, workerGetChats);
  yield takeEvery(actionTypes.GET_MESSAGES_START, workerGetMessages);
  yield takeEvery(actionTypes.GET_CHAT_DETAILS_START, workerGetChatDetails);
  yield takeEvery(actionTypes.GET_CHAT_MEMBERS_START, workerGetChatMembers);
  yield takeEvery(actionTypes.GET_MESSAGES_NEXT_START, workerGetMessagesNext);
  yield takeEvery(actionTypes.SEARCH_FOR_USERS_START, workerSearchForUsers);
  yield takeEvery(
    actionTypes.SEARCH_FOR_USERS_NEXT_START,
    workerSearchForUsersNext
  );
  yield takeEvery(actionTypes.CREATE_SINGLE_CHAT_START, workerCreateSingleChat);
  yield takeEvery(
    actionTypes.CREATE_MULTIPLE_CHAT_START,
    workerCreateMultipleChat
  );
  yield takeEvery(
    actionTypes.CHANGE_CHAT_SETTINGS_START,
    workerChangeChatSettings
  );
  yield takeEvery(actionTypes.DELETE_CHAT_START, workerDeleteChat);
  yield takeEvery(actionTypes.UPLOAD_CHAT_MEDIA_AWS, workerUploadChatMediaAws);
  yield takeEvery(actionTypes.GET_ROOM_INFO_START, workerGetRoomInfo);
  yield takeEvery(actionTypes.EDIT_MULTIPLE_CHAT_START, workerEditMultipleChat);
}

export default watchChat;
