import styled, { keyframes } from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  delay?: number;
}

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export const Wrapper = styled.div<IProps>`
  padding: 30px;
  background-color: ${style.mainWhite.white};
  width: 100%;
  max-width: 360px;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  position: fixed;
  left: 50%;
  margin-left: -180px;
  top: 45%;
  z-index: 2;

  animation-name: ${fadeOut};
  animation-delay: ${({ delay }) => `${delay}s`};
  animation-duration: 1s;
  animation-fill-mode: both;
`;

export const ModalText = styled.p`
  color: ${style.mainBlack.black};
  font-size: 15px;
  line-height: 130%;
  letter-spacing: 0.02em;
  text-align: center;
`;
