import Router from 'next/router';

import { getLocalStorage } from './hooks';
import { routesPath } from './constants/routePath';

export const anonymousClickHandler = (onClick) => {
  const userId = JSON.parse(getLocalStorage('user_id'));

  if (userId === process.env._ANONYMOUS_ID) {
    Router.push(routesPath.authentication);
  } else {
    onClick();
  }
};
