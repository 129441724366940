import { useTranslation } from 'react-i18next';

import Heading from 'components/common/Typography/Heading';
import { PopularPostsIcon } from 'public/assets/icons';
import Image from 'components/common/Image';
import { useRouter } from 'next/router';
import { routesPath } from 'utils/constants/routePath';
import Fetch from 'components/Fetch';
import { ISidebarPopularPosts } from './ISidebarPopularPosts';

import {
  PlaceHolderWrapper,
  PopularPostsListItem,
  SidebarPopularPostsList,
  SidebarPopularPostsWrapper,
  TitleIcon,
  TitleWrapper,
} from './SidebarPopularPosts.styles';

const SidebarPopularPosts = ({ listItems = [] }: ISidebarPopularPosts) => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <SidebarPopularPostsWrapper>
      <TitleWrapper>
        <TitleIcon>
          <PopularPostsIcon />
        </TitleIcon>
        <Heading type="h6">{t('common:popularPosts')}</Heading>
      </TitleWrapper>
      <SidebarPopularPostsList>
        {listItems?.length > 0 &&
          listItems?.map((item, i) => (
            <PopularPostsListItem
              key={`popular-post${i}`}
              onClick={() =>
                router.push(`${routesPath.post_details}/${item._id}`)
              }
            >
              <Image
                src={item?.mediaObjects[0]?.url || '/assets/img/tireTrace.png'}
                alt="popular posts"
                width={130}
                height={157}
                unoptimized
                priority
              />
            </PopularPostsListItem>
          ))}
      </SidebarPopularPostsList>
    </SidebarPopularPostsWrapper>
  );
};

export default SidebarPopularPosts;
