import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

const Scroll = () => {
  const router = useRouter();
  const scrollPositions = useRef({});
  const isBack = useRef(false);

  useEffect(() => {
    router.beforePopState(() => {
      isBack.current = true;
      return true;
    });

    const onRouteChangeStart = () => {
      const url = router.pathname;
      scrollPositions.current[url?.split('?')[0]] = window.scrollY;
    };

    const onRouteChangeComplete = (url) => {
      const route = url?.split('?')?.[0];
      if (isBack.current && scrollPositions?.current[url?.split('?')[0]]) {
        window.scroll({
          top: scrollPositions?.current[url?.split('?')[0]],
          behavior: 'auto',
        });
        // setTimeout(
        //   () => {
        //     window.scroll({
        //       top: scrollPositions?.current[url?.split('?')[0]],
        //       behavior: 'auto',
        //     });
        //   },
        //   route === '/search' ? 1000 : 0
        // );
      }
      isBack.current = false;
    };

    router.events.on('routeChangeStart', onRouteChangeStart);
    router.events.on('routeChangeComplete', onRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', onRouteChangeStart);
      router.events.off('routeChangeComplete', onRouteChangeComplete);
    };
  }, [router]);

  return null;
};

export default Scroll;
