import React from 'react';
import { style } from 'utils/constants/style';

export const DefaultUserIcon = ({ size = '24' }: { size?: string }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 17.8855C26.8719 17.8855 29.2 15.5574 29.2 12.6855C29.2 9.81367 26.8719 7.48555 24 7.48555C21.1281 7.48555 18.8 9.81367 18.8 12.6855C18.8 15.5574 21.1281 17.8855 24 17.8855ZM24 20.6855C28.4183 20.6855 32 17.1038 32 12.6855C32 8.26727 28.4183 4.68555 24 4.68555C19.5817 4.68555 16 8.26727 16 12.6855C16 17.1038 19.5817 20.6855 24 20.6855Z"
      fill={style.mainBlue.blue}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 25.4855C22.0039 25.4855 20.0274 25.8787 18.1832 26.6426C16.3391 27.4064 14.6634 28.5261 13.252 29.9375C11.8405 31.349 10.7209 33.0246 9.95703 34.8688C9.19316 36.7129 8.8 38.6895 8.8 40.6855C8.8 41.4587 8.1732 42.0855 7.4 42.0855C6.6268 42.0855 6 41.4587 6 40.6855C6 38.3218 6.46558 35.9811 7.37017 33.7972C8.27475 31.6134 9.60062 29.6291 11.2721 27.9576C12.9435 26.2862 14.9278 24.9603 17.1117 24.0557C19.2956 23.1511 21.6362 22.6855 24 22.6855C26.3638 22.6855 28.7044 23.1511 30.8883 24.0557C33.0722 24.9603 35.0565 26.2862 36.7279 27.9576C38.3994 29.6291 39.7252 31.6134 40.6298 33.7972C41.5344 35.9811 42 38.3218 42 40.6855C42 41.4587 41.3732 42.0855 40.6 42.0855C39.8268 42.0855 39.2 41.4587 39.2 40.6855C39.2 38.6895 38.8068 36.7129 38.043 34.8688C37.2791 33.0246 36.1595 31.349 34.748 29.9375C33.3366 28.5261 31.6609 27.4064 29.8168 26.6426C27.9726 25.8787 25.9961 25.4855 24 25.4855Z"
      fill={style.mainBlue.blue}
    />
  </svg>
);
