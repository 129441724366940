import Skeleton from 'components/common/Skeleton';

import {
  BusinessMainCardSkeletonContent,
  BusinessMainCardSkeletonWrapper,
  BusinessMainCardSkeletonFooter,
  BusinessMainCardSkeletonContentLeft,
  BusinessMainCardSkeletonContentRight,
} from './BusinessMainCardSkeleton.styles';

const BusinessMainCardSkeleton = () => {
  return (
    <BusinessMainCardSkeletonWrapper>
      <Skeleton height={366} bottom={0} />
      <BusinessMainCardSkeletonContent>
        <BusinessMainCardSkeletonContentLeft>
          <Skeleton circle width="84px" borderRadius={100} bottom={16} />
        </BusinessMainCardSkeletonContentLeft>
        <BusinessMainCardSkeletonContentRight>
          <Skeleton height={14} width="40%" bottom={10} borderRadius={2} />
          <Skeleton height={18} width="70%" bottom={16} borderRadius={2} />
        </BusinessMainCardSkeletonContentRight>
      </BusinessMainCardSkeletonContent>

      <BusinessMainCardSkeletonFooter>
        <Skeleton height={30} width="28%" bottom={0} borderRadius={4} />
        <Skeleton height={30} width="22%" bottom={0} borderRadius={4} />
        <Skeleton height={30} width="18%" bottom={0} borderRadius={4} />
      </BusinessMainCardSkeletonFooter>
    </BusinessMainCardSkeletonWrapper>
  );
};

export default BusinessMainCardSkeleton;
