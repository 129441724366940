import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  mt?: number;
  mb?: number;
  mr?: number;
  ml?: number;
  lh?: number;
  width?: number;
  height?: number;
  img?: string;
  withBorder?: boolean;
  top?: number;
}

export const Wrapper = styled.div`
  padding-top: 20px;
`;

export const SearchFieldWrap = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  margin-bottom: 20px;
`;

export const SelectedUsersWrap = styled.div`
  height: 98px;
  box-sizing: border-box;
  margin-bottom: 20px;
  background-color: ${style.mainGray.whiteSmokeColor};
  padding: 12px 0 16px;
`;

export const UserPhoto = styled.div<IProps>`
  width: ${({ width }) => (width ? `${width}px` : '52px')};
  height: ${({ height }) => (height ? `${height}px` : '52px')};
  box-sizing: border-box;
  border-radius: 50%;
  border: ${({ withBorder }) =>
    withBorder ? `2px solid ${style.mainWhite.white}` : 'none'};
  position: relative;
  background: url(${({ img }) => img}) no-repeat;
  background-size: cover;
  background-position: center;
  margin-right: ${({ mr }) => (mr ? `${mr}px` : '0')};
  margin-left: ${({ ml }) => (ml ? `${ml}px` : '0')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
`;

export const SelectedUserWrap = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 16px;
  margin-left: ${({ ml }) => (ml ? `${ml}px` : '0')};
  position: relative;
  top: ${({ top }) => (top ? `${top}px` : '0')};

  :hover {
    .selected-item-username {
      color: ${style.mainBlue.blue};
    }

    .selected-item-remove-icon {
      display: flex;
    }
  }
`;

export const RemoveSelectedUserIconWrap = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${style.mainWhite.white};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  cursor: pointer;
  position: absolute;
  top: -2px;
  right: -2px;
  display: none;
  justify-content: center;
  align-items: center;
`;

export const UsernameText = styled.p<IProps>`
  font-size: 13px;
  line-height: ${({ lh }) => (lh ? `${lh}%` : '100%')};
  font-weight: 500;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
`;

export const FullNameText = styled.p`
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${style.mainGray.SuvaGrey};
`;

export const UserWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  box-sizing: border-box;
  border-bottom: 1px solid ${style.mainGray.whiteSmokeColor};
  cursor: pointer;
`;

export const UserInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CheckboxWrap = styled.div`
  width: 14px;
  height: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EmptyCheckbox = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 2px solid ${style.mainGray.whisperColor};
`;

export const NoResultWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
`;

export const Text = styled.p<IProps>`
  font-size: 13px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${style.mainGray.gray};
  text-align: center;
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
`;
