/* eslint-disable react/style-prop-object */
/* eslint-disable react/prop-types */
import React from 'react';

export const NotificationsIcon = ({
  colorStroke = '#1C1F29',
  width = '24',
  height = '24',
}: {
  colorStroke?: string;
  width?: string;
  height?: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.75 8.8847C18.75 10.7971 18.75 11.8614 18.75 13.7738C18.75 16.0687 19.5 17.5987 21 18.3636H3C4.5 17.5987 5.25 16.0687 5.25 13.7738C5.25 11.8614 5.25 10.7971 5.25 8.8847C5.25 5.08239 8.27208 2 12 2C15.7279 2 18.75 5.08239 18.75 8.8847Z"
      stroke={colorStroke}
      strokeWidth="1.6"
      strokeLinejoin="round"
    />
    <path
      d="M9.30117 19.2727C9.30117 20.7789 10.51 22 12.0012 22C13.4923 22 14.7012 20.7789 14.7012 19.2727"
      stroke={colorStroke}
      strokeWidth="1.6"
      strokeLinejoin="round"
    />
  </svg>
);
