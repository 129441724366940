import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  img?: string;
  mt?: number;
  mb?: number;
  fontSize?: number;
  color?: string;
  fontWeight?: number;
  isCoverPhotoExists?: boolean;
  width?: number;
  lastCardInLine?: number;
  isMyGroupsScreen?: boolean;
}

export const CardWrap = styled.div`
  width: 100%;
  background-color: ${style.mainWhite.white};
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

export const BackgroundImage = styled.span<IProps>`
  display: block;
  width: 100%;
  height: 184px;
  background: url(${({ img }) => img}) no-repeat;
  background-size: cover;
  background-position: center;
  cursor: pointer;
`;

export const GroupHeaderWrap = styled.div`
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  flex: 1;
`;

export const GroupNameWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  width: 100%;
`;

export const GroupNameText = styled.p<IProps>`
  width: 100%;
  max-width: calc(
    100% - ${({ isMyGroupsScreen }) => (isMyGroupsScreen ? '40px' : '31px')}
  );
  font-size: 15px;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 4px;
`;

export const GroupDescriptionText = styled.p<IProps>`
  width: 100%;
  font-size: 13px;
  line-height: 120%;
  letter-spacing: 0.02em;
  color: ${style.mainGray.SuvaGrey};
  max-width: calc(
    100% - ${({ isMyGroupsScreen }) => (isMyGroupsScreen ? '40px' : '31px')}
  );
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const GroupInfoWrap = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 12px 16px 8px 16px;
  background-color: ${style.mainWhite.white};
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Text = styled.p<IProps>`
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : '13px')};
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${({ color }) => color || style.mainGray.gray};
  font-weight: ${({ fontWeight }) => fontWeight || '400'};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
  text-align: center;
  text-transform: lowercase;
`;
