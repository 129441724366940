import styled from 'styled-components';

export const SidebarPopularPostsWrapper = styled.div``;
export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  & h6 {
    font-size: ${(props) => props.theme.typography.fontSize.f5};
  }
`;
export const TitleIcon = styled.span`
  display: flex;
  margin-right: 10px;
`;
export const SidebarPopularPostsList = styled.div`
  width: 270px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  height: auto;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const PopularPostsListItem = styled.div`
  width: 130px;
  height: 157px;
  cursor: pointer;
  border-radius: 8px;
  overflow: hidden;
  margin: 5px 0;

  & img {
    object-fit: cover;
  }
`;

export const PlaceHolderWrapper = styled.div`
  width: 140px;
  margin: 0 auto;
  padding: 20px 0;

  div {
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.02em;
    color: ${(props) => props.theme.colors.neutral.n6};
  }

  img {
    background: none;
  }
`;
