import React from 'react';

export const UsersIcon = ({
  size = '24',
  color = '#8D8D8D',
}: {
  size?: string;
  color?: string;
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.9997 20.3637V18.5455C21.9991 17.7398 21.731 16.9571 21.2373 16.3204C20.7437 15.6836 20.0526 15.2288 19.2725 15.0273"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5455 20.3637V18.5455C16.5455 17.5811 16.1623 16.6562 15.4804 15.9742C14.7984 15.2923 13.8735 14.9092 12.9091 14.9092H5.63636C4.67194 14.9092 3.74702 15.2923 3.06507 15.9742C2.38312 16.6562 2 17.5811 2 18.5455V20.3637"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.6367 4.11816C16.4189 4.31844 17.1122 4.77335 17.6073 5.41117C18.1024 6.049 18.3711 6.83346 18.3711 7.64089C18.3711 8.44832 18.1024 9.23278 17.6073 9.87061C17.1122 10.5084 16.4189 10.9633 15.6367 11.1636"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.27308 11.2727C11.2814 11.2727 12.9094 9.64467 12.9094 7.63636C12.9094 5.62806 11.2814 4 9.27308 4C7.26477 4 5.63672 5.62806 5.63672 7.63636C5.63672 9.64467 7.26477 11.2727 9.27308 11.2727Z"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
