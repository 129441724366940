import { useContext } from 'react';

import Link from 'components/common/Link';
import { ThemeContext } from 'theme';

import { PublicHeaderWrapper } from './PublicHeader.styles';

const PublicHeader = () => {
  const context: any = useContext(ThemeContext);
  const Logo = context.content.logoWithText;

  return (
    <PublicHeaderWrapper>
      <Link path="/">
        <Logo />
      </Link>
    </PublicHeaderWrapper>
  );
};

export default PublicHeader;
