const MailOutlineSolid = () => {
    return (
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M17.4997 4.15205V2.16667H2.49967V4.15166L9.99969 7.90205L17.4997 4.15205ZM17.4997 6.01544L9.99966 9.76545L2.49967 6.01509V13.8333H17.4997V6.01544ZM2.49967 0.5H17.4997C18.4201 0.5 19.1663 1.24619 19.1663 2.16667V13.8333C19.1663 14.7538 18.4201 15.5 17.4997 15.5H2.49967C1.5792 15.5 0.833008 14.7538 0.833008 13.8333V2.16667C0.833008 1.24619 1.5792 0.5 2.49967 0.5Z" fill="white" />
        </svg>


    )
}

export default MailOutlineSolid