import React from 'react';
import { style } from 'utils/constants/style';

export const UpcomingEventIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12"
      cy="12"
      r="11.3333"
      stroke={style.mainBlue.blue}
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.7692 16.6357L11.9995 15.6698L9.22966 16.6357C9.05812 16.6955 8.86738 16.6685 8.72001 16.5635C8.57264 16.4584 8.48741 16.2889 8.49237 16.1103L8.57329 13.2289L6.78066 10.9443C6.66954 10.8029 6.63674 10.6165 6.69306 10.4466C6.74937 10.2767 6.88747 10.1451 7.06224 10.0946L9.88194 9.27959L11.5438 6.90169C11.6469 6.75443 11.8174 6.66641 11.9995 6.66641C12.1816 6.66641 12.3521 6.75443 12.4552 6.90169L14.1171 9.27959L16.9368 10.0946C17.1116 10.1451 17.2497 10.2767 17.306 10.4466C17.3623 10.6165 17.3295 10.8027 17.2184 10.9443L15.4257 13.2291L15.5067 16.1103C15.5116 16.2889 15.4264 16.4586 15.279 16.5635C15.1834 16.6317 15.0693 16.667 14.9543 16.667C14.8921 16.667 14.8296 16.6566 14.7692 16.6357Z"
      fill={style.mainBlue.blue}
    />
  </svg>
);
