import React from 'react';

export const NotificationsOffIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3844 7.23076C11.3844 5.33845 10.3782 3.75999 8.61515 3.34152V2.92308C8.61515 2.4123 8.20285 2 7.69207 2C7.18129 2 6.769 2.4123 6.769 2.92308V3.34155C6.67977 3.3631 6.59361 3.3877 6.50745 3.41539C6.44283 3.43694 6.3813 3.45846 6.31975 3.4831C6.31975 3.4831 6.31666 3.4831 6.31666 3.48618C6.31357 3.48618 6.31051 3.48927 6.30743 3.48927C6.16588 3.54465 6.02742 3.60927 5.89204 3.68003C5.88896 3.68003 5.8859 3.68312 5.88281 3.68312L11.3844 9.49541V7.23076H11.3844Z"
      fill="#A7A7A7"
    />
    <path
      d="M12.6157 11.9662L5.13261 4.24617L3.55415 2.61847L2.76953 3.4L4.4926 5.12309L4.49569 5.12618C4.17261 5.73541 4.00031 6.45541 4.00031 7.2308V10.3077L2.76953 11.5385V12.1539H11.2188L12.4496 13.3846L13.2311 12.6L12.6157 11.9662Z"
      fill="#A7A7A7"
    />
    <path
      d="M7.69266 14C8.37268 14 8.92344 13.4492 8.92344 12.7692H6.46191C6.46191 13.4492 7.01267 14 7.69266 14Z"
      fill="#A7A7A7"
    />
  </svg>
);
