import { HYDRATE } from 'next-redux-wrapper';
import { AsyncStatus } from 'utils/types';
import { actionTypes } from './actions';

const initialState = {
  searchError: null,
  searchEventInviteResult: [],
  searchParams: {
    category: 0,
    start: 0,
    limit: 11,
    q: '',
    paramsForFilter: 0,
  },
  isRedirect: false,

  searchCategory: 'profiles',
  searchFilters: '',
  searchQuery: '',

  searchWithQueryReqStat: AsyncStatus.NONE,
  searchEventInviteReqStat: AsyncStatus.NONE,
  searchGroupsReqStat: AsyncStatus.NONE,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE: {
      return { ...state, ...action.payload };
    }

    // SEARCH EVENT INVITE
    case actionTypes.SEARCH_EVENT_INVITE_START:
      return { ...state, searchEventInviteReqStat: AsyncStatus.LOADING };

    case actionTypes.SEARCH_EVENT_INVITE_SUCCESS: {
      const { paramsForFilter, q } = action.data.params;
      const isNewList = paramsForFilter !== state.searchFilters;
      return {
        ...state,
        searchEventInviteResult: action.data.data,
        searchFilters: paramsForFilter,
        searchQuery: q,
        searchEventInviteReqStat: AsyncStatus.SUCCESS,
      };
    }

    case actionTypes.SEARCH_EVENT_INVITE_ERROR:
      return { ...state, searchEventInviteReqStat: AsyncStatus.ERROR };

    // CLEAR SEARCH STORE
    case actionTypes.CLEAR_SEARCH_STORE:
      return { ...initialState };

    // SET SEARCH PARAMS
    case actionTypes.SET_SEARCH_PARAMS:
      return { ...state, searchParams: action.data };

    // HANDLE REDIRECT
    case actionTypes.HANDLE_REDIRECT:
      return { ...state, isRedirect: action.data };

    default:
      return state;
  }
};

export default reducer;
