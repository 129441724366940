import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { useField } from 'react-final-form';

import { composeValidators, required } from 'utils/validators';
import FieldElect from 'components/common/FieldElect';
import fetchApi from 'hooks/fetchApi';
import API_NAME from 'services/api';

import { SearchIcon, WheelIcon } from 'public/assets/icons';
import {
  FormRenderPills,
  ItemForSelectText,
  ItemForSelectValue,
  ItemForSelectWrapper,
  RenderPills,
  SelectedPills,
  SelectedItemWrapper,
  SelectedItemText,
  SelectedValues,
  NoResultWrap,
  NoResultText,
  FormSearch,
  FormSearchWrapper,
} from './GroupForm.styles';

const search = (data: any[], searchValue: string) => {
  const searchValueLowerCase = searchValue?.toLocaleLowerCase();

  return data?.filter((elem) =>
    elem?.name?.toLowerCase()?.includes(searchValueLowerCase)
  );
};

const Interests = () => {
  const { t } = useTranslation();
  const theme: any = useTheme();
  const searchValue = useField('search').input.value;

  const getUserInterests = useSelector((store) => store.fetchData);

  const { action, loading } = fetchApi();

  const fetchData = useCallback(() => {
    action(API_NAME.GET_USER_INTERESTS, {});
  }, []);

  useEffect(() => {
    if (!(getUserInterests[API_NAME.GET_USER_INTERESTS]?.data?.length > 0)) {
      fetchData();
    }
  }, []);

  return (
    <FieldElect
      name="groupInterests"
      label={t('addNewGroup:groupInterests')}
      headerText="Group interests"
      render={(b, onRemove) => {
        return (
          b?.length > 0 &&
          b?.map((k) => {
            return (
              <RenderPills
                key={k?.id}
                onCrossClick={() => onRemove(k)}
                bgColor={theme.colors.neutral.n0}
                type="addTags"
                isCross
              >
                {k?.name}
              </RenderPills>
            );
          })
        );
      }}
      formRender={(value, onChange) => {
        const isSelectedItem = (id) => value?.map((v) => v?.id).includes(id);

        return (
          <>
            <FormSearchWrapper>
              <FormSearch
                name="search"
                prefix={<SearchIcon colorStroke={theme.colors.neutral.n6} />}
              />
            </FormSearchWrapper>
            {value?.length > 0 && (
              <SelectedItemWrapper>
                <SelectedItemText>{t('common:selected')}</SelectedItemText>
                <SelectedValues>
                  {value &&
                    value.map((s) => {
                      return (
                        <SelectedPills
                          key={s?.id}
                          onCrossClick={() => onChange(s)}
                          bgColor={theme.colors.blue}
                          color={theme.colors.white}
                          type="addTags"
                          isCross
                        >
                          {s?.name}
                        </SelectedPills>
                      );
                    })}
                </SelectedValues>
              </SelectedItemWrapper>
            )}

            <ItemForSelectWrapper>
              <ItemForSelectText>
                {t('addVehicle:selectYours')}
              </ItemForSelectText>
              <ItemForSelectValue>
                {getUserInterests[API_NAME.GET_USER_INTERESTS]?.data?.length >
                0 ? (
                  search(
                    getUserInterests[API_NAME.GET_USER_INTERESTS]?.data,
                    searchValue
                  )?.map((s, i) => {
                    return (
                      <FormRenderPills
                        key={s?._id || i}
                        onSelectItemClick={() => onChange({ ...s, id: s?._id })}
                        bgColor={
                          isSelectedItem(s?._id)
                            ? theme.colors.blue
                            : theme.colors.neutral.n0
                        }
                        color={
                          isSelectedItem(s?._id)
                            ? theme.colors.white
                            : theme.colors.neutral.text
                        }
                        type="addTags"
                      >
                        {s?.name}
                      </FormRenderPills>
                    );
                  })
                ) : (
                  <NoResultWrap>
                    <NoResultText>{t('chat:nothingFound')}</NoResultText>
                    <WheelIcon />
                  </NoResultWrap>
                )}
              </ItemForSelectValue>
            </ItemForSelectWrapper>
          </>
        );
      }}
      validate={composeValidators(required(t('error:thisFieldIsRequired')))}
    />
  );
};

export default Interests;
