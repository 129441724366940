import { Field } from 'react-final-form';

import { IField } from './Types';
import InputField from './Input';

const GroupLocalityTypesField = (props: IField) => {
  return <Field component={InputField} {...props} />;
};

export default GroupLocalityTypesField;
