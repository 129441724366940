import styled from 'styled-components';

import Text from 'components/common/Text';

interface IProps {
  isSelected?: boolean;
}

export const GroupLocalityWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const GroupLocalityItemWrap = styled.div<IProps>`
  width: 32%;
  height: 48px;
  padding: 8px 0;
  box-sizing: border-box;
  border: ${({ isSelected, theme }) =>
    isSelected ? 'none' : `1px solid ${theme.colors.neutral.n7}`};
  border-radius: 4px;
  background-color: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.blue : 'transparent'};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    color: ${({ isSelected, theme }) =>
      isSelected ? theme.colors.white : theme.colors.spunPearl};
  }
`;

export const GroupLocalityItemText = styled(Text)`
  font-size: 11px;
  line-height: 12px;
  font-weight: 500;
  letter-spacing: 0.02em;
  margin-top: 4px;
`;
