/* eslint-disable no-unused-expressions */
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { style } from 'utils/constants/style';
import { LockIcon } from 'public/assets/icons';
import { joinGroup, unjoinGroup, unfollowGroup, followGroup } from 'services';
import { IGroupCard } from './IGroupCard';
import {
  CardWrap,
  BackgroundImage,
  GroupHeaderWrap,
  GroupNameWrap,
  GroupNameText,
  GroupDescriptionText,
  GroupInfoWrap,
  Text,
} from './GroupCard.style';
import { ProfileImage } from '../Cards.style';
import {
  RectangleButton,
  EmptyUserProfilePhoto,
  ConfirmationModal,
} from '../../index';

export const GroupCard = ({
  id,
  coverPhoto,
  username,
  name,
  profilePhoto,
  followers,
  onClick,
  onJoinClick,
  isCoverPhotoExists,
  paginationRef,
  privacy,
  isFollowing,
  followingStatus,
  width,
  lastCardInLine,
  isMyGroupsScreen,
  isAdmin,
  membershipStatus,
  withConfirmationModal = true,
}: IGroupCard) => {
  const [following, setFollowing] = useState(followingStatus === 2);
  const [membership, setMembership] = useState(membershipStatus === 2);
  const [loadingDots, setLoadingDots] = useState(false);
  const [peopleCount, setPeopleCount] = useState(followers);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isConfirmationJoinModalOpen, setIsConfirmationJoinModalOpen] =
    useState(false);

  const profileIsPrivate = useMemo(() => privacy === 1, [privacy]);
  const pendingFollowRequest = useMemo(
    () => profileIsPrivate && (followingStatus === 1 || following),
    [following, profileIsPrivate, followingStatus]
  );

  const { t } = useTranslation();

  const getFollowersCountText = () => {
    if (peopleCount === 1) {
      return `1 ${
        isMyGroupsScreen ? t('search:member') : t('profile:follower')
      }`;
    }

    if (peopleCount > 1000) {
      return `${Math.floor(peopleCount / 1000)}k ${
        isMyGroupsScreen ? t('search:members') : t('profile:followers')
      }`;
    }

    return `${peopleCount} ${
      isMyGroupsScreen ? t('search:members') : t('profile:followers')
    }`;
  };

  const getJoinButtonText = () => {
    if (isAdmin) {
      return t('group:adminUppercase');
    }

    if (pendingFollowRequest) {
      return t('group:requestedUppercase');
    }

    if (isMyGroupsScreen) {
      if (membership) {
        return t('group:memberUppercase');
      }

      if (!membership && following) {
        return t('group:followingUppercase');
      }

      if (!following) {
        return t('group:followUppercase');
      }
    }

    return following ? t('group:memberUppercase') : t('group:joinUppercase');
  };

  const handlePressFollow = () => {
    if (pendingFollowRequest || isAdmin) {
      return;
    }

    setLoadingDots(true);

    if (isMyGroupsScreen) {
      if (membership) {
        unjoinGroup(id).then((data) => {
          if (data.ok) {
            setLoadingDots(false);
            setPeopleCount(peopleCount - 1);
            setMembership(false);
            setIsConfirmationModalOpen(false);
          }
          if (data.msg) {
            setLoadingDots(false);
            setIsConfirmationModalOpen(false);
          }
        });
      } else if (following) {
        unfollowGroup(id).then((data) => {
          if (data.ok) {
            setLoadingDots(false);
            setPeopleCount(peopleCount - 1);
            setFollowing(false);
            setIsConfirmationModalOpen(false);
          }
          if (data.msg) {
            setLoadingDots(false);
            setIsConfirmationModalOpen(false);
          }
        });
      } else {
        followGroup(id).then((data) => {
          if (data.ok) {
            setLoadingDots(false);
            setPeopleCount(peopleCount + 1);
            setFollowing(true);
            setIsConfirmationModalOpen(false);
          }
          if (data.msg) {
            setLoadingDots(false);
            setIsConfirmationModalOpen(false);
          }
        });
      }
    } else if (following) {
      unjoinGroup(id).then((data) => {
        if (data.ok) {
          setLoadingDots(false);
          setPeopleCount(peopleCount - 1);
          setFollowing(false);
          setIsConfirmationModalOpen(false);
        }
        if (data.msg) {
          setLoadingDots(false);
          setIsConfirmationModalOpen(false);
        }
      });
    } else {
      joinGroup(id).then((data) => {
        if (data.ok) {
          setLoadingDots(false);
          setPeopleCount(peopleCount + 1);
          setFollowing(true);
          setIsConfirmationModalOpen(false);
        }
        if (data.msg) {
          setLoadingDots(false);
          setIsConfirmationModalOpen(false);
        }
      });
    }
  };

  const onPressButton = () => {
    if (pendingFollowRequest || isAdmin) {
      return null;
    }

    const isJoinPrivateGroup = profileIsPrivate && !isFollowing;

    (following || membership || isJoinPrivateGroup) && withConfirmationModal
      ? setIsConfirmationModalOpen(true)
      : handlePressFollow();
  };

  const isBlueFill =
    (getJoinButtonText() !== t('group:joinUppercase') &&
      getJoinButtonText() !== t('group:followUppercase')) ||
    isAdmin;

  const renderConfirmationModal = () => {
    const isJoinPrivateGroup = profileIsPrivate && !isFollowing;
    let header;
    let body;

    if (isJoinPrivateGroup) {
      header = t('group:joinCapitalize');
      body = t('group:join');
    } else {
      header = membership ? t('group:unjoin') : t('group:unfollowCapitalize');
      body = membership ? t('group:leave') : t('group:unfollow');
    }

    const getBodyText = () => {
      if (body === t('group:join')) {
        return t('profile:areYouSureYouWantToJoinTheGroup');
      }
      if (body === t('group:leave')) {
        return t('profile:areYouSureYouWantToLeaveTheGroup');
      }

      return t('profile:areYouSureYouWantToUnfollowTheGroup');
    };

    return (
      <ConfirmationModal
        headerText={`${header} ${name}`}
        bodyText={getBodyText()}
        submitText={`${
          isJoinPrivateGroup
            ? t('group:joinUppercase')
            : t('group:confirmUppercase')
        }`}
        onSubmit={handlePressFollow}
        loading={loadingDots}
        onCancelClick={() => setIsConfirmationModalOpen(false)}
      />
    );
  };

  return (
    <CardWrap ref={paginationRef}>
      <BackgroundImage
        img={coverPhoto}
        onClick={onClick}
        role="img"
        aria-label="group cover"
      />

      <GroupInfoWrap>
        <GroupHeaderWrap>
          {profilePhoto?.trim() ? (
            <ProfileImage
              img={profilePhoto.trim()}
              height={40}
              width={40}
              withoutBorder
            >
              <span role="img" aria-label="profile card" />
            </ProfileImage>
          ) : (
            <EmptyUserProfilePhoto wrapSize={40} userIconSize={20} />
          )}

          <GroupNameWrap>
            <GroupNameText isMyGroupsScreen={isMyGroupsScreen}>
              {username}
            </GroupNameText>
            <GroupDescriptionText isMyGroupsScreen={isMyGroupsScreen}>
              {name}
            </GroupDescriptionText>
          </GroupNameWrap>
        </GroupHeaderWrap>

        <RectangleButton
          text={getJoinButtonText()}
          color={isBlueFill ? style.mainBlue.blue : style.mainWhite.white}
          backgroundColor={
            isBlueFill ? style.mainWhite.white : style.mainBlue.blue
          }
          onClick={onPressButton}
          height="32px"
          icon={profileIsPrivate && !pendingFollowRequest ? <LockIcon /> : null}
          buttonTextMargin={
            profileIsPrivate && !pendingFollowRequest ? '0 0 0 4px' : ''
          }
          border={isBlueFill ? `1px solid ${style.mainBlue.blue}` : ''}
        />

        {isMyGroupsScreen && (
          <Text color={style.mainGray.SuvaGrey} mt={4}>
            {getFollowersCountText()}
          </Text>
        )}
      </GroupInfoWrap>

      {/* {isConfirmationModalOpen && (
        <ConfirmationModal
          headerText={`${membership ? 'Unjoin' : 'Unfollow'} ${name}`}
          bodyText={`Are you sure you want to ${
            membership ? 'leave' : 'unfollow'
          } ${name} group?`}
          submitText="CONFIRM"
          onSubmit={handlePressFollow}
          loading={loadingDots}
          onCancelClick={() => setIsConfirmationModalOpen(false)}
        />
      )} */}
      {isConfirmationModalOpen && renderConfirmationModal()}
    </CardWrap>
  );
};
