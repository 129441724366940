export const actionTypes = {
  SEARCH_EVENT_INVITE_START: 'SEARCH_EVENT_INVITE_START',
  SEARCH_EVENT_INVITE_SUCCESS: 'SEARCH_EVENT_INVITE_SUCCESS',
  SEARCH_EVENT_INVITE_ERROR: 'SEARCH_EVENT_INVITE_ERROR',
  CLEAR_SEARCH_STORE: 'CLEAR_SEARCH_STORE',
  CLEAR_APP_CLUB_STATE: 'CLEAR_APP_CLUB_STATE',

  SET_SEARCH_PARAMS: 'SET_SEARCH_PARAMS',
  HANDLE_REDIRECT: 'HANDLE_REDIRECT',
};

export const clearAppClubState = () => ({
  type: actionTypes.CLEAR_APP_CLUB_STATE,
});
// SEARCH EVENT INVITE ACTIONS
export const searchEventInviteStart = (data) => ({
  type: actionTypes.SEARCH_EVENT_INVITE_START,
  data,
});

export const searchEventInviteSuccess = (data) => ({
  type: actionTypes.SEARCH_EVENT_INVITE_SUCCESS,
  data,
});

export const searchEventInviteError = (error) => ({
  type: actionTypes.SEARCH_EVENT_INVITE_ERROR,
  error,
});

// CLEAR SEARCH STORE
export const clearSearchStore = () => ({
  type: actionTypes.CLEAR_SEARCH_STORE,
});

// SET SEARCH PARAMS
export const setSearchParams = (data) => ({
  type: actionTypes.SET_SEARCH_PARAMS,
  data,
});

// HANDLE REDIRECT ACTIONS
export const handleRedirect = (data) => ({
  type: actionTypes.HANDLE_REDIRECT,
  data,
});
