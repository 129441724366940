import React from 'react';

export const RoadIcon = ({ color = '#A9A9B5' }: { color?: string }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9219 2.32625C13.8721 1.96188 13.5336 1.6665 13.1659 1.6665H10.5177L10.5875 4.78925C10.5895 4.87957 10.5089 4.9528 10.4074 4.9528H9.00056C8.89907 4.9528 8.81917 4.87957 8.82212 4.78925L8.92414 1.66952L6.32908 1.6665C5.96134 1.6665 5.62028 1.96188 5.56736 2.32625L3.33745 17.6734C3.28451 18.0378 3.71125 18.3332 4.29063 18.3332H8.38826C8.38299 18.3197 8.38008 18.3056 8.38058 18.291L8.48721 15.03C8.49016 14.9397 8.60016 14.8665 8.73287 14.8665H10.5722C10.7049 14.8665 10.8141 14.9397 10.8161 15.03L10.8889 18.291C10.8893 18.3056 10.8862 18.3197 10.8808 18.3332H15.0617C15.641 18.3332 16.0703 18.0378 16.0205 17.6734L13.9219 2.32625ZM8.77807 6.14824C8.781 6.05792 8.86833 5.9847 8.97307 5.9847H10.425C10.5297 5.9847 10.6163 6.05792 10.6183 6.14824L10.6879 9.26797C10.6899 9.35829 10.5978 9.43152 10.4822 9.43152H8.87997C8.76439 9.43152 8.67304 9.35829 8.67601 9.26797L8.77807 6.14824ZM8.75961 13.872H10.5556C10.6852 13.872 10.7886 13.7988 10.7866 13.7085L10.7169 10.5888C10.7149 10.4984 10.617 10.4252 10.4983 10.4252H8.85269C8.73394 10.4252 8.6353 10.4984 8.63235 10.5888L8.53033 13.7085C8.5274 13.7988 8.63003 13.872 8.75961 13.872Z"
      fill={color}
    />
  </svg>
);
