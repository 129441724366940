import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorMessage } from 'components';
import NavTabs from 'components/common/NavTabs';
import ScheduleTime from 'components/ScheduleTime';
import { ERROR_MESSAGES } from 'utils/constants';
import { ISchedulePost } from './ISchedulePost';

import { SchedulePostHeader, SchedulePostWrapper } from './SchedulePost.styles';

const SchedulePost = ({
  setScheduledPostData,
  scheduledPostData,
}: ISchedulePost) => {
  const [activeTab, setActiveTab] = useState<number>(1);

  const { t } = useTranslation();

  const onPostTimeChange = (newDate) => {
    if (newDate <= new Date()) {
      setScheduledPostData({
        date: newDate,
        error: ERROR_MESSAGES().scheduledPostError,
      });
    } else {
      setScheduledPostData({ date: newDate, error: '' });
    }
  };

  return (
    <SchedulePostWrapper>
      <SchedulePostHeader type="h3">
        {t('common:schedulePost')}
      </SchedulePostHeader>
      <NavTabs
        tabsList={[
          { id: 1, name: t('common:no') },
          { id: 2, name: t('common:yes') },
        ]}
        defaultActive={activeTab}
        onHandleSelect={setActiveTab}
      />
      {activeTab === 2 && (
        <>
          <ScheduleTime
            headerText={t('common:date')}
            selectedDate={scheduledPostData.date}
            onScheduleTimeChange={onPostTimeChange}
            minDate={new Date()}
          />
          {scheduledPostData.error && (
            <ErrorMessage text={scheduledPostData.error} mt={6} />
          )}
        </>
      )}
    </SchedulePostWrapper>
  );
};

export default SchedulePost;
