import Card from 'components/common/Card';

import styled from 'styled-components';

export const PostSkeletonWrapper = styled(Card)`
  height: auto;
  width: 100%;
  max-width: 550px;
  margin-bottom: 16px;
  @media (max-width: 861px) {
    display: block;
    margin: 0 auto 16px;
  }
`;
export const PostSkeletonHeader = styled.div`
  padding: 16px;
  display: -webkit-box;
`;

export const PostSkeletonHeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  margin-left: 16px;
`;

export const PostSkeletonFooter = styled.div`
  padding: 16px;
  display: flex;
  & div {
    &:not(:last-child) {
      margin-right: 24px;
    }
    &:last-child {
      margin-left: auto;
    }
  }
`;
