import styled from 'styled-components';

import { style } from 'utils/constants/style';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  p {
    font-size: 18px;
    line-height: 20px;
    font-weight: 500;
    letter-spacing: 0.02em;
    color: ${style.mainBlack.black};
    margin-left: 4px;
    margin-right: 2px;
  }

  span {
    font-size: 16px;
    line-height: 100%;
    font-weight: 500;
    letter-spacing: 0.02em;
    color: ${style.mainGray.SuvaGrey};
  }
`;

export const SeparateDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 0 11px;
  background-color: ${style.mainGray.SuvaGrey};
`;
