import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  margin?: string;
  iconLeft?: boolean;
}

export const Wrapper = styled.div<IProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${({ margin }) => margin || '0'};
  padding: 6px 0;
`;

export const TitleWithIconWrap = styled.div`
  display: flex;
`;

export const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const Title = styled.p<IProps>`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  margin-left: ${({ iconLeft }) => (iconLeft ? '8px' : '0')};
`;
