import styled from 'styled-components';

import { style } from 'utils/constants/style';

export const MessageWrap = styled.div`
  display: inline-block;
  margin-bottom: 12px;
  /* margin-top: 3px;  */
  background-color: ${style.mainGray.snowColor};
  padding: 3px 24px;
  font-size: 12px;
  line-height: 100%;
  font-weight: 400;
  color: ${style.mainGray.SuvaGrey};
  border-radius: 22px;
  align-self: center;
`;
