/* eslint-disable react/prop-types */
import React from 'react';
import { style } from '../../../utils/constants/style';

export const ThreeDotsIcon = ({
  colorStroke = '#1C1F29',
}: {
  colorStroke?: string | undefined;
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 12C8 13.1046 7.10457 14 6 14C4.89543 14 4 13.1046 4 12C4 10.8954 4.89543 10 6 10C7.10457 10 8 10.8954 8 12ZM14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 10 12 10C13.1046 10 14 10.8954 14 12ZM18.3333 14C19.4379 14 20.3333 13.1046 20.3333 12C20.3333 10.8954 19.4379 10 18.3333 10C17.2288 10 16.3333 10.8954 16.3333 12C16.3333 13.1046 17.2288 14 18.3333 14Z"
      fill={colorStroke}
    />
  </svg>
);
