/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { ISelectedVehicleForm } from './ISelectedVehicleForm';

import { FinalFormWrapper, FormWrapper } from './SelectedVehicleForm.styles';

const SelectedVehicleForm = (props: ISelectedVehicleForm) => {
  const { t } = useTranslation();

  return (
    <>
      <FinalFormWrapper
        {...props}
        render={(formRenderProps: FormRenderProps) => {
          const { handleSubmit, form, values, initialValues } = formRenderProps;
          const { inProgress, ready, onIconClick, children } = props;

          return (
            <>
              <FormWrapper
                onSubmit={async (e) => {
                  await handleSubmit(e);
                }}
                onIconClick={onIconClick}
                isHeaderBorder
                headerText="Select vehicle"
                submitText={t('editUserProfile:save')}
                disabled={false}
              >
                {children}
              </FormWrapper>
            </>
          );
        }}
      />
    </>
  );
};

export default SelectedVehicleForm;
