import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { LoadingComponent } from 'components';

import {
  ArrowBack,
  EmptyGroupIcon,
  SearchIcon,
  WheelIcon,
} from 'public/assets/icons';
import { closeSidebarInviteEvent } from 'store/sideBarControler/actions';
import { searchEventInviteStart } from 'store/search/actions';
import { inviteUserToEvent, getInvitedUsers } from 'services/inviteUserEvent';
import { style } from 'utils/constants/style';
import Image from 'components/common/Image';
import {
  InputWrapper,
  UserNameEvent,
  EventUserItemWrap,
  ImageWrap,
  ButtonWrap,
  EmptyEventImg,
  EventNameWrap,
  PlaceholderWrap,
  Text,
} from './InviteToEvent.styled';
import { InputField, RectangleButton } from '../..';
import { SidebarWrapper } from '..';

export const EventUserItem = ({
  el,
  onInvite,
  loading,
  invited,
  isMe,
  chatRef,
}: any) => {
  const { t } = useTranslation();

  return (
    <EventUserItemWrap ref={chatRef}>
      {el?.profilePhoto ? (
        <ImageWrap>
          <Image
            loader={() => el.profilePhoto}
            src={el?.profilePhoto?.trim() || el?.coverPhoto?.trim()}
            alt="Picture of user"
            width={48}
            height={48}
            unoptimized
            priority
          />
        </ImageWrap>
      ) : (
        <ImageWrap>
          <EmptyEventImg>
            <EmptyGroupIcon />
          </EmptyEventImg>
        </ImageWrap>
      )}
      <EventNameWrap>
        <UserNameEvent>{el?.username}</UserNameEvent>
        <span>{el?.fullName}</span>
      </EventNameWrap>

      {!isMe && (
        <ButtonWrap>
          <RectangleButton
            color={invited ? style.mainBlue.blue : style.mainWhite.white}
            text={
              invited ? t('event:invitedUppercase') : t('event:inviteUppercase')
            }
            backgroundColor={
              invited ? style.mainBlue.aliceBlueColor : style.mainBlue.blue
            }
            onClick={() => onInvite(el._id)}
            loading={loading}
            disabled={invited}
          />
        </ButtonWrap>
      )}
    </EventUserItemWrap>
  );
};
interface InviteToEventProps {
  eventId?: string;
}
const getSearchByQueryResults = (q: string, username: string) => {
  const lowerCaseQ = q.toLowerCase();
  const lowerCaseUsername = username.toLowerCase();

  return lowerCaseUsername.includes(lowerCaseQ);
};

const INVITE_PAGE_SIZE = 11;
const InviteToEvent = ({ eventId }: InviteToEventProps) => {
  const [filtred, setFiltred] = useState('');
  const [loading, setLoading] = useState(false);
  const [invitedIdList, setInvitedIds] = useState([]);
  const [invitedId, setInvitedId] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [isLastInviteElementVisible, setIsLastInviteElementVisible] =
    useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const observer = useRef(null);

  const { searchEventInviteResult } = useSelector((state) => state.search);
  useEffect(() => {
    getInvitedUsers(eventId).then((res) => {
      const inVitedUsersId = res?.data?.map((d) => d._id);
      setInvitedIds(inVitedUsersId);
    });
    dispatch(
      searchEventInviteStart({
        eventId,
        prefix: 'next',
        from: 0,
        size: INVITE_PAGE_SIZE,
        q: filtred === '' ? '' : `&q=${filtred}`,
        paramsForFilter: filtred,
      })
    );
  }, [filtred]);

  useEffect(() => {
    if (isLastInviteElementVisible) {
      loadNextPage();
      setIsLastInviteElementVisible(false);
    }
  }, [isLastInviteElementVisible]);

  const loadNextPage = () => {
    dispatch(
      searchEventInviteStart({
        eventId,
        prefix: 'next',
        from: 0,
        size: searchEventInviteResult?.length + INVITE_PAGE_SIZE,
        q: filtred === '' ? '' : `&q=${filtred}`,
        paramsForFilter: filtred,
      })
    );
  };

  const lastInviteElement = useCallback(
    (node) => {
      if (observer.current) observer?.current?.disconnect();
      observer.current = new IntersectionObserver((entires) => {
        if (entires[0]?.isIntersecting) {
          setIsLastInviteElementVisible(true);
        }
      });
      if (node) observer.current.observe(node);
    },
    [searchEventInviteResult?.length]
  );

  const onInviteUserToGroup = (id) => {
    setLoading(true);
    setInvitedId(id);
    setInvitedIds((prevState) => [...prevState, id]);
    const usersId = {
      users: [...invitedIdList, id],
    };
    inviteUserToEvent(eventId, usersId).then((data) => setLoading(false));
  };
  const userId = JSON.parse(localStorage.getItem('user_id'));

  const chatsList = (
    searchValue?.length > 0
      ? searchEventInviteResult?.filter((e) =>
          getSearchByQueryResults(searchValue, e.name)
        )
      : searchEventInviteResult
  )?.map((el) => (
    <EventUserItem
      key={el._id}
      el={el}
      isMe={userId === el._id}
      onInvite={(id) => onInviteUserToGroup(id)}
      loading={invitedId === el._id && loading}
      invited={invitedIdList.includes(el._id)}
      chatRef={lastInviteElement}
    />
  ));

  const renderPlaceholder = () => {
    return <LoadingComponent fullWidth color="#C2C2C2" variant="spin" />;
  };

  return (
    <SidebarWrapper
      headerText={t('event:inviteToEvent')}
      headerIcon={<ArrowBack />}
      onIconClick={() => {
        dispatch(closeSidebarInviteEvent());
      }}
      contentPadding="0 0 20px 0"
    >
      <InputWrapper>
        <InputField
          value={null}
          onChange={(e) => setFiltred(e.target.value)}
          iconLeft={<SearchIcon colorStroke="#C2C2C2" />}
          crossIcon
          maxLength={50}
        />
        {searchEventInviteResult?.length ? (
          chatsList?.length ? (
            chatsList
          ) : (
            <PlaceholderWrap>
              <Text mb={30}>{t('search:nothingFound')}</Text>
              <WheelIcon />
            </PlaceholderWrap>
          )
        ) : (
          renderPlaceholder()
        )}
      </InputWrapper>
    </SidebarWrapper>
  );
};

export default InviteToEvent;
