/* eslint-disable no-case-declarations */
import { actionTypes } from './actions';

const reducer = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.APP_STATE:
      const apiKey = action.data?.key;
      const data = action?.data?.data;
      return {
        ...state,
        [apiKey]: data,
      };

    default:
      return state;
  }
};

export default reducer;
