import fetchData from 'utils/fetchData';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const getCarBrands = () => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._DB_HOST}api/v01/cars/brand`, {
      method: 'get',
      headers: { ...headers, tsec: JSON.parse(token) },
    }).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const addCarBrand = (brand) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._DB_HOST}api/v01/tags/brand?tag=${brand}`, {
      method: 'PUT',
      headers: { ...headers, tsec: JSON.parse(token) },
    }).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const addCarBrands = (brands) => {
  try {
    const token = localStorage.getItem('token');
    const userId = JSON.parse(localStorage.getItem('user_id'));

    return fetchData(`${process.env._DB_HOST}api/v01/user/${userId}/brand`, {
      method: 'POST',
      headers: { ...headers, tsec: JSON.parse(token) },
      body: JSON.stringify({ brands }),
    }).then((response) =>
      response.status === 204 ? response : response.json()
    );
  } catch (error) {
    console.log(error);
  }
};

export const getCarTypes = () => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._DB_HOST}api/v01/cars/types`, {
      method: 'GET',
      headers: { ...headers, tsec: JSON.parse(token) },
    }).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const addCarType = (type) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOST}api/v01/tags/car-type?tag=${type}`,
      {
        method: 'PUT',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const getCarModels = (brandId) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._DB_HOST}api/v01/cars/${brandId}/models`, {
      method: 'GET',
      headers: { ...headers, tsec: JSON.parse(token) },
    }).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const addCarModel = ({ model, brandId }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOST}api/v01/tags/brand/${brandId}/model?tag=${model}`,
      {
        method: 'PUT',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const addVehicleToMyGarage = (vehicleData) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._DB_HOST}api/v01/cars/`, {
      method: 'POST',
      headers: { ...headers, tsec: JSON.parse(token) },
      body: JSON.stringify(vehicleData),
    }).then((response) => (response.status === 204 ? null : response.json()));
  } catch (error) {
    console.log(error);
  }
};

export const addTutorialVehicle = (vehicleData) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOSTv12}api/v12/profile/tutorial/vehicle`,
      {
        method: 'POST',
        headers: { ...headers, tsec: JSON.parse(token) },
        body: JSON.stringify(vehicleData),
      }
    ).then((response) =>
      response.status === 204 ? response : response.json()
    );
  } catch (error) {
    console.log(error);
  }
};

export const editMyVehicle = ({ vehicleData, vehicleId }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOST}api/v01/cars/detail/${vehicleId}`,
      {
        method: 'PATCH',
        headers: { ...headers, tsec: JSON.parse(token) },
        body: JSON.stringify(vehicleData),
      }
    ).then((response) => (response.status === 204 ? null : response.json()));
  } catch (error) {
    console.log(error);
  }
};

export const getVehicleInfo = (vehicleId) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOST}api/v10/cars/detail/${vehicleId}`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const likeVehicle = (vehicleId) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOST}api/v01/cars/detail/${vehicleId}/like`,
      {
        method: 'PUT',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((resp) => (resp.status === 204 ? resp : resp.json()));
  } catch (error) {
    console.log(error);
  }
};

export const unlikeVehicle = (vehicleId) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOST}api/v01/cars/detail/${vehicleId}/like`,
      {
        method: 'DELETE',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((resp) => (resp.status === 204 ? resp : resp.json()));
  } catch (error) {
    console.log(error);
  }
};

export const reportVehicle = ({ vehicleId, reportData }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOST}api/v01/cars/${vehicleId}/report`,
      {
        method: 'POST',
        headers: { ...headers, tsec: JSON.parse(token) },
        body: JSON.stringify(reportData),
      }
    ).then((resp) => (resp.status === 204 ? resp : resp.json()));
  } catch (error) {
    console.log(error);
  }
};
