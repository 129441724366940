import React from 'react';
import { style } from 'utils/constants/style';

export const CustomNotificationIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12"
      cy="12"
      r="11.3333"
      fill="white"
      stroke={style.mainBlue.dodgerBlue}
      strokeWidth="1.33333"
    />
    <path
      d="M13.86 16.1103L13.7124 16.7576C13.2697 16.9451 12.916 17.0878 12.6527 17.1859C12.389 17.2844 12.0827 17.3333 11.7337 17.3333C11.1977 17.3333 10.7808 17.1924 10.4835 16.9126C10.1862 16.6317 10.0375 16.2756 10.0375 15.8437C10.0375 15.6766 10.0483 15.5046 10.0707 15.3294C10.0933 15.154 10.1293 14.9564 10.1785 14.7356L10.7318 12.6342C10.781 12.433 10.8229 12.2423 10.8564 12.062C10.8904 11.8829 10.9067 11.718 10.9067 11.5696C10.9067 11.3013 10.855 11.1135 10.7519 11.008C10.6488 10.9028 10.4521 10.8494 10.1601 10.8494C10.017 10.8494 9.87002 10.8739 9.72017 10.9215C9.56966 10.9692 9.44102 11.0153 9.33301 11.0582L9.48096 10.4103C9.84342 10.252 10.1899 10.1164 10.5213 10.0038C10.8527 9.891 11.1658 9.83452 11.462 9.83452C11.9943 9.83452 12.405 9.97256 12.6935 10.2486C12.9819 10.5249 13.1261 10.8831 13.1261 11.3244C13.1261 11.4157 13.1166 11.5766 13.0962 11.8066C13.0764 12.0371 13.0393 12.2482 12.9854 12.4403L12.4345 14.5332C12.3893 14.7013 12.3487 14.8935 12.3134 15.1098C12.2769 15.3246 12.2595 15.4887 12.2595 15.5989C12.2595 15.8768 12.3173 16.0665 12.433 16.1674C12.5497 16.2682 12.7505 16.3184 13.0358 16.3184C13.1698 16.3184 13.3222 16.2929 13.4915 16.2428C13.6604 16.1928 13.7836 16.1488 13.86 16.1103ZM13.9997 7.32351C13.9997 7.68816 13.8716 7.99959 13.6142 8.25566C13.3575 8.51264 13.0481 8.64123 12.6863 8.64123C12.3232 8.64123 12.0131 8.51264 11.7535 8.25566C11.4943 7.99949 11.3644 7.68816 11.3644 7.32351C11.3644 6.95957 11.4943 6.64764 11.7535 6.38832C12.0127 6.12941 12.3233 6 12.6863 6C13.0481 6 13.3575 6.12971 13.6142 6.38832C13.8718 6.64764 13.9997 6.95967 13.9997 7.32351Z"
      fill={style.mainBlue.dodgerBlue}
    />
  </svg>
);
