import React from 'react';

export const EventsIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.2 9V6.6H4.6V11.2H19.4V6.6H15.8V9H14.2V6.6H9.8V9H8.2ZM14.2 5H9.8V3H8.2V5H4C3.44772 5 3 5.44772 3 6V20C3 20.5523 3.44772 21 4 21H20C20.5523 21 21 20.5523 21 20V6C21 5.44772 20.5523 5 20 5H15.8V3H14.2V5ZM4.6 12.8V19.4H19.4V12.8H4.6Z"
      fill="#8D8D8D"
    />
  </svg>
);
