import React from 'react';

export const UsersIcon2 = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5931 13H15C14.8857 12.9531 14.7962 12.6769 14.694 12.3619C14.5513 11.9215 14.3839 11.4053 14.0901 11.3333C13.8 11.2623 13.5377 11.2028 13.3007 11.149C12.4686 10.9602 11.9486 10.8422 11.6375 10.5446C11.3566 10.2759 11.5158 10.1125 11.8134 9.80699C12.2756 9.33255 13.0715 8.51543 13.0715 6.42936C13.0715 4.14312 12.1155 3 10.2035 3C9.74808 3 9.34693 3.06484 9 3.19452C10.1097 3.60935 10.6646 4.68762 10.6646 6.42936C10.6646 8.51543 9.86865 9.33255 9.40649 9.80699C9.1089 10.1125 8.94971 10.2759 9.23057 10.5446C9.54167 10.8422 10.0616 10.9602 10.8938 11.149C11.1308 11.2028 11.3931 11.2623 11.6831 11.3333C11.977 11.4053 12.1443 11.9215 12.2871 12.3619C12.3892 12.6769 12.4788 12.9531 12.5931 13Z"
      fill="#A9A9B5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 13C1.25395 12.8997 1.65902 11.3333 2.02468 11.2908C3.45961 11.1238 4.1084 10.935 4.53206 10.5446C5.27639 9.85871 3.0434 9.85871 3.0434 6.42936C3.0434 4.14312 4.03584 3 6.02072 3C8.00561 3 8.99805 4.14312 8.99805 6.42936C8.99805 9.85871 6.76505 9.85871 7.50939 10.5446C7.92433 10.9269 8.6972 11.0128 10.0554 11.3333C10.5787 11.4568 10.7155 12.8877 11 13"
      fill="#A9A9B5"
    />
  </svg>
);
