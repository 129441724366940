import styled from 'styled-components';

interface IProps {
  active?: boolean;
}

export const NavigationWrapper = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
`;
export const NavigationItemWrapper = styled.li<IProps>`
  &:first-child {
    & svg {
      & > path {
        stroke: ${(props) => props.active && props.theme.colors.blue};
        fill: ${(props) => props.active && props.theme.colors.blue};
      }
    }
  }
  &:nth-child(4) {
    & svg {
      & > path {
        stroke: none;
        fill: ${(props) => props.active && props.theme.colors.blue};
      }
    }
  }
  &:last-child {
    & button {
      margin: 0;
    }
  }
`;
