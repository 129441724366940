const ExternalLink = () => {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5003 9.33333V12.0889C12.5003 12.4189 12.3692 12.7355 12.1358 12.9688C11.9025 13.2022 11.5859 13.3333 11.2559 13.3333H4.41144C4.08139 13.3333 3.76486 13.2022 3.53148 12.9688C3.2981 12.7355 3.16699 12.4189 3.16699 12.0889V5.24444C3.16699 4.9144 3.2981 4.59787 3.53148 4.36449C3.76486 4.13111 4.08139 4 4.41144 4H7.16699" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M9.83301 2.66663H13.833V6.66663" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6.5 9.99996L13.8333 2.66663" stroke="white" strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}

export default ExternalLink