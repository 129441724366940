import Skeleton from 'components/common/Skeleton';

import {
  SidbarListItemSkeleton,
  SidbarListItemSkeletonRight,
} from './SidebarListItemSkeleton.styles';

const SidebarListItemSkeleton = () => (
  <SidbarListItemSkeleton>
    <Skeleton circle width="40px" borderRadius={50} bottom={0} />
    <SidbarListItemSkeletonRight>
      <Skeleton height={8} width="50%" borderRadius={10} bottom={6} />
      <Skeleton height={6} width="36%" bottom={0} borderRadius={10} />
    </SidbarListItemSkeletonRight>
  </SidbarListItemSkeleton>
);

export default SidebarListItemSkeleton;
