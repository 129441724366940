import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSwitchAppClubAccount from 'utils/useSwitchAppClubAccount';
import {
  getAllClubsRequest,
  postActiveClubInfoRequest,
  setActiveAccountId,
} from 'store/club/actions';
import { getClubIdFromStorage, setClubIdInStorage } from './storageUtils';

const ClubManager = () => {
  const dispatch = useDispatch();
  const activeAccountId = useSelector((state) => state.club.activeAccountId);
  const userId = useSelector((store) => store.user?.userDetails?._id);
  const userDetails = useSelector((store) => store.user?.userDetails);
  const userActiveClub = userDetails?.activeClub;
  console.log('USER_DETAIL_IS', userDetails);
  const token = useSelector((state) => state?.login?.token);
  console.log('TOKEN', token);
  const { handleClubSwitch } = useSwitchAppClubAccount();

  useEffect(() => {
    if (userId) {
      dispatch(getAllClubsRequest());
    }
  }, [userId]);

  // Executes on application start
  useEffect(() => {
    if (!userId) {
      return;
    }
    const storedAccountId = userActiveClub;
    dispatch(setActiveAccountId(storedAccountId));
    dispatch(postActiveClubInfoRequest(storedAccountId));
  }, [dispatch, userId, userActiveClub]);

  useEffect(() => {
    if (!userId) {
      return;
    }
    const storedActiveAccountId = getClubIdFromStorage();
    if (activeAccountId && storedActiveAccountId !== activeAccountId) {
      handleClubSwitch(activeAccountId);
    } else if (!userActiveClub && storedActiveAccountId) {
      handleClubSwitch('RoadStr');
    }
  }, [activeAccountId, dispatch, handleClubSwitch, userId]);
  return null;
};

export default ClubManager;
