import { useSelector } from 'react-redux';
import ImageSlider from 'containers/ImageSlider';
import appState from 'hooks/appState';
import { APP_STATE } from 'types';

import { ModalWrapper } from './ExpandImage.styles';

const ExpandImage = () => {
  const appStateStore = useSelector((store) => store.app);

  const appStateAction = appState();

  const onClose = () => {
    appStateAction(APP_STATE.EXPAND_IMAGES_TOGGLE, {
      isOpen: false,
    });
  };

  return (
    <ModalWrapper
      open={appStateStore[APP_STATE.EXPAND_IMAGES_TOGGLE]?.isOpen || false}
      onClose={onClose}
      expanded
      hasImage={
        appStateStore[APP_STATE.EXPAND_IMAGES_TOGGLE]?.data?.mediaObjects
          ?.length
      }
    >
      <ImageSlider
        images={
          appStateStore[APP_STATE.EXPAND_IMAGES_TOGGLE]?.data?.mediaObjects
        }
        alt="photo post"
        variantToUse="_detail"
        isResize={
          appStateStore[APP_STATE.EXPAND_IMAGES_TOGGLE]?.isResize === undefined
        }
      />
    </ModalWrapper>
  );
};

export default ExpandImage;
