import { HYDRATE } from 'next-redux-wrapper';
import { AsyncStatus } from 'utils/types';
import { actionTypes } from './actions';

const initialState = {
  notifications: null,
  pendingNotifications: null,
  notificationsError: null,
  pendingNotificationsError: null,

  getUserNotificationsReqStat: AsyncStatus.NONE,
  getUserPendingNotificationReqStat: AsyncStatus.NONE,
  acceptPendingRequestReqStat: AsyncStatus.NONE,
  declinePendingRequestStart: AsyncStatus.NONE,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE: {
      return { ...state, ...action.payload };
    }

    // GET USER NOTIFICATIONS
    case actionTypes.GET_USER_NOTIFICATIONS_START:
      return { ...state, getUserNotificationsReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_USER_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.data,
        getUserNotificationsReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_USER_NOTIFICATIONS_ERROR:
      return {
        ...state,
        getUserNotificationsReqStat: AsyncStatus.ERROR,
        notificationsError: action.error,
      };

    // GET USER PENDING NOTIFICATIONS
    case actionTypes.GET_USER_PENDING_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        pendingNotifications: action.data,
        getUserPendingNotificationReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_USER_PENDING_NOTIFICATIONS_ERROR:
      return {
        ...state,
        pendingNotificationsError: action.error,
        getUserPendingNotificationReqStat: AsyncStatus.ERROR,
      };

    // ACCEPT PENDING REQUEST
    case actionTypes.ACCEPT_PENDING_REQUEST_START:
      return {
        ...state,
        acceptPendingRequestReqStat: AsyncStatus.LOADING,
      };

    case actionTypes.ACCEPT_PENDING_REQUEST_SUCCESS:
      return {
        ...state,
        acceptPendingRequestReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.ACCEPT_PENDING_REQUEST_ERROR:
      return {
        ...state,
        acceptPendingRequestReqStat: AsyncStatus.ERROR,
      };

    // DECLINE PENDING REQUEST
    case actionTypes.DECLINE_PENDING_REQUEST_START:
      return {
        ...state,
        declinePendingRequestStart: AsyncStatus.LOADING,
      };

    case actionTypes.DECLINE_PENDING_REQUEST_SUCCESS:
      return {
        ...state,
        declinePendingRequestStart: AsyncStatus.SUCCESS,
      };

    case actionTypes.DECLINE_PENDING_REQUEST_ERROR:
      return {
        ...state,
        declinePendingRequestStart: AsyncStatus.ERROR,
      };

    // CLEAR NOTIFICATIONS STORE
    case actionTypes.CLEAR_NOTIFICATIONS_STORE:
      return { ...initialState };

    default:
      return state;
  }
};

export default reducer;
