/* eslint-disable react/prop-types */
import React from 'react';

export const MapIcon = ({
  colorStroke = '#1C1F29',
}: {
  colorStroke?: string;
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9996 15.0648C15.398 11.5369 16.66 9.80636 16.66 7.00632C16.66 4.55315 14.6095 2.5 11.9996 2.5C9.38966 2.5 7.33916 4.55315 7.33916 7.00632C7.33916 9.80636 8.60116 11.5369 11.9996 15.0648ZM11.4061 16.6083C10.7638 15.9477 10.1772 15.3365 9.64605 14.7587V19.2413L14.3533 21.5971V14.7585C13.8221 15.3365 13.2354 15.9477 12.5931 16.6083C12.2688 16.9419 11.7304 16.9419 11.4061 16.6083ZM8.17611 19.2649V13.0543C6.8529 11.3814 6.0916 9.87755 5.89222 8.02693L3.46994 8.97418V21.4302L8.17611 19.2649ZM11.9996 1C8.78635 1 6.14769 3.39856 5.86435 6.45949L2.80644 7.65531C2.32664 7.7954 2 8.23787 2 8.73546V21.9811C2 22.3191 2.15128 22.6402 2.41426 22.8546C2.61519 23.0187 2.8646 23.1061 3.1193 23.1061C3.20185 23.1061 3.28093 23.0968 3.35476 23.0812L3.4352 23.0642L3.5099 23.0299L8.89944 20.5502L14.6946 23.4382L14.7847 23.4831L14.8835 23.5022C14.9548 23.5159 15.0238 23.5216 15.0893 23.5216C15.2346 23.5216 15.3688 23.4925 15.486 23.4478L15.5494 23.4237L15.6074 23.3883L21.3646 19.8759C21.7545 19.6884 22.0014 19.2926 22.0014 18.8605V6.39501C22.0014 6.03739 21.8323 5.69875 21.5407 5.48525L21.5403 5.48494C21.2507 5.27325 20.8702 5.20862 20.5192 5.33052L20.4678 5.34837L20.4195 5.3736L18.1436 6.56436C17.9116 3.45352 15.2495 1 11.9996 1ZM2.41426 22.8546L2.87887 22.2856L2.41523 22.8554L2.41426 22.8546ZM2.80644 7.65531C2.82849 7.64887 2.85085 7.64307 2.87352 7.63794L3.0359 8.35474L2.76823 7.67025L2.80644 7.65531ZM15.8232 13.0542C17.0911 11.4512 17.843 10.0035 18.079 8.25712L20.5314 6.97403V18.6622L15.8232 21.5347V13.0542ZM10.9915 6.97464C10.9915 6.46755 11.4219 6.01758 12.0016 6.01758C12.5813 6.01758 13.0118 6.46755 13.0118 6.97464C13.0118 7.48173 12.5813 7.9317 12.0016 7.9317C11.4219 7.9317 10.9915 7.48173 10.9915 6.97464ZM12.0016 4.51758C10.6371 4.51758 9.4915 5.59617 9.4915 6.97464C9.4915 8.35311 10.6371 9.4317 12.0016 9.4317C13.3661 9.4317 14.5118 8.35311 14.5118 6.97464C14.5118 5.59617 13.3661 4.51758 12.0016 4.51758Z"
      fill={colorStroke}
    />
  </svg>
);
