import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormState } from 'react-final-form';

import Drawer from 'components/Drawer';
import CheckBox from 'components/common/CheckBox';
import { ArrowRight } from 'public/assets/icons';
import { IPermissionDrawer } from './IPermissionDrawer';

import {
  BlockHeading,
  BlockText,
  SeparateSettingsHeader,
  PermissionContentWrapper,
  PermissionContentBlock,
  PermissionContentBlockLeft,
  PermissionContentBlockRight,
  PermissionWrapper,
  PermissionWrapperLeft,
  PermissionBlockRight,
  PermissionBlockText,
  PermissionBlock,
  PermissionBlockLeft,
} from './PermissionDrawer.styles';

const PermissionDrawer = ({
  headerText = 'Post',
  meta,
  input,
}: IPermissionDrawer) => {
  const [isPermissionDrawerOpen, SetIsPermissionDrawerOpen] =
    useState<boolean>(false);
  const [checked, setChecked] = useState<number>();

  const { t } = useTranslation();
  const { values } = useFormState();

  const { onChange, value } = input;

  const { valid, invalid, touched, error, submitError } = meta;

  const errorText = error || submitError;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = !!(touched && invalid && (error || submitError));

  const permissionData = [
    {
      id: 1,
      title: t('postPermissionOptions:open'),
      text: t('postPermissionOptions:openDescription'),
      permission: 1,
    },
    {
      id: 2,
      title: t('postPermissionOptions:membersPlus'),
      text: t('postPermissionOptions:membersPlusDescription'),
      permission: 2,
    },
    {
      id: 3,
      title: t('postPermissionOptions:admin'),
      text: t('postPermissionOptions:adminDescription'),
      permission: 3,
    },
  ];

  const getPermissionContent = useMemo(
    () => permissionData?.find((d) => d?.permission === value),
    [value]
  );

  return (
    <>
      <PermissionWrapper onClick={() => SetIsPermissionDrawerOpen(true)}>
        <PermissionWrapperLeft>
          <SeparateSettingsHeader type="h3" mb={8}>
            {`${headerText} permissions`}
          </SeparateSettingsHeader>
          <PermissionBlockRight>
            <ArrowRight />
          </PermissionBlockRight>
        </PermissionWrapperLeft>
        {getPermissionContent?.title && (
          <PermissionBlock>
            <PermissionBlockLeft>
              <SeparateSettingsHeader type="h4" fs={15} fw={500}>
                {getPermissionContent?.title}
              </SeparateSettingsHeader>
              <PermissionBlockText>
                {getPermissionContent?.text}
              </PermissionBlockText>
            </PermissionBlockLeft>
          </PermissionBlock>
        )}
      </PermissionWrapper>
      <Drawer
        open={isPermissionDrawerOpen}
        headerText={`${headerText} permissions`}
        onIconClick={() => SetIsPermissionDrawerOpen(false)}
        onDrawerClose={() => SetIsPermissionDrawerOpen(false)}
        submitText={t('editUserProfile:save')}
        onSubmit={() => SetIsPermissionDrawerOpen(false)}
      >
        <PermissionContentWrapper checked={checked === 3}>
          {permissionData?.map((d) => (
            <PermissionContentBlock
              key={d?.id}
              onClick={() => onChange(d?.permission)}
              checked={d?.permission === value}
            >
              <PermissionContentBlockRight>
                <CheckBox
                  type="radio"
                  name="privacy"
                  checked={d?.permission === value}
                >
                  <PermissionContentBlockLeft>
                    <BlockHeading type="h4" fs={14}>
                      {d?.title}
                    </BlockHeading>
                    <BlockText>{d?.text}</BlockText>
                  </PermissionContentBlockLeft>
                </CheckBox>
              </PermissionContentBlockRight>
            </PermissionContentBlock>
          ))}
        </PermissionContentWrapper>
      </Drawer>
    </>
  );
};

export default PermissionDrawer;
