/* eslint-disable no-template-curly-in-string */
import types, { methods, versions, variant } from './endpointType';

export default {
  [types.AUTH_LOGIN]: ({ params, body }: any) => ({
    api: `/auth/${params?.nameOrEmail || ':nameOrEmail'}`,
    version: versions.v11,
    method: methods.POST,
    authentication: false,
    body: JSON.stringify({
      password: body?.password || 'password',
    }),
  }),
  [types.AUTH_SIGNUP]: ({ params, body }: any) => ({
    api: `/auth/${params?.email || ':email'}/new`,
    version: versions.v11,
    method: methods.POST,
    authentication: false,
    body: JSON.stringify({
      password: body?.password || 'password',
      referrerData: body?.referrerData || undefined,
    }),
  }),
  [types.AUTH_RESET_PASSWORD]: ({ params }: any) => ({
    api: `/auth/${params?.email || ':email'}/reset`,
    version: versions.v11,
    method: methods.POST,
    authentication: false,
    body: null,
  }),
  [types.AUTH_LOGIN_FACEBOOK]: ({ body }: any) => ({
    api: `/auth/facebook`,
    version: versions.v11,
    method: methods.POST,
    authentication: false,
    body: JSON.stringify({
      accessToken: body?.accessToken || 'accessToken',
    }),
  }),
  [types.AUTH_LOGIN_APPLE]: ({ body }: any) => ({
    api: `/auth/apple`,
    version: versions.v11,
    method: methods.POST,
    authentication: false,
    body: JSON.stringify({
      accessToken: body?.accessToken || 'accessToken',
    }),
  }),
  [types.AUTH_RSTORE_PASSWORD]: ({ body }: any) => ({
    api: `/auth/reset`,
    version: versions.v11,
    method: methods.POST,
    authentication: false,
    body: JSON.stringify({
      token: body?.token || 'token',
      password: body?.password || 'password',
    }),
  }),
  [types.TUTORIAL_CREATE_USERNAME]: ({ params, body }: any) => ({
    api: `/user/${params?.userId || ':userId'}/username/${
      body?.username || ':username'
    }`,
    version: versions.v01,
    method: methods.PUT,
    authentication: true,
    body: JSON.stringify({
      username: body?.username || 'username',
    }),
  }),
  [types.TUTORIAL_ADD_CAR_BRANDS]: ({ params, body }: any) => ({
    api: `/user/${params?.userId || ':userId'}/brand`,
    version: versions.v01,
    method: methods.POST,
    authentication: true,
    body: JSON.stringify({
      brands: body?.brands || 'brands',
    }),
  }),
  [types.GET_CARS_BRAND]: () => ({
    api: `/cars/brand`,
    version: versions.v01,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.TUTORIAL_GET_SUGGESTION]: ({ params }: any) => ({
    api: `/suggestion?start=${
      params?.start === 0 ? 0 : params?.start || ':start'
    }&limit=${params?.limit || ':limit'}`,
    version: versions.v10,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.TUTORIAL_GET_USER_INTEREST]: () => ({
    api: `/user/interest`,
    version: versions.v01,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.TUTORIAL_POST_USER_INTEREST]: ({ body }: any) => ({
    api: `/user/interest`,
    version: versions.v12,
    method: methods.POST,
    authentication: true,
    body: JSON.stringify({
      interests: body?.interests || 'interests[]',
    }),
  }),
  [types.TUTORIAL_COMPLETE]: ({ params }: any) => ({
    api: `/user/${params?.userId || ':userId'}/tutorial`,
    version: versions.v01,
    method: methods.PUT,
    authentication: true,
    body: null,
  }),
  [types.USER_FOLLOW_USER]: ({ params }: any) => ({
    api: `/user/${params?.userId || ':userId'}/follow/${
      params?.personID || ':personID'
    }`,
    version: versions.v01,
    method: methods.PUT,
    authentication: true,
    body: null,
  }),
  [types.USER_UNFOLLOW_USER]: ({ params }: any) => ({
    api: `/user/${params?.userId || ':userId'}/follow/${
      params?.personID || ':personID'
    }`,
    version: versions.v01,
    method: methods.DELETE,
    authentication: true,
    body: null,
  }),
  [types.USER_FOLLOW_GROUP]: ({ params }: any) => ({
    api: `/group/${params?.groupId || ':groupId'}/follow`,
    version: versions.v01,
    method: methods.PUT,
    authentication: true,
    body: null,
  }),
  [types.USER_UNFOLLOW_GROUP]: ({ params }: any) => ({
    api: `/group/${params?.groupId || ':groupId'}/follow`,
    version: versions.v01,
    method: methods.DELETE,
    authentication: true,
    body: null,
  }),
  [types.USER_IDENTITY]: ({ params }: any) => ({
    api: `/user/${params?.userId || ':userId'}`,
    version: versions.v10,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.DONATE_EVENT]: ({ params }: any) => ({
    api: `/event/?interestId=${params?.interestId || ':interestId'}`,
    version: versions.v12,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.DONATE_INFO]: ({ params }: any) => ({
    api: `/donate/info?interestId=${params?.interestId || ':interestId'}`,
    version: versions.v12,
    method: methods.GET,
    authentication: true,
    body: null,
  }),

  // FEED
  [types.FEED_GET_NEARBY_DRIVERS]: ({ params }: any) => ({
    api: `/user/${params?.userId || ':userId'}/home/drivers?lat=${
      params?.lat || ':lat'
    }&lng=${params?.lng || ':lng'}`,
    version: versions.v10,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.FEED_GET_FOLLOWING_POSTS]: ({ params }: any) => ({
    api: `/profile/feed?skipIds=${params?.filterIds || ''}&start=${
      params?.start
    }&limit=${params?.limit}`,
    version: versions.v12,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.FEED_GET_DISCOVER_POSTS]: ({ params }: any) => ({
    api: `/profile/feed-discover?skipIds=${params?.filterIds}&start=${params?.start}&limit=${params?.limit}`,
    version: versions.v12,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.FEED_GET_EVENT_POSTS]: ({ params }: any) => ({
    api: `/event/panel?${params?.lat ? `lat=${params?.lat}` : ''}${
      params?.lng ? `&lng=${params?.lng}` : ''
    }&start=${params?.start}&limit=${params?.limit}`,
    version: versions.v12,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.FEED_GET_BUSINESS_POSTS]: ({ params }: any) => ({
    api: `/business?offset=${params?.offset}&limit=${
      params?.limit || ':limit'
    }&businessesLimit=${params?.limit || ':limit'}`,
    version: versions.v01,
    variant: variant.business,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.FEED_GET_SHOP_POSTS]: ({ params }: any) => ({
    api: `/shop?offset=${params?.offset}&limit=${params?.limit}&productsLimit=${params?.limit}`,
    version: versions.v01,
    variant: variant.business,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.FEED_GET_FOLLOWING_CHECK_NEARBY]: () => ({
    api: '/shop/check-nearby',
    version: versions.v01,
    variant: variant.business,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.FEED_DELETE_POST]: ({ params }: any) => ({
    api: `/media/${params?.postId || ':postId'}`,
    version: versions.v01,
    method: methods.DELETE,
    authentication: true,
    body: null,
  }),

  [types.POSTS_SENSOR]: ({ params }: any) => ({
    api: `/analytics/posts/${params?.id || ':postId'}`,
    version: versions.v11,
    method: methods.POST,
    authentication: true,
    body: null,
  }),
  [types.EVENT_ATTENDS_WITH_VEHICLE]: ({ params, body }: any) => ({
    api: `/event/${params?.eventId || ':eventId'}/attend/${
      params?.vehicleId || ':vehicleId'
    }`,
    version: versions.v01,
    method: methods.PUT,
    authentication: true,
    ...(body?.code
      ? {
          body: JSON.stringify({
            code: body?.code,
          }),
        }
      : {
          body: null,
        }),
  }),
  [types.EVENT_ATTENDS_WITHOUT_VEHICLE]: ({ params, body }: any) => ({
    api: `/event/${params?.eventId || ':eventId'}/attend`,
    version: versions.v01,
    method: methods.PUT,
    authentication: true,
    ...(body?.code
      ? {
          body: JSON.stringify({
            code: body?.code,
          }),
        }
      : {
          body: null,
        }),
  }),
  [types.EVENT_CREATE]: ({ body }: any) => ({
    api: `/event`,
    version: versions.v01,
    method: methods.POST,
    authentication: true,
    body: JSON.stringify({
      ...body,
    }),
  }),
  [types.EVENT_EDIT]: ({ body, params }: any) => ({
    api: `/event/${params?.eventId || ':eventId'}`,
    version: versions.v01,
    method: methods.PATCH,
    authentication: true,
    body: JSON.stringify({
      ...body,
    }),
  }),
  [types.EVENT_GET_ADDENDED_VEHICLES]: ({ params }: any) => ({
    api: `/event/${params?.eventId || ':eventId'}/vehicles?offset=${
      params?.offset || 0
    }&limit=${params?.limit || ':limit'}`,
    version: versions.v12,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.CREATE_PHOTO_POST]: ({ body }: any) => ({
    api: '/media',
    version: versions.v10,
    method: methods.POST,
    authentication: true,
    body: JSON.stringify({
      ...body,
    }),
  }),
  [types.UPDATE_PHOTO_POST]: ({ body, params }: any) => ({
    api: `/media/${params?.postId || ':postId'}`,
    version: versions.v01,
    method: methods.PATCH,
    authentication: true,
    body: JSON.stringify({
      ...body,
    }),
  }),
  [types.PROFILE_GET_POSTS]: ({ params }: any) => ({
    api: `/user/${params?.userId || ':userId'}/posts?offset=${
      params?.offset || 0
    }&limit=${params?.limit || ':limit'}`,
    version: versions.v10,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.CREATE_LINK_POST]: ({ body }: any) => ({
    api: '/media',
    version: versions.v01,
    method: methods.POST,
    authentication: true,
    body: JSON.stringify({
      ...body,
    }),
  }),
  [types.EDIT_LINK_POST]: ({ params, body }: any) => ({
    api: `/media/${params?.id || ':id'}`,
    version: versions.v01,
    method: methods.PATCH,
    authentication: true,
    body: JSON.stringify({
      ...body,
    }),
  }),
  [types.LIKE_POSTS]: ({ params }: any) => ({
    api: `/media/${params?.postId || ':postId'}/like`,
    version: versions.v01,
    method: methods.PUT,
    authentication: true,
    body: null,
  }),
  [types.UNLIKE_POSTS]: ({ params }: any) => ({
    api: `/media/${params?.postId || ':postId'}/like`,
    version: versions.v01,
    method: methods.DELETE,
    authentication: true,
    body: null,
  }),

  // VEHICLES
  [types.LIKE_VEHICLE]: ({ params }: any) => ({
    api: `/cars/detail/${params?.vehicleId || ':vehicleId'}/like`,
    version: versions.v01,
    method: methods.PUT,
    authentication: true,
    body: null,
  }),
  [types.UNLIKE_VEHICLE]: ({ params }: any) => ({
    api: `/cars/detail/${params?.vehicleId || ':vehicleId'}/like`,
    version: versions.v01,
    method: methods.DELETE,
    authentication: true,
    body: null,
  }),
  [types.DELETE_VEHICLES]: ({ params }: any) => ({
    api: `/cars/detail/${params?.vehicleId || ':vehicleId'}`,
    version: versions.v01,
    method: methods.DELETE,
    authentication: true,
    body: null,
  }),
  [types.SIDEBAR_GET_MY_EVENTS]: ({ params }: any) => ({
    api: `/profile/events?offset=${params?.offset || 0}&limit=${
      params?.limit || ':limit'
    }`,
    version: versions.v12,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.BUSINESS_SELLER_CONTACT]: ({ params, body }: any) => ({
    api: `/business/services/${params?.serviceId || ':serviceId'}/form-lead`,
    version: versions.v01,
    method: methods.POST,
    variant: variant.business,
    authentication: true,
    body: JSON.stringify({
      formLead: { ...body },
    }),
  }),
  [types.SIDEBAR_GET_MY_GROUPS]: ({ params }: any) => ({
    api: `/profile/groups?offset=${params?.offset || 0}&limit=${
      params?.limit || ':limit'
    }`,
    version: versions.v12,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.SIDEBAR_GET_POPULAR_POSTS]: () => ({
    api: `/search/posts/popular`,
    version: versions.v05,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.PROFILE_USER_FOLLOW]: ({ params }: any) => ({
    api: `/user/${params?.myId || ':myId'}/follow/${
      params?.userId || ':userId'
    }`,
    version: versions.v01,
    method: methods.PUT,
    authentication: true,
    body: null,
  }),
  [types.PROFILE_USER_UNFOLLOW]: ({ params }: any) => ({
    api: `/user/${params?.myId || ':myId'}/follow/${
      params?.userId || ':userId'
    }`,
    version: versions.v01,
    method: methods.DELETE,
    authentication: true,
    body: null,
  }),
  [types.CAR_GET_CAR_BRANDS]: ({ params }: any) => ({
    api: `/cars/brand`,
    version: versions.v01,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.GET_CAR_MODELS]: ({ params }: any) => ({
    api: `/cars/${params?.id || ':id'}/models`,
    version: versions.v01,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.VEHICLE_CREATE_POST]: ({ body }: any) => ({
    api: `/vehicle`,
    version: versions.v12,
    method: methods.POST,
    authentication: true,
    body: JSON.stringify({
      ...body,
    }),
  }),
  [types.VEHICLE_EDIT_POST]: ({ params, body }: any) => ({
    api: `/vehicle/${params?.id || ':id'}`,
    version: versions.v12,
    method: methods.PATCH,
    authentication: true,
    body: JSON.stringify({
      ...body,
    }),
  }),
  [types.GET_CAR_TYPES]: () => ({
    api: `/cars/types`,
    version: versions.v01,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.GET_USER_INTERESTS]: () => ({
    api: `/user/interest`,
    version: versions.v01,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.GET_USER_COLLABORATORS]: ({ params }: any) => ({
    api: `/user/${params?.id || ':id'}/followers?offset=${
      params?.offset || 0
    }&limit=${params?.limit || ':limit'}&search=`,
    version: versions.v12,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.USER_UPDATE]: ({ params, body }: any) => ({
    api: `/user/${params?.id || ':id'}`,
    version: versions.v01,
    method: methods.PATCH,
    authentication: true,
    body: JSON.stringify({
      ...body,
    }),
  }),
  [types.PROFILE_GET_GROUPS]: ({ params }: any) => ({
    api: `/profile/groups?offset=${params?.offset || 0}&limit=${
      params?.limit || ':limit'
    }&search=`,
    version: versions.v12,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.PROFILE_GET_USER_EVENTS]: ({ params }: any) => ({
    api: `/user/${params?.id || ':id'}/events`,
    version: versions.v01,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.GET_VEHICLES]: ({ params }: any) => ({
    api: `/user/${params?.id || ':id'}/garage/vehicles`,
    version: versions.v01,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.SEARCH_GROUP]: ({ params, body }: any) => ({
    api: `/search/groups?offset=${
      params?.offset === 0 ? 0 : params?.offset || ':start'
    }&limit=${params?.limit || ':limit'}${
      params?.filter ? params?.filter : ''
    }`,
    version: versions.v12,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.SEARCH_PROFILE]: ({ params, body }: any) => ({
    api: `/search/users?offset=${
      params?.offset === 0 ? 0 : params?.offset || ':start'
    }&limit=${params?.limit || ':limit'}${
      params?.filter ? params?.filter : ''
    }`,
    version: versions.v12,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.SEARCH_POSTS]: ({ params, body }: any) => ({
    api: `/search/posts?start=${
      params?.start === 0 ? 0 : params?.start || ':start'
    }&limit=${params?.limit || ':limit'}${
      params?.filter ? params?.filter : ''
    }${params?.postType ? `&postType=${params?.postType}` : ''}`,
    version: versions.v05,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.SEARCH_VEHICLES]: ({ params, body }: any) => ({
    api: `/search/vehicles?start=${
      params?.start === 0 ? 0 : params?.start || ':start'
    }&limit=${params?.limit || ':limit'}${
      params?.filter ? params?.filter : ''
    }`,
    version: versions.v05,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.SEARCH_EVENTS]: ({ params, body }: any) => ({
    api: `/search/events?start=${
      params?.start === 0 ? 0 : params?.start || ':start'
    }&limit=${params?.limit || ':limit'}${
      params?.filter ? params?.filter : ''
    }`,
    version: versions.v05,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.USER_GET_GARAGE]: ({ params }: any) => ({
    api: `/user/${params?.userId || ':userId'}/vehicles?offset=${
      params?.offset === 0 ? 0 : params?.offset || ':start'
    }&limit=${params?.limit || ':limit'}${
      params?.filter ? params?.filter : ''
    }`,
    version: versions.v12,
    method: methods.GET,
    authentication: true,
    body: null,
  }),

  [types.GET_GROUP_POSTS]: ({ params }: any) => ({
    api: `/group/${params?.id || ':id'}/posts?limit=${
      params?.limit || 0
    }&start=${params?.start || ':start'}`,
    version: versions.v10,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.GROUP_ATTEND_VEHICLE]: ({ params, body }: any) => ({
    api: `/group/${params?.groupId || ':groupId'}/garage/vehicle`,
    version: versions.v01,
    method: methods.POST,
    authentication: true,
    body: JSON.stringify({
      ...body,
    }),
  }),
  [types.GET_GROUP_VEHICLES]: ({ params }: any) => ({
    api: `/group/${params?.id || ':id'}/vehicles?offset=${
      params?.offset || 0
    }&limit=${params?.limit || 20}`,
    version: versions.v12,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.GET_SAVED_ROUTES]: ({ params }: any) => ({
    api: `/public/routes?offset=${params?.offset || 0}&limit=${
      params?.limit || 0
    }`,
    version: versions.v12,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.ROUTES_ADD_FAVORITES]: ({ params }: any) => ({
    api: `/roadsharing/routes/${params?.routesId || `:routesId`}/favorites`,
    version: versions.v11,
    variant: variant?.roadsharing,
    method: methods.POST,
    authentication: true,
    body: null,
  }),
  [types.ROUTES_DELETE_FAVORITES]: ({ params }: any) => ({
    api: `/roadsharing/routes/${params?.routesId || `:routesId`}/favorites`,
    version: versions.v11,
    variant: variant?.roadsharing,
    method: methods.DELETE,
    authentication: true,
    body: null,
  }),
  [types.ROUTES_PATCH_PRIVACY]: ({ params, body }: any) => ({
    api: `/roadsharing/routes/my/${params?.routesId || `:routesId`}`,
    version: versions.v11,
    variant: variant?.roadsharing,
    method: methods.PATCH,
    authentication: true,
    body: JSON.stringify({
      isPublic: body?.isPublic || false,
    }),
  }),
  [types.GET_GROUP_ADMINS]: ({ params }: any) => ({
    api: `/group/${params.groupId || ':groupId'}/admins`,
    version: versions.v12,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.CREATE_GROUP]: ({ body }: any) => ({
    api: `/group`,
    version: versions.v01,
    method: methods.POST,
    authentication: true,
    body: JSON.stringify({
      ...body,
    }),
  }),
  [types.EDIT_GROUP]: ({ params, body }: any) => ({
    api: `/group/${params.groupId || ':groupId'}`,
    version: versions.v01,
    method: methods.PATCH,
    authentication: true,
    body: JSON.stringify({
      ...body,
    }),
  }),
  [types.LANDING_GET_EVENTS]: ({ params }: any) => ({
    api: `/public/events?start=${params?.start || 0}&limit=${5}&calendar=${
      params?.calendar || ''
    }&range=${params?.range || 0}&lng=${params?.lng || 0}&lat=${
      params?.lat || ''
    }&country=${params?.country || ''}&region=${params?.region || ''}&place=${
      params?.place || ''
    }`,
    version: versions.v12,
    method: methods.GET,
    authentication: false,
    body: null,
  }),
  [types.LANDING_GET_ROUTES]: ({ params }: any) => ({
    api: `/public/routes?start=${params?.start || 0}&limit=${
      params?.limit || 10
    }&range=${params?.range || 0}&search=${
      params?.filter ? params?.filter : ''
    }&country=${params?.country || ''}&region=${params?.region || ''}&place=${
      params?.place || ''
    }`,
    version: versions.v12,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.LANDING_GET_GROUPS]: ({ params }: any) => ({
    api: `/public/groups?start=${params?.start || 0}&limit=${
      params?.limit || 10
    }&brands=${params?.filter ? params?.filter : ''}&range=${
      params?.range || 0
    }&lng=${params?.lng || 0}&lat=${params?.lat || ''}&country=${
      params?.country || ''
    }&region=${params?.region || ''}&place=${params?.place || ''}`,
    version: versions.v12,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.LANDING_GET_BRAND_POSTS]: ({ params }: any) => ({
    api: `/public/posts?interests=${params.interests || ''}&start=${
      params?.start || 0
    }&limit=${params?.limit || 10}`,
    version: versions.v12,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.LANDING_GET_BRAND_GROUPS]: ({ params }: any) => ({
    api: `/public/groups?start=${params.start || 0}&search=${
      params.search || ''
    }&brands=${params.brands || ''}&lat=${params.lat}&lng${params.lng}&range=${
      params.range || 0
    }&country=${params?.country || ''}&region=${params?.region || ''}&place=${
      params?.place || ''
    }`,
    version: versions.v12,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.LANDING_GET_BRAND_VEHICLES]: ({ params }: any) => ({
    api: `/public/vehicles?start=${params.start || 0}&make=${
      params.make
    }&limit=${params.limit || 0}`,
    version: versions.v12,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.GET_CAMPAIGN]: ({ params }: any) => ({
    api: `/campaign`,
    version: versions.v01,
    variant: variant.kitt,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.GET_CAMPAIGN_DETAIL]: ({ params }: any) => ({
    api: `/campaign/${params.id}`,
    version: versions.v01,
    variant: variant.kitt,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.GET_REWARD_CAMPAIGN]: ({ params }: any) => ({
    api: `/reward-campaign`,
    version: versions.v01,
    variant: variant.kitt,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
  [types.GET_REWARD_CAMPAIGN_DETAIL]: ({ params }: any) => ({
    api: `/reward-campaign/${params.id}`,
    version: versions.v01,
    variant: variant.kitt,
    method: methods.GET,
    authentication: true,
    body: null,
  }),
};
