import React from 'react';

const BackArrow = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.19497 3.19497C8.46834 2.92161 8.46834 2.47839 8.19497 2.20503C7.92161 1.93166 7.47839 1.93166 7.20503 2.20503L2.20503 7.20503C1.93166 7.47839 1.93166 7.92161 2.20503 8.19497L7.20503 13.195C7.47839 13.4683 7.92161 13.4683 8.19497 13.195C8.46834 12.9216 8.46834 12.4784 8.19497 12.205L4.38995 8.4H12.7C13.0866 8.4 13.4 8.0866 13.4 7.7C13.4 7.3134 13.0866 7 12.7 7H4.38995L8.19497 3.19497Z"
        fill="#1C1F29"
      />
    </svg>
  );
};

export default BackArrow;
