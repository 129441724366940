import { useMemo } from 'react';

const useImageStyle = (height: number, imageObject: any) => {
  const imageStyle = useMemo(() => {
    if (!imageObject?.dimensions?.width || !imageObject?.dimensions?.height) {
      return { width: 50, height: 50 };
    }
    const width =
      (imageObject.dimensions.width / imageObject.dimensions.height) * height;
    return {
      width,
      height,
    };
  }, [height, imageObject]);

  return imageStyle;
};

export default useImageStyle;
