import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  img?: string;
  color?: string;
  mr?: number;
  ml?: number;
  mt?: number;
  mb?: number;
  isCoverPhotoExists?: boolean;
  width?: number;
  lastCardInLine?: number;
  isClickable?: boolean;
}

export const CardWrap = styled.div<IProps>`
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  overflow: hidden;
  cursor: pointer;
  display: flex;
  flex-direction: column;
`;

export const BackgroundImage = styled.span<IProps>`
  display: block;
  width: 100%;
  height: 184px;
  background: url(${({ img }) => img}) no-repeat;
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
`;

export const UserDataWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

export const EventNameText = styled.p<IProps>`
  width: 100%;
  font-size: 15px;
  line-height: 130%;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  padding-bottom: 8px;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const EventDetailsWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 12px 16px 16px 16px;
  background-color: ${style.mainWhite.white};
  flex: 1;
`;

export const EventInfoWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const CircleWrap = styled.div`
  font-size: 12px;
  line-height: 130%;
  letter-spacing: 0.02em;
  margin: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1px;
`;

export const Circle = styled.div`
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: ${style.mainGray.silver};
`;

export const Text = styled.p<IProps>`
  display: inline-block;
  font-size: 13px;
  line-height: 100%;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};
  color: ${({ color }) => color || style.mainGray.SuvaGrey};
  margin-right: ${({ mr }) => (mr ? `${mr}px` : '0')};
  margin-left: ${({ ml }) => (ml ? `${ml}px` : '0')};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
`;
