export const actionTypes = {
  FAILURE_LOAD_IDENTITY: 'FAILURE_LOAD_IDENTITY',
  LOAD_IDENTITY: 'LOAD_IDENTITY',
  LOAD_IDENTITY_SUCCESS: 'LOAD_IDENTITY_SUCCESS',
  HYDRATE: 'HYDRATE',
};

export function failure(error) {
  return {
    type: actionTypes.FAILURE_LOAD_IDENTITY,
    error,
  };
}

export function loadIdentity(data?: any) {
  return { type: actionTypes.LOAD_IDENTITY, data };
}

export function loadIdentitySuccess(data) {
  return {
    type: actionTypes.LOAD_IDENTITY_SUCCESS,
    data,
  };
}
