import { useWindowSize } from 'hooks';
import { IImagePreview } from './IImagePreview';

import { ImagePreviewWrapper, UploadedImage } from './ImagePreview.styles';

const ImagePreview = ({ isPhotoPost, imageSrc }: IImagePreview) => {
  const size = useWindowSize();

  return (
    <ImagePreviewWrapper
      backgroundColor={isPhotoPost ? 'transparent' : '#000000'}
      wrapperHeight={
        isPhotoPost ? 'auto' : size?.width <= 640 ? `${size?.width}px` : '360px'
      }
      isPhotoPost={isPhotoPost}
      height={isPhotoPost ? 'auto' : size?.width <= 640 ? size?.width : 360}
    >
      <UploadedImage
        src={imageSrc}
        width={size.width <= 640 ? size.width : 360}
        objectFit="contain"
      />
    </ImagePreviewWrapper>
  );
};

export default ImagePreview;
