import React from 'react';

export const CupIcon = ({
  color = '#A9A9B5',
  width = '24',
  height = '24',
}: {
  color?: string;
  width?: string;
  height?: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 3H18V2H6V3H3C2.4 3 2 3.4 2 4V6.4C2 8.7 3.7 10.6 6 10.9V11C6 13.9 8 16.3 10.7 16.9L10 19H7.7C7.3 19 6.9 19.3 6.8 19.7L6 22H18L17.2 19.7C17.1 19.3 16.7 19 16.3 19H14L13.3 16.9C16 16.3 18 13.9 18 11V10.9C20.3 10.6 22 8.7 22 6.4V4C22 3.4 21.6 3 21 3ZM5.99916 8.89944C4.89916 8.59944 3.99916 7.59944 3.99916 6.39944V4.99944H5.99916V8.89944ZM18.0003 8.89944C19.1003 8.69944 20.0003 7.59944 20.0003 6.39944V4.99944H18.0003V8.89944Z"
      fill="#8D8D8D"
    />
  </svg>
);
