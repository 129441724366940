import styled from 'styled-components';

export const CreatePostQuickTagsWrapper = styled.div`
  padding: 20px 0 10px;
  border-bottom: 1px dashed ${(props) => props.theme.colors.neutral.n4};

  :last-of-type {
    border: none;
  }

  & h3 {
    font-size: ${(props) => props.theme.typography.fontSize.f7};
    line-height: 130%;
    font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
    color: ${(props) => props.theme.colors.black};
    margin-bottom: 13px;
  }
`;

export const QuickTagsWrap = styled.div`
  overflow: hidden;
  overflow-y: auto;
  max-height: 250px;
  display: flex;
  flex-wrap: wrap;

  ::-webkit-scrollbar-track {
    background: none;
  }
  ::-webkit-scrollbar {
    width: 4px;
    height: 100px;
    background: none;
  }
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.neutral.n6};
    border-radius: 10px;
  }
`;
