import React from 'react';

export const FavoriteIcon = ({ stroke = '#8D8D8D' }: { stroke?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 6.25129V21L12 17.1139L5 21V6.25129C5 4.45565 6.567 3 8.5 3H15.5C17.433 3 19 4.45565 19 6.25129Z"
      stroke={stroke}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
