import Head from 'next/head';

import { APP_CONFIG } from 'utils/constants';
import imageResize from 'utils/imageResize';

import { IMeta } from './Types';

const Meta = ({
  title,
  description,
  ogImage,
  ogTitle,
  ogUrl,
  ogType = 'article',
  isResize = true,
}: IMeta) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name="author" content={APP_CONFIG.APPNAME} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content={ogType} />
      <meta property="fb:app_id" content={process.env._FACEBOOK_APP_ID} />
      <meta property="og:site_name" content={`${APP_CONFIG.appname}`} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content={`@${APP_CONFIG.appname}_app`} />
      <meta name="twitter:title" content={title} />
      {description && (
        <>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <meta name="twitter:description" content={description} />
        </>
      )}

      <meta property="og:title" content={ogTitle || title} />

      {ogImage && (
        <meta
          property="og:image"
          content={isResize ? imageResize(ogImage, '_detail') : ogImage}
        />
      )}
      {ogUrl && (
        <>
          <meta
            property="og:url"
            content={`https://app.${APP_CONFIG.appname}.io${ogUrl}`}
          />
          <meta
            property="al:ios:url"
            content={`https://app.${APP_CONFIG.appname}.io${ogUrl}`}
          />
        </>
      )}
      <link rel="canonical" href={`https://${APP_CONFIG.appname}.io/app`} />
    </Head>
  );
};

export default Meta;
