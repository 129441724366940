import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getLocalStorage } from 'utils/hooks';
import { getIdentity } from 'services/identity';
import {
  actionTypes,
  failure,
  loadIdentity,
  loadIdentitySuccess,
} from './actions';

function* workerLoadIdentity(action) {
  try {
    let userId = action?.data;

    if (!userId) {
      userId = yield call(getLocalStorage, 'user_id');
      userId = JSON.parse(userId);
    }
    if (userId) {
      const res = yield call(getIdentity, userId);
      yield localStorage.setItem('user_data', JSON.stringify(res));
      yield put(loadIdentitySuccess(res));
    }
  } catch (err) {
    yield put(failure(err));
  }
}

function* watchLoadIdentity() {
  yield all([takeLatest(actionTypes.LOAD_IDENTITY, workerLoadIdentity)]);
}

export default watchLoadIdentity;
