import styled from 'styled-components';

import Card from 'components/common/Card';

export const BusinessMainCardSkeletonWrapper = styled(Card)`
  height: auto;
  width: 100%;
  max-width: 550px;
  margin-bottom: 16px;
  overflow: hidden;
  position: relative;
  @media (max-width: 861px) {
    display: block;
    margin: 0 auto 16px;
  }
`;

export const BusinessMainCardSkeletonContent = styled.div`
  padding: 16px 16px 0 16px;
  display: flex;
  width: 100%;
`;

export const BusinessMainCardSkeletonContentLeft = styled.div`
  position: absolute;
  bottom: 44px;
`;
export const BusinessMainCardSkeletonContentRight = styled.div`
  margin-left: 94px;
  width: 100%;
`;

export const BusinessMainCardSkeletonFooter = styled.div`
  padding: 16px;
  display: flex;
  & div {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;
