export const actionTypes = {
  GET_INTERESTS_REQUEST: 'GET_INTERESTS_REQUEST',
  GET_INTERESTS_SUCCESS: 'GET_INTERESTS_SUCCESS',
  GET_INTERESTS_ERROR: 'GET_INTERESTS_ERROR',
  GET_EVENTS_REQUEST: 'GET_EVENTS_REQUEST',
  GET_EVENTS_SUCCESS: 'GET_EVENTS_SUCCESS',
  GET_EVENTS_ERROR: 'GET_EVENTS_ERROR',
  GET_MY_ROUTES_START:'GET_MY_ROUTES_START',
  GET_MY_ROUTES_SUCCESS:'GET_MY_ROUTES_SUCCESS',
  GET_MY_ROUTES_ERROR:'GET_MY_ROUTES_ERROR',
  GET_MY_EVENTS_START: 'GET_MY_EVENTS_START',
  GET_MY_EVENTS_SUCCESS: 'GET_MY_EVENTS_SUCCESS',
  GET_MY_EVENTS_ERROR: 'GET_MY_EVENTS_ERROR',
  CLEAR_MY_EVENTS: 'CLEAR_MY_EVENTS',
  GET_FOLLOWERS_REQUEST: 'GET_FOLLOWERS_REQUEST',
  GET_FOLLOWERS_SUCCESS: 'GET_FOLLOWERS_SUCCESS',
  GET_FOLLOWERS_ERROR: 'GET_FOLLOWERS_ERROR',
  CLEAR_FOLLOWERS_DATA: 'CLEAR_FOLLOWERS_DATA',
  GET_GROUPS_REQUEST: 'GET_GROUPS_REQUEST',
  GET_GROUPS_SUCCESS: 'GET_GROUPS_SUCCESS',
  GET_GROUPS_ERROR: 'GET_GROUPS_ERROR',
  ADD_INTEREST_START: 'ADD_INTEREST_START',
  ADD_INTEREST_SUCCESS: 'ADD_INTEREST_SUCCESS',
  ADD_INTEREST_ERROR: 'ADD_INTEREST_ERROR',
  EDIT_PROFILE_DATA_START: 'EDIT_PROFILE_DATA_START',
  EDIT_PROFILE_DATA_SUCCESS: 'EDIT_PROFILE_DATA_SUCCESS',
  EDIT_PROFILE_DATA_ERROR: 'EDIT_PROFILE_DATA_ERROR',
  CLEAR_REQUESTS_ASYNC_STATUS: 'CLEAR_REQUESTS_ASYNC_STATUS',
  HANDLE_REDIRECT_FROM_MY_EVENTS: 'HANDLE_REDIRECT_FROM_MY_EVENTS',
  HANDLE_REDIRECT_FROM_MY_GROUPS: 'HANDLE_REDIRECT_FROM_MY_GROUPS',
  ADD_USERNAME_START: 'ADD_USERNAME_START',
  ADD_USERNAME_SUCCESS: 'ADD_USERNAME_SUCCESS',
  ADD_USERNAME_ERROR: 'ADD_USERNAME_ERROR',
  CLEAR_ADD_USERNAME_REQ_STATUS: 'CLEAR_ADD_USERNAME_REQ_STATUS',
  FINISH_TUTORIAL_START: 'FINISH_TUTORIAL_START',
  FINISH_TUTORIAL_SUCCESS: 'FINISH_TUTORIAL_SUCCESS',
  FINISH_TUTORIAL_ERROR: 'FINISH_TUTORIAL_ERROR',
  GET_SUGGESTION_START: 'GET_SUGGESTION_START',
  GET_SUGGESTION_SUCCESS: 'GET_SUGGESTION_SUCCESS',
  GET_SUGGESTION_ERROR: 'GET_SUGGESTION_ERROR',
  REPORT_USER_START: 'REPORT_USER_START',
  REPORT_USER_SUCCESS: 'REPORT_USER_SUCCESS',
  REPORT_USER_ERROR: 'REPORT_USER_ERROR',
  ADD_GROUP_START: 'ADD_GROUP_START',
  ADD_GROUP_SUCCESS: 'ADD_GROUP_SUCCESS',
  ADD_GROUP_ERROR: 'ADD_GROUP_ERROR',
  CLEAR_CREATED_GROUP_STATUS: 'CLEAR_CREATED_GROUP_STATUS',
  GET_USER_DETAILS_START: 'GET_USER_DETAILS_START',
  GET_USER_DETAILS_SUCCESS: 'GET_USER_DETAILS_SUCCESS',
  GET_USER_DETAILS_ERROR: 'GET_USER_DETAILS_ERROR',
  GET_USER_POSTS_START: 'GET_USER_POSTS_START',
  GET_USER_POSTS_SUCCESS: 'GET_USER_POSTS_SUCCESS',
  GET_USER_POSTS_ERROR: 'GET_USER_POSTS_ERROR',
  GET_SIDE_MY_EVENTS_START: 'GET_SIDE_MY_EVENTS_START',
  GET_SIDE_MY_EVENTS_SUCCESS: 'GET_SIDE_MY_EVENTS_SUCCESS',
  GET_SIDE_MY_EVENTS_ERROR: 'GET_SIDE_MY_EVENTS_ERROR',
  GET_SIDE_GROUPS_START: 'GET_SIDE_GROUPS_START',
  GET_SIDE_GROUPS_SUCCESS: 'GET_SIDE_GROUPS_SUCCESS',
  GET_SIDE_GROUPS_ERROR: 'GET_SIDE_GROUPS_ERROR',
  CLEAR_SUGGESTIONS: 'CLEAR_SUGGESTIONS',
  GET_USER_FOLLOWERS_START: 'GET_USER_FOLLOWERS_START',
  GET_USER_FOLLOWERS_SUCCESS: 'GET_USER_FOLLOWERS_SUCCESS',
  GET_USER_FOLLOWERS_ERROR: 'GET_USER_FOLLOWERS_ERROR',
  GET_USER_FOLLOWING_START: 'GET_USER_FOLLOWING_START',
  GET_USER_FOLLOWING_SUCCESS: 'GET_USER_FOLLOWING_SUCCESS',
  GET_USER_FOLLOWING_ERROR: 'GET_USER_FOLLOWING_ERROR',
  REMOVE_USER_FROM_MY_FOLLOWERS_START: 'REMOVE_USER_FROM_MY_FOLLOWERS_START',
  REMOVE_USER_FROM_MY_FOLLOWERS_SUCCESS:
    'REMOVE_USER_FROM_MY_FOLLOWERS_SUCCESS',
  REMOVE_USER_FROM_MY_FOLLOWERS_ERROR: 'REMOVE_USER_FROM_MY_FOLLOWERS_ERROR',
  GET_INVOLVED_USERS: 'GET_INVOLVED_USERS',
  CLEAR_INVOLVED_USERS_STORE: 'CLEAR_INVOLVED_USERS_STORE',
  GET_USER_GARAGE_VEHICLES_START: 'GET_USER_GARAGE_VEHICLES_START',
  GET_USER_GARAGE_VEHICLES_SUCCESS: 'GET_USER_GARAGE_VEHICLES_SUCCESS',
  GET_USER_GARAGE_VEHICLES_ERROR: 'GET_USER_GARAGE_VEHICLES_ERROR',
  SET_REDIRECT_FROM_USER_DETAILS: 'SET_REDIRECT_FROM_USER_DETAILS',
  ADD_LIKE_TO_USER_DETAILS_POST: 'ADD_LIKE_TO_USER_DETAILS_POST',
  REMOVE_LIKE_FROM_USER_DETAILS_POST: 'REMOVE_LIKE_FROM_USER_DETAILS_POST',
  SET_POSTS_ON_USER_DETAILS: 'SET_POSTS_ON_USER_DETAILS',
};

// GET INTERESTS ACTIONS
export const getInterestsStart = () => ({
  type: actionTypes.GET_INTERESTS_REQUEST,
});

export const getInterestsSuccess = (data) => ({
  type: actionTypes.GET_INTERESTS_SUCCESS,
  data,
});

export const getInterestsError = () => ({
  type: actionTypes.GET_INTERESTS_ERROR,
});

// GET EVENTS ACTIONS
export const getEventsStart = () => ({
  type: actionTypes.GET_EVENTS_REQUEST,
});

export const getEventsSuccess = (data) => ({
  type: actionTypes.GET_EVENTS_SUCCESS,
  data,
});

export const getEventsError = () => ({
  type: actionTypes.GET_EVENTS_ERROR,
});

// GET MY ROUTES ACTIONS
export const getMyRoutesStart = (data) => ({
  type: actionTypes.GET_MY_ROUTES_START,
  data
});

export const getMyRoutesSuccess = (data) => ({
  type: actionTypes.GET_MY_ROUTES_SUCCESS,
  data,
});

export const getMyRoutesError = (error) => ({
  type: actionTypes.GET_MY_ROUTES_ERROR,
  error,
});

// GET MY EVENTS ACTIONS
export const getMyEventsStart = (data) => ({
  type: actionTypes.GET_MY_EVENTS_START,
  data,
});

export const getMyEventsSuccess = (data) => ({
  type: actionTypes.GET_MY_EVENTS_SUCCESS,
  data,
});

export const getMyEventsError = (error) => ({
  type: actionTypes.GET_MY_EVENTS_ERROR,
  error,
});

export const clearMyEvents = () => ({
  type: actionTypes.CLEAR_MY_EVENTS,
});

// GET FOLLOWERS ACTIONS
export const getFollowersStart = (data) => ({
  type: actionTypes.GET_FOLLOWERS_REQUEST,
  data,
});

export const getFollowersSuccess = (data) => ({
  type: actionTypes.GET_FOLLOWERS_SUCCESS,
  data,
});

export const getFollowersError = () => ({
  type: actionTypes.GET_FOLLOWERS_ERROR,
});

export const clearFollowersData = () => ({
  type: actionTypes.CLEAR_FOLLOWERS_DATA,
});

// GET GROUPS ACTIONS
export const getGroupsStart = (data) => ({
  type: actionTypes.GET_GROUPS_REQUEST,
  data,
});

export const getGroupsSuccess = (data) => ({
  type: actionTypes.GET_GROUPS_SUCCESS,
  data,
});

export const getGroupsError = () => ({
  type: actionTypes.GET_GROUPS_ERROR,
});

// ADD INTEREST ACTIONS
export const addInterestStart = (data) => ({
  type: actionTypes.ADD_INTEREST_START,
  data,
});

export const addInterestSuccess = () => ({
  type: actionTypes.ADD_INTEREST_SUCCESS,
});

export const addInterestError = (error) => ({
  type: actionTypes.ADD_INTEREST_ERROR,
  error,
});

// EDIT PROFILE DATA ACTIONS
export const editProfileDataStart = (data) => ({
  type: actionTypes.EDIT_PROFILE_DATA_START,
  data,
});

export const editProfileDataSuccess = () => ({
  type: actionTypes.EDIT_PROFILE_DATA_SUCCESS,
});

export const editProfileDataError = (error) => ({
  type: actionTypes.EDIT_PROFILE_DATA_ERROR,
  error,
});

// CLEAR REQUESTS ASYNC STATUS ACTIONS
export const clearRequestsAsyncStatus = () => ({
  type: actionTypes.CLEAR_REQUESTS_ASYNC_STATUS,
});

// HANDLE REDIRECT FROM MY EVENTS ACTIONS
export const handleRedirectFromMyEvents = (data) => ({
  type: actionTypes.HANDLE_REDIRECT_FROM_MY_EVENTS,
  data,
});

// HANDLE REDIRECT FROM MY GROUPS
export const handleRedirectFromMyGroups = (data) => ({
  type: actionTypes.HANDLE_REDIRECT_FROM_MY_GROUPS,
  data,
});

// ADD USERNAME ACTIONS
export const addUsernameStart = (data) => ({
  type: actionTypes.ADD_USERNAME_START,
  data,
});

export const addUsernameSuccess = () => ({
  type: actionTypes.ADD_USERNAME_SUCCESS,
});

export const addUsernameError = (error) => ({
  type: actionTypes.ADD_USERNAME_ERROR,
  error,
});

export const clearAddUsernameReqStatus = () => ({
  type: actionTypes.CLEAR_ADD_USERNAME_REQ_STATUS,
});

// FINISH TUTORIAL ACTIONS
export const finishTutorialStart = () => ({
  type: actionTypes.FINISH_TUTORIAL_START,
});

export const finishTutorialSuccess = () => ({
  type: actionTypes.FINISH_TUTORIAL_SUCCESS,
});

export const finishTutorialError = (error) => ({
  type: actionTypes.FINISH_TUTORIAL_ERROR,
  error,
});

// GET SUGGESTION ACTIONS
export const getSuggestionStart = (data) => ({
  type: actionTypes.GET_SUGGESTION_START,
  data,
});

export const getSuggestionSuccess = (data) => ({
  type: actionTypes.GET_SUGGESTION_SUCCESS,
  data,
});

export const getSuggestionError = (error) => ({
  type: actionTypes.GET_SUGGESTION_ERROR,
  error,
});

export const clearSuggestions = () => ({
  type: actionTypes.CLEAR_SUGGESTIONS,
});

// REPORT USER ACTIONS
export const reportUserStart = (data) => ({
  type: actionTypes.REPORT_USER_START,
  data,
});

export const reportUserSuccess = () => ({
  type: actionTypes.REPORT_USER_SUCCESS,
});

export const reportUserError = (error) => ({
  type: actionTypes.REPORT_USER_ERROR,
  error,
});

// ADD GROUP ACTIONS
export const addGroupStart = (data) => ({
  type: actionTypes.ADD_GROUP_START,
  data,
});

export const addGroupSuccess = (data) => ({
  type: actionTypes.ADD_GROUP_SUCCESS,
  data,
});

export const addGroupError = (error) => ({
  type: actionTypes.ADD_GROUP_ERROR,
  error,
});

export const clearCreatedGroupStatus = () => ({
  type: actionTypes.CLEAR_CREATED_GROUP_STATUS,
});

// GET USER DETAILS ACTIONS
export const getUserDetailsStart = (data) => ({
  type: actionTypes.GET_USER_DETAILS_START,
  data,
});

export const getUserDetailsSuccess = (data) => ({
  type: actionTypes.GET_USER_DETAILS_SUCCESS,
  data,
});

export const getUserDetailsError = (error) => ({
  type: actionTypes.GET_USER_DETAILS_ERROR,
  error,
});

// GET USER POSTS ACTIONS
export const getUserPostsStart = (data) => ({
  type: actionTypes.GET_USER_POSTS_START,
  data,
});

export const getUserPostsSuccess = (data) => ({
  type: actionTypes.GET_USER_POSTS_SUCCESS,
  data,
});

export const getUserPostsError = (error) => ({
  type: actionTypes.GET_USER_POSTS_ERROR,
  error,
});

// GET SIDE MY EVENTS ACTIONS
export const getSideMyEventsStart = () => ({
  type: actionTypes.GET_SIDE_MY_EVENTS_START,
});

export const getSideMyEventsSuccess = (data) => ({
  type: actionTypes.GET_SIDE_MY_EVENTS_SUCCESS,
  data,
});

export const getSideMyEventsError = (error) => ({
  type: actionTypes.GET_SIDE_MY_EVENTS_ERROR,
  error,
});

// GET SIDE GROUPS ACTIONS
export const getSideGroupsStart = () => ({
  type: actionTypes.GET_SIDE_GROUPS_START,
});

export const getSideGroupsSuccess = (data) => ({
  type: actionTypes.GET_SIDE_GROUPS_SUCCESS,
  data,
});

export const getSideGroupsError = (error) => ({
  type: actionTypes.GET_SIDE_GROUPS_ERROR,
  error,
});

// GET USER FOLLOWERS ACTIONS
export const getUserFollowersStart = (data) => ({
  type: actionTypes.GET_USER_FOLLOWERS_START,
  data,
});

export const getUserFollowersSuccess = (data) => ({
  type: actionTypes.GET_USER_FOLLOWERS_SUCCESS,
  data,
});

export const getUserFollowersError = (error) => ({
  type: actionTypes.GET_USER_FOLLOWERS_ERROR,
  error,
});

// GET USER FOLLOWING ACTIONS
export const getUserFollowingStart = (data) => ({
  type: actionTypes.GET_USER_FOLLOWING_START,
  data,
});

export const getUserFollowingSuccess = (data) => ({
  type: actionTypes.GET_USER_FOLLOWING_SUCCESS,
  data,
});

export const getUserFollowingError = (error) => ({
  type: actionTypes.GET_USER_FOLLOWING_ERROR,
  error,
});

// REMOVE USER FROM MY FOLLOWING ACTIONS
export const removeUserFromMyFollowersStart = (data) => ({
  type: actionTypes.REMOVE_USER_FROM_MY_FOLLOWERS_START,
  data,
});

export const removeUserFromMyFollowersSuccess = () => ({
  type: actionTypes.REMOVE_USER_FROM_MY_FOLLOWERS_SUCCESS,
});

export const removeUserFromMyFollowersError = (error) => ({
  type: actionTypes.REMOVE_USER_FROM_MY_FOLLOWERS_ERROR,
  error,
});

// GET INVOLVED USERS ACTIONS
export const getInvolvedUsers = (data) => ({
  type: actionTypes.GET_INVOLVED_USERS,
  data,
});

export const clearInvolvedUsersStore = (data) => ({
  type: actionTypes.CLEAR_INVOLVED_USERS_STORE,
  data,
});

// GET USER GARAGE VEHICLES ACTIONS
export const getUserGarageVehiclesStart = (data) => ({
  type: actionTypes.GET_USER_GARAGE_VEHICLES_START,
  data,
});

export const getUserGarageVehiclesSuccess = (data) => ({
  type: actionTypes.GET_USER_GARAGE_VEHICLES_SUCCESS,
  data,
});

export const getUserGarageVehiclesError = (error) => ({
  type: actionTypes.GET_USER_GARAGE_VEHICLES_START,
  error,
});

// SET REDIRECT FROM USER DETAILS SCREEN
export const setRedirectFromUserDetails = (data) => ({
  type: actionTypes.SET_REDIRECT_FROM_USER_DETAILS,
  data,
});

// ADD/REMOVE LIKE TO USER SCREEN POST ACTIONS
export const addLikeToUserDetailsPost = (data) => ({
  type: actionTypes.ADD_LIKE_TO_USER_DETAILS_POST,
  data,
});

export const removeLikeToUserDetailsPost = (data) => ({
  type: actionTypes.REMOVE_LIKE_FROM_USER_DETAILS_POST,
  data,
});

// SET POST ON DETAILS PAGE
export const setPostsOnUserDetails = (data) => ({
  type: actionTypes.SET_POSTS_ON_USER_DETAILS,
  data,
});
