import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  isClickable?: boolean;
  isRightPhoto?: boolean;
  ml?: number;
  width?: number;
}

export const Wrapper = styled.div<IProps>`
  width: 100%;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  /* cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')}; */
  position: relative;
  box-sizing: border-box;
  box-shadow: inset 0px -1px 0px rgba(236, 236, 236, 0.25);

  ${({ isClickable }) =>
    isClickable &&
    `:hover { background-color: ${style.mainGray.whiteSmokeColor} }`};
`;

export const NotificationContainer = styled.div`
  display: flex;
`;

export const IconWrap = styled.div`
  height: 24px;
  width: 24px;
  min-width: 24px;
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
`;

export const NotificationInfoWrap = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  margin-right: ${({ isRightPhoto }) => (isRightPhoto ? '20px' : '0')};
`;

export const Text = styled.p<IProps>`
  font-size: 15px;
  line-height: 130%;
  color: ${style.mainBlack.charcoalColor};
  letter-spacing: 0.02em;
  margin-left: ${({ ml }) => (ml ? `${ml}px` : '0')};
`;

export const HeaderText = styled(Text)`
  display: inline-block;
  color: ${style.mainBlack.black};
  font-weight: 500;
  cursor: pointer;
`;

export const DateText = styled.p`
  font-size: 13px;
  line-height: 100%;
  color: ${style.mainGray.SuvaGrey};
  margin-top: 8px;
`;

export const RightIconWrap = styled.div`
  height: 53px;
  width: 53px;
  min-width: 53px;
  border-radius: 2.12px;
  overflow: hidden;
  cursor: pointer;
`;

export const ButtonsWrap = styled.div`
  margin-top: 16px;
  display: flex;
`;

export const ButtonWrap = styled.div<IProps>`
  width: ${({ width }) => (width ? `${width}px` : '93px')};

  :first-of-type {
    margin-right: 10px;
  }
`;
