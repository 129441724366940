import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';

import { clearSidebarStore } from 'store/sideBarControler/actions';
import { ISideBar } from './ISideBar';
import { Wrapper, MainPageContentWrap } from './SideBar.style';

const SideBar = ({ children, scrollToTop }: ISideBar) => {
  const dispatch = useDispatch();

  const wrapperRef = useRef(null);

  if (scrollToTop) {
    wrapperRef.current.scrollTo(0, 0);
  }

  return (
    <>
      <MainPageContentWrap onClick={() => dispatch(clearSidebarStore())} />
      <Wrapper id="scrollTarget" ref={wrapperRef}>
        {children}
      </Wrapper>
    </>
  );
};

export default SideBar;
