export default (value) => {
  const data =
    value &&
    value?.map((d) => ({
      ...d,
      name: d?.username,
      avatar: d?.profilePhoto,
    }));
  return data;
};
