import React from 'react';
import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  width?: number;
  height?: number;
}

const IconWrap = styled.div<IProps>`
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  min-width: ${({ width }) => `${width}px`};
  display: inline-flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: ${style.mainBlack.appleBtnBlack};
`;

const Icon = styled.img<IProps>`
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  min-width: ${({ width }) => `${width}px`};
  background: none;
`;

export const RockrLogoIcon = ({ width = 36, height = 36 }: IProps) => (
  <IconWrap width={width} height={height}>
    <Icon
      src="/assets/img/rockrLogo.svg"
      alt="logo"
      width={width * 0.58}
      height={height * 0.58}
    />
  </IconWrap>
);
