import i18n from 'utils/i18n';

export const MESSAGE_TYPE = {
  DATE_ITEM: 1,
  MESSAGE_LEFT_SIMPLE_ITEM: 2,
  MESSAGE_LEFT_SIMPLE_NO_TITLE_ITEM: 3,
  MESSAGE_LEFT_COMPLETED_ITEM: 4,
  MESSAGE_LEFT_COMPLETED_NO_TITLE_ITEM: 5,
  MESSAGE_RIGHT_ITEM: 6,
  MESSAGE_RIGHT_COMPLETED_NO_TITLE_ITEM: 7,
  MESSAGE_RIGHT_COMPLETED_ITEM: 8,
  MESSAGE_RIGHT_SIMPLE_NO_TITLE_ITEM: 9,
  MESSAGE_RIGHT_MEDIA_ITEM: 10,
  MESSAGE_LEFT_MEDIA_ITEM: 11,
};

export const isRockr = process.env._ENV === 'rockr';

export const BASE_SITE_HOST = isRockr
  ? 'https://www.rockr.io'
  : 'https://www.roadstr.io';

// Server returns default image sometimes so we hardcode it's url
export const DEFAULT_LINK_POST_PLACEHOLDER_IMAGE =
  'https://cdn.roadstr.io/linkPreview_default_img.png';

// TAGS FOR SEARCH SCREEN
export const SEARCH_TAGS = () => [
  [
    {
      key: 'all',
      title: i18n.t('search:allFilter'),
    },
    {
      key: 'nearby',
      title: i18n.t('search:nearbyFilter'),
    },
    {
      key: 'brands',
      title: i18n.t('search:brandsFilter'),
    },
  ],
  [
    {
      key: 'all',
      title: i18n.t('search:allFilter'),
    },
    {
      key: 'nearby',
      title: i18n.t('search:nearbyFilter'),
    },
  ],
  [
    {
      key: 'all',
      title: i18n.t('search:allFilter'),
    },
    {
      key: 'nearby',
      title: i18n.t('search:nearbyFilter'),
    },
    {
      key: 'photos',
      title: i18n.t('search:photosFilter'),
    },
    {
      key: 'links',
      title: i18n.t('search:linksFilter'),
    },
    {
      key: 'videos',
      title: i18n.t('search:videosFilter'),
    },
  ],
  [
    {
      key: 'all',
      title: i18n.t('search:allFilter'),
    },
    {
      key: 'nearby',
      title: i18n.t('search:nearbyFilter'),
    },
    {
      key: 'brands',
      title: i18n.t('search:brandsFilter'),
    },
    {
      key: 'models',
      title: i18n.t('search:modelsFilter'),
    },
  ],
  [
    {
      key: 'all',
      title: i18n.t('search:allFilter'),
    },
    {
      key: 'calendar',
      title: i18n.t('search:calendarFilter'),
    },
    {
      key: 'nearby',
      title: i18n.t('search:nearbyFilter'),
    },
  ],
];

export const APP_CONFIG = {
  appname: isRockr ? 'rockr' : 'roadstr',
  APPNAME: isRockr ? 'RocKr' : 'RoadStr',
  vehicle: isRockr ? 'bike' : 'car',
  VEHICLE: isRockr ? 'Bike' : 'Car',
};

export const ERROR_MESSAGES = () => ({
  scheduledPostError: i18n.t('error:theDateShouldBeAFutureDate'),
});

export const InvolvedUserTypes = {
  MY_FOLLOWERS: 'MY_FOLLOWERS',
  MY_FOLLOWING: 'MY_FOLLOWING',
  USER_FOLLOWERS: 'USER_FOLLOWERS',
  USER_FOLLOWING: 'USER_FOLLOWING',
  GROUP_FOLLOWERS: 'GROUP_FOLLOWERS',
  GROUP_MEMBERS: 'GROUP_MEMBERS',
  GROUP_ADMINS: 'GROUP_ADMINS',
  EVENT_ATTENDING: 'EVENT_ATTENDING',
  EVENT_INVITED: 'EVENT_INVITED',
};

export const FollowingStatus = {
  NOT_FOLLOWING: 0,
  REQUESTED: 1,
  FOLLOWING: 2,
};

export const MapSidebarTypes = {
  SAVED_ROUTES: 'SAVED_ROUTES',
  WHERE_TO: 'WHERE_TO',
  PLAN_ROUTE: 'PLAN_ROUTE',
  CHOSE_START_POINT: 'CHOSE_START_POINT',
  CHOSE_END_POINT: 'CHOSE_END_POINT',
  CHOSE_WAY_POINT: 'CHOSE_WAY_POINT',
  ROUTE_DETAILS: 'ROUTE_DETAILS',
};

export const MapRoutePointTypes = {
  START: 'START',
  END: 'END',
  WAY: 'WAY',
};

export const START_WAYPOINT_TYPE = 'start.waypoint';
export const FINISH_WAYPOINT_TYPE = 'finish.waypoint';

export const DEFAULT_PAGE_SIZE = 20;
