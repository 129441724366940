export const regexMap = {
  tags: /(<.*?>)/g,
  email:
    /^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
  password: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
  url: /(?:http[s]?\/\/)?(?:[\w\\-]+(?::[\w\\-]+)?@)?(?:[\w\\-]+\.)+(?:[a-z]{2,4})(?::[0-9]+)?(?:\/[\w\-\\.%]+)*(?:\?(?:[\w\-\\.%]+=[\w\-\\.%!]+&?)+)?(#\w+\\-\.%!)?/,
  phone: /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/,
  urlImg:
    /((http|https):\/\/)?[a-zA-Z0-9./?:@\-_=#]+\.([a-zA-Z0-9&./?:@\-_=#])*/,
  lowerAndUpperCaseInString: /(?=.*[a-z])(?=.*[A-Z])/g,
  // tagWord: /(?<!\w)#\w+/,
};
