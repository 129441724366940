import Image from 'next/image';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

import { ArrowBack, EmptyGroupIcon, SearchIcon } from 'public/assets/icons';
import { getUserGroups } from 'services/userGroups';
import { actionTypes, openSideBar } from 'store/sideBarControler/actions';
import { inviteUserToGroups } from 'services/inviteUserGroup';
import { style } from 'utils/constants/style';
import {
  InputWrapper,
  UserNameGroup,
  GroupCardWrap,
  ImageWrap,
  ButtonWrap,
  EmptyGroupImg,
  GroupNameWrap,
} from './InviteTogroupe.styled';
import { InputField, RectangleButton } from '../..';
import { SidebarWrapper } from '..';

export const GroupCardComponent = ({
  el,
  onInvite,
  loading,
  invited,
  chatRef,
}: any) => {
  const { t } = useTranslation();

  return (
    <GroupCardWrap ref={chatRef}>
      {el.profilePhoto ? (
        <ImageWrap>
          <Image
            loader={() => el.profilePhoto}
            src={el.profilePhoto || el.coverPhoto}
            alt="Picture of user"
            width={48}
            height={48}
            unoptimized
            priority
          />
        </ImageWrap>
      ) : (
        <ImageWrap>
          <EmptyGroupImg>
            <EmptyGroupIcon />
          </EmptyGroupImg>
        </ImageWrap>
      )}
      <GroupNameWrap>
        <UserNameGroup>{el.username}</UserNameGroup>
        <span>{el.name}</span>
      </GroupNameWrap>

      <ButtonWrap>
        <RectangleButton
          color={invited ? style.mainBlue.blue : style.mainWhite.white}
          text={
            invited ? t('event:invitedUppercase') : t('event:inviteUppercase')
          }
          backgroundColor={
            invited ? style.mainBlue.aliceBlueColor : style.mainBlue.blue
          }
          onClick={() => onInvite(el._id)}
          loading={loading}
        />
      </ButtonWrap>
    </GroupCardWrap>
  );
};

const INVITE_PAGE_SIZE = 15;
const InviteToGroup = () => {
  const router = useRouter();
  const [filtred, setFiltred] = useState('');
  const [userGroups, setUserGroups] = useState([]);
  const [total, setTotal] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [invitedIdList, setInvitedIds] = useState([]);
  const [isLastInviteElementVisible, setIsLastInviteElementVisible] =
    useState(false);
  const [invitedId, setInvitedId] = useState('');
  const observer = useRef(null);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    getUserGroups({
      offset: 0,
      limit: INVITE_PAGE_SIZE,
      search: filtred,
    }).then((res) => {
      setUserGroups(res?.data);
      setTotal(res?.total);
    });
  }, [filtred]);

  useEffect(() => {
    if (isLastInviteElementVisible) {
      loadNextPage();
      setIsLastInviteElementVisible(false);
    }
  }, [isLastInviteElementVisible]);

  const loadNextPage = () => {
    if (userGroups?.length < total) {
      getUserGroups({
        offset: 0,
        limit: userGroups?.length + INVITE_PAGE_SIZE,
        search: filtred,
      }).then((res) => setUserGroups(res?.data));
    }
  };

  const lastInviteElement = useCallback((node) => {
    if (observer.current) observer?.current?.disconnect();
    observer.current = new IntersectionObserver((entires) => {
      if (entires[0]?.isIntersecting) {
        setIsLastInviteElementVisible(true);
      }
    });
    if (node) observer.current.observe(node);
  }, []);

  const onInviteUserToGroup = (id) => {
    setLoading(true);
    setInvitedIds((prevState) => [...prevState, id]);
    setInvitedId(id);
    inviteUserToGroups(id, router.query.id).then((data) => setLoading(false));
  };

  return (
    <SidebarWrapper
      headerText={t('profile:inviteToGroup')}
      headerIcon={<ArrowBack />}
      onIconClick={() => {
        dispatch(openSideBar(actionTypes.CLOSE_SIDEBAR_ADD_USER_GROUP));
      }}
      contentPadding="0 0 20px 0"
    >
      <InputWrapper>
        <InputField
          value={null}
          onChange={(e) => setFiltred(e.target.value)}
          iconLeft={<SearchIcon colorStroke="#C2C2C2" />}
          crossIcon
          maxLength={50}
        />
        {userGroups?.map((el, i) => (
          <GroupCardComponent
            el={el}
            onInvite={(id) => onInviteUserToGroup(id)}
            loading={invitedId === el._id && loading}
            invited={invitedIdList.find((elem) => elem === el._id)}
            chatRef={lastInviteElement}
          />
        ))}
      </InputWrapper>
    </SidebarWrapper>
  );
};

export default InviteToGroup;
