export const SOCKET_EVENTS = {
  CONNECT: 'connect',
  JOIN_ROOM_EVENT: '/v11/chat/join',
  LEFT_ROOM_EVENT: '/v11/chat/left',
  SEND_MESSAGE_EVENT: '/v11/chat/send',
  NEW_MESSAGE_EVENT: 'newMessage',
  MEDIA_UPLOADED_EVENT: 'media-uploaded',
  IS_READ: '/v11/chat/isRead',
  IS_NOTIFIED: '/v11/chat/isNotified',
  SEND_MEDIA: '/v11/chat/send-media',
  UPLOAD_MEDIA: '/v11/chat/upload-media',
  MEDIA_UPLOADED: '/v11/chat/media-uploaded',
  UNSEND: '/v11/chat/unsend',
};
