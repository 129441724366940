import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  fw?: number;
}

export const Text = styled.p`
  font-size: 14px;
  line-height: 114%;
  color: ${style.mainBlack.black};
`;

export const HeaderText = styled.span<IProps>`
  color: ${style.mainBlack.black};
  font-weight: ${({ fw }) => fw || 400};
  cursor: pointer;
  font-size: 14px;
  line-height: 114%;
`;
