import styled from 'styled-components';
import Modal from 'components/common/Modal';

interface IProps {
  hasImage?: boolean;
}

export const ModalWrapper = styled(Modal)<IProps>`
  margin: 0;
  width: 100vw;
  overflow-y: inherit;
  height: calc(100vh - 116px);

  @media (max-width: 767px) {
    height: 100vh;
  }

  & > div {
    position: fixed;
    left: 0;
    top: 59px;
    bottom: 0;
    right: 0;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 767px) {
      padding: 0;
    }
    & > div {
      ${(props) => {
        if (props.hasImage) {
          return `
            max-height: 100%;
            height: 100%;
            display: flex;
            align-items: center;
          `;
        }
      }}
      & > .carousel-root {
        height: 100%;
        & > div {
          height: 100%;
          & > .control-dots {
            margin: 0;
          }
        }
      }
    }
    &:nth-child(2) {
    }
  }

  .slider-wrapper {
    & li {
      & > div {
        background: none !important;
      }
    }
  }
`;
