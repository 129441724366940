import { useCallback, useMemo, useRef, useState } from 'react';
import { EditorState, ContentState } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import createMentionPlugin, {
  defaultSuggestionsFilter,
} from '@draft-js-plugins/mention';
import createHashtagPlugin from '@draft-js-plugins/hashtag';
import linkifyIt from 'linkify-it';
import tlds from 'tlds';
import { searchUserAutocomplete } from 'services/search';
import getMentionData from 'utils/getMentionData';
import { Field } from 'react-final-form';
import ValidationError from 'components/ValidationError';
import {
  IInputDescription,
  IField,
  IInputDescriptionField,
} from './IInputDescription';
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/mention/lib/plugin.css';

import {
  InputDescriptionWrapper,
  Label,
  DescriptionWrapper,
} from './InputDescription.style';
import editorStyles from './SimpleMentionEditor.module.css';

const InputDescription = ({
  defaultValue = '',
  placeholder = '',
  onChange,
  height,
  label,
}: IInputDescription) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(ContentState.createFromText(defaultValue))
  );

  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState<any>([]);

  const ref = useRef<Editor>(null);

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      mentionPrefix: '@',
    });
    const linkifyPlugin = createLinkifyPlugin({
      theme: {
        link: editorStyles.hashtag,
      },
      target: '_blank',
      customExtractLinks: (text) =>
        linkifyIt().tlds(tlds).set({ fuzzyEmail: false }).match(text),
    });
    const hashtagPlugin = createHashtagPlugin({
      theme: {
        hashtag: editorStyles.hashtag,
      },
    });
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin;
    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin, linkifyPlugin, hashtagPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);

  const onSearchChange = useCallback(async ({ value }: { value: string }) => {
    try {
      if (value) {
        const ddd = await searchUserAutocomplete({
          query: value,
        });
        const usernames = ddd?.data?.usernames;
        setSuggestions(
          defaultSuggestionsFilter(value, getMentionData(usernames))
        );
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleChange = (e) => {
    setEditorState(e);
    onChange(e.getCurrentContent().getPlainText());
  };

  return (
    <InputDescriptionWrapper
      onClick={() => {
        ref.current.focus();
      }}
    >
      {label && <Label>{label}</Label>}
      <DescriptionWrapper height={height}>
        <Editor
          editorKey="editor"
          editorState={editorState}
          onChange={handleChange}
          plugins={plugins}
          ref={ref}
          placeholder={placeholder}
        />
        <MentionSuggestions
          open={open}
          onOpenChange={onOpenChange}
          suggestions={suggestions}
          onSearchChange={onSearchChange}
        />
      </DescriptionWrapper>
    </InputDescriptionWrapper>
  );
};

const Input = ({
  defaultValue = '',
  placeholder = '',
  onChange,
  label,
  input,
  meta,
  customErrorText,
  height,
  ...rest
}: IInputDescriptionField) => {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(
      ContentState.createFromText(input?.value || '')
    )
  );

  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState<any>([]);

  const ref = useRef<Editor>(null);

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      mentionPrefix: '@',
    });
    const linkifyPlugin = createLinkifyPlugin({
      theme: {
        link: editorStyles.hashtag,
      },
      target: '_blank',
      customExtractLinks: (text) =>
        linkifyIt().tlds(tlds).set({ fuzzyEmail: false }).match(text),
    });
    const hashtagPlugin = createHashtagPlugin({
      theme: {
        hashtag: editorStyles.hashtag,
      },
    });
    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin;
    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin, linkifyPlugin, hashtagPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);

  const onSearchChange = useCallback(async ({ value }: { value: string }) => {
    try {
      if (value) {
        const ddd = await searchUserAutocomplete({
          query: value,
        });
        const usernames = ddd?.data?.usernames;
        setSuggestions(
          defaultSuggestionsFilter(value, getMentionData(usernames))
        );
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleChange = (e) => {
    setEditorState(e);
    input.onChange(e.getCurrentContent().getPlainText());
  };

  const { valid, invalid, touched, error, submitError } = meta;

  const errorText = customErrorText || error || submitError;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError =
    !!customErrorText || !!(touched && invalid && (error || submitError));

  return (
    <InputDescriptionWrapper
      onClick={() => {
        ref.current.focus();
      }}
    >
      {label && <Label>{label}</Label>}
      <DescriptionWrapper height={height}>
        <Editor
          editorKey="editor"
          editorState={editorState}
          onChange={handleChange}
          plugins={plugins}
          ref={ref}
          placeholder={placeholder}
          {...rest}
        />
        <MentionSuggestions
          open={open}
          onOpenChange={onOpenChange}
          suggestions={suggestions}
          onSearchChange={onSearchChange}
        />
      </DescriptionWrapper>
      <ValidationError touched={hasError} error={errorText} />
    </InputDescriptionWrapper>
  );
};

export const InputDescriptionField = (props: IField) => {
  return <Field component={Input} {...props} />;
};

export default InputDescription;
