import ReactLoading from 'react-loading';
import { LoadingWrap } from './LoadingComponent.style';

interface ILoadingComponent {
  color: string;
  variant: any;
  width?: number;
  fullWidth?: boolean;
  loaderSize?: number;
  wrapHeight?: number;
  mobileWrapHeight?: number;
}

const LoadingComponent = ({
  color,
  variant,
  width,
  fullWidth,
  loaderSize = 100,
  wrapHeight = 400,
  mobileWrapHeight = 400,
}: ILoadingComponent) => (
  <LoadingWrap
    width={width}
    fullWidth={fullWidth}
    height={wrapHeight}
    mobileWrapHeight={mobileWrapHeight}
  >
    <ReactLoading
      type={variant}
      color={color}
      height={loaderSize}
      width={loaderSize}
    />
  </LoadingWrap>
);

export default LoadingComponent;
