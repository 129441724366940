import ImageCropper from 'components/ImageCropper/ImageCropper';
import ImagePreview from 'components/ImagePreview';
import { CameraIcon } from 'public/assets/icons';
import { useRef, useState } from 'react';
import { IImageUploader } from './IImageUploader';

import {
  FileInput,
  ImageUploaderWrapper,
  PickerIcon,
  PickerLabel,
  SmallImageContainerWrap,
} from './ImageUploader.styles';

const ImageUploader = ({
  cropAspect = 1 / 1,
  isAspect,
  isCropShapeCircle,
  onChange,
  label,
  isPhotoPost,
  isSmallImages = false,
  hasError,
}: IImageUploader) => {
  const [imageSrc, setImageSrc] = useState<string>('');
  const smallImageInputRef: any = useRef();
  const [imageSize, setImageSize] = useState<number>(0);
  const [showCropper, setShowCropper] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<any>(null);

  const onFileChange = (e) => {
    if (e?.target?.files && e?.target?.files?.length > 0) {
      const file = e?.target?.files[0];
      setShowCropper(true);
      setImageSrc(URL.createObjectURL(file));
      setImageSize(file?.size);
      if (smallImageInputRef.current) smallImageInputRef.current.value = '';
      setUploadedFile(file);
    }
  };

  const onSubmitImage = (image: string) => {
    setShowCropper(false);
    onChange(image, imageSize, uploadedFile);
  };

  return (
    <>
      {showCropper && imageSrc && (
        <ImageCropper
          aspect={cropAspect}
          image={imageSrc}
          onSubmitImage={onSubmitImage}
          isCircleShape={isCropShapeCircle}
          isAspect={isAspect}
        />
      )}
      {isSmallImages ? (
        <SmallImageContainerWrap>
          <FileInput
            ref={smallImageInputRef}
            type="file"
            onChange={onFileChange}
            accept="image/*"
          />

          <PickerIcon mt={20} mb={0} isSmallImages>
            <CameraIcon width="24" height="24" />
          </PickerIcon>
        </SmallImageContainerWrap>
      ) : (
        <ImageUploaderWrapper hasError={hasError}>
          <PickerIcon hasError={hasError}>
            <CameraIcon />
            <PickerLabel hasError={hasError}>{label}</PickerLabel>
          </PickerIcon>
          <FileInput type="file" onChange={onFileChange} accept="image/*" />
        </ImageUploaderWrapper>
      )}
    </>
  );
};

export default ImageUploader;
