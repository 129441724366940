import React from 'react';

export const LocationArrowIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.9969 4.30198C19.9945 4.28354 19.9917 4.2658 19.9862 4.24771C19.9802 4.22788 19.9722 4.20979 19.9632 4.19135C19.9583 4.18126 19.9566 4.17047 19.9507 4.16073C19.9468 4.15447 19.9406 4.1503 19.9361 4.14438C19.9246 4.12838 19.911 4.11446 19.8967 4.1002C19.8825 4.08593 19.8682 4.07271 19.8519 4.06123C19.846 4.05706 19.8421 4.05114 19.8362 4.04767C19.8265 4.04175 19.8157 4.04036 19.8056 4.03549C19.7865 4.0261 19.7677 4.01809 19.7475 4.01218C19.7305 4.00766 19.7138 4.00522 19.6964 4.00313C19.6758 4.0007 19.656 3.99931 19.6355 4.00035C19.6163 4.00139 19.5979 4.00522 19.5791 4.00974C19.5673 4.01253 19.5551 4.01148 19.5433 4.01566L4.23559 9.23418C4.09713 9.2808 4.00285 9.40987 4.00006 9.55634C3.99728 9.70281 4.08634 9.83536 4.22342 9.88789L11.3752 12.636L14.4657 19.7902C14.521 19.9179 14.6469 20 14.785 20C14.7909 20 14.7965 20 14.8024 19.9997C14.9475 19.9923 15.0724 19.896 15.1166 19.7579L19.9872 4.45019C19.9907 4.4394 19.9896 4.42827 19.9917 4.41714C19.9959 4.39765 19.999 4.37887 19.9997 4.35904C20.0004 4.33955 19.9994 4.32112 19.9969 4.30198Z"
      stroke="#8D8D8D"
      strokeWidth="1.6"
    />
  </svg>
);
