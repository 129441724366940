/* eslint-disable no-prototype-builtins */
import React from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import StarRatings from 'react-star-ratings';

import { routesPath } from 'utils/constants/routePath';
import { closeSidebarNotifications } from 'store/sideBarControler/actions';
import { Text, HeaderText } from './NotificationText.style';
import { INotificationText } from './INotificationText';

export const NotificationText = ({ type, data }: INotificationText) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { t } = useTranslation();

  const userProfileLink = (
    <HeaderText
      onClick={(e) => {
        e.stopPropagation();
        router.push(`${routesPath.user_details}/${data.userId}`);
        dispatch(closeSidebarNotifications());
      }}
      fw={600}
    >
      {data.username}
    </HeaderText>
  );

  const rattingNotification = (
    <HeaderText
      onClick={(e) => {
        e.stopPropagation();
        router.push(`${routesPath.routeDetails}/${data.userId}`);
        dispatch(closeSidebarNotifications());
      }}
      fw={600}
    >
      {data?.username}
    </HeaderText>
  );

  const rattedRouteName = (
    <HeaderText
      onClick={(e) => {
        e.stopPropagation();
        router.push(`${routesPath.routeDetails}/${data.userId}`);
        dispatch(closeSidebarNotifications());
      }}
      fw={600}
    >
      {data?.routeName}
    </HeaderText>
  );

  const mentionerProfileLink = (
    <HeaderText
      onClick={(e) => {
        e.stopPropagation();
        router.push(`${routesPath.user_details}/${data.mentionerId}`);
        dispatch(closeSidebarNotifications());
      }}
      fw={600}
    >
      {data.mentionerUsername}
    </HeaderText>
  );

  const eventPageLink = (
    <HeaderText
      onClick={(e) => {
        e.stopPropagation();
        router.push(`${routesPath.event_details}/${data.eventId}`);
        dispatch(closeSidebarNotifications());
      }}
      fw={600}
    >
      {data.name ||
        data.eventName ||
        ([70].includes(data.type) && data.username) ||
        t('common:eventLowercase')}
    </HeaderText>
  );

  const groupPageLink = (
    <HeaderText
      onClick={(e) => {
        e.stopPropagation();
        router.push(`${routesPath.group_details}/${data.groupId}`);
        dispatch(closeSidebarNotifications());
      }}
      fw={600}
    >
      {data.groupName ||
        data.groupUsername ||
        ([20, 70].includes(data.type) && data.username) ||
        t('group:groupText')}
    </HeaderText>
  );

  const postPageLink = (
    <HeaderText
      onClick={(e) => {
        e.stopPropagation();
        router.push(`${routesPath.post_details}/${data.postId}`);
        dispatch(closeSidebarNotifications());
      }}
      fw={600}
    >
      {t('notifications:post')}
    </HeaderText>
  );

  const vehiclePageLink = (
    <HeaderText
      onClick={(e) => {
        e.stopPropagation();
        router.push(`${routesPath.vehicle_details}/${data.vehicleId}`);
        dispatch(closeSidebarNotifications());
      }}
      fw={600}
    >
      {t('notifications:vehicle')}
    </HeaderText>
  );

  const postOwnerLink = (
    <HeaderText
      onClick={(e) => {
        e.stopPropagation();
        router.push(`${routesPath.user_details}/${data.postOwnerId}`);
        dispatch(closeSidebarNotifications());
      }}
      fw={600}
    >
      {data.postOwner}
      {t('notifications:dotS')}
    </HeaderText>
  );

  switch (type) {
    case 8:
      return (
        <Text>
          {userProfileLink}&nbsp;{t('notifications:startedFollowingYou')}
        </Text>
      );

    case 9:
      if (data.hasOwnProperty('eventId')) {
        return (
          <Text>
            {userProfileLink}&nbsp;{t('notifications:likedYourEvent')}&nbsp;
            {eventPageLink}.
          </Text>
        );
      }

      if (data.hasOwnProperty('postId')) {
        return (
          <Text>
            {userProfileLink}&nbsp;{t('notifications:likedYour')}&nbsp;
            {postPageLink}.
          </Text>
        );
      }

      if (data.hasOwnProperty('vehicleId')) {
        return (
          <Text>
            {userProfileLink}&nbsp;{t('notifications:likedYour')}&nbsp;
            {vehiclePageLink}.
          </Text>
        );
      }
      break;

    case 10:
      if (data.hasOwnProperty('eventId')) {
        return (
          <Text>
            {userProfileLink}&nbsp;{data?.additional && t('notifications:also')}
            {data?.additional
              ? t('notifications:commentedOnEvent')
              : t('notifications:commentedOnYourEvent')}
            &nbsp;{eventPageLink}.
          </Text>
        );
      }

      if (data.hasOwnProperty('postId')) {
        return (
          <Text>
            {userProfileLink}&nbsp;{data?.additional && t('notifications:also')}
            {data?.additional
              ? t('notifications:commentedOn')
              : t('notifications:commentedOnYour')}
            &nbsp;
            {data?.additional ? postOwnerLink : ''}
            &nbsp;{postPageLink}.
          </Text>
        );
      }

      if (data.hasOwnProperty('vehicleId')) {
        return (
          <Text>
            {userProfileLink}&nbsp;{data?.additional && t('notifications:also')}
            {data?.additional
              ? t('notifications:commentedOn')
              : t('notifications:commentedOnYour')}
            &nbsp;
            {data?.commentedUserName}
            {t('notifications:dotS')}&nbsp;{vehiclePageLink}.
          </Text>
        );
      }
      break;

    case 11:
      return (
        <Text>
          {userProfileLink}&nbsp;{t('notifications:taggedYouInAPost')}
        </Text>
      );

    case 12:
      return (
        <Text>
          {data.chat ? (
            <>
              {userProfileLink}&nbsp;{t('notifications:isAttendingYourEvent')}
              &nbsp;
              {eventPageLink}. {t('notifications:welcomeThemInTheEventChat')}
            </>
          ) : (
            <>
              {userProfileLink}&nbsp;{t('notifications:isAttendingYourEvent')}
              &nbsp;
              {eventPageLink}.{' '}
              {t('notifications:enableTheEventChatToWelcomeThem')}
            </>
          )}
        </Text>
      );

    case 13:
      return (
        <Text>
          {userProfileLink}&nbsp;{t('notifications:invitedYouToTheEvent')}&nbsp;
          {eventPageLink}.
        </Text>
      );

    case 14:
      return (
        <Text>
          {userProfileLink}&nbsp;{t('notifications:cancelledTheEvent')}&nbsp;
          {eventPageLink}.
        </Text>
      );

    case 15:
      return (
        <Text>
          {userProfileLink}&nbsp;
          {t('notifications:modifiedTheDetailsOfTheEvent')}&nbsp;
          {eventPageLink}.
        </Text>
      );

    case 16:
      return (
        <Text>
          {userProfileLink}&nbsp;{t('notifications:addedYouAsACollaborator')}
        </Text>
      );

    case 17:
      return (
        <Text>
          {userProfileLink}&nbsp;{t('notifications:becameAMemberOfYourGroup')}
          &nbsp;
          {groupPageLink}.
        </Text>
      );

    case 18:
      return (
        <Text>
          {userProfileLink}&nbsp;
          {t('notifications:requestsMembershipOfYourGroup')}&nbsp;
          {groupPageLink}.
        </Text>
      );

    case 19:
      return (
        <Text>
          {userProfileLink}&nbsp;{t('notifications:sentYouAFollowRequest')}
        </Text>
      );

    case 20:
      if (data?.membership) {
        return (
          <Text>
            {groupPageLink}&nbsp;{t('notifications:approvedYourMemberRequest')}
          </Text>
        );
      }
      return (
        <Text>
          {userProfileLink}&nbsp;{t('notifications:approvedYourMemberRequest')}
        </Text>
      );

    case 22:
      return (
        <Text>
          {userProfileLink}&nbsp;{t('notifications:taggedYourEvent')}&nbsp;
          {eventPageLink}
          &nbsp;{t('notifications:inAPost')}
        </Text>
      );

    case 23:
      return (
        <Text>
          {userProfileLink}&nbsp;{t('notifications:wantsToTagYourEvent')}&nbsp;
          {eventPageLink}
          &nbsp;{t('notifications:inAPost')}
        </Text>
      );

    case 24:
      return (
        <Text>
          {userProfileLink}&nbsp;{t('notifications:taggedYourGroup')}&nbsp;
          {groupPageLink}
          &nbsp;{t('notifications:inAPost')}
        </Text>
      );

    case 25:
      return (
        <Text>
          {userProfileLink}&nbsp;{t('notifications:wantsToTagYourGroup')}&nbsp;
          {groupPageLink}
          &nbsp;{t('notifications:inAPost')}
        </Text>
      );

    case 26:
      return (
        <Text>
          {userProfileLink}&nbsp;{t('notifications:taggedYouAsACollaborator')}
          &nbsp;{t('notifications:inA')}&nbsp;
          {postPageLink}.
        </Text>
      );

    case 27:
      return (
        <Text>
          {userProfileLink}
          &nbsp;{t('notifications:isRoadSharingNearbyInA')}&nbsp;
          {data?.car?.year ?? ''}&nbsp;
          {data?.car?.make ?? ''}&nbsp;{data?.car?.model ?? ''}!&nbsp;
          {data?.roadshareStatus ?? ''}.
        </Text>
      );

    case 28:
      return <Text>{t('notifications:welcomeToRoadStrMessage')}</Text>;

    case 29:
      return (
        <Text>
          {t('notifications:aFriendFromYourContactsIsOnRoadStrAs')}&nbsp;
          {userProfileLink}.
        </Text>
      );

    case 31:
      return (
        <Text>
          {data.isGroup ? (
            <>
              {mentionerProfileLink}&nbsp;
              {t('notifications:mentionedYourGroup')}&nbsp;
              {groupPageLink}&nbsp;{t('notifications:inAComment')}
            </>
          ) : (
            <>
              {mentionerProfileLink}&nbsp;
              {t('notifications:mentionedYouInAComment')}
            </>
          )}
        </Text>
      );

    case 32:
      return (
        <Text>
          {data.isGroup ? (
            <>
              {mentionerProfileLink}&nbsp;
              {t('notifications:mentionedYourGroup')}&nbsp;{groupPageLink}&nbsp;
              {t('notifications:inAVehicleComment')}
            </>
          ) : (
            <>
              {mentionerProfileLink}&nbsp;
              {t('notifications:mentionedYouInAVehicleComment')}
            </>
          )}
        </Text>
      );

    case 33:
      return (
        <Text>
          {data.isGroup ? (
            <>
              {mentionerProfileLink}&nbsp;
              {t('notifications:mentionedYourGroup')}&nbsp;{groupPageLink}&nbsp;
              {t('notifications:inACommentOfTheEvent')}&nbsp;{eventPageLink}.
            </>
          ) : (
            <>
              {mentionerProfileLink}&nbsp;
              {t('notifications:mentionedYouInACommentOfTheEvent')}&nbsp;
              {eventPageLink}.
            </>
          )}
        </Text>
      );

    case 34:
      return (
        <Text>
          {data.isGroup ? (
            <>
              {mentionerProfileLink}&nbsp;
              {t('notifications:mentionedYourGroup')}&nbsp;{groupPageLink}&nbsp;
              {t('notifications:inAPost')}
            </>
          ) : (
            <>
              {mentionerProfileLink}&nbsp;
              {t('notifications:mentionedYouInAPost')}
            </>
          )}
        </Text>
      );

    case 35:
      return (
        <Text>
          {t('notifications:discoverProfilesAndGroupsToFollowLike')}&nbsp;
          {userProfileLink}.
        </Text>
      );

    case 36:
      return (
        <Text>
          {t('notifications:checkOutNewPostsFrom')}&nbsp;{userProfileLink}.
        </Text>
      );

    case 37:
      return (
        <Text>
          {userProfileLink}&nbsp;
          {t('notifications:postedForTheFirstTimeInAWhileCheckItOut')}
        </Text>
      );

    case 38:
      return (
        <Text>
          {t('notifications:youHave')}&nbsp;{data.daysNumber}&nbsp;
          {t('notifications:upcomingEventsInYourArea')}
        </Text>
      );

    case 39:
      return (
        <Text>
          {t('notifications:youHaveAnUpcomingEventTomorrow')}&nbsp;
          {eventPageLink}.
        </Text>
      );

    case 40:
      return (
        <Text>
          {t('notifications:youHaveAPendingEventInvitationFor')}&nbsp;
          {eventPageLink}.&nbsp;
          {t('notifications:letThemKnowIfYouReComing')}
        </Text>
      );

    case 41:
      return <Text>{t('notifications:inviteYourFriends')}</Text>;

    case 42:
      return (
        <Text>
          {t('notifications:letOthersKnowIfYouReOnYourWayTo')}&nbsp;
          {eventPageLink}.&nbsp;
          {t('notifications:turnOnRoadSharingMode')}
        </Text>
      );

    case 43:
      return (
        <Text>
          {userProfileLink}&nbsp;
          {t('notifications:isAttendingANearbyEventCheckItOut')}&nbsp;
          {eventPageLink}
          .&nbsp;{t('notifications:checkItOut')}
        </Text>
      );

    case 44:
      return (
        <Text>
          {userProfileLink}&nbsp;{t('notifications:taggedTheEvent')}&nbsp;
          {eventPageLink}
          &nbsp;{t('notifications:inAPost')}.&nbsp;
          {t('notifications:checkItOut')}
        </Text>
      );

    case 45:
      return (
        <Text>
          {userProfileLink}&nbsp;{t('notifications:invitedYouToJoinTheGroup')}
          &nbsp;
          {groupPageLink}.&nbsp;{t('notifications:checkItOut')}
        </Text>
      );

    case 47:
      return (
        <Text>
          {t('notifications:yourGroupIsGrowing')}&nbsp;{groupPageLink}&nbsp;
          {t('notifications:nowHas')}
          &nbsp;{data.groupMembers}&nbsp;{t('notifications:members')}
        </Text>
      );

    case 48:
      return (
        <Text>
          {userProfileLink}&nbsp;{t('notifications:sharedAPostInTheGroup')}
          &nbsp;{groupPageLink}
          .&nbsp;{t('notifications:takeALook')}
        </Text>
      );

    case 51:
      return (
        <Text>
          {userProfileLink}&nbsp;{t('notifications:justSharedAPost')}
        </Text>
      );

    case 54:
      return (
        <Text>
          {t('notifications:thereAre')}&nbsp;{data.groupGarageVehicles}&nbsp;
          {t('notifications:newVehiclesInTheGroup')}&nbsp;
          {groupPageLink}&nbsp;{t('notifications:sGarageTakeALook')}
        </Text>
      );

    case 56:
      return (
        <Text>
          {userProfileLink}&nbsp;{t('notifications:created')}&nbsp;
          {t('notifications:an')}&nbsp;{t('notifications:createdEventNearby')}
          &nbsp;
          {eventPageLink}
        </Text>
      );

    case 59:
      return (
        <Text>
          {t('notifications:findYourFriendOn')}&nbsp;
          {t('notifications:RoadStr')}
        </Text>
      );

    case 60:
      return <Text>{t('notifications:introduceYourself')}</Text>;

    case 61:
      return <Text>{t('notifications:attendOrCreateEvent')}</Text>;

    case 62:
      return <Text>{t('notifications:uploadToVirtualGarage')}</Text>;

    case 63:
      return <Text>{t('notifications:shareYoutKnowledgeSubheader')}</Text>;

    case 76:
      return (
        <>
          <Text>
            {rattingNotification}&nbsp;{t('notifications:ratedYourRoute')}&nbsp;
            {rattedRouteName}
          </Text>
          <StarRatings
            rating={data?.averageRating}
            starRatedColor="#FF950A"
            starHoverColor="#FF950A"
            name="rating"
            starDimension="18px"
            starSpacing="1px"
          />
        </>
      );

    case 70:
      if (data?.eventCoverPhoto) {
        return (
          <Text>
            {eventPageLink}&nbsp;{t('notifications:approvedYourPostTag')}
          </Text>
        );
      }
      return (
        <Text>
          {groupPageLink}&nbsp;{t('notifications:approvedYourPostTag')}
        </Text>
      );

    case 222:
      return <Text>{data.body}</Text>;

    default:
      return <Text>{data.body}</Text>;
  }
};
