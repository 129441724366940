import React from 'react';

import { ICard } from './ICard';

import { CardWrapper } from './Card.styles';

const Card = ({ className, children, onClick, ...restProps }: ICard) => {
  return (
    <CardWrapper className={className} onClick={onClick} {...restProps}>
      {children}
    </CardWrapper>
  );
};

export default Card;
