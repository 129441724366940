export const actionTypes = {
  POST_VEHICLE_SHARE_START: 'POST_VEHICLE_SHARE_START',
  POST_VEHICLE_SHARE_SUCCESS: 'POST_VEHICLE_SHARE_SUCCESS',
  POST_VEHICLE_SHARE_ERROR: 'POST_VEHICLE_SHARE_ERROR',

  POST_POST_SHARE_START: 'POST_POST_SHARE_START',
  POST_POST_SHARE_SUCCESS: 'POST_POST_SHARE_SUCCESS',
  POST_POST_SHARE_ERROR: 'POST_POST_SHARE_ERROR',

  POST_POST_VIEWED_START: 'POST_POST_VIEWED_START',
  POST_POST_VIEWED_SUCCESS: 'POST_POST_VIEWED_SUCCESS',
  POST_POST_VIEWED_ERROR: 'POST_POST_VIEWED_ERROR',

  POST_EVENT_SHARE_START: 'POST_EVENT_SHARE_START',
  POST_EVENT_SHARE_SUCCESS: 'POST_EVENT_SHARE_SUCCESS',
  POST_EVENT_SHARE_ERROR: 'POST_EVENT_SHARE_ERROR',

  POST_USER_SHARE_START: 'POST_USER_SHARE_START',
  POST_USER_SHARE_SUCCESS: 'POST_USER_SHARE_SUCCESS',
  POST_USER_SHARE_ERROR: 'POST_USER_SHARE_ERROR',
};

// POST VEHICLE SHARE ACTIONS
export const postVehicleShareStart = (data) => ({
  type: actionTypes.POST_VEHICLE_SHARE_START,
  data,
});

export const postVehicleShareSuccess = () => ({
  type: actionTypes.POST_VEHICLE_SHARE_SUCCESS,
});

export const postVehicleShareError = (error) => ({
  type: actionTypes.POST_VEHICLE_SHARE_ERROR,
  error,
});

// POST POST SHARE ACTIONS
export const postPostShareStart = (data) => ({
  type: actionTypes.POST_POST_SHARE_START,
  data,
});

export const postPostShareSuccess = () => ({
  type: actionTypes.POST_POST_SHARE_SUCCESS,
});

export const postPostShareError = (error) => ({
  type: actionTypes.POST_POST_SHARE_ERROR,
  error,
});

// POST POST VIEWED ACTIONS
export const postPostViewedStart = (data) => ({
  type: actionTypes.POST_POST_VIEWED_START,
  data,
});

export const postPostViewedSuccess = (data) => ({
  type: actionTypes.POST_POST_VIEWED_SUCCESS,
  data,
});

export const postPostViewedError = (error) => ({
  type: actionTypes.POST_POST_VIEWED_ERROR,
  error,
});

// POST EVENT SHARE ACTIONS
export const postEventShareStart = (data) => ({
  type: actionTypes.POST_EVENT_SHARE_START,
  data,
});

export const postEventShareSuccess = () => ({
  type: actionTypes.POST_EVENT_SHARE_SUCCESS,
});

export const postEventShareError = (error) => ({
  type: actionTypes.POST_EVENT_SHARE_ERROR,
  error,
});

// POST USER SHARE ACTIONS
export const postUserShareStart = (data) => ({
  type: actionTypes.POST_USER_SHARE_START,
  data,
});

export const postUserShareSuccess = () => ({
  type: actionTypes.POST_USER_SHARE_SUCCESS,
});

export const postUserShareError = (error) => ({
  type: actionTypes.POST_USER_SHARE_ERROR,
  error,
});
