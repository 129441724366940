import Image from 'components/common/Image';
import imageResize from 'utils/imageResize';
import { CheckMarkIcon, EmptyGroupIcon } from 'public/assets/icons';
import { useWindowSize } from 'utils/hooks';
import { IAttendVehicleGarageCard } from './IAttendVehicleGarageCard';

import {
  VehicleImgWrap,
  CheckIcon,
  GradientWrap,
  EmptyUserPhoto,
} from './AttendVehicleGarageCard.styles';

const AttendVehicleGarageCard = ({
  image,
  make,
  model,
  onClick,
  checked,
}: IAttendVehicleGarageCard) => {
  const { width } = useWindowSize();

  return (
    <VehicleImgWrap onClick={onClick} checked={checked}>
      {image?.trim() ? (
        <Image
          src={imageResize(image)}
          alt="garage vehicle img"
          width={width < 410 ? width - 40 : 172}
          height={width < 410 ? 150 : 120}
          objectFit="cover"
          unoptimized
          priority
        />
      ) : (
        <EmptyUserPhoto>
          <EmptyGroupIcon />
        </EmptyUserPhoto>
      )}
      <GradientWrap>
        <div>
          <span>{`${make}  ${model}`}</span>
        </div>
      </GradientWrap>
      <CheckIcon checked={checked}>{checked && <CheckMarkIcon />}</CheckIcon>
      <CheckMarkIcon />
    </VehicleImgWrap>
  );
};

export default AttendVehicleGarageCard;
