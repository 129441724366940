import Skeleton from 'components/common/Skeleton';
import {
  EventCardSkeletonContent,
  EventCardSkeletonFooter,
  EventCardSkeletonWrapper,
} from './EventCardSkeleton.styles';

const EventCardSkeleton = () => {
  return (
    <EventCardSkeletonWrapper>
      <Skeleton height={366} bottom={0} />
      <EventCardSkeletonContent>
        <Skeleton height={12} width="40%" bottom={10} borderRadius={2} />
        <Skeleton height={16} width="60%" bottom={10} borderRadius={2} />
        <Skeleton height={26} width="80%" bottom={10} borderRadius={2} />
      </EventCardSkeletonContent>

      <EventCardSkeletonFooter>
        <Skeleton height={12} width="8%" bottom={0} borderRadius={10} />
        <Skeleton height={12} width="8%" bottom={0} borderRadius={10} />
        <Skeleton height={12} width="8%" bottom={0} borderRadius={10} />
        <Skeleton height={12} width="8%" bottom={0} borderRadius={10} />
      </EventCardSkeletonFooter>
    </EventCardSkeletonWrapper>
  );
};

export default EventCardSkeleton;
