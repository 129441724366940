import React from 'react';

import { CrossIcon, CheckMarkIcon, EmptyGroupIcon } from 'public/assets/icons';
import EmptyUserProfilePhoto from 'components/EmptyUserProfilePhoto/EmptyUserProfilePhoto';
import Image from '../Image';
import { IPills } from './IPills';

import {
  PillsWrapper,
  TextWrapper,
  IconWrapper,
  EmptyUserPhoto,
} from './Pills.styles';

const Pills = ({
  type = 'default',
  image = '/',
  size = 'default',
  bgColor,
  color,
  children,
  onCrossClick,
  onSelectItemClick,
  isSelected = false,
  disabled = false,
  isImage = false,
  isCross = false,
  className,
  variant,
}: IPills) => {
  if (type === 'addTags' && isImage && !image) {
    throw new Error('image required when a type is addTags');
  }

  const renderPillsByType = (pillsType) => {
    switch (pillsType) {
      case 'addTags':
        return (
          <>
            {isImage && image?.trim() ? (
              <Image
                src={image}
                alt="image"
                height={26}
                width={26}
                unoptimized
                priority
              />
            ) : (
              <>
                {(variant === 'group' && (
                  <EmptyUserPhoto>
                    <EmptyGroupIcon />
                  </EmptyUserPhoto>
                )) ||
                  (variant === 'profile' && (
                    <EmptyUserProfilePhoto wrapSize={28} userIconSize={14} />
                  ))}
              </>
            )}
            <TextWrapper color={color}>{children}</TextWrapper>
            {isCross && (
              <IconWrapper onClick={onCrossClick}>
                <CrossIcon height="10" width="10" />
              </IconWrapper>
            )}
          </>
        );
      case 'selectTags':
        return (
          <>
            {isSelected && (
              <IconWrapper type={type}>
                <CheckMarkIcon />
              </IconWrapper>
            )}
            <TextWrapper>{children}</TextWrapper>
          </>
        );

      default:
        return <>{children}</>;
    }
  };

  return (
    <PillsWrapper
      type={type}
      size={size}
      bgColor={bgColor}
      color={color}
      isSelected={isSelected}
      onClick={onSelectItemClick}
      disabled={disabled}
      className={className}
      isImage={isImage}
    >
      {renderPillsByType(type)}
    </PillsWrapper>
  );
};

export default Pills;
