export const actionTypes = {
  SIGN_UP_ERROR: 'SIGN_UP_ERROR',
  SIGN_UP_START: 'SIGN_UP_START',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  SET_SIGN_UP_STATUS: 'SET_SIGN_UP_STATUS',
};

// SIGN UP ACTIONS
export function signUpError(error) {
  return {
    type: actionTypes.SIGN_UP_ERROR,
    error,
  };
}

export function signUpStart(data) {
  return {
    type: actionTypes.SIGN_UP_START,
    data,
  };
}

export function SignUpSuccess(data) {
  return {
    type: actionTypes.SIGN_UP_SUCCESS,
    data,
  };
}

// SET SIGN UP STATUS ACTIONS
export const setSignUpStatus = (data) => ({
  type: actionTypes.SET_SIGN_UP_STATUS,
  data,
});
