import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import { composeValidators, required } from 'utils/validators';
import FieldElect from 'components/common/FieldElect';
import fetchApi from 'hooks/fetchApi';
import API_NAME from 'services/api';

import { SearchIcon, WheelIcon } from 'public/assets/icons';
import { useField } from 'react-final-form';
import {
  FormRenderPills,
  ItemForSelectText,
  ItemForSelectValue,
  ItemForSelectWrapper,
  RenderPills,
  SelectedPills,
  SelectedItemWrapper,
  SelectedItemText,
  SelectedValues,
  NoResultWrap,
  NoResultText,
  FormSearch,
  FormSearchWrapper,
} from './VehicleForm.styles';

const search = (data: any[], searchValue: string) => {
  const searchValueLowerCase = searchValue?.toLocaleLowerCase();

  return data?.filter((elem) =>
    elem?.name?.toLowerCase()?.includes(searchValueLowerCase)
  );
};

const Make = () => {
  const { t } = useTranslation();
  const theme: any = useTheme();
  const searchValue = useField('search').input.value;

  const getCarBrands = useSelector((store) => store.fetchData);

  const { action, loading } = fetchApi();

  const fetchData = useCallback(() => {
    action(API_NAME.CAR_GET_CAR_BRANDS, {});
  }, []);

  useEffect(() => {
    if (!(getCarBrands[API_NAME.CAR_GET_CAR_BRANDS]?.data?.length > 0)) {
      fetchData();
    }
  }, []);

  return (
    <FieldElect
      name="make"
      label={t('addVehicle:make')}
      headerText="My brands"
      multipleSelect={false}
      render={(b, onRemove) =>
        b?.length > 0 && (
          <RenderPills
            key={b}
            onCrossClick={() => onRemove(b)}
            bgColor={theme.colors.neutral.n0}
            type="addTags"
            isCross
          >
            {b}
          </RenderPills>
        )
      }
      formRender={(value, onChange) => {
        return (
          <>
            <FormSearchWrapper>
              <FormSearch
                name="search"
                prefix={<SearchIcon colorStroke={theme.colors.neutral.n6} />}
              />
            </FormSearchWrapper>
            {value?.length > 0 && (
              <SelectedItemWrapper>
                <SelectedItemText>{t('common:selected')}</SelectedItemText>
                <SelectedValues>
                  {value && (
                    <SelectedPills
                      key={value}
                      onCrossClick={() => onChange()}
                      bgColor={theme.colors.blue}
                      color={theme.colors.white}
                      type="addTags"
                      isCross
                    >
                      {value}
                    </SelectedPills>
                  )}
                </SelectedValues>
              </SelectedItemWrapper>
            )}

            <ItemForSelectWrapper>
              <ItemForSelectText>
                {t('addVehicle:selectYours')}
              </ItemForSelectText>
              <ItemForSelectValue>
                {getCarBrands[API_NAME.CAR_GET_CAR_BRANDS]?.data?.length > 0 ? (
                  search(
                    getCarBrands[API_NAME.CAR_GET_CAR_BRANDS]?.data,
                    searchValue
                  )?.map((s, i) => {
                    return (
                      <FormRenderPills
                        key={s?._id || i}
                        onSelectItemClick={() => onChange(s?.name)}
                        bgColor={
                          s?.name === value
                            ? theme.colors.blue
                            : theme.colors.neutral.n0
                        }
                        color={
                          s?.name === value
                            ? theme.colors.white
                            : theme.colors.neutral.text
                        }
                        type="addTags"
                      >
                        {s?.name}
                      </FormRenderPills>
                    );
                  })
                ) : (
                  <NoResultWrap>
                    <NoResultText>{t('chat:nothingFound')}</NoResultText>
                    <WheelIcon />
                  </NoResultWrap>
                )}
              </ItemForSelectValue>
            </ItemForSelectWrapper>
          </>
        );
      }}
      validate={composeValidators(required(t('error:thisFieldIsRequired')))}
    />
  );
};

export default Make;
