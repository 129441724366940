import React from 'react';
import { style } from 'utils/constants/style';

export const CreateFirstVehicleIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1826 4.52747C11.5313 4.49196 10.8719 4.64753 10.2915 4.99421L4.3181 8.56243C3.7138 8.92341 3.34375 9.5755 3.34375 10.2794V19.3268C3.34375 20.4313 4.23918 21.3268 5.34375 21.3268H7.24079C7.27124 21.331 7.30236 21.3332 7.33398 21.3332H16.6673C16.6989 21.3332 16.7301 21.331 16.7605 21.3268H18.6581C19.7627 21.3268 20.6581 20.4313 20.6581 19.3268V11.3808C20.239 11.5882 19.7922 11.748 19.3248 11.8533V19.3268C19.3248 19.695 19.0263 19.9934 18.6581 19.9934H17.334V17.3332V14.6666V13.9999C17.334 12.8953 16.4386 11.9999 15.334 11.9999H8.66732C7.56275 11.9999 6.66732 12.8953 6.66732 13.9999V14.6666V17.3332V19.9934H5.34375C4.97556 19.9934 4.67708 19.6949 4.67708 19.3268V10.2794C4.67708 10.0448 4.80043 9.82741 5.00187 9.70709L10.9753 6.13887C11.2915 5.94994 11.647 5.8556 12.0023 5.85586C12.0131 5.39857 12.0751 4.95394 12.1826 4.52747ZM16.0007 15.3332V16.6666H8.00065V15.3332H16.0007ZM16.0007 17.9999H8.00065V19.9934H16.0007V17.9999ZM16.0007 13.9999L8.00065 13.9999C8.00065 13.6317 8.29913 13.3332 8.66732 13.3332H15.334C15.7022 13.3332 16.0007 13.6317 16.0007 13.9999Z"
      fill={style.mainBlue.blue}
    />
    <g clipPath="url(#clip0_6074_93690)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0003 2.66669C17.6321 2.66669 17.3337 2.96516 17.3337 3.33335V5.33335H15.3337C14.9655 5.33335 14.667 5.63183 14.667 6.00002C14.667 6.36821 14.9655 6.66669 15.3337 6.66669H17.3337V8.66669C17.3337 9.03488 17.6321 9.33335 18.0003 9.33335C18.3685 9.33335 18.667 9.03488 18.667 8.66669V6.66669H20.667C21.0352 6.66669 21.3337 6.36821 21.3337 6.00002C21.3337 5.63183 21.0352 5.33335 20.667 5.33335H18.667V3.33335C18.667 2.96516 18.3685 2.66669 18.0003 2.66669Z"
        fill={style.mainBlue.blue}
      />
    </g>
    <defs>
      <clipPath id="clip0_6074_93690">
        <rect
          width="6.66667"
          height="6.66667"
          fill={style.mainWhite.white}
          transform="translate(14.667 2.66666)"
        />
      </clipPath>
    </defs>
  </svg>
);
