import styled from 'styled-components';

import { deviceMaxWidth } from 'utils/constants/style';

interface IProps {
  width?: number;
  fullWidth?: boolean;
  height?: number;
  mobileWrapHeight?: number;
}

export const NoResultsWrap = styled.div<IProps>`
  width: ${({ width, fullWidth }) => {
    if (fullWidth) {
      return '100%';
    }

    return width ? `${width}px` : '550px';
  }};
  height: ${({ height }) => `${height}px`};
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (${deviceMaxWidth.sm}) {
    width: 100%;

    ${({ mobileWrapHeight }) =>
      mobileWrapHeight && `height: ${mobileWrapHeight}px`};
  }
`;

// REDIRECT LOADING STYLES
export const ScreenWrap = styled.div`
  position: fixed;
  padding-top: 25px;
  box-sizing: borer-box;
  height: calc(100vh - 56px);
  width: 100%;
  z-index: 99;
  background: #f6f6f6;

  body {
    overflow: hidden;
  }
`;

export const RedirectLoadingContainer = styled.div`
  max-width: 860px;
  margin: 0 auto;
  width: 100%;
`;

export const RedirectLoadingWrap = styled.div`
  width: 100%;
  max-width: 550px;
`;
