import React from 'react';
import {
  NavigationControl,
  GeolocateControl,
  FullscreenControl,
} from 'react-map-gl';
import { useSelector } from 'react-redux';

import { MapControlsWrap } from './mapControls.style';

interface IMapControls {
  fullscreenControl?: boolean;
  onGeolocate?: (e: any) => void;
}

export const MapControls = ({
  fullscreenControl,
  onGeolocate,
}: IMapControls) => {
  const {
    sidebarsInfo: {
      isOpenSidebarSavedRoutes,
      isOpenSidebarWhereTo,
      isOpenSidebarPlanRoute,
      isOpenSidebarChoseStartPoint,
      isOpenSidebarChoseWayPoint,
      isOpenSidebarRouteDetail,
      isOpenSidebarChoseEndPoint,
    },
  } = useSelector((state) => state.map);

  return (
    <MapControlsWrap
      isAnyMapSidebarOpen={
        isOpenSidebarSavedRoutes ||
        isOpenSidebarWhereTo ||
        isOpenSidebarPlanRoute ||
        isOpenSidebarChoseStartPoint ||
        isOpenSidebarChoseWayPoint ||
        isOpenSidebarRouteDetail ||
        isOpenSidebarChoseEndPoint
      }
    >
      <NavigationControl className="nav-controll" />
      <GeolocateControl
        className="GeolocateControl"
        positionOptions={{ enableHighAccuracy: true }}
        trackUserLocation
        auto
        onGeolocate={onGeolocate}
      />
      {fullscreenControl && <FullscreenControl className="filterButton" />}
      {/* <LocationButton className="locationButton" /> */}
    </MapControlsWrap>
  );
};
