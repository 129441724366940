import React from 'react';
import { style } from 'utils/constants/style';

export const WelcomeToRoadSTRIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_6074_93599)">
      <path
        d="M15.2745 7.40663H19.3411V2.66663H15.2745C15.1894 2.66663 15.1044 2.66845 15.0195 2.67137V7.41137C15.1044 7.40842 15.1894 7.40663 15.2745 7.40663Z"
        fill={style.mainBlue.blue}
      />
      <path
        d="M11.7431 8.29147C12.4266 7.92597 13.1653 7.66955 13.9261 7.52995V2.78995C13.1653 2.92959 12.4266 3.186 11.7431 3.5515C11.3292 3.77281 10.8916 3.94701 10.4404 4.07239V8.81236C10.8916 8.68698 11.3292 8.51278 11.7431 8.29147Z"
        fill={style.mainBlue.blue}
      />
      <path
        d="M15.0195 8.50628V13.2463C15.1044 13.2428 15.1894 13.2405 15.2745 13.2405H19.3411V8.50052H15.2745C15.1894 8.50052 15.1044 8.50285 15.0195 8.50628Z"
        fill={style.mainBlue.blue}
      />
      <path
        d="M12.2588 9.25628C11.6851 9.56308 11.0723 9.79237 10.4404 9.94098V14.681C11.0723 14.5324 11.6851 14.3031 12.2588 13.9962C12.7833 13.7158 13.346 13.5105 13.9261 13.3852V8.64526C13.346 8.77054 12.7834 8.9758 12.2588 9.25628Z"
        fill={style.mainBlue.blue}
      />
      <path
        d="M8.7268 9.04718C8.93385 9.04718 9.14061 9.03657 9.34635 9.0164V4.27644C9.14061 4.2966 8.93385 4.30721 8.7268 4.30721H5.75393V3.39529H4.66016V21.3331H5.75393V14.881H8.7268C8.93374 14.881 9.14039 14.8715 9.34635 14.8543V10.1143C9.14043 10.1315 8.93374 10.1409 8.7268 10.1409H5.75393V9.04714H8.7268V9.04718Z"
        fill={style.mainBlue.blue}
      />
    </g>
    <defs>
      <clipPath id="clip0_6074_93599">
        <rect
          width="18.6667"
          height="18.6667"
          fill={style.mainWhite.white}
          transform="translate(2.66699 2.66666)"
        />
      </clipPath>
    </defs>
  </svg>
);
