import React from 'react';
import { style } from 'utils/constants/style';

export const ArrowDown = () => (
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.99939 1L4.99939 5L8.99939 1"
      stroke={style.mainBlue.blue}
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
