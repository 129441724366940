import { HYDRATE } from 'next-redux-wrapper';

import { AsyncStatus } from 'utils/types';
import { actionTypes } from './actions';
import { UserReducerProps } from './types';

const initialState: UserReducerProps = {
  interests: null,
  events: null,
  users: null,
  groups: [],
  suggestion: [],
  userDetails: {},

  userPosts: [],
  userPostsLikeIds: [],

  myEvents: [],
  myRoutes: [],
  isRedirectFromMyEvents: false,
  isRedirectFromMyGroups: false,

  editProfileDataError: null,
  addUsernameError: null,

  sideMyEvents: [],
  sideGroups: [],

  userFollowers: null,
  userFollowersTotal: 0,
  userFollowing: null,
  userFollowingTotal: 0,

  userGarageVehicles: [],

  redirectFromUserDetailsData: {
    isRedirectFromUserDetails: false,
    scrollYPosition: 0,
  },

  getInterestsReqStat: AsyncStatus.NONE,
  getEventsReqStat: AsyncStatus.NONE,
  getFollowersReqStat: AsyncStatus.NONE,
  getGroupsReqStat: AsyncStatus.NONE,
  addInterestReqStat: AsyncStatus.NONE,
  editProfileDataReqStat: AsyncStatus.NONE,
  getMyEventsReqStat: AsyncStatus.NONE,
  addUsernameReqStat: AsyncStatus.NONE,
  finishTutorialReqStat: AsyncStatus.NONE,
  getSuggestionReqStat: AsyncStatus.NONE,
  reportUserReqStat: AsyncStatus.NONE,
  addGroupReqStat: AsyncStatus.NONE,
  getUserDetailsReqStat: AsyncStatus.NONE,
  getUserPostsReqStat: AsyncStatus.NONE,
  getSideMyEventsReqStat: AsyncStatus.NONE,
  getSideGroupsReqStat: AsyncStatus.NONE,
  getUserFollowersReqStat: AsyncStatus.NONE,
  getUserFollowingReqStat: AsyncStatus.NONE,
  removeUserFromMyFollowersReqStat: AsyncStatus.NONE,
  getUserGarageVehiclesReqStat: AsyncStatus.NONE,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE: {
      return { ...state, ...action.payload };
    }

    // GET INTERESTS
    case actionTypes.GET_INTERESTS_REQUEST: {
      return { ...state, getInterestsReqStat: AsyncStatus.LOADING };
    }

    case actionTypes.GET_INTERESTS_SUCCESS: {
      return {
        ...state,
        interests: action.data,
        getInterestsReqStat: AsyncStatus.SUCCESS,
      };
    }

    case actionTypes.GET_INTERESTS_ERROR: {
      return {
        ...state,
        getInterestsReqStat: AsyncStatus.ERROR,
      };
    }

    // GET EVENTS
    case actionTypes.GET_EVENTS_REQUEST: {
      return { ...state, getEventsReqStat: AsyncStatus.LOADING };
    }

    case actionTypes.GET_EVENTS_SUCCESS: {
      return {
        ...state,
        events: action.data,
        getEventsReqStat: AsyncStatus.SUCCESS,
      };
    }

    case actionTypes.GET_EVENTS_ERROR: {
      return { ...state, getEventsReqStat: AsyncStatus.ERROR };
    }

    // GET MY EVENTS
    case actionTypes.GET_MY_EVENTS_START:
      return { ...state, getMyEventsReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_MY_EVENTS_SUCCESS:
      return {
        ...state,
        myEvents: [...state.myEvents, ...action.data],
        getMyEventsReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_MY_EVENTS_ERROR:
      return { ...state, getMyEventsReqStat: AsyncStatus.ERROR };

    case actionTypes.CLEAR_MY_EVENTS:
      return { ...state, myEvents: [], getMyEventsReqStat: AsyncStatus.NONE };

    // GET MY ROUTES
    case actionTypes.GET_MY_ROUTES_START:
      return { ...state, getMyRoutesReqStat: AsyncStatus.LOADING };
    case actionTypes.GET_MY_ROUTES_SUCCESS:
      return {
        ...state,
        myRoutes: action.data,
        getMyRoutesReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_MY_ROUTES_ERROR:
      return { ...state, getMyRoutesReqStat: AsyncStatus.ERROR };

    // GET FOLLOWERS
    case actionTypes.GET_FOLLOWERS_REQUEST: {
      return { ...state, getFollowersReqStat: AsyncStatus.LOADING };
    }

    case actionTypes.GET_FOLLOWERS_SUCCESS: {
      return {
        ...state,
        users: action.data,
        getFollowersReqStat: AsyncStatus.ERROR,
      };
    }

    case actionTypes.GET_FOLLOWERS_ERROR: {
      return { ...state, getFollowersReqStat: AsyncStatus.ERROR };
    }

    case actionTypes.CLEAR_FOLLOWERS_DATA: {
      return { ...state, users: null, getFollowersReqStat: AsyncStatus.NONE };
    }

    // GET GROUPS
    case actionTypes.GET_GROUPS_REQUEST: {
      return { ...state, getGroupsReqStat: AsyncStatus.LOADING };
    }

    case actionTypes.GET_GROUPS_SUCCESS: {
      return {
        ...state,
        groups: [...state.groups, ...action.data],
        getGroupsReqStat: AsyncStatus.SUCCESS,
      };
    }

    case actionTypes.GET_GROUPS_ERROR: {
      return { ...state, getGroupsReqStat: AsyncStatus.ERROR };
    }

    // ADD INTEREST
    case actionTypes.ADD_INTEREST_START: {
      return { ...state, addInterestReqStat: AsyncStatus.LOADING };
    }

    case actionTypes.ADD_INTEREST_SUCCESS: {
      return { ...state, addInterestReqStat: AsyncStatus.SUCCESS };
    }

    case actionTypes.ADD_INTEREST_ERROR: {
      return { ...state, addInterestReqStat: AsyncStatus.ERROR };
    }

    // EDIT PROFILE DATA
    case actionTypes.EDIT_PROFILE_DATA_START: {
      return { ...state, editProfileDataReqStat: AsyncStatus.LOADING };
    }

    case actionTypes.EDIT_PROFILE_DATA_SUCCESS: {
      return { ...state, editProfileDataReqStat: AsyncStatus.SUCCESS };
    }

    case actionTypes.EDIT_PROFILE_DATA_ERROR: {
      return {
        ...state,
        editProfileDataReqStat: AsyncStatus.ERROR,
        editProfileDataError: action.error,
      };
    }

    // CLEAR REQUESTS ASYNC STATUS
    case actionTypes.CLEAR_REQUESTS_ASYNC_STATUS: {
      return {
        ...state,
        editProfileDataReqStat: AsyncStatus.NONE,
      };
    }

    // HANDLE REDIRECT FROM MY EVENTS
    case actionTypes.HANDLE_REDIRECT_FROM_MY_EVENTS:
      return { ...state, isRedirectFromMyEvents: action.data };

    // HANDLE REDIRECT FROM MY GROUPS
    case actionTypes.HANDLE_REDIRECT_FROM_MY_GROUPS:
      return { ...state, isRedirectFromMyGroups: action.data };

    // ADD USERNAME
    case actionTypes.ADD_USERNAME_START:
      return { ...state, addUsernameReqStat: AsyncStatus.LOADING };

    case actionTypes.ADD_USERNAME_SUCCESS:
      return { ...state, addUsernameReqStat: AsyncStatus.SUCCESS };

    case actionTypes.ADD_USERNAME_ERROR:
      return {
        ...state,
        addUsernameReqStat: AsyncStatus.ERROR,
        addUsernameError: action.error,
      };

    case actionTypes.CLEAR_ADD_USERNAME_REQ_STATUS:
      return {
        ...state,
        addUsernameReqStat: AsyncStatus.NONE,
        addUsernameError: null,
      };

    // FINISH TUTORIAL
    case actionTypes.FINISH_TUTORIAL_START:
      return { ...state, finishTutorialReqStat: AsyncStatus.LOADING };

    case actionTypes.FINISH_TUTORIAL_SUCCESS:
      return { ...state, finishTutorialReqStat: AsyncStatus.SUCCESS };

    case actionTypes.FINISH_TUTORIAL_ERROR:
      return { ...state, finishTutorialReqStat: AsyncStatus.ERROR };

    // GET SUGGESTION
    case actionTypes.GET_SUGGESTION_START:
      return { ...state, getSuggestionReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_SUGGESTION_SUCCESS:
      return {
        ...state,
        suggestion: action.data,
        getSuggestionReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_SUGGESTION_ERROR:
      return { ...state, getSuggestionReqStat: AsyncStatus.ERROR };

    case actionTypes.CLEAR_SUGGESTIONS:
      return { ...state, suggestion: [] };

    // REPORT USER
    case actionTypes.REPORT_USER_START:
      return { ...state, reportUserReqStat: AsyncStatus.LOADING };

    case actionTypes.REPORT_USER_SUCCESS:
      return { ...state, reportUserReqStat: AsyncStatus.SUCCESS };

    case actionTypes.REPORT_USER_ERROR:
      return { ...state, reportUserReqStat: AsyncStatus.ERROR };

    // ADD GROUP
    case actionTypes.ADD_GROUP_START:
      return { ...state, addGroupReqStat: AsyncStatus.LOADING };

    case actionTypes.ADD_GROUP_SUCCESS:
      return {
        ...state,
        addGroupReqStat: AsyncStatus.SUCCESS,
        groups: [action.data, ...state.groups],
      };

    case actionTypes.ADD_GROUP_ERROR:
      return { ...state, addGroupReqStat: AsyncStatus.ERROR };

    case actionTypes.CLEAR_CREATED_GROUP_STATUS:
      return { ...state, addGroupReqStat: AsyncStatus.NONE };

    // GET USER DETAILS
    case actionTypes.GET_USER_DETAILS_START:
      return { ...state, getUserDetailsReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: action.data,
        getUserDetailsReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_USER_DETAILS_ERROR:
      return { ...state, getUserDetailsReqStat: AsyncStatus.ERROR };

    // GET USER POSTS
    case actionTypes.GET_USER_POSTS_START:
      return { ...state, getUserPostsReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_USER_POSTS_SUCCESS:
      return {
        ...state,
        userPosts: action.data,
        getUserPostsReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_USER_POSTS_ERROR:
      return { ...state, getUserPostsReqStat: AsyncStatus.ERROR };

    // GET SIDE MY EVENTS
    case actionTypes.GET_SIDE_MY_EVENTS_START:
      return { ...state, getSideMyEventsReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_SIDE_MY_EVENTS_SUCCESS:
      return {
        ...state,
        sideMyEvents: action.data,
        getSideMyEventsReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_SIDE_MY_EVENTS_ERROR:
      return { ...state, getSideMyEventsReqStat: AsyncStatus.ERROR };

    // GET SIDE GROUPS
    case actionTypes.GET_SIDE_GROUPS_START:
      return { ...state, getSideGroupsReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_SIDE_GROUPS_SUCCESS:
      return {
        ...state,
        sideGroups: action.data,
        getSideGroupsReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_SIDE_GROUPS_ERROR:
      return { ...state, getSideGroupsReqStat: AsyncStatus.ERROR };

    // GET USER FOLLOWERS
    case actionTypes.GET_USER_FOLLOWERS_START:
      return { ...state, getUserFollowersReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_USER_FOLLOWERS_SUCCESS:
      return {
        ...state,
        userFollowers: action.data?.data,
        userFollowersTotal: action.data?.total,
        getUserFollowersReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_USER_FOLLOWERS_ERROR:
      return { ...state, getUserFollowersReqStat: AsyncStatus.ERROR };

    // GET USER FOLLOWING
    case actionTypes.GET_USER_FOLLOWING_START:
      return { ...state, getUserFollowingReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_USER_FOLLOWING_SUCCESS:
      return {
        ...state,
        userFollowing: action.data?.data,
        userFollowingTotal: action.data?.total,
        getUserFollowingReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_USER_FOLLOWING_ERROR:
      return { ...state, getUserFollowingReqStat: AsyncStatus.ERROR };

    // REMOVE USER FROM MY FOLLOWERS
    case actionTypes.REMOVE_USER_FROM_MY_FOLLOWERS_START:
      return {
        ...state,
        removeUserFromMyFollowersReqStat: AsyncStatus.LOADING,
      };

    case actionTypes.REMOVE_USER_FROM_MY_FOLLOWERS_SUCCESS:
      return {
        ...state,
        removeUserFromMyFollowersReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.REMOVE_USER_FROM_MY_FOLLOWERS_ERROR:
      return { ...state, removeUserFromMyFollowersReqStat: AsyncStatus.ERROR };

    // CLEAR INVOLVED USERS STORE
    case actionTypes.CLEAR_INVOLVED_USERS_STORE:
      return { ...state, [action.data]: null };

    // GET USER GARAGE VEHICLES
    case actionTypes.GET_USER_GARAGE_VEHICLES_START:
      return { ...state, getUserGarageVehiclesReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_USER_GARAGE_VEHICLES_SUCCESS:
      return {
        ...state,
        userGarageVehicles: action.data,
        getUserGarageVehiclesReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_USER_GARAGE_VEHICLES_ERROR:
      return { ...state, getUserGarageVehiclesReqStat: AsyncStatus.ERROR };

    // SET REDIRECT FROM USER DETAILS
    case actionTypes.SET_REDIRECT_FROM_USER_DETAILS:
      return { ...state, redirectFromUserDetailsData: action.data };

    // ADD/REMOVE LIKE TO USER DETAILS POST
    case actionTypes.ADD_LIKE_TO_USER_DETAILS_POST:
      return {
        ...state,
        userPostsLikeIds: [...state.userPostsLikeIds, action.data],
      };

    case actionTypes.REMOVE_LIKE_FROM_USER_DETAILS_POST:
      return {
        ...state,
        userPostsLikeIds: state.userPostsLikeIds.filter(
          (el) => el !== action.data
        ),
      };

    // SET POSTS ON USER DETAILS
    case actionTypes.SET_POSTS_ON_USER_DETAILS:
      return {
        ...state,
        userPosts: action.data.posts,
      };

    default:
      return state;
  }
}

export default reducer;
