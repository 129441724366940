import cookieCutter from 'cookie-cutter';
import Router from 'next/router';
import API_NAME, { generateApi } from 'services/api';
import { routesPath } from './constants/routePath';

type authsData = {
  token: string;
  _id: string;
  tutorialIsActive: boolean;
};

type getAuthsDataType = {
  token: string;
  tsec: any;
  userId: string;
  tutorialIsActive: any;
};

export function getAuthsData(): getAuthsDataType {
  if (typeof window !== 'undefined') {
    const token = localStorage.getItem('token') || undefined;
    const tsec = token !== undefined ? JSON.parse(token) : undefined;
    const getUserId = localStorage.getItem('user_id') || undefined;
    const userId = getUserId !== undefined ? JSON.parse(getUserId) : undefined;
    const tutorialIsActive =
      localStorage.getItem('tutorialIsActive') || undefined;
    return {
      token,
      tsec,
      userId,
      tutorialIsActive,
    };
  }
  return {
    token: undefined,
    tsec: undefined,
    userId: undefined,
    tutorialIsActive: undefined,
  };
}

export async function clearAuthData() {
  try {
    const res = await generateApi(API_NAME.AUTH_LOGIN, {
      params: {
        nameOrEmail: process.env._ANONYMOUS_USERNAME,
      },
      body: {
        password: process.env._ANONYMOUS_PASSWORD,
      },
    });
    Router.push(`${routesPath.authentication}`);
    if (typeof window !== 'undefined') {
      cookieCutter.set('tsec', res?.data?.token, {
        path: '/',
      });
      localStorage.setItem('token', JSON.stringify(res?.data?.token));
      // localStorage.removeItem('token');
      localStorage.setItem('user_id', JSON.stringify(res?.data?._id));
      localStorage.setItem(
        'tutorialIsActive',
        JSON.stringify(res?.data?.tutorialIsActive)
      );
    }
    if (res?.data?._id) {
      const resUser = await generateApi(API_NAME.USER_IDENTITY, {
        params: { userId: res?.data?._id },
      });
      if (typeof window !== 'undefined') {
        localStorage.setItem('user_data', JSON.stringify(resUser));
      }
    }
  } catch (error) {
    console.log(error);
  }
}

async function auths(data: authsData, isAnonimus = false): Promise<void> {
  try {
    if (typeof window !== 'undefined') {
      cookieCutter.set('tsec', data.token, { path: '/' });
      localStorage.setItem('token', JSON.stringify(data.token));
      localStorage.setItem('user_id', JSON.stringify(data._id));
      localStorage.setItem(
        'tutorialIsActive',
        JSON.stringify(data.tutorialIsActive)
      );
      if (!isAnonimus) {
        if (data.tutorialIsActive) {
          Router.push(`${routesPath.tutorial}`);
        } else {
          Router.push(`${routesPath.feedPath}`);
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export default auths;
