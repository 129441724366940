import { HYDRATE } from 'next-redux-wrapper';
import { AsyncStatus } from 'utils/types';
import { actionTypes } from './actions';

const initialState = {
  range: 0,
  units: null,
  privacy: null,
  _id: null,
  error: null,
  email: null,
  changeEmailError: null,
  changePasswordError: null,
  notificationSettings: {
    groupChat: null,
    collaborator: null,
    followers: null,
    roadshare: null,
    event: null,
    group: null,
    reminders: null,
    mentions: null,
    taggedPosts: null,
    commentPost: null,
    likePost: null,
    groupMembers: null,
    nearby: null,
    individualChat: null,
  },
  getSettingsReqStat: AsyncStatus.NONE,
  changeSettingsReqStat: AsyncStatus.NONE,
  changeEmailReqStat: AsyncStatus.NONE,
  changePasswordReqStat: AsyncStatus.NONE,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE: {
      return { ...state, ...action.payload };
    }

    // GET SETTINGS
    case actionTypes.GET_SETTINGS_START: {
      return { ...state, getSettingsReqStat: AsyncStatus.LOADING };
    }

    case actionTypes.GET_SETTINGS_SUCCESS: {
      return {
        ...state,
        ...action.data,
        getSettingsReqStat: AsyncStatus.SUCCESS,
      };
    }

    case actionTypes.GET_SETTINGS_ERROR: {
      return {
        ...state,
        error: action.error,
        getSettingsReqStat: AsyncStatus.ERROR,
      };
    }

    // CHANGE SETTINGS
    case actionTypes.CHANGE_SETTINGS_START: {
      return { ...state, changeSettingsReqStat: AsyncStatus.LOADING };
    }

    case actionTypes.CHANGE_SETTINGS_SUCCESS: {
      return { ...state, changeSettingsReqStat: AsyncStatus.SUCCESS };
    }

    case actionTypes.CHANGE_SETTINGS_ERROR: {
      return {
        ...state,
        error: action.error,
        changeSettingsReqStat: AsyncStatus.ERROR,
      };
    }

    // CHANGE EMAIL
    case actionTypes.CHANGE_EMAIL_START: {
      return { ...state, changeEmailReqStat: AsyncStatus.LOADING };
    }

    case actionTypes.CHANGE_EMAIL_SUCCESS: {
      return { ...state, changeEmailReqStat: AsyncStatus.SUCCESS };
    }

    case actionTypes.CHANGE_EMAIL_ERROR: {
      return {
        ...state,
        changeEmailError: action.error,
        changeEmailReqStat: AsyncStatus.ERROR,
      };
    }

    // CHANGE PASSWORD
    case actionTypes.CHANGE_PASSWORD_START: {
      return { ...state, changePasswordReqStat: AsyncStatus.LOADING };
    }

    case actionTypes.CHANGE_PASSWORD_SUCCESS: {
      return { ...state, changePasswordReqStat: AsyncStatus.SUCCESS };
    }

    case actionTypes.CHANGE_PASSWORD_ERROR: {
      return {
        ...state,
        changePasswordReqStat: AsyncStatus.ERROR,
        changePasswordError: action.error,
      };
    }

    default:
      return state;
  }
}

export default reducer;
