import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  mt?: number;
  mb?: number;
  color?: string;
  isSelected?: boolean;
  width?: number;
  height?: number;
  top?: number;
  right?: number;
}

export const ContentWrapper = styled.div`
  border-bottom: 1px dashed #ececec;
  padding-bottom: 24.5px;
  margin-bottom: 24.5px;
`;

export const ContentHeaderText = styled.p<IProps>`
  font-size: 16px;
  line-height: 150%;
  font-weight: 600;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  margin-bottom: 20px;
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
`;

export const SectionHeader = styled.p<IProps>`
  font-size: 15px;
  line-height: 100%;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: ${({ color }) => color || style.mainBlack.black};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
`;

export const TagImageWrap = styled.div`
  height: 26px;
  width: 26px;
  border-radius: 50%;
  overflow: hidden;
`;

export const GroupLocalityWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const GroupLocalityItemWrap = styled.div<IProps>`
  width: 32%;
  height: 48px;
  padding: 8px 0;
  box-sizing: border-box;
  border: ${({ isSelected }) =>
    isSelected ? 'none' : `1px solid ${style.mainGray.gray}`};
  border-radius: 4px;
  background-color: ${({ isSelected }) =>
    isSelected ? style.mainBlue.blue : 'transparent'};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    color: ${({ isSelected }) =>
      isSelected ? style.mainWhite.white : style.mainGray.spunPearl};
  }
`;

export const GroupLocalityItemText = styled.p`
  font-size: 11px;
  line-height: 12px;
  font-weight: 500;
  letter-spacing: 0.02em;
  margin-top: 4px;
`;

// GROUP SETTING STYLES
export const GroupSettingWrap = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 16px;
`;

export const GroupSettingText = styled.p`
  font-size: 13px;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.charcoalColor};
  width: 74%;
`;

export const GroupSettingsIconWrap = styled.div<IProps>`
  position: absolute;
  width: ${({ width }) => (width ? `${width}px` : '24px')};
  height: ${({ height }) => (height ? `${height}px` : '24px')};
  top: ${({ top }) => (top ? `${top}px` : '36px')};
  right: ${({ right }) => (right ? `${right}px` : '0')};
  cursor: pointer;
`;

// GROUP SETTING RADIO STYLES
export const GroupSettingRadioWrap = styled.div<IProps>`
  width: 100%;
  box-sizing: border-box;
  padding: 8px 16px;
  border-radius: 6px;
  border: 1px solid ${({ isSelected }) => (isSelected ? '#2693FF' : '#ECECEC')};
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-top: 16px;
`;

export const GroupSettingRadioBodyWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 84%;
`;

export const GroupSettingRadioHeader = styled.p`
  font-size: 14px;
  line-height: 150%;
  font-weight: 600;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
`;

export const GroupSettingRadioBodyText = styled.p`
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: ${style.mainGray.SuvaGrey};
`;

export const RadioWrapper = styled.div<IProps>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${({ isSelected }) =>
    isSelected ? 'none' : `2px solid ${style.mainBlue.blue}`};
  background-color: ${({ isSelected }) =>
    isSelected ? style.mainBlue.blue : 'transparent'};
`;

export const LocationText = styled.p`
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  margin: 14px 0 31px 8px;
`;
