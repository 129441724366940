import styled from 'styled-components';

import { style, deviceMaxWidth } from 'utils/constants/style';

interface IProps {
  padding?: string;
  headerBorder?: boolean;
  rotate?: number;
  isChat?: boolean;
  isTop?: boolean;
}

export const Header = styled.div<IProps>`
  max-width: 404px;
  width: 100%;
  height: 48px;
  padding: ${({ padding }) => padding || '11px 20px'};
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border-bottom: ${({ headerBorder }) =>
    headerBorder ? `1px solid ${style.mainGray.gainsboroColor}` : 'none'};
  position: fixed;
  background-color: ${style.mainWhite.white};
  z-index: 99;

  @media only screen and (${deviceMaxWidth.sm}) {
    max-width: 100%;
    position: ${({ isTop }) => (isTop ? 'fixed' : 'relative')};
  }
`;

export const HeaderTextWithIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const HeaderIconWrapper = styled.div<IProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  transform: ${({ rotate }) => (rotate ? `rotate(${rotate}deg)` : 'none')};
`;

export const HeaderText = styled.h3`
  font-size: 15px;
  line-height: 150%;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  margin-left: 6px;
`;

export const ContentWrapper = styled.div<IProps>`
  margin-top: 48px;
  padding: ${({ padding }) => padding || '20px'};
  position: relative;

  ${({ isChat }) =>
    isChat &&
    `
    display: flex;
    flex-grow: 1;
  `}
  @media only screen and (${deviceMaxWidth.sm}) {
    margin-top: ${({ isTop }) => (isTop ? '48px' : 0)};
    margin-bottom: ${({ isTop }) => (isTop ? 0 : '48px')};
  }

  // @media (max-width: 767px) {
  //   margin-top: 60px;
  // }
`;
