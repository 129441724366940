import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  isMe?: boolean;
  img?: string;
  height?: number;
  width?: number;
  mr?: number;
  isGeneralInfo?: boolean;
}

export const CollaboratorsSliderComponentWrap = styled.div<IProps>`
  width: 100%;
  box-sizing: border-box;

  .react-multi-carousel-list {
    padding: 15px 10px 10px 10px;
  }
`;

export const ArrowNext = styled.div<IProps>`
  position: absolute;
  right: ${({ isGeneralInfo }) => (isGeneralInfo ? '8px' : '2px')};
  top: 50%;
  margin-top: -16px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  margin-left: 10px;

  z-index: 50;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: ${style.mainBlue.blue};
  svg {
    position: relative;
    left: 1px;
  }
`;

export const ArrowPrev = styled.div<IProps>`
  position: absolute;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  top: 50%;
  margin-top: -16px;
  left: ${({ isGeneralInfo }) => (isGeneralInfo ? '8px' : '2px')};
  z-index: 50;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: ${style.mainBlue.blue};
  transform: rotate(180deg);
  svg {
    position: relative;
    left: 1px;
  }
`;

export const SlideComponent = styled.div<IProps>`
  width: ${({ width }) => (width ? `${width}px` : '120px')};
  height: ${({ height }) => (height ? `${height}px` : '84px')};
  overflow: hidden;
  background: url(${({ img }) => img}) no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  cursor: pointer;
  border-radius: 4px;
`;

export const GradientBlock = styled.div<IProps>`
  width: 100%;
  height: ${({ height }) => (height ? `${height}px` : '47px')};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 0 4px 5px 4px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 9.67%, #1c1f29 100%);
`;

export const UserInfoBlock = styled.div`
  display: flex;
  align-items: center;
`;

// export const CollaboratorNameText = styled.p`
//   font-size: 13px;
//   line-height: 120%;
//   letter-spacing: 0.02em;
//   color: ${style.mainWhite.white};
//   text-overflow: ellipsis;
//   overflow: hidden;
//   white-space: nowrap;
// `;

export const CollaboratorWrap = styled.div<IProps>`
  cursor: pointer;
  width: 120px;
  height: 100px;
  position: relative;
  border-radius: 4px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  background-color: ${style.mainWhite.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
`;

export const CollaboratorImg = styled.div<IProps>`
  width: 36px;
  min-width: 36px;
  height: 36px;
  border-radius: 50%;
  box-sizing: border-box;
  overflow: hidden;
  border: 2px solid ${style.mainWhite.white};
  position: absolute;
  top: 36px;
  left: 50%;
  margin-left: -18px;
  background: url(${({ img }) => img}) no-repeat;
  background-size: cover;
  background-position: center;
`;

export const EmptyCollaboratorImgWrap = styled.div`
  position: absolute;
  top: 36px;
  left: 50%;
  margin-left: -18px;
`;

export const CollaboratorNameText = styled.p`
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 10px;
  text-align: center;
  margin-top: 8px;
`;

export const CoverImgWrap = styled.div<IProps>`
  width: 100%;
  height: 64px;
  background: url(${({ img }) => img}) no-repeat;
  background-size: cover;
  background-position: center;
`;
