import styled from 'styled-components';

import { style } from 'utils/constants/style';

export interface IProps {
  isCollapsed?: boolean;
  mb?: number;
  mt?: number;
  pt?: number;
  pb?: number;
  pl?: number;
  isContactInfoExists?: boolean;
  width?: number;
  img?: string;
  adminNumber?: number;
}

export const DescriptionWrap = styled.div<IProps>`
  width: 100%;
  height: auto;
  min-height: ${({ isCollapsed }) => (isCollapsed ? '110px' : '70px')};
  max-height: ${({ isCollapsed }) => (isCollapsed ? `2000px` : '167px')};
  transition-duration: 1s;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ isCollapsed }) => (isCollapsed ? '45px' : '20px')};

  align-items: center;
  position: relative;
`;

export const GeneralInfo = styled.div<IProps>`
  width: 94%;
  padding-bottom: ${({ pb }) => (pb ? `${pb}px` : '0')};
  border-bottom: ${(props) =>
    props.isCollapsed ? '1px solid #e3e3e3' : 'none'};
`;

export const SeeMoreButtonWrap = styled.div<IProps>`
  width: 100%;
  font-size: 14px;
  line-height: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0;
  padding-bottom: 13px;
  background: ${style.mainWhite.white};
`;

export const SeeMoreButton = styled.div<IProps>`
  min-width: 111px;
  padding: 0 12px;
  box-sizing: border-box;
  height: 30px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  color: ${style.mainBlue.blue};
  letter-spacing: 0.02em;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  background: #e8f3ff;
  border-radius: 30px;
  box-shadow: 1em 0 1em white, -1em 0 1em white;

  div {
    margin-right: 4px;
  }
`;

export const HeaderText = styled.div<IProps>`
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  width: 100%;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  padding-top: 16px;
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
`;

export const GeneralInfoDescription = styled.div<IProps>`
  height: ${(props) => (props.isCollapsed ? 'auto' : 'auto')};
  overflow: hidden;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: ${style.mainBlack.black};
`;
export const BottomLine = styled.div<IProps>`
  display: flex;
  width: 100%;
  padding-top: ${({ pt }) => (pt ? `${pt}px` : '0')};
  border-bottom: 1px solid #e3e3e3;
`;
export const contactBlock = styled.div`
  height: 150px;
`;

export const ContactInfoBlock = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  height: auto;
  width: 94%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  margin-top: ${({ isCollapsed, isContactInfoExists }) =>
    isCollapsed && isContactInfoExists ? '16px' : '0'};
  transition: 1s, height 2s;
  opacity: ${(props) => (props.isCollapsed ? '1' : '0')};
  height: ${(props) => (props.isCollapsed ? 'auto' : '0')};
  visibility: ${(props) => (props.isCollapsed ? 'visible' : 'hidden')};
`;

export const MyInterestBlock = styled.div<IProps>`
  width: 94%;
  transition: 1s, height 2s;
  opacity: ${(props) => (props.isCollapsed ? '1' : '0')};
  height: ${(props) => (props.isCollapsed ? 'auto' : '0')};
  visibility: ${(props) => (props.isCollapsed ? 'visible' : 'hidden')};
  flex-direction: column;

  p {
    position: relative;

    margin-bottom: 20px;
  }
`;
export const PillWrap = styled.div`
  width: 100%;
  margin-top: 8px;
`;

export const PillWrapBrands = styled.div`
  margin-top: 8px;
  padding-bottom: 8px;
`;
export const MyBrands = styled.div<IProps>`
  width: 94%;
  flex-direction: column;
  position: relative;
  transition: 1s, height 2s;
  opacity: ${(props) => (props.isCollapsed ? '1' : '0')};
  height: ${(props) => (props.isCollapsed ? 'auto' : '0')};
  visibility: ${(props) => (props.isCollapsed ? 'visible' : 'hidden')};
`;
export const ArrowWrap = styled.div<IProps>`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: ${(props) =>
    props.isCollapsed ? 'rotate(180deg)' : 'rotate(0deg)'};
  transition-duration: 1s;
`;

export const MyCollaborators = styled.div<IProps>`
  width: 94%;
  margin-bottom: 16px;
  transition: 1s, height 2s;
  opacity: ${(props) => (props.isCollapsed ? '1' : '0')};
  height: ${(props) => (props.isCollapsed ? 'auto' : '0')};
  visibility: ${(props) => (props.isCollapsed ? 'visible' : 'hidden')};
`;

export const ContactInfoItemWrap = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
`;

export const ContactInfoItemText = styled.p`
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  margin-left: 12px;
  margin-top: 5px;
`;

export const ContentInfoItemIconWrap = styled.div`
  width: 24px;
  height: 24px;
  min-width: 24px;
`;

export const LinkWrap = styled.a`
  text-decoration: none;
  color: ${style.mainBlack.black};
  cursor: pointer;
  display: inline-block;
`;

export const GroupAdminsPill = styled.div<IProps>`
  height: 40px;
  display: inline-flex;
  align-items: center;
  padding: 0 8px;
  border-radius: 24px;
  border: 1px solid ${style.mainGray.whisperColor};
  cursor: pointer;
`;

export const GroupAdminsWrap = styled.div<IProps>`
  height: 32px;
  margin-right: 8px;
  position: relative;
  width: ${({ width }) => (width ? `${width}px` : '32px')};
`;

export const GroupAdminItemWrap = styled.div<IProps>`
  width: 32px;
  height: 32px;
  position: absolute;
  left: ${({ adminNumber }) => `${adminNumber * 14}px`};
`;

export const GroupAdminItem = styled.div<IProps>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 2px solid ${style.mainGray.whisperColor};
  background: url(${({ img }) => img}) no-repeat;
  background-size: cover;
  background-position: center;
`;

export const GroupAdminsCountText = styled.p`
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
`;
