import React from 'react';

export const PlusInCircleIcon = ({ color = '#C2C2C2' }: { color?: string }) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M7.91675 12.5H16.0834"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0001 8.41699V16.5837"
        stroke={color}
        strokeWidth="1.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="12.0001"
        cy="12.5"
        r="11"
        stroke={color}
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.00012207 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
