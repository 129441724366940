import Card from 'components/common/Card';

import styled from 'styled-components';

export const ProfileCardSkeletonWrapper = styled(Card)`
  height: auto;
  width: 100%;
  max-width: 550px;
  margin-bottom: 16px;
  overflow: hidden;
  position: relative;
  @media (max-width: 861px) {
    display: block;
    margin: 0 auto 16px;
  }
`;

export const ProfileCardSkeletonContent = styled.div`
  padding: 16px 16px 0 16px;
  display: flex;
  width: 100%;
`;

export const ProfileCardSkeletonContentLeft = styled.div`
  position: absolute;
  bottom: 64px;
`;
export const ProfileCardSkeletonContentRight = styled.div`
  margin-left: 94px;
  width: 100%;
`;

export const ProfileCardSkeletonFooter = styled.div`
  padding: 16px;
  display: flex;
  & div {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
`;
