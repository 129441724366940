import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  mb?: number;
  isSelected?: boolean;
}

export const ContentWrap = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(28, 31, 41, 0.3);
  z-index: 110;
`;

export const ModalWrap = styled.div`
  width: 100%;
  max-width: 360px;
  padding: 30px 30px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${style.mainWhite.white};
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  position: relative;
  box-sizing: border-box;
`;

export const HeaderText = styled.h2`
  font-size: 24px;
  line-height: 130%;
  font-weight: 600;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  text-align: center;
`;

export const BodyText = styled.p`
  font-size: 15px;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  margin: 8px 0 16px 0;
  text-align: center;
`;

export const CloseIconWrap = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`;

// REPORT STYLES
export const ReportItemWrap = styled.div<IProps>`
  width: 100%;
  height: 45px;
  padding: 0 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
  border: ${({ isSelected }) =>
    isSelected
      ? `1px solid ${style.mainYellow.yellow}`
      : `1px solid ${style.mainGray.whiteSmokeColor}`};
  cursor: pointer;
`;

export const ReportMessageText = styled(BodyText)`
  margin: 0;
`;

export const CustomRadioWrap = styled.div<IProps>`
  width: 20px;
  height: 20px;
  box-sizing: border-box;
  padding-right: 1.5px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ isSelected }) =>
    isSelected ? style.mainYellow.yellow : 'transparent'};
  border: ${({ isSelected }) =>
    isSelected ? 'none' : `1px solid ${style.mainGray.whiteSmokeColor}`};
`;
