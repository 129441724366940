import { HYDRATE } from 'next-redux-wrapper';
import { AsyncStatus } from 'utils/types';
import { actionTypes } from './actions';

import { VehicleReducerProps } from './types';

const initialState: VehicleReducerProps = {
  carBrands: null,
  carTypes: null,
  carModels: null,
  vehicleInfo: null,
  addedDataByUser: {
    brands: null,
    types: null,
    models: null,
  },

  getCarBrandsReqStat: AsyncStatus.NONE,
  addCarBrandReqStat: AsyncStatus.NONE,
  getCarTypesReqStat: AsyncStatus.NONE,
  addCarTypeReqStat: AsyncStatus.NONE,
  getCarModelsReqStat: AsyncStatus.NONE,
  addCarModelReqStat: AsyncStatus.NONE,
  addVehicleToMyGarageReqStat: AsyncStatus.NONE,
  editMyVehicleReqStat: AsyncStatus.NONE,
  getVehicleInfoReqStat: AsyncStatus.NONE,
  addCarBrandsReqStat: AsyncStatus.NONE,
  addTutorialVehicleReqStat: AsyncStatus.NONE,
  reportVehicleReqStat: AsyncStatus.NONE,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE: {
      return { ...state, ...action.payload };
    }

    // GET CAR BRANDS
    case actionTypes.GET_CAR_BRANDS_START:
      return { ...state, getCarBrandsReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_CAR_BRANDS_SUCCESS:
      return {
        ...state,
        carBrands: action.data,
        getCarBrandsReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_CAR_BRANDS_ERROR:
      return { ...state, getCarBrandsReqStat: AsyncStatus.ERROR };

    // ADD CAR BRANDS
    case actionTypes.ADD_CAR_BRANDS_START:
      return { ...state, addCarBrandsReqStat: AsyncStatus.LOADING };

    case actionTypes.ADD_CAR_BRANDS_SUCCESS:
      return { ...state, addCarBrandsReqStat: AsyncStatus.SUCCESS };

    case actionTypes.ADD_CAR_BRANDS_ERROR:
      return { ...state, addCarBrandsReqStat: AsyncStatus.ERROR };

    // ADD CAR BRAND
    case actionTypes.ADD_CAR_BRAND_START:
      return { ...state, addCarBrandReqStat: AsyncStatus.LOADING };

    case actionTypes.ADD_CAR_BRAND_SUCCESS:
      return {
        ...state,
        addCarBrandReqStat: AsyncStatus.SUCCESS,
        addedDataByUser: { ...state.addedDataByUser, brands: action.data },
      };

    case actionTypes.ADD_CAR_BRAND_ERROR:
      return { ...state, addCarBrandReqStat: AsyncStatus.ERROR };

    // GET CAR TYPES
    case actionTypes.GET_CAR_TYPES_START:
      return { ...state, getCarTypesReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_CAR_TYPES_SUCCESS:
      return {
        ...state,
        carTypes: action.data,
        getCarTypesReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_CAR_TYPES_ERROR:
      return { ...state, getCarTypesReqStat: AsyncStatus.ERROR };

    // ADD CAR TYPE
    case actionTypes.ADD_CAR_TYPE_START:
      return { ...state, addCarTypeReqStat: AsyncStatus.LOADING };

    case actionTypes.ADD_CAR_TYPE_SUCCESS:
      return {
        ...state,
        addCarTypeReqStat: AsyncStatus.SUCCESS,
        addedDataByUser: { ...state.addedDataByUser, types: action.data },
      };

    case actionTypes.ADD_CAR_TYPE_ERROR:
      return { ...state, addCarTypeReqStat: AsyncStatus.ERROR };

    // GET CAR MODELS
    case actionTypes.GET_CAR_MODELS_START:
      return { ...state, getCarModelsReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_CAR_MODELS_SUCCESS:
      return {
        ...state,
        carModels: action.data,
        getCarModelsReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_CAR_MODELS_ERROR:
      return { ...state, getCarModelsReqStat: AsyncStatus.ERROR };

    // ADD CAR MODEL
    case actionTypes.ADD_CAR_MODEL_START:
      return { ...state, addCarModelReqStat: AsyncStatus.LOADING };

    case actionTypes.ADD_CAR_MODEL_SUCCESS:
      return {
        ...state,
        addCarModelReqStat: AsyncStatus.SUCCESS,
        addedDataByUser: { ...state.addedDataByUser, models: action.data },
      };

    case actionTypes.ADD_CAR_MODEL_ERROR:
      return { ...state, addCarModelReqStat: AsyncStatus.ERROR };

    // ADD VEHICLE TO MY GARAGE
    case actionTypes.ADD_VEHICLE_TO_MY_GARAGE_START:
      return { ...state, addVehicleToMyGarageReqStat: AsyncStatus.LOADING };

    case actionTypes.ADD_VEHICLE_TO_MY_GARAGE_SUCCESS:
      return { ...state, addVehicleToMyGarageReqStat: AsyncStatus.SUCCESS };

    case actionTypes.ADD_VEHICLE_TO_MY_GARAGE_ERROR:
      return { ...state, addVehicleToMyGarageReqStat: AsyncStatus.ERROR };

    // EDIT MY VEHICLE
    case actionTypes.EDIT_MY_VEHICLE_START:
      return { ...state, editMyVehicleReqStat: AsyncStatus.LOADING };

    case actionTypes.EDIT_MY_VEHICLE_SUCCESS:
      return { ...state, editMyVehicleReqStat: AsyncStatus.SUCCESS };

    case actionTypes.EDIT_MY_VEHICLE_ERROR:
      return { ...state, editMyVehicleReqStat: AsyncStatus.ERROR };

    // GET VEHICLE INFO
    case actionTypes.GET_VEHICLE_INFO_START:
      return { ...state, getVehicleInfoReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_VEHICLE_INFO_SUCCESS:
      return {
        ...state,
        vehicleInfo: action.data,
        getVehicleInfoReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_VEHICLE_INFO_ERROR:
      return { ...state, getVehicleInfoReqStat: AsyncStatus.ERROR };

    // ADD TUTORIAL VEHICLE
    case actionTypes.ADD_TUTORIAL_VEHICLE_START:
      return { ...state, addTutorialVehicleReqStat: AsyncStatus.LOADING };

    case actionTypes.ADD_TUTORIAL_VEHICLE_SUCCESS:
      return { ...state, addTutorialVehicleReqStat: AsyncStatus.SUCCESS };

    case actionTypes.ADD_TUTORIAL_VEHICLE_ERROR:
      return { ...state, addTutorialVehicleReqStat: AsyncStatus.ERROR };

    // REPORT VEHICLE
    case actionTypes.REPORT_VEHICLE_START:
      return { ...state, reportVehicleReqStat: AsyncStatus.LOADING };

    case actionTypes.REPORT_VEHICLE_SUCCESS:
      return { ...state, reportVehicleReqStat: AsyncStatus.SUCCESS };

    case actionTypes.REPORT_VEHICLE_ERROR:
      return { ...state, reportVehicleReqStat: AsyncStatus.ERROR };

    // CLEAR VEHICLE STORE
    case actionTypes.CLEAR_VEHICLE_STORE:
      return { ...state, addVehicleToMyGarageReqStat: AsyncStatus.NONE };

    default:
      return state;
  }
}

export default reducer;
