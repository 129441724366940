import styled from 'styled-components';

import { deviceMaxWidth } from 'utils/constants/style';

export const Wrapper = styled.div`
  position: fixed;
  width: 404px;
  right: 0;
  top: 49px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 80;
  background: #000000;
  padding: 0 20px;
  box-sizing: border-box;

  img {
    max-height: calc(100vh - 200px);
  }

  @media only screen and (${deviceMaxWidth.sm}) {
    width: 100%;
  }
`;

export const ButtonWrap = styled.div`
  width: 300px;
  position: absolute;
  bottom: 30px;
`;
