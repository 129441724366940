import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  checked: boolean;
}

export const VehicleImgWrap = styled.div<IProps>`
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 12px;
  width: 172px;
  height: 120px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  border: ${({ checked }) => (checked ? '3px solid #f18c09' : 'none')};

  :nth-of-type(odd) {
    margin-right: 16px;
  }

  @media only screen and (max-width: 800px) {
    margin-right: 16px;
  }

  @media only screen and (max-width: 590px) {
    margin-right: 0;

    :nth-of-type(odd) {
      margin-right: 16px;
    }
  }

  @media only screen and (max-width: 410px) {
    margin-right: 0;
    width: 100%;
    height: 150px;

    :nth-of-type(odd) {
      margin-right: 0;
    }
  }
`;

export const GradientWrap = styled.div`
  position: relative;

  div {
    width: 100%;
    height: 45px;
    top: -52px;
    position: absolute;
    z-index: 1;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 9.67%, #1c1f29 100%);
    border-radius: 6px;

    span {
      position: relative;
      top: 15px;
      left: 20px;
      font-family: ${(props) => props.theme.typography.fontFamily.primary};
      font-weight: ${(props) => props.theme.typography.fontWeight.regular};
      font-size: ${(props) => props.theme.typography.fontSize.f2};
      line-height: 100%;
      letter-spacing: 0.02em;
      color: ${(props) => props.theme.colors.white};
    }
  }
`;

export const CheckIcon = styled.div<IProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  top: 6px;
  right: 6px;
  background: ${({ checked, theme }) => (checked ? theme.colors.blue : 'none')};
  border: ${({ checked, theme }) =>
    checked
      ? `2px solid ${theme.colors.blue}`
      : `2px solid ${theme.colors.neutral.n4}`};
`;

export const EmptyUserPhoto = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: ${(props) => props.theme.colors.blueLight};
  display: flex;
  justify-content: center;
  align-items: center;
`;
