import styled from 'styled-components';
import { Form as FinalForm } from 'react-final-form';

import Heading from 'components/common/Typography/Heading';
import DrawerForm from 'forms/DrawerForm';
import Pills from 'components/common/Pills';
import Text from 'components/common/Text';
import Input from 'components/common/Input';

interface IProps {
  pt?: number;
  pb?: number;
  mt?: number;
  mb?: number;
  collapse?: boolean;
}

export const FinalFormWrapper = styled(FinalForm)``;

export const FormWrapper = styled(DrawerForm)``;

export const SectionWrapper = styled.div<IProps>`
  padding-top: 20px;
  padding-bottom: ${({ pb }) => (pb ? `${pb}px` : '16.5px')};
  border-bottom: 1px dashed ${(props) => props.theme.colors.neutral.n4};

  & input {
    &::placeholder {
      color: ${(props) => props.theme.colors.neutral.n7};
      font-weight: ${(props) => props.theme.typography.fontSize.f5};
      font-weight: ${(props) => props.theme.typography.fontWeight.regular};
    }
  }

  :last-of-type {
    border: none;
  }
`;

export const SectionHeader = styled(Heading)`
  font-size: ${(props) => props.theme.typography.fontSize.f7};
  line-height: 130%;
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 13px;
`;

export const SelectedItemWrapper = styled.div``;
export const SelectedItemText = styled(Text)`
  padding: 10px 10px 10px 16px;
  background-color: ${(props) => props.theme.colors.neutral.n0};
  font-size: ${(props) => props.theme.typography.fontSize.f3};
  color: ${(props) => props.theme.colors.neutral.n7};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
`;
export const SelectedValues = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 16px 16px 8px;
`;

export const ItemForSelectWrapper = styled.div``;
export const ItemForSelectText = styled(Text)`
  padding: 10px 10px 10px 16px;
  background-color: ${(props) => props.theme.colors.neutral.n0};
  font-size: ${(props) => props.theme.typography.fontSize.f3};
  color: ${(props) => props.theme.colors.neutral.n7};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
`;

export const ItemForSelectValue = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
`;

export const RenderPills = styled(Pills)`
  padding: 0 8px 0;
`;
export const FormRenderPills = styled(Pills)`
  padding: 0 6px 0;
`;

export const SelectedPills = styled(Pills)`
  padding: 0 8px 0;
  & > span {
    &:last-child {
      & > svg {
        & > path {
          stroke: ${(props) => props.theme.colors.white};
        }
      }
    }
  }
`;

export const NoResultWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
`;

export const NoResultText = styled(Text)`
  font-size: ${(props) => props.theme.typography.fontSize.f3};
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${(props) => props.theme.colors.neutral.n7};
  text-align: center;
  margin-top: 0;
  margin-bottom: 42px;
`;

export const FormSearchWrapper = styled.div`
  padding: 20px;
  & > div {
    & > div {
    }
  }
`;
export const FormSearch = styled(Input)`
  padding: 0;
  padding-left: 35px;
  height: 44px;
`;

export const VehicleAdditionalInfo = styled.div`
  line-height: 130%;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: ${(props) => props.theme.colors.black};
  padding-top: 16px;
  cursor: pointer;

  & > div {
    & > h3 {
      margin: 0;
    }
  }
`;

export const CollapsedButton = styled.div<IProps>`
  width: 16px;
  height: 16px;
  background: ${({ collapse, theme }) =>
    collapse ? theme.colors.blue : theme.colors.neutral.n0};
  border-radius: 50%;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  transform: ${({ collapse }) => (collapse ? `rotate(180deg)` : 'none')}; ;
`;

export const CollapsedBlock = styled.div<IProps>`
  display: ${({ collapse }) => (collapse ? `block` : 'none')};
  height: auto;
  width: auto;
`;

export const InputWrapper = styled.div`
  padding-top: 20px;

  & input {
    &::placeholder {
      color: ${(props) => props.theme.colors.neutral.n7};
      font-weight: ${(props) => props.theme.typography.fontSize.f5};
      font-weight: ${(props) => props.theme.typography.fontWeight.regular};
    }
  }

  & textarea {
    height: 88px;
    width: 100%;
    box-sizing: border-box;
    &::placeholder {
      color: ${(props) => props.theme.colors.neutral.n7};
      font-size: ${(props) => props.theme.typography.fontSize.f5};
      font-weight: ${(props) => props.theme.typography.fontWeight.regular};
    }
  }
`;
