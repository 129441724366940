import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  img?: string;
  left?: number;
  right?: number;
  width?: number;
}

export const MessageContainerLeft = styled.div`
  display: flex;
  width: 100%;

  :hover {
    .chat-message-three-dots {
      display: flex;
    }
  }
`;

export const MessageContainerRight = styled.div<IProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  align-items: flex-end;
  margin-bottom: 6px;

  :hover {
    .chat-message-three-dots {
      display: flex;
    }
  }
`;

export const UserProfilePhoto = styled.span<IProps>`
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 8px;
  ${({ img }) =>
    img &&
    `background: url(${img}) no-repeat;
     background-size: cover; background-position: center;`};
`;

export const MessageWrapLeft = styled.div<IProps>`
  border-radius: 16px;
  /* background-color: ${style.mainGray.whiteSmokeColor}; */
  width: 75%;
  position: relative;
`;

export const MessageWrapRight = styled.div<IProps>`
  max-width: 84%;
  /* background-color: ${style.mainBlue.blue}; */
  border-radius: 16px;
  position: relative;
`;

export const TimeTextLeft = styled.p`
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${style.mainGray.SuvaGrey};
  margin-left: 40px;
  margin-bottom: 10px;
`;

export const TimeTextRight = styled.p`
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${style.mainGray.silver};
  margin-left: 10px;
`;

export const ThreeDotsWrap = styled.div<IProps>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: none;
  align-items: center;
  justify-content: center;
  background-color: ${style.mainGray.snowColor};
  cursor: pointer;
  position: absolute;
  top: 0;
  left: ${({ left }) => (left ? `${left}px` : 'auto')};
  right: ${({ right }) => (right ? `${right}px` : 'auto')};
`;

export const PopOverWrap = styled.div<IProps>`
  position: absolute;
  top: 30px;
  left: ${({ left }) => (left ? `${left}px` : 'auto')};
  right: ${({ right }) => (right ? `${right}px` : 'auto')};
  z-index: 100;
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
`;

export const Image = styled.img`
  max-width: 100%;
  border-radius: 16px;
  cursor: pointer;
`;
