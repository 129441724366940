import styled from 'styled-components';
import 'react-multi-carousel/lib/styles.css';

import { style } from 'utils/constants/style';

interface IProps {
  right?: number;
  left?: number;
  transform?: number;
  width?: any;
}

export const Wrapper = styled.div`
  width: 100%;
  max-width: 550px;
  margin-top: 16px;
  padding-top: 7px;

  @media only screen and (max-width: 860px) {
    margin-top: 0;
    margin-bottom: 16px;
  }
`;

export const HeaderWrap = styled.div`
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;

  @media only screen and (max-width: 550px) {
    padding: 0 16px;
  }
`;

export const SuggestionsListWrap = styled.div`
  width: 100%;
  max-width: 550px;
  position: relative;
`;

export const ArrowWrap = styled.div<IProps>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${style.mainBlue.blue};
  position: absolute;
  top: 50%;
  margin-top: -18px;
  ${({ left }) => left && `left: ${left}px`};
  ${({ right }) => right && `right: ${right}px`};
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: rotate(
    ${({ transform }) => (transform ? `${transform}deg` : '0')}
  );
`;

export const GroupCardWrap = styled.div<IProps>`
  ${({ width }) => (width ? `width: ${width};` : `width: 267px;`)};
`;
