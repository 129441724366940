import React from 'react';

export const EditIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.7871 7.84934L16.1507 4.21294C16.0143 4.07659 15.8293 4 15.6365 4C15.4436 4 15.2587 4.07659 15.1223 4.21294L4.21309 15.1221C4.07669 15.2585 4.00004 15.4434 4 15.6363V19.2727C4 19.4656 4.07662 19.6506 4.21302 19.787C4.34941 19.9234 4.53439 20 4.72728 20H8.36368C8.55655 20 8.74151 19.9233 8.87787 19.7869L19.7871 8.87771C19.9234 8.74132 20 8.55637 20 8.36352C20 8.17067 19.9234 7.98572 19.7871 7.84934ZM8.06258 18.5454H5.45456V15.9374L13.4546 7.93734L16.0627 10.5454L8.06258 18.5454ZM17.091 9.51699L14.483 6.90896L15.6365 5.7555L18.2445 8.36352L17.091 9.51699Z"
      fill="#8D8D8D"
    />
  </svg>
);
