import styled from 'styled-components';

import Text from 'components/common/Text';

export const ViewProfileWrapper = styled.div`
  display: flex;
  padding: 16px 16px 12px;
`;

export const ProfileImage = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 30%;
  @media (max-width: 767px) {
    flex-basis: 15%;
  }

  & > span {
    border-radius: 50%;
    & > img {
      object-fit: cover;
    }
  }
`;

export const ViewProfileContent = styled.div`
  margin-left: 12px;
  word-break: break-word;
  flex-basis: 70%;
`;

export const UserName = styled(Text)`
  color: ${(props) => props.theme.colors.neutral.n12};
  font-size: ${(props) => props.theme.typography.fontSize.f5};
  letter-spacing: 0.5px;
`;

export const FullName = styled(Text)`
  color: ${(props) => props.theme.colors.orange};
  font-size: ${(props) => props.theme.typography.fontSize.f5};
  letter-spacing: 0.5px;
  margin: 2px 0 6px;
`;
