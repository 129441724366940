import styled from 'styled-components';
import { deviceMaxWidth, style } from '../../utils/constants/style';

interface IProps {
  img?: string;
  type?: string;
  isMe?: boolean;
  mb?: number;
  mt?: number;
  ml?: number;
  mr?: number;
  isMobileHidden?: boolean;
  isClickable?: boolean;
  isNearby?: boolean;
}

export const RectangleButtonWrapper = styled.div<IProps>`
  display: flex;
  align-items: center;
  margin-top: 7px;
`;

export const WrapMainButton = styled.div`
  width: 122px;
  p {
    margin: 0;
  }
`;

export const OptionsButtonWrapper = styled.div<IProps>`
  width: 44px;
  margin-left: ${({ ml }) => (ml ? `${ml}px` : '0')};
  height: 40px;

  div {
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  @media only screen and (${deviceMaxWidth.sm}) {
    height: 32px;
  }
`;

export const ActionButtonWrap = styled.div`
  width: 86px;
`;

export const MessageButtonWrap = styled.div`
  width: 44px;
  margin-left: 44px;
  div {
    text-align: center;
    display: flex;
    flex-direction: column;
  }
`;

export const WrapConfiguringPopover = styled.div`
  width: 50px;
  position: relative;
  top: -20px;
  left: -70px;

  @media only screen and (${deviceMaxWidth.sm}) {
    left: -150px;
  }
`;

export const BackgroundImageWrapp = styled.div<IProps>`
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
`;

export const NearbyBlock = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  /* identical to box height, or 16px */
  div {
    padding-left: 4px;
  }
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  letter-spacing: 0.02em;

  /* Interaction-01 */

  color: ${style.mainYellow.yellow};
`;

// NEW STYLES
export const Wrap = styled.div<IProps>`
  width: 550px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.02);
  margin-bottom: 27px;
  box-sizing: border-box;

  @media only screen and (max-width: 550px) {
    width: 100%;
    max-width: 550px;
    padding-left: 16px;
    padding-right: 16px;
    box-sizing: border-box;
  }
`;

export const BackgroundImage = styled.div<IProps>`
  width: 100%;
  height: 366px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: url(${({ img }) => img}) no-repeat;
  background-size: cover;
  background-position: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  @media only screen and (${deviceMaxWidth.sm}) {
    height: 228px;
  }
`;

export const ShadowGradient = styled.div`
  width: 100%;
  height: 120px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 9.67%,
    rgba(28, 31, 41, 0.7) 100%
  );
  display: none;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 0 32px 16px 32px;

  @media only screen and (${deviceMaxWidth.sm}) {
    display: flex;
  }
`;

export const UserInfoWrap = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 8px 16px 16px 16px;
  background-color: ${style.mainWhite.white};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const UserInfo = styled.div`
  width: 100%;
  display: flex;
  position: relative;
`;

export const PhotoWithNearbyWrap = styled.div`
  width: 80px;
  position: absolute;
  top: -16px;
`;

export const UserProfilePhotoWrap = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 3px solid ${style.mainWhite.white};
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.06));
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UserProfilePhoto = styled.span<IProps>`
  display: block;
  width: 77px;
  height: 77px;
  border-radius: 50%;
  background: url(${({ img }) => img}) no-repeat;
  background-size: cover;
  background-position: center;
`;

export const UserDetailsWrap = styled.div<IProps>`
  display: flex;
  flex-direction: column;
  margin-left: 96px;
  min-height: ${({ isNearby }) => (isNearby ? '80px' : '64px')};
`;

export const CentralizeContent = styled.div<IProps>`
  display: flex;
  align-items: center;
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
  margin-left: ${({ ml }) => (ml ? `${ml}px` : '0')};
  margin-right: ${({ mr }) => (mr ? `${mr}px` : '0')};

  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};

  @media only screen and (${deviceMaxWidth.sm}) {
    display: ${({ isMobileHidden }) => (isMobileHidden ? 'none' : 'flex')};
  }
`;

export const UsernameText = styled.p`
  font-size: 18px;
  line-height: 130%;
  letter-spacing: 0.02em;
  font-weight: 600;
  color: ${style.mainBlack.black};
  margin-right: 5px;
`;

export const IconWrapper = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (${deviceMaxWidth.sm}) {
    display: none;
  }
`;

export const Text = styled.p`
  font-size: 15px;
  line-height: 130%;
  letter-spacing: 0.02em;
  color: ${style.mainGray.zambeziColor};
`;

export const CountText = styled.p`
  font-weight: 500;
  color: ${style.mainBlack.black};
  margin-right: 4px;
  font-size: 15px;
  line-height: 130%;
  letter-spacing: 0.02em;
`;

export const FollowingInfoWrap = styled.div`
  display: flex;
  margin-top: 2px;
  flex-wrap: wrap;
`;
