import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  wrapSize?: number;
  margin?: string;
  withBorder?: boolean;
  cursor?: string;
  withShadow?: boolean;
  borderColor?: string;
}

export const Wrapper = styled.div<IProps>`
  width: ${({ wrapSize }) => (wrapSize ? `${wrapSize}px` : '40px')};
  height: ${({ wrapSize }) => (wrapSize ? `${wrapSize}px` : '40px')};
  min-width: ${({ wrapSize }) => (wrapSize ? `${wrapSize}px` : '40px')};
  box-sizing: border-box;
  border-radius: 50%;
  border: ${({ withBorder, borderColor }) =>
    withBorder ? `2px solid ${borderColor}` : 'none'};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${style.mainGray.whiteSmokeColor};
  margin: ${({ margin }) => margin || '0'};
  position: relative;
  cursor: ${({ cursor }) => cursor || 'auto'};
  ${({ withShadow }) =>
    withShadow &&
    `filter: drop-shadow(0.75px 0.75px 3.75px rgba(0, 0, 0, 0.15));`}
`;
