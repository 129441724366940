export const actionTypes = {
  GET_CAR_BRANDS_START: 'GET_CAR_BRANDS_START',
  GET_CAR_BRANDS_SUCCESS: 'GET_CAR_BRANDS_SUCCESS',
  GET_CAR_BRANDS_ERROR: 'GET_CAR_BRANDS_ERROR',
  ADD_CAR_BRAND_START: 'ADD_CAR_BRAND_START',
  ADD_CAR_BRAND_SUCCESS: 'ADD_CAR_BRAND_SUCCESS',
  ADD_CAR_BRAND_ERROR: 'ADD_CAR_BRAND_ERROR',
  GET_CAR_TYPES_START: 'GET_CAR_TYPES_START',
  GET_CAR_TYPES_SUCCESS: 'GET_CAR_TYPES_SUCCESS',
  GET_CAR_TYPES_ERROR: 'GET_CAR_TYPES_ERROR',
  ADD_CAR_TYPE_START: 'ADD_CAR_TYPE_START',
  ADD_CAR_TYPE_SUCCESS: 'ADD_CAR_TYPE_SUCCESS',
  ADD_CAR_TYPE_ERROR: 'ADD_CAR_TYPE_ERROR',
  GET_CAR_MODELS_START: 'GET_CAR_MODELS_START',
  GET_CAR_MODELS_SUCCESS: 'GET_CAR_MODELS_SUCCESS',
  GET_CAR_MODELS_ERROR: 'GET_CAR_MODELS_ERROR',
  ADD_CAR_MODEL_START: 'ADD_CAR_MODEL_START',
  ADD_CAR_MODEL_SUCCESS: 'ADD_CAR_MODEL_SUCCESS',
  ADD_CAR_MODEL_ERROR: 'ADD_CAR_MODEL_ERROR',
  ADD_VEHICLE_TO_MY_GARAGE_START: 'ADD_VEHICLE_TO_MY_GARAGE_START',
  ADD_VEHICLE_TO_MY_GARAGE_SUCCESS: 'ADD_VEHICLE_TO_MY_GARAGE_SUCCESS',
  ADD_VEHICLE_TO_MY_GARAGE_ERROR: 'ADD_VEHICLE_TO_MY_GARAGE_SUCCESS',
  EDIT_MY_VEHICLE_START: 'EDIT_MY_VEHICLE_START',
  EDIT_MY_VEHICLE_SUCCESS: 'EDIT_MY_VEHICLE_SUCCESS',
  EDIT_MY_VEHICLE_ERROR: 'EDIT_MY_VEHICLE_ERROR',
  GET_VEHICLE_INFO_START: 'GET_VEHICLE_INFO_START',
  GET_VEHICLE_INFO_SUCCESS: 'GET_VEHICLE_INFO_SUCCESS',
  GET_VEHICLE_INFO_ERROR: 'GET_VEHICLE_INFO_ERROR',
  CLEAR_VEHICLE_STORE: 'CLEAR_VEHICLE_STORE',
  ADD_CAR_BRANDS_START: 'ADD_CAR_BRANDS_START',
  ADD_CAR_BRANDS_SUCCESS: 'ADD_CAR_BRANDS_SUCCESS',
  ADD_CAR_BRANDS_ERROR: 'ADD_CAR_BRANDS_ERROR',
  ADD_TUTORIAL_VEHICLE_START: 'ADD_TUTORIAL_VEHICLE_START',
  ADD_TUTORIAL_VEHICLE_SUCCESS: 'ADD_TUTORIAL_VEHICLE_SUCCESS',
  ADD_TUTORIAL_VEHICLE_ERROR: 'ADD_TUTORIAL_VEHICLE_ERROR',
  REPORT_VEHICLE_START: 'REPORT_VEHICLE_START',
  REPORT_VEHICLE_SUCCESS: 'REPORT_VEHICLE_SUCCESS',
  REPORT_VEHICLE_ERROR: 'REPORT_VEHICLE_ERROR',
};

// GET CAR BRANDS ACTIONS
export const getCarBrandsStart = () => ({
  type: actionTypes.GET_CAR_BRANDS_START,
});

export const getCarBrandsSuccess = (data) => ({
  type: actionTypes.GET_CAR_BRANDS_SUCCESS,
  data,
});

export const getCarBrandsError = (error) => ({
  type: actionTypes.GET_CAR_BRANDS_ERROR,
  error,
});

// ADD CAR BRANDS ACTIONS
export const addCarBrandsStart = (data) => ({
  type: actionTypes.ADD_CAR_BRANDS_START,
  data,
});

export const addCarBrandsSuccess = () => ({
  type: actionTypes.ADD_CAR_BRANDS_SUCCESS,
});

export const addCarBrandsError = (error) => ({
  type: actionTypes.ADD_CAR_BRANDS_ERROR,
  error,
});

// ADD CAR BRAND ACTIONS
export const addCarBrandStart = (data) => ({
  type: actionTypes.ADD_CAR_BRAND_START,
  data,
});

export const addCarBrandSuccess = (data) => ({
  type: actionTypes.ADD_CAR_BRAND_SUCCESS,
  data,
});

export const addCarBrandError = (error) => ({
  type: actionTypes.ADD_CAR_BRAND_ERROR,
  error,
});

// GET CAR TYPES ACTIONS
export const getCarTypesStart = () => ({
  type: actionTypes.GET_CAR_TYPES_START,
});

export const getCarTypesSuccess = (data) => ({
  type: actionTypes.GET_CAR_TYPES_SUCCESS,
  data,
});

export const getCarTypesError = (error) => ({
  type: actionTypes.GET_CAR_TYPES_ERROR,
  error,
});

// ADD CAR TYPE ACTIONS
export const addCarTypeStart = (data) => ({
  type: actionTypes.ADD_CAR_TYPE_START,
  data,
});

export const addCarTypeSuccess = (data) => ({
  type: actionTypes.ADD_CAR_TYPE_SUCCESS,
  data,
});

export const addCarTypeError = (error) => ({
  type: actionTypes.ADD_CAR_TYPE_ERROR,
  error,
});

// GET CAR MODELS ACTIONS
export const getCarModelsStart = (data) => ({
  type: actionTypes.GET_CAR_MODELS_START,
  data,
});

export const getCarModelsSuccess = (data) => ({
  type: actionTypes.GET_CAR_MODELS_SUCCESS,
  data,
});

export const getCarModelsError = (error) => ({
  type: actionTypes.GET_CAR_MODELS_ERROR,
  error,
});

// ADD CAR MODEL ACTIONS
export const addCarModelStart = (data) => ({
  type: actionTypes.ADD_CAR_MODEL_START,
  data,
});

export const addCarModelSuccess = (data) => ({
  type: actionTypes.ADD_CAR_MODEL_SUCCESS,
  data,
});

export const addCarModelError = (error) => ({
  type: actionTypes.ADD_CAR_MODEL_ERROR,
  error,
});

// ADD VEHICLE TO MY GARAGE ACTIONS
export const addVehicleToMyGarageStart = (data) => ({
  type: actionTypes.ADD_VEHICLE_TO_MY_GARAGE_START,
  data,
});

export const addVehicleToMyGarageSuccess = () => ({
  type: actionTypes.ADD_VEHICLE_TO_MY_GARAGE_SUCCESS,
});

export const addVehicleToMyGarageError = (error) => ({
  type: actionTypes.ADD_VEHICLE_TO_MY_GARAGE_ERROR,
  error,
});

// EDIT MY VEHICLE ACTIONS
export const editMyVehicleStart = (data) => ({
  type: actionTypes.EDIT_MY_VEHICLE_START,
  data,
});

export const editMyVehicleSuccess = () => ({
  type: actionTypes.EDIT_MY_VEHICLE_SUCCESS,
});

export const editMyVehicleError = (error) => ({
  type: actionTypes.EDIT_MY_VEHICLE_ERROR,
  error,
});

// GET VEHICLE INFO
export const getVehicleInfoStart = (data) => ({
  type: actionTypes.GET_VEHICLE_INFO_START,
  data,
});

export const getVehicleInfoSuccess = (data) => ({
  type: actionTypes.GET_VEHICLE_INFO_SUCCESS,
  data,
});

export const getVehicleInfoError = (error) => ({
  type: actionTypes.GET_VEHICLE_INFO_ERROR,
  error,
});

// CLEAR VEHICLE STORE
export const clearVehicleStore = () => ({
  type: actionTypes.CLEAR_VEHICLE_STORE,
});

// ADD TUTORIAL VEHICLE ACTIONS
export const addTutorialVehicleStart = (data) => ({
  type: actionTypes.ADD_TUTORIAL_VEHICLE_START,
  data,
});

export const addTutorialVehicleSuccess = () => ({
  type: actionTypes.ADD_TUTORIAL_VEHICLE_SUCCESS,
});

export const addTutorialVehicleError = (error) => ({
  type: actionTypes.ADD_TUTORIAL_VEHICLE_ERROR,
  error,
});

// REPORT VEHICLE ACTIONS
export const reportVehicleStart = (data) => ({
  type: actionTypes.REPORT_VEHICLE_START,
  data,
});

export const reportVehicleSuccess = () => ({
  type: actionTypes.REPORT_VEHICLE_SUCCESS,
});

export const reportVehicleError = (error) => ({
  type: actionTypes.REPORT_VEHICLE_ERROR,
  error,
});
