import React from 'react';
import { style } from 'utils/constants/style';

export const CurrentLocationIcon = ({
  color = style.mainBlue.dodgerBlue,
}: {
  color?: string;
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="11" y="1" width="2" height="3" rx="1" fill={color} />
    <rect x="11" y="20" width="2" height="3" rx="1" fill={color} />
    <rect x="1" y="11" width="3" height="2" rx="1" fill={color} />
    <rect x="20" y="11" width="3" height="2" rx="1" fill={color} />
    <circle cx="12" cy="12" r="3" fill={color} />
    <circle cx="12" cy="12" r="8" stroke={color} strokeWidth="2" />
  </svg>
);
