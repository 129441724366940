/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { WheelIcon } from 'public/assets/icons';
import { openSidebarCreateEvent } from 'store/sideBarControler/actions';
import appState from 'hooks/appState';
import { APP_STATE } from 'types';

import { NothingHappeningWrap, CentralBlock } from './NothingHappening.style';

export const NothingHappening = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const appStateAction = appState();

  return (
    <NothingHappeningWrap>
      <CentralBlock>
        <div>
          <WheelIcon />
        </div>
        <span style={{ textAlign: 'center' }}>
          {t('feed:feedEventsPlaceholderText')}{' '}
          <span
            onClick={() =>
              appStateAction(APP_STATE.EVENT_CREATE_FORM, {
                isOpen: true,
              })
            }
          >
            {t('common:clickHere')}
          </span>{' '}
          {t('event:toCreateAnEvent')}
        </span>
      </CentralBlock>
    </NothingHappeningWrap>
  );
};
