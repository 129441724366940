import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  mt?: number;
  mb?: number;
}

export const Wrapper = styled.p<IProps>`
  width: 100%;
  font-size: 12px;
  line-height: 100%;
  color: ${style.mainRed.cinnabarColor};
  letter-spacing: 0.02em;
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
  text-align: left;
`;
