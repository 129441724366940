/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ArrowBack } from 'public/assets/icons';
import {
  closeSidebarNewMultipleChat,
  closeSidebarNewChat,
  openSidebarChatConversation,
} from 'store/sideBarControler/actions';
import {
  createMultipleChatStart,
  setSelectedChatId,
  clearSingleChatReqStat,
  getChatMembersStart,
  clearChatMembers,
  editMultipleChatStart,
} from 'store/chat/actions';
import { getCorrectImageBase64Format } from 'utils/getCorrectImageBase64Format';
import { AsyncStatus } from 'utils/types';
import {
  InputField,
  EmptyUserProfilePhoto,
  ImagesUploader,
} from '../../../index';
import { SidebarWrapper } from '../../index';
import {
  InputFieldWrap,
  ParticipantsHeader,
  UserWrap,
  UserPhoto,
  UserInfoWrap,
  UsernameText,
  FullNameText,
  ImageUploaderWrap,
  ChatPhoto,
  ChatNameText,
  ChatSecondNameText,
} from './NewGroupChat.style';
import { INewGroupChat } from './INewGroupChat';

export const NewGroupChat = ({ selectedUsers, chatId }: INewGroupChat) => {
  const [image, setImage] = useState('');
  const [imageSize, setImageSize] = useState(0);
  const [groupName, setGroupName] = useState('');
  const [inputFile, setInputFile] = useState(null);
  const [chatDefaultValues, setChatDefaultValues] = useState({});

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { createdChatInfo, chatMembers, createMultipleChatReqStat } =
    useSelector((state) => state.chat);

  useEffect(() => {
    if (createMultipleChatReqStat === AsyncStatus.SUCCESS) {
      dispatch(setSelectedChatId(createdChatInfo.chat._id));
      dispatch(clearSingleChatReqStat());
      dispatch(openSidebarChatConversation(createdChatInfo.chat));
      dispatch(closeSidebarNewChat());
      dispatch(closeSidebarNewMultipleChat());
    }
  }, [createMultipleChatReqStat]);

  useEffect(() => {
    if (chatId) {
      dispatch(getChatMembersStart(chatId));
    }

    return () => {
      dispatch(clearChatMembers());
    };
  }, []);

  useEffect(() => {
    if (chatMembers) {
      setImage(chatMembers.chat.photoUrl);
      setGroupName(chatMembers.chat.name);
      setChatDefaultValues({
        image: chatMembers.chat.photoUrl,
        groupName: chatMembers.chat.name,
      });
    }
  }, [chatMembers]);

  const onFileInputChange = (img: string, size: number, file) => {
    setImage(img);
    setImageSize(size);
    setInputFile(file);
  };

  const onCreateChat = () => {
    const userIds = selectedUsers.map((el) => el._id);
    const groupImage = getCorrectImageBase64Format(image);

    dispatch(
      createMultipleChatStart({
        userIds,
        title: groupName,
        size: imageSize,
        photoUri: image,
        file: inputFile,
      })
    );
  };

  const onEditMultipleChat = () => {
    dispatch(
      editMultipleChatStart({
        title: groupName,
        size: imageSize,
        chatId: chatMembers.chat._id,
        photoUri: image,
        file: inputFile,
      })
    );
  };

  const getSubmitAvailability = () => {
    if (selectedUsers) {
      return Boolean(groupName.length && image?.length);
    }

    const chatDataForEdit = { image, groupName };

    if (JSON.stringify(chatDataForEdit) !== JSON.stringify(chatDefaultValues)) {
      return true;
    }

    return false;
  };

  const usersList = (selectedUsers || chatMembers?.members)?.map((user) => (
    <UserWrap key={user._id}>
      {user.profilePhoto && user.profilePhoto.trim() ? (
        <UserPhoto
          img={user.profilePhoto}
          mr={10}
          role="img"
          aria-label="user profile img"
        />
      ) : (
        <EmptyUserProfilePhoto
          wrapSize={48}
          userIconSize={24}
          margin="0 10px 0 0"
        />
      )}
      <UserInfoWrap>
        <UsernameText mb={4}>{user.username}</UsernameText>
        <FullNameText>{user.fullName}</FullNameText>
      </UserInfoWrap>
    </UserWrap>
  ));

  return (
    <SidebarWrapper
      headerText={t('addNewGroup:groupChat')}
      headerIcon={<ArrowBack />}
      submitText={`${
        selectedUsers ? t('chat:createGroupChat') : t('editUserProfile:save')
      }`}
      isSubmitAvailable={getSubmitAvailability()}
      onSubmit={
        selectedUsers
          ? onCreateChat
          : chatMembers?.chat.type === 'multiple'
          ? onEditMultipleChat
          : null
      }
      onIconClick={() => dispatch(closeSidebarNewMultipleChat())}
      contentPadding="20px 0"
    >
      <ImageUploaderWrap>
        {selectedUsers ||
        (chatMembers?.chat.type === 'multiple' && chatMembers?.chat.isAdmin) ? (
          <ImagesUploader
            profilePhoto={image}
            onChange={onFileInputChange}
            isCropShapeCircle
            label={t('chat:addImage')}
            isGroupChatPhoto
          />
        ) : (
          <>
            {chatMembers?.chat.photoUrl && chatMembers?.chat.photoUrl.trim() ? (
              <ChatPhoto
                img={chatMembers?.chat.photoUrl}
                role="img"
                aria-label="chat img"
              />
            ) : (
              <EmptyUserProfilePhoto wrapSize={150} userIconSize={75} />
            )}
            <ChatNameText>{chatMembers?.chat.name}</ChatNameText>
            {chatMembers?.chat.otherUserFullName && (
              <ChatSecondNameText>
                {chatMembers?.chat.otherUserFullName}
              </ChatSecondNameText>
            )}
          </>
        )}
      </ImageUploaderWrap>

      {(selectedUsers ||
        (chatMembers?.chat.type === 'multiple' &&
          chatMembers?.chat.isAdmin)) && (
        <InputFieldWrap>
          <InputField
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            crossIcon
            onCrossClick={() => setGroupName('')}
            label={t('chat:groupName')}
            placeholder={t('chat:addGroupName')}
          />
        </InputFieldWrap>
      )}

      <ParticipantsHeader>{`${
        selectedUsers ? selectedUsers.length : chatMembers?.members.length
      } ${t('chat:participants')}`}</ParticipantsHeader>
      {usersList}
    </SidebarWrapper>
  );
};
