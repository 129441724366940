import React from 'react';

export const LikeOutlinedIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.33325 6H12.4761C13.4216 6 13.9999 7.05457 13.9999 8V9.71429C13.9999 9.77429 13.9871 9.83343 13.9622 9.88829L12.7778 12.9817C12.5044 13.5997 11.889 14 11.2118 14H3.99992H1.855C1.567 14 1.33325 13.8133 1.33325 13.5833V7.75C1.33325 7.52 1.567 7.33333 1.855 7.33333H3.99992V7.57097C4 7.49656 4.01885 7.42387 4.05649 7.35886L5.33325 4.45743V2.42857C5.33325 2.192 5.52525 2 5.76182 2H7.33328C8.2787 2 9.33325 2.76886 9.33325 3.71429V6Z"
      fill="white"
    />
  </svg>
);
