import { getClubIdFromStorage } from './storageUtils';

type FetchDataOptions = RequestInit & {
  headers?: {
    Accept?: string;
    'Content-Type'?: string;
    tsec?: string;
  };
};

const fetchData = async (url: RequestInfo | URL, options: FetchDataOptions) => {
  const clubId = getClubIdFromStorage();
  const mergedHeaders: any = {
    ...options.headers,
  };

  if (clubId) {
    mergedHeaders.activeClubId = clubId;
  }
  const customOptions: RequestInit = {
    ...options,
    headers: mergedHeaders,
  };
  const formattedUrl = typeof url === 'string' ? url : url.toString();

  const response = await fetch(formattedUrl, customOptions);
  return response;
};

export default fetchData;
