import styled from 'styled-components';

import { style, deviceMaxWidth } from 'utils/constants/style';

interface IProps {
  isFullMobileHeight?: boolean;
  isTop?: boolean;
}

export const Wrap = styled.div<IProps>`
  width: 100%;
  max-width: 400px;
  position: absolute;
  right: 0;
  top: 56px;
  min-height: calc(100vh - 56px);
  bottom: 0;
  overflow: auto;
  background-color: ${style.mainWhite.white};
  /* z-index: 1; */

  ::-webkit-scrollbar-track {
    background: none;
  }
  ::-webkit-scrollbar {
    width: 4px;
    height: 100px;
    background: none;
  }
  ::-webkit-scrollbar-thumb {
    background: ${style.mainGray.silver};
    border-radius: 10px;
  }

  @media only screen and (${deviceMaxWidth.sm}) {
    top: ${({ isTop }) => (isTop ? 0 : '300px')};
    min-height: auto;
    height: ${({ isTop }) => (isTop ? 'calc(100vh - 56px)' : 'auto')};
    bottom: auto;
    max-width: 100%;
    width: 100%;
  }
`;
