import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  isCollapsed?: boolean | undefined;
  isSimpleText?: boolean;
  color?: string;
  cursor?: string;
  ml?: number;
}

export const TextContainer = styled.div<IProps>`
  width: 100%;
  box-sizing: border-box;
  padding: 0 16px;
  max-height: auto;
  position: relative;
  overflow: hidden;
  font-size: 15px;
  line-height: 150%;
`;

export const Text = styled.p<IProps>`
  display: inline-block;
  color: ${({ isSimpleText }) =>
    isSimpleText ? style.mainBlack.black : style.mainBlue.blue};
  cursor: ${({ isSimpleText }) => (isSimpleText ? 'auto' : 'pointer')};
  margin-right: 5.5px;
`;

export const SeeMoreText = styled.p<IProps>`
  display: inline-block;
  font-size: 15px;
  cursor: pointer;
  line-height: 150%;
  color: ${({ color }) => color || style.mainGray.SuvaGrey};
  margin-left: ${({ ml }) => (ml ? `${ml}px` : '0')};
`;

export const SeeMoreWrap = styled.span`
  display: inline-block;
  width: 153px;
  background-color: ${style.mainWhite.white};
  z-index: 2;
  cursor: pointer;
`;

export const LinkText = styled.a`
  display: inline-block;
  font-size: 15px;
  line-height: 150%;
  letter-spacing: 0.02em;
  font-weight: 500;
  color: ${style.mainBlue.blue};
  cursor: pointer;
  text-decoration: none;
  margin-right: 4px;
`;
