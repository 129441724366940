import { IStyle } from './IStyle';

const roadstrLightStyle: IStyle = {
  typography: {
    fontFamily: {
      primary: 'Poppins',
      secondary: 'Sofia Pro',
    },
    fontSize: {
      f1: '11px',
      f2: '12px',
      f3: '13px',
      f4: '14px',
      f5: '15px',
      f6: '16px',
      f7: '18px',
      f8: '20px',
      f9: '24px',
      f10: '26px',
      lg: '30px',
      xl: '35px',
    },
    fontWeight: {
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
    },
  },
  colors: {
    text: '#1C1F29',
    orange: '#FF950A',
    orangeHover: '#FF9E21',
    orangeLight: '#FFF5E8',
    orangeDark: '#FF8F00',
    blue: '#1188FF',
    blueHover: '#2693FF',
    blueLight: '#E8F3FF',
    green: '#5EC043',
    red: '#EA3C24',
    white: '#FFFFFF',
    bodyBg: '#F6F6F6',
    black: '#000000',
    spunPearl: '#a9a9b5',
    // temporary color for donation metric banner
    purple: '#8d5cf5',
    brandButton: {
      facebook: '#1877F2',
      apple: '#000000',
    },
    neutral: {
      n0: '#F7F7F7',
      n1: '#FBFBFB',
      n2: '#F9F9F9',
      n3: '#F6F6F6',
      n4: '#ECECEC',
      n5: '#E3E3E3',
      n6: '#C2C2C2',
      n7: '#A7A7A7',
      n8: '#8D8D8D',
      n9: '#747474',
      n10: '#5C5C5C',
      n11: '#454545',
      n12: '#2F2F2F',
    },
    gradients: {
      green: 'linear-gradient(0deg, #8BC835 0%, #9ED74F 100%)',
      red: 'linear-gradient(0deg, #D53D28 0%, #F7553F 100%)',
      blue: 'linear-gradient(0deg, #1188FF 0%, #46A3FF 100%)',
      gray: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 9.67%, #1C1F29 100%)',
      orange: 'linear-gradient(180deg, #FFAF47 0%, #FF950A 100%)',
    },
    shadows: {
      xs: '0px 4px 10px rgba(0, 0, 0, 0.02)',
      sm: '0px 4px 20px rgba(0, 0, 0, 0.06)',
      md: '0px 5px 5px rgba(0, 0, 0, 0.07)',
      lg: '0px 8px 20px rgba(0, 0, 0, 0.08)',
    },
  },
};

const roadstrDarkStyle: IStyle = {
  typography: {
    fontFamily: {
      primary: 'Poppins',
      secondary: 'Sofia Pro',
    },
    fontSize: {
      f1: '11px',
      f2: '12px',
      f3: '13px',
      f4: '14px',
      f5: '15px',
      f6: '16px',
      f7: '18px',
      f8: '20px',
      f9: '24px',
      f10: '26px',
      lg: '30px',
      xl: '35px',
    },
    fontWeight: {
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
    },
  },
  colors: {
    text: '#1C1F29',
    orange: '#FF950A',
    orangeHover: '#FF9E21',
    orangeLight: '#FFF5E8',
    orangeDark: '#FF8F00',
    blue: '#1188FF',
    blueHover: '#2693FF',
    blueLight: '#E8F3FF',
    green: '#5EC043',
    red: '#EA3C24',
    white: '#FFFFFF',
    bodyBg: '#F6F6F6',
    black: '#000000',
    spunPearl: '#a9a9b5',
    // temporary color for donation metric banner
    purple: '#8d5cf5',
    brandButton: {
      facebook: '#1877F2',
      apple: '#000000',
    },
    neutral: {
      n0: '#F7F7F7',
      n1: '#FBFBFB',
      n2: '#F9F9F9',
      n3: '#F6F6F6',
      n4: '#ECECEC',
      n5: '#E3E3E3',
      n6: '#C2C2C2',
      n7: '#A7A7A7',
      n8: '#8D8D8D',
      n9: '#747474',
      n10: '#5C5C5C',
      n11: '#454545',
      n12: '#2F2F2F',
    },
    gradients: {
      green: 'linear-gradient(0deg, #8BC835 0%, #9ED74F 100%)',
      red: 'linear-gradient(0deg, #D53D28 0%, #F7553F 100%)',
      blue: 'linear-gradient(0deg, #1188FF 0%, #46A3FF 100%)',
      gray: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 9.67%, #1C1F29 100%)',
      orange: 'linear-gradient(180deg, #FFAF47 0%, #FF950A 100%)',
    },
    shadows: {
      xs: '0px 4px 10px rgba(0, 0, 0, 0.02)',
      sm: '0px 4px 20px rgba(0, 0, 0, 0.06)',
      md: '0px 5px 5px rgba(0, 0, 0, 0.07)',
      lg: '0px 8px 20px rgba(0, 0, 0, 0.08)',
    },
  },
};

const rockrLightStyle: IStyle = {
  typography: {
    fontFamily: {
      primary: 'Poppins',
      secondary: 'Sofia Pro',
    },
    fontSize: {
      f1: '11px',
      f2: '12px',
      f3: '13px',
      f4: '14px',
      f5: '15px',
      f6: '16px',
      f7: '18px',
      f8: '20px',
      f9: '24px',
      f10: '26px',
      lg: '30px',
      xl: '35px',
    },
    fontWeight: {
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
    },
  },
  colors: {
    text: '#1C1F29',
    orange: '#ED0547',
    orangeHover: '#FF1F5E',
    orangeLight: '#FFF5E8',
    orangeDark: '#D4043F',
    blue: '#0884CC',
    blueHover: '#2691fc',
    blueLight: '#E8F3FF',
    green: '#5EC043',
    red: '#EA3C24',
    white: '#FFFFFF',
    bodyBg: '#F6F6F6',
    black: '#000000',
    spunPearl: '#a9a9b5',
    // temporary color for donation metric banner
    purple: '#8d5cf5',
    brandButton: {
      facebook: '#1877F2',
      apple: '#000000',
    },
    neutral: {
      n0: '#F7F7F7',
      n1: '#FBFBFB',
      n2: '#F9F9F9',
      n3: '#F6F6F6',
      n4: '#ECECEC',
      n5: '#E3E3E3',
      n6: '#C2C2C2',
      n7: '#A7A7A7',
      n8: '#8D8D8D',
      n9: '#747474',
      n10: '#5C5C5C',
      n11: '#454545',
      n12: '#2F2F2F',
    },
    gradients: {
      green: 'linear-gradient(0deg, #8BC835 0%, #9ED74F 100%)',
      red: 'linear-gradient(0deg, #D53D28 0%, #F7553F 100%)',
      blue: 'linear-gradient(0deg, #0884CC 0%, #46A3FF 100%)',
      gray: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 9.67%, #1C1F29 100%)',
      orange: 'linear-gradient(180deg, #FF1F5E 0%, #ED0547 100%)',
    },
    shadows: {
      xs: '0px 4px 10px rgba(0, 0, 0, 0.02)',
      sm: '0px 4px 20px rgba(0, 0, 0, 0.06)',
      md: '0px 5px 5px rgba(0, 0, 0, 0.07)',
      lg: '0px 8px 20px rgba(0, 0, 0, 0.08)',
    },
  },
};

const rockrDarkStyle: IStyle = {
  typography: {
    fontFamily: {
      primary: 'Poppins',
      secondary: 'Sofia Pro',
    },
    fontSize: {
      f1: '11px',
      f2: '12px',
      f3: '13px',
      f4: '14px',
      f5: '15px',
      f6: '16px',
      f7: '18px',
      f8: '20px',
      f9: '24px',
      f10: '26px',
      lg: '30px',
      xl: '35px',
    },
    fontWeight: {
      light: 300,
      regular: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
    },
  },
  colors: {
    text: '#1C1F29',
    orange: '#ED0547',
    orangeHover: '#FF1F5E',
    orangeLight: '#FFF5E8',
    orangeDark: '#D4043F',
    blue: '#0884CC',
    blueHover: '#2691fc',
    blueLight: '#E8F3FF',
    green: '#5EC043',
    red: '#EA3C24',
    white: '#FFFFFF',
    bodyBg: '#F6F6F6',
    black: '#000000',
    spunPearl: '#a9a9b5',
    // temporary color for donation metric banner
    purple: '#8d5cf5',
    brandButton: {
      facebook: '#1877F2',
      apple: '#000000',
    },
    neutral: {
      n0: '#F7F7F7',
      n1: '#FBFBFB',
      n2: '#F9F9F9',
      n3: '#F6F6F6',
      n4: '#ECECEC',
      n5: '#E3E3E3',
      n6: '#C2C2C2',
      n7: '#A7A7A7',
      n8: '#8D8D8D',
      n9: '#747474',
      n10: '#5C5C5C',
      n11: '#454545',
      n12: '#2F2F2F',
    },
    gradients: {
      green: 'linear-gradient(0deg, #8BC835 0%, #9ED74F 100%)',
      red: 'linear-gradient(0deg, #D53D28 0%, #F7553F 100%)',
      blue: 'linear-gradient(0deg, #0884CC 0%, #46A3FF 100%)',
      gray: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 9.67%, #1C1F29 100%)',
      orange: 'linear-gradient(180deg, #FF1F5E 0%, #ED0547 100%)',
    },
    shadows: {
      xs: '0px 4px 10px rgba(0, 0, 0, 0.02)',
      sm: '0px 4px 20px rgba(0, 0, 0, 0.06)',
      md: '0px 5px 5px rgba(0, 0, 0, 0.07)',
      lg: '0px 8px 20px rgba(0, 0, 0, 0.08)',
    },
  },
};

export { roadstrLightStyle, roadstrDarkStyle, rockrLightStyle, rockrDarkStyle };
