import Skeleton from 'components/common/Skeleton';

import {
  ProductMainCardSkeletonContent,
  ProductMainCardSkeletonFooter,
  ProductMainCardSkeletonWrapper,
} from './ProductMainCardSkeleton.styles';

const ProductMainCardSkeleton = () => {
  return (
    <ProductMainCardSkeletonWrapper>
      <Skeleton height={366} bottom={0} />
      <ProductMainCardSkeletonContent>
        <Skeleton height={16} width="50%" bottom={0} borderRadius={2} />
      </ProductMainCardSkeletonContent>

      <ProductMainCardSkeletonFooter>
        <Skeleton circle width="32px" borderRadius={100} bottom={0} />
        <Skeleton height={10} width="26%" bottom={0} borderRadius={2} />
        <Skeleton height={40} width="20%" bottom={0} borderRadius={4} />
      </ProductMainCardSkeletonFooter>
    </ProductMainCardSkeletonWrapper>
  );
};

export default ProductMainCardSkeleton;
