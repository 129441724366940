import { CrossIcon, EditIcon } from 'public/assets/icons';
import { ISmallImagePreview } from './ISmallImagePreview';

import {
  CloseIconWrap,
  SmallImageContainerWrap,
  SmallImageWrap,
  UploadedImage,
  EditIconWrap,
} from './SmallImagePreview.styles';

const SmallImagePreview = ({
  onCloseIconClick,
  setSelectedImage,
  image,
  index,
  onEditIconClick,
}: ISmallImagePreview) => {
  return (
    <SmallImageContainerWrap isFilter>
      <SmallImageWrap onClick={() => setSelectedImage(index)}>
        <UploadedImage fullWidth src={image} />
      </SmallImageWrap>

      <EditIconWrap onClick={() => onEditIconClick(index)}>
        <EditIcon />
      </EditIconWrap>

      <CloseIconWrap onClick={() => onCloseIconClick(index)}>
        <CrossIcon height="16" width="16" />
      </CloseIconWrap>
    </SmallImageContainerWrap>
  );
};

export default SmallImagePreview;
