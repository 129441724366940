/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';

import { EventsIcon, TimeIcon } from 'public/assets/icons';
import { IDatePicker } from './ICustomDatePicker';
import { Wrapper, DateText, ItemWrap } from './CustomDatePicker.style';

import 'react-datepicker/dist/react-datepicker.css';

const CustomDatePicker = ({ selectedDate, onChange, minDate }: IDatePicker) => {
  const CustomInput = forwardRef((props: any, ref: any) => (
    <Wrapper ref={ref}>
      <ItemWrap mr={16} onClick={props.onClick}>
        <EventsIcon />
        <DateText>{props.value}</DateText>
      </ItemWrap>
      <ItemWrap mr={16} onClick={props.onClick}>
        <TimeIcon />
        <DateText>{format(selectedDate, 'p')}</DateText>
      </ItemWrap>
    </Wrapper>
  ));

  return (
    <DatePicker
      selected={selectedDate}
      onChange={onChange}
      showTimeSelect
      customInput={<CustomInput />}
      minDate={minDate}
    />
  );
};

export default CustomDatePicker;
