/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import { IPillButton } from './IPillButton';
import { Button, LinkButton } from './PillButton.style';
import { LoadingDots } from '../../index';

const PillButton = ({
  text,
  loading,
  loadingDotsWrapperHeight,
  loadingDotsWidth,
  isLinkButton,
  ...props
}: IPillButton) => {
  if (isLinkButton) {
    return (
      <LinkButton {...props} target="_blank">
        {loading ? (
          <LoadingDots
            wrapperHeight={loadingDotsWrapperHeight}
            dotWidth={loadingDotsWidth}
          />
        ) : (
          text
        )}
      </LinkButton>
    );
  }

  return (
    <Button {...props}>
      {loading ? (
        <LoadingDots
          wrapperHeight={loadingDotsWrapperHeight}
          dotWidth={loadingDotsWidth}
        />
      ) : (
        text
      )}
    </Button>
  );
};

export default PillButton;
