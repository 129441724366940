import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  img?: string;
}

export const InvolvedUsersListWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const SearchWrap = styled.div`
  width: 100%;
  margin-bottom: 20px;
  box-sizing: border-box;
`;

export const InvolvedUserItemWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  cursor: pointer;
  position: relative;
`;

export const InvolvedUserItemImage = styled.div<IProps>`
  width: 48px;
  height: 48px;
  min-width: 48px;
  border-radius: 50%;
  margin-right: 10px;
  background: url(${({ img }) => img}) no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
`;

export const InvolvedUserItemInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 0;
  margin-right: 10px;
`;

export const InvolvedUserItemHeaderText = styled.p`
  font-size: 13px;
  line-height: 13px;
  font-weight: 600;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  padding-bottom: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const InvolvedUserItemText = styled(InvolvedUserItemHeaderText)`
  font-weight: 400;
  color: ${style.mainGray.SuvaGrey};
  padding-top: 2px;
`;

export const ButtonWrap = styled.div`
  width: 100px;
  min-width: 100px;
`;

export const ThreeDotsWrap = styled.div`
  width: 24px;
  height: 24px;
  min-width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  transform: rotate(90deg);
`;

export const PlaceholderWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
`;

export const Text = styled.p`
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.02em;
  color: ${style.mainGray.silver};
  margin-bottom: 30px;
`;

export const PopoverWrap = styled.div`
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 110;
  width: 200px;
  height: 60px;
`;
