import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  img?: string;
  isCoverPhotoExists?: boolean;
  height?: number;
}

export const CardWrap = styled.div<IProps>`
  width: 276px;
  height: 345px;
  display: flex;
  align-items: flex-end;
  border-radius: 7px;
  margin-bottom: 16px;
  margin-right: 16px;
  background: url(${({ img }) => img}) no-repeat, #ececec;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  &:nth-of-type(3n) {
    margin-right: 0;
  }
  filter: drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.1));

  @media only screen and (max-width: 900px) {
    width: 32%;
    margin-right: 2%;
  }

  @media only screen and (max-width: 720px) {
    width: 49%;
    margin-right: 0;

    &:nth-of-type(odd) {
      margin-right: 2%;
    }
  }

  @media only screen and (max-width: 475px) {
    width: 100%;
    margin-right: 0;

    &:nth-of-type(odd) {
      margin-right: 0;
    }
  }
`;

export const LinkWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 16px 16px 16px;
`;

export const LinkTitle = styled.p<IProps>`
  font-size: 15px;
  line-height: 130%;
  font-weight: 500;
  color: ${({ isCoverPhotoExists }) =>
    isCoverPhotoExists ? style.mainWhite.white : style.mainBlack.black};
  max-height: ${({ height }) => (height ? `${height}px` : '15.6px')};

  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: -o-ellipsis-lastline;
`;

export const LinkHost = styled(LinkTitle)`
  font-size: 13px;
  font-weight: 400;
  line-height: 100%;
  color: ${style.mainBlue.blue};
  margin-top: 4px;
`;

export const PlayButtonWrap = styled.div`
  position: absolute;
  top: 6px;
  left: 6px;
`;
