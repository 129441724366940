import Image from 'components/common/Image';
import { EmptyGroupIcon } from 'public/assets/icons';
import Skeleton from 'components/common/Skeleton';
import CheckBox from 'components/common/CheckBox';
import imageResize from 'utils/imageResize';
import EmptyUserProfilePhoto from 'components/EmptyUserProfilePhoto/EmptyUserProfilePhoto';
import { ISidebarCheckItem } from './ISidebarCheckItem';

import {
  CheckboxWrap,
  EmptyUserPhoto,
  ImageWrapper,
  ItemDescription,
  ItemDescriptions,
  ItemName,
  ItemWrap,
  SidbarListItemSkeleton,
  SidbarListItemSkeletonRight,
} from './SidebarCheckItem.styles';

export const SidebarListItemSkeleton = () => (
  <SidbarListItemSkeleton>
    <Skeleton circle width="40px" borderRadius={50} bottom={0} />
    <SidbarListItemSkeletonRight>
      <Skeleton height={8} width="30%" borderRadius={10} bottom={6} />
      <Skeleton height={6} width="20%" bottom={0} borderRadius={10} />
    </SidbarListItemSkeletonRight>
  </SidbarListItemSkeleton>
);

const SidebarCheckItem = ({
  id,
  image,
  item,
  userName,
  fullName,
  name,
  onSidebarCheckItemClick,
  isSelected,
  type = 'profile',
}: ISidebarCheckItem) => {
  const onItemClick = (item) => {
    onSidebarCheckItemClick(item);
  };

  return (
    <ItemWrap onClick={() => onItemClick(item)}>
      <CheckboxWrap>
        <CheckBox type="radio" checked={isSelected} />
      </CheckboxWrap>
      <ImageWrapper>
        {image?.trim() ? (
          <Image
            loader={() => image}
            src={imageResize(image, '_thumb') || image}
            alt="list item image"
            height={40}
            width={40}
            objectFit="cover"
            unoptimized
          />
        ) : (
          <>
            {(type === 'group' && (
              <EmptyUserPhoto>
                <EmptyGroupIcon />
              </EmptyUserPhoto>
            )) ||
              (type === 'profile' && (
                <EmptyUserProfilePhoto wrapSize={40} userIconSize={20} />
              ))}
          </>
        )}
      </ImageWrapper>
      <ItemDescriptions>
        <ItemName>{userName || name}</ItemName>
        <ItemDescription>{fullName}</ItemDescription>
      </ItemDescriptions>
    </ItemWrap>
  );
};

export default SidebarCheckItem;
