import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormState } from 'react-final-form';

import Drawer from 'components/Drawer';
import CheckBox from 'components/common/CheckBox';
import { ArrowRight } from 'public/assets/icons';
import { IPrivacyDrawer } from './IPrivacyDrawer';

import {
  PrivacyContentBlock,
  PrivacyContentBlockLeft,
  PrivacyContentBlockRight,
  PrivacyContentWrapper,
  BlockHeading,
  BlockText,
  PrivacyWrapper,
  PrivacyWrapperLeft,
  PrivacyBlockRight,
  PrivacyBlock,
  PrivacyBlockLeft,
  PrivacyBlockText,
  SeparateSettingsHeader,
} from './PrivacyDrawer.styles';

const PrivacyDrawer = ({
  headerText = 'Group',
  meta,
  input,
  privacy,
}: IPrivacyDrawer) => {
  const [isPrivacyDrawerOpen, SetIsPrivacyDrawerOpen] =
    useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [checked, setChecked] = useState<number>();

  const { t } = useTranslation();
  const { values } = useFormState();

  const { onChange, value } = input;

  const { valid, invalid, touched, error, submitError } = meta;

  const errorText = error || submitError;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = !!(touched && invalid && (error || submitError));

  const privacyData = [
    {
      id: 1,
      title: t('groupPrivacyOptions:public'),
      text: t('groupPrivacyOptions:publicDescription'),
      privacy: 0,
    },
    {
      id: 2,
      title: t('groupPrivacyOptions:membersPlus'),
      text: t('groupPrivacyOptions:membersPlusDescription'),
      privacy: 1,
    },
    {
      id: 3,
      title: t('groupPrivacyOptions:private'),
      text: t('groupPrivacyOptions:privateDescription'),
      privacy: 2,
    },
  ];

  // useEffect(() => {
  //   const getData = privacyData?.find((i) => i?.privacy === value);
  //   onChange(getData?.privacy);
  // }, [value]);

  const getPrivacyContent = useMemo(
    () => privacyData?.find((d) => d?.privacy === value),
    [value]
  );

  return (
    <>
      <PrivacyWrapper onClick={() => SetIsPrivacyDrawerOpen(true)}>
        <PrivacyWrapperLeft>
          <SeparateSettingsHeader type="h3" mb={8}>
            {`${headerText} privacy`}
          </SeparateSettingsHeader>
          <PrivacyBlockRight>
            <ArrowRight />
          </PrivacyBlockRight>
        </PrivacyWrapperLeft>
        {getPrivacyContent?.title && (
          <PrivacyBlock>
            <PrivacyBlockLeft>
              <SeparateSettingsHeader type="h4" fs={15} fw={500}>
                {getPrivacyContent?.title}
              </SeparateSettingsHeader>
              <PrivacyBlockText>{getPrivacyContent?.text}</PrivacyBlockText>
            </PrivacyBlockLeft>
          </PrivacyBlock>
        )}
      </PrivacyWrapper>
      <Drawer
        open={isPrivacyDrawerOpen}
        headerText={`${headerText} privacy`}
        onIconClick={() => SetIsPrivacyDrawerOpen(false)}
        onDrawerClose={() => SetIsPrivacyDrawerOpen(false)}
        submitText={t('editUserProfile:save')}
        onSubmit={() => SetIsPrivacyDrawerOpen(false)}
      >
        <PrivacyContentWrapper checked={checked === 3}>
          {privacyData?.map((d) => (
            <PrivacyContentBlock
              key={d?.id}
              onClick={() => onChange(d?.privacy)}
              checked={value === d?.privacy}
            >
              <PrivacyContentBlockRight>
                <CheckBox
                  type="radio"
                  name="privacy"
                  checked={value === d?.privacy}
                >
                  <PrivacyContentBlockLeft>
                    <BlockHeading type="h4" fs={14}>
                      {d?.title}
                    </BlockHeading>
                    <BlockText>{d?.text}</BlockText>
                  </PrivacyContentBlockLeft>
                </CheckBox>
              </PrivacyContentBlockRight>
            </PrivacyContentBlock>
          ))}
        </PrivacyContentWrapper>
      </Drawer>
    </>
  );
};

export default PrivacyDrawer;
