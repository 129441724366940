/* eslint-disable no-case-declarations */
/* eslint-disable react/display-name */
import React, { useContext, useEffect, useState } from 'react';
import Head from 'next/head';

import imageResize from 'utils/imageResize';
import { ThemeContext } from 'theme';
import i18n from 'utils/i18n';
import { APP_CONFIG } from 'utils/constants';
import { routesPath } from 'utils/constants/routePath';
import { getUserDetails } from 'services';
import { useRouter } from 'next/router';

const DOMAIN = `https://app.${APP_CONFIG.appname}.io`;

const getDefaultTags = () => (
  <>
    <title>{i18n.t('metaTags:defaultTitle')}</title>
    <meta name="author" content={`${APP_CONFIG.APPNAME}`} />
    <meta name="description" content={i18n.t('metaTags:defaultDescription')} />
    <meta property="og:locale" content="en_US" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={i18n.t('metaTags:defaultOgTitle')} />
    <meta
      property="og:description"
      content={i18n.t('metaTags:defaultDescription')}
    />
    <meta
      property="og:image"
      content={`${process.env._APP_HOST}/assets/img/${
        process.env._ENV === 'rockr'
          ? 'rockr-background.jpeg'
          : 'roadstr-background.png'
      }`}
    />
    <meta property="og:site_name" content={`${APP_CONFIG.appname}`} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta
      name="twitter:description"
      content={i18n.t('metaTags:defaultDescription')}
    />
    <meta name="twitter:title" content={i18n.t('metaTags:defaultOgTitle')} />
    <meta name="twitter:site" content={`@${APP_CONFIG.appname}_app`} />
  </>
);

export const MetaTags = React.memo(
  ({ pagePath, data }: { pagePath?: string; data?: any }) => {
    const {
      creator,
      name,
      coverPhoto,
      description,
      fullName,
      username,
      _id,
      photos,
      make,
      model,
      year,
      owner,
      text,
      media,
      staticImageUrl,
      previewImageUrl,
    } = data || {};

    const [user, setUser] = useState<any>();
    const router = useRouter();
    const themeContext: any = useContext(ThemeContext);
    useEffect(() => {
      if (_id) {
        getUserDetails(_id)
          .then((item) => {
            setUser(item?.data);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }, [_id]);

    let tags;

    const showPrivateContent = data?.privacy === 1;

    switch (pagePath) {
      case `${routesPath.business}/[id]`:
        tags = <></>;
        break;
      case `${routesPath.shop}/[id]`:
        tags = <></>;
        break;
      case `${routesPath.landing_events}`:
        tags = <></>;
        break;
      case `${routesPath.landing_events}/[country]`:
        tags = <></>;
        break;
      case `${routesPath.landing_events}/[country]/[region]`:
        tags = <></>;
        break;
      case `${routesPath.landing_events}/[country]/[region]/[place]`:
        tags = <></>;
        break;
      case `${routesPath.landing_routs}`:
        tags = <></>;
        break;
      case `${routesPath.landing_routs}/[country]`:
        tags = <></>;
        break;
      case `${routesPath.landing_routs}/[country]/[region]`:
        tags = <></>;
        break;
      case `${routesPath.landing_routs}/[country]/[region]/[place]`:
        tags = <></>;
        break;
      case `${routesPath.landing_groups}`:
        tags = <></>;
        break;
      case `brands/[brandname]`:
        tags = <></>;
        break;
      case `${routesPath.event_details}/[id]`:
        tags = (
          <>
            <title>
              {i18n.t('metaTags:eventTitle', {
                name,
                username: creator?.data?.username || '',
              })}
            </title>
            <meta name="description" content={description} />
            <meta name="author" content={APP_CONFIG.APPNAME} />
            <meta
              property="og:title"
              content={i18n.t('metaTags:eventTitle', {
                name,
                username: creator?.data?.username || '',
              })}
            />
            <meta property="og:description" content={description} />
            <meta
              property="og:image"
              content={imageResize(coverPhoto, '_detail')}
            />
            <meta property="og:type" content="article" />
            <meta property="fb:app_id" content={process.env._FACEBOOK_APP_ID} />
            {showPrivateContent && <meta name="robots" content="noindex" />}
          </>
        );
        break;

      case `${routesPath.user_details}/[id]`:
        const userFullName = fullName ? ` | ${fullName}` : '';
        const userCoverPhoto = coverPhoto?.trim()
          ? coverPhoto
          : '/assets/img/tireTrace.png';

        tags = (
          <>
            <title>
              {i18n.t('metaTags:userTitle', { username: user?.username })}
            </title>

            <meta name="description" content={description} />
            <meta name="author" content={APP_CONFIG.APPNAME} />
            <meta
              property="og:title"
              content={i18n.t('metaTags:userOgTitle', {
                username,
                fullName: userFullName,
              })}
            />
            <meta property="og:description" content={description} />
            <meta
              property="og:image"
              content={imageResize(userCoverPhoto, '_detail')}
            />
            <meta property="og:type" content="article" />
            <meta
              property="og:url"
              content={`https://app.${APP_CONFIG.appname}.io/p/${_id}`}
            />
            <meta
              property="al:ios:url"
              content={`https://app.${APP_CONFIG.appname}.io/p/${_id}`}
            />
            <meta property="fb:app_id" content={process.env._FACEBOOK_APP_ID} />
            {showPrivateContent && <meta name="robots" content="noindex" />}
          </>
        );
        break;
      case `${routesPath.donate}`:
        const donateCoverPhoto = themeContext.content.donateBanerImage;

        tags = (
          <>
            <title>{i18n.t('donation:driveAndHelpUkraine')}</title>

            <meta name="author" content={APP_CONFIG.APPNAME} />
            <meta
              property="og:title"
              content={i18n.t('donation:driveAndHelpUkraine')}
            />
            <meta property="og:image" content={donateCoverPhoto} />
            <meta property="og:type" content="article" />
            <meta property="fb:app_id" content={process.env._FACEBOOK_APP_ID} />
          </>
        );
        break;

      case `${routesPath.vehicle_details}/[id]`:
        const vehicleCoverPhoto = photos?.length
          ? photos[0]
          : '/assets/img/tireTrace.png';
        const vehicleTitle = i18n.t('metaTags:vehicleTitle', {
          username: owner?.username,
          make,
          model,
          year,
        });

        tags = (
          <>
            <title>{vehicleTitle}</title>
            <meta name="description" content={description} />
            <meta name="author" content={APP_CONFIG.APPNAME} />
            <meta property="og:title" content={vehicleTitle} />
            <meta property="og:description" content={description} />
            <meta
              property="og:image"
              content={imageResize(vehicleCoverPhoto, '_detail')}
            />
            <meta property="og:type" content="article" />
            <meta property="fb:app_id" content={process.env._FACEBOOK_APP_ID} />
            {showPrivateContent && <meta name="robots" content="noindex" />}
          </>
        );
        break;

      case `${routesPath.post_details}/[id]`:
        const postCoverPhoto = Array.isArray(media)
          ? media[0]?.trim()
            ? media[0]
            : '/assets/img/tireTrace.png'
          : '/assets/img/tireTrace.png';

        tags = (
          <>
            <title>
              {i18n.t('metaTags:postTitle', {
                username: creator?.username,
                text,
              })}
            </title>

            <meta name="description" content={text} />
            <meta name="author" content={APP_CONFIG.APPNAME} />
            <meta
              property="og:title"
              content={i18n.t('metaTags:postTitle', {
                username: creator?.username,
                text,
              })}
            />
            <meta property="og:description" content={text} />
            <meta
              property="og:image"
              content={imageResize(postCoverPhoto, '_detail')}
            />
            <meta property="og:type" content="article" />
            <meta property="fb:app_id" content={process.env._FACEBOOK_APP_ID} />
            {showPrivateContent && <meta name="robots" content="noindex" />}
          </>
        );
        break;

      case `${routesPath.group_details}/[id]`:
        const groupCoverPhoto = coverPhoto?.trim()
          ? coverPhoto
          : '/assets/img/tireTrace.png';

        tags = (
          <>
            <title>{i18n.t('metaTags:groupTitle', { username })}</title>
            <meta name="description" content={`${description}`} />
            <meta name="author" content={`${APP_CONFIG.APPNAME}`} />

            <meta
              property="og:title"
              content={i18n.t('metaTags:groupOgTitle', { username, name })}
            />
            <meta property="og:description" content={description} />
            <meta
              property="og:image"
              content={imageResize(groupCoverPhoto, '_detail')}
            />
            <meta property="og:type" content="article" />
            <meta property="fb:app_id" content={process.env._FACEBOOK_APP_ID} />
            {showPrivateContent && <meta name="robots" content="noindex" />}
          </>
        );
        break;

      case `${routesPath.group_garage}/[id]`:
        const garageCoverPhoto = coverPhoto?.trim()
          ? coverPhoto
          : '/assets/img/tireTrace.png';

        tags = (
          <>
            <title>
              {i18n.t('metaTags:garageTitle', {
                username,
                type: 'group',
              })}
            </title>
            <meta name="description" content={`${description}`} />
            <meta name="author" content={`${APP_CONFIG.APPNAME}`} />

            <meta
              property="og:title"
              content={i18n.t('metaTags:garageOrTitle', {
                username,
                type: 'group',
              })}
            />
            <meta property="og:description" content={description} />
            <meta
              property="og:image"
              content={imageResize(garageCoverPhoto, '_detail')}
            />
            <meta property="og:type" content="article" />
            <meta property="fb:app_id" content={process.env._FACEBOOK_APP_ID} />
          </>
        );
        break;

      case `${routesPath.vehicles_attending}/[id]`:
        const eventCoverPhoto = coverPhoto?.trim()
          ? coverPhoto
          : '/assets/img/tireTrace.png';

        tags = (
          <>
            <title>
              {i18n.t('metaTags:garageTitle', {
                username: creator?.data.username,
                type: 'event',
              })}
            </title>
            <meta name="description" content={`${description}`} />
            <meta name="author" content={`${APP_CONFIG.APPNAME}`} />

            <meta
              property="og:title"
              content={i18n.t('metaTags:garageOrTitle', {
                username: creator?.data.username,
                type: 'event',
              })}
            />
            <meta property="og:description" content={description} />
            <meta
              property="og:image"
              content={imageResize(eventCoverPhoto, '_detail')}
            />
            <meta property="og:type" content="article" />
            <meta property="fb:app_id" content={process.env._FACEBOOK_APP_ID} />
          </>
        );
        break;

      case `${routesPath.routeDetails}/[id]`:
        const routeTitle = i18n.t('metaTags:routeTitle', {
          username: data.creator.username,
          name: data.name,
        });

        const staticImage = `${process.env._APP_HOST}/assets/img/${
          process.env._ENV === 'rockr'
            ? 'rockr-background.jpeg'
            : 'roadstr-background.png'
        }`;

        const routeDetailsImage = previewImageUrl?.trim()
          ? previewImageUrl
          : staticImageUrl || staticImage;

        tags = (
          <>
            <title>{routeTitle}</title>

            <meta name="description" content={data.name} />
            <meta name="author" content={APP_CONFIG.APPNAME} />
            <meta property="og:title" content={routeTitle} />
            <meta property="og:description" content={data.name} />
            <meta property="og:image" content={routeDetailsImage} />
            <meta property="og:type" content="article" />
            <meta property="fb:app_id" content={process.env._FACEBOOK_APP_ID} />
            {showPrivateContent && <meta name="robots" content="noindex" />}
          </>
        );
        break;

      case `${routesPath.mapPath}`:
        tags = <title>{i18n.t('metaTags:map')}</title>;
        break;

      case `${routesPath.settings}`:
        tags = <title>{i18n.t('metaTags:settings')}</title>;
        break;

      case `${routesPath.searchPath}`:
        tags = <title>{i18n.t('metaTags:search')}</title>;
        break;

      case `${routesPath.feedPath}`:
        tags = <title>{i18n.t('metaTags:roadStr')}</title>;
        break;

      case `${routesPath.my_events}`:
        tags = <title>{i18n.t('metaTags:roadStr')}</title>;
        break;
      case `${routesPath.my_groups}`:
        tags = <title>{i18n.t('metaTags:roadStr')}</title>;
        break;

      case `${routesPath.privacyPolicy}`:
        tags = <title>{i18n.t('metaTags:privacyPolicy')}</title>;
        break;

      case `${routesPath.termsOfServices}`:
        tags = <title>{i18n.t('metaTags:termsOfServices')}</title>;
        break;

      default:
        tags = getDefaultTags();
    }

    return (
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href={`/assets/img/${APP_CONFIG.appname}_logo.png`} />
        <meta
          property="og:url"
          content={
            router?.locale === 'es'
              ? `${DOMAIN}/es${router?.asPath}`
              : `${DOMAIN}${router?.asPath}`
          }
        />
        <link
          rel="canonical"
          href={
            router?.locale === 'es'
              ? `${DOMAIN}/es${router?.asPath}`
              : `${DOMAIN}${router?.asPath}`
          }
        />
        <link
          rel="alternate"
          hrefLang="en"
          href={`${DOMAIN}${router?.asPath}`}
        />
        <link
          rel="alternate"
          hrefLang="es"
          href={`${DOMAIN}/es${router?.asPath}`}
        />
        {tags}

        {/* <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          // rel="stylesheet"
          href="https://api.mapbox.com/mapbox-gl-js/v2.3.0/mapbox-gl.css"
        />
        <link
          // rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap"
        />
        <link
          // rel="stylesheet"
          href="https://fonts.cdnfonts.com/css/sofia-pro"
        /> */}
      </Head>
    );
  }
);
