import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  mt?: number;
  mb?: number;
  pb?: number;
}

export const ContentContainer = styled.div<IProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
  padding-bottom: ${({ pb }) => (pb ? `${pb}px` : '0')};
  border-bottom: 1px dashed ${style.mainGray.whisperColor};

  :last-of-type {
    border-bottom: none;
  }
`;

export const WaypointsHeader = styled.h3<IProps>`
  font-size: 16px;
  line-height: 150%;
  font-weight: 600;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
`;

export const AddWayPointWrap = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 16px;

  p {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: ${style.mainOrange.darkOrange};
    letter-spacing: 0.02em;
    margin-left: 6px;
    text-transform: uppercase;
  }
`;
