import styled from 'styled-components';

interface IProps {
  isSidebar?: boolean;
}

export const LayoutWrapper = styled.div``;

export const ContainerWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding: 30px 0;
  max-width: 860px;
  margin: 0 auto;
  width: 100%;
  position: relative;
  @media (max-width: 860px) {
    flex-direction: column;
  }
  @media (max-width: 767px) {
    padding-bottom: 80px;
    padding-top: 76px;
  }
`;

export const ContainerContentWrapper = styled.div<IProps>`
  max-width: ${(props) => (props.isSidebar ? '550px' : '100%')};
  width: 100%;
  @media (max-width: 860px) {
    /* margin: 0 auto; */
    max-width: initial;
    width: auto;
    padding: 0 10px;
  }
`;

export const ContainerSidebarWrapper = styled.div<IProps>`
  width: 310px;
  padding-left: 40px;
`;

export const SidebarWrapper = styled.div`
  position: sticky;
  top: 86px;
`;

export const BackButtonWrap = styled.div`
  position: absolute;
  left: -62px;
  top: 25px;

  @media only screen and (max-width: 1000px) {
    display: none;
  }
`;
