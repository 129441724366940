/* eslint-disable react/prop-types */
import React from 'react';

export const HomeIcon = ({
  colorFill = 'none',
  colorStroke = '#1C1F29',
}: {
  colorFill?: string;
  colorStroke?: string;
}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill={colorFill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 7.92168C3 7.56904 3.18573 7.24247 3.48881 7.06221L11.4888 2.30404C11.8038 2.11667 12.1962 2.11667 12.5112 2.30404L20.5112 7.06221C20.8143 7.24247 21 7.56904 21 7.92168V21C21 21.5523 20.5523 22 20 22H16.6C16.0477 22 15.6 21.5523 15.6 21V16.4706C15.6 15.9183 15.1523 15.4706 14.6 15.4706H9.4C8.84772 15.4706 8.4 15.9183 8.4 16.4706V21C8.4 21.5523 7.95228 22 7.4 22H4C3.44772 22 3 21.5523 3 21V7.92168Z"
      fill={colorFill}
      stroke={colorStroke}
      strokeWidth="1.6"
    />
  </svg>
);
