import Meta from 'components/Meta';
import { useWindowSize } from 'hooks';
import Button from 'components/common/Button';
import { BackArrow } from 'components/icons';
import { useRouter } from 'next/router';
import Sidebar from './Sidebar';

import { IContainer } from './Types';

import {
  ContainerWrapper,
  ContainerContentWrapper,
  ContainerSidebarWrapper,
  BackButtonWrap,
} from './styles';

const Container = ({
  children,
  isSidebar = false,
  isBack = false,
  meta: { title, description, ogImage, ogTitle, ogUrl, ogType, isResize },
  className,
}: IContainer) => {
  const router = useRouter();
  const windowSize = useWindowSize();

  return (
    <>
      <Meta
        title={title}
        description={description}
        ogImage={ogImage}
        ogTitle={ogTitle}
        ogUrl={ogUrl}
        ogType={ogType}
        isResize={isResize}
      />
      <ContainerWrapper className={className}>
        {isBack && (
          <BackButtonWrap>
            <Button buttonType="icon" onClick={() => router.back()}>
              <BackArrow />
            </Button>
          </BackButtonWrap>
        )}
        <ContainerContentWrapper isSidebar={isSidebar}>
          {children}
        </ContainerContentWrapper>
        {windowSize?.width > 860 && isSidebar && (
          <ContainerSidebarWrapper>
            <Sidebar />
          </ContainerSidebarWrapper>
        )}
      </ContainerWrapper>
    </>
  );
};

export default Container;
