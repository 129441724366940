import styled from 'styled-components';

import Heading from 'components/common/Typography/Heading';
import Drawer from 'components/Drawer';

interface IProps {
  hasError?: boolean;
}

export const Wrapper = styled.div``;

export const BarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 6px 0;
`;

export const BarLeft = styled.div`
  display: flex;
  align-items: center;
`;

export const BarIcon = styled.span`
  display: flex;
  margin-right: 8px;
`;

export const Title = styled(Heading)`
  font-weight: ${(props) => props.theme.typography.fontWeight.medium};
`;

export const IconButton = styled.button<IProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
    & > svg {
      & > path {
        fill: ${(props) => props.theme.colors.neutral.n6} !important;
      }
    }
  }
  & > svg {
    & > path {
      fill: ${(props) => props?.hasError && props.theme.colors.red} !important;
    }
  }
`;

export const RenderContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const RenderContentDrawer = styled(Drawer)`
  padding: 0;
`;
