import React from 'react';
import { useTranslation } from 'react-i18next';

import { MoveIcon, CloseIcon } from 'pages/map/icons';
import { IWayPoint } from './IWayPoint';
import {
  Container,
  LeftSideWrap,
  RightSideWrap,
  IconWrap,
  BurgerIconDescription,
  Text,
} from '../StartPoint/StartPoint.style';
import { PointNumberWrap, PointNumber } from './WayPoint.style';

const WayPoint = ({
  address,
  pointNumber,
  isEditable = true,
  onCloseClick = null,
  onDrag = null,
  onDrop = null,
  waypointIndex = 0,
}: IWayPoint) => {
  const { t } = useTranslation();

  return (
    <Container
      draggable
      onDragOver={(ev) => ev.preventDefault()}
      onDragStart={onDrag}
      onDrop={onDrop}
      id={String(waypointIndex)}
    >
      <LeftSideWrap>
        <IconWrap mr={8}>
          <PointNumberWrap>
            <PointNumber>{pointNumber}</PointNumber>
          </PointNumberWrap>
        </IconWrap>

        <Text>{address}</Text>
      </LeftSideWrap>

      {isEditable && (
        <RightSideWrap>
          <IconWrap mr={8} cursor="pointer">
            <MoveIcon />

            <BurgerIconDescription>
              {t('map:dragToReorder')}
            </BurgerIconDescription>
          </IconWrap>

          <IconWrap cursor="pointer" onClick={onCloseClick}>
            <CloseIcon />
          </IconWrap>
        </RightSideWrap>
      )}
    </Container>
  );
};

export default WayPoint;
