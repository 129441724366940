/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';

import { MESSAGE_TYPE } from 'utils/constants';
import { ThreeDotsIcon } from 'public/assets/icons';
import { IMediaMessageItem } from './IMediaMessageItem';
import {
  MessageContainerLeft,
  MessageContainerRight,
  UserProfilePhoto,
  MessageWrapLeft,
  MessageWrapRight,
  TimeTextLeft,
  TimeTextRight,
  ThreeDotsWrap,
  PopOverWrap,
  Image,
} from './MediaMessageItem.style';
import { EmptyUserProfilePhoto } from '../..';
import { ListUserUserActions } from '../../Popover/Popover.style';

import 'react-image-lightbox/style.css';

export const MediaMessageItem = ({
  messageType,
  profilePhoto,
  media,
  datetime,
  onClick,
  onDeleteMessageClick,
}: IMediaMessageItem) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isImageOpen, setIsImageOpen] = useState(false);

  const { t } = useTranslation();

  const onDownloadClick = () => {
    const imageSrc = media.url.trim();
    const imageName = imageSrc.slice(imageSrc.lastIndexOf('/') + 1);

    saveAs(imageSrc, imageName);
  };

  return (
    <>
      {messageType === MESSAGE_TYPE.MESSAGE_LEFT_MEDIA_ITEM ? (
        <>
          <MessageContainerLeft onMouseLeave={() => setIsPopoverOpen(false)}>
            {profilePhoto && profilePhoto.trim() ? (
              <UserProfilePhoto
                img={profilePhoto}
                role="img"
                aria-label="user profile img"
              />
            ) : (
              <EmptyUserProfilePhoto
                wrapSize={32}
                userIconSize={16}
                margin="0 8px 0 0"
              />
            )}
            <MessageWrapLeft>
              <Image
                onClick={() => setIsImageOpen(true)}
                src={media.url.trim()}
                alt="message"
              />

              <ThreeDotsWrap
                className="chat-message-three-dots"
                onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                right={-41}
              >
                <ThreeDotsIcon colorStroke="#C2C2C2" />
              </ThreeDotsWrap>

              {isPopoverOpen && (
                <PopOverWrap width={140} right={-40}>
                  <ListUserUserActions>
                    <li onClick={onDownloadClick}>{t('common:download')}</li>
                  </ListUserUserActions>
                </PopOverWrap>
              )}
            </MessageWrapLeft>
          </MessageContainerLeft>
          <TimeTextLeft>{datetime}</TimeTextLeft>
        </>
      ) : (
        <MessageContainerRight onMouseLeave={() => setIsPopoverOpen(false)}>
          <MessageWrapRight>
            <Image
              onClick={() => setIsImageOpen(true)}
              src={media.url.trim()}
              alt="message"
            />

            <ThreeDotsWrap
              className="chat-message-three-dots"
              onClick={() => setIsPopoverOpen(!isPopoverOpen)}
              left={-41}
            >
              <ThreeDotsIcon colorStroke="#C2C2C2" />
            </ThreeDotsWrap>

            {isPopoverOpen && (
              <PopOverWrap width={140} left={-40}>
                <ListUserUserActions>
                  <li onClick={onDownloadClick}>{t('common:download')}</li>
                  <li onClick={onDeleteMessageClick}>{t('common:delete')}</li>
                </ListUserUserActions>
              </PopOverWrap>
            )}
          </MessageWrapRight>

          <TimeTextRight>{datetime}</TimeTextRight>
        </MessageContainerRight>
      )}

      {isImageOpen && (
        <Lightbox
          mainSrc={media.url.trim()}
          onCloseRequest={() => setIsImageOpen(false)}
          reactModalStyle={{ maxWidth: 860, width: '100%' }}
        />
      )}
    </>
  );
};
