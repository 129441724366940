import React, { useRef } from 'react';

import { useOutsideClick } from 'utils/hooks';
import { ILocationPlacesList } from './ILocationPlacesList';
import {
  ListWrap,
  LocationItemWrap,
  HeaderText,
  Text,
} from './LocationPlacesList.style';

export const LocationPlacesList = ({
  locationsList,
  onOutsideClick,
  onItemClick,
  top,
  left,
}: ILocationPlacesList) => {
  const offeredPlacesRef = useRef(null);

  useOutsideClick(offeredPlacesRef, () => {
    onOutsideClick();
  });

  return (
    <ListWrap top={top} left={left} ref={offeredPlacesRef}>
      {locationsList.map((item, index) => (
        <LocationItemWrap
          onClick={() => onItemClick(item)}
          key={`${item.id} ${index}`}
        >
          <HeaderText>{item.text}</HeaderText>
          <Text>{item.place_name}</Text>
        </LocationItemWrap>
      ))}
    </ListWrap>
  );
};
