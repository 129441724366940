import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  SearchIcon,
  CircleWithCheckMark,
  WheelIcon,
  ArrowBack,
  CrossIcon,
} from 'public/assets/icons';
import { style } from 'utils/constants/style';
import {
  getEventsStart,
  getFollowersStart,
  getGroupsStart,
} from 'store/user/actions';
import {
  actionTypes,
  closeSidebarTag,
  addTags,
} from 'store/sideBarControler/actions';
import { getCarBrandsStart } from 'store/vehicle/actions';
import { DEFAULT_PAGE_SIZE } from 'utils/constants';
import { InputField, Pill } from '../../index';
import {
  InputWrapper,
  SearchHeader,
  SearchItemWrap,
  EmptyCheckbox,
  CheckboxWrap,
  ItemImageWrap,
  ItemInfoWrap,
  ItemInfoText,
  ItemInfoHeaderText,
  SelectedItemsWrap,
  SelectedItemWrap,
  SelectedItemName,
  NoSelectedItemsWrap,
  Text,
  NoResultWrap,
} from './Tag.style';
import { ITag } from './ITag';
import { SidebarWrapper } from '../index';

const search = (data: any[], searchValue: string, type: string) => {
  const searchValueLowerCase = searchValue.toLocaleLowerCase();

  if (type === 'events' || type === 'brands') {
    return data.filter((elem) =>
      elem.name.toLowerCase().includes(searchValueLowerCase)
    );
  }

  if (type === 'users' || type === 'collaborators' || type === 'admins') {
    return data.filter(
      (elem) =>
        elem.fullName.toLowerCase().includes(searchValueLowerCase) ||
        elem.username.toLowerCase().includes(searchValueLowerCase)
    );
  }

  if (type === 'groups') {
    return data.filter(
      (elem) =>
        elem.name.toLowerCase().includes(searchValueLowerCase) ||
        elem.username.toLowerCase().includes(searchValueLowerCase)
    );
  }

  return [];
};

const Tag = ({ type, sidebarHeaderStartText }: ITag) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchedItems, setSearchedItems] = useState([]);

  const [offset, setOffset] = useState(0);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { carBrands } = useSelector((state) => state.vehicle);
  const user = useSelector((state) => state.user);
  const tagsData = useSelector((state) => state.sideBarControler.tagsData);
  const userData = {
    ...user,
    brands: carBrands,
    collaborators: user.users,
    admins: user.users,
  };

  useEffect(() => {
    if (type === 'groups') {
      // eslint-disable-next-line no-unused-expressions
      user.groups.length === 0 &&
        dispatch(getGroupsStart({ offset: 0, limit: 20 }));
    } else if (
      type === 'users' ||
      type === 'collaborators' ||
      type === 'admins'
    ) {
      dispatch(
        getFollowersStart({
          offset,
          search: searchValue,
          limit: DEFAULT_PAGE_SIZE,
        })
      );
    } else if (type === 'events') {
      dispatch(getEventsStart());
    } else if (type === 'brands') {
      dispatch(getCarBrandsStart());
    }
  }, [dispatch, type]);

  useEffect(() => {
    const selectedTags = tagsData[type];
    if (selectedTags && !!selectedTags.length) {
      setSearchedItems(selectedTags);
    }
  }, [tagsData, type]);

  const onSelectClick = (item) => {
    const newSearchedItems = searchedItems.filter((el) => el._id !== item._id);

    if (newSearchedItems.length === searchedItems.length) {
      setSearchedItems([...searchedItems, item]);
    } else {
      setSearchedItems([...newSearchedItems]);
    }
  };

  const onSubmit = () => {
    dispatch(addTags({ type, tags: searchedItems }));
  };

  const searchItem = (item: any, image, name, subName = null) => (
    <SearchItemWrap
      onClick={() => onSelectClick(item)}
      borderBottom
      key={item._id}
    >
      <CheckboxWrap>
        {searchedItems.map((el) => el._id).includes(item._id) ? (
          <CircleWithCheckMark />
        ) : (
          <EmptyCheckbox />
        )}
      </CheckboxWrap>
      <ItemImageWrap>
        <Image
          height={48}
          width={48}
          src={image.trim().length ? image : '/assets/img/emptyUserPhoto.png'}
          alt="group"
          unoptimized
          priority
        />
      </ItemImageWrap>
      <ItemInfoWrap>
        <ItemInfoHeaderText>{name}</ItemInfoHeaderText>
        {subName && <ItemInfoText>{subName}</ItemInfoText>}
      </ItemInfoWrap>
    </SearchItemWrap>
  );

  const selectedItem = (id: string, image: string, name: string) => (
    <SelectedItemWrap key={id}>
      <ItemImageWrap isItemSelected>
        <Image
          height={48}
          width={48}
          src={image.trim().length ? image : '/assets/img/emptyUserPhoto.png'}
          alt="group"
          unoptimized
          priority
        />
      </ItemImageWrap>
      <SelectedItemName>{name}</SelectedItemName>
    </SelectedItemWrap>
  );

  const searchedItemsList = search(userData[type] || [], searchValue, type).map(
    (item) => {
      if (type === 'events') {
        return searchItem(item, item.coverPhoto, item.name);
      }

      if (
        type === 'users' ||
        type === 'collaborators' ||
        type === 'admins' ||
        type === 'groups'
      ) {
        return searchItem(
          item,
          item.profilePhoto,
          item.username,
          type === 'groups' ? item.name : item.fullName
        );
      }

      if (type === 'brands') {
        const isItemSelected = searchedItems
          .map((el) => el._id)
          .includes(item._id);

        return (
          <Pill
            key={item._id}
            text={item.name || item.brand}
            onClick={() => onSelectClick(item)}
            fontSize={13}
            margin="0 10px 8px 0"
            backgroundColor={
              isItemSelected
                ? style.mainBlue.blue
                : style.mainGray.whiteSmokeColor
            }
            color={
              isItemSelected ? style.mainWhite.white : style.mainBlack.black
            }
          />
        );
      }

      return null;
    }
  );

  const selectedItemsList = searchedItems.map((item) => {
    if (type === 'events') {
      return selectedItem(item._id, item.coverPhoto, item.name);
    }

    if (
      type === 'users' ||
      type === 'collaborators' ||
      type === 'admins' ||
      type === 'groups'
    ) {
      return selectedItem(item._id, item.profilePhoto, item.username);
    }

    if (type === 'brands') {
      return (
        <Pill
          key={item._id}
          text={item.name || item.brand}
          onClick={() => onSelectClick(item)}
          fontSize={13}
          margin="0 10px 8px 0"
          iconRight={
            <CrossIcon width="12" height="12" color={style.mainWhite.white} />
          }
          backgroundColor={style.mainBlue.blue}
          color={style.mainWhite.white}
        />
      );
    }

    return null;
  });

  const renderSelectedList = () => {
    if (selectedItemsList.length) {
      return type !== 'brands' ? (
        <SelectedItemsWrap>
          <ScrollMenu
            data={selectedItemsList}
            wrapperStyle={{ width: '100%', textAlign: 'left' }}
            alignCenter={false}
            itemStyle={{ border: 'none', outline: 'none' }}
          />
        </SelectedItemsWrap>
      ) : (
        <>
          <SearchHeader mb={16}>{t('common:selected')}</SearchHeader>
          <div style={{ marginLeft: 16 }}>{selectedItemsList}</div>
        </>
      );
    }

    return type !== 'brands' ? (
      <NoSelectedItemsWrap>
        <Text italic>
          {type === 'collaborators'
            ? t('common:thereAreNoSelectedCollaborators')
            : t('common:thereAreNoSelectedTags')}
        </Text>
      </NoSelectedItemsWrap>
    ) : null;
  };

  const getHeaderText = () => {
    if (type === 'brands') {
      return `${sidebarHeaderStartText || 'Event'} ${type}`;
    }

    if (type === 'collaborators' || type === 'admins') {
      return `${sidebarHeaderStartText || 'My'} ${type}`;
    }

    return `Tag ${type}`;
  };

  return (
    <SidebarWrapper
      headerText={getHeaderText()}
      headerIcon={<ArrowBack />}
      submitText={t('editUserProfile:Save')}
      isSubmitAvailable
      onSubmit={onSubmit}
      onIconClick={() =>
        dispatch(closeSidebarTag(actionTypes.CLOSE_SIDEBAR_TAG))
      }
      contentPadding="20px 0"
    >
      <InputWrapper>
        <InputField
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          iconLeft={<SearchIcon colorStroke={style.mainGray.silver} />}
        />
      </InputWrapper>

      {renderSelectedList()}

      <SearchHeader
        mb={type === 'brands' ? 16 : 0}
        mt={type === 'brands' ? 6 : 0}
      >
        {t('search:searchResults')}
      </SearchHeader>
      {!searchedItemsList.length ? (
        <NoResultWrap>
          <Text mb={42}>{t('chat:nothingFound')}</Text>
          <WheelIcon />
        </NoResultWrap>
      ) : (
        <div style={{ marginLeft: type === 'brands' ? 16 : 0 }}>
          {searchedItemsList}
        </div>
      )}
    </SidebarWrapper>
  );
};

export default Tag;
