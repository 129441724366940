import styled from 'styled-components';

interface IProps {
  isAuthenticate?: boolean;
  isAuthPath?: boolean;
}

export const HeaderWrapper = styled.header<IProps>`
  position: sticky;
  top: 0;
  z-index: 100;
  min-height: 56px;
  background: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.text};
  box-shadow: ${(props) => props.theme.colors.shadows.sm};
  box-sizing: border-box;
  width: 100%;
  ${(props) => {
    if (!props.isAuthenticate && props.isAuthPath) {
      return `
     @media(max-width: 480px) {
      display: none;
     }
    `;
    }
  }}
  @media(max-width: 767px) {
    position: fixed;
  }
`;

export const DesktopLogOutHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 24px;

  @media (max-width: 1015px) {
    flex-direction: column;
  }
`;

export const DesktopLogOutHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  & > button {
    margin-left: 20px;
    padding: 13px 10px;
  }
  @media (max-width: 1015px) {
    margin-bottom: 10px;
  }
`;

export const DesktopLogOutHeaderRight = styled.div`
  & > form {
    display: flex;
    align-items: center;
    justify-content: end;

    & > div {
      &:first-child {
        margin: 0;
        width: 200px;
      }
      &:nth-child(2) {
        margin: 0 10px;
        width: 200px;

        & > div {
          top: 10px;
          right: 10px;
        }
      }
      &:nth-child(3) {
        position: relative;
        order: -1;
        margin: 0 16px 0 0;
        padding: 10px 0;

        &::after {
          content: '';
          position: absolute;
          width: 1px;
          top: 0;
          bottom: 0;
          background: ${(props) => props.theme.colors.neutral.n5};
        }

        & > span {
          padding-right: 16px;
        }
      }
      & > label {
        display: none;
      }

      & > input {
        padding: 9px 16px;

        &::placeholder {
          color: ${(props) => props.theme.colors.neutral.n7};
        }
      }

      & > .validation-error {
        display: none;
      }
    }

    & > button {
      margin: 0;
      padding: 13px 10px;
      width: auto;
      margin-bottom: 1px;
      background: ${(props) => props.theme.colors.blue};

      &:hover {
        background: ${(props) => props.theme.colors.blue};
      }

      & > div {
        height: 14px;
      }
    }
  }
`;

export const DesktopLogInHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 860px;
  margin: 0 auto;
  min-height: 56px;
  padding: 0 24px;
`;

export const DesktopLoginHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > a {
    position: relative;
    padding-right: 24px;
  }

  & > ul {
    margin-left: 24px;
  }
`;

export const RightBorder = styled.div`
  position: absolute;
  width: 1px;
  height: 60px;
  right: 0;
  top: -10px;
  background-color: #ececec;
`;

export const DesktopLoginHeaderRight = styled.div`
  display: flex;

  & > div {
    display: flex;
    & > button {
      position: relative;
      z-index: 120;
    }
    &:not(:last-child) {
      margin-right: 10px;
    }

    &:first-child {
      & > div {
        right: auto;
      }
    }
  }
`;

export const CreateButton = styled.span`
  display: flex;
  align-items: center;

  & > span:last-child {
    margin-left: 8px;
  }
`;

export const IconWrapper = styled.span`
  display: flex;
`;

export const TextWrapper = styled.span`
  font-family: ${(props) => props.theme.typography.fontFamily.primary};
  font-weight: ${(props) => props.theme.typography.fontWeight.medium};
  font-size: ${(props) => props.theme.typography.fontSize.f3};
  color: ${(props) => props.theme.colors.text};
  line-height: 100%;
  letter-spacing: 0.02em;
`;

export const ProfileButton = styled.span`
  display: flex;
  align-items: center;

  & > span {
    border-radius: 50%;
    &:nth-child(2) {
      margin: 0 8px;
    }
  }
`;

export const MobileAuthHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 200;
`;
