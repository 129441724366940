/* eslint-disable no-unused-expressions */
import { call, put, takeEvery } from 'redux-saga/effects';
import {
  actionTypes,
  getSettingsSuccess,
  getSettingsError,
  changeSettingsSuccess,
  changeSettingsError,
  changeEmailSuccess,
  changeEmailError,
  changePasswordSuccess,
  changePasswordError,
} from './actions';
import {
  GetSettings,
  PatchSettings,
  PostEmail,
  postPassword,
} from '../../services';
import { getLocalStorage } from '../../utils/hooks';

function* workerGetSettings() {
  try {
    const userId = yield call(getLocalStorage, 'user_id');
    const data = yield call(GetSettings, JSON.parse(userId));

    yield put(getSettingsSuccess(data));
  } catch (err) {
    yield put(getSettingsError(err));
  }
}

function* workerChangeSettings(action) {
  try {
    const userId = yield call(getLocalStorage, 'user_id');
    const data = yield call(
      PatchSettings,
      JSON.parse(userId),
      action.data.settings
    );

    yield put(changeSettingsSuccess(data));
  } catch (err) {
    yield put(changeSettingsError(err));
  }
}

function* workerChangeEmail(action) {
  try {
    const userId = yield call(getLocalStorage, 'user_id');
    const resp = yield call(
      PostEmail,
      JSON.parse(userId),
      action.data.newEmail
    );

    resp
      ? yield put(changeEmailError(resp.error.message || resp.error))
      : yield put(changeEmailSuccess());
  } catch (err) {
    yield put(changeEmailError(err));
  }
}

function* workerChangePassword(action) {
  try {
    const resp = yield call(
      postPassword,
      action.data.newPassword,
      action.data.newPasswordConfirmation
    );

    console.log('PASSWORD RESP', resp);

    resp
      ? yield put(changePasswordError(resp.error.message || resp.error))
      : yield put(changePasswordSuccess());
  } catch (err) {
    yield put(changePasswordError(err));
  }
}

function* watchSettings() {
  yield takeEvery(actionTypes.GET_SETTINGS_START, workerGetSettings);
  yield takeEvery(actionTypes.CHANGE_SETTINGS_START, workerChangeSettings);
  yield takeEvery(actionTypes.CHANGE_EMAIL_START, workerChangeEmail);
  yield takeEvery(actionTypes.CHANGE_PASSWORD_START, workerChangePassword);
}

export default watchSettings;
