import React from 'react';

import { DefaultUserIcon } from 'public/assets/icons';
import { style } from 'utils/constants/style';
import { IEmptyUserProfilePhoto } from './IEmptyUserProfilePhoto';
import { Wrapper } from './EmptyUserProfilePhoto.style';

const EmptyUserProfilePhoto = ({
  wrapSize,
  userIconSize,
  margin,
  icon,
  withBorder = true,
  onClick,
  cursor,
  withShadow = false,
  borderColor = style.mainWhite.white,
}: IEmptyUserProfilePhoto) => (
  <Wrapper
    wrapSize={wrapSize}
    margin={margin}
    withBorder={withBorder}
    onClick={onClick}
    cursor={cursor}
    withShadow={withShadow}
    borderColor={borderColor}
  >
    <DefaultUserIcon size={String(userIconSize)} />

    {icon}
  </Wrapper>
);

export default EmptyUserProfilePhoto;
