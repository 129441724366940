/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import { format } from 'date-fns';
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { EditorState, ContentState } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createMentionPlugin, {
  defaultSuggestionsFilter,
} from '@draft-js-plugins/mention';

import { searchUserAutocomplete } from 'services/search';
import {
  ThreeDotsIcon,
  ArrowBack,
  SmileyIcon,
  PaperPlaneIcon,
  GroupChatIcon,
  EventChatIcon,
  CalendarChatIcon,
  ChatPlaceholderIcon,
} from 'public/assets/icons';
import {
  closeSidebarChatConversation,
  openSidebarNewMultipleChat,
  clearSidebarStore,
} from 'store/sideBarControler/actions';
import {
  joinRoom,
  leaveRoom,
  sendMessage,
  uploadMedia,
  setOnNewMessage,
  deleteChat,
  deleteMessage,
  setCurrentChatScreen,
  connect as connectSocket,
  disconnect as disconnectSocket,
  setNewMessageListener,
} from 'utils/chatSocketManager2';
import getMentionData from 'utils/getMentionData';
import { useWindowSize, getLocalStorage } from 'utils/hooks';
import { MESSAGE_TYPE } from 'utils/constants';
import {
  insertNewMessage,
  getMessagesStart,
  deleteChatMessage,
  deleteChatStart,
  clearMessagesReqStat,
  uploadChatMediaAws,
  changeChatStatus,
  markMessageAsSent,
  clearCreatedChatInfo,
  clearChatsData,
} from 'store/chat/actions';
import { routesPath } from 'utils/constants/routePath';
import { LoadingComponent } from 'components';
import { AsyncStatus } from 'utils/types';

import { style } from 'utils/constants/style';
import { ListUserUserActions } from '../../../Popover/Popover.style';
import {
  EmptyUserProfilePhoto,
  DateMessageItem,
  RightMessageItem,
  LeftMessageItem,
  MediaMessageItem,
} from '../../../index';
import { SidebarWrapper } from '../../index';
import { IChatConversation } from './IChatConversation';

import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/mention/lib/plugin.css';
import {
  CentralizeContentWrap,
  UserProfilePhoto,
  UsernameText,
  ThreeDotsWrap,
  InputFieldWrap,
  IconWrap,
  CameraIconWrap,
  ChatMessagesWrap,
  ProfilePhotoIcon,
  PopOverWrap,
  EmojiPickerWrap,
  MessageWrap,
  ImageInput,
  PlaceholderWrap,
  Text,
} from './ChatConversation.style';

export const ChatConversation = ({ chatData }: IChatConversation) => {
  const [messagesList, setMessagesList] = useState([]);
  const [message, setMessage] = useState('');
  const [newMessageId, setNewMessageId] = useState('');
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isNotificationsOn, setIsNotificationsOn] = useState(
    chatData.notifications
  );
  const [sentIconColor, setSentIconColor] = useState(style.mainBlue.blue);
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const [footerHeight, setFooterHeight] = useState(63);

  const [firstItemIndex, setFirstItemIndex] = useState(10000);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(ContentState.createFromText(''))
  );
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState<any>([]);

  const router = useRouter();
  const size = useWindowSize();
  const { messages, roomInfo, createdChatInfo, getMessagesReqStat } =
    useSelector((state) => state.chat);
  const { t } = useTranslation();

  let photoUrl;
  let name;
  let currentChatId;
  let type;

  if (roomInfo || createdChatInfo) {
    const { chat } = roomInfo || createdChatInfo;

    photoUrl = chat.photoUrl;
    name = chat.name;
    currentChatId = chat._id;
    type = chat.type;
  } else {
    photoUrl = chatData.photoUrl;
    name = chatData.name;
    currentChatId = chatData._id;
    type = chatData.type;
  }

  const dispatch = useDispatch();

  const ref = useRef<Editor>(null);
  const endMessagesRef = useRef(null);
  const chatFooterRef = useRef(null);

  const userProfile = JSON.parse(getLocalStorage('user_data')).data;

  const getMessages = (chatId, forNextPageMessages) =>
    dispatch(getMessagesStart({ chatId, forNextPageMessages }));

  const scrollToMyRef = () => {
    endMessagesRef.current.scrollIntoView();
  };

  const onNewMessage = useCallback((chatMessage) => {
    chatMessage = {
      ...chatMessage,
      isLoading: false,
      isMe: false,
      messageType: MESSAGE_TYPE.MESSAGE_LEFT_SIMPLE_NO_TITLE_ITEM,
    };
    dispatch(insertNewMessage(chatMessage));
  }, []);

  useEffect(() => {
    if (!chatData._id) {
      const token = JSON.parse(getLocalStorage('token'));

      connectSocket(token);
    }

    setCurrentChatScreen('chat');
    setOnNewMessage(onNewMessage);
    ref?.current?.focus();

    if (!chatData._id) {
      return () => {
        dispatch(clearChatsData());
        disconnectSocket();
        leaveRoom({ chatId: currentChatId });
        setCurrentChatScreen('chatList');
        dispatch(clearMessagesReqStat());
        dispatch(changeChatStatus({ chatId: currentChatId, isRead: true }));
      };
    }
    return () => {
      dispatch(clearCreatedChatInfo());
      leaveRoom({ chatId: currentChatId });
      setCurrentChatScreen('chatList');
      dispatch(clearMessagesReqStat());
      dispatch(changeChatStatus({ chatId: currentChatId, isRead: true }));
    };
  }, []);

  useEffect(() => {
    if (currentChatId) {
      getMessages(currentChatId, false);
      joinRoom({ chatId: currentChatId });
    }
  }, [currentChatId]);

  useEffect(() => {
    if (!chatData._id) {
      setNewMessageListener();
      setOnNewMessage(onNewMessage);
    }
  }, [onNewMessage]);

  useEffect(() => {
    if (currentChatId && messages[currentChatId]) {
      const tempMessages = _.get(
        messages,
        `${currentChatId}.convertedMessages`,
        []
      );

      setFirstItemIndex(
        firstItemIndex - (tempMessages.length - messagesList.length)
      );

      setMessagesList(tempMessages);
    }
  }, [messages, currentChatId]);

  useEffect(() => {
    if (newMessageId) {
      dispatch(markMessageAsSent(newMessageId));
      scrollToMyRef();
    }
  }, [newMessageId]);

  // TODO: Implement marking message as read throught socket iteraction
  // useEffect(() => {
  //   if (chatId !== '') {
  //     const tempMessages = _.get(messages, `${chatId}.convertedMessages`, []);
  //     const messageIndex = tempMessages.findIndex(
  //       (element) => String(element._id) === newMessageId
  //     );

  //     if (messageIndex >= 0) {
  //       dispatch(markMessageAsSent(messageIndex));
  //     }
  //   }
  // }, [newMessageId]);

  useEffect(() => {
    if (
      chatFooterRef?.current?.clientHeight &&
      chatFooterRef?.current?.clientHeight !== footerHeight
    ) {
      setFooterHeight(chatFooterRef?.current?.clientHeight);
    }
  }, [message]);

  const handleOnSend = () => {
    setIsEmojiPickerOpen(false);

    if (_.trim(message) === '') {
      return;
    }

    setEditorState(EditorState.createEmpty());

    const chatMessage = {
      _id: moment().valueOf(),
      username: userProfile.username,
      to: currentChatId,
      from: userProfile._id,
      profilePhoto: _.trim(userProfile.profilePhoto),
      isMe: true,
      datetime: moment().toISOString(),
      message,
      isLoading: true,
      // messageType: MESSAGE_TYPE.MESSAGE_RIGHT_ITEM,
    };
    setMessage('');
    sendMessage({ chatId: currentChatId, message }, (data) => {
      dispatch(insertNewMessage({ ...chatMessage, _id: data._id }));
      setTimeout(() => setNewMessageId(data._id), 1000);
    });
  };

  const onEmojiClick = (emoji) => {
    setMessage(`${message}${emoji.native} `);
    setEditorState(
      EditorState.createWithContent(
        ContentState.createFromText(`${message}${emoji.native} `)
      )
    );
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleOnSend();
      ref.current.blur();
      setTimeout(() => ref.current.focus(), 50);
    }
  };

  const onDeleteMessageClick = (messageId, chatId) => {
    deleteMessage({ chatId, messageId }, () =>
      dispatch(deleteChatMessage(messageId))
    );
  };

  const onDeleteChatClick = (chatId) => {
    deleteChat({ chatId }, () => {
      dispatch(deleteChatStart(chatId));
      dispatch(closeSidebarChatConversation());
    });
  };

  const handleSendImage = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      const { size } = file;
      const fileName = encodeURIComponent(file.name);
      const chatId = currentChatId;
      const imageToPresent = URL.createObjectURL(file);

      const mediaForUpload = {
        type: 'image/jpeg',
        size,
      };
      const mediaForSend = {
        type: 'image/jpeg',
        url: fileName,
      };

      const chatMessage = {
        _id: moment().valueOf(),
        username: userProfile.username,
        to: chatId,
        from: userProfile._id,
        profilePhoto: _.trim(userProfile.profilePhoto),
        isMe: true,
        datetime: moment().toISOString(),
        message: '',
        isLoading: true,
        media: { ...mediaForSend, url: imageToPresent },
        // messageType: MESSAGE_TYPE.MESSAGE_RIGHT_ITEM,
      };

      uploadMedia({ chatId, mediaForUpload }, (data) => {
        dispatch(insertNewMessage({ ...chatMessage, _id: data.messageId }));
        dispatch(uploadChatMediaAws({ chatId, data, mediaForSend, file }));
        setNewMessageId(data.messageId);
      });
    }
  };

  const loadNextPage = () => {
    const chat = messages[currentChatId];

    if (!chat.isAllLoaded) {
      getMessages(currentChatId, chat.next);
    }
  };

  const getChatIcon = () => {
    if (type === 'multiple') {
      return <GroupChatIcon size="13" />;
    }

    if (type === 'members') {
      return <EventChatIcon size="13" />;
    }

    if (type === 'event') {
      return <CalendarChatIcon size="13" />;
    }
  };

  const onChatHeaderClick = () => {
    if (type === 'multiple') {
      return;
    }

    const { originId } = messages[currentChatId].chat;

    if (type === 'single') {
      router.push(`${routesPath.user_details}/${originId}`);
    }

    if (type === 'members') {
      router.push(`${routesPath.group_details}/${originId}`);
    }

    if (type === 'event') {
      router.push(`${routesPath.event_details}/${originId}`);
    }

    dispatch(clearSidebarStore());
  };

  const renderUserInfo = () => (
    <CentralizeContentWrap
      cursor={type === 'multiple' ? 'auto' : 'pointer'}
      onClick={onChatHeaderClick}
    >
      {photoUrl && photoUrl.trim() ? (
        <UserProfilePhoto mr={9} ml={6} img={photoUrl}>
          <span role="img" aria-label="user profile img" />

          {type !== 'single' && (
            <ProfilePhotoIcon>{getChatIcon()}</ProfilePhotoIcon>
          )}
        </UserProfilePhoto>
      ) : (
        <EmptyUserProfilePhoto
          wrapSize={36}
          userIconSize={18}
          margin="0 9px 0 6px"
          withBorder={false}
          icon={
            type !== 'single' ? (
              <ProfilePhotoIcon>{getChatIcon()}</ProfilePhotoIcon>
            ) : null
          }
        />
      )}
      <UsernameText>{name}</UsernameText>
    </CentralizeContentWrap>
  );

  const renderMessageItem = (index, item) => {
    let messageItem;
    const message =
      item.messageType !== MESSAGE_TYPE.DATE_ITEM
        ? {
            ...item,
            datetime: item.datetime ? format(new Date(item.datetime), 'p') : '',
          }
        : {};
    let alignSelf;
    let key;

    if (item.messageType === MESSAGE_TYPE.DATE_ITEM) {
      messageItem = <DateMessageItem date={item.date} />;
      alignSelf = 'center';
      key = index;
    } else if (
      message.messageType === MESSAGE_TYPE.MESSAGE_LEFT_SIMPLE_ITEM ||
      message.messageType === MESSAGE_TYPE.MESSAGE_LEFT_SIMPLE_NO_TITLE_ITEM ||
      message.messageType === MESSAGE_TYPE.MESSAGE_LEFT_COMPLETED_ITEM ||
      message.messageType === MESSAGE_TYPE.MESSAGE_LEFT_COMPLETED_NO_TITLE_ITEM
    ) {
      const onMessageOwnerPress = () => {
        dispatch(clearSidebarStore());
        router.push(`${routesPath.user_details}/${message.from}`);
      };

      messageItem = (
        <LeftMessageItem
          messageType={message.messageType}
          profilePhoto={message.profilePhoto}
          username={message.username}
          message={message.message}
          datetime={message.datetime}
          onMessageOwnerPress={onMessageOwnerPress}
        />
      );
      alignSelf = 'flex-start';
      key = message._id;
    } else if (
      item.messageType === MESSAGE_TYPE.MESSAGE_LEFT_MEDIA_ITEM ||
      item.messageType === MESSAGE_TYPE.MESSAGE_RIGHT_MEDIA_ITEM
    ) {
      messageItem = (
        <MediaMessageItem
          messageType={message.messageType}
          profilePhoto={message.profilePhoto}
          datetime={message.datetime}
          media={message.media}
          onClick={() => alert('image click')}
          onDeleteMessageClick={() =>
            onDeleteMessageClick(message._id, currentChatId)
          }
        />
      );
      key = message._id;
    } else {
      messageItem = (
        <RightMessageItem
          messageType={message.messageType}
          message={message.message}
          isLoading={message.isLoading}
          datetime={message.datetime}
          onDeleteMessageClick={() =>
            onDeleteMessageClick(message._id, currentChatId)
          }
        />
      );
      alignSelf = 'flex-end';
      key = message._id;
    }

    return (
      <MessageWrap
        alignSelf={alignSelf}
        key={key}
        centralize={item.messageType === MESSAGE_TYPE.DATE_ITEM}
      >
        {messageItem}
      </MessageWrap>
    );
  };

  const renderMessagesList = useMemo(() => {
    if (messages && messages[currentChatId]) {
      return (
        <InfiniteScroll
          next={loadNextPage}
          dataLength={messagesList.length}
          inverse
          hasMore={!messages[currentChatId].isAllLoaded}
          loader={<div />}
          scrollableTarget="chat"
          style={{
            display: 'flex',
            flexDirection: 'column-reverse',
            overflow: 'visible',
          }}
        >
          {messagesList.map((el, index) => renderMessageItem(index, el))}
        </InfiniteScroll>
      );
    }

    return null;
  }, [messagesList]);

  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin({
      mentionPrefix: '@',
    });

    // eslint-disable-next-line no-shadow
    const { MentionSuggestions } = mentionPlugin;
    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  const onOpenChange = useCallback((_open: boolean) => {
    setOpen(_open);
  }, []);

  const onSearchChange = useCallback(async ({ value }: { value: string }) => {
    try {
      if (value) {
        const ddd = await searchUserAutocomplete({
          query: value,
        });
        const usernames = ddd?.data?.usernames;
        setSuggestions(
          defaultSuggestionsFilter(value, getMentionData(usernames))
        );
      }
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleChange = (e) => {
    setEditorState(e);
    setMessage(e.getCurrentContent().getPlainText());
  };

  const onSidebarHeaderBackClick = () => {
    dispatch(closeSidebarChatConversation());
  };

  const renderContent = () => {
    if (getMessagesReqStat !== AsyncStatus.SUCCESS && !messagesList.length) {
      return <LoadingComponent fullWidth color="#C2C2C2" variant="spin" />;
    }

    if (
      getMessagesReqStat === AsyncStatus.SUCCESS &&
      !messages[currentChatId]?.convertedMessages?.length
    ) {
      return (
        <PlaceholderWrap>
          <ChatPlaceholderIcon />
          <Text>{t('chat:chatConversationPlaceholder')}</Text>
        </PlaceholderWrap>
      );
    }

    return (
      <ChatMessagesWrap
        id="chat"
        height={size.height ? size.height - (105 + footerHeight) : 0}
      >
        <div ref={endMessagesRef} />
        {renderMessagesList}
      </ChatMessagesWrap>
    );
  };

  return (
    <SidebarWrapper
      onSidebarClick={() => isEmojiPickerOpen && setIsEmojiPickerOpen(false)}
      headerTextElement={renderUserInfo()}
      headerIcon={<ArrowBack />}
      headerRightIcon={
        <>
          <ThreeDotsWrap>
            <ThreeDotsIcon colorStroke={style.mainGray.SuvaGrey} />
          </ThreeDotsWrap>
          {isPopoverOpen && (
            <PopOverWrap>
              <ListUserUserActions>
                <li
                  onClick={() =>
                    dispatch(
                      openSidebarNewMultipleChat({ chatId: currentChatId })
                    )
                  }
                >
                  {t('chat:viewAllMembers')}
                </li>
                <li
                  onClick={() => {
                    chatData.handleMuteNotifications();
                    setIsNotificationsOn(!isNotificationsOn);
                  }}
                >
                  {isNotificationsOn
                    ? t('chat:muteNotificationsOption')
                    : t('chat:unmuteNotificationsOption')}
                </li>
                <li onClick={() => onDeleteChatClick(currentChatId)}>
                  {t('chat:removeChat')}
                </li>
              </ListUserUserActions>
            </PopOverWrap>
          )}
        </>
      }
      onIconClick={onSidebarHeaderBackClick}
      onSubmit={() => setIsPopoverOpen(!isPopoverOpen)}
      contentPadding="0"
      isChat
    >
      {renderContent()}

      {chatData && chatData.readOnly && (
        <InputFieldWrap ref={chatFooterRef}>
          {t('event:eventChatBroadcastOn')}
        </InputFieldWrap>
      )}
      {chatData && !chatData.readOnly && (
        <InputFieldWrap ref={chatFooterRef}>
          <CameraIconWrap>
            <svg
              width="19"
              height="16"
              viewBox="0 0 19 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.2895 0C12.3103 0 13.1548 0.745073 13.2953 1.71431L15.0526 1.71429C16.9275 1.71429 18.4554 3.18033 18.5239 5.01432L18.5263 5.14286V12.5714C18.5263 14.4219 17.041 15.93 15.1829 15.9976L15.0526 16H3.47368C1.59882 16 0.0708795 14.534 0.00239596 12.7L0 12.5714V5.14286C0 3.29234 1.48533 1.78424 3.34346 1.71665L3.47368 1.71429L5.23104 1.71431C5.36636 0.780492 6.15523 0.0547526 7.12566 0.00295935L7.23684 0H11.2895ZM11.2895 1.14286H7.23684C6.78544 1.14286 6.41448 1.48279 6.3724 1.91745L6.36842 2C6.36842 2.47339 5.98466 2.85714 5.51128 2.85714H3.47368C2.23347 2.85714 1.22095 3.81941 1.16073 5.02878L1.15789 5.14286V12.5714C1.15789 13.7955 2.13282 14.7949 3.3581 14.8543L3.47368 14.8571H15.0526C16.2929 14.8571 17.3054 13.8949 17.3656 12.6855L17.3684 12.5714V5.14286C17.3684 3.91875 16.3935 2.91938 15.1682 2.85994L15.0526 2.85714H13.015C12.5417 2.85714 12.1579 2.47339 12.1579 2C12.1579 1.55446 11.8135 1.18832 11.3731 1.14678L11.2895 1.14286ZM9.26316 4C11.8211 4 13.8947 6.0467 13.8947 8.57143C13.8947 11.0962 11.8211 13.1429 9.26316 13.1429C6.70521 13.1429 4.63158 11.0962 4.63158 8.57143C4.63158 6.0467 6.70521 4 9.26316 4ZM9.26316 5.14286C7.3447 5.14286 5.78947 6.67788 5.78947 8.57143C5.78947 10.465 7.3447 12 9.26316 12C11.1816 12 12.7368 10.465 12.7368 8.57143C12.7368 6.67788 11.1816 5.14286 9.26316 5.14286ZM15.0526 4.57143C15.3724 4.57143 15.6316 4.82727 15.6316 5.14286C15.6316 5.45845 15.3724 5.71429 15.0526 5.71429C14.7329 5.71429 14.4737 5.45845 14.4737 5.14286C14.4737 4.82727 14.7329 4.57143 15.0526 4.57143Z"
                fill="#8D8D8D"
              />
            </svg>
            <ImageInput
              type="file"
              onChange={handleSendImage}
              accept="image/*"
            />
          </CameraIconWrap>

          <div
            onClick={() => {
              ref.current.focus();
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey && !open) {
                setEditorState(EditorState.createEmpty());
              }
            }}
            style={{
              flexGrow: 1,
              padding: '8px 60px 8px 17px',
              wordBreak: 'break-word',
              marginLeft: 8,
              borderRadius: 18,
              backgroundColor: style.mainGray.snowColor,
              fontSize: 14,
              lineHeight: '21px',
              letterSpacing: '0.01em',
              color: style.mainBlack.black,
              position: 'relative',
              border: 'none',
              outline: 'none',
              fontFamily: 'Poppins',
              resize: 'none',
            }}
          >
            <div>
              <Editor
                editorKey="editor"
                editorState={editorState}
                onChange={handleChange}
                plugins={plugins}
                ref={ref}
                placeholder={t('chat:writeAMessage')}
                keyBindingFn={onKeyDown}
              />
              <MentionSuggestions
                open={open}
                onOpenChange={onOpenChange}
                suggestions={suggestions}
                onSearchChange={onSearchChange}
              />
            </div>
          </div>

          <IconWrap
            width={16}
            height={16}
            bottom={23}
            right={58}
            onClick={() => setIsEmojiPickerOpen(!isEmojiPickerOpen)}
          >
            <SmileyIcon />
          </IconWrap>
          <IconWrap
            width={28}
            height={28}
            rounded
            backgroundColor={style.mainBlue.aliceBlueColor}
            bottom={17}
            right={20}
            onClick={handleOnSend}
            onMouseEnter={() => setSentIconColor(style.mainWhite.white)}
            onMouseLeave={() => setSentIconColor(style.mainBlue.blue)}
          >
            <div
              style={{
                position: 'absolute',
                bottom: 6,
                left: 6,
                height: 14,
                width: 14,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <PaperPlaneIcon colorStroke={sentIconColor} size="14" />
            </div>
          </IconWrap>

          {isEmojiPickerOpen && (
            <EmojiPickerWrap onClick={(e) => e.stopPropagation()}>
              <Picker
                onSelect={onEmojiClick}
                showPreview={false}
                showSkinTones={false}
              />
            </EmojiPickerWrap>
          )}
        </InputFieldWrap>
      )}
    </SidebarWrapper>
  );
};
