import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  borderBottom?: boolean;
  padding?: string;
  isItemSelected?: boolean;
  italic?: boolean;
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
}

export const InputWrapper = styled.div`
  padding: 0 20px 20px 20px;
`;

export const SearchHeader = styled.div<IProps>`
  width: 100%;
  padding-left: 16px;
  height: 40px;
  display: flex;
  align-items: center;
  background-color: ${style.mainGray.whiteSmokeColor};
  color: ${style.mainGray.gray};
  font-size: 13px;
  line-height: 13px;
  box-sizing: border-box;
  letter-spacing: 0.02em;
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
`;

export const SearchItemWrap = styled.div<IProps>`
  width: 100%;
  padding: 8px 17px;
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? `1px solid ${style.mainGray.whiteSmokeColor}` : 'none'};
  box-sizing: border-box;
`;

export const CheckboxWrap = styled.div`
  width: 14px;
  height: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const EmptyCheckbox = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 2px solid ${style.mainGray.whisperColor};
`;

export const ItemImageWrap = styled.div<IProps>`
  height: 48px;
  width: 48px;
  border-radius: 50%;
  overflow: hidden;
  margin: ${({ isItemSelected }) =>
    isItemSelected ? '0 0 4px 0' : '0 10px 0 5px'};
  border: ${({ isItemSelected }) =>
    isItemSelected ? `1px solid ${style.mainWhite.white}` : 'none'};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ItemInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ItemInfoHeaderText = styled.p`
  font-size: 13px;
  line-height: 13px;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
`;

export const SelectedItemName = styled.p`
  font-size: 13px;
  line-height: 120%;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  width: 100%;
  max-width: 100px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ItemInfoText = styled.p`
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.02em;
  color: ${style.mainGray.gray};
  margin-top: 4px;
`;

export const SelectedItemsWrap = styled.div<IProps>`
  width: 100%;
  box-sizing: border-box;
  background-color: ${style.mainGray.whiteSmokeColor};
  padding: 12px 0 34px 0;
  margin-bottom: 20px;
`;

export const NoSelectedItemsWrap = styled.div`
  width: 100%;
  height: 118px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${style.mainGray.whiteSmokeColor};
  margin-bottom: 20px;
`;

export const SelectedItemWrap = styled.div`
  box-sizing: border-box;
  padding: 0 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 16px;
  box-sizing: border-box;
`;

export const Text = styled.p<IProps>`
  font-size: 13px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${style.mainGray.gray};
  font-style: ${({ italic }) => (italic ? 'italic' : 'initial')};
  text-align: center;
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
`;

export const NoResultWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
`;

// styles for tag with adding component

export const AddingWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 12px;
  margin-bottom: 16px;
`;

export const AddingText = styled.p<IProps>`
  font-size: 15px;
  line-height: 100%;
  font-weight: 600;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  margin-right: ${({ mr }) => (mr ? `${mr}px` : '0')};
  margin-left: ${({ ml }) => (ml ? `${ml}px` : '0')};
`;

export const PlusIconWrap = styled.div`
  margin-left: 10px;
  margin-right: -2px;
  display: flex;
  align-items: center;
`;

export const ItemsWrap = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 16px 16px 8px 16px;
`;
