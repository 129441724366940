import React from 'react'

const CopyIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.0002 21.9999H4.00019C3.46427 22.0186 2.94467 21.8138 2.56548 21.4347C2.1863 21.0555 1.98157 20.5359 2.00019 19.9999V9.99995C1.98157 9.46402 2.1863 8.94442 2.56548 8.56524C2.94467 8.18605 3.46427 7.98132 4.00019 7.99995H8.00019V3.99995C7.98157 3.46402 8.1863 2.94442 8.56548 2.56524C8.94467 2.18605 9.46427 1.98132 10.0002 1.99995H20.0002C20.5361 1.98132 21.0557 2.18605 21.4349 2.56524C21.8141 2.94442 22.0188 3.46402 22.0002 3.99995V13.9999C22.0185 14.5358 21.8137 15.0552 21.4346 15.4343C21.0555 15.8134 20.536 16.0183 20.0002 15.9999H16.0002V19.9999C16.0185 20.5358 15.8137 21.0552 15.4346 21.4343C15.0555 21.8134 14.536 22.0183 14.0002 21.9999ZM4.00019 9.99995V19.9999H14.0002V15.9999H10.0002C9.46435 16.0183 8.94493 15.8134 8.56581 15.4343C8.18669 15.0552 7.98187 14.5358 8.00019 13.9999V9.99995H4.00019ZM10.0002 3.99995V13.9999H20.0002V3.99995H10.0002Z" fill="#A7A7A7" />
        </svg>
    )
}

export default CopyIcon