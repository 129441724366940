import React from 'react';
import styled from 'styled-components';
import { IModalWrapper } from './IModalWrapper';

const Wrapper = styled.div`
  color: ${(props) => props.color};
`;

const ModalWrapper = ({ text, color, children }: IModalWrapper) => (
  <Wrapper color={color}>
    {text}
    {children}
  </Wrapper>
);

export default ModalWrapper;
