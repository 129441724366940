/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import Immutable from 'seamless-immutable';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import {
  CrossIcon,
  SearchIcon,
  ChatPlaceholderIcon,
  NotificationsOffIcon,
  WheelIcon,
  GroupChatIcon,
  EventChatIcon,
  CalendarChatIcon,
} from 'public/assets/icons';
import {
  closeSidebarChatsList,
  openSidebarChatConversation,
  openSidebarNewChat,
} from 'store/sideBarControler/actions';
import {
  connect as connectSocket,
  disconnect as disconnectSocket,
  deleteChat,
  setOnNewMessage,
  setNewMessageListener,
} from 'utils/chatSocketManager2';

import { getLocalStorage } from 'utils/hooks';
import {
  getChatsStart,
  setSelectedChatId,
  changeChatSettingsStart,
  deleteChatStart,
  clearChatsData,
  insertNewMessage,
} from 'store/chat/actions';
import { MESSAGE_TYPE } from 'utils/constants';
import { AsyncStatus } from 'utils/types';
import { LoadingComponent } from 'components';

import { style } from 'utils/constants/style';
import { ListUserUserActions } from '../../../Popover/Popover.style';
import { InputField, EmptyUserProfilePhoto } from '../../../index';
import { SidebarWrapper } from '../../index';
import {
  Wrapper,
  SearchFieldWrap,
  PlaceholderWrap,
  Text,
  ChatItemWrap,
  ProfilePhoto,
  ProfilePhotoIcon,
  ChatItemInfoWrap,
  CentralizeContentWrap,
  UsernameText,
  NewMessageDot,
  ChatItemText,
  ChatItemThreeDotsWrap,
  ThreeDotsWrap,
  Dot,
  PopOverWrap,
} from './ChatsList.style';

const getSearchByQueryResults = (q: string, username: string) => {
  const lowerCaseQ = q.toLowerCase();
  const lowerCaseUsername = username.toLowerCase();

  return lowerCaseUsername.includes(lowerCaseQ);
};

const ChatItem = ({
  data,
  chatRef,
}: {
  data: any;
  chatRef: (node: any) => void;
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isNotificationsOn, setIsNotificationsOn] = useState(
    data.notifications
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onChatItemMenuClick = (e) => {
    e.stopPropagation();
    setIsPopoverOpen(!isPopoverOpen);
  };

  const onMuteNotificationsClick = () => {
    dispatch(
      changeChatSettingsStart({
        chatId: data._id,
        settings: { notifications: !isNotificationsOn },
      })
    );
    setIsNotificationsOn(!isNotificationsOn);
  };

  const getChatIcon = () => {
    const { type } = data;

    if (type === 'multiple') {
      return <GroupChatIcon />;
    }

    if (type === 'members') {
      return <EventChatIcon />;
    }

    if (type === 'event') {
      return <CalendarChatIcon />;
    }
  };

  return (
    <ChatItemWrap
      onClick={() => {
        dispatch(setSelectedChatId(data._id));
        dispatch(
          openSidebarChatConversation({
            ...data,
            notifications: isNotificationsOn,
            handleMuteNotifications: onMuteNotificationsClick,
          })
        );
      }}
      onMouseLeave={() => setIsPopoverOpen(false)}
      ref={chatRef}
    >
      {data.photoUrl && data.photoUrl.trim() ? (
        <ProfilePhoto img={data.photoUrl}>
          <span role="img" aria-label="profile img" />

          {data.type !== 'single' && (
            <ProfilePhotoIcon>{getChatIcon()}</ProfilePhotoIcon>
          )}
        </ProfilePhoto>
      ) : (
        <EmptyUserProfilePhoto
          wrapSize={48}
          userIconSize={24}
          margin="0 10px 0 0"
          withBorder={false}
          icon={
            data.type !== 'single' ? (
              <ProfilePhotoIcon>{getChatIcon()}</ProfilePhotoIcon>
            ) : null
          }
        />
      )}

      <ChatItemInfoWrap>
        <CentralizeContentWrap>
          <UsernameText>{data.name}</UsernameText>
          {!data.isRead && <NewMessageDot ml={4} />}
        </CentralizeContentWrap>

        {(!!data.lastMessage.message.trim() || data.lastMessage.media) && (
          <ChatItemText isNewMessage={!data.isRead} isCutting pb={1.5} pt={2.6}>
            {_.isNil(data.lastMessage?.media)
              ? data.lastMessage.message
              : `${data.lastMessage.username} ${t('chat:sentImage')}`}
          </ChatItemText>
        )}

        <CentralizeContentWrap>
          <ChatItemText pt={2.5}>
            {!_.isNil(data.lastMessage)
              ? moment(data.lastMessage.datetime).fromNow()
              : ''}
          </ChatItemText>
          {!isNotificationsOn && (
            <>
              <NewMessageDot
                width={4}
                height={4}
                ml={4}
                mr={4}
                backgroundColor={style.mainGray.silver}
              />
              <NotificationsOffIcon />
            </>
          )}
        </CentralizeContentWrap>
      </ChatItemInfoWrap>

      <ChatItemThreeDotsWrap onClick={onChatItemMenuClick}>
        <ThreeDotsWrap>
          <Dot className="custom-dot" />
          <Dot className="custom-dot" />
          <Dot className="custom-dot" />
        </ThreeDotsWrap>

        {isPopoverOpen && (
          <PopOverWrap width={isNotificationsOn ? 175 : 195}>
            <ListUserUserActions pl={20}>
              <li onClick={onMuteNotificationsClick}>
                {isNotificationsOn
                  ? t('chat:muteNotificationsOption')
                  : t('chat:unmuteNotificationsOption')}
              </li>
              <li
                onClick={() =>
                  deleteChat({ chatId: data._id }, () =>
                    dispatch(deleteChatStart(data._id))
                  )
                }
              >
                {t('chat:removeChat')}
              </li>
            </ListUserUserActions>
          </PopOverWrap>
        )}
      </ChatItemThreeDotsWrap>
    </ChatItemWrap>
  );
};

export const ChatsList = () => {
  const [searchValue, setSearchValue] = useState('');
  const [isLastChatElementVisible, setIsLastChatElementVisible] =
    useState(false);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { data: chatsListData, getChatsReqStat } = useSelector(
    (state) => state.chat.chats
  );
  const { chats } = useSelector((state) => state.chat);

  const getChats = (forNext) => dispatch(getChatsStart(forNext));

  const observer = useRef(null);

  useEffect(() => {
    const token = JSON.parse(getLocalStorage('token'));

    connectSocket(token);
    getChats(false);
    return () => disconnectSocket();
  }, []);

  const onNewMessage = useCallback(
    (chatMessage) => {
      chatMessage = {
        ...chatMessage,
        isLoading: false,
        isMe: false,
        messageType: MESSAGE_TYPE.MESSAGE_LEFT_SIMPLE_NO_TITLE_ITEM,
      };
      dispatch(insertNewMessage(chatMessage));
    },
    [insertNewMessage]
  );

  useEffect(() => {
    if (isLastChatElementVisible) {
      loadNextPage();
      setIsLastChatElementVisible(false);
    }
  }, [isLastChatElementVisible]);

  useEffect(() => {
    setNewMessageListener();
    setOnNewMessage(onNewMessage);
  }, [onNewMessage]);

  const loadNextPage = () => {
    !chats.isAllLoaded && getChats(true);
  };

  const lastChatElement = useCallback(
    (node) => {
      if (observer.current) observer?.current?.disconnect();
      observer.current = new IntersectionObserver((entires) => {
        if (entires[0].isIntersecting) {
          setIsLastChatElementVisible(true);
        }
      });
      if (node) observer.current.observe(node);
    },
    [chatsListData?.length]
  );

  const chatsList = (
    searchValue.length > 0
      ? chatsListData.filter((e) =>
          getSearchByQueryResults(searchValue, e.name)
        )
      : chatsListData
  )?.map((chatItem) => (
    <ChatItem data={chatItem} key={chatItem._id} chatRef={lastChatElement} />
  ));

  const renderPlaceholder = () => {
    if (getChatsReqStat !== AsyncStatus.SUCCESS) {
      return <LoadingComponent fullWidth color="#C2C2C2" variant="spin" />;
    }

    return (
      <PlaceholderWrap>
        <ChatPlaceholderIcon />
        <Text>{t('chat:chatPlaceholder')}</Text>
      </PlaceholderWrap>
    );
  };

  return (
    <SidebarWrapper
      headerText={t('chat:chatsText')}
      headerIcon={<CrossIcon color={style.mainBlack.black} />}
      submitText={t('chat:newChatBtnTitle')}
      isSubmitAvailable
      onSubmit={() => dispatch(openSidebarNewChat())}
      onIconClick={() => {
        dispatch(closeSidebarChatsList());
        dispatch(clearChatsData());
      }}
      contentPadding="0 0 20px 0"
    >
      <Wrapper>
        {/* WAIT FOR BACKEND  */}
        {/* <SearchFieldWrap>
          <InputField
            value={searchValue}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            crossIcon
            onCrossClick={() => setSearchValue('')}
            iconLeft={<SearchIcon />}
            placeholder="Search..."
          />
        </SearchFieldWrap> */}

        {chatsListData.length ? (
          chatsList.length ? (
            chatsList
          ) : (
            <PlaceholderWrap>
              <Text mb={30}>{t('chat:nothingFound')}</Text>
              <WheelIcon />
            </PlaceholderWrap>
          )
        ) : (
          renderPlaceholder()
        )}
      </Wrapper>
    </SidebarWrapper>
  );
};
