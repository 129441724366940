import React from 'react';
import { style } from 'utils/constants/style';

export const CircleWithCheckMark = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="16" r="16" fill={style.mainBlue.blue} />
    <path
      d="M9 16.3448L14.2703 21L22 12"
      stroke={style.mainWhite.white}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
