import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  mr?: number;
  ml?: number;
  mb?: number;
  img?: string;
}

export const InputFieldWrap = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 0 20px;
  margin-bottom: 20px;
`;

export const ParticipantsHeader = styled.div`
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 16px;
  background-color: ${style.mainGray.whiteSmokeColor};
  font-size: 13px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${style.mainGray.gray};
`;

export const ImageUploaderWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

// USER
export const UserWrap = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  box-sizing: border-box;
  border-bottom: 1px solid ${style.mainGray.whiteSmokeColor};
`;

export const UserPhoto = styled.span<IProps>`
  display: block;
  width: 48px;
  height: 48px;
  box-sizing: border-box;
  border-radius: 50%;
  position: relative;
  background: url(${({ img }) => img}) no-repeat;
  background-size: cover;
  background-position: center;
  margin-right: ${({ mr }) => (mr ? `${mr}px` : '0')};
  margin-left: ${({ ml }) => (ml ? `${ml}px` : '0')};
`;

export const UserInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UsernameText = styled.p<IProps>`
  font-size: 13px;
  line-height: '100%';
  font-weight: 500;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
`;

export const FullNameText = styled.p`
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${style.mainGray.SuvaGrey};
`;

// CHAT DETAILS STYLES
export const ChatPhoto = styled.span<IProps>`
  display: block;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: url(${({ img }) => img}) no-repeat;
  background-size: cover;
  background-position: center;
`;

export const ChatNameText = styled.p`
  font-size: 15px;
  line-height: 150%;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  margin-top: 7px;
`;

export const ChatSecondNameText = styled(ChatNameText)`
  font-size: 14px;
  color: ${style.mainGray.gray};
  margin-top: 0;
  font-weight: 400;
`;
