const CLUB_ID_KEY = 'ROADSTR_CLUB_ID';

export const setClubIdInStorage = (clubId) => {
  try {
    if (clubId) {
      localStorage.setItem(CLUB_ID_KEY, clubId);
    } else {
      localStorage.removeItem(CLUB_ID_KEY);
    }
  } catch (e) {
    console.error('Failed to save clubId to storage', e);
  }
};

export const getClubIdFromStorage = () => {
  try {
    const clubId = localStorage.getItem(CLUB_ID_KEY);
    return clubId;
  } catch (e) {
    console.error('Failed to fetch clubId from storage', e);
    return null;
  }
};
