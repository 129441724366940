export const actionTypes = {
  FETCH_API: 'FETCH_API',
  CLEAR_APP_STATE: 'CLEAR_APP_STATE',
};

export const fetchApiReq = (data) => ({
  type: actionTypes.FETCH_API,
  data,
});
export const clearAppState = () => ({
  type: actionTypes.CLEAR_APP_STATE,
});
