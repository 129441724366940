import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { processReferrerQueryData } from 'utils/referrerdata';

const useReferrerData = () => {
  const router = useRouter();

  useEffect(() => {
    // Function to check if a value is a valid string
    processReferrerQueryData(router?.query);
  }, [router.query]);
};

export default useReferrerData;
