import styled from 'styled-components';

interface IProps {
  isSelected?: boolean;
  height?: number;
  length?: number;
}

export const ImageSliderWrapper = styled.div<IProps>`
  user-select: none;
  cursor: pointer;
  .carousel-root {
    position: relative;
    width: 100%;

    .carousel-slider {
      position: static;
      display: flex;
      justify-content: center;
    }

    .control-dots {
      position: absolute;
      width: auto;
      box-sizing: border-box;
      padding: 0 16px;
      background: rgba(0, 0, 0, 0.6);
      border-radius: 8px;
      height: 24px;
      display: ${(props) => (props.length > 1 ? 'flex' : 'none')};
      align-items: center;
    }
    .slider-wrapper {
      padding: 0;
      overflow: hidden;
      background: none;

      ::-webkit-scrollbar {
        width: 0;
        background: transparent;
      }

      ::-webkit-scrollbar-thumb {
        width: 0;
        background: transparent;
      }
    }
  }
`;
export const ImageSliderItemWrapper = styled.div`
  width: 100%;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: rgb(0, 0, 0);
`;

export const CoverImg = styled.div`
  width: 100%;
  height: auto;
`;

export const WheelMarkIconWrap = styled.div`
  width: 100%;

  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 55%,
    rgba(28, 31, 41, 0.2) 100%
  );
  background-color: ${(props) => props.theme.colors.neutral.n4};
`;

export const ArrowPrev = styled.div`
  position: absolute;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  /* padding-right: 3px; */
  top: 50%;
  left: 24px;
  z-index: 50;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: rgba(0, 0, 0, 0.5);
  margin-top: -16px;

  @media only screen and (max-width: 800px) {
    left: 10px;
  }

  svg {
    transform: rotate(180deg);
  }
`;
export const ArrowNext = styled.div`
  position: absolute;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  /* margin-left: 10px; */
  /* padding-left: 1px; */
  top: 50%;
  right: 24px;
  z-index: 50;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  background: rgba(0, 0, 0, 0.5);
  margin-top: -16px;

  @media only screen and (max-width: 800px) {
    right: 10px;
  }
`;

export const ImageWrap = styled.div<IProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #000000;

  & img {
    object-fit: cover;
  }
  & .video-react-control-bar {
    background: transparent;
  }
`;

export const CarouselDots = styled.li<IProps>`
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.isSelected
      ? props.theme.colors.orange
      : props.theme.colors.neutral.n5};
  margin-right: 4px;
  box-sizing: content-box;

  :last-of-type {
    margin-right: 0;
  }
`;
