import React from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { routesPath } from 'utils/constants/routePath';

import {
  CardWrapper,
  GradientWrap,
  VehicleInfoBlock,
} from './VehicleCard.styles';

export interface IVehicleCard {
  isMe: boolean;
  make: string;
  model: string;
  nickname: string;
  photos: string[];
  id: string;
}

const VehicleCard = ({
  id,
  isMe,
  make,
  model,
  nickname,
  photos,
}: IVehicleCard) => {
  const router = useRouter();
  return (
    <CardWrapper>
      <Image
        // loader={() => photos[0]}
        src={
          photos?.length > 0 ? photos[0] : '/assets/img/empty_garage_slide.png'
        }
        alt="post img"
        width={259}
        height={150}
        onClick={() => router.push(`${routesPath.vehicle_details}/${id}`)}
        unoptimized
        priority
      />
      <GradientWrap
        onClick={() => router.push(`${routesPath.vehicle_details}/${id}`)}
      >
        <VehicleInfoBlock>
          {make} {model}
        </VehicleInfoBlock>
      </GradientWrap>
    </CardWrapper>
  );
};

export default VehicleCard;
