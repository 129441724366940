/* eslint-disable no-unused-expressions */
import React, { useCallback, useEffect, useState } from 'react';

import { INavTabs } from './INavTabs';

import { NavTabsContainer, TabContainer } from './NavTabs.styles';

const NavTabs = ({
  tabsList,
  onHandleSelect,
  defaultActive,
  variant = 'default',
}: INavTabs) => {
  const [active, setActive] = useState<number | string>(defaultActive);

  const handleNavTabs = useCallback(
    (id) => {
      onHandleSelect && onHandleSelect(id || defaultActive);
      setActive(id || defaultActive);
    },
    [defaultActive]
  );

  // const updateNav = useCallback(() => {
  //   setActive(defaultActive);
  // }, [defaultActive]);

  // useEffect(() => {
  //   updateNav();
  // }, [updateNav]);
  return (
    <NavTabsContainer variant={variant}>
      {tabsList.map((tab) => (
        <TabContainer
          key={tab.id}
          variant={variant}
          active={tab.id === active}
          withBorder={tab.id !== active}
          onClick={() => handleNavTabs(tab.id)}
        >
          {tab.name}
        </TabContainer>
      ))}
    </NavTabsContainer>
  );
};

export default NavTabs;
