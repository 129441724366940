/* eslint-disable camelcase */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-expressions */
import { call, put, takeEvery } from 'redux-saga/effects';

import {
  createPost,
  parseLink,
  createEvent,
  getEventDetails,
  getPostDetails,
  reportPost,
  reportGroup,
  reportEvent,
  editGroup,
  getGroupDetails,
  getGroupAdmins,
  editEvent,
  cancelEvent,
  deleteEvent,
  editPost,
  deletePost,
  getPopularPosts,
  attendEventWithVehicle,
  attendEventWithoutVehicle,
  cancelEventSubscription,
  getGroupFollowers,
  getGroupMembers,
  deleteGroupFollower,
  deleteGroupMember,
  getEventInvited,
  getEventAttending,
  getLikesPost,
  getLikesVehicle,
  getLikesEvent,
  getGroupAdmins_v12,
  getFeedFollowingPosts,
  getFeedDiscoverPosts,
  getEventsPosts,
  getGroupPosts,
  getGroupVehicles,
  getEventPosts,
  getEventVehicles,
} from 'services';
import { getLocalStorage } from 'utils/hooks';
import {
  createPostSuccess,
  createPostError,
  parseLinkSuccess,
  parseLinkError,
  actionTypes,
  createEventSuccess,
  createEventError,
  getEventDetailsSuccess,
  getEventDetailsError,
  getEventDetailsStart,
  getPostDetailsSuccess,
  getPostDetailsError,
  getPostDetailsStart,
  reportPostSuccess,
  reportPostError,
  reportGroupSuccess,
  reportGroupError,
  reportEventSuccess,
  reportEventError,
  editGroupSuccess,
  editGroupError,
  getGroupDetailsSuccess,
  getGroupDetailsError,
  getGroupDetailsStart,
  editEventSuccess,
  editEventError,
  cancelEventSuccess,
  cancelEventError,
  deleteEventSuccess,
  deleteEventError,
  editPostSuccess,
  editPostError,
  deletePostSuccess,
  deletePostError,
  refreshEventPosts,
  refreshGroupPosts,
  refreshFeedPosts,
  getPopularPostsSuccess,
  getPopularPostsError,
  setIsScheduledPostCreated,
  attendEventWithVehicleSuccess,
  attendEventWithVehicleError,
  attendEventWithoutVehicleSuccess,
  attendEventWithoutVehicleError,
  cancelEventSubscriptionSuccess,
  cancelEventSubscriptionError,
  getGroupFollowersSuccess,
  getGroupFollowersError,
  getGroupMembersSuccess,
  getGroupMembersError,
  deleteGroupFollowerSuccess,
  deleteGroupFollowerError,
  deleteGroupMemberSuccess,
  deleteGroupMemberError,
  getEventInvitedSuccess,
  getEventInvitedError,
  getEventAttendingSuccess,
  getEventAttendingError,
  getLikesPostSuccess,
  getLikesPostError,
  getLikesVehicleSuccess,
  getLikesVehicleError,
  getLikesEventSuccess,
  getLikesEventError,
  getGroupAdminsV12Success,
  getGroupAdminsV12Error,
  getFeedFollowingPostsSuccess,
  getFeedFollowingPostsError,
  getFeedDiscoverPostsSuccess,
  getFeedDiscoverPostsError,
  getFeedEventPostsSuccess,
  getFeedEventPostsError,
  getGroupPostsSuccess,
  getGroupPostsError,
  getGroupVehiclesSuccess,
  getGroupVehiclesError,
  getEventPostsSuccess,
  getEventPostsError,
  getEventVehiclesSuccess,
  getEventVehiclesError,
} from './actions';
import {
  handleRedirectFromMyEvents,
  clearMyEvents,
  getUserPostsStart,
} from '../user/actions';
import { closeSidebarAttendEvent } from '../sideBarControler/actions';

function* workerCreatePost(action) {
  try {
    const { postData, defaultTagId } = action.data;

    const data = yield call(createPost, postData);

    const { code, result } = data;

    if (code === 200 && result === 'ok') {
      if (defaultTagId && postData.events.includes(defaultTagId)) {
        yield put(refreshEventPosts());
      } else if (defaultTagId && postData.groups.includes(defaultTagId)) {
        yield put(refreshGroupPosts());
      }

      yield put(createPostSuccess());
      yield put(refreshFeedPosts(data?.data));

      if (postData.publishAt) {
        yield put(setIsScheduledPostCreated(true));
      }
    } else {
      yield put(createPostError('Error'));
    }
  } catch (err) {
    yield put(createPostError(err));
  }
}

function* workerParseLink(action) {
  try {
    const resp = yield call(parseLink, action.data.link);

    resp.code === 200
      ? yield put(parseLinkSuccess(resp.data))
      : yield put(parseLinkError(resp.data));
  } catch (err) {
    yield put(parseLinkError(null));
  }
}

function* workerCreateEvent(action) {
  try {
    const resp = yield call(createEvent, action.data.eventData);

    resp.code >= 400
      ? yield put(createEventError(resp.data))
      : yield put(createEventSuccess(resp.data));
  } catch (err) {
    yield put(createEventError(err));
  }
}

function* workerGetEventDetails(action) {
  try {
    const resp = yield call(getEventDetails, action.data);

    console.log('EVENT DETAILS', resp);

    yield put(getEventDetailsSuccess(resp.data));
  } catch (err) {
    yield put(getEventDetailsError(err));
  }
}

function* workerGetPostDetails(action) {
  try {
    const resp = yield call(getPostDetails, action.data);

    resp.code === 200
      ? yield put(getPostDetailsSuccess(resp.data))
      : yield put(getPostDetailsError(resp.data));
  } catch (err) {
    yield put(getPostDetailsError(err));
  }
}

function* workerReportPost(action) {
  try {
    const resp = yield call(reportPost, action.data);

    console.log('resp report post', resp);

    resp.status === 204 || resp.code === 200
      ? yield put(reportPostSuccess())
      : yield put(reportPostError(resp.data));
  } catch (err) {
    yield put(reportPostError(err));
  }
}

function* workerReportGroup(action) {
  try {
    const resp = yield call(reportGroup, action.data);

    console.log('resp report group', resp);

    resp.status === 204 || resp.code === 200
      ? yield put(reportGroupSuccess())
      : yield put(reportGroupError(resp.data));
  } catch (err) {
    yield put(reportGroupError(err));
  }
}

function* workerReportEvent(action) {
  try {
    const resp = yield call(reportEvent, action.data);

    console.log('resp report Event', resp);

    resp.status === 204 || resp.code === 200
      ? yield put(reportEventSuccess())
      : yield put(reportEventError(resp.data));
  } catch (err) {
    yield put(reportEventError(err));
  }
}

function* workerEditGroup(action) {
  try {
    const resp = yield call(editGroup, action.data);

    console.log('edit group resp', resp);

    if (resp.status === 204 || resp.code === 200) {
      yield put(editGroupSuccess(resp.data));
      yield put(getGroupDetailsStart(action.data.groupId));
    } else {
      yield put(editGroupError(resp.data));
    }
  } catch (error) {
    yield put(editGroupError(error));
  }
}

function* workerGetGroupDetails(action) {
  try {
    const resp = yield call(getGroupDetails, action.data);

    if (resp.code === 200 && resp.result === 'ok') {
      const adminsResp = yield call(getGroupAdmins, action.data);

      const result = {
        ...resp,
        data: { ...resp.data, admins: adminsResp.data },
      };

      yield put(getGroupDetailsSuccess(result));
    } else {
      yield put(getGroupDetailsError(resp.data));
    }
  } catch (err) {
    yield put(getGroupDetailsError(err));
  }
}

function* workerEditEvent(action) {
  try {
    const resp = yield call(editEvent, action.data);

    console.log('edit event resp', resp);

    if (resp.status === 204 || resp.code === 200) {
      yield put(editEventSuccess(resp.data));
      yield put(getEventDetailsStart(action.data.eventId));
    } else {
      yield put(editEventError(resp.data));
    }
  } catch (err) {
    yield put(editEventError(err));
  }
}

function* workerCancelEvent(action) {
  try {
    const resp = yield call(cancelEvent, action.data);

    if (resp.status === 204 || resp.code === 200) {
      yield put(cancelEventSuccess(resp.data));
      yield put(getEventDetailsStart(action.data));
    } else {
      yield put(cancelEventError(resp.data));
    }
  } catch (err) {
    yield put(cancelEventError(err));
  }
}

function* workerDeleteEvent(action) {
  try {
    const resp = yield call(deleteEvent, action.data);

    if (resp.status === 204 || resp.code === 200) {
      yield put(handleRedirectFromMyEvents(false));
      yield put(clearMyEvents());
      yield put(
        deleteEventSuccess({ isEventDeleted: true, eventId: action.data })
      );
    } else {
      yield put(deleteEventError(resp.data));
    }
  } catch (err) {
    yield put(deleteEventError(err));
  }
}

function* workerEditPost(action) {
  try {
    const resp = yield call(editPost, action.data);

    if (resp.status === 204 || resp.code === 200) {
      yield put(editPostSuccess(action.data));
      yield put(getPostDetailsStart(action.data.postId));
    } else {
      yield put(editPostError(resp.data));
    }
  } catch (err) {
    yield put(editPostError(err));
  }
}

function* workerDeletePost(action) {
  try {
    const resp = yield call(deletePost, action.data);
    const userId = JSON.parse(getLocalStorage('user_id'));

    if (resp.status === 204 || resp.code === 200) {
      yield put(
        deletePostSuccess({ isPostDeleted: true, postId: action.data })
      );
      yield put(getUserPostsStart(userId));
    } else {
      yield put(deletePostError(resp.data));
    }
  } catch (err) {
    yield put(deletePostError(err));
  }
}

function* workerGetPopularPosts() {
  try {
    const resp = yield call(getPopularPosts);

    resp.code === 200 && resp.result === 'ok'
      ? yield put(getPopularPostsSuccess(resp.data))
      : yield put(getPopularPostsError('Error'));
  } catch (err) {
    yield put(getPopularPostsError(err));
  }
}

function* workerAttendEventWithVehicle(action) {
  try {
    const resp = yield call(attendEventWithVehicle, action.data);

    if (resp.status === 204 || resp.code === 200) {
      yield put(attendEventWithVehicleSuccess(action.data.eventId));
      yield put(closeSidebarAttendEvent());
    } else {
      yield put(attendEventWithVehicleError('Error'));
    }
  } catch (err) {
    yield put(attendEventWithVehicleError(err));
  }
}

function* workerAttendEventWithoutVehicle(action) {
  try {
    const resp = yield call(attendEventWithoutVehicle, action.data);

    if (resp.status === 204 || resp.code === 200) {
      yield put(attendEventWithoutVehicleSuccess(action.data));
      yield put(closeSidebarAttendEvent());
    } else {
      yield put(attendEventWithoutVehicleError('Error'));
    }
  } catch (err) {
    yield put(attendEventWithoutVehicleError(err));
  }
}

function* workerCancelEventSubscription(action) {
  try {
    const resp = yield call(cancelEventSubscription, action.data);

    resp.status === 204 || resp.code === 200
      ? yield put(cancelEventSubscriptionSuccess(action.data))
      : yield put(cancelEventSubscriptionError('Error'));
  } catch (err) {
    yield put(cancelEventSubscriptionError(err));
  }
}

function* workerGetGroupFollowers(action) {
  try {
    const resp = yield call(getGroupFollowers, action.data);

    typeof resp.total === 'number'
      ? yield put(
          getGroupFollowersSuccess({ data: resp.data, total: resp.total })
        )
      : yield put(getGroupFollowersError('error'));
  } catch (err) {
    yield put(getGroupFollowersError(err));
  }
}

function* workerGetGroupMembers(action) {
  try {
    const resp = yield call(getGroupMembers, action.data);

    typeof resp.total === 'number'
      ? yield put(
          getGroupMembersSuccess({ data: resp.data, total: resp.total })
        )
      : yield put(getGroupMembersError('error'));
  } catch (err) {
    yield put(getGroupMembersError(err));
  }
}

function* workerGetGroupAdminsV12(action) {
  try {
    const resp = yield call(getGroupAdmins_v12, action.data);

    resp.data
      ? yield put(getGroupAdminsV12Success(resp.data))
      : yield put(getGroupAdminsV12Error('error'));
  } catch (err) {
    yield put(getGroupAdminsV12Error(err));
  }
}

function* workerDeleteGroupFollower(action) {
  try {
    const resp = yield call(deleteGroupFollower, action.data);

    console.log('delete follower g', resp);

    resp.status === 204 || resp.code === 200
      ? yield put(deleteGroupFollowerSuccess())
      : yield put(deleteGroupFollowerError('error'));
  } catch (err) {
    yield put(deleteGroupFollowerError(err));
  }
}

function* workerDeleteGroupMember(action) {
  try {
    const resp = yield call(deleteGroupMember, action.data);

    console.log('delete Member g', resp);

    resp.status === 204 || resp.code === 200
      ? yield put(deleteGroupMemberSuccess())
      : yield put(deleteGroupMemberError('error'));
  } catch (err) {
    yield put(deleteGroupMemberError(err));
  }
}

function* workerGetEventInvited(action) {
  try {
    const resp = yield call(getEventInvited, action.data);

    console.log('resp event invited', resp);

    resp.code === 200
      ? yield put(getEventInvitedSuccess(resp.data))
      : yield put(getEventInvitedError('error'));
  } catch (err) {
    yield put(getEventInvitedError(err));
  }
}

function* workerGetEventAttending(action) {
  try {
    const resp = yield call(getEventAttending, action.data);

    resp.code === 200
      ? yield put(getEventAttendingSuccess(resp.data))
      : yield put(getEventAttendingError('error'));
  } catch (err) {
    yield put(getEventAttendingError(err));
  }
}

function* workerGetLikesPost(action) {
  try {
    const resp = yield call(getLikesPost, action.data);

    resp.code === 200 && resp.result === 'ok'
      ? yield put(getLikesPostSuccess(resp.data))
      : yield put(getLikesPostError('error'));
  } catch (err) {
    yield put(getLikesPostError(err));
  }
}

function* workerGetLikesVehicle(action) {
  try {
    const resp = yield call(getLikesVehicle, action.data);

    resp.code === 200 && resp.result === 'ok'
      ? yield put(getLikesVehicleSuccess(resp.data))
      : yield put(getLikesVehicleError('error'));
  } catch (err) {
    yield put(getLikesVehicleError(err));
  }
}

function* workerGetLikesEvent(action) {
  try {
    const resp = yield call(getLikesEvent, action.data);

    resp.code === 200 && resp.result === 'ok'
      ? yield put(getLikesEventSuccess(resp.data))
      : yield put(getLikesEventError('error'));
  } catch (err) {
    yield put(getLikesEventError(err));
  }
}

function* workerGetFeedFollowingPosts(action) {
  try {
    const resp = yield call(getFeedFollowingPosts, action.data);

    resp.code === 200
      ? yield put(getFeedFollowingPostsSuccess(resp.data))
      : yield put(getFeedFollowingPostsError('Error'));
  } catch (err) {
    yield put(getFeedFollowingPostsError(err));
  }
}

function* workerGetFeedDiscoverPosts(action) {
  try {
    const resp = yield call(getFeedDiscoverPosts, action.data);

    resp.code === 200
      ? yield put(getFeedDiscoverPostsSuccess(resp.data))
      : yield put(getFeedDiscoverPostsError('Error'));
  } catch (err) {
    yield put(getFeedDiscoverPostsError(err));
  }
}

function* workerGetFeedEventPosts(action) {
  try {
    const resp = yield call(getEventsPosts, action.data);

    resp.code === 200
      ? yield put(getFeedEventPostsSuccess(resp.data))
      : yield put(getFeedEventPostsError('Error'));
  } catch (err) {
    yield put(getFeedEventPostsError(err));
  }
}

function* workerGetGroups(action) {
  try {
    const resp = yield call(getGroupPosts, action.data);

    console.log('resp g p', resp);

    resp.code === 200
      ? yield put(getGroupPostsSuccess(resp.data))
      : yield put(getGroupPostsError(resp.data));
  } catch (err) {
    yield put(getGroupPostsError(err));
  }
}

function* workerGetGroupVehicles(action) {
  try {
    const resp = yield call(getGroupVehicles, action.data);

    typeof resp.total === 'number'
      ? yield put(getGroupVehiclesSuccess(resp.data))
      : yield put(getGroupVehiclesError(resp.data));
  } catch (err) {
    yield put(getGroupVehiclesError(err));
  }
}

function* workerGetEventPosts(action) {
  try {
    const resp = yield call(getEventPosts, action.data);

    resp.code === 200
      ? yield put(getEventPostsSuccess(resp.data))
      : yield put(getEventPostsError(resp.data));
  } catch (err) {
    yield put(getEventPostsError(err));
  }
}

function* workerGetEventVehicles(action) {
  try {
    const resp = yield call(getEventVehicles, action.data);
    // TODO: we should check response code and status
    resp?.data
      ? yield put(getEventVehiclesSuccess(resp.data))
      : yield put(getEventVehiclesError(resp.data));
  } catch (err) {
    yield put(getEventVehiclesError(err));
  }
}

function* watchPosts() {
  yield takeEvery(actionTypes.CREATE_POST_START, workerCreatePost);
  yield takeEvery(actionTypes.PARSE_LINK_START, workerParseLink);
  yield takeEvery(actionTypes.CREATE_EVENT_START, workerCreateEvent);
  yield takeEvery(actionTypes.GET_EVENT_DETAILS_START, workerGetEventDetails);
  yield takeEvery(actionTypes.GET_POST_DETAILS_START, workerGetPostDetails);
  yield takeEvery(actionTypes.REPORT_POST_START, workerReportPost);
  yield takeEvery(actionTypes.REPORT_GROUP_START, workerReportGroup);
  yield takeEvery(actionTypes.REPORT_EVENT_START, workerReportEvent);
  yield takeEvery(actionTypes.EDIT_GROUP_START, workerEditGroup);
  yield takeEvery(actionTypes.GET_GROUP_DETAILS_START, workerGetGroupDetails);
  yield takeEvery(actionTypes.EDIT_EVENT_START, workerEditEvent);
  yield takeEvery(actionTypes.CANCEL_EVENT_START, workerCancelEvent);
  yield takeEvery(actionTypes.DELETE_EVENT_START, workerDeleteEvent);
  yield takeEvery(actionTypes.EDIT_POST_START, workerEditPost);
  yield takeEvery(actionTypes.DELETE_POST_START, workerDeletePost);
  yield takeEvery(actionTypes.GET_POPULAR_POSTS_START, workerGetPopularPosts);
  yield takeEvery(
    actionTypes.ATTEND_EVENT_WITH_VEHICLE_START,
    workerAttendEventWithVehicle
  );
  yield takeEvery(
    actionTypes.ATTEND_EVENT_WITHOUT_VEHICLE_START,
    workerAttendEventWithoutVehicle
  );
  yield takeEvery(
    actionTypes.CANCEL_EVENT_SUBSCRIPTION_START,
    workerCancelEventSubscription
  );
  yield takeEvery(
    actionTypes.GET_GROUP_FOLLOWERS_START,
    workerGetGroupFollowers
  );
  yield takeEvery(actionTypes.GET_GROUP_MEMBERS_START, workerGetGroupMembers);
  yield takeEvery(
    actionTypes.DELETE_GROUP_FOLLOWER_START,
    workerDeleteGroupFollower
  );
  yield takeEvery(
    actionTypes.DELETE_GROUP_MEMBER_START,
    workerDeleteGroupMember
  );
  yield takeEvery(actionTypes.GET_EVENT_INVITED_START, workerGetEventInvited);
  yield takeEvery(
    actionTypes.GET_EVENT_ATTENDING_START,
    workerGetEventAttending
  );
  yield takeEvery(actionTypes.GET_LIKES_POST_START, workerGetLikesPost);
  yield takeEvery(actionTypes.GET_LIKES_VEHICLE_START, workerGetLikesVehicle);
  yield takeEvery(actionTypes.GET_LIKES_EVENT_START, workerGetLikesEvent);
  yield takeEvery(
    actionTypes.GET_GROUP_ADMINS_V12_START,
    workerGetGroupAdminsV12
  );
  yield takeEvery(
    actionTypes.GET_FEED_FOLLOWING_POSTS_START,
    workerGetFeedFollowingPosts
  );
  yield takeEvery(
    actionTypes.GET_FEED_DISCOVER_POSTS_START,
    workerGetFeedDiscoverPosts
  );
  yield takeEvery(
    actionTypes.GET_FEED_EVENT_POSTS_START,
    workerGetFeedEventPosts
  );
  yield takeEvery(actionTypes.GET_GROUP_POSTS_START, workerGetGroups);
  yield takeEvery(actionTypes.GET_GROUP_VEHICLES_START, workerGetGroupVehicles);
  yield takeEvery(actionTypes.GET_EVENT_POSTS_START, workerGetEventPosts);
  yield takeEvery(actionTypes.GET_EVENT_VEHICLES_START, workerGetEventVehicles);
}

export default watchPosts;
