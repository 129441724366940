import fetchData from 'utils/fetchData';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const userGarageVehicles = (userId) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOST}api/v01/user/${userId}/garage/vehicles`,
      {
        method: 'GET',
        headers: { ...headers, tsec: token },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const userGaragePageVehicles = (userId, offset, limit) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOSTv12}api/v12/user/${userId}/vehicles?offset=${offset}&limit=${limit}`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};
