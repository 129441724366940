import React from 'react';

export const LocationPinIcon = ({ color = '#A7A7A7' }: { color?: string }) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.33301 12.5288C11.2497 9.53989 12.708 7.97424 12.708 5.26994C12.708 2.91172 10.7493 1 8.33301 1C5.91676 1 3.95801 2.91172 3.95801 5.26994C3.95801 7.97424 5.41634 9.53989 8.33301 12.5288ZM8.33301 4.9707C8.19494 4.9707 8.08301 5.08263 8.08301 5.2207C8.08301 5.35877 8.19494 5.4707 8.33301 5.4707C8.47108 5.4707 8.58301 5.35877 8.58301 5.2207C8.58301 5.08263 8.47108 4.9707 8.33301 4.9707ZM8.33301 6.9707C9.29951 6.9707 10.083 6.1872 10.083 5.2207C10.083 4.2542 9.29951 3.4707 8.33301 3.4707C7.36651 3.4707 6.58301 4.2542 6.58301 5.2207C6.58301 6.1872 7.36651 6.9707 8.33301 6.9707ZM12.9499 11.7255C12.4208 11.5387 11.7833 11.3811 11.0658 11.2662C11.3605 10.9699 11.6361 10.6827 11.8924 10.4017C13.9519 10.8317 15.333 11.6236 15.333 12.5294C15.333 13.8938 12.199 14.9998 8.33301 14.9998C4.46701 14.9998 1.33301 13.8938 1.33301 12.5294C1.33301 11.6236 2.71409 10.8317 4.77357 10.4017C5.02992 10.6827 5.30548 10.9699 5.60026 11.2662C4.88273 11.3811 4.2452 11.5387 3.71607 11.7255C3.14446 11.9272 2.75298 12.1443 2.52377 12.3356C2.4106 12.43 2.3622 12.4952 2.34243 12.5294C2.3622 12.5635 2.4106 12.6287 2.52377 12.7232C2.75298 12.9144 3.14446 13.1315 3.71607 13.3332C4.85026 13.7335 6.48253 13.9998 8.33301 13.9998C10.1835 13.9998 11.8158 13.7335 12.9499 13.3332C13.5216 13.1315 13.913 12.9144 14.1422 12.7232C14.2554 12.6287 14.3038 12.5635 14.3236 12.5294C14.3038 12.4952 14.2554 12.43 14.1422 12.3356C13.913 12.1443 13.5216 11.9272 12.9499 11.7255ZM14.3363 12.5015C14.3364 12.5015 14.3361 12.5032 14.3349 12.5064C14.3355 12.5031 14.3361 12.5015 14.3363 12.5015ZM2.32975 12.5015C2.32989 12.5015 2.33048 12.5031 2.33112 12.5063C2.32994 12.5032 2.32962 12.5015 2.32975 12.5015ZM2.32975 12.5572C2.32962 12.5572 2.32994 12.5556 2.33112 12.5524C2.33048 12.5556 2.32989 12.5572 2.32975 12.5572ZM14.3349 12.5524C14.3361 12.5556 14.3364 12.5572 14.3363 12.5572C14.3361 12.5572 14.3355 12.5556 14.3349 12.5524Z"
      fill={color}
    />
  </svg>
);
