import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { SidebarWrapper, InputField, LoadingComponent } from 'components';
import { CrossIcon, SearchIcon } from 'public/assets/icons';
import {
  FinishLocationIcon,
  StartLocationIcon,
  SavedRoutesPlaceholder,
} from 'pages/map/icons';
import {
  closeSidebarSavedRoutes,
  openSidebarWhereTo,
  getSavedRoutesStart,
  openSidebarRouteDetail,
} from 'store/map/actions';
import { style } from 'utils/constants/style';
import { AsyncStatus } from 'utils/types';
import { MapSidebarWrap, VerticalDashedLine } from '../index';
import {
  SavedRoutesContainer,
  RouteWrap,
  RouteName,
  WaypointWrap,
  WaypointName,
  EmptyScreenWrap,
  EmptyScreenText,
} from './SidebarSavedRoutes.style';

const SidebarSavedRoutes = () => {
  const [searchValue, setSearchValue] = useState('');
  const [savedRoutesList, setSavedRoutesList] = useState([]);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { savedRoutes, routeForEditData, updateRouteReqStat } = useSelector(
    (state) => state.map
  );

  useEffect(() => {
    if (
      updateRouteReqStat === AsyncStatus.NONE ||
      updateRouteReqStat === AsyncStatus.SUCCESS
    ) {
      dispatch(getSavedRoutesStart({ offset: 0, limit: 20 }));
    }
  }, [dispatch, routeForEditData, updateRouteReqStat]);

  useEffect(() => {
    if (searchValue.length && savedRoutes) {
      setSavedRoutesList(
        savedRoutes.filter((route) =>
          route.name.toLowerCase().includes(searchValue.toLowerCase())
        )
      );
    } else if (savedRoutes) {
      setSavedRoutesList(savedRoutes);
    }
  }, [savedRoutes, searchValue]);

  const onRouteClick = (routeId) => {
    dispatch(openSidebarRouteDetail(routeId));
  };

  const renderRoutesList = useCallback(
    () =>
      savedRoutesList.map((route) => (
        <RouteWrap key={route._id} onClick={() => onRouteClick(route._id)}>
          <RouteName>{route.name}</RouteName>

          <WaypointWrap>
            <StartLocationIcon />

            <WaypointName>{route.waypoints[0].waypointName}</WaypointName>
          </WaypointWrap>

          <VerticalDashedLine margin="0 0 0 11px" />

          <WaypointWrap>
            <FinishLocationIcon />

            <WaypointName>{route.waypoints[1].waypointName}</WaypointName>
          </WaypointWrap>
        </RouteWrap>
      )),
    [savedRoutesList]
  );

  const renderContent = () => {
    if (!savedRoutes) {
      return (
        <LoadingComponent
          fullWidth
          color="#C2C2C2"
          variant="spin"
          mobileWrapHeight={200}
        />
      );
    }

    if (savedRoutes.length === 0) {
      return (
        <EmptyScreenWrap>
          <SavedRoutesPlaceholder />

          <EmptyScreenText mt={7}>
            {t('savedRoutes:noRoutesHere')}
          </EmptyScreenText>
          <EmptyScreenText>{t('savedRoutes:createOneNow')}</EmptyScreenText>
        </EmptyScreenWrap>
      );
    }

    return (
      <>
        <InputField
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          iconLeft={<SearchIcon colorStroke={style.mainGray.dimGrayColor} />}
          placeholder={t('search:searchText')}
        />
        <SavedRoutesContainer>{renderRoutesList()}</SavedRoutesContainer>
      </>
    );
  };

  return (
    <MapSidebarWrap isFullMobileHeight>
      <SidebarWrapper
        headerText={t('map:savedRoutes')}
        headerIcon={<CrossIcon color={style.mainBlack.black} />}
        submitText={t('savedRoutes:newRouteLowercase')}
        isSubmitAvailable
        onSubmit={() => dispatch(openSidebarWhereTo())}
        onIconClick={() => dispatch(closeSidebarSavedRoutes())}
        contentPadding="20px"
      >
        {renderContent()}
      </SidebarWrapper>
    </MapSidebarWrap>
  );
};

export default SidebarSavedRoutes;
