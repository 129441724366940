import { HYDRATE } from 'next-redux-wrapper';

import { AsyncStatus } from 'utils/types';
import { actionTypes } from './actions';

const initialState = {
  viewedPostIds: [],

  postVehicleShareReqStat: AsyncStatus.NONE,
  postPostShareReqStat: AsyncStatus.NONE,
  postPostViewedReqStat: AsyncStatus.NONE,
  postEventShareReqStat: AsyncStatus.NONE,
  postUserShareReqStat: AsyncStatus.NONE,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case HYDRATE: {
      return { ...state, ...action.payload };
    }

    // POST VEHICLE SHARE
    case actionTypes.POST_VEHICLE_SHARE_START:
      return { ...state, postVehicleShareReqStat: AsyncStatus.LOADING };

    case actionTypes.POST_VEHICLE_SHARE_SUCCESS:
      return { ...state, postVehicleShareReqStat: AsyncStatus.SUCCESS };

    case actionTypes.POST_VEHICLE_SHARE_ERROR:
      return { ...state, postVehicleShareReqStat: AsyncStatus.ERROR };

    // POST POST SHARE
    case actionTypes.POST_POST_SHARE_START:
      return { ...state, postPostShareReqStat: AsyncStatus.LOADING };

    case actionTypes.POST_POST_SHARE_SUCCESS:
      return { ...state, postPostShareReqStat: AsyncStatus.SUCCESS };

    case actionTypes.POST_POST_SHARE_ERROR:
      return { ...state, postPostShareReqStat: AsyncStatus.ERROR };

    // POST POST VIEWED
    case actionTypes.POST_POST_VIEWED_START:
      return { ...state, postPostViewedReqStat: AsyncStatus.LOADING };

    case actionTypes.POST_POST_VIEWED_SUCCESS:
      return {
        ...state,
        viewedPostIds: [...state.viewedPostIds, action.data],
        postPostViewedReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.POST_POST_VIEWED_ERROR:
      return { ...state, postPostViewedReqStat: AsyncStatus.ERROR };

    // POST EVENT SHARE
    case actionTypes.POST_EVENT_SHARE_START:
      return { ...state, postEventShareReqStat: AsyncStatus.LOADING };

    case actionTypes.POST_EVENT_SHARE_SUCCESS:
      return { ...state, postEventShareReqStat: AsyncStatus.SUCCESS };

    case actionTypes.POST_EVENT_SHARE_ERROR:
      return { ...state, postEventShareReqStat: AsyncStatus.ERROR };

    // POST USER SHARE
    case actionTypes.POST_USER_SHARE_START:
      return { ...state, postUserShareReqStat: AsyncStatus.LOADING };

    case actionTypes.POST_USER_SHARE_SUCCESS:
      return { ...state, postUserShareReqStat: AsyncStatus.SUCCESS };

    case actionTypes.POST_USER_SHARE_ERROR:
      return { ...state, postUserShareReqStat: AsyncStatus.ERROR };

    default:
      return state;
  }
};

export default reducer;
