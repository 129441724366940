import React, { useEffect, useMemo, useState } from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import moment from 'moment';
import i18n from 'utils/i18n';
import { minimalTimezoneSet } from 'node_modules/compact-timezone-list';
import ChooseLocationDrawer from 'containers/ChooseLocationDrawer';

import UploadMultipleImages from 'containers/UploadMultipleImages';
import UploadImage from 'components/common/UploadImage';
import Input from 'components/common/Input';
import ScheduleTime from 'components/ScheduleTime';
import {
  BeepIcon,
  CalendarWithStarIcon,
  CupIcon,
  EventsIcon,
  FlagIcon,
  LocationIcon,
  RoadIcon,
  StarIcon,
  WorldIcon,
} from 'public/assets/icons';
import { Selector } from 'components';
import { composeValidators, required } from 'utils/validators';
import { IStepOne } from './ICreateEventPostForm';

import {
  EventTypeItemWrap,
  EventTypesHeaderText,
  EventTypesHeaderWrap,
  EventTypesList,
  EventTypesWrap,
  EventTypeText,
  LocationText,
  SectionWrapper,
  Select,
  SelectIconWrap,
  SelectWrapper,
  SeparateSettingsHeader,
  TimeZoneText,
} from './CreateEventPostForm.styles';

const EventTypes = () => [
  {
    id: 0,
    name: i18n.t('buttonGroupLabels:meetup'),
    value: process.env._EVENT_MEETUPS,
    pinType: 'meetups',
    icon: (color) => <StarIcon color={color} />,
  },
  {
    id: 1,
    name: i18n.t('buttonGroupLabels:drive'),
    value: process.env._EVENT_DRIVES,
    pinType: 'drives',
    icon: (color) => <RoadIcon color={color} />,
  },
  {
    id: 2,
    name: i18n.t('buttonGroupLabels:race'),
    value: process.env._EVENT_RACES,
    pinType: 'races',
    icon: (color) => <CupIcon color={color} />,
  },
  {
    id: 3,
    name: i18n.t('buttonGroupLabels:trackDay'),
    value: process.env._EVENT_TRACK_DAYS,
    pinType: 'trackdays',
    icon: (color) => <FlagIcon color={color} />,
  },
  {
    id: 4,
    name: i18n.t('buttonGroupLabels:carShow'),
    value: process.env._EVENT_CARS_SHOWS,
    pinType: 'carshows',
    icon: (color) => <BeepIcon color={color} />,
  },
  {
    id: 5,
    name: i18n.t('settings:other'),
    value: process.env._EVENT_OTHERS,
    pinType: 'otros',
    icon: (color) => <CalendarWithStarIcon color={color} />,
  },
];

const EVENT_TYPE = [
  {
    id: 0,
    name: i18n.t('buttonGroupLabels:meetup'),
    value: process.env._EVENT_MEETUPS,
    pinType: 'meetups',
    icon: (color) => <StarIcon color={color} />,
  },
  {
    id: 1,
    name: i18n.t('buttonGroupLabels:drive'),
    value: process.env._EVENT_DRIVES,
    pinType: 'drives',
    icon: (color) => <RoadIcon color={color} />,
  },
  {
    id: 2,
    name: i18n.t('buttonGroupLabels:race'),
    value: process.env._EVENT_RACES,
    pinType: 'races',
    icon: (color) => <CupIcon color={color} />,
  },
  {
    id: 3,
    name: i18n.t('buttonGroupLabels:trackDay'),
    value: process.env._EVENT_TRACK_DAYS,
    pinType: 'trackdays',
    icon: (color) => <FlagIcon color={color} />,
  },
  {
    id: 4,
    name: i18n.t('buttonGroupLabels:carShow'),
    value: process.env._EVENT_CARS_SHOWS,
    pinType: 'carshows',
    icon: (color) => <BeepIcon color={color} />,
  },
  {
    id: 5,
    name: i18n.t('settings:other'),
    value: process.env._EVENT_OTHERS,
    pinType: 'otros',
    icon: (color) => <CalendarWithStarIcon color={color} />,
  },
];

const EventsTypesList = ({ id, onClick }: any) => (
  <>
    {EVENT_TYPE.map((event) => (
      <EventTypeItemWrap
        selected={event.id === id}
        key={event.id}
        onClick={() => onClick(event.pinType)}
      >
        {event.icon(event.id === id ? '#FBFBFB' : '#A9A9B5')}
        <EventTypeText selected={event.id === id}>{event.name}</EventTypeText>
      </EventTypeItemWrap>
    ))}
  </>
);

const StepOne = ({ form, data, initialValues }: IStepOne) => {
  const [images, setImages] = useState<Array<any>>([]);
  const [eventStart, setEventStart] = useState<any>(
    initialValues?.startDate || new Date()
  );
  const [eventEnd, setEventEnd] = useState<any>(
    initialValues?.endDate || new Date()
  );
  const [openChooseLocationDrawer, setOpenChooseLocationDrawer] =
    useState<boolean>(false);
  const [timeZone, setTimeZone] = useState<any>(format(new Date(), 'xxx'));
  const [interests, setInterests] = useState<Array<string>>([]);

  const { t } = useTranslation();

  const getTimezone = useMemo(() => {
    const minimalTimeOne = minimalTimezoneSet.filter(
      (d) => d?.offset === timeZone
    );
    const { offset, tzCode, label } = minimalTimeOne[0];
    const tzAbbrMoment = moment.tz(tzCode).format('z');
    const isAbbrValid = !tzAbbrMoment.match(/[+-]/);
    if (isAbbrValid) {
      return `(GMT${offset}) ${tzAbbrMoment}`;
    }
    return label;
  }, [timeZone]);

  useEffect(() => {
    if (initialValues?.coverPhoto) {
      setImages([...images, initialValues?.coverPhoto]);
    }
  }, []);

  return (
    <>
      <SectionWrapper>
        {/* <Field name="coverPhoto">
          {({ input }) => {
            form.change('coverPhoto', images[images.length - 1]);
            return (
              <UploadMultipleImages
                images={images}
                setImages={setImages}
                isOnePhoto
                label="Add a cover photo for your event"
                {...input}
              />
            );
          }}
        </Field> */}
        <UploadImage
          name="coverPhoto"
          isOnePhoto
          label="Add a cover photo for your event"
        />

        <SeparateSettingsHeader mt={20} mb={20}>
          {t('event:mainInformation')}
        </SeparateSettingsHeader>
        <Input
          id="eventName"
          name="eventName"
          type="text"
          label={t('event:eventName')}
          validate={composeValidators(required(t('error:thisFieldIsRequired')))}
        />
      </SectionWrapper>
      <SectionWrapper pt={16.5} pb={16.5}>
        <Field name="startDate">
          {({ input }) => {
            return (
              <ScheduleTime
                selectedDate={input?.value || new Date()}
                headerText={t('event:eventStart')}
                onScheduleTimeChange={(v) =>
                  input.onChange(new Date(v).getTime())
                }
                minDate={input?.value || new Date()}
              />
            );
          }}
        </Field>
        <Field name="endDate">
          {({ input }) => {
            return (
              <ScheduleTime
                selectedDate={input?.value || new Date()}
                headerText={t('event:eventEnd')}
                onScheduleTimeChange={(v) =>
                  input.onChange(new Date(v).getTime())
                }
                minDate={input?.value || new Date()}
              />
            );
          }}
        </Field>
        <SeparateSettingsHeader mb={4} mt={16}>
          {t('timePicker:timeZone')}
        </SeparateSettingsHeader>
        <Field name="timeZone">
          {() => {
            form.change('timeZone', timeZone);
            return (
              <SelectWrapper>
                <SelectIconWrap>
                  <WorldIcon />
                </SelectIconWrap>
                <TimeZoneText>{getTimezone}</TimeZoneText>
                <Select
                  onChange={(e: any) => setTimeZone(e.target.value)}
                  value={timeZone}
                >
                  {minimalTimezoneSet.map((el, index) => (
                    <option value={el.offset} key={index}>
                      {el.label}
                    </option>
                  ))}
                </Select>
              </SelectWrapper>
            );
          }}
        </Field>
      </SectionWrapper>
      <SectionWrapper pt={22.5}>
        <Field
          name="interests"
          validate={composeValidators(required(t('error:thisFieldIsRequired')))}
        >
          {({ input }) => {
            const getIn = EVENT_TYPE.filter(
              (v) => v?.pinType === input?.value
            )[0];

            return (
              <EventTypesWrap>
                <EventTypesHeaderWrap>
                  <EventsIcon />
                  <EventTypesHeaderText>
                    {t('event:eventType')}
                  </EventTypesHeaderText>
                </EventTypesHeaderWrap>
                <EventTypesList>
                  <EventsTypesList
                    id={getIn?.id}
                    onClick={(v) => input.onChange(v)}
                  />
                </EventTypesList>
              </EventTypesWrap>
            );
          }}
        </Field>
        <Field name="location">
          {({ input }) => {
            return (
              <>
                <Selector
                  title={t('event:location')}
                  isSelectedItemsExist={input?.value?.address}
                  onClick={() => setOpenChooseLocationDrawer(true)}
                  leftIcon={<LocationIcon />}
                />
                {input?.value && (
                  <LocationText>{input?.value?.address}</LocationText>
                )}
                <ChooseLocationDrawer
                  open={openChooseLocationDrawer}
                  handleSubmit={(e) => input.onChange(e)}
                  setOpenChooseLocationDrawer={setOpenChooseLocationDrawer}
                  location={input?.value}
                />
              </>
            );
          }}
        </Field>
      </SectionWrapper>
    </>
  );
};

export default StepOne;
