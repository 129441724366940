import React from 'react';
import { style } from 'utils/constants/style';

export const CreateFirstEventIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.1093 11.999C18.073 11.9997 18.0365 12 18 12C14.6863 12 12 9.31371 12 6C12 5.39868 12.0885 4.81802 12.2531 4.27032C12.1037 4.0994 11.8913 4 11.6667 4C11.4049 4 11.1599 4.13495 11.0116 4.36073L8.62274 8.00664L4.56956 9.25623C4.31833 9.33376 4.11982 9.53553 4.03887 9.79602C3.95792 10.0565 4.00507 10.3423 4.1648 10.559L6.74162 14.0619L6.62531 18.4798C6.61817 18.7536 6.74069 19.0135 6.95253 19.1747C7.16437 19.3356 7.43855 19.3771 7.68513 19.2853L11.6667 17.8044L15.6479 19.2853C15.7347 19.3174 15.8247 19.3333 15.914 19.3333C16.0793 19.3333 16.2434 19.2792 16.3808 19.1747C16.5926 19.0138 16.7152 18.7536 16.708 18.4798L16.5917 14.0622L18.1093 11.999Z"
      fill={style.mainBlue.blue}
    />
    <g clipPath="url(#clip0_6074_93681)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0003 2.66672C17.6321 2.66672 17.3337 2.96519 17.3337 3.33338V5.33338H15.3337C14.9655 5.33338 14.667 5.63186 14.667 6.00005C14.667 6.36824 14.9655 6.66672 15.3337 6.66672H17.3337V8.66672C17.3337 9.03491 17.6321 9.33338 18.0003 9.33338C18.3685 9.33338 18.667 9.03491 18.667 8.66672V6.66672H20.667C21.0352 6.66672 21.3337 6.36824 21.3337 6.00005C21.3337 5.63186 21.0352 5.33338 20.667 5.33338H18.667V3.33338C18.667 2.96519 18.3685 2.66672 18.0003 2.66672Z"
        fill={style.mainBlue.blue}
      />
    </g>
    <defs>
      <clipPath id="clip0_6074_93681">
        <rect
          width="6.66667"
          height="6.66667"
          fill="white"
          transform="translate(14.667 2.66667)"
        />
      </clipPath>
    </defs>
  </svg>
);
