import styled from 'styled-components';

interface IProps {
  isFilter?: boolean;
  objectFit?: string;
  fullWidth?: boolean;
  rounded?: boolean;
}

export const SmallImageContainerWrap = styled.div<IProps>`
  display: block;
  position: relative;
  width: 64px;
  height: 64px;
  border-radius: 8px;
  display: flex;
  background-color: ${(props) => props.theme.colors.neutral.n0};
  justify-content: center;
  text-align: center;
  margin-top: 8px;
  margin-right: 10px;
  filter: ${({ isFilter }) =>
    isFilter ? 'drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.06))' : 'none'};

  :last-of-type {
    margin-right: 0;
  }

  @media only screen and (max-width: 800px) {
    width: 19%;
    height: 0;
    margin-right: 1.25%;
    padding-top: 19%;
  }
`;

export const SmallImageWrap = styled.div`
  width: 64px;
  height: 64px;
  overflow: hidden;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  cursor: pointer;

  @media only screen and (max-width: 800px) {
    width: auto;
    height: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

export const UploadedImage = styled.img<IProps>`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  object-fit: ${({ objectFit }) => objectFit || 'cover'};
  border-radius: ${({ rounded }) => (rounded ? '50%' : '6px')};
  max-width: 100%;
`;

export const CloseIconWrap = styled.div`
  height: 24px;
  width: 24px;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => props.theme.colors.shadows.sm};
  border-radius: 12px;
  position: absolute;
  top: -4px;
  right: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const EditIconWrap = styled.div`
  height: 24px;
  width: 24px;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => props.theme.colors.shadows.sm};
  border-radius: 12px;
  position: absolute;
  top: -4px;
  left: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
