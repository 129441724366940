import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EarthIcon, LocationPinIcon } from 'public/assets/icons';

import i18n from 'utils/i18n';
import { IGroupLocality } from './Types';

import {
  GroupLocalityItemText,
  GroupLocalityItemWrap,
  GroupLocalityWrap,
} from './Styles';

const groupLocalityTypesData = [
  {
    id: 0,
    name: i18n.t('buttonGroupLabels:local'),
    value: 0,
    icon: (color) => <LocationPinIcon color={color} />,
  },
  {
    id: 1,
    name: i18n.t('buttonGroupLabels:national'),
    value: 2,
    icon: (color = '#A7A7A7') => (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 1.50012C4 1.22398 3.77614 1.00012 3.5 1.00012C3.22386 1.00012 3 1.22398 3 1.50012V14.5703C3 14.8464 3.22386 15.0703 3.5 15.0703C3.77614 15.0703 4 14.8464 4 14.5703V1.50012ZM13.286 4.40649C13.2889 5.44042 13.2919 6.47434 13.2946 7.50827C13.2952 7.70996 13.132 7.7827 12.9302 7.70111C11.6332 7.2509 10.3739 7.56802 9.11472 7.88514C7.85553 8.20226 6.59635 8.51938 5.29941 8.06918C5.0975 7.98748 4.9342 7.83064 4.93273 7.53167C4.92658 6.28311 4.92401 5.0345 4.92144 3.78589C4.92017 3.17159 4.91891 2.55728 4.91722 1.94299C4.91662 1.74133 5.07992 1.66858 5.28177 1.75024C6.57876 2.20045 7.83796 1.88332 9.09715 1.56619C10.3563 1.24906 11.6155 0.931934 12.9125 1.38212C13.2431 1.49233 13.2785 1.71795 13.2791 1.91962C13.2814 2.74858 13.2837 3.57753 13.286 4.40649Z"
          fill={color}
        />
      </svg>
    ),
  },
  {
    id: 2,
    name: i18n.t('buttonGroupLabels:global'),
    value: 4,
    icon: (color) => <EarthIcon color={color} />,
  },
];

const InputField = ({ id, label, render, meta, input }: IGroupLocality) => {
  const [open, setOpen] = useState(false);
  const [inputCurentValue, setInputCurentValue] = useState<any>();
  const { onChange, value } = input;

  const { t } = useTranslation();

  const { valid, invalid, touched, error, submitError } = meta;

  const errorText = error || submitError;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = !!(touched && invalid && (error || submitError));

  return (
    <GroupLocalityWrap>
      {groupLocalityTypesData?.map((i) => (
        <GroupLocalityItemWrap
          isSelected={i?.value === value}
          key={i?.id}
          onClick={() => onChange(i?.value)}
        >
          {i?.icon(i?.value === value ? '#ffffff' : '#A9A9B5')}
          <GroupLocalityItemText>{i?.name}</GroupLocalityItemText>
        </GroupLocalityItemWrap>
      ))}
    </GroupLocalityWrap>
  );
};

export default InputField;
