import React, { memo } from 'react';
import Image from 'next/image';
import imageResize from 'utils/imageResize';
import { CheckMarkIcon } from 'public/assets/icons';
import {
  Pressable,
  Container,
  ImageWrapper,
  TextContainer,
  TextWrapper,
  CheckIcon,
  DropdownListItem,
  LogoWrap,
  Logo,
} from './AppClubItem.styles';
import { AppClubsItemProps } from './IAppClubItemList';

const AppClubsItem: React.FC<AppClubsItemProps> = ({
  clubName,
  logoUrl,
  onPress,
  selected,
}) => {
  const appName = process.env._ENV === 'rockr' ? 'rockr' : 'roadstr';
  const clubLogo = imageResize(logoUrl, '_thumb');
  return (
    <DropdownListItem>
      <Pressable onClick={onPress}>
        <Container>
          <ImageWrapper>
            {clubLogo ? (
              <Image
                src={clubLogo}
                alt={`${clubName} logo`}
                width={24}
                height={24}
              />
            ) : (
              <LogoWrap>
                <Logo
                  src={`/assets/img/logoRounded_${appName}.png`}
                  width={24}
                  height={24}
                  alt="logo"
                />
              </LogoWrap>
            )}
          </ImageWrapper>
          <TextContainer>
            <TextWrapper>{clubName}</TextWrapper>
            <CheckIcon checked={selected}>
              {selected && <CheckMarkIcon />}
            </CheckIcon>
          </TextContainer>
        </Container>
      </Pressable>
    </DropdownListItem>
  );
};

const arePropsEqual = (
  prevProps: AppClubsItemProps,
  nextProps: AppClubsItemProps
) => {
  return prevProps.selected === nextProps.selected;
};

AppClubsItem.displayName = 'AppClubsItem';

export default memo(AppClubsItem, arePropsEqual);
