/* eslint-disable global-require */
import { applyMiddleware, combineReducers, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createWrapper } from 'next-redux-wrapper';
import logger from 'redux-logger';
import identityReducer from './identity/reducer';
import loginReducer from './auth/Login/reducer';
import resetPasswordReducer from './auth/ResetPassword/reducer';
import rootSaga from './rootSaga';
import logOutReducer from './auth/LogOut/reducer';
import registrationReducer from './auth/Registration/reduser';
import settingsReducer from './settings/reducer';
import sideBarControlerReducer from './sideBarControler/reducer';
import userReducer from './user/reducer';
import postsReducer from './posts/reducer';
import vehicleReducer from './vehicle/reducer';
import searchReducer from './search/reducer';
import notificationsReducer from './notifications/reducer';
import chatReducer from './chat/reducer';
import analyticsReducer from './analytics/reducer';
import mapReducer from './map/reducer';
import fetchApiReducer from './fetchApi/reducer';
import appStateReducer from './appState/reducer';
import clubReducer from './club/reducer';

const rootReducers = combineReducers({
  identity: identityReducer,
  login: loginReducer,
  logOut: logOutReducer,
  resetPassword: resetPasswordReducer,
  registration: registrationReducer,
  settings: settingsReducer,
  sideBarControler: sideBarControlerReducer,
  user: userReducer,
  posts: postsReducer,
  vehicle: vehicleReducer,
  search: searchReducer,
  notifications: notificationsReducer,
  chat: chatReducer,
  analytics: analyticsReducer,
  map: mapReducer,
  fetchData: fetchApiReducer,
  app: appStateReducer,
  club: clubReducer,
});

export const rootReducer = (state, action) =>
  rootReducers(action.type === 'LOGOUT_SUCCESS' ? undefined : state, action);

const bindMiddleware = (middleware) => {
  if (process.env._ENV === 'dev') {
    // eslint-disable-next-line import/no-extraneous-dependencies
    const { composeWithDevTools } = require('redux-devtools-extension');
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

export const makeStore = (context) => {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    rootReducer,
    bindMiddleware([sagaMiddleware, logger])
  );

  store.sagaTask = sagaMiddleware.run(rootSaga);

  return store;
};

export const wrapper = createWrapper(makeStore, { debug: true });
