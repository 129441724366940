import React from 'react';

import { IButton } from './IButton';
import LoadingDots from '../LoadingDots';

import { ButtonWrapper, SuffixWrapper, PrefixWrapper } from './Button.styles';

const Button = ({
  htmlType = 'button',
  variant,
  outline = false,
  buttonType = 'default',
  onClick,
  children,
  disabled = false,
  active = false,
  suffix,
  prefix,
  loading = false,
  width,
  size,
  bgColor,
  dotColor,
  className,
}: IButton) => {
  return (
    <ButtonWrapper
      type={htmlType}
      onClick={onClick}
      variant={variant}
      outline={outline}
      disabled={loading || disabled}
      buttonType={buttonType}
      active={active}
      loading={loading}
      width={width}
      size={size}
      bgColor={bgColor}
      className={className}
    >
      {suffix && <SuffixWrapper>{suffix}</SuffixWrapper>}
      {!loading ? children : <LoadingDots dotColor={dotColor} />}
      {prefix && <PrefixWrapper>{prefix}</PrefixWrapper>}
    </ButtonWrapper>
  );
};

export default Button;
