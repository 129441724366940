/* eslint-disable react/prop-types */
import React from 'react';
import { style } from '../../../utils/constants/style';

export const AmbasadorIcon = ({
  color = style.mainYellow.yellow,
}: {
  color?: string;
}) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.9996 4.72509V3.94288C13.9996 3.69089 13.7535 3.48703 13.4492 3.48703C10.2607 3.48703 8.44459 1.67762 8.42663 1.66012C8.2185 1.44663 7.78006 1.44663 7.57193 1.66012C7.55291 1.67762 5.73892 3.48703 2.55043 3.48703C2.24616 3.48703 2 3.69089 2 3.94288L2.00951 9.84097C2.03064 9.93634 2.39724 11.4369 3.98303 13.0214L13.9996 4.72509Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.62305 14.9224C6.97697 15.107 7.3478 15.2864 7.75666 15.4527C7.83273 15.4842 7.91619 15.4999 7.9986 15.4999C8.08206 15.4999 8.16552 15.4842 8.24159 15.4527C13.1247 13.4656 13.9572 9.98767 13.9995 9.75756V8.81348L6.62305 14.9224Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0007 7.57631V5.96289L4.69727 13.6678C5.00893 13.9215 5.35863 14.1717 5.74319 14.415L14.0007 7.57631Z"
      fill={color}
    />
  </svg>
);
