import { all, put, takeLatest } from 'redux-saga/effects';
import {
  actionTypes,
  openSideBarEditProfile,
  closeSideBarEditProfile,
  openSidebarComments,
  closeSidebarComments,
  openSidebarInviteUserToGroup,
  closeSidebarInviteUserToGroup,
  attendSuccess,
  cancelAttendSuccess,
} from './actions';

function* workerSideBar(action) {
  if (action.data === actionTypes.OPEN_SIDEBAR_EDIT_PROFILE) {
    yield put(openSideBarEditProfile(action));
  }
  if (action.data === actionTypes.CLOSE_SIDEBAR_EDIT_PROFILE) {
    yield put(closeSideBarEditProfile(action));
  }
  if (action.data === actionTypes.OPEN_SIDEBAR_COMMENTS) {
    yield put(openSidebarComments(action));
  }
  if (action.data === actionTypes.CLOSE_SIDEBAR_COMMENTS) {
    yield put(closeSidebarComments(action));
  }
  if (action.data === actionTypes.OPEN_SIDEBAR_EDIT_PROFILE) {
    yield put(openSideBarEditProfile(action));
  }
  if (action.data === actionTypes.CLOSE_SIDEBAR_EDIT_PROFILE) {
    yield put(closeSideBarEditProfile(action));
  }
  if (action.data === actionTypes.OPEN_SIDEBAR_ADD_USER_GROUP) {
    yield put(openSidebarInviteUserToGroup(action));
  }
  if (action.data === actionTypes.CLOSE_SIDEBAR_ADD_USER_GROUP) {
    yield put(closeSidebarInviteUserToGroup(action));
  }
  if (action.data === actionTypes.ATTEND_SUCCESS) {
    yield put(attendSuccess(action));
  }
  if (action.data === actionTypes.CANCEL_ATTEND_SUCCESS) {
    yield put(cancelAttendSuccess(action));
  }
}

function* watchSideBar() {
  yield all([takeLatest(actionTypes.OPEN_SIDE_BAR, workerSideBar)]);
}

export default watchSideBar;
