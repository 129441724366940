import React from 'react';

export const FlagIcon = ({ color = '#A9A9B5' }: { color?: string }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.16602 3.00049C5.16602 2.72435 4.94216 2.50049 4.66602 2.50049C4.38987 2.50049 4.16602 2.72435 4.16602 3.00049V17.8988C4.16602 18.175 4.38987 18.3988 4.66602 18.3988C4.94216 18.3988 5.16602 18.175 5.16602 17.8988V3.00049ZM15.8207 6.33097L15.8207 6.3109L15.8128 3.47856C15.8121 3.24869 15.7718 2.99151 15.3949 2.86588C13.9165 2.35273 12.4812 2.71421 11.0459 3.0757C9.61057 3.43719 8.17524 3.79867 6.69684 3.28549C6.46675 3.19241 6.28062 3.27534 6.28129 3.5052C6.28322 4.20543 6.28466 4.90568 6.2861 5.60592V5.60593C6.28903 7.02917 6.29196 8.45241 6.29897 9.87559C6.30065 10.2164 6.4868 10.3952 6.71695 10.4883C8.19529 11.0015 9.6306 10.64 11.0659 10.2785C12.5013 9.91703 13.9366 9.55555 15.415 10.0687C15.6451 10.1617 15.8312 10.0788 15.8305 9.84892C15.8274 8.67627 15.824 7.50362 15.8207 6.33097ZM14.984 3.55538L14.9889 5.31293C14.349 5.16386 13.7549 5.14184 13.1021 5.20519L13.0973 3.44766C13.75 3.38436 14.3441 3.40627 14.984 3.55538ZM7.12921 8.01447C7.12777 7.54561 7.1265 7.07671 7.12523 6.60782L7.12361 6.01742C7.79552 6.17675 8.42584 6.19078 9.1102 6.11199L9.11573 8.10903C8.43134 8.18777 7.80109 8.17376 7.12921 8.01447ZM11.106 5.65158C10.4449 5.83277 9.78753 6.00371 9.1102 6.11199L9.10532 4.3544C9.7827 4.2461 10.44 4.07526 11.101 3.89402L11.106 5.65158ZM9.11608 8.10916L9.12107 9.89255C9.79365 9.78514 10.4463 9.61604 11.1025 9.43602L11.0975 7.65261C10.4412 7.83257 9.78869 8.00176 9.11608 8.10916ZM11.1137 7.64885L11.1083 5.65181C11.769 5.47131 12.4256 5.3051 13.104 5.20569L13.1095 7.2027C12.4312 7.30222 11.7745 7.46834 11.1137 7.64885ZM13.1196 7.20113L13.1246 8.98453C13.7732 8.92259 14.3641 8.94542 14.9998 9.09369L14.9947 7.31032C14.3591 7.16203 13.7683 7.13924 13.1196 7.20113Z"
      fill={color}
    />
  </svg>
);
