/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useState } from 'react';
import { generateApi } from 'services/api';
import { useDispatch, useSelector } from 'react-redux';
import { fetchApiReq } from 'store/fetchApi/actions';

const fetchApi = () => {
  const [loading, setLoading] = useState({});
  const [data, setData] = useState(null);

  const dispatch = useDispatch();

  const fetchStartDispatch = useCallback(
    async (
      name,
      provide = null,
      store = true,
      paginate = false,
      prevData = [],
      isResponse = true
    ) => {
      try {
        setLoading((prevState) => ({
          ...prevState,
          [name]: true,
        }));
        // dispatch(
        //   fetchApiReq({
        //     key: name,
        //     res: { error: false, success: false, loading: true },
        //   })
        // );
        const res = await generateApi(name, provide, isResponse);
        if (res?.error || res?.result === 'error' || res?.code >= 400) {
          dispatch(
            fetchApiReq({
              key: name,
              res: {
                error: true,
                success: false,
                loading: false,
              },
            })
          );
          setLoading((prevState) => ({
            ...prevState,
            [name]: false,
          }));
          return res;
        }

        setData(res?.data);
        if (store && isResponse) {
          if (paginate) {
            if (prevData?.length) {
              dispatch(
                fetchApiReq({
                  key: name,
                  res: {
                    ...res,
                    data: [...prevData, ...res?.data],
                    error: false,
                    success: true,
                    loading: false,
                  },
                })
              );
            } else {
              dispatch(
                fetchApiReq({
                  key: name,
                  res: {
                    ...res,
                    data: [...res?.data],
                    error: false,
                    success: true,
                    loading: false,
                  },
                })
              );
            }
          } else {
            dispatch(
              fetchApiReq({
                key: name,
                res: {
                  ...res,
                  error: false,
                  success: true,
                  loading: false,
                },
              })
            );
          }
        }
        if (store && !isResponse) {
          dispatch(
            fetchApiReq({
              key: name,
              res: {
                error: false,
                success: true,
                loading: false,
              },
            })
          );
        }

        setLoading((prevState) => ({
          ...prevState,
          [name]: false,
        }));
        return res;
      } catch (error) {
        setLoading((prevState) => ({
          ...prevState,
          [name]: false,
        }));
        setData(null);
      }
    },
    []
  );

  return {
    action: fetchStartDispatch,
    data,
    loading,
  };
};

export default fetchApi;
