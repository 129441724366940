import React from 'react';
import { useTheme } from 'styled-components';

const EditIconWithBg = ({
  disabled,
  width = '32',
  height = '32',
  cx = '16',
  cy = '16',
  r = '16',
}: {
  disabled?: boolean;
  width?: string;
  height?: string;
  cx?: string;
  cy?: string;
  r?: string;
}) => {
  const theme: any = useTheme();

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx={cx}
        cy={cy}
        r={r}
        fill={disabled ? theme.colors.neutral.n6 : theme.colors.blue}
      />
      <path
        d="M21.1917 13.2332L18.7674 10.809C18.6765 10.7181 18.5532 10.667 18.4246 10.667C18.2961 10.667 18.1728 10.7181 18.0819 10.809L10.8091 18.0817C10.7181 18.1727 10.667 18.296 10.667 18.4245V20.8488C10.667 20.9774 10.7181 21.1007 10.809 21.1916C10.8999 21.2826 11.0233 21.3337 11.1518 21.3337H13.5761C13.7047 21.3336 13.828 21.2825 13.9189 21.1916L21.1917 13.9188C21.2826 13.8279 21.3337 13.7046 21.3337 13.576C21.3337 13.4474 21.2826 13.3241 21.1917 13.2332ZM13.3754 20.364H11.6367V18.6253L16.9701 13.2919L18.7088 15.0306L13.3754 20.364ZM19.3943 14.345L17.6557 12.6063L18.4246 11.8373L20.1633 13.576L19.3943 14.345Z"
        fill="white"
      />
    </svg>
  );
};

export default EditIconWithBg;
