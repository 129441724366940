import { HYDRATE } from 'next-redux-wrapper';
import { actionTypes } from './actions';

const initialState = {
  logout: false,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case HYDRATE: {
      return { ...state, ...action.payload };
    }

    case actionTypes.LOGOUT_ERROR:
      return {
        ...state,
        ...{ error: action.error },
      };

    case actionTypes.LOGOUT_START:
      return {};

    case actionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        ...{ logout: true },
      };
    default:
      return state;
  }
}

export default reducer;
