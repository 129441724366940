export const actionTypes = {
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGIN_START: 'LOGIN_START',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_CREDENTIALS_CHECK_SUCCESS: 'LOGIN_CREDENTIALS_CHECK_SUCCESS',
  LOGIN_RESET_DATA: 'LOGIN_RESET_DATA',
  LOGIN_WITH_FACEBOOK_START: 'LOGIN_WITH_FACEBOOK_START',
  LOGIN_WITH_FACEBOOK_SUCCESS: 'LOGIN_WITH_FACEBOOK_SUCCESS',
  LOGIN_WITH_FACEBOOK_ERROR: 'LOGIN_WITH_FACEBOOK_ERROR',
  LOGIN_WITH_APPLE_START: 'LOGIN_WITH_APPLE_START',
  LOGIN_WITH_APPLE_SUCCESS: 'LOGIN_WITH_APPLE_SUCCESS',
  LOGIN_WITH_APPLE_ERROR: 'LOGIN_WITH_APPLE_ERROR',
  HYDRATE: 'HYDRATE',
  SET_LOGIN_DATA: 'SET_LOGIN_DATA',
  ACTIVATE_ACCOUNT_START: 'ACTIVATE_ACCOUNT_START',
  ACTIVATE_ACCOUNT_SUCCESS: 'ACTIVATE_ACCOUNT_SUCCESS',
  ACTIVATE_ACCOUNT_ERROR: 'ACTIVATE_ACCOUNT_ERROR',
  RESEND_ACTIVATION_EMAIL_START: 'RESEND_ACTIVATION_EMAIL_START',
  RESEND_ACTIVATION_EMAIL_SUCCESS: 'RESEND_ACTIVATION_EMAIL_SUCCESS',
  RESEND_ACTIVATION_EMAIL_ERROR: 'RESEND_ACTIVATION_EMAIL_ERROR',
};

export function loginError(error) {
  return {
    type: actionTypes.LOGIN_ERROR,
    error,
  };
}
export const resetLoginData = () => ({
  type: actionTypes.LOGIN_RESET_DATA,
});

export function LogInStart(data) {
  return {
    type: actionTypes.LOGIN_START,
    data,
  };
}

export function LogInSuccess(data) {
  console.log(data, 'logindataIS');
  return {
    type: actionTypes.LOGIN_SUCCESS,
    data,
  };
}

// LOGIN WITH FACEBOOK ACTIONS
export const loginWithFacebookStart = (data) => ({
  type: actionTypes.LOGIN_WITH_FACEBOOK_START,
  data,
});

export const loginWithFacebookSuccess = (data) => ({
  type: actionTypes.LOGIN_WITH_FACEBOOK_SUCCESS,
  data,
});

export const loginWithFacebookError = (error) => ({
  type: actionTypes.LOGIN_WITH_FACEBOOK_ERROR,
  error,
});

// LOGIN WITH APPLE ACTIONS
export const loginWithAppleStart = (data) => ({
  type: actionTypes.LOGIN_WITH_APPLE_START,
  data,
});

export const loginWithAppleSuccess = (data) => ({
  type: actionTypes.LOGIN_WITH_APPLE_SUCCESS,
  data,
});

export const loginWithAppleError = (error) => ({
  type: actionTypes.LOGIN_WITH_APPLE_ERROR,
  error,
});

// SET LOGIN DATA ACTIONS
export const setLoginData = (data) => ({
  type: actionTypes.SET_LOGIN_DATA,
  data,
});

// ACTIVATE ACCOUNT ACTIONS
export const activateAccountStart = (data) => ({
  type: actionTypes.ACTIVATE_ACCOUNT_START,
  data,
});

export const activateAccountSuccess = (data) => ({
  type: actionTypes.ACTIVATE_ACCOUNT_SUCCESS,
  data,
});

export const activateAccountError = (data) => ({
  type: actionTypes.ACTIVATE_ACCOUNT_ERROR,
  data,
});

export const resendActivationEmailStart = (email, onSuccess) => ({
  type: actionTypes.RESEND_ACTIVATION_EMAIL_START,
  email,
  onSuccess,
});

export const resendActivationEmailSuccess = () => ({
  type: actionTypes.RESEND_ACTIVATION_EMAIL_SUCCESS,
});

export const resendActivationEmailError = (error) => ({
  type: actionTypes.RESEND_ACTIVATION_EMAIL_ERROR,
  error,
});
