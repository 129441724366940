/* eslint-disable no-nested-ternary */
import React from 'react';

import { style } from 'utils/constants/style';
import { IRectangleButton } from './IRectangleButton';
import { Button, TextWrapper, ButtonText } from './RectangleButton.style';
import { LoadingDots } from '../../index';

const RectangleButton = ({
  text,
  color,
  onClick,
  disabled,
  backgroundColor,
  height,
  icon,
  margin,
  ref,
  rounded,
  width,
  loading,
  buttonTextMargin,
  border,
  fontSize,
  onKeyDown,
  mobileHeight,
  type = 'button',
  cursor = 'pointer',
}: IRectangleButton) => (
  <Button
    color={disabled ? style.mainGray.dimGrayColor : color}
    rounded={rounded}
    ref={ref}
    disabled={disabled}
    onClick={onClick}
    backgroundColor={disabled ? style.mainGray.whisperColor : backgroundColor}
    height={height}
    margin={margin}
    width={width}
    border={border}
    onKeyDown={onKeyDown}
    mobileHeight={mobileHeight}
    type={type}
    cursor={cursor}
  >
    {loading ? (
      <LoadingDots />
    ) : icon ? (
      <TextWrapper height={height} mobileHeight={mobileHeight}>
        <>{icon}</>
        <ButtonText margin={buttonTextMargin} fontSize={fontSize}>
          {text}
        </ButtonText>
      </TextWrapper>
    ) : (
      <>{text}</>
    )}
  </Button>
);

export default RectangleButton;
