import { useCallback, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import SelectVehicleDrawer from 'components/SelectVehicle';
import SidebarCheckItem from 'components/SidebarCheckItem';
import API_NAME from 'services/api';
import fetchApi from 'hooks/fetchApi';
import { ThemeContext } from 'theme';
import { WheelIcon } from 'public/assets/icons';
import AttendVehicleGarageCard from 'components/AttendVehicleGarageCard';
import { IAddVehicleGroupDrawer } from './IAddVehicleGroupDrawer';
import {
  NoResultText,
  NoResultWrap,
  VehiclesWrap,
} from './AddVehicleGroupDrawer.styles';

const SelectVehicle = () => {
  const context: any = useContext(ThemeContext);

  const { t } = useTranslation();

  const getVehicles = useSelector((store) => store.fetchData);

  const { action, loading } = fetchApi();

  const fetchData = useCallback(() => {
    action(API_NAME.GET_VEHICLES, {
      params: {
        id: context?.userId,
      },
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <SelectVehicleDrawer
      name="vehicle"
      formRender={(value, onChange) => {
        const isSelectedItem = (id) => value?.map((v) => v?.id)?.includes(id);
        return (
          <>
            {getVehicles[API_NAME.GET_VEHICLES]?.data?.length > 0 ? (
              <VehiclesWrap>
                {getVehicles[API_NAME.GET_VEHICLES]?.data?.map((v) => (
                  <AttendVehicleGarageCard
                    key={v?._id}
                    image={v?.photos[0]}
                    make={v?.make?.trim()}
                    model={v?.model}
                    checked={isSelectedItem(v?._id)}
                    onClick={() => onChange({ ...v, id: v?._id }, false)}
                  />
                ))}
              </VehiclesWrap>
            ) : (
              <NoResultWrap>
                <NoResultText>{t('chat:nothingFound')}</NoResultText>
                <WheelIcon />
              </NoResultWrap>
            )}
          </>
        );
      }}
    />
  );
};

export default SelectVehicle;
