import styled from 'styled-components';

interface IProps {
  valid?: boolean;
}

export const InputWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Label = styled.label`
  font-family: 'Poppins';
  font-weight: 400;
  font-size: 13px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: #454545;
  margin-bottom: 4px;
`;

export const InputField = styled.input<any>`
  font-family: 'Poppins';
  font-weight: 400;
  background: #f7f7f7;
  border-radius: 4px;
  padding: 14.5px 16px;

  /* width: 100%; */
  border: 1px solid ${(props) => (!props.valid ? 'transparent' : '#ea3c24')};
  color: #1c1f29;
  font-size: 15px;
  outline: none;
  letter-spacing: 0.02em;
  &:focus {
    border: ${(props) => !props.valid && '1px solid #ff950a !important'};
  }
  &::placeholder {
    color: #1c1f29;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 15px;
    line-height: 100%;
    letter-spacing: 0.02em;
  }
  &:disabled {
    background: #ececec;
    cursor: not-allowed;
    color: #ececec;
  }
`;

export const TextareaField = styled.textarea<any>`
  font-family: 'Poppins';
  font-weight: 400;
  background: #f7f7f7;
  border-radius: 4px;
  padding: 14.5px 0 14.5px 16px;
  width: calc(100% - 16px);
  border: 1px solid transparent;
  color: #1c1f29;
  font-size: 15px;
  outline: none;
  letter-spacing: 0.02em;
  &:focus {
    border: 1px solid #ff950a !important;
  }
  &::placeholder {
    color: #1c1f29;
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 15px;
    line-height: 100%;
    letter-spacing: 0.02em;
  }
  &:disabled {
    background: #ececec;
    cursor: not-allowed;
    color: #1c1f29;
    opacity: 0.6;
  }
`;

export const CrossIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 32px;
  right: 16px;
  cursor: pointer;
`;

export const LeftIconWrapper = styled.div<IProps>`
  width: 24px;
  height: 24px;
  position: absolute;
  bottom: ${(props) => (!props.valid ? '10px' : '28px')};
  left: 8px;
  display: flex;
  justify-content: center;
`;

export const TextareaBottom = styled.div<IProps>`
  ${(props) => {
    if (props.valid) {
      return `
          display: flex;
          align-items: center;
          justify-content: space-between;
          & > span {
            order: 1;
          }
    `;
    }
    return `
        text-align: end;
      `;
  }}
  & > span {
    color: ${(props) => props.theme.colors.neutral.n8};
    font-size: ${(props) => props.theme.typography.fontSize.f2};
    font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  }
  & > div {
    margin: 0;
  }
`;
