import { ISkeleton } from './ISkeleton';

import SkeletonContainer from './Skeleton.styles';

const Skeleton = ({
  width = '100%',
  height = 16,
  bottom = 14,
  circle = false,
  borderRadius = 0,
}: ISkeleton) => {
  return (
    <SkeletonContainer
      style={{
        width,
        height,
        marginBottom: bottom,
        ...(circle && { borderRadius: '100%', height: width }),
        borderRadius,
      }}
    />
  );
};

export default Skeleton;
