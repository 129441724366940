import React from 'react';
import { useTranslation } from 'react-i18next';

import FieldSwitchTab from 'components/common/FieldSwitchTab';

import {
  GroupChatBlock,
  GroupChatBlockLeft,
  GroupChatBlockRight,
  GroupChatBlockText,
  GroupChatWrapper,
  GroupChatWrapperLeft,
  GroupChatWrapperRight,
  SeparateSettingsHeader,
} from './GroupForm.styles';

const GroupPrivate = (props:any) => {
  const { t } = useTranslation();
  const privateKey = props?.privateKey ? true : false;

  return (
    <GroupChatWrapper>
      <GroupChatWrapperLeft>
        <SeparateSettingsHeader type="h3" mb={8}>
          {t('addNewGroup:privateContent')}
        </SeparateSettingsHeader>
        <GroupChatBlock>
          <GroupChatBlockLeft>
            <SeparateSettingsHeader type="h4" fs={15} fw={500}>
              {t('addNewGroup:privateContentOn')}
            </SeparateSettingsHeader>
            <GroupChatBlockText>
              {t('addNewGroup:privateContentOff')}
            </GroupChatBlockText>
          </GroupChatBlockLeft>
        </GroupChatBlock>
      </GroupChatWrapperLeft>
      <GroupChatWrapperRight>
        <GroupChatBlockRight>
          <FieldSwitchTab
            id="private"
            name="private"
            defaultValue={privateKey}
            labelLeft="Off"
            labelRight="On"
          />
        </GroupChatBlockRight>
      </GroupChatWrapperRight>
    </GroupChatWrapper>
  );
};

export default GroupPrivate;
