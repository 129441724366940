import React from 'react';

import { style } from 'utils/constants/style';
import {
  GroupSettingWrap,
  SectionHeader,
  GroupSettingText,
  GroupSettingsIconWrap,
} from './CreateGroup.style';
import { ButtonsGroup } from '../../index';

interface IGroupSetting {
  header: string;
  subHeader: string;
  text: string;
  onClick?: () => void;
  onChatSettingClick?: (val: boolean) => void;
  isChat?: boolean;
  isChatActive?: boolean;
}

export const GroupSetting = ({
  header,
  subHeader,
  text,
  onClick,
  onChatSettingClick,
  isChat,
  isChatActive,
}: IGroupSetting) => (
  <GroupSettingWrap>
    <SectionHeader color={style.mainGray.gray}>{header}</SectionHeader>
    <SectionHeader mt={8} mb={8}>
      {subHeader}
    </SectionHeader>
    <GroupSettingText>{text}</GroupSettingText>

    {isChat ? (
      <GroupSettingsIconWrap height={40} width={88} top={21}>
        <ButtonsGroup
          itemList={[
            { id: 0, name: 'Off' },
            { id: 1, name: 'On' },
          ]}
          isSelectedId={isChatActive ? 1 : 0}
          onClick={(newValue) => onChatSettingClick(Boolean(newValue))}
          inactiveFieldsBackgroundColor={style.mainGray.whiteSmokeColor}
        />
      </GroupSettingsIconWrap>
    ) : (
      <GroupSettingsIconWrap onClick={onClick}>
        <svg
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 18.5L15 12.5L9 6.5"
            stroke={style.mainBlue.blue}
            strokeWidth="2.4"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </GroupSettingsIconWrap>
    )}
  </GroupSettingWrap>
);
