import styled from 'styled-components';

import { style, deviceMaxWidth } from 'utils/constants/style';

interface IProps {
  isAnyMapSidebarOpen?: boolean;
}

export const MapControlsWrap = styled.div<IProps>`
  .mapboxgl-ctrl-group {
    border-radius: 1px;
  }
  .mapboxgl-ctrl-group > .mapboxgl-ctrl-icon {
    width: 40px;
    height: 40px;
  }
  .mapboxgl-ctrl-group > .mapboxgl-ctrl-icon > button {
    width: 24px !important;
    height: 24px !important;
    border-radius: 2px !important;
  }

  .mapboxgl-ctrl > button.mapboxgl-ctrl-zoom-out > .mapboxgl-ctrl-icon {
    background-image: url('/assets/img/minus.svg');
  }
  .mapboxgl-ctrl > button.mapboxgl-ctrl-zoom-in > .mapboxgl-ctrl-icon {
    background-image: url('/assets/img/plus.svg');
  }
  .mapboxgl-ctrl-compass-arrow {
    margin: 0.1em 2px !important;
  }
  .mapboxgl-ctrl-geolocate > .mapboxgl-ctrl-icon {
    background-image: url('/assets/img/location.svg');
  }

  .nav-controll {
    right: ${({ isAnyMapSidebarOpen }) =>
      isAnyMapSidebarOpen ? '410px' : '10px'};
    bottom: 70px;

    @media only screen and (${deviceMaxWidth.sm}) {
      bottom: 170px;
    }
  }
  .locationButton {
    cursor: pointer;
    width: 40px;
    height: 40px;
    background-color: ${style.mainWhite.white};
    border-radius: 1px;
    position: absolute;
    right: ${({ isAnyMapSidebarOpen }) =>
      isAnyMapSidebarOpen ? '410px' : '10px'};
    bottom: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('/assets/img/location.svg');
    background-position: center;
    background-repeat: no-repeat;

    @media only screen and (${deviceMaxWidth.sm}) {
      bottom: 120px;
    }
  }
  .GeolocateControl {
    background-image: url('/assets/img/location.svg');
    cursor: pointer;
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: 1px;
    position: absolute;
    right: ${({ isAnyMapSidebarOpen }) =>
      isAnyMapSidebarOpen ? '410px' : '10px'};
    bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;

    @media only screen and (${deviceMaxWidth.sm}) {
      bottom: 120px;
    }
  }

  @media only screen and (${deviceMaxWidth.sm}) {
    ${({ isAnyMapSidebarOpen }) => isAnyMapSidebarOpen && `display: none`}
  }
`;
