import React from 'react';

import { IButtonsGroup } from './IButtonsGroup';
import { ButtonsGroupWrap, Button } from './ButtonsGroup.style';

export const ButtonsGroup = ({
  itemList,
  onClick,
  isSelectedId,
  inactiveFieldsBackgroundColor,
}: IButtonsGroup) => (
  <ButtonsGroupWrap>
    {itemList.map((item, id) => (
      <Button
        selected={item.id === isSelectedId}
        onClick={() => onClick(item.id)}
        withBorder={item.id !== isSelectedId && item.id !== isSelectedId - 1}
        inactiveFieldsBackgroundColor={inactiveFieldsBackgroundColor}
        key={id}
      >
        <p>{item.name}</p>
      </Button>
    ))}
  </ButtonsGroupWrap>
);
