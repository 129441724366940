import React from 'react';
import { style } from 'utils/constants/style';

export const PlusWithoutCircleIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33301 8.5H12.6663"
      stroke={style.mainBlue.blue}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 3.83301V13.1663"
      stroke={style.mainBlue.blue}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
