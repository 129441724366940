import { useState } from 'react';

import { ISelectVehicle } from './ISelectVehicle';

const WITHOUT_VEHICLE_ID = 1;

const SelectVehicle = ({
  meta,
  input,
  formRender,
  multipleSelect = true,
}: ISelectVehicle) => {
  const [inputCurentValue, setInputCurentValue] = useState<any>();

  const { onChange } = input;

  const onSelect = (v, isWithoutVehicle = false) => {
    // if (isWithoutVehicle) {
    //   if (inputCurentValue?.length > 0) {
    //     if (inputCurentValue[0]?.id === WITHOUT_VEHICLE_ID) {
    //       setInputCurentValue([]);
    //       onChange([]);
    //       return;
    //     }
    //   }

    //   setInputCurentValue([{ id: WITHOUT_VEHICLE_ID }]);
    //   onChange([{ id: WITHOUT_VEHICLE_ID }]);
    //   return;
    // }
    if (multipleSelect && !isWithoutVehicle) {
      // const getWithoutVehicle = inputCurentValue?.filter(
      //   (b) => b?.id !== WITHOUT_VEHICLE_ID
      // );
      const checkPrevious = inputCurentValue?.filter((b) => b?.id === v?.id);
      if (checkPrevious?.length > 0) {
        const getFilterValue = inputCurentValue?.filter((b) => b?.id !== v?.id);
        setInputCurentValue(getFilterValue);
        onChange(getFilterValue);
        return;
      }
      if (inputCurentValue?.length > 0) {
        setInputCurentValue([...inputCurentValue, v]);
        onChange([...inputCurentValue, v]);
        return;
      }
      setInputCurentValue([v]);
      onChange([v]);
      return;
    }
    setInputCurentValue(v);
    onChange(v);
  };

  // const onSelect = (v) => {
  //   if (multipleSelect) {
  //     const checkPrevious = inputCurentValue?.filter((b) => b?.id === v?.id);
  //     if (checkPrevious?.length > 0) {
  //       const getFilterValue = inputCurentValue?.filter((b) => b?.id !== v?.id);
  //       setInputCurentValue(getFilterValue);
  //       return;
  //     }
  //     if (inputCurentValue?.length > 0) {
  //       setInputCurentValue([...inputCurentValue, v]);
  //       return;
  //     }
  //     setInputCurentValue([v]);
  //     return;
  //   }
  //   setInputCurentValue(v);
  // };

  return <>{formRender(inputCurentValue, onSelect)}</>;
};

export default SelectVehicle;
