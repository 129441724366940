import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  pt?: number;
  pb?: number;
  mt?: number;
  mb?: number;
}

export const Wrapper = styled.div``;

export const SectionWrapper = styled.div<IProps>`
  padding-top: ${({ pt }) => (pt ? `${pt}px` : '20px')};
  padding-bottom: ${({ pb }) => (pb ? `${pb}px` : '16.5px')};
  border-bottom: 1px dashed ${style.mainGray.whisperColor};

  :last-of-type {
    border: none;
  }
`;

export const SectionHeader = styled.h3`
  font-size: 18px;
  line-height: 130%;
  font-weight: 600;
  color: ${style.mainBlack.black};
  margin-bottom: 13px;
`;

export const TagsWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TagItemWrap = styled.div`
  margin-bottom: 8px;

  :last-of-type {
    margin: 0;
  }
`;

export const QuickTagsWrap = styled.div`
  overflow: hidden;
  overflow-y: auto;
  max-height: 130px;

  ::-webkit-scrollbar-track {
    background: none;
  }
  ::-webkit-scrollbar {
    width: 4px;
    height: 100px;
    background: none;
  }
  ::-webkit-scrollbar-thumb {
    background: ${style.mainGray.silver};
    border-radius: 10px;
  }
`;

export const TagImageWrap = styled.div`
  height: 26px;
  width: 26px;
  border-radius: 50%;
  overflow: hidden;
`;

export const LocationText = styled.p`
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  margin: 14px 0 11px 8px;
`;

export const SeparateSettingsHeader = styled.h3<IProps>`
  font-size: 16px;
  line-height: 150%;
  font-weight: 600;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
`;
