import fetchData from 'utils/fetchData';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const followUser = (personID: string): Promise<any> => {
  try {
    const token = localStorage.getItem('token');
    const userId = JSON.parse(localStorage.getItem('user_id'));

    return fetchData(
      `${process.env._DB_HOST}api/v01/user/${userId}/follow/${personID}`,
      {
        method: 'PUT',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response);
  } catch (error) {
    console.log(error);
  }
};

export const unfollowUser = (personID: string): Promise<any> => {
  try {
    const token = localStorage.getItem('token');
    const userId = JSON.parse(localStorage.getItem('user_id'));

    return fetchData(
      `${process.env._DB_HOST}api/v01/user/${userId}/follow/${personID}`,
      {
        method: 'DELETE',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response);
  } catch (error) {
    console.log(error);
  }
};
