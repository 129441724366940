import React from 'react';

import { RouteDistanceIcon, RouteTimeIcon } from 'pages/map/icons';
import { t } from 'i18next';
import { Container, SeparateDot } from './RouteSummary.style';
import { IRouteSummary } from './IRouteSummary';

const RouteSummary = ({ distance, minutes, hours, units }: IRouteSummary) => (
  <Container>
    <RouteDistanceIcon />
    <p>{distance}</p>
    <span>{units}</span>

    <SeparateDot />

    <RouteTimeIcon />
    <p>{hours}</p>
    <span>{t('common:h')}</span>
    <p>{minutes}</p>
    <span>{t('common:min')}</span>
  </Container>
);

export default RouteSummary;
