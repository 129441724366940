import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  img?: string;
  isCoverPhotoExists?: boolean;
  cardHeight?: number;
  borderRadius?: number;
  isVehicleAttendingScreen?: boolean;
}

export const CardWrap = styled.div<IProps>`
  height: ${({ cardHeight }) => (cardHeight ? `${cardHeight}px` : '200px')};
  width: 100%;
  display: flex;
  align-items: flex-end;
  border-radius: ${({ borderRadius }) =>
    borderRadius ? `${borderRadius}px` : '4px'};
  background: url(${({ img }) => img}) no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  cursor: pointer;
`;

export const CarName = styled.p<IProps>`
  width: 100%;
  max-width: calc(
    100% -
      ${({ isVehicleAttendingScreen }) =>
        isVehicleAttendingScreen ? '10px' : '55px'}
  );
  margin-left: ${({ isVehicleAttendingScreen }) =>
    isVehicleAttendingScreen ? '10px' : '16px'};
  margin-bottom: ${({ isVehicleAttendingScreen }) =>
    isVehicleAttendingScreen ? '10px' : '16px'};
  font-size: ${({ isVehicleAttendingScreen }) =>
    isVehicleAttendingScreen ? '13px' : '15px'};
  line-height: ${({ isVehicleAttendingScreen }) =>
    isVehicleAttendingScreen ? '120%' : '130%'};
  font-weight: 500;
  color: ${({ isCoverPhotoExists }) =>
    isCoverPhotoExists ? style.mainWhite.white : style.mainBlack.black};
`;
