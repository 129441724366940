import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
  lh?: number;
  pb?: number;
  pt?: number;
  isNewMessage?: boolean;
  width?: number;
  height?: number;
  backgroundColor?: string;
  img?: string;
  isCutting?: boolean;
}

export const Wrapper = styled.div``;

export const SearchFieldWrap = styled.div`
  width: 100%;
  padding: 0 20px;
  margin-bottom: 20px;
  box-sizing: border-box;
`;

export const PlaceholderWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;
`;

export const Text = styled.p<IProps>`
  font-size: 16px;
  line-height: 160%;
  letter-spacing: 0.02em;
  color: ${style.mainGray.silver};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
`;

// Chat item styles
export const ChatItemWrap = styled.div`
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 60px 0 16px;
  box-sizing: border-box;
  border-bottom: 1px solid ${style.mainGray.whiteSmokeColor};
  cursor: pointer;
  position: relative;

  :hover {
    background-color: ${style.mainGray.whiteSmokeColor};

    span {
      display: inline-flex;
    }
  }
`;

export const ProfilePhoto = styled.div<IProps>`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 10px;
  position: relative;
  background: url(${({ img }) => img || '/assets/img/emptyUserPhoto.png'})
    no-repeat;
  background-size: cover;
  background-position: center;
`;

export const ProfilePhotoIcon = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${style.mainBlue.blue};
  position: absolute;
  right: -3px;
  bottom: -3px;
`;

export const ChatItemInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

export const CentralizeContentWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const UsernameText = styled.p`
  font-size: 13px;
  line-height: 100%;
  font-weight: 500;
  letter-spacing: 0.02em;
  color: ${style.mainBlack.black};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-bottom: 1.5px;
`;

export const NewMessageDot = styled.div<IProps>`
  width: ${({ width }) => (width ? `${width}px` : '8px')};
  height: ${({ height }) => (height ? `${height}px` : '8px')};
  border-radius: 50%;
  background-color: ${({ backgroundColor }) =>
    backgroundColor || style.mainYellow.yellow};
  margin-left: ${({ ml }) => (ml ? `${ml}px` : '0')};
  margin-right: ${({ mr }) => (mr ? `${mr}px` : '0')};
`;

export const ChatItemText = styled.p<IProps>`
  font-size: 14px;
  line-height: ${({ lh }) => (lh ? `${lh}px` : '100%')};
  letter-spacing: 0.02em;
  color: ${({ isNewMessage }) =>
    isNewMessage ? style.mainBlack.black : style.mainGray.SuvaGrey};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
  padding-bottom: ${({ pb }) => (pb ? `${pb}px` : '0')};
  padding-top: ${({ pt }) => (pt ? `${pt}px` : '0')};

  ${({ isCutting }) =>
    isCutting &&
    `  width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;`}
`;

export const ChatItemThreeDotsWrap = styled.span`
  display: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${style.mainWhite.white};
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 12px;
  right: 12px;

  :hover {
    background-color: ${style.mainBlue.blue};

    .custom-dot {
      background-color: ${style.mainWhite.white};
    }
  }
`;

export const ThreeDotsWrap = styled.div`
  width: 4px;
  height: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Dot = styled.div`
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: #080808;
`;

export const PopOverWrap = styled.div<IProps>`
  position: absolute;
  top: 44px;
  right: 12px;
  z-index: 100;
  width: ${({ width }) => (width ? `${width}px` : 'auto')};
`;
