/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import { useTranslation } from 'react-i18next';

import {
  postVehicleShareStart,
  postPostShareStart,
} from 'store/analytics/actions';
import { IShareOptions } from './IShareOptions';
import { WrapPopover } from './ShareOptions.style';
import { ListUserUserActions } from '../../Popover/Popover.style';

const OptionStyle = {
  padding: '10px 26px 10px 26px',
};

export const ShareOptions = ({
  url,
  onCancelClick = null,
  type,
  postId,
  top = 25,
  right = -45,
  isCopyToClipboard,
  // onCopyToClipboardClick,
  isUrlCopied,
  setIsUrlCopied,
  isProfileUrl = false,
}: IShareOptions) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onShareWindowClose = () => {
    if (type === 4) {
      dispatch(postVehicleShareStart(postId));
    } else if (type === 1 || type === 3) {
      dispatch(postPostShareStart(postId));
    }
  };

  const onCopyToClipboardClick = (urlToCopy) => {
    if (!isUrlCopied) {
      navigator.clipboard.writeText(urlToCopy);
      setIsUrlCopied(true);
    }
  };

  return (
    <WrapPopover top={top} right={right}>
      <ListUserUserActions padding="0" pl={0}>
        <li>
          <FacebookShareButton
            style={OptionStyle}
            url={url}
            onShareWindowClose={onShareWindowClose}
          >
            {t('socialShare:shareToFacebook')}
          </FacebookShareButton>
        </li>
        <li>
          <TwitterShareButton
            style={OptionStyle}
            url={url}
            onShareWindowClose={onShareWindowClose}
          >
            {t('socialShare:shareToTwitter')}
          </TwitterShareButton>
        </li>
        <li>
          <LinkedinShareButton
            style={OptionStyle}
            url={url}
            onShareWindowClose={onShareWindowClose}
          >
            {t('socialShare:shareToLinkedIn')}
          </LinkedinShareButton>
        </li>
        <li>
          <EmailShareButton
            style={OptionStyle}
            url={url}
            onShareWindowClose={onShareWindowClose}
            // openShareDialogOnClick
          >
            {t('socialShare:shareToEmail')}
          </EmailShareButton>
        </li>
        {/* {isCopyToClipboard && ( */}
        <li onClick={() => onCopyToClipboardClick(url)} style={OptionStyle}>
          {isUrlCopied
            ? t('common:urlCopied')
            : isProfileUrl
            ? t('common:copyProfileUrl')
            : t('common:copyUrl')}
        </li>
        {/* )} */}
        <li onClick={onCancelClick} style={OptionStyle}>
          {t('common:cancel')}
        </li>
      </ListUserUserActions>
    </WrapPopover>
  );
};
