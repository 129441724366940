/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef } from 'react';
import Image from 'next/image';
import { useTranslation } from 'react-i18next';

import {
  CameraIcon,
  CrossIcon,
  CircleWithEditIcon,
  EditIcon,
  TrashIcon,
} from 'public/assets/icons';
import { useWindowSize } from 'utils/hooks';
import { IImagesUploader } from './IImagesUploader';
import {
  Wrapper,
  FileInput,
  PickerIcon,
  PickerLabel,
  UploadedImage,
  SmallImageContainerWrap,
  MultipleImagesWrap,
  CloseIconWrap,
  SmallImageWrap,
  UserImagePicker,
  EditIconWrapper,
  PopOverWrap,
  ProfilePhotoWrap,
  CoverPhotoWrap,
  RoundedImageWrap,
  ChangeImgButton,
  GroupChatPhotoWrap,
} from './ImagesUploader.style';
import { ConfiguringPopoverComponent } from '../Popover/ConfiguringPopoverComponent';
import { ListUserUserActions } from '../Popover/Popover.style';
import { ImageCropper } from '../index';

const ImagesUploader = ({
  images = [],
  onChange,
  onCloseClick,
  isProfileImages,
  isAddVehicleImage,
  height,
  coverPhoto,
  profilePhoto,
  onProfileImagesChange,
  onRemovePhotoClick,
  cropAspect = 1 / 1,
  isCropShapeCircle,
  label = 'Add a photo',
  onEditMultipleImages,
  onRemoveMultiplePhotoClick,
  isOnePhoto,
  isGroupChatPhoto,
  isPhotoPost,
  isEditPhotoPost,
  autoRatio = false,
  isAspect,
}: IImagesUploader) => {
  const [selectedImage, setSelectedImage] = useState<number>(0);
  const [isCoverPopOverOpen, setIsCoverPopOverOpen] = useState<boolean>(false);
  const [isProfilePopOverOpen, setIsProfilePopOverOpen] =
    useState<boolean>(false);
  const [showCropper, setShowCropper] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState('');
  const [imageSize, setImageSize] = useState(0);
  const [isCircleShape, setIsCircleShape] = useState<boolean>(false);
  const [isEditMultipleImagesClicked, setIsEditMultipleImagesClicked] =
    useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);

  const refActionCoverPopover = useRef(null);
  const refActionProfilePopover = useRef(null);

  const { t } = useTranslation();

  const size = useWindowSize();

  const onFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setShowCropper(true);
      setImageSize(file.size);
      setImageSrc(URL.createObjectURL(file));
      setUploadedFile(file);
      isCoverPopOverOpen && setIsCoverPopOverOpen(false);
      isProfilePopOverOpen && setIsProfilePopOverOpen(false);
    }
  };

  const onSubmitImage = (image: string) => {
    setShowCropper(false);
    setIsEditMultipleImagesClicked(false);
    onChange(image, imageSize, uploadedFile);
  };

  const onEditMultipleImagesClick = (image: string) => {
    setShowCropper(false);
    onEditMultipleImages(image, selectedImage);
  };

  const onProfileImageSubmit = (image: string, type: string) => {
    setIsCircleShape(false);
    setShowCropper(false);
    onProfileImagesChange(image, type);
  };

  const onCloseIconClick = (index) => {
    onCloseClick(index);

    if (images.length !== 1 && index === 0 && selectedImage === 0) {
      return;
    }

    if (images.length === 1) {
      setSelectedImage(index);
    } else if (index === images.length - 1) {
      setSelectedImage(index - 1);
    } else {
      setSelectedImage(selectedImage - 1);
    }
  };

  const onRemoveMultipleImagesClick = () => {
    if (images.length === 1) {
      setSelectedImage(selectedImage);
    } else {
      selectedImage === images.length - 1 &&
        setSelectedImage(selectedImage - 1);
    }
    onRemoveMultiplePhotoClick(selectedImage);
    setIsCoverPopOverOpen(false);
  };

  const smallImagesList = images.map((image, index) => (
    <SmallImageContainerWrap isFilter key={index}>
      <SmallImageWrap onClick={() => setSelectedImage(index)}>
        <UploadedImage fullWidth src={image} />
      </SmallImageWrap>

      {!isEditPhotoPost && (
        <CloseIconWrap onClick={() => onCloseIconClick(index)}>
          <CrossIcon height="16" width="16" />
        </CloseIconWrap>
      )}
    </SmallImageContainerWrap>
  ));

  if (isProfileImages) {
    return (
      <>
        {showCropper && imageSrc && (
          <ImageCropper
            aspect={cropAspect}
            image={imageSrc}
            onSubmitImage={onProfileImageSubmit}
            isCircleShape={isCircleShape}
            isCoverImage={!isCircleShape}
            isProfileImages={isProfileImages}
            isAspect={isAspect}
          />
        )}
        <Wrapper
          wrapperHeight={coverPhoto.trim() ? 'auto' : `${height}px`}
          height={!autoRatio ? 360 : 'auto'}
        >
          {coverPhoto.trim() ? (
            <>
              <CoverPhotoWrap height={!autoRatio ? height : 'auto'}>
                <UploadedImage src={coverPhoto} fullWidth />
              </CoverPhotoWrap>

              <EditIconWrapper
                isCover
                ref={refActionCoverPopover}
                onClick={() => setIsCoverPopOverOpen(!isCoverPopOverOpen)}
              >
                <CircleWithEditIcon
                  transparent={false}
                  width="32"
                  height="32"
                />
              </EditIconWrapper>

              {isCoverPopOverOpen && (
                <PopOverWrap>
                  <ListUserUserActions>
                    <li style={{ position: 'relative' }}>
                      <FileInput
                        type="file"
                        onChange={onFileChange}
                        accept="image/*"
                      />
                      <span>
                        <EditIcon />
                      </span>
                      {t('selectPhotos:changePhoto')}
                    </li>
                    <li
                      onClick={() => {
                        onRemovePhotoClick('cover');
                        setIsCoverPopOverOpen(false);
                      }}
                    >
                      <span>
                        <TrashIcon />
                      </span>
                      {t('selectPhotos:removePhoto')}
                    </li>
                  </ListUserUserActions>
                </PopOverWrap>
              )}
            </>
          ) : (
            <>
              <PickerIcon mt={27}>
                <CameraIcon />
                <PickerLabel>{label}</PickerLabel>
              </PickerIcon>
              <FileInput type="file" onChange={onFileChange} accept="image/*" />
            </>
          )}

          {!isOnePhoto && (
            <UserImagePicker>
              {profilePhoto.trim() ? (
                <>
                  <ProfilePhotoWrap>
                    <UploadedImage fullWidth src={profilePhoto} rounded />
                  </ProfilePhotoWrap>
                  <EditIconWrapper
                    ref={refActionProfilePopover}
                    onClick={() =>
                      setIsProfilePopOverOpen(!isProfilePopOverOpen)
                    }
                  >
                    <CircleWithEditIcon transparent={false} />
                  </EditIconWrapper>
                  {isProfilePopOverOpen && (
                    <PopOverWrap top={-5} right={280}>
                      <ConfiguringPopoverComponent>
                        <ListUserUserActions>
                          <li style={{ position: 'relative' }}>
                            <FileInput
                              type="file"
                              onChange={(e) => {
                                onFileChange(e);
                                setIsCircleShape(true);
                              }}
                              accept="image/*"
                            />
                            <span>
                              <EditIcon />
                            </span>
                            {t('selectPhotos:changePhoto')}
                          </li>
                          <li
                            onClick={() => {
                              onRemovePhotoClick('profile');
                              setIsProfilePopOverOpen(false);
                            }}
                          >
                            <span>
                              <TrashIcon />
                            </span>
                            {t('selectPhotos:removePhoto')}
                          </li>
                        </ListUserUserActions>
                      </ConfiguringPopoverComponent>
                    </PopOverWrap>
                  )}
                </>
              ) : (
                <>
                  <CameraIcon width="32" height="32" />

                  <FileInput
                    type="file"
                    onChange={(e) => {
                      setIsCircleShape(true);
                      onFileChange(e);
                    }}
                    accept="image/*"
                  />
                </>
              )}
            </UserImagePicker>
          )}
        </Wrapper>
      </>
    );
  }

  const renderVehicleImageCropper = () => {
    if (isEditMultipleImagesClicked) {
      return (
        <ImageCropper
          aspect={cropAspect}
          image={imageSrc}
          onSubmitImage={onEditMultipleImagesClick}
          isProfileImages={isProfileImages}
          isAspect={isAspect}
        />
      );
    }

    return (
      <ImageCropper
        aspect={cropAspect}
        image={imageSrc}
        onSubmitImage={onSubmitImage}
        isProfileImages={isProfileImages}
        isAspect={isAspect}
      />
    );
  };

  if (isAddVehicleImage && images && images.length) {
    return (
      <>
        {showCropper && imageSrc && renderVehicleImageCropper()}
        <>
          <MultipleImagesWrap>
            <Wrapper
              backgroundColor="#000000"
              wrapperHeight={size.width <= 640 ? `${size.width}px` : '360px'}
              isPhotoPost
              height={size.width <= 640 ? size.width : 360}
            >
              <UploadedImage
                src={images[selectedImage]}
                width={size.width <= 640 ? size.width : 360}
                objectFit="contain"
              />

              <EditIconWrapper
                isCover
                ref={refActionCoverPopover}
                onClick={() => setIsCoverPopOverOpen(!isCoverPopOverOpen)}
              >
                <CircleWithEditIcon
                  transparent={false}
                  width="32"
                  height="32"
                />
              </EditIconWrapper>
            </Wrapper>
            {smallImagesList}
            {smallImagesList.length < 5 && (
              <SmallImageContainerWrap>
                <FileInput
                  type="file"
                  onChange={onFileChange}
                  accept="image/*"
                />

                <PickerIcon mt={20} mb={0} isSmallImages>
                  <CameraIcon width="24" height="24" />
                </PickerIcon>
              </SmallImageContainerWrap>
            )}
          </MultipleImagesWrap>
          {isCoverPopOverOpen && (
            <PopOverWrap top={140} right={30} width={210}>
              <ListUserUserActions>
                <li style={{ position: 'relative' }}>
                  <FileInput
                    type="file"
                    onChange={(e) => {
                      onFileChange(e);
                      setIsEditMultipleImagesClicked(true);
                    }}
                    accept="image/*"
                  />
                  <span>
                    <EditIcon />
                  </span>
                  {t('selectPhotos:changePhoto')}
                </li>
                <li onClick={onRemoveMultipleImagesClick}>
                  <span>
                    <TrashIcon />
                  </span>
                  {t('selectPhotos:removePhoto')}
                </li>
              </ListUserUserActions>
            </PopOverWrap>
          )}
        </>
      </>
    );
  }

  if (isGroupChatPhoto) {
    return (
      <>
        {showCropper && imageSrc && (
          <ImageCropper
            aspect={cropAspect}
            image={imageSrc}
            onSubmitImage={onSubmitImage}
            isCircleShape={isCropShapeCircle}
            isBlobImage
            isAspect={isAspect}
          />
        )}

        {profilePhoto ? (
          <div style={{ width: 150, height: 150, position: 'relative' }}>
            <GroupChatPhotoWrap>
              <Image
                loader={() => profilePhoto}
                width={150}
                height={150}
                src={profilePhoto}
                alt="chat_group"
                unoptimized
                priority
              />
            </GroupChatPhotoWrap>

            <EditIconWrapper
              isCover
              ref={refActionCoverPopover}
              onClick={() => setIsCoverPopOverOpen(!isCoverPopOverOpen)}
            >
              <CircleWithEditIcon transparent={false} width="32" height="32" />
            </EditIconWrapper>

            {isCoverPopOverOpen && (
              <PopOverWrap width={180}>
                <ListUserUserActions>
                  <li style={{ position: 'relative' }}>
                    <FileInput
                      type="file"
                      onChange={onFileChange}
                      accept="image/*"
                    />
                    <span>
                      <EditIcon />
                    </span>
                    {t('selectPhotos:changePhoto')}
                  </li>
                </ListUserUserActions>
              </PopOverWrap>
            )}
          </div>
        ) : (
          <RoundedImageWrap>
            <PickerIcon>
              <CameraIcon />
              <PickerLabel>{label}</PickerLabel>
            </PickerIcon>
            <FileInput type="file" onChange={onFileChange} accept="image/*" />
          </RoundedImageWrap>
        )}
      </>
    );
  }

  const renderSmallImages = () => {
    if (isEditPhotoPost) {
      return images.length > 1 && smallImagesList;
    }

    return smallImagesList;
  };

  return (
    <>
      {showCropper && imageSrc && (
        <ImageCropper
          aspect={cropAspect}
          image={imageSrc}
          onSubmitImage={onSubmitImage}
          isCircleShape={isCropShapeCircle}
          isAspect={isAspect}
        />
      )}
      {images.length ? (
        <MultipleImagesWrap>
          <Wrapper
            backgroundColor={isPhotoPost ? 'transparent' : '#000000'}
            wrapperHeight={
              isPhotoPost
                ? 'auto'
                : size.width <= 640
                ? `${size.width}px`
                : '360px'
            }
            isPhotoPost={isPhotoPost}
            height={isPhotoPost ? 'auto' : size.width <= 640 ? size.width : 360}
          >
            <UploadedImage
              src={images[selectedImage]}
              width={size.width <= 640 ? size.width : 360}
              objectFit="contain"
            />
          </Wrapper>
          {renderSmallImages()}
          {smallImagesList.length < 5 && !isEditPhotoPost && (
            <SmallImageContainerWrap>
              <FileInput type="file" onChange={onFileChange} accept="image/*" />

              <PickerIcon mt={20} mb={0} isSmallImages>
                <CameraIcon width="24" height="24" />
              </PickerIcon>
            </SmallImageContainerWrap>
          )}
        </MultipleImagesWrap>
      ) : (
        <Wrapper height={height} isPhotoPost={isPhotoPost}>
          <PickerIcon>
            <CameraIcon />
            <PickerLabel>{label}</PickerLabel>
          </PickerIcon>
          <FileInput type="file" onChange={onFileChange} accept="image/*" />
        </Wrapper>
      )}
    </>
  );
};

export default ImagesUploader;
